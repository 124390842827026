import { Divider, Stack, Typography } from '@cmg/design-system';
import React from 'react';

type Row = {
  rowLabel: string;
  rowValue: string;
};

type InformationBlockProps = {
  title: string;
  body: Row[];
  footer: Row;
};

export function InformationBlock({ title, body, footer }: InformationBlockProps) {
  return (
    <Stack
      flex={1}
      gap={1}
      minWidth={270}
      borderRadius={1}
      paddingY={theme => theme.spacing(2)}
      paddingX={theme => theme.spacing(3)}
      bgcolor="rgba(222, 243, 247, 0.8)"
      color="rgba(43, 62, 89, 0.95)"
      data-testid={`${title}-information-block`}
    >
      <Typography variant="h3">{title}</Typography>
      {body.map(({ rowLabel, rowValue }, index) => (
        <Stack
          key={index}
          data-testid={`information-block-row-${index}`}
          flexDirection="row"
          justifyContent="space-between"
        >
          <div>{rowLabel}</div>
          <div>{rowValue}</div>
        </Stack>
      ))}
      <Divider />
      <Stack
        data-testid="information-block-footer"
        flexDirection="row"
        justifyContent="space-between"
      >
        <Typography variant="highlight1">{footer.rowLabel}</Typography>
        <Typography variant="highlight1">{footer.rowValue}</Typography>
      </Stack>
    </Stack>
  );
}
