import { getOperationName } from '@apollo/client/utilities';
import { UUID } from '@cmg/common';
import { SnackbarManager } from '@cmg/design-system';
import React from 'react';

import { IndicationStatus } from '../../../../../../../../../../graphql';
import { Calendar_LiveCalendarOfferingsListDocument } from '../../../../../../../../../calendar/live-calendar/graphql';
import { refetchDemandGridReactiveVar } from '../../../../../../../../../order-book/institutional-demand-v2/hooks/useRefetchDemandGridReactiveVar';
import { useCmgEntityKeyContext } from '../../../../../../../../contexts/CmgEntityKeyContext';
import {
  useManageIndication_PassOnInstitutionalIndicationMutation,
  useManageIndication_PassOnOfferingWithoutInstitutionalIndicationMutation,
} from '../../../../graphql/__generated__';

export const refetchQueries = [getOperationName(Calendar_LiveCalendarOfferingsListDocument)!];

export const usePassOnInstitutionalIndication = (
  offeringId: UUID,
  indicationId: UUID | undefined,
  indicationStatus: IndicationStatus | null = null,
  onClose: () => void,
  isPassingNewIndication: boolean,
  onFinishEditing?: () => void
): [() => Promise<void>, boolean] => {
  const [passIndication, { loading: isPassingIndication }] =
    useManageIndication_PassOnInstitutionalIndicationMutation();
  const [passWithoutIndication, { loading: isPassingWithoutIndication }] =
    useManageIndication_PassOnOfferingWithoutInstitutionalIndicationMutation();
  const { cmgEntityKey } = useCmgEntityKeyContext();

  const handlePassWithoutIndication = React.useCallback(async () => {
    await passWithoutIndication({
      variables: {
        offeringId,
        input: { investorInformation: { cmgEntityKey } },
      },
      awaitRefetchQueries: true,
      refetchQueries,
    });
  }, [cmgEntityKey, offeringId, passWithoutIndication]);
  const handlePassIndication = React.useCallback(async () => {
    await passIndication({
      variables: {
        offeringId,
        indicationId: indicationId!,
        activateIfCancelled: indicationStatus === IndicationStatus.Cancelled,
      },
      awaitRefetchQueries: true,
      refetchQueries,
    });
  }, [indicationId, indicationStatus, offeringId, passIndication]);

  const handlePass = React.useCallback(async () => {
    try {
      if (isPassingNewIndication) {
        await handlePassWithoutIndication();
      } else {
        await handlePassIndication();
      }

      SnackbarManager.success('Successfully passed indication');
      refetchDemandGridReactiveVar(true);
      onClose();
      onFinishEditing?.();
    } catch {
      SnackbarManager.error('Failed to pass indication');
    }
  }, [
    handlePassIndication,
    handlePassWithoutIndication,
    isPassingNewIndication,
    onClose,
    onFinishEditing,
  ]);

  return [handlePass, isPassingIndication || isPassingWithoutIndication];
};
