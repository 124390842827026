import { makeVar, useReactiveVar } from '@apollo/client';
import { useEffect } from 'react';

/**
 * Reactive var to trigger refetching demand grid data right after user performs demand grid data change.
 */
export const refetchDemandGridReactiveVar = makeVar(false);

type Props = Readonly<{
  onRefetch: () => void;
}>;

/**
 * Listen to refetchDemandGridReactiveVar and call onRefetch when it changes to true.
 * This hook should be used as reactive var consumer only.
 *
 * @param onRefetch
 */
export const useRefetchDemandGridReactiveVar = ({ onRefetch }: Props) => {
  const value = useReactiveVar(refetchDemandGridReactiveVar);

  useEffect(() => {
    if (value) {
      onRefetch();
      refetchDemandGridReactiveVar(false);
    }
  }, [value, onRefetch]);
};
