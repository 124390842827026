import type { UUID } from '@cmg/common';
import type { GridRowSelectionState } from '@cmg/data-grid';
import { useDataGridContext } from '@cmg/data-grid';
import { SnackbarManager } from '@cmg/design-system';
import { useCallback } from 'react';

import { useDialogState } from '../../institutional-demand/components/institutional-demand-grid/toolbar/allocations-dropdown-menu/allocations-dropdown-buton/common/hooks/useDialogState';
import type { OrderBook_InstitutionalDemand_GridRowPartsFragment } from '../graphql/__generated__';
import { useOrderBook_InstitutionalDemand_MarkAsDuplicateMutation } from '../graphql/__generated__';
import { refetchDemandGridReactiveVar } from './useRefetchDemandGridReactiveVar';

export type Props = Readonly<{
  offeringId: UUID;
  rowSelectionState: GridRowSelectionState;
}>;

export const useMarkAsDuplicateDialogState = ({ offeringId, rowSelectionState }: Props) => {
  const { getRowData } = useDataGridContext();
  const { onOpen, ...dialogState } =
    useDialogState<OrderBook_InstitutionalDemand_GridRowPartsFragment[]>();

  const [markAsDuplicate, { error, loading }] =
    useOrderBook_InstitutionalDemand_MarkAsDuplicateMutation();

  const handleOpen = useCallback(() => {
    if (rowSelectionState.selectAll || rowSelectionState.selectedRowIdsFlat.length < 2) {
      SnackbarManager.error('Please select specific rows to mark as duplicates.');
      return;
    }

    const selectedRowsData = getRowData<OrderBook_InstitutionalDemand_GridRowPartsFragment>(
      rowSelectionState.selectedRowIdsFlat
    );

    onOpen(selectedRowsData);
  }, [getRowData, onOpen, rowSelectionState]);

  const onSave = useCallback(
    async (survivorId: string, markAsDuplicateIds: string[]) => {
      await markAsDuplicate({
        variables: {
          offeringId,
          survivorId,
          markAsDuplicateIds,
        },
      });

      refetchDemandGridReactiveVar(true);
      SnackbarManager.success('Selected indications have been successfully marked as duplicates.');
    },
    [markAsDuplicate, offeringId]
  );

  return { ...dialogState, onOpen: handleOpen, onSave, error, loading };
};
