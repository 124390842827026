import type { UUID } from '@cmg/common';
import type { GridRowSelectionState } from '@cmg/data-grid';
import { useDataGridContext } from '@cmg/data-grid';
import { SnackbarManager } from '@cmg/design-system';
import { useCallback } from 'react';

import { useDialogState } from '../../institutional-demand/components/institutional-demand-grid/toolbar/allocations-dropdown-menu/allocations-dropdown-buton/common/hooks/useDialogState';
import { getBulkActionsFilterInput } from '../demand-grid/DemandGrid.model';
import type { DemandConfigValues } from '../demand-grid/types';
import { useOrderBook_InstitutionalDemand_BulkUpdateAllocationsMutation } from '../graphql/__generated__';
import { refetchDemandGridReactiveVar } from './useRefetchDemandGridReactiveVar';

type FinalAllocationSet = {
  id: UUID;
  isReleased: boolean;
};

export type Props = Readonly<{
  offeringId: UUID;
  demandConfig: DemandConfigValues | null;
  rowSelectionState: GridRowSelectionState;
  finalAllocationSet: FinalAllocationSet | undefined;
  onShowComplianceBeforeSave: (args: { onContinue: () => void }) => void;
}>;

/**
 * Hook for handling the state of the Bulk Enter Allocations dialog including the onSave function handler.
 *
 * @param offeringId
 * @param demandConfig
 * @param rowSelectionState
 * @param finalAllocationSet - The final allocation set information.
 * @param onShowComplianceBeforeSave - Function to show compliance dialog before saving allocations, applicable when final allocation is released and selected for update.
 */
export const useBulkEnterAllocationsDialogState = ({
  offeringId,
  demandConfig,
  rowSelectionState,
  finalAllocationSet,
  onShowComplianceBeforeSave,
}: Props) => {
  const dialogState = useDialogState();
  const { getFilters } = useDataGridContext();

  const [bulkUpdateAllocations, { error, loading }] =
    useOrderBook_InstitutionalDemand_BulkUpdateAllocationsMutation();

  const handleSave = useCallback(
    async (shareQuantity: number, allocationSetIds: string[]) => {
      if (!demandConfig) {
        return;
      }

      await bulkUpdateAllocations({
        variables: {
          offeringId,
          allocationSetIds,
          shareQuantity,
          applyTo: {
            ...demandConfig,
            where: getBulkActionsFilterInput(rowSelectionState, getFilters()?.filterInput),
          },
        },
      });

      refetchDemandGridReactiveVar(true);
      SnackbarManager.success('Allocations have been successfully updated.');
    },
    [bulkUpdateAllocations, demandConfig, getFilters, offeringId, rowSelectionState]
  );

  const onSave = useCallback(
    async (shareQuantity: number, allocationSetIds: string[]) => {
      if (finalAllocationSet?.isReleased && allocationSetIds.includes(finalAllocationSet.id)) {
        dialogState.onClose();
        onShowComplianceBeforeSave({
          onContinue: async () => {
            await handleSave(shareQuantity, allocationSetIds);
          },
        });
      } else {
        await handleSave(shareQuantity, allocationSetIds);
      }
    },
    [
      dialogState,
      finalAllocationSet?.id,
      finalAllocationSet?.isReleased,
      handleSave,
      onShowComplianceBeforeSave,
    ]
  );

  return { ...dialogState, onSave, error, loading };
};
