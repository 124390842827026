import { getOperationName } from '@apollo/client/utilities';
import { UUID } from '@cmg/common';
import { SnackbarManager } from '@cmg/design-system';
import React from 'react';

import { AssignContactInput } from '../../../../../../../../../graphql';
import { pendingOrderBookGridForcedRefreshVar } from '../../../../../../../../order-book';
import { InstitutionalDemandGrid_SummaryDocument } from '../../../../../../../../order-book/institutional-demand/components/institutional-demand-grid/graphql';
import { refetchDemandGridReactiveVar } from '../../../../../../../../order-book/institutional-demand-v2/hooks/useRefetchDemandGridReactiveVar';
import {
  parseFirmKey,
  useCmgEntityKeyContext,
} from '../../../../../../../contexts/CmgEntityKeyContext';
import { useFirmKeyOrIdContext } from '../../../../../../../contexts/FirmKeyOrIdContext';
import { SyndicateIndicationDocument } from '../../../../../context/hooks/syndicate/__generated__';
import { IndicationForm_IndicationPartsFragment } from '../../../../indication-form/graphql/__generated__';
import { useInvestorContactInformation_AssignContactsMutation } from '../../../../investor-contact-information/graphql/__generated__';
import { createInstitutionalIndicationSubmitInputVariables } from '../../../common/utils/CreateIndicationVariables';
import { IndicationCreateFormValues } from '../../../common/utils/IndicationSectionValidation';
import { useCreateIndication_SubmitIndicationMutation } from '../../../graphql/__generated__';

export const refetchQueries = [
  getOperationName(InstitutionalDemandGrid_SummaryDocument)!,
  getOperationName(SyndicateIndicationDocument)!,
];

export const useCreateInstitutionalIndication = (offeringId: UUID) => {
  const [createIndication] = useCreateIndication_SubmitIndicationMutation();
  const [assignContacts] = useInvestorContactInformation_AssignContactsMutation();

  const { firmKey } = useFirmKeyOrIdContext();
  const { cmgEntityKey, updateCmgEntityKey } = useCmgEntityKeyContext();
  const firmKeyOnUrl = parseFirmKey(cmgEntityKey);

  const handleCreateInstitutionalIndication = React.useCallback(
    async (values: IndicationCreateFormValues) => {
      let returnIndication: IndicationForm_IndicationPartsFragment;
      let indicationSubmitted = false;
      try {
        const response = await createIndication({
          variables: createInstitutionalIndicationSubmitInputVariables({
            offeringId,
            values,
            firmKey,
            cmgEntityKey,
          }),
          awaitRefetchQueries: true,
          refetchQueries,
        });

        const responseIndication = response.data!.submitInstitutionalIndication;
        if (responseIndication.__typename === 'ServiceError') {
          throw new Error('Sending new indication failed');
        }
        returnIndication = responseIndication;

        // in case the indication was created with investor not linked to a cmg entity
        // (i.e. via firmKey), update cmgEntityKey context with newly created cmg entity
        if (firmKeyOnUrl) {
          updateCmgEntityKey(returnIndication.investorInformation.cmgEntityKey!);
        }

        pendingOrderBookGridForcedRefreshVar(true);
        indicationSubmitted = true;
      } catch {
        SnackbarManager.error('Failed to create Indication');
        return;
      }

      try {
        await assignContacts({
          variables: {
            indicationId: returnIndication.id,
            offeringId,
            input: values.sellSideContacts.map<AssignContactInput>(({ name, email }) => ({
              email,
              name,
            })),
          },
        });
      } catch {
        SnackbarManager.error('Failed to assign contacts');
      }

      if (indicationSubmitted) {
        refetchDemandGridReactiveVar(true);
        SnackbarManager.success('Successfully created Indication');
      }
    },
    [
      assignContacts,
      cmgEntityKey,
      createIndication,
      firmKey,
      firmKeyOnUrl,
      offeringId,
      updateCmgEntityKey,
    ]
  );

  return [handleCreateInstitutionalIndication];
};
