import { UUID } from '@cmg/common';
import {
  AddIcon,
  Button,
  Grid,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Typography,
} from '@cmg/design-system';
import { FormikSelectField, SelectOption } from '@cmg/design-system-formik';
import { FieldArray, useFormikContext } from 'formik';
import React from 'react';

import { IndicationForm_OfferingPartsFragment } from '../../../indication-form/graphql/__generated__';
import InterestLevelsHeaderRow from '../indication-section-read-view/interest-levels-header-row/InterestLevelsHeaderRow';
import { IndicationFormValues } from '../utils/IndicationSectionValidation';
import {
  createInterestLevelNewValue,
  getCurrencyOptions,
  getInstrumentOptions,
  getTrancheOptions,
} from './IndicationSectionForm.model';
import InterestLevelFormRow from './interest-level-form-row/InterestLevelFormRow';
import InvalidFormAlert from './invalid-form-alert/InvalidFormAlert';

export type Props = Readonly<{
  canEnterTranche?: boolean;
  tranches: IndicationForm_OfferingPartsFragment['orderEntryProfile']['tranches'];
  instruments: IndicationForm_OfferingPartsFragment['orderEntryProfile']['instruments'];
  offeringType: IndicationForm_OfferingPartsFragment['type'];
  pricingCurrencyCode: IndicationForm_OfferingPartsFragment['pricingCurrencyCode'];
  securityTypeDisplayName: string;
}>;

const IndicationSectionForm: React.FC<Props> = ({
  tranches,
  instruments,
  offeringType,
  pricingCurrencyCode,
  canEnterTranche = false,
  securityTypeDisplayName,
}) => {
  const { values, isSubmitting } = useFormikContext<IndicationFormValues>();

  const selectedTranche = React.useMemo(
    () => tranches.find(({ id }) => id === values.trancheId),
    [tranches, values.trancheId]
  );

  const trancheOptions = React.useMemo<SelectOption<UUID>[]>(
    () => getTrancheOptions(tranches),
    [tranches]
  );
  const instrumentOptions = React.useMemo<SelectOption<UUID>[]>(
    () => getInstrumentOptions(instruments, selectedTranche, securityTypeDisplayName),
    [instruments, selectedTranche, securityTypeDisplayName]
  );
  const currencyOptions = React.useMemo<SelectOption<string>[]>(
    () => getCurrencyOptions(selectedTranche),
    [selectedTranche]
  );

  return (
    <Stack gap={1}>
      <InvalidFormAlert />

      <Grid container pt={1} spacing={2}>
        <Grid item xs={4}>
          <FormikSelectField
            aria-label="tranche"
            name="trancheId"
            label="Tranche"
            disabled={isSubmitting || !canEnterTranche}
            options={trancheOptions}
            showHelperTextInTooltip
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={4}>
          {values.trancheId && (
            <FormikSelectField
              aria-label="demandInstrument"
              name="instrumentId"
              label="Demand Instrument"
              disabled={isSubmitting}
              options={instrumentOptions}
              showHelperTextInTooltip
              fullWidth
              required
            />
          )}
        </Grid>
        <Grid item xs={4}>
          {values.trancheId && (
            <FormikSelectField
              aria-label="demandCurrency"
              name="currencyCode"
              label="Demand Currency"
              disabled={isSubmitting}
              options={currencyOptions}
              showHelperTextInTooltip
              fullWidth
              required
            />
          )}
        </Grid>
      </Grid>

      <FieldArray
        name="interestLevels"
        render={({ remove, push }) => (
          <div>
            <Stack gap={2} direction="row" alignItems="center">
              <Typography variant="h4">Interest Levels</Typography>
              <Button
                variant="text"
                startIcon={<AddIcon />}
                disabled={isSubmitting}
                onClick={() => push(createInterestLevelNewValue(values.interestLevels))}
              >
                Add Interest Level
              </Button>
            </Stack>

            <TableContainer>
              <Table>
                <TableHead>
                  <InterestLevelsHeaderRow isEditing />
                </TableHead>
                <TableBody>
                  {values.interestLevels.map((rowValue, index) => (
                    <InterestLevelFormRow
                      key={index}
                      index={index}
                      onRemove={() => remove(index)}
                      rowValue={rowValue}
                      offeringType={offeringType}
                      pricingCurrencyCode={pricingCurrencyCode}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
      />
    </Stack>
  );
};

export default IndicationSectionForm;
