import { getCurrencySymbol, numericUtil } from '@cmg/common';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableGroupHeader,
  TableGroupHeaderCell,
  TableHead,
  TableRow,
  Typography,
} from '@cmg/design-system';
import { useFormikContext } from 'formik';

import TableTotalRow from '../../../../design-system/components/data-display/table/TableTotalRow';
import { useFormIsEditing } from '../hooks/useFormIsEditing';
import { UnderwritingTermsDiscountsAndFeesFormValues } from '../UnderwritingTermsDiscountsAndFeesSection';
import { TableCellCurrency } from './TableCellCurrency';
import { TableCellPercentage } from './TableCellPercentage';
import { TableTotalCell } from './TableTotalCell';

type UnderwritingTermsDiscountsAndFeesFormProps = {
  currencyCode: string;
};

export function UnderwritingTermsDiscountsAndFeesForm({
  currencyCode,
}: UnderwritingTermsDiscountsAndFeesFormProps) {
  const formik = useFormikContext<UnderwritingTermsDiscountsAndFeesFormValues>();
  const { isEditing } = useFormIsEditing();

  return (
    <TableContainer>
      <Table style={{ tableLayout: 'fixed' }}>
        <TableHead>
          <TableRow>
            <TableCell>Underwriting Discount</TableCell>
            <TableCell align="center" colSpan={2}>
              Base Gross Spread (%, {getCurrencySymbol(currencyCode)})
            </TableCell>
            <TableCell align="center" colSpan={2}>
              Incentive Fee (%, {getCurrencySymbol(currencyCode)})
            </TableCell>
            <TableCell align="right">Total (%, {getCurrencySymbol(currencyCode)})</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>
              <Typography variant="body1">Gross Spread</Typography>
            </TableCell>
            <TableCellPercentage
              tabIndex={1}
              name="grossSpreadBaseData.grossSpreadBasePercentage"
            />
            <TableCellCurrency
              currencyCode={currencyCode}
              tabIndex={1}
              name="grossSpreadBaseData.grossSpreadBase"
            />
            <TableCellPercentage tabIndex={5} name="incentiveFeeData.incentiveFeePercentage" />
            <TableCellCurrency
              currencyCode={currencyCode}
              tabIndex={5}
              name="incentiveFeeData.incentiveFee"
            />
            <TableCell align="right">
              <Typography variant="highlight1">
                {numericUtil.getDisplayValueForPercents(formik.values.grossSpreadTotalPercentage)} /{' '}
                {numericUtil.getDisplayValueForCurrency(
                  formik.values.grossSpreadTotal,
                  6,
                  currencyCode
                )}
              </Typography>
            </TableCell>
          </TableRow>
          <TableGroupHeader>
            <TableGroupHeaderCell colSpan={6}>Gross Spread Allocation</TableGroupHeaderCell>
          </TableGroupHeader>
          <TableRow>
            <TableCell>Management Fee</TableCell>
            <TableCellPercentage tabIndex={2} name="grossSpreadBaseData.managementFeePercentage" />
            <TableCellCurrency
              currencyCode={currencyCode}
              tabIndex={2}
              name="grossSpreadBaseData.managementFee"
            />
            <TableCellPercentage tabIndex={6} name="incentiveFeeData.managementFeePercentage" />
            <TableCellCurrency
              currencyCode={currencyCode}
              tabIndex={6}
              name="incentiveFeeData.managementFee"
            />
            <TableCell align="right">
              <Typography variant="highlight1">
                {numericUtil.getDisplayValueForCurrency(
                  formik.values.managementFee,
                  6,
                  currencyCode
                )}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Underwriting Fee</TableCell>
            <TableCellPercentage
              tabIndex={3}
              name="grossSpreadBaseData.underwritingFeePercentage"
            />
            <TableCellCurrency
              currencyCode={currencyCode}
              tabIndex={3}
              name="grossSpreadBaseData.underwritingFee"
            />
            <TableCellPercentage tabIndex={7} name="incentiveFeeData.underwritingFeePercentage" />
            <TableCellCurrency
              currencyCode={currencyCode}
              tabIndex={7}
              name="incentiveFeeData.underwritingFee"
            />
            <TableCell align="right">
              <Typography variant="highlight1">
                {numericUtil.getDisplayValueForCurrency(
                  formik.values.underwritingFee,
                  6,
                  currencyCode
                )}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Selling Concession</TableCell>
            <TableCellPercentage
              tabIndex={4}
              name="grossSpreadBaseData.sellingConcessionPercentage"
            />
            <TableCellCurrency
              currencyCode={currencyCode}
              tabIndex={4}
              name="grossSpreadBaseData.sellingConcession"
            />
            <TableCellPercentage tabIndex={8} name="incentiveFeeData.sellingConcessionPercentage" />
            <TableCellCurrency
              currencyCode={currencyCode}
              tabIndex={8}
              name="incentiveFeeData.sellingConcession"
            />
            <TableCell align="right">
              <Typography variant="highlight1">
                {numericUtil.getDisplayValueForCurrency(
                  formik.values.sellingConcession,
                  6,
                  currencyCode
                )}
              </Typography>
            </TableCell>
          </TableRow>
          {isEditing && (
            <TableTotalRow>
              <TableCell>
                <Typography variant="highlight1" color="white">
                  Total
                </Typography>
                <Typography variant="body2" color="secondaryDarkBackground.main">
                  Difference
                </Typography>
              </TableCell>
              <TableTotalCell
                tableCellProps={{
                  align: 'right',
                }}
                mimicFormInputPaddings
                primaryText={numericUtil.getDisplayValueForPercents(
                  formik.values.totalBaseGrossSpreadAllocationPercentage
                )}
                secondaryText={numericUtil.getDisplayValueForPercents(
                  formik.values.totalBaseGrossSpreadAllocationPercentageDiff
                )}
                errorMessage={formik.errors.totalBaseGrossSpreadAllocationPercentageDiff}
              />
              <TableTotalCell
                tableCellProps={{
                  align: 'right',
                }}
                mimicFormInputPaddings
                primaryText={numericUtil.getDisplayValueForCurrency(
                  formik.values.totalBaseGrossSpreadAllocation,
                  6,
                  currencyCode
                )}
                secondaryText={numericUtil.getDisplayValueForCurrency(
                  formik.values.totalBaseGrossSpreadAllocationDiff,
                  6,
                  currencyCode
                )}
                errorMessage={formik.errors.totalBaseGrossSpreadAllocationDiff}
              />
              <TableTotalCell
                tableCellProps={{
                  align: 'right',
                }}
                mimicFormInputPaddings
                primaryText={numericUtil.getDisplayValueForPercents(
                  formik.values.totalIncentiveGrossSpreadAllocationPercentage
                )}
                secondaryText={numericUtil.getDisplayValueForPercents(
                  formik.values.totalIncentiveGrossSpreadAllocationPercentageDiff
                )}
                errorMessage={formik.errors.totalIncentiveGrossSpreadAllocationPercentageDiff}
              />
              <TableTotalCell
                tableCellProps={{
                  align: 'right',
                }}
                mimicFormInputPaddings
                primaryText={numericUtil.getDisplayValueForCurrency(
                  formik.values.totalIncentiveGrossSpreadAllocation,
                  6,
                  currencyCode
                )}
                secondaryText={numericUtil.getDisplayValueForCurrency(
                  formik.values.totalIncentiveGrossSpreadAllocationDiff,
                  6,
                  currencyCode
                )}
                errorMessage={formik.errors.totalIncentiveGrossSpreadAllocationDiff}
              />
              <TableTotalCell
                tableCellProps={{ align: 'right' }}
                primaryText={numericUtil.getDisplayValueForCurrency(
                  formik.values.totalCurrency,
                  6,
                  currencyCode
                )}
                secondaryText={numericUtil.getDisplayValueForCurrency(
                  formik.values.totalCurrencyDiff,
                  6,
                  currencyCode
                )}
                errorMessage={[
                  formik.errors.totalBaseGrossSpreadAllocationDiff,
                  formik.errors.totalIncentiveGrossSpreadAllocationDiff,
                ]
                  .filter(Boolean)
                  .join('\n')}
              />
            </TableTotalRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
