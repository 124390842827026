/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OrderBook_InstitutionalDemand_ManagerProfilePartsFragment = {
  readonly __typename?: 'ManagerProfile';
  readonly id: string;
  readonly firmName: string;
  readonly firmNameAbbreviation?: string | null;
  readonly cmgEntityKey: string;
};

export type OrderBook_InstitutionalDemand_PublishedOfferingPartsFragment = {
  readonly __typename?: 'OfferingProfile';
  readonly id: string;
  readonly status: Types.OfferingStatus;
  readonly pricingCurrencyCode: string;
  readonly derivedOfferPrice?: number | null;
  readonly initialFilingPriceRangeLow?: number | null;
  readonly initialFilingPriceRangeHigh?: number | null;
  readonly latestFilingPriceRangeLow?: number | null;
  readonly latestFilingPriceRangeHigh?: number | null;
  readonly prospectusDocuments: ReadonlyArray<{
    readonly __typename?: 'ProspectusDocumentProfile';
    readonly id: string;
  }>;
  readonly issuer: { readonly __typename?: 'Issuer'; readonly name: string };
  readonly syndicate: {
    readonly __typename?: 'SyndicateProfile';
    readonly managers: ReadonlyArray<{
      readonly __typename?: 'ManagerProfile';
      readonly id: string;
      readonly firmName: string;
      readonly firmNameAbbreviation?: string | null;
      readonly cmgEntityKey: string;
    }>;
  };
  readonly orderBook: {
    readonly __typename?: 'OrderBook';
    readonly id: string;
    readonly state: Types.OrderBookState;
  };
};

export type OrderBook_InstitutionalDemand_AllocationSetPartsFragment = {
  readonly __typename?: 'AllocationSet';
  readonly id: string;
  readonly name: string;
  readonly isFinal: boolean;
  readonly isDefault: boolean;
  readonly isReleased: boolean;
  readonly shareTimestamp?: string | null;
  readonly author: {
    readonly __typename?: 'FirmInfo';
    readonly firmKey: string;
    readonly firmDisplayName: string;
    readonly firmName: string;
    readonly firmNameAbbreviation?: string | null;
  };
  readonly auditInfo: { readonly __typename?: 'AuditInformation'; readonly modifiedAt: string };
  readonly dealLayout: {
    readonly __typename?: 'OfferingDealLayout';
    readonly institutionalTarget?: number | null;
    readonly offeringSize?: number | null;
  };
  readonly sharedWithManagers?: ReadonlyArray<{
    readonly __typename?: 'AllocationSetShareInfo';
    readonly isActive: boolean;
    readonly sharedAt: string;
    readonly manager: {
      readonly __typename?: 'FirmInfo';
      readonly firmKey: string;
      readonly firmDisplayName: string;
      readonly firmName: string;
      readonly firmNameAbbreviation?: string | null;
    };
  } | null> | null;
};

export type OrderBook_InstitutionalDemand_InvestorExtensionSchemaPartsFragment = {
  readonly __typename?: 'InvestorExtensionSchema';
  readonly id: string;
  readonly category: Types.ExtendedDataCategory;
  readonly columnName?: string | null;
  readonly displayName?: string | null;
};

export type OrderBook_InstitutionalDemandRouteQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type OrderBook_InstitutionalDemandRouteQuery = {
  readonly __typename?: 'Query';
  readonly allocationSets: ReadonlyArray<{
    readonly __typename?: 'AllocationSet';
    readonly id: string;
    readonly name: string;
    readonly isFinal: boolean;
    readonly isDefault: boolean;
    readonly isReleased: boolean;
    readonly shareTimestamp?: string | null;
    readonly author: {
      readonly __typename?: 'FirmInfo';
      readonly firmKey: string;
      readonly firmDisplayName: string;
      readonly firmName: string;
      readonly firmNameAbbreviation?: string | null;
    };
    readonly auditInfo: { readonly __typename?: 'AuditInformation'; readonly modifiedAt: string };
    readonly dealLayout: {
      readonly __typename?: 'OfferingDealLayout';
      readonly institutionalTarget?: number | null;
      readonly offeringSize?: number | null;
    };
    readonly sharedWithManagers?: ReadonlyArray<{
      readonly __typename?: 'AllocationSetShareInfo';
      readonly isActive: boolean;
      readonly sharedAt: string;
      readonly manager: {
        readonly __typename?: 'FirmInfo';
        readonly firmKey: string;
        readonly firmDisplayName: string;
        readonly firmName: string;
        readonly firmNameAbbreviation?: string | null;
      };
    } | null> | null;
  }>;
  readonly publishedOffering: {
    readonly __typename?: 'OfferingProfile';
    readonly id: string;
    readonly status: Types.OfferingStatus;
    readonly pricingCurrencyCode: string;
    readonly derivedOfferPrice?: number | null;
    readonly initialFilingPriceRangeLow?: number | null;
    readonly initialFilingPriceRangeHigh?: number | null;
    readonly latestFilingPriceRangeLow?: number | null;
    readonly latestFilingPriceRangeHigh?: number | null;
    readonly prospectusDocuments: ReadonlyArray<{
      readonly __typename?: 'ProspectusDocumentProfile';
      readonly id: string;
    }>;
    readonly issuer: { readonly __typename?: 'Issuer'; readonly name: string };
    readonly syndicate: {
      readonly __typename?: 'SyndicateProfile';
      readonly managers: ReadonlyArray<{
        readonly __typename?: 'ManagerProfile';
        readonly id: string;
        readonly firmName: string;
        readonly firmNameAbbreviation?: string | null;
        readonly cmgEntityKey: string;
      }>;
    };
    readonly orderBook: {
      readonly __typename?: 'OrderBook';
      readonly id: string;
      readonly state: Types.OrderBookState;
    };
  };
  readonly investorExtensionSchema: ReadonlyArray<{
    readonly __typename?: 'InvestorExtensionSchema';
    readonly id: string;
    readonly category: Types.ExtendedDataCategory;
    readonly columnName?: string | null;
    readonly displayName?: string | null;
  }>;
};

export type OrderBook_InstitutionalDemand_ComplianceErrorCountQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  errorCodes:
    | ReadonlyArray<Types.InputMaybe<Types.Scalars['String']>>
    | Types.InputMaybe<Types.Scalars['String']>;
}>;

export type OrderBook_InstitutionalDemand_ComplianceErrorCountQuery = {
  readonly __typename?: 'Query';
  readonly syndicateInstitutionalGrid?: {
    readonly __typename?: 'SyndicateInstitutionalGridCollectionSegment';
    readonly summaries: {
      readonly __typename?: 'SyndicateInstitutionalGridSummaries';
      readonly totals: {
        readonly __typename?: 'SyndicateGridTotalsRow';
        readonly indicationCount: number;
      };
    };
  } | null;
};

export type OrderBook_InstitutionalDemand_FinalAllocationsQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  finalAllocationSetId: Types.Scalars['UUID'];
  min: Types.Scalars['Decimal'];
  max: Types.Scalars['Decimal'];
  increment: Types.Scalars['Decimal'];
  referencePrice?: Types.InputMaybe<Types.Scalars['Decimal']>;
  skip?: Types.InputMaybe<Types.Scalars['Int']>;
  take?: Types.InputMaybe<Types.Scalars['Int']>;
  where?: Types.InputMaybe<Types.SyndicateInstitutionalGridRowFilterInput>;
}>;

export type OrderBook_InstitutionalDemand_FinalAllocationsQuery = {
  readonly __typename?: 'Query';
  readonly syndicateInstitutionalGrid?: {
    readonly __typename?: 'SyndicateInstitutionalGridCollectionSegment';
    readonly items?: ReadonlyArray<{
      readonly __typename?: 'SyndicateInstitutionalGridRow';
      readonly id: string;
      readonly investor: { readonly __typename?: 'InvestorInfo'; readonly displayName: string };
      readonly allocations?: ReadonlyArray<{
        readonly __typename?: 'Allocation';
        readonly allocationSetId: string;
        readonly shares?: number | null;
      }> | null;
    }> | null;
    readonly pageInfo: {
      readonly __typename?: 'CollectionSegmentInfo';
      readonly hasNextPage: boolean;
    };
  } | null;
};

export type OrderBook_InstitutionalDemand_GridChangedSubscriptionVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type OrderBook_InstitutionalDemand_GridChangedSubscription = {
  readonly __typename?: 'Subscription';
  readonly syndicateInstitutionalGridChanged: {
    readonly __typename?: 'SyndicateInstitutionalGridChanged';
    readonly offeringId: string;
    readonly eventSource?: string | null;
  };
};

export type OrderBook_InstitutionalDemand_InvestorKeysQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  min: Types.Scalars['Decimal'];
  max: Types.Scalars['Decimal'];
  increment: Types.Scalars['Decimal'];
  referencePrice?: Types.InputMaybe<Types.Scalars['Decimal']>;
  skip?: Types.InputMaybe<Types.Scalars['Int']>;
  take?: Types.InputMaybe<Types.Scalars['Int']>;
  where?: Types.InputMaybe<Types.SyndicateInstitutionalGridRowFilterInput>;
}>;

export type OrderBook_InstitutionalDemand_InvestorKeysQuery = {
  readonly __typename?: 'Query';
  readonly syndicateInstitutionalGrid?: {
    readonly __typename?: 'SyndicateInstitutionalGridCollectionSegment';
    readonly items?: ReadonlyArray<{
      readonly __typename?: 'SyndicateInstitutionalGridRow';
      readonly id: string;
      readonly investor: {
        readonly __typename?: 'InvestorInfo';
        readonly displayName: string;
        readonly cmgEntityKey: string;
      };
    }> | null;
    readonly pageInfo: {
      readonly __typename?: 'CollectionSegmentInfo';
      readonly hasNextPage: boolean;
    };
  } | null;
};

export type OrderBook_InstitutionalDemand_MarkAsDuplicateMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['ID'];
  survivorId: Types.Scalars['UUID'];
  markAsDuplicateIds: ReadonlyArray<Types.Scalars['UUID']> | Types.Scalars['UUID'];
}>;

export type OrderBook_InstitutionalDemand_MarkAsDuplicateMutation = {
  readonly __typename?: 'Mutation';
  readonly markIndicationsAsDuplicate: {
    readonly __typename?: 'InstitutionalIndication';
    readonly id: string;
  };
};

export type OrderBook_InstitutionalDemand_IndicationDetailPartsFragment = {
  readonly __typename?: 'IndicationDetail';
  readonly status?: Types.IndicationStatus | null;
  readonly currencyCode?: string | null;
  readonly interestLevels?: ReadonlyArray<{
    readonly __typename?: 'InterestLevelDetail';
    readonly interestQuantity?: number | null;
    readonly interestUnit: Types.InterestLevelInterestUnit;
    readonly limitPrice?: number | null;
    readonly limitType?: Types.InterestLevelLimitType | null;
  }> | null;
};

export type OrderBook_InstitutionalDemand_AllocationSetDetailPartsFragment = {
  readonly __typename?: 'AllocationSetDetail';
  readonly id: string;
  readonly name: string;
  readonly isDraft: boolean;
};

export type OrderBook_InstitutionalDemand_AllocationChangeDetailPartsFragment = {
  readonly __typename?: 'AllocationChangeDetail';
  readonly old?: {
    readonly __typename?: 'AllocationRevisionDetail';
    readonly shares?: number | null;
  } | null;
  readonly new: {
    readonly __typename?: 'AllocationRevisionDetail';
    readonly shares?: number | null;
  };
};

export type OrderBook_InstitutionalDemand_SyndicateGridChangeSummaryPartsFragment = {
  readonly __typename?: 'SyndicateGridChangeSummary';
  readonly itemId: string;
  readonly batchId?: string | null;
  readonly offeringId: string;
  readonly changeType: Types.SyndicateGridChangeType;
  readonly investorFirm?: string | null;
  readonly manager?: string | null;
  readonly timestamp: string;
  readonly allocationRevisions?: ReadonlyArray<{
    readonly __typename?: 'AllocationChangeDetail';
    readonly old?: {
      readonly __typename?: 'AllocationRevisionDetail';
      readonly shares?: number | null;
    } | null;
    readonly new: {
      readonly __typename?: 'AllocationRevisionDetail';
      readonly shares?: number | null;
    };
  } | null> | null;
  readonly oldIndication?: {
    readonly __typename?: 'IndicationDetail';
    readonly status?: Types.IndicationStatus | null;
    readonly currencyCode?: string | null;
    readonly interestLevels?: ReadonlyArray<{
      readonly __typename?: 'InterestLevelDetail';
      readonly interestQuantity?: number | null;
      readonly interestUnit: Types.InterestLevelInterestUnit;
      readonly limitPrice?: number | null;
      readonly limitType?: Types.InterestLevelLimitType | null;
    }> | null;
  } | null;
  readonly newIndication?: {
    readonly __typename?: 'IndicationDetail';
    readonly status?: Types.IndicationStatus | null;
    readonly currencyCode?: string | null;
    readonly interestLevels?: ReadonlyArray<{
      readonly __typename?: 'InterestLevelDetail';
      readonly interestQuantity?: number | null;
      readonly interestUnit: Types.InterestLevelInterestUnit;
      readonly limitPrice?: number | null;
      readonly limitType?: Types.InterestLevelLimitType | null;
    }> | null;
  } | null;
  readonly newAllocationSet?: {
    readonly __typename?: 'AllocationSetDetail';
    readonly id: string;
    readonly name: string;
    readonly isDraft: boolean;
  } | null;
};

export type OrderBook_InstitutionalDemand_RecentUpdatesSubscriptionVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type OrderBook_InstitutionalDemand_RecentUpdatesSubscription = {
  readonly __typename?: 'Subscription';
  readonly syndicateInstitutionalGridChangedSummaries: {
    readonly __typename?: 'SyndicateGridChangeSummary';
    readonly itemId: string;
    readonly batchId?: string | null;
    readonly offeringId: string;
    readonly changeType: Types.SyndicateGridChangeType;
    readonly investorFirm?: string | null;
    readonly manager?: string | null;
    readonly timestamp: string;
    readonly allocationRevisions?: ReadonlyArray<{
      readonly __typename?: 'AllocationChangeDetail';
      readonly old?: {
        readonly __typename?: 'AllocationRevisionDetail';
        readonly shares?: number | null;
      } | null;
      readonly new: {
        readonly __typename?: 'AllocationRevisionDetail';
        readonly shares?: number | null;
      };
    } | null> | null;
    readonly oldIndication?: {
      readonly __typename?: 'IndicationDetail';
      readonly status?: Types.IndicationStatus | null;
      readonly currencyCode?: string | null;
      readonly interestLevels?: ReadonlyArray<{
        readonly __typename?: 'InterestLevelDetail';
        readonly interestQuantity?: number | null;
        readonly interestUnit: Types.InterestLevelInterestUnit;
        readonly limitPrice?: number | null;
        readonly limitType?: Types.InterestLevelLimitType | null;
      }> | null;
    } | null;
    readonly newIndication?: {
      readonly __typename?: 'IndicationDetail';
      readonly status?: Types.IndicationStatus | null;
      readonly currencyCode?: string | null;
      readonly interestLevels?: ReadonlyArray<{
        readonly __typename?: 'InterestLevelDetail';
        readonly interestQuantity?: number | null;
        readonly interestUnit: Types.InterestLevelInterestUnit;
        readonly limitPrice?: number | null;
        readonly limitType?: Types.InterestLevelLimitType | null;
      }> | null;
    } | null;
    readonly newAllocationSet?: {
      readonly __typename?: 'AllocationSetDetail';
      readonly id: string;
      readonly name: string;
      readonly isDraft: boolean;
    } | null;
  };
};

export type OrderBook_InstitutionalDemand_InstitutionalDemandSummaryMetricsPartsFragment = {
  readonly __typename?: 'InstitutionalDemandSummaryMetrics';
  readonly averageAllocation: number;
  readonly averageDemand: number;
  readonly fillRate: number;
  readonly limitToMarketRatio: number;
  readonly marketToLimitRatio: number;
  readonly subscription: number;
  readonly totalActiveNonPassIndicationCount: number;
  readonly totalAllocatedShares: number;
  readonly totalAllocationCount: number;
  readonly totalDemand: number;
};

export type OrderBook_InstitutionalDemand_AllocationTotalRowPartsFragment = {
  readonly __typename?: 'AllocationTotalRow';
  readonly allocationSetId: string;
  readonly shares: number;
};

export type OrderBook_InstitutionalDemand_GridTotalRowPartsFragment = {
  readonly __typename?: 'SyndicateGridTotalsRow';
  readonly indicationCount: number;
  readonly demandAtMarket: number;
  readonly demandMax: number;
  readonly demandLevels: ReadonlyArray<{
    readonly __typename?: 'DemandLevel';
    readonly price: number;
    readonly shares: number;
  }>;
  readonly allocations: ReadonlyArray<{
    readonly __typename?: 'AllocationTotalRow';
    readonly allocationSetId: string;
    readonly shares: number;
  }>;
};

export type OrderBook_InstitutionalDemand_SummariesQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  min: Types.Scalars['Decimal'];
  max: Types.Scalars['Decimal'];
  increment: Types.Scalars['Decimal'];
  referencePrice?: Types.InputMaybe<Types.Scalars['Decimal']>;
  where?: Types.InputMaybe<Types.SyndicateInstitutionalGridRowFilterInput>;
}>;

export type OrderBook_InstitutionalDemand_SummariesQuery = {
  readonly __typename?: 'Query';
  readonly syndicateInstitutionalGrid?: {
    readonly __typename?: 'SyndicateInstitutionalGridCollectionSegment';
    readonly summaries: {
      readonly __typename?: 'SyndicateInstitutionalGridSummaries';
      readonly summaryMetrics: {
        readonly __typename?: 'InstitutionalDemandSummaryMetrics';
        readonly averageAllocation: number;
        readonly averageDemand: number;
        readonly fillRate: number;
        readonly limitToMarketRatio: number;
        readonly marketToLimitRatio: number;
        readonly subscription: number;
        readonly totalActiveNonPassIndicationCount: number;
        readonly totalAllocatedShares: number;
        readonly totalAllocationCount: number;
        readonly totalDemand: number;
      };
      readonly totals: {
        readonly __typename?: 'SyndicateGridTotalsRow';
        readonly indicationCount: number;
        readonly demandAtMarket: number;
        readonly demandMax: number;
        readonly demandLevels: ReadonlyArray<{
          readonly __typename?: 'DemandLevel';
          readonly price: number;
          readonly shares: number;
        }>;
        readonly allocations: ReadonlyArray<{
          readonly __typename?: 'AllocationTotalRow';
          readonly allocationSetId: string;
          readonly shares: number;
        }>;
      };
    };
  } | null;
};

export type OrderBook_InstitutionalDemand_InvestorExtensionAttributesPartsFragment = {
  readonly __typename?: 'InvestorExtensionAttributes';
  readonly investorCrmKey: string;
  readonly compliance1?: string | null;
  readonly compliance2?: string | null;
  readonly compliance3?: string | null;
  readonly compliance4?: string | null;
  readonly compliance5?: string | null;
  readonly investorComments1?: string | null;
  readonly investorComments2?: string | null;
  readonly investorComments3?: string | null;
  readonly investorComments4?: string | null;
  readonly investorComments5?: string | null;
  readonly investorRegion1?: string | null;
  readonly investorRegion2?: string | null;
  readonly investorRegion3?: string | null;
  readonly investorRegion4?: string | null;
  readonly investorRegion5?: string | null;
  readonly investorType1?: string | null;
  readonly investorType2?: string | null;
  readonly investorType3?: string | null;
  readonly investorType4?: string | null;
  readonly investorType5?: string | null;
  readonly others1?: string | null;
  readonly others2?: string | null;
  readonly others3?: string | null;
  readonly others4?: string | null;
  readonly others5?: string | null;
  readonly revenue1?: string | null;
  readonly revenue2?: string | null;
  readonly revenue3?: string | null;
  readonly revenue4?: string | null;
  readonly revenue5?: string | null;
};

export type OrderBook_InstitutionalDemand_InvestorInfoPartsFragment = {
  readonly __typename?: 'InvestorInfo';
  readonly cmgEntityKey: string;
  readonly displayName: string;
  readonly firmName: string;
  readonly isOnPlatform: boolean;
  readonly bankInvestorKey?: string | null;
  readonly extensions?: {
    readonly __typename?: 'InvestorExtensionAttributes';
    readonly investorCrmKey: string;
    readonly compliance1?: string | null;
    readonly compliance2?: string | null;
    readonly compliance3?: string | null;
    readonly compliance4?: string | null;
    readonly compliance5?: string | null;
    readonly investorComments1?: string | null;
    readonly investorComments2?: string | null;
    readonly investorComments3?: string | null;
    readonly investorComments4?: string | null;
    readonly investorComments5?: string | null;
    readonly investorRegion1?: string | null;
    readonly investorRegion2?: string | null;
    readonly investorRegion3?: string | null;
    readonly investorRegion4?: string | null;
    readonly investorRegion5?: string | null;
    readonly investorType1?: string | null;
    readonly investorType2?: string | null;
    readonly investorType3?: string | null;
    readonly investorType4?: string | null;
    readonly investorType5?: string | null;
    readonly others1?: string | null;
    readonly others2?: string | null;
    readonly others3?: string | null;
    readonly others4?: string | null;
    readonly others5?: string | null;
    readonly revenue1?: string | null;
    readonly revenue2?: string | null;
    readonly revenue3?: string | null;
    readonly revenue4?: string | null;
    readonly revenue5?: string | null;
  } | null;
};

export type OrderBook_InstitutionalDemand_DemandLevelPartsFragment = {
  readonly __typename?: 'DemandLevel';
  readonly price: number;
  readonly shares: number;
};

export type OrderBook_InstitutionalDemand_RowAlertPartsFragment = {
  readonly __typename?: 'RowAlert';
  readonly severity: Types.AlertSeverity;
  readonly sortValue?: string | null;
  readonly alertCodes?: ReadonlyArray<string> | null;
};

export type OrderBook_InstitutionalDemand_IndicationAcknowledgementPartsFragment = {
  readonly __typename?: 'IndicationAcknowledgement';
  readonly isRevised: boolean;
  readonly manager: {
    readonly __typename?: 'FirmInfo';
    readonly firmKey: string;
    readonly firmDisplayName: string;
    readonly firmName: string;
    readonly firmNameAbbreviation?: string | null;
  };
};

export type OrderBook_InstitutionalDemand_UserInfoPartsFragment = {
  readonly __typename?: 'UserInfo';
  readonly name: string;
  readonly email?: string | null;
};

export type OrderBook_InstitutionalDemand_AllocationPartsFragment = {
  readonly __typename?: 'Allocation';
  readonly allocationSetId: string;
  readonly shares?: number | null;
};

export type OrderBook_InstitutionalDemand_InterestLevelPartsFragment = {
  readonly __typename?: 'InterestLevel';
  readonly interestQuantity: number;
  readonly interestUnit: Types.InterestLevelInterestUnit;
  readonly limitPrice?: number | null;
  readonly limitType?: Types.InterestLevelLimitType | null;
};

export type OrderBook_InstitutionalDemand_ProspectusDeliverySummaryPartsFragment = {
  readonly __typename?: 'ProspectusDeliverySummary';
  readonly failedEmailCount: number;
  readonly sentEmailCount: number;
  readonly totalEmailCount: number;
  readonly inProgressEmailCount: number;
  readonly overallStatusDisplayValue: string;
  readonly overallStatus: Types.ProspectusDeliveryStatus;
};

export type OrderBook_InstitutionalDemand_IndicationNotesPartsFragment = {
  readonly __typename?: 'IndicationNotes';
  readonly salesAndTrading?: string | null;
  readonly salesContact?: string | null;
  readonly salesDepartment?: string | null;
  readonly syndicate?: string | null;
};

export type OrderBook_InstitutionalDemand_GridRowPartsFragment = {
  readonly __typename?: 'SyndicateInstitutionalGridRow';
  readonly id: string;
  readonly type: Types.InstitutionalIndicationOrderType;
  readonly typeDisplayValue: string;
  readonly status: Types.IndicationStatus;
  readonly investorReplyStatus: Types.AllocationAcknowledgement;
  readonly demandAtMarket: number;
  readonly demandMax: number;
  readonly duplicateOfIndicationId?: string | null;
  readonly hasDuplicates: boolean;
  readonly coveringManagerNames?: string | null;
  readonly acknowledgedByFirmNames?: string | null;
  readonly currencyCode?: string | null;
  readonly attestationStatus?: Types.AttestationFormStatus | null;
  readonly salesCoverage?: ReadonlyArray<string> | null;
  readonly salesCoverageDisplayValue?: string | null;
  readonly alert: {
    readonly __typename?: 'RowAlert';
    readonly severity: Types.AlertSeverity;
    readonly sortValue?: string | null;
    readonly alertCodes?: ReadonlyArray<string> | null;
  };
  readonly investor: {
    readonly __typename?: 'InvestorInfo';
    readonly cmgEntityKey: string;
    readonly displayName: string;
    readonly firmName: string;
    readonly isOnPlatform: boolean;
    readonly bankInvestorKey?: string | null;
    readonly extensions?: {
      readonly __typename?: 'InvestorExtensionAttributes';
      readonly investorCrmKey: string;
      readonly compliance1?: string | null;
      readonly compliance2?: string | null;
      readonly compliance3?: string | null;
      readonly compliance4?: string | null;
      readonly compliance5?: string | null;
      readonly investorComments1?: string | null;
      readonly investorComments2?: string | null;
      readonly investorComments3?: string | null;
      readonly investorComments4?: string | null;
      readonly investorComments5?: string | null;
      readonly investorRegion1?: string | null;
      readonly investorRegion2?: string | null;
      readonly investorRegion3?: string | null;
      readonly investorRegion4?: string | null;
      readonly investorRegion5?: string | null;
      readonly investorType1?: string | null;
      readonly investorType2?: string | null;
      readonly investorType3?: string | null;
      readonly investorType4?: string | null;
      readonly investorType5?: string | null;
      readonly others1?: string | null;
      readonly others2?: string | null;
      readonly others3?: string | null;
      readonly others4?: string | null;
      readonly others5?: string | null;
      readonly revenue1?: string | null;
      readonly revenue2?: string | null;
      readonly revenue3?: string | null;
      readonly revenue4?: string | null;
      readonly revenue5?: string | null;
    } | null;
  };
  readonly bndAgent?: {
    readonly __typename?: 'FirmInfo';
    readonly firmKey: string;
    readonly firmDisplayName: string;
    readonly firmName: string;
    readonly firmNameAbbreviation?: string | null;
  } | null;
  readonly demandLevels: ReadonlyArray<{
    readonly __typename?: 'DemandLevel';
    readonly price: number;
    readonly shares: number;
  }>;
  readonly auditInfo: {
    readonly __typename?: 'AuditInformation';
    readonly createdAt: string;
    readonly modifiedAt: string;
    readonly createdBy?: {
      readonly __typename?: 'FirmInfo';
      readonly firmKey: string;
      readonly firmDisplayName: string;
      readonly firmName: string;
      readonly firmNameAbbreviation?: string | null;
    } | null;
    readonly modifiedBy?: {
      readonly __typename?: 'FirmInfo';
      readonly firmKey: string;
      readonly firmDisplayName: string;
      readonly firmName: string;
      readonly firmNameAbbreviation?: string | null;
    } | null;
  };
  readonly acknowledgements?: ReadonlyArray<{
    readonly __typename?: 'IndicationAcknowledgement';
    readonly isRevised: boolean;
    readonly manager: {
      readonly __typename?: 'FirmInfo';
      readonly firmKey: string;
      readonly firmDisplayName: string;
      readonly firmName: string;
      readonly firmNameAbbreviation?: string | null;
    };
  }> | null;
  readonly acknowledgedByUser?: {
    readonly __typename?: 'UserInfo';
    readonly name: string;
    readonly email?: string | null;
  } | null;
  readonly allocationAcknowledgedBy?: {
    readonly __typename?: 'UserInfo';
    readonly name: string;
    readonly email?: string | null;
  } | null;
  readonly allocations?: ReadonlyArray<{
    readonly __typename?: 'Allocation';
    readonly allocationSetId: string;
    readonly shares?: number | null;
  }> | null;
  readonly interestLevels?: ReadonlyArray<{
    readonly __typename?: 'InterestLevel';
    readonly interestQuantity: number;
    readonly interestUnit: Types.InterestLevelInterestUnit;
    readonly limitPrice?: number | null;
    readonly limitType?: Types.InterestLevelLimitType | null;
  }> | null;
  readonly prospectusDeliverySummary: {
    readonly __typename?: 'ProspectusDeliverySummary';
    readonly failedEmailCount: number;
    readonly sentEmailCount: number;
    readonly totalEmailCount: number;
    readonly inProgressEmailCount: number;
    readonly overallStatusDisplayValue: string;
    readonly overallStatus: Types.ProspectusDeliveryStatus;
  };
  readonly notes?: {
    readonly __typename?: 'IndicationNotes';
    readonly salesAndTrading?: string | null;
    readonly salesContact?: string | null;
    readonly salesDepartment?: string | null;
    readonly syndicate?: string | null;
  } | null;
};

export type OrderBook_InstitutionalDemand_SyndicateInstitutionalGridQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  min: Types.Scalars['Decimal'];
  max: Types.Scalars['Decimal'];
  increment: Types.Scalars['Decimal'];
  referencePrice?: Types.InputMaybe<Types.Scalars['Decimal']>;
  skip?: Types.InputMaybe<Types.Scalars['Int']>;
  take?: Types.InputMaybe<Types.Scalars['Int']>;
  order?: Types.InputMaybe<
    | ReadonlyArray<Types.SyndicateInstitutionalGridRowSortInput>
    | Types.SyndicateInstitutionalGridRowSortInput
  >;
  where?: Types.InputMaybe<Types.SyndicateInstitutionalGridRowFilterInput>;
}>;

export type OrderBook_InstitutionalDemand_SyndicateInstitutionalGridQuery = {
  readonly __typename?: 'Query';
  readonly syndicateInstitutionalGrid?: {
    readonly __typename?: 'SyndicateInstitutionalGridCollectionSegment';
    readonly items?: ReadonlyArray<{
      readonly __typename?: 'SyndicateInstitutionalGridRow';
      readonly id: string;
      readonly type: Types.InstitutionalIndicationOrderType;
      readonly typeDisplayValue: string;
      readonly status: Types.IndicationStatus;
      readonly investorReplyStatus: Types.AllocationAcknowledgement;
      readonly demandAtMarket: number;
      readonly demandMax: number;
      readonly duplicateOfIndicationId?: string | null;
      readonly hasDuplicates: boolean;
      readonly coveringManagerNames?: string | null;
      readonly acknowledgedByFirmNames?: string | null;
      readonly currencyCode?: string | null;
      readonly attestationStatus?: Types.AttestationFormStatus | null;
      readonly salesCoverage?: ReadonlyArray<string> | null;
      readonly salesCoverageDisplayValue?: string | null;
      readonly alert: {
        readonly __typename?: 'RowAlert';
        readonly severity: Types.AlertSeverity;
        readonly sortValue?: string | null;
        readonly alertCodes?: ReadonlyArray<string> | null;
      };
      readonly investor: {
        readonly __typename?: 'InvestorInfo';
        readonly cmgEntityKey: string;
        readonly displayName: string;
        readonly firmName: string;
        readonly isOnPlatform: boolean;
        readonly bankInvestorKey?: string | null;
        readonly extensions?: {
          readonly __typename?: 'InvestorExtensionAttributes';
          readonly investorCrmKey: string;
          readonly compliance1?: string | null;
          readonly compliance2?: string | null;
          readonly compliance3?: string | null;
          readonly compliance4?: string | null;
          readonly compliance5?: string | null;
          readonly investorComments1?: string | null;
          readonly investorComments2?: string | null;
          readonly investorComments3?: string | null;
          readonly investorComments4?: string | null;
          readonly investorComments5?: string | null;
          readonly investorRegion1?: string | null;
          readonly investorRegion2?: string | null;
          readonly investorRegion3?: string | null;
          readonly investorRegion4?: string | null;
          readonly investorRegion5?: string | null;
          readonly investorType1?: string | null;
          readonly investorType2?: string | null;
          readonly investorType3?: string | null;
          readonly investorType4?: string | null;
          readonly investorType5?: string | null;
          readonly others1?: string | null;
          readonly others2?: string | null;
          readonly others3?: string | null;
          readonly others4?: string | null;
          readonly others5?: string | null;
          readonly revenue1?: string | null;
          readonly revenue2?: string | null;
          readonly revenue3?: string | null;
          readonly revenue4?: string | null;
          readonly revenue5?: string | null;
        } | null;
      };
      readonly bndAgent?: {
        readonly __typename?: 'FirmInfo';
        readonly firmKey: string;
        readonly firmDisplayName: string;
        readonly firmName: string;
        readonly firmNameAbbreviation?: string | null;
      } | null;
      readonly demandLevels: ReadonlyArray<{
        readonly __typename?: 'DemandLevel';
        readonly price: number;
        readonly shares: number;
      }>;
      readonly auditInfo: {
        readonly __typename?: 'AuditInformation';
        readonly createdAt: string;
        readonly modifiedAt: string;
        readonly createdBy?: {
          readonly __typename?: 'FirmInfo';
          readonly firmKey: string;
          readonly firmDisplayName: string;
          readonly firmName: string;
          readonly firmNameAbbreviation?: string | null;
        } | null;
        readonly modifiedBy?: {
          readonly __typename?: 'FirmInfo';
          readonly firmKey: string;
          readonly firmDisplayName: string;
          readonly firmName: string;
          readonly firmNameAbbreviation?: string | null;
        } | null;
      };
      readonly acknowledgements?: ReadonlyArray<{
        readonly __typename?: 'IndicationAcknowledgement';
        readonly isRevised: boolean;
        readonly manager: {
          readonly __typename?: 'FirmInfo';
          readonly firmKey: string;
          readonly firmDisplayName: string;
          readonly firmName: string;
          readonly firmNameAbbreviation?: string | null;
        };
      }> | null;
      readonly acknowledgedByUser?: {
        readonly __typename?: 'UserInfo';
        readonly name: string;
        readonly email?: string | null;
      } | null;
      readonly allocationAcknowledgedBy?: {
        readonly __typename?: 'UserInfo';
        readonly name: string;
        readonly email?: string | null;
      } | null;
      readonly allocations?: ReadonlyArray<{
        readonly __typename?: 'Allocation';
        readonly allocationSetId: string;
        readonly shares?: number | null;
      }> | null;
      readonly interestLevels?: ReadonlyArray<{
        readonly __typename?: 'InterestLevel';
        readonly interestQuantity: number;
        readonly interestUnit: Types.InterestLevelInterestUnit;
        readonly limitPrice?: number | null;
        readonly limitType?: Types.InterestLevelLimitType | null;
      }> | null;
      readonly prospectusDeliverySummary: {
        readonly __typename?: 'ProspectusDeliverySummary';
        readonly failedEmailCount: number;
        readonly sentEmailCount: number;
        readonly totalEmailCount: number;
        readonly inProgressEmailCount: number;
        readonly overallStatusDisplayValue: string;
        readonly overallStatus: Types.ProspectusDeliveryStatus;
      };
      readonly notes?: {
        readonly __typename?: 'IndicationNotes';
        readonly salesAndTrading?: string | null;
        readonly salesContact?: string | null;
        readonly salesDepartment?: string | null;
        readonly syndicate?: string | null;
      } | null;
    }> | null;
  } | null;
};

export type OrderBook_InstitutionalDemand_GridGroupRowPartsFragment = {
  readonly __typename?: 'Group';
  readonly count: number;
  readonly groupKey?: string | null;
};

export type OrderBook_InstitutionalDemand_SyndicateInstitutionalGridGroupsQueryVariables =
  Types.Exact<{
    offeringId: Types.Scalars['UUID'];
    groupBy: Types.SyndicateGridGroupingColumn;
    min: Types.Scalars['Decimal'];
    max: Types.Scalars['Decimal'];
    increment: Types.Scalars['Decimal'];
    referencePrice?: Types.InputMaybe<Types.Scalars['Decimal']>;
    where?: Types.InputMaybe<Types.SyndicateInstitutionalGridRowFilterInput>;
  }>;

export type OrderBook_InstitutionalDemand_SyndicateInstitutionalGridGroupsQuery = {
  readonly __typename?: 'Query';
  readonly syndicateInstitutionalGridGroupKeys: ReadonlyArray<{
    readonly __typename?: 'Group';
    readonly count: number;
    readonly groupKey?: string | null;
  }>;
};

export type OrderBook_InstitutionalDemand_BulkUpdateAllocationsMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  shareQuantity: Types.Scalars['Long'];
  allocationSetIds: ReadonlyArray<Types.Scalars['UUID']> | Types.Scalars['UUID'];
  applyTo?: Types.InputMaybe<Types.SyndicateGridQueryInput>;
}>;

export type OrderBook_InstitutionalDemand_BulkUpdateAllocationsMutation = {
  readonly __typename?: 'Mutation';
  readonly bulkUpdateAllocations: {
    readonly __typename?: 'UpdatedAllocationSets';
    readonly updatedAllocationSetIds: ReadonlyArray<string>;
  };
};

export type OrderBook_InstitutionalDemand_UpdateDraftAllocationMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  indicationId: Types.Scalars['UUID'];
  allocationSetId: Types.Scalars['String'];
  shareQuantity?: Types.InputMaybe<Types.Scalars['Long']>;
}>;

export type OrderBook_InstitutionalDemand_UpdateDraftAllocationMutation = {
  readonly __typename?: 'Mutation';
  readonly updateDraftInstitutionalAllocation: {
    readonly __typename?: 'DraftAllocation';
    readonly indicationId: string;
  };
};

export type OrderBook_InstitutionalDemand_UpdateFinalAllocationMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  indicationId: Types.Scalars['UUID'];
  shareQuantity?: Types.InputMaybe<Types.Scalars['Long']>;
}>;

export type OrderBook_InstitutionalDemand_UpdateFinalAllocationMutation = {
  readonly __typename?: 'Mutation';
  readonly updateFinalInstitutionalAllocation: {
    readonly __typename?: 'FinalAllocation';
    readonly indicationId: string;
  };
};

export type OrderBook_InstitutionalDemand_BulkUpdateBndAgentMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  bndAgentKey?: Types.InputMaybe<Types.Scalars['CmgEntityKey']>;
  applyTo?: Types.InputMaybe<Types.SyndicateGridQueryInput>;
}>;

export type OrderBook_InstitutionalDemand_BulkUpdateBndAgentMutation = {
  readonly __typename?: 'Mutation';
  readonly bulkAssignBndAgent: {
    readonly __typename?: 'BulkAssignBndAgentPayload';
    readonly updatedIndicationIds: ReadonlyArray<string>;
  };
};

export type OrderBook_InstitutionalDemand_UpdateBndAgentMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  indicationId: Types.Scalars['UUID'];
  bndAgentKey: Types.Scalars['String'];
}>;

export type OrderBook_InstitutionalDemand_UpdateBndAgentMutation = {
  readonly __typename?: 'Mutation';
  readonly updateInstitutionalIndicationBillingAndDeliveryAgent: {
    readonly __typename?: 'BillingAndDeliveryAgent';
    readonly indicationId: string;
  };
};

export type OrderBook_InstitutionalDemand_UnassignBndAgentMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  indicationId: Types.Scalars['UUID'];
}>;

export type OrderBook_InstitutionalDemand_UnassignBndAgentMutation = {
  readonly __typename?: 'Mutation';
  readonly deleteInstitutionalIndicationBillingAndDeliveryAgent: {
    readonly __typename?: 'DeleteBillingAndDeliveryAgentResult';
    readonly indicationId: string;
  };
};

export const OrderBook_InstitutionalDemand_ManagerProfilePartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_ManagerProfileParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ManagerProfile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmNameAbbreviation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const OrderBook_InstitutionalDemand_PublishedOfferingPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_PublishedOfferingParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'OfferingProfile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingCurrencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'derivedOfferPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'initialFilingPriceRangeLow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'initialFilingPriceRangeHigh' } },
          { kind: 'Field', name: { kind: 'Name', value: 'latestFilingPriceRangeLow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'latestFilingPriceRangeHigh' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'prospectusDocuments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'issuer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'syndicate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'managers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'OrderBook_InstitutionalDemand_ManagerProfileParts',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orderBook' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_ManagerProfileParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ManagerProfile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmNameAbbreviation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const OrderBook_InstitutionalDemand_AllocationSetPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_AllocationSetParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AllocationSet' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFinal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isDefault' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isReleased' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'author' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FirmInfoParts' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'auditInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'modifiedAt' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dealLayout' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'institutionalTarget' } },
                { kind: 'Field', name: { kind: 'Name', value: 'offeringSize' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sharedWithManagers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'manager' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FirmInfoParts' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'sharedAt' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'shareTimestamp' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FirmInfoParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FirmInfo' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'firmKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmDisplayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmNameAbbreviation' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const OrderBook_InstitutionalDemand_InvestorExtensionSchemaPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_InvestorExtensionSchemaParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InvestorExtensionSchema' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'columnName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const OrderBook_InstitutionalDemand_AllocationChangeDetailPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_AllocationChangeDetailParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AllocationChangeDetail' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'old' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'shares' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'new' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'shares' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const OrderBook_InstitutionalDemand_IndicationDetailPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_IndicationDetailParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'IndicationDetail' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'interestLevels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'interestQuantity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'interestUnit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limitPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limitType' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const OrderBook_InstitutionalDemand_AllocationSetDetailPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_AllocationSetDetailParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AllocationSetDetail' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isDraft' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const OrderBook_InstitutionalDemand_SyndicateGridChangeSummaryPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'OrderBook_InstitutionalDemand_SyndicateGridChangeSummaryParts',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SyndicateGridChangeSummary' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'itemId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'batchId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'changeType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorFirm' } },
          { kind: 'Field', name: { kind: 'Name', value: 'manager' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allocationRevisions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'OrderBook_InstitutionalDemand_AllocationChangeDetailParts',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'oldIndication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'OrderBook_InstitutionalDemand_IndicationDetailParts',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'newIndication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'OrderBook_InstitutionalDemand_IndicationDetailParts',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'newAllocationSet' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'OrderBook_InstitutionalDemand_AllocationSetDetailParts',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_IndicationDetailParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'IndicationDetail' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'interestLevels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'interestQuantity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'interestUnit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limitPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limitType' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_AllocationSetDetailParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AllocationSetDetail' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isDraft' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_AllocationChangeDetailParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AllocationChangeDetail' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'old' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'shares' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'new' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'shares' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const OrderBook_InstitutionalDemand_InstitutionalDemandSummaryMetricsPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'OrderBook_InstitutionalDemand_InstitutionalDemandSummaryMetricsParts',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstitutionalDemandSummaryMetrics' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'averageAllocation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'averageDemand' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fillRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'limitToMarketRatio' } },
          { kind: 'Field', name: { kind: 'Name', value: 'marketToLimitRatio' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subscription' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalActiveNonPassIndicationCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalAllocatedShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalAllocationCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalDemand' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const OrderBook_InstitutionalDemand_DemandLevelPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_DemandLevelParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DemandLevel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const OrderBook_InstitutionalDemand_AllocationTotalRowPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_AllocationTotalRowParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AllocationTotalRow' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'allocationSetId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const OrderBook_InstitutionalDemand_GridTotalRowPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_GridTotalRowParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SyndicateGridTotalsRow' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'indicationCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'demandAtMarket' } },
          { kind: 'Field', name: { kind: 'Name', value: 'demandMax' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'demandLevels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_DemandLevelParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'OrderBook_InstitutionalDemand_AllocationTotalRowParts',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_AllocationTotalRowParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AllocationTotalRow' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'allocationSetId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_DemandLevelParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DemandLevel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const OrderBook_InstitutionalDemand_RowAlertPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_RowAlertParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RowAlert' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'severity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sortValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'alertCodes' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const OrderBook_InstitutionalDemand_InvestorExtensionAttributesPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'OrderBook_InstitutionalDemand_InvestorExtensionAttributesParts',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InvestorExtensionAttributes' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'investorCrmKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'compliance1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'compliance2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'compliance3' } },
          { kind: 'Field', name: { kind: 'Name', value: 'compliance4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'compliance5' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorComments1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorComments2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorComments3' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorComments4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorComments5' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorRegion1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorRegion2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorRegion3' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorRegion4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorRegion5' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorType1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorType2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorType3' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorType4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorType5' } },
          { kind: 'Field', name: { kind: 'Name', value: 'others1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'others2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'others3' } },
          { kind: 'Field', name: { kind: 'Name', value: 'others4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'others5' } },
          { kind: 'Field', name: { kind: 'Name', value: 'revenue1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'revenue2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'revenue3' } },
          { kind: 'Field', name: { kind: 'Name', value: 'revenue4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'revenue5' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const OrderBook_InstitutionalDemand_InvestorInfoPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_InvestorInfoParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'InvestorInfo' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isOnPlatform' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bankInvestorKey' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'extensions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'OrderBook_InstitutionalDemand_InvestorExtensionAttributesParts',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'OrderBook_InstitutionalDemand_InvestorExtensionAttributesParts',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InvestorExtensionAttributes' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'investorCrmKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'compliance1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'compliance2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'compliance3' } },
          { kind: 'Field', name: { kind: 'Name', value: 'compliance4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'compliance5' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorComments1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorComments2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorComments3' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorComments4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorComments5' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorRegion1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorRegion2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorRegion3' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorRegion4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorRegion5' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorType1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorType2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorType3' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorType4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorType5' } },
          { kind: 'Field', name: { kind: 'Name', value: 'others1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'others2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'others3' } },
          { kind: 'Field', name: { kind: 'Name', value: 'others4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'others5' } },
          { kind: 'Field', name: { kind: 'Name', value: 'revenue1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'revenue2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'revenue3' } },
          { kind: 'Field', name: { kind: 'Name', value: 'revenue4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'revenue5' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const OrderBook_InstitutionalDemand_IndicationAcknowledgementPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_IndicationAcknowledgementParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'IndicationAcknowledgement' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'isRevised' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'manager' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FirmInfoParts' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FirmInfoParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FirmInfo' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'firmKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmDisplayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmNameAbbreviation' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const OrderBook_InstitutionalDemand_UserInfoPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_UserInfoParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UserInfo' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const OrderBook_InstitutionalDemand_AllocationPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_AllocationParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Allocation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'allocationSetId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const OrderBook_InstitutionalDemand_InterestLevelPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_InterestLevelParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'InterestLevel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'interestQuantity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'interestUnit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'limitPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'limitType' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const OrderBook_InstitutionalDemand_ProspectusDeliverySummaryPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_ProspectusDeliverySummaryParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProspectusDeliverySummary' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'failedEmailCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sentEmailCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalEmailCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inProgressEmailCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overallStatusDisplayValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overallStatus' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const OrderBook_InstitutionalDemand_IndicationNotesPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_IndicationNotesParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'IndicationNotes' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'salesAndTrading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salesContact' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salesDepartment' } },
          { kind: 'Field', name: { kind: 'Name', value: 'syndicate' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const OrderBook_InstitutionalDemand_GridRowPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_GridRowParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SyndicateInstitutionalGridRow' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'typeDisplayValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorReplyStatus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'alert' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_RowAlertParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_InvestorInfoParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bndAgent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FirmInfoParts' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'demandAtMarket' } },
          { kind: 'Field', name: { kind: 'Name', value: 'demandMax' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'demandLevels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_DemandLevelParts' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'duplicateOfIndicationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasDuplicates' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'auditInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AuditInformationParts' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'acknowledgements' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'OrderBook_InstitutionalDemand_IndicationAcknowledgementParts',
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'coveringManagerNames' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'acknowledgedByUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_UserInfoParts' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'acknowledgedByFirmNames' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allocationAcknowledgedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_UserInfoParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_AllocationParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'interestLevels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_InterestLevelParts' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'prospectusDeliverySummary' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'OrderBook_InstitutionalDemand_ProspectusDeliverySummaryParts',
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'attestationStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salesCoverage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salesCoverageDisplayValue' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'notes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'OrderBook_InstitutionalDemand_IndicationNotesParts',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'OrderBook_InstitutionalDemand_InvestorExtensionAttributesParts',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InvestorExtensionAttributes' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'investorCrmKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'compliance1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'compliance2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'compliance3' } },
          { kind: 'Field', name: { kind: 'Name', value: 'compliance4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'compliance5' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorComments1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorComments2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorComments3' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorComments4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorComments5' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorRegion1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorRegion2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorRegion3' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorRegion4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorRegion5' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorType1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorType2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorType3' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorType4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorType5' } },
          { kind: 'Field', name: { kind: 'Name', value: 'others1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'others2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'others3' } },
          { kind: 'Field', name: { kind: 'Name', value: 'others4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'others5' } },
          { kind: 'Field', name: { kind: 'Name', value: 'revenue1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'revenue2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'revenue3' } },
          { kind: 'Field', name: { kind: 'Name', value: 'revenue4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'revenue5' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_InvestorInfoParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'InvestorInfo' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isOnPlatform' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bankInvestorKey' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'extensions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'OrderBook_InstitutionalDemand_InvestorExtensionAttributesParts',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_DemandLevelParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DemandLevel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_RowAlertParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RowAlert' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'severity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sortValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'alertCodes' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_IndicationAcknowledgementParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'IndicationAcknowledgement' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'isRevised' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'manager' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FirmInfoParts' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_UserInfoParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UserInfo' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_AllocationParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Allocation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'allocationSetId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_InterestLevelParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'InterestLevel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'interestQuantity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'interestUnit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'limitPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'limitType' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_ProspectusDeliverySummaryParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProspectusDeliverySummary' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'failedEmailCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sentEmailCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalEmailCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inProgressEmailCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overallStatusDisplayValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overallStatus' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_IndicationNotesParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'IndicationNotes' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'salesAndTrading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salesContact' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salesDepartment' } },
          { kind: 'Field', name: { kind: 'Name', value: 'syndicate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FirmInfoParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FirmInfo' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'firmKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmDisplayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmNameAbbreviation' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AuditInformationParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AuditInformation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modifiedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FirmInfoParts' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'modifiedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FirmInfoParts' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const OrderBook_InstitutionalDemand_GridGroupRowPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_GridGroupRowParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Group' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'count' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupKey' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const OrderBook_InstitutionalDemandRouteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'OrderBook_InstitutionalDemandRoute' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allocationSets' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_AllocationSetParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'publishedOffering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'OrderBook_InstitutionalDemand_PublishedOfferingParts',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investorExtensionSchema' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'OrderBook_InstitutionalDemand_InvestorExtensionSchemaParts',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_ManagerProfileParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ManagerProfile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmNameAbbreviation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_PublishedOfferingParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'OfferingProfile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingCurrencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'derivedOfferPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'initialFilingPriceRangeLow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'initialFilingPriceRangeHigh' } },
          { kind: 'Field', name: { kind: 'Name', value: 'latestFilingPriceRangeLow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'latestFilingPriceRangeHigh' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'prospectusDocuments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'issuer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'syndicate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'managers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'OrderBook_InstitutionalDemand_ManagerProfileParts',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orderBook' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_AllocationSetParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AllocationSet' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFinal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isDefault' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isReleased' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'author' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FirmInfoParts' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'auditInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'modifiedAt' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dealLayout' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'institutionalTarget' } },
                { kind: 'Field', name: { kind: 'Name', value: 'offeringSize' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sharedWithManagers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'manager' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FirmInfoParts' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'sharedAt' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'shareTimestamp' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_InvestorExtensionSchemaParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InvestorExtensionSchema' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'columnName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FirmInfoParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FirmInfo' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'firmKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmDisplayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmNameAbbreviation' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useOrderBook_InstitutionalDemandRouteQuery__
 *
 * To run a query within a React component, call `useOrderBook_InstitutionalDemandRouteQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderBook_InstitutionalDemandRouteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderBook_InstitutionalDemandRouteQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useOrderBook_InstitutionalDemandRouteQuery(
  baseOptions: Apollo.QueryHookOptions<
    OrderBook_InstitutionalDemandRouteQuery,
    OrderBook_InstitutionalDemandRouteQueryVariables
  > &
    (
      | { variables: OrderBook_InstitutionalDemandRouteQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OrderBook_InstitutionalDemandRouteQuery,
    OrderBook_InstitutionalDemandRouteQueryVariables
  >(OrderBook_InstitutionalDemandRouteDocument, options);
}
export function useOrderBook_InstitutionalDemandRouteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrderBook_InstitutionalDemandRouteQuery,
    OrderBook_InstitutionalDemandRouteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OrderBook_InstitutionalDemandRouteQuery,
    OrderBook_InstitutionalDemandRouteQueryVariables
  >(OrderBook_InstitutionalDemandRouteDocument, options);
}
export function useOrderBook_InstitutionalDemandRouteSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        OrderBook_InstitutionalDemandRouteQuery,
        OrderBook_InstitutionalDemandRouteQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    OrderBook_InstitutionalDemandRouteQuery,
    OrderBook_InstitutionalDemandRouteQueryVariables
  >(OrderBook_InstitutionalDemandRouteDocument, options);
}
export type OrderBook_InstitutionalDemandRouteQueryHookResult = ReturnType<
  typeof useOrderBook_InstitutionalDemandRouteQuery
>;
export type OrderBook_InstitutionalDemandRouteLazyQueryHookResult = ReturnType<
  typeof useOrderBook_InstitutionalDemandRouteLazyQuery
>;
export type OrderBook_InstitutionalDemandRouteSuspenseQueryHookResult = ReturnType<
  typeof useOrderBook_InstitutionalDemandRouteSuspenseQuery
>;
export type OrderBook_InstitutionalDemandRouteQueryResult = Apollo.QueryResult<
  OrderBook_InstitutionalDemandRouteQuery,
  OrderBook_InstitutionalDemandRouteQueryVariables
>;
export const OrderBook_InstitutionalDemand_ComplianceErrorCountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_ComplianceErrorCount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'errorCodes' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'syndicateInstitutionalGrid' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'min' },
                value: { kind: 'IntValue', value: '1' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'max' },
                value: { kind: 'IntValue', value: '1' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'increment' },
                value: { kind: 'IntValue', value: '1' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'alert' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'alertCodes' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'some' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: 'in' },
                                        value: {
                                          kind: 'Variable',
                                          name: { kind: 'Name', value: 'errorCodes' },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'summaries' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'totals' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'indicationCount' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useOrderBook_InstitutionalDemand_ComplianceErrorCountQuery__
 *
 * To run a query within a React component, call `useOrderBook_InstitutionalDemand_ComplianceErrorCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderBook_InstitutionalDemand_ComplianceErrorCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderBook_InstitutionalDemand_ComplianceErrorCountQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      errorCodes: // value for 'errorCodes'
 *   },
 * });
 */
export function useOrderBook_InstitutionalDemand_ComplianceErrorCountQuery(
  baseOptions: Apollo.QueryHookOptions<
    OrderBook_InstitutionalDemand_ComplianceErrorCountQuery,
    OrderBook_InstitutionalDemand_ComplianceErrorCountQueryVariables
  > &
    (
      | {
          variables: OrderBook_InstitutionalDemand_ComplianceErrorCountQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OrderBook_InstitutionalDemand_ComplianceErrorCountQuery,
    OrderBook_InstitutionalDemand_ComplianceErrorCountQueryVariables
  >(OrderBook_InstitutionalDemand_ComplianceErrorCountDocument, options);
}
export function useOrderBook_InstitutionalDemand_ComplianceErrorCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrderBook_InstitutionalDemand_ComplianceErrorCountQuery,
    OrderBook_InstitutionalDemand_ComplianceErrorCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OrderBook_InstitutionalDemand_ComplianceErrorCountQuery,
    OrderBook_InstitutionalDemand_ComplianceErrorCountQueryVariables
  >(OrderBook_InstitutionalDemand_ComplianceErrorCountDocument, options);
}
export function useOrderBook_InstitutionalDemand_ComplianceErrorCountSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        OrderBook_InstitutionalDemand_ComplianceErrorCountQuery,
        OrderBook_InstitutionalDemand_ComplianceErrorCountQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    OrderBook_InstitutionalDemand_ComplianceErrorCountQuery,
    OrderBook_InstitutionalDemand_ComplianceErrorCountQueryVariables
  >(OrderBook_InstitutionalDemand_ComplianceErrorCountDocument, options);
}
export type OrderBook_InstitutionalDemand_ComplianceErrorCountQueryHookResult = ReturnType<
  typeof useOrderBook_InstitutionalDemand_ComplianceErrorCountQuery
>;
export type OrderBook_InstitutionalDemand_ComplianceErrorCountLazyQueryHookResult = ReturnType<
  typeof useOrderBook_InstitutionalDemand_ComplianceErrorCountLazyQuery
>;
export type OrderBook_InstitutionalDemand_ComplianceErrorCountSuspenseQueryHookResult = ReturnType<
  typeof useOrderBook_InstitutionalDemand_ComplianceErrorCountSuspenseQuery
>;
export type OrderBook_InstitutionalDemand_ComplianceErrorCountQueryResult = Apollo.QueryResult<
  OrderBook_InstitutionalDemand_ComplianceErrorCountQuery,
  OrderBook_InstitutionalDemand_ComplianceErrorCountQueryVariables
>;
export const OrderBook_InstitutionalDemand_FinalAllocationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_FinalAllocations' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'finalAllocationSetId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'min' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Decimal' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'max' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Decimal' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'increment' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Decimal' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'referencePrice' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Decimal' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'take' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'SyndicateInstitutionalGridRowFilterInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'syndicateInstitutionalGrid' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'min' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'min' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'max' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'max' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'increment' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'increment' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'referencePrice' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'referencePrice' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'take' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'take' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'allocationSetIds' },
                value: {
                  kind: 'ListValue',
                  values: [
                    { kind: 'Variable', name: { kind: 'Name', value: 'finalAllocationSetId' } },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'investor' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'allocations' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'allocationSetId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useOrderBook_InstitutionalDemand_FinalAllocationsQuery__
 *
 * To run a query within a React component, call `useOrderBook_InstitutionalDemand_FinalAllocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderBook_InstitutionalDemand_FinalAllocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderBook_InstitutionalDemand_FinalAllocationsQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      finalAllocationSetId: // value for 'finalAllocationSetId'
 *      min: // value for 'min'
 *      max: // value for 'max'
 *      increment: // value for 'increment'
 *      referencePrice: // value for 'referencePrice'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useOrderBook_InstitutionalDemand_FinalAllocationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    OrderBook_InstitutionalDemand_FinalAllocationsQuery,
    OrderBook_InstitutionalDemand_FinalAllocationsQueryVariables
  > &
    (
      | { variables: OrderBook_InstitutionalDemand_FinalAllocationsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OrderBook_InstitutionalDemand_FinalAllocationsQuery,
    OrderBook_InstitutionalDemand_FinalAllocationsQueryVariables
  >(OrderBook_InstitutionalDemand_FinalAllocationsDocument, options);
}
export function useOrderBook_InstitutionalDemand_FinalAllocationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrderBook_InstitutionalDemand_FinalAllocationsQuery,
    OrderBook_InstitutionalDemand_FinalAllocationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OrderBook_InstitutionalDemand_FinalAllocationsQuery,
    OrderBook_InstitutionalDemand_FinalAllocationsQueryVariables
  >(OrderBook_InstitutionalDemand_FinalAllocationsDocument, options);
}
export function useOrderBook_InstitutionalDemand_FinalAllocationsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        OrderBook_InstitutionalDemand_FinalAllocationsQuery,
        OrderBook_InstitutionalDemand_FinalAllocationsQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    OrderBook_InstitutionalDemand_FinalAllocationsQuery,
    OrderBook_InstitutionalDemand_FinalAllocationsQueryVariables
  >(OrderBook_InstitutionalDemand_FinalAllocationsDocument, options);
}
export type OrderBook_InstitutionalDemand_FinalAllocationsQueryHookResult = ReturnType<
  typeof useOrderBook_InstitutionalDemand_FinalAllocationsQuery
>;
export type OrderBook_InstitutionalDemand_FinalAllocationsLazyQueryHookResult = ReturnType<
  typeof useOrderBook_InstitutionalDemand_FinalAllocationsLazyQuery
>;
export type OrderBook_InstitutionalDemand_FinalAllocationsSuspenseQueryHookResult = ReturnType<
  typeof useOrderBook_InstitutionalDemand_FinalAllocationsSuspenseQuery
>;
export type OrderBook_InstitutionalDemand_FinalAllocationsQueryResult = Apollo.QueryResult<
  OrderBook_InstitutionalDemand_FinalAllocationsQuery,
  OrderBook_InstitutionalDemand_FinalAllocationsQueryVariables
>;
export const OrderBook_InstitutionalDemand_GridChangedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_GridChanged' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'syndicateInstitutionalGridChanged' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'offeringId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'eventSource' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useOrderBook_InstitutionalDemand_GridChangedSubscription__
 *
 * To run a query within a React component, call `useOrderBook_InstitutionalDemand_GridChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOrderBook_InstitutionalDemand_GridChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderBook_InstitutionalDemand_GridChangedSubscription({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useOrderBook_InstitutionalDemand_GridChangedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    OrderBook_InstitutionalDemand_GridChangedSubscription,
    OrderBook_InstitutionalDemand_GridChangedSubscriptionVariables
  > &
    (
      | {
          variables: OrderBook_InstitutionalDemand_GridChangedSubscriptionVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    OrderBook_InstitutionalDemand_GridChangedSubscription,
    OrderBook_InstitutionalDemand_GridChangedSubscriptionVariables
  >(OrderBook_InstitutionalDemand_GridChangedDocument, options);
}
export type OrderBook_InstitutionalDemand_GridChangedSubscriptionHookResult = ReturnType<
  typeof useOrderBook_InstitutionalDemand_GridChangedSubscription
>;
export type OrderBook_InstitutionalDemand_GridChangedSubscriptionResult =
  Apollo.SubscriptionResult<OrderBook_InstitutionalDemand_GridChangedSubscription>;
export const OrderBook_InstitutionalDemand_InvestorKeysDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_InvestorKeys' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'min' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Decimal' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'max' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Decimal' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'increment' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Decimal' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'referencePrice' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Decimal' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'take' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'SyndicateInstitutionalGridRowFilterInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'syndicateInstitutionalGrid' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'min' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'min' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'max' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'max' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'increment' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'increment' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'referencePrice' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'referencePrice' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'take' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'take' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'investor' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useOrderBook_InstitutionalDemand_InvestorKeysQuery__
 *
 * To run a query within a React component, call `useOrderBook_InstitutionalDemand_InvestorKeysQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderBook_InstitutionalDemand_InvestorKeysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderBook_InstitutionalDemand_InvestorKeysQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      min: // value for 'min'
 *      max: // value for 'max'
 *      increment: // value for 'increment'
 *      referencePrice: // value for 'referencePrice'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useOrderBook_InstitutionalDemand_InvestorKeysQuery(
  baseOptions: Apollo.QueryHookOptions<
    OrderBook_InstitutionalDemand_InvestorKeysQuery,
    OrderBook_InstitutionalDemand_InvestorKeysQueryVariables
  > &
    (
      | { variables: OrderBook_InstitutionalDemand_InvestorKeysQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OrderBook_InstitutionalDemand_InvestorKeysQuery,
    OrderBook_InstitutionalDemand_InvestorKeysQueryVariables
  >(OrderBook_InstitutionalDemand_InvestorKeysDocument, options);
}
export function useOrderBook_InstitutionalDemand_InvestorKeysLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrderBook_InstitutionalDemand_InvestorKeysQuery,
    OrderBook_InstitutionalDemand_InvestorKeysQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OrderBook_InstitutionalDemand_InvestorKeysQuery,
    OrderBook_InstitutionalDemand_InvestorKeysQueryVariables
  >(OrderBook_InstitutionalDemand_InvestorKeysDocument, options);
}
export function useOrderBook_InstitutionalDemand_InvestorKeysSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        OrderBook_InstitutionalDemand_InvestorKeysQuery,
        OrderBook_InstitutionalDemand_InvestorKeysQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    OrderBook_InstitutionalDemand_InvestorKeysQuery,
    OrderBook_InstitutionalDemand_InvestorKeysQueryVariables
  >(OrderBook_InstitutionalDemand_InvestorKeysDocument, options);
}
export type OrderBook_InstitutionalDemand_InvestorKeysQueryHookResult = ReturnType<
  typeof useOrderBook_InstitutionalDemand_InvestorKeysQuery
>;
export type OrderBook_InstitutionalDemand_InvestorKeysLazyQueryHookResult = ReturnType<
  typeof useOrderBook_InstitutionalDemand_InvestorKeysLazyQuery
>;
export type OrderBook_InstitutionalDemand_InvestorKeysSuspenseQueryHookResult = ReturnType<
  typeof useOrderBook_InstitutionalDemand_InvestorKeysSuspenseQuery
>;
export type OrderBook_InstitutionalDemand_InvestorKeysQueryResult = Apollo.QueryResult<
  OrderBook_InstitutionalDemand_InvestorKeysQuery,
  OrderBook_InstitutionalDemand_InvestorKeysQueryVariables
>;
export const OrderBook_InstitutionalDemand_MarkAsDuplicateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_MarkAsDuplicate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'survivorId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'markAsDuplicateIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'markIndicationsAsDuplicate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'survivorId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'survivorId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'markAsDuplicateIds' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'markAsDuplicateIds' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type OrderBook_InstitutionalDemand_MarkAsDuplicateMutationFn = Apollo.MutationFunction<
  OrderBook_InstitutionalDemand_MarkAsDuplicateMutation,
  OrderBook_InstitutionalDemand_MarkAsDuplicateMutationVariables
>;

/**
 * __useOrderBook_InstitutionalDemand_MarkAsDuplicateMutation__
 *
 * To run a mutation, you first call `useOrderBook_InstitutionalDemand_MarkAsDuplicateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderBook_InstitutionalDemand_MarkAsDuplicateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderBookInstitutionalDemandMarkAsDuplicateMutation, { data, loading, error }] = useOrderBook_InstitutionalDemand_MarkAsDuplicateMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      survivorId: // value for 'survivorId'
 *      markAsDuplicateIds: // value for 'markAsDuplicateIds'
 *   },
 * });
 */
export function useOrderBook_InstitutionalDemand_MarkAsDuplicateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OrderBook_InstitutionalDemand_MarkAsDuplicateMutation,
    OrderBook_InstitutionalDemand_MarkAsDuplicateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OrderBook_InstitutionalDemand_MarkAsDuplicateMutation,
    OrderBook_InstitutionalDemand_MarkAsDuplicateMutationVariables
  >(OrderBook_InstitutionalDemand_MarkAsDuplicateDocument, options);
}
export type OrderBook_InstitutionalDemand_MarkAsDuplicateMutationHookResult = ReturnType<
  typeof useOrderBook_InstitutionalDemand_MarkAsDuplicateMutation
>;
export type OrderBook_InstitutionalDemand_MarkAsDuplicateMutationResult =
  Apollo.MutationResult<OrderBook_InstitutionalDemand_MarkAsDuplicateMutation>;
export type OrderBook_InstitutionalDemand_MarkAsDuplicateMutationOptions =
  Apollo.BaseMutationOptions<
    OrderBook_InstitutionalDemand_MarkAsDuplicateMutation,
    OrderBook_InstitutionalDemand_MarkAsDuplicateMutationVariables
  >;
export const OrderBook_InstitutionalDemand_RecentUpdatesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_RecentUpdates' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'syndicateInstitutionalGridChangedSummaries' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'OrderBook_InstitutionalDemand_SyndicateGridChangeSummaryParts',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_IndicationDetailParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'IndicationDetail' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'interestLevels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'interestQuantity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'interestUnit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limitPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limitType' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_AllocationSetDetailParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AllocationSetDetail' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isDraft' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_AllocationChangeDetailParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AllocationChangeDetail' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'old' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'shares' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'new' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'shares' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'OrderBook_InstitutionalDemand_SyndicateGridChangeSummaryParts',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SyndicateGridChangeSummary' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'itemId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'batchId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'changeType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorFirm' } },
          { kind: 'Field', name: { kind: 'Name', value: 'manager' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allocationRevisions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'OrderBook_InstitutionalDemand_AllocationChangeDetailParts',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'oldIndication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'OrderBook_InstitutionalDemand_IndicationDetailParts',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'newIndication' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'OrderBook_InstitutionalDemand_IndicationDetailParts',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'newAllocationSet' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'OrderBook_InstitutionalDemand_AllocationSetDetailParts',
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useOrderBook_InstitutionalDemand_RecentUpdatesSubscription__
 *
 * To run a query within a React component, call `useOrderBook_InstitutionalDemand_RecentUpdatesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOrderBook_InstitutionalDemand_RecentUpdatesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderBook_InstitutionalDemand_RecentUpdatesSubscription({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useOrderBook_InstitutionalDemand_RecentUpdatesSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    OrderBook_InstitutionalDemand_RecentUpdatesSubscription,
    OrderBook_InstitutionalDemand_RecentUpdatesSubscriptionVariables
  > &
    (
      | {
          variables: OrderBook_InstitutionalDemand_RecentUpdatesSubscriptionVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    OrderBook_InstitutionalDemand_RecentUpdatesSubscription,
    OrderBook_InstitutionalDemand_RecentUpdatesSubscriptionVariables
  >(OrderBook_InstitutionalDemand_RecentUpdatesDocument, options);
}
export type OrderBook_InstitutionalDemand_RecentUpdatesSubscriptionHookResult = ReturnType<
  typeof useOrderBook_InstitutionalDemand_RecentUpdatesSubscription
>;
export type OrderBook_InstitutionalDemand_RecentUpdatesSubscriptionResult =
  Apollo.SubscriptionResult<OrderBook_InstitutionalDemand_RecentUpdatesSubscription>;
export const OrderBook_InstitutionalDemand_SummariesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_Summaries' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'min' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Decimal' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'max' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Decimal' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'increment' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Decimal' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'referencePrice' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Decimal' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'SyndicateInstitutionalGridRowFilterInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'syndicateInstitutionalGrid' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'min' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'min' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'max' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'max' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'increment' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'increment' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'referencePrice' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'referencePrice' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'IntValue', value: '0' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'take' },
                value: { kind: 'IntValue', value: '0' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'summaries' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'summaryMetrics' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value:
                                  'OrderBook_InstitutionalDemand_InstitutionalDemandSummaryMetricsParts',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'totals' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'OrderBook_InstitutionalDemand_GridTotalRowParts',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'OrderBook_InstitutionalDemand_InstitutionalDemandSummaryMetricsParts',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstitutionalDemandSummaryMetrics' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'averageAllocation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'averageDemand' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fillRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'limitToMarketRatio' } },
          { kind: 'Field', name: { kind: 'Name', value: 'marketToLimitRatio' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subscription' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalActiveNonPassIndicationCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalAllocatedShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalAllocationCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalDemand' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_AllocationTotalRowParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AllocationTotalRow' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'allocationSetId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_GridTotalRowParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SyndicateGridTotalsRow' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'indicationCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'demandAtMarket' } },
          { kind: 'Field', name: { kind: 'Name', value: 'demandMax' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'demandLevels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_DemandLevelParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'OrderBook_InstitutionalDemand_AllocationTotalRowParts',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_DemandLevelParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DemandLevel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useOrderBook_InstitutionalDemand_SummariesQuery__
 *
 * To run a query within a React component, call `useOrderBook_InstitutionalDemand_SummariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderBook_InstitutionalDemand_SummariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderBook_InstitutionalDemand_SummariesQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      min: // value for 'min'
 *      max: // value for 'max'
 *      increment: // value for 'increment'
 *      referencePrice: // value for 'referencePrice'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useOrderBook_InstitutionalDemand_SummariesQuery(
  baseOptions: Apollo.QueryHookOptions<
    OrderBook_InstitutionalDemand_SummariesQuery,
    OrderBook_InstitutionalDemand_SummariesQueryVariables
  > &
    (
      | { variables: OrderBook_InstitutionalDemand_SummariesQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OrderBook_InstitutionalDemand_SummariesQuery,
    OrderBook_InstitutionalDemand_SummariesQueryVariables
  >(OrderBook_InstitutionalDemand_SummariesDocument, options);
}
export function useOrderBook_InstitutionalDemand_SummariesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrderBook_InstitutionalDemand_SummariesQuery,
    OrderBook_InstitutionalDemand_SummariesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OrderBook_InstitutionalDemand_SummariesQuery,
    OrderBook_InstitutionalDemand_SummariesQueryVariables
  >(OrderBook_InstitutionalDemand_SummariesDocument, options);
}
export function useOrderBook_InstitutionalDemand_SummariesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        OrderBook_InstitutionalDemand_SummariesQuery,
        OrderBook_InstitutionalDemand_SummariesQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    OrderBook_InstitutionalDemand_SummariesQuery,
    OrderBook_InstitutionalDemand_SummariesQueryVariables
  >(OrderBook_InstitutionalDemand_SummariesDocument, options);
}
export type OrderBook_InstitutionalDemand_SummariesQueryHookResult = ReturnType<
  typeof useOrderBook_InstitutionalDemand_SummariesQuery
>;
export type OrderBook_InstitutionalDemand_SummariesLazyQueryHookResult = ReturnType<
  typeof useOrderBook_InstitutionalDemand_SummariesLazyQuery
>;
export type OrderBook_InstitutionalDemand_SummariesSuspenseQueryHookResult = ReturnType<
  typeof useOrderBook_InstitutionalDemand_SummariesSuspenseQuery
>;
export type OrderBook_InstitutionalDemand_SummariesQueryResult = Apollo.QueryResult<
  OrderBook_InstitutionalDemand_SummariesQuery,
  OrderBook_InstitutionalDemand_SummariesQueryVariables
>;
export const OrderBook_InstitutionalDemand_SyndicateInstitutionalGridDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_SyndicateInstitutionalGrid' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'min' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Decimal' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'max' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Decimal' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'increment' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Decimal' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'referencePrice' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Decimal' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'take' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'SyndicateInstitutionalGridRowSortInput' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'SyndicateInstitutionalGridRowFilterInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'syndicateInstitutionalGrid' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'min' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'min' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'max' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'max' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'increment' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'increment' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'referencePrice' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'referencePrice' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'take' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'take' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_GridRowParts' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'OrderBook_InstitutionalDemand_InvestorExtensionAttributesParts',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InvestorExtensionAttributes' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'investorCrmKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'compliance1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'compliance2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'compliance3' } },
          { kind: 'Field', name: { kind: 'Name', value: 'compliance4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'compliance5' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorComments1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorComments2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorComments3' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorComments4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorComments5' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorRegion1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorRegion2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorRegion3' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorRegion4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorRegion5' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorType1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorType2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorType3' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorType4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorType5' } },
          { kind: 'Field', name: { kind: 'Name', value: 'others1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'others2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'others3' } },
          { kind: 'Field', name: { kind: 'Name', value: 'others4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'others5' } },
          { kind: 'Field', name: { kind: 'Name', value: 'revenue1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'revenue2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'revenue3' } },
          { kind: 'Field', name: { kind: 'Name', value: 'revenue4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'revenue5' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_InvestorInfoParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'InvestorInfo' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isOnPlatform' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bankInvestorKey' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'extensions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'OrderBook_InstitutionalDemand_InvestorExtensionAttributesParts',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_DemandLevelParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DemandLevel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_RowAlertParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RowAlert' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'severity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sortValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'alertCodes' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_IndicationAcknowledgementParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'IndicationAcknowledgement' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'isRevised' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'manager' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FirmInfoParts' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_UserInfoParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UserInfo' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_AllocationParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Allocation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'allocationSetId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_InterestLevelParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'InterestLevel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'interestQuantity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'interestUnit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'limitPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'limitType' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_ProspectusDeliverySummaryParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProspectusDeliverySummary' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'failedEmailCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sentEmailCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalEmailCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inProgressEmailCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overallStatusDisplayValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overallStatus' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_IndicationNotesParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'IndicationNotes' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'salesAndTrading' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salesContact' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salesDepartment' } },
          { kind: 'Field', name: { kind: 'Name', value: 'syndicate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_GridRowParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SyndicateInstitutionalGridRow' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'typeDisplayValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorReplyStatus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'alert' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_RowAlertParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_InvestorInfoParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bndAgent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FirmInfoParts' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'demandAtMarket' } },
          { kind: 'Field', name: { kind: 'Name', value: 'demandMax' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'demandLevels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_DemandLevelParts' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'duplicateOfIndicationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasDuplicates' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'auditInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AuditInformationParts' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'acknowledgements' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'OrderBook_InstitutionalDemand_IndicationAcknowledgementParts',
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'coveringManagerNames' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'acknowledgedByUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_UserInfoParts' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'acknowledgedByFirmNames' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allocationAcknowledgedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_UserInfoParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_AllocationParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'interestLevels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_InterestLevelParts' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'prospectusDeliverySummary' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'OrderBook_InstitutionalDemand_ProspectusDeliverySummaryParts',
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'attestationStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salesCoverage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salesCoverageDisplayValue' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'notes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'OrderBook_InstitutionalDemand_IndicationNotesParts',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FirmInfoParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FirmInfo' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'firmKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmDisplayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmNameAbbreviation' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AuditInformationParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AuditInformation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modifiedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FirmInfoParts' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'modifiedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FirmInfoParts' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useOrderBook_InstitutionalDemand_SyndicateInstitutionalGridQuery__
 *
 * To run a query within a React component, call `useOrderBook_InstitutionalDemand_SyndicateInstitutionalGridQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderBook_InstitutionalDemand_SyndicateInstitutionalGridQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderBook_InstitutionalDemand_SyndicateInstitutionalGridQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      min: // value for 'min'
 *      max: // value for 'max'
 *      increment: // value for 'increment'
 *      referencePrice: // value for 'referencePrice'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      order: // value for 'order'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useOrderBook_InstitutionalDemand_SyndicateInstitutionalGridQuery(
  baseOptions: Apollo.QueryHookOptions<
    OrderBook_InstitutionalDemand_SyndicateInstitutionalGridQuery,
    OrderBook_InstitutionalDemand_SyndicateInstitutionalGridQueryVariables
  > &
    (
      | {
          variables: OrderBook_InstitutionalDemand_SyndicateInstitutionalGridQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OrderBook_InstitutionalDemand_SyndicateInstitutionalGridQuery,
    OrderBook_InstitutionalDemand_SyndicateInstitutionalGridQueryVariables
  >(OrderBook_InstitutionalDemand_SyndicateInstitutionalGridDocument, options);
}
export function useOrderBook_InstitutionalDemand_SyndicateInstitutionalGridLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrderBook_InstitutionalDemand_SyndicateInstitutionalGridQuery,
    OrderBook_InstitutionalDemand_SyndicateInstitutionalGridQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OrderBook_InstitutionalDemand_SyndicateInstitutionalGridQuery,
    OrderBook_InstitutionalDemand_SyndicateInstitutionalGridQueryVariables
  >(OrderBook_InstitutionalDemand_SyndicateInstitutionalGridDocument, options);
}
export function useOrderBook_InstitutionalDemand_SyndicateInstitutionalGridSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        OrderBook_InstitutionalDemand_SyndicateInstitutionalGridQuery,
        OrderBook_InstitutionalDemand_SyndicateInstitutionalGridQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    OrderBook_InstitutionalDemand_SyndicateInstitutionalGridQuery,
    OrderBook_InstitutionalDemand_SyndicateInstitutionalGridQueryVariables
  >(OrderBook_InstitutionalDemand_SyndicateInstitutionalGridDocument, options);
}
export type OrderBook_InstitutionalDemand_SyndicateInstitutionalGridQueryHookResult = ReturnType<
  typeof useOrderBook_InstitutionalDemand_SyndicateInstitutionalGridQuery
>;
export type OrderBook_InstitutionalDemand_SyndicateInstitutionalGridLazyQueryHookResult =
  ReturnType<typeof useOrderBook_InstitutionalDemand_SyndicateInstitutionalGridLazyQuery>;
export type OrderBook_InstitutionalDemand_SyndicateInstitutionalGridSuspenseQueryHookResult =
  ReturnType<typeof useOrderBook_InstitutionalDemand_SyndicateInstitutionalGridSuspenseQuery>;
export type OrderBook_InstitutionalDemand_SyndicateInstitutionalGridQueryResult =
  Apollo.QueryResult<
    OrderBook_InstitutionalDemand_SyndicateInstitutionalGridQuery,
    OrderBook_InstitutionalDemand_SyndicateInstitutionalGridQueryVariables
  >;
export const OrderBook_InstitutionalDemand_SyndicateInstitutionalGridGroupsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {
        kind: 'Name',
        value: 'OrderBook_InstitutionalDemand_SyndicateInstitutionalGridGroups',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'groupBy' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SyndicateGridGroupingColumn' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'min' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Decimal' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'max' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Decimal' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'increment' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Decimal' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'referencePrice' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Decimal' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'SyndicateInstitutionalGridRowFilterInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'syndicateInstitutionalGridGroupKeys' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'groupBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'min' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'min' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'max' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'max' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'increment' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'increment' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'referencePrice' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'referencePrice' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_GridGroupRowParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_GridGroupRowParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Group' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'count' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupKey' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useOrderBook_InstitutionalDemand_SyndicateInstitutionalGridGroupsQuery__
 *
 * To run a query within a React component, call `useOrderBook_InstitutionalDemand_SyndicateInstitutionalGridGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderBook_InstitutionalDemand_SyndicateInstitutionalGridGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderBook_InstitutionalDemand_SyndicateInstitutionalGridGroupsQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      groupBy: // value for 'groupBy'
 *      min: // value for 'min'
 *      max: // value for 'max'
 *      increment: // value for 'increment'
 *      referencePrice: // value for 'referencePrice'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useOrderBook_InstitutionalDemand_SyndicateInstitutionalGridGroupsQuery(
  baseOptions: Apollo.QueryHookOptions<
    OrderBook_InstitutionalDemand_SyndicateInstitutionalGridGroupsQuery,
    OrderBook_InstitutionalDemand_SyndicateInstitutionalGridGroupsQueryVariables
  > &
    (
      | {
          variables: OrderBook_InstitutionalDemand_SyndicateInstitutionalGridGroupsQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OrderBook_InstitutionalDemand_SyndicateInstitutionalGridGroupsQuery,
    OrderBook_InstitutionalDemand_SyndicateInstitutionalGridGroupsQueryVariables
  >(OrderBook_InstitutionalDemand_SyndicateInstitutionalGridGroupsDocument, options);
}
export function useOrderBook_InstitutionalDemand_SyndicateInstitutionalGridGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrderBook_InstitutionalDemand_SyndicateInstitutionalGridGroupsQuery,
    OrderBook_InstitutionalDemand_SyndicateInstitutionalGridGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OrderBook_InstitutionalDemand_SyndicateInstitutionalGridGroupsQuery,
    OrderBook_InstitutionalDemand_SyndicateInstitutionalGridGroupsQueryVariables
  >(OrderBook_InstitutionalDemand_SyndicateInstitutionalGridGroupsDocument, options);
}
export function useOrderBook_InstitutionalDemand_SyndicateInstitutionalGridGroupsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        OrderBook_InstitutionalDemand_SyndicateInstitutionalGridGroupsQuery,
        OrderBook_InstitutionalDemand_SyndicateInstitutionalGridGroupsQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    OrderBook_InstitutionalDemand_SyndicateInstitutionalGridGroupsQuery,
    OrderBook_InstitutionalDemand_SyndicateInstitutionalGridGroupsQueryVariables
  >(OrderBook_InstitutionalDemand_SyndicateInstitutionalGridGroupsDocument, options);
}
export type OrderBook_InstitutionalDemand_SyndicateInstitutionalGridGroupsQueryHookResult =
  ReturnType<typeof useOrderBook_InstitutionalDemand_SyndicateInstitutionalGridGroupsQuery>;
export type OrderBook_InstitutionalDemand_SyndicateInstitutionalGridGroupsLazyQueryHookResult =
  ReturnType<typeof useOrderBook_InstitutionalDemand_SyndicateInstitutionalGridGroupsLazyQuery>;
export type OrderBook_InstitutionalDemand_SyndicateInstitutionalGridGroupsSuspenseQueryHookResult =
  ReturnType<typeof useOrderBook_InstitutionalDemand_SyndicateInstitutionalGridGroupsSuspenseQuery>;
export type OrderBook_InstitutionalDemand_SyndicateInstitutionalGridGroupsQueryResult =
  Apollo.QueryResult<
    OrderBook_InstitutionalDemand_SyndicateInstitutionalGridGroupsQuery,
    OrderBook_InstitutionalDemand_SyndicateInstitutionalGridGroupsQueryVariables
  >;
export const OrderBook_InstitutionalDemand_BulkUpdateAllocationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_BulkUpdateAllocations' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'shareQuantity' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Long' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'allocationSetIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'applyTo' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'SyndicateGridQueryInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bulkUpdateAllocations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'allocation' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'shareQuantity' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'shareQuantity' } },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'allocationSetIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'allocationSetIds' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'applyTo' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'applyTo' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAllocationSetIds' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type OrderBook_InstitutionalDemand_BulkUpdateAllocationsMutationFn = Apollo.MutationFunction<
  OrderBook_InstitutionalDemand_BulkUpdateAllocationsMutation,
  OrderBook_InstitutionalDemand_BulkUpdateAllocationsMutationVariables
>;

/**
 * __useOrderBook_InstitutionalDemand_BulkUpdateAllocationsMutation__
 *
 * To run a mutation, you first call `useOrderBook_InstitutionalDemand_BulkUpdateAllocationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderBook_InstitutionalDemand_BulkUpdateAllocationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderBookInstitutionalDemandBulkUpdateAllocationsMutation, { data, loading, error }] = useOrderBook_InstitutionalDemand_BulkUpdateAllocationsMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      shareQuantity: // value for 'shareQuantity'
 *      allocationSetIds: // value for 'allocationSetIds'
 *      applyTo: // value for 'applyTo'
 *   },
 * });
 */
export function useOrderBook_InstitutionalDemand_BulkUpdateAllocationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OrderBook_InstitutionalDemand_BulkUpdateAllocationsMutation,
    OrderBook_InstitutionalDemand_BulkUpdateAllocationsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OrderBook_InstitutionalDemand_BulkUpdateAllocationsMutation,
    OrderBook_InstitutionalDemand_BulkUpdateAllocationsMutationVariables
  >(OrderBook_InstitutionalDemand_BulkUpdateAllocationsDocument, options);
}
export type OrderBook_InstitutionalDemand_BulkUpdateAllocationsMutationHookResult = ReturnType<
  typeof useOrderBook_InstitutionalDemand_BulkUpdateAllocationsMutation
>;
export type OrderBook_InstitutionalDemand_BulkUpdateAllocationsMutationResult =
  Apollo.MutationResult<OrderBook_InstitutionalDemand_BulkUpdateAllocationsMutation>;
export type OrderBook_InstitutionalDemand_BulkUpdateAllocationsMutationOptions =
  Apollo.BaseMutationOptions<
    OrderBook_InstitutionalDemand_BulkUpdateAllocationsMutation,
    OrderBook_InstitutionalDemand_BulkUpdateAllocationsMutationVariables
  >;
export const OrderBook_InstitutionalDemand_UpdateDraftAllocationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_UpdateDraftAllocation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'indicationId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'allocationSetId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'shareQuantity' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Long' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateDraftInstitutionalAllocation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'indicationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'indicationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'setId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'allocationSetId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'shareQuantity' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'shareQuantity' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'indicationId' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type OrderBook_InstitutionalDemand_UpdateDraftAllocationMutationFn = Apollo.MutationFunction<
  OrderBook_InstitutionalDemand_UpdateDraftAllocationMutation,
  OrderBook_InstitutionalDemand_UpdateDraftAllocationMutationVariables
>;

/**
 * __useOrderBook_InstitutionalDemand_UpdateDraftAllocationMutation__
 *
 * To run a mutation, you first call `useOrderBook_InstitutionalDemand_UpdateDraftAllocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderBook_InstitutionalDemand_UpdateDraftAllocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderBookInstitutionalDemandUpdateDraftAllocationMutation, { data, loading, error }] = useOrderBook_InstitutionalDemand_UpdateDraftAllocationMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      indicationId: // value for 'indicationId'
 *      allocationSetId: // value for 'allocationSetId'
 *      shareQuantity: // value for 'shareQuantity'
 *   },
 * });
 */
export function useOrderBook_InstitutionalDemand_UpdateDraftAllocationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OrderBook_InstitutionalDemand_UpdateDraftAllocationMutation,
    OrderBook_InstitutionalDemand_UpdateDraftAllocationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OrderBook_InstitutionalDemand_UpdateDraftAllocationMutation,
    OrderBook_InstitutionalDemand_UpdateDraftAllocationMutationVariables
  >(OrderBook_InstitutionalDemand_UpdateDraftAllocationDocument, options);
}
export type OrderBook_InstitutionalDemand_UpdateDraftAllocationMutationHookResult = ReturnType<
  typeof useOrderBook_InstitutionalDemand_UpdateDraftAllocationMutation
>;
export type OrderBook_InstitutionalDemand_UpdateDraftAllocationMutationResult =
  Apollo.MutationResult<OrderBook_InstitutionalDemand_UpdateDraftAllocationMutation>;
export type OrderBook_InstitutionalDemand_UpdateDraftAllocationMutationOptions =
  Apollo.BaseMutationOptions<
    OrderBook_InstitutionalDemand_UpdateDraftAllocationMutation,
    OrderBook_InstitutionalDemand_UpdateDraftAllocationMutationVariables
  >;
export const OrderBook_InstitutionalDemand_UpdateFinalAllocationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_UpdateFinalAllocation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'indicationId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'shareQuantity' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Long' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateFinalInstitutionalAllocation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'indicationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'indicationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'shareQuantity' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'shareQuantity' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'indicationId' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type OrderBook_InstitutionalDemand_UpdateFinalAllocationMutationFn = Apollo.MutationFunction<
  OrderBook_InstitutionalDemand_UpdateFinalAllocationMutation,
  OrderBook_InstitutionalDemand_UpdateFinalAllocationMutationVariables
>;

/**
 * __useOrderBook_InstitutionalDemand_UpdateFinalAllocationMutation__
 *
 * To run a mutation, you first call `useOrderBook_InstitutionalDemand_UpdateFinalAllocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderBook_InstitutionalDemand_UpdateFinalAllocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderBookInstitutionalDemandUpdateFinalAllocationMutation, { data, loading, error }] = useOrderBook_InstitutionalDemand_UpdateFinalAllocationMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      indicationId: // value for 'indicationId'
 *      shareQuantity: // value for 'shareQuantity'
 *   },
 * });
 */
export function useOrderBook_InstitutionalDemand_UpdateFinalAllocationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OrderBook_InstitutionalDemand_UpdateFinalAllocationMutation,
    OrderBook_InstitutionalDemand_UpdateFinalAllocationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OrderBook_InstitutionalDemand_UpdateFinalAllocationMutation,
    OrderBook_InstitutionalDemand_UpdateFinalAllocationMutationVariables
  >(OrderBook_InstitutionalDemand_UpdateFinalAllocationDocument, options);
}
export type OrderBook_InstitutionalDemand_UpdateFinalAllocationMutationHookResult = ReturnType<
  typeof useOrderBook_InstitutionalDemand_UpdateFinalAllocationMutation
>;
export type OrderBook_InstitutionalDemand_UpdateFinalAllocationMutationResult =
  Apollo.MutationResult<OrderBook_InstitutionalDemand_UpdateFinalAllocationMutation>;
export type OrderBook_InstitutionalDemand_UpdateFinalAllocationMutationOptions =
  Apollo.BaseMutationOptions<
    OrderBook_InstitutionalDemand_UpdateFinalAllocationMutation,
    OrderBook_InstitutionalDemand_UpdateFinalAllocationMutationVariables
  >;
export const OrderBook_InstitutionalDemand_BulkUpdateBndAgentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_BulkUpdateBndAgent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bndAgentKey' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'CmgEntityKey' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'applyTo' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'SyndicateGridQueryInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bulkAssignBndAgent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'bndAgentKey' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'bndAgentKey' } },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'applyTo' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'applyTo' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'updatedIndicationIds' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type OrderBook_InstitutionalDemand_BulkUpdateBndAgentMutationFn = Apollo.MutationFunction<
  OrderBook_InstitutionalDemand_BulkUpdateBndAgentMutation,
  OrderBook_InstitutionalDemand_BulkUpdateBndAgentMutationVariables
>;

/**
 * __useOrderBook_InstitutionalDemand_BulkUpdateBndAgentMutation__
 *
 * To run a mutation, you first call `useOrderBook_InstitutionalDemand_BulkUpdateBndAgentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderBook_InstitutionalDemand_BulkUpdateBndAgentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderBookInstitutionalDemandBulkUpdateBndAgentMutation, { data, loading, error }] = useOrderBook_InstitutionalDemand_BulkUpdateBndAgentMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      bndAgentKey: // value for 'bndAgentKey'
 *      applyTo: // value for 'applyTo'
 *   },
 * });
 */
export function useOrderBook_InstitutionalDemand_BulkUpdateBndAgentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OrderBook_InstitutionalDemand_BulkUpdateBndAgentMutation,
    OrderBook_InstitutionalDemand_BulkUpdateBndAgentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OrderBook_InstitutionalDemand_BulkUpdateBndAgentMutation,
    OrderBook_InstitutionalDemand_BulkUpdateBndAgentMutationVariables
  >(OrderBook_InstitutionalDemand_BulkUpdateBndAgentDocument, options);
}
export type OrderBook_InstitutionalDemand_BulkUpdateBndAgentMutationHookResult = ReturnType<
  typeof useOrderBook_InstitutionalDemand_BulkUpdateBndAgentMutation
>;
export type OrderBook_InstitutionalDemand_BulkUpdateBndAgentMutationResult =
  Apollo.MutationResult<OrderBook_InstitutionalDemand_BulkUpdateBndAgentMutation>;
export type OrderBook_InstitutionalDemand_BulkUpdateBndAgentMutationOptions =
  Apollo.BaseMutationOptions<
    OrderBook_InstitutionalDemand_BulkUpdateBndAgentMutation,
    OrderBook_InstitutionalDemand_BulkUpdateBndAgentMutationVariables
  >;
export const OrderBook_InstitutionalDemand_UpdateBndAgentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_UpdateBndAgent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'indicationId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bndAgentKey' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateInstitutionalIndicationBillingAndDeliveryAgent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'indicationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'indicationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'managerCmgEntityKey' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'bndAgentKey' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'indicationId' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type OrderBook_InstitutionalDemand_UpdateBndAgentMutationFn = Apollo.MutationFunction<
  OrderBook_InstitutionalDemand_UpdateBndAgentMutation,
  OrderBook_InstitutionalDemand_UpdateBndAgentMutationVariables
>;

/**
 * __useOrderBook_InstitutionalDemand_UpdateBndAgentMutation__
 *
 * To run a mutation, you first call `useOrderBook_InstitutionalDemand_UpdateBndAgentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderBook_InstitutionalDemand_UpdateBndAgentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderBookInstitutionalDemandUpdateBndAgentMutation, { data, loading, error }] = useOrderBook_InstitutionalDemand_UpdateBndAgentMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      indicationId: // value for 'indicationId'
 *      bndAgentKey: // value for 'bndAgentKey'
 *   },
 * });
 */
export function useOrderBook_InstitutionalDemand_UpdateBndAgentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OrderBook_InstitutionalDemand_UpdateBndAgentMutation,
    OrderBook_InstitutionalDemand_UpdateBndAgentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OrderBook_InstitutionalDemand_UpdateBndAgentMutation,
    OrderBook_InstitutionalDemand_UpdateBndAgentMutationVariables
  >(OrderBook_InstitutionalDemand_UpdateBndAgentDocument, options);
}
export type OrderBook_InstitutionalDemand_UpdateBndAgentMutationHookResult = ReturnType<
  typeof useOrderBook_InstitutionalDemand_UpdateBndAgentMutation
>;
export type OrderBook_InstitutionalDemand_UpdateBndAgentMutationResult =
  Apollo.MutationResult<OrderBook_InstitutionalDemand_UpdateBndAgentMutation>;
export type OrderBook_InstitutionalDemand_UpdateBndAgentMutationOptions =
  Apollo.BaseMutationOptions<
    OrderBook_InstitutionalDemand_UpdateBndAgentMutation,
    OrderBook_InstitutionalDemand_UpdateBndAgentMutationVariables
  >;
export const OrderBook_InstitutionalDemand_UnassignBndAgentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'OrderBook_InstitutionalDemand_UnassignBndAgent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'indicationId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteInstitutionalIndicationBillingAndDeliveryAgent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'indicationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'indicationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'indicationId' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type OrderBook_InstitutionalDemand_UnassignBndAgentMutationFn = Apollo.MutationFunction<
  OrderBook_InstitutionalDemand_UnassignBndAgentMutation,
  OrderBook_InstitutionalDemand_UnassignBndAgentMutationVariables
>;

/**
 * __useOrderBook_InstitutionalDemand_UnassignBndAgentMutation__
 *
 * To run a mutation, you first call `useOrderBook_InstitutionalDemand_UnassignBndAgentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderBook_InstitutionalDemand_UnassignBndAgentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderBookInstitutionalDemandUnassignBndAgentMutation, { data, loading, error }] = useOrderBook_InstitutionalDemand_UnassignBndAgentMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      indicationId: // value for 'indicationId'
 *   },
 * });
 */
export function useOrderBook_InstitutionalDemand_UnassignBndAgentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OrderBook_InstitutionalDemand_UnassignBndAgentMutation,
    OrderBook_InstitutionalDemand_UnassignBndAgentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OrderBook_InstitutionalDemand_UnassignBndAgentMutation,
    OrderBook_InstitutionalDemand_UnassignBndAgentMutationVariables
  >(OrderBook_InstitutionalDemand_UnassignBndAgentDocument, options);
}
export type OrderBook_InstitutionalDemand_UnassignBndAgentMutationHookResult = ReturnType<
  typeof useOrderBook_InstitutionalDemand_UnassignBndAgentMutation
>;
export type OrderBook_InstitutionalDemand_UnassignBndAgentMutationResult =
  Apollo.MutationResult<OrderBook_InstitutionalDemand_UnassignBndAgentMutation>;
export type OrderBook_InstitutionalDemand_UnassignBndAgentMutationOptions =
  Apollo.BaseMutationOptions<
    OrderBook_InstitutionalDemand_UnassignBndAgentMutation,
    OrderBook_InstitutionalDemand_UnassignBndAgentMutationVariables
  >;

import {
  UseWithSubscriptionOptions,
  useWithLazySubscription,
  useWithSubscription,
} from '../../../../../graphql/with-subscription';

type UseWithOrderBook_InstitutionalDemand_GridChangedSubscriptionOptions<
  TQueryData extends {},
  TQueryVariables extends Apollo.OperationVariables
> = Omit<
  UseWithSubscriptionOptions<
    OrderBook_InstitutionalDemand_GridChangedSubscription,
    OrderBook_InstitutionalDemand_GridChangedSubscriptionVariables,
    TQueryData,
    TQueryVariables
  >,
  'document' | 'clientName'
>;

export const useWithOrderBook_InstitutionalDemand_GridChangedSubscription = <
  TQueryData extends {},
  TQueryVariables extends Apollo.OperationVariables
>(
  queryResults: Apollo.QueryResult<TQueryData, TQueryVariables>,
  options: UseWithOrderBook_InstitutionalDemand_GridChangedSubscriptionOptions<
    TQueryData,
    TQueryVariables
  >
) => {
  return useWithSubscription(queryResults, {
    ...options,
    document: OrderBook_InstitutionalDemand_GridChangedDocument,
  });
};

export const useWithOrderBook_InstitutionalDemand_GridChangedLazySubscription = <
  TQueryData extends {},
  TQueryVariables extends Apollo.OperationVariables
>(
  queryResults: Apollo.LazyQueryResultTuple<TQueryData, TQueryVariables>,
  options: UseWithOrderBook_InstitutionalDemand_GridChangedSubscriptionOptions<
    TQueryData,
    TQueryVariables
  >
) => {
  return useWithLazySubscription(queryResults, {
    ...options,
    document: OrderBook_InstitutionalDemand_GridChangedDocument,
  });
};

type UseWithOrderBook_InstitutionalDemand_RecentUpdatesSubscriptionOptions<
  TQueryData extends {},
  TQueryVariables extends Apollo.OperationVariables
> = Omit<
  UseWithSubscriptionOptions<
    OrderBook_InstitutionalDemand_RecentUpdatesSubscription,
    OrderBook_InstitutionalDemand_RecentUpdatesSubscriptionVariables,
    TQueryData,
    TQueryVariables
  >,
  'document' | 'clientName'
>;

export const useWithOrderBook_InstitutionalDemand_RecentUpdatesSubscription = <
  TQueryData extends {},
  TQueryVariables extends Apollo.OperationVariables
>(
  queryResults: Apollo.QueryResult<TQueryData, TQueryVariables>,
  options: UseWithOrderBook_InstitutionalDemand_RecentUpdatesSubscriptionOptions<
    TQueryData,
    TQueryVariables
  >
) => {
  return useWithSubscription(queryResults, {
    ...options,
    document: OrderBook_InstitutionalDemand_RecentUpdatesDocument,
  });
};

export const useWithOrderBook_InstitutionalDemand_RecentUpdatesLazySubscription = <
  TQueryData extends {},
  TQueryVariables extends Apollo.OperationVariables
>(
  queryResults: Apollo.LazyQueryResultTuple<TQueryData, TQueryVariables>,
  options: UseWithOrderBook_InstitutionalDemand_RecentUpdatesSubscriptionOptions<
    TQueryData,
    TQueryVariables
  >
) => {
  return useWithLazySubscription(queryResults, {
    ...options,
    document: OrderBook_InstitutionalDemand_RecentUpdatesDocument,
  });
};

import { makeMockResponse } from '../../../../../graphql/mock-response';

export const makeOrderBook_InstitutionalDemandRouteMockResponse = makeMockResponse<
  OrderBook_InstitutionalDemandRouteQueryVariables,
  OrderBook_InstitutionalDemandRouteQuery
>(OrderBook_InstitutionalDemandRouteDocument);

export const makeOrderBook_InstitutionalDemand_ComplianceErrorCountMockResponse = makeMockResponse<
  OrderBook_InstitutionalDemand_ComplianceErrorCountQueryVariables,
  OrderBook_InstitutionalDemand_ComplianceErrorCountQuery
>(OrderBook_InstitutionalDemand_ComplianceErrorCountDocument);

export const makeOrderBook_InstitutionalDemand_FinalAllocationsMockResponse = makeMockResponse<
  OrderBook_InstitutionalDemand_FinalAllocationsQueryVariables,
  OrderBook_InstitutionalDemand_FinalAllocationsQuery
>(OrderBook_InstitutionalDemand_FinalAllocationsDocument);

export const makeOrderBook_InstitutionalDemand_InvestorKeysMockResponse = makeMockResponse<
  OrderBook_InstitutionalDemand_InvestorKeysQueryVariables,
  OrderBook_InstitutionalDemand_InvestorKeysQuery
>(OrderBook_InstitutionalDemand_InvestorKeysDocument);

export const makeOrderBook_InstitutionalDemand_SummariesMockResponse = makeMockResponse<
  OrderBook_InstitutionalDemand_SummariesQueryVariables,
  OrderBook_InstitutionalDemand_SummariesQuery
>(OrderBook_InstitutionalDemand_SummariesDocument);

export const makeOrderBook_InstitutionalDemand_SyndicateInstitutionalGridMockResponse =
  makeMockResponse<
    OrderBook_InstitutionalDemand_SyndicateInstitutionalGridQueryVariables,
    OrderBook_InstitutionalDemand_SyndicateInstitutionalGridQuery
  >(OrderBook_InstitutionalDemand_SyndicateInstitutionalGridDocument);

export const makeOrderBook_InstitutionalDemand_SyndicateInstitutionalGridGroupsMockResponse =
  makeMockResponse<
    OrderBook_InstitutionalDemand_SyndicateInstitutionalGridGroupsQueryVariables,
    OrderBook_InstitutionalDemand_SyndicateInstitutionalGridGroupsQuery
  >(OrderBook_InstitutionalDemand_SyndicateInstitutionalGridGroupsDocument);
