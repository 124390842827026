/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InstitutionalDemand_ComplianceTable_InvestorDeliveryStatusPartsFragment = {
  readonly __typename?: 'InvestorDeliveryStatus';
  readonly id: string;
  readonly offeringId: string;
  readonly cmgEntityKey: string;
  readonly overallDeliveryStatus: Types.ProspectusDeliveryStatus;
  readonly sentEmailCount: number;
  readonly totalEmailCount: number;
  readonly failedEmailCount: number;
  readonly inProgressEmailCount: number;
};

export type InstitutionalDemand_ComplianceTableQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type InstitutionalDemand_ComplianceTableQuery = {
  readonly __typename?: 'Query';
  readonly publishedOffering: {
    readonly __typename?: 'OfferingProfile';
    readonly id: string;
    readonly type: Types.OfferingType;
    readonly syndicate: {
      readonly __typename?: 'SyndicateProfile';
      readonly managers: ReadonlyArray<{
        readonly __typename?: 'ManagerProfile';
        readonly cmgEntityKey: string;
        readonly firmName: string;
        readonly firmNameAbbreviation?: string | null;
      }>;
    };
    readonly orderBook: {
      readonly __typename?: 'OrderBook';
      readonly id: string;
      readonly institutionalIndications: ReadonlyArray<{
        readonly __typename?: 'InstitutionalIndication';
        readonly id: string;
        readonly status: Types.IndicationStatus;
        readonly type: Types.InstitutionalIndicationOrderType;
        readonly submittedByCmgEntityKey: string;
        readonly billingAndDeliveryAgentCmgEntityKey?: string | null;
        readonly investorInformation: {
          readonly __typename?: 'InvestorInformation';
          readonly bankInvestorName?: string | null;
          readonly cmgEntityName: string;
          readonly cmgEntityKey: string;
        };
        readonly finalAllocation?: {
          readonly __typename?: 'FinalAllocation';
          readonly id: string;
          readonly shareQuantity?: number | null;
        } | null;
        readonly attestationStatus: {
          readonly __typename?: 'AttestationStatus';
          readonly status: Types.AttestationFormStatus;
          readonly cmgEntityKey: string;
        };
        readonly investorProspectusDeliveryStatus?: {
          readonly __typename?: 'InvestorDeliveryStatus';
          readonly id: string;
          readonly offeringId: string;
          readonly cmgEntityKey: string;
          readonly overallDeliveryStatus: Types.ProspectusDeliveryStatus;
          readonly sentEmailCount: number;
          readonly totalEmailCount: number;
          readonly failedEmailCount: number;
          readonly inProgressEmailCount: number;
        } | null;
      }>;
    };
  };
};

export const InstitutionalDemand_ComplianceTable_InvestorDeliveryStatusPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'InstitutionalDemand_ComplianceTable_InvestorDeliveryStatusParts',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'InvestorDeliveryStatus' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overallDeliveryStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sentEmailCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalEmailCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'failedEmailCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inProgressEmailCount' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const InstitutionalDemand_ComplianceTableDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'InstitutionalDemand_ComplianceTable' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'publishedOffering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'syndicate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'managers' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'firmNameAbbreviation' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'orderBook' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'institutionalIndications' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'submittedByCmgEntityKey' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'investorInformation' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'bankInvestorName' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'billingAndDeliveryAgentCmgEntityKey' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'finalAllocation' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'shareQuantity' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'attestationStatus' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'investorProspectusDeliveryStatus' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value:
                                        'InstitutionalDemand_ComplianceTable_InvestorDeliveryStatusParts',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'InstitutionalDemand_ComplianceTable_InvestorDeliveryStatusParts',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'InvestorDeliveryStatus' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overallDeliveryStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sentEmailCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalEmailCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'failedEmailCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inProgressEmailCount' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useInstitutionalDemand_ComplianceTableQuery__
 *
 * To run a query within a React component, call `useInstitutionalDemand_ComplianceTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useInstitutionalDemand_ComplianceTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInstitutionalDemand_ComplianceTableQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useInstitutionalDemand_ComplianceTableQuery(
  baseOptions: Apollo.QueryHookOptions<
    InstitutionalDemand_ComplianceTableQuery,
    InstitutionalDemand_ComplianceTableQueryVariables
  > &
    (
      | { variables: InstitutionalDemand_ComplianceTableQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    InstitutionalDemand_ComplianceTableQuery,
    InstitutionalDemand_ComplianceTableQueryVariables
  >(InstitutionalDemand_ComplianceTableDocument, options);
}
export function useInstitutionalDemand_ComplianceTableLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InstitutionalDemand_ComplianceTableQuery,
    InstitutionalDemand_ComplianceTableQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    InstitutionalDemand_ComplianceTableQuery,
    InstitutionalDemand_ComplianceTableQueryVariables
  >(InstitutionalDemand_ComplianceTableDocument, options);
}
export function useInstitutionalDemand_ComplianceTableSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        InstitutionalDemand_ComplianceTableQuery,
        InstitutionalDemand_ComplianceTableQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    InstitutionalDemand_ComplianceTableQuery,
    InstitutionalDemand_ComplianceTableQueryVariables
  >(InstitutionalDemand_ComplianceTableDocument, options);
}
export type InstitutionalDemand_ComplianceTableQueryHookResult = ReturnType<
  typeof useInstitutionalDemand_ComplianceTableQuery
>;
export type InstitutionalDemand_ComplianceTableLazyQueryHookResult = ReturnType<
  typeof useInstitutionalDemand_ComplianceTableLazyQuery
>;
export type InstitutionalDemand_ComplianceTableSuspenseQueryHookResult = ReturnType<
  typeof useInstitutionalDemand_ComplianceTableSuspenseQuery
>;
export type InstitutionalDemand_ComplianceTableQueryResult = Apollo.QueryResult<
  InstitutionalDemand_ComplianceTableQuery,
  InstitutionalDemand_ComplianceTableQueryVariables
>;

import { makeMockResponse } from '../../../../../../../../../graphql/mock-response';

export const makeInstitutionalDemand_ComplianceTableMockResponse = makeMockResponse<
  InstitutionalDemand_ComplianceTableQueryVariables,
  InstitutionalDemand_ComplianceTableQuery
>(InstitutionalDemand_ComplianceTableDocument);
