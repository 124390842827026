import { useFormikContext } from 'formik';

import { InvalidFormAlert } from '../../../common/content/alerts/InvalidFormAlert';
import { useFormikErrors } from '../../../hooks/useFormikErrors';
import { formSchema } from './TNFilingForm.model';

export const TNInvalidFormAlert = () => {
  const { errors, touched } = useFormikContext();
  const { invalidFormFieldLabels, showInvalidFields } = useFormikErrors({
    errors,
    touched,
    validationSchema: formSchema,
  });

  return (
    <InvalidFormAlert
      showInvalidFields={showInvalidFields}
      invalidFormFieldLabels={invalidFormFieldLabels}
    />
  );
};
