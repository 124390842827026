/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OfferingSetup_MarketingPartsFragment = {
  readonly __typename?: 'Marketing';
  readonly id: string;
  readonly managementTeam?: string | null;
  readonly callDetails?: string | null;
};

export type OfferingSetup_RoadshowSchedulePartsFragment = {
  readonly __typename?: 'RoadshowSchedule';
  readonly id: string;
  readonly date: string;
  readonly details: string;
  readonly recordStatus: Types.RecordStatus;
};

export type OfferingSetup_MarketingQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type OfferingSetup_MarketingQuery = {
  readonly __typename?: 'Query';
  readonly offering: {
    readonly __typename?: 'Offering';
    readonly id: string;
    readonly marketing: {
      readonly __typename?: 'Marketing';
      readonly id: string;
      readonly managementTeam?: string | null;
      readonly callDetails?: string | null;
    };
    readonly roadshowSchedules: ReadonlyArray<{
      readonly __typename?: 'RoadshowSchedule';
      readonly id: string;
      readonly date: string;
      readonly details: string;
      readonly recordStatus: Types.RecordStatus;
    }>;
  };
};

export type OfferingSetup_UpdateMarketingMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  input: Types.MarketingInput;
}>;

export type OfferingSetup_UpdateMarketingMutation = {
  readonly __typename?: 'Mutation';
  readonly updateMarketing: {
    readonly __typename?: 'Marketing';
    readonly id: string;
    readonly managementTeam?: string | null;
    readonly callDetails?: string | null;
  };
};

export type OfferingSetup_CreateRoadshowScheduleMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  input: Types.RoadshowScheduleInput;
}>;

export type OfferingSetup_CreateRoadshowScheduleMutation = {
  readonly __typename?: 'Mutation';
  readonly createRoadshowSchedule: {
    readonly __typename?: 'RoadshowSchedule';
    readonly id: string;
    readonly date: string;
    readonly details: string;
    readonly recordStatus: Types.RecordStatus;
  };
};

export type OfferingSetup_UpdateRoadshowScheduleMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  roadshowScheduleId: Types.Scalars['UUID'];
  input: Types.RoadshowScheduleInput;
}>;

export type OfferingSetup_UpdateRoadshowScheduleMutation = {
  readonly __typename?: 'Mutation';
  readonly updateRoadshowSchedule: {
    readonly __typename?: 'RoadshowSchedule';
    readonly id: string;
    readonly date: string;
    readonly details: string;
    readonly recordStatus: Types.RecordStatus;
  };
};

export type OfferingSetup_DeleteRoadshowScheduleMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  roadshowScheduleId: Types.Scalars['UUID'];
}>;

export type OfferingSetup_DeleteRoadshowScheduleMutation = {
  readonly __typename?: 'Mutation';
  readonly deleteRoadshowSchedule: {
    readonly __typename?: 'DeleteRoadshowSchedule';
    readonly id: string;
  };
};

export const OfferingSetup_MarketingPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_MarketingParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Marketing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'managementTeam' } },
          { kind: 'Field', name: { kind: 'Name', value: 'callDetails' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const OfferingSetup_RoadshowSchedulePartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_RoadshowScheduleParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RoadshowSchedule' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'details' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recordStatus' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const OfferingSetup_MarketingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'OfferingSetup_Marketing' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'offering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'marketing' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'OfferingSetup_MarketingParts' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'roadshowSchedules' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filters' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'recordStatus' },
                            value: { kind: 'EnumValue', value: 'EFFECTIVE' },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'OfferingSetup_RoadshowScheduleParts' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_MarketingParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Marketing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'managementTeam' } },
          { kind: 'Field', name: { kind: 'Name', value: 'callDetails' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_RoadshowScheduleParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RoadshowSchedule' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'details' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recordStatus' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useOfferingSetup_MarketingQuery__
 *
 * To run a query within a React component, call `useOfferingSetup_MarketingQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfferingSetup_MarketingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferingSetup_MarketingQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useOfferingSetup_MarketingQuery(
  baseOptions: Apollo.QueryHookOptions<
    OfferingSetup_MarketingQuery,
    OfferingSetup_MarketingQueryVariables
  > &
    ({ variables: OfferingSetup_MarketingQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OfferingSetup_MarketingQuery, OfferingSetup_MarketingQueryVariables>(
    OfferingSetup_MarketingDocument,
    options
  );
}
export function useOfferingSetup_MarketingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OfferingSetup_MarketingQuery,
    OfferingSetup_MarketingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OfferingSetup_MarketingQuery, OfferingSetup_MarketingQueryVariables>(
    OfferingSetup_MarketingDocument,
    options
  );
}
export function useOfferingSetup_MarketingSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        OfferingSetup_MarketingQuery,
        OfferingSetup_MarketingQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    OfferingSetup_MarketingQuery,
    OfferingSetup_MarketingQueryVariables
  >(OfferingSetup_MarketingDocument, options);
}
export type OfferingSetup_MarketingQueryHookResult = ReturnType<
  typeof useOfferingSetup_MarketingQuery
>;
export type OfferingSetup_MarketingLazyQueryHookResult = ReturnType<
  typeof useOfferingSetup_MarketingLazyQuery
>;
export type OfferingSetup_MarketingSuspenseQueryHookResult = ReturnType<
  typeof useOfferingSetup_MarketingSuspenseQuery
>;
export type OfferingSetup_MarketingQueryResult = Apollo.QueryResult<
  OfferingSetup_MarketingQuery,
  OfferingSetup_MarketingQueryVariables
>;
export const OfferingSetup_UpdateMarketingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'OfferingSetup_UpdateMarketing' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'MarketingInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateMarketing' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OfferingSetup_MarketingParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_MarketingParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Marketing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'managementTeam' } },
          { kind: 'Field', name: { kind: 'Name', value: 'callDetails' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type OfferingSetup_UpdateMarketingMutationFn = Apollo.MutationFunction<
  OfferingSetup_UpdateMarketingMutation,
  OfferingSetup_UpdateMarketingMutationVariables
>;

/**
 * __useOfferingSetup_UpdateMarketingMutation__
 *
 * To run a mutation, you first call `useOfferingSetup_UpdateMarketingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOfferingSetup_UpdateMarketingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [offeringSetupUpdateMarketingMutation, { data, loading, error }] = useOfferingSetup_UpdateMarketingMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOfferingSetup_UpdateMarketingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OfferingSetup_UpdateMarketingMutation,
    OfferingSetup_UpdateMarketingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OfferingSetup_UpdateMarketingMutation,
    OfferingSetup_UpdateMarketingMutationVariables
  >(OfferingSetup_UpdateMarketingDocument, options);
}
export type OfferingSetup_UpdateMarketingMutationHookResult = ReturnType<
  typeof useOfferingSetup_UpdateMarketingMutation
>;
export type OfferingSetup_UpdateMarketingMutationResult =
  Apollo.MutationResult<OfferingSetup_UpdateMarketingMutation>;
export type OfferingSetup_UpdateMarketingMutationOptions = Apollo.BaseMutationOptions<
  OfferingSetup_UpdateMarketingMutation,
  OfferingSetup_UpdateMarketingMutationVariables
>;
export const OfferingSetup_CreateRoadshowScheduleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'OfferingSetup_CreateRoadshowSchedule' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'RoadshowScheduleInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createRoadshowSchedule' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OfferingSetup_RoadshowScheduleParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_RoadshowScheduleParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RoadshowSchedule' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'details' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recordStatus' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type OfferingSetup_CreateRoadshowScheduleMutationFn = Apollo.MutationFunction<
  OfferingSetup_CreateRoadshowScheduleMutation,
  OfferingSetup_CreateRoadshowScheduleMutationVariables
>;

/**
 * __useOfferingSetup_CreateRoadshowScheduleMutation__
 *
 * To run a mutation, you first call `useOfferingSetup_CreateRoadshowScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOfferingSetup_CreateRoadshowScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [offeringSetupCreateRoadshowScheduleMutation, { data, loading, error }] = useOfferingSetup_CreateRoadshowScheduleMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOfferingSetup_CreateRoadshowScheduleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OfferingSetup_CreateRoadshowScheduleMutation,
    OfferingSetup_CreateRoadshowScheduleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OfferingSetup_CreateRoadshowScheduleMutation,
    OfferingSetup_CreateRoadshowScheduleMutationVariables
  >(OfferingSetup_CreateRoadshowScheduleDocument, options);
}
export type OfferingSetup_CreateRoadshowScheduleMutationHookResult = ReturnType<
  typeof useOfferingSetup_CreateRoadshowScheduleMutation
>;
export type OfferingSetup_CreateRoadshowScheduleMutationResult =
  Apollo.MutationResult<OfferingSetup_CreateRoadshowScheduleMutation>;
export type OfferingSetup_CreateRoadshowScheduleMutationOptions = Apollo.BaseMutationOptions<
  OfferingSetup_CreateRoadshowScheduleMutation,
  OfferingSetup_CreateRoadshowScheduleMutationVariables
>;
export const OfferingSetup_UpdateRoadshowScheduleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'OfferingSetup_UpdateRoadshowSchedule' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'roadshowScheduleId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'RoadshowScheduleInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateRoadshowSchedule' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'roadshowScheduleId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'roadshowScheduleId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OfferingSetup_RoadshowScheduleParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_RoadshowScheduleParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RoadshowSchedule' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'details' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recordStatus' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type OfferingSetup_UpdateRoadshowScheduleMutationFn = Apollo.MutationFunction<
  OfferingSetup_UpdateRoadshowScheduleMutation,
  OfferingSetup_UpdateRoadshowScheduleMutationVariables
>;

/**
 * __useOfferingSetup_UpdateRoadshowScheduleMutation__
 *
 * To run a mutation, you first call `useOfferingSetup_UpdateRoadshowScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOfferingSetup_UpdateRoadshowScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [offeringSetupUpdateRoadshowScheduleMutation, { data, loading, error }] = useOfferingSetup_UpdateRoadshowScheduleMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      roadshowScheduleId: // value for 'roadshowScheduleId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOfferingSetup_UpdateRoadshowScheduleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OfferingSetup_UpdateRoadshowScheduleMutation,
    OfferingSetup_UpdateRoadshowScheduleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OfferingSetup_UpdateRoadshowScheduleMutation,
    OfferingSetup_UpdateRoadshowScheduleMutationVariables
  >(OfferingSetup_UpdateRoadshowScheduleDocument, options);
}
export type OfferingSetup_UpdateRoadshowScheduleMutationHookResult = ReturnType<
  typeof useOfferingSetup_UpdateRoadshowScheduleMutation
>;
export type OfferingSetup_UpdateRoadshowScheduleMutationResult =
  Apollo.MutationResult<OfferingSetup_UpdateRoadshowScheduleMutation>;
export type OfferingSetup_UpdateRoadshowScheduleMutationOptions = Apollo.BaseMutationOptions<
  OfferingSetup_UpdateRoadshowScheduleMutation,
  OfferingSetup_UpdateRoadshowScheduleMutationVariables
>;
export const OfferingSetup_DeleteRoadshowScheduleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'OfferingSetup_DeleteRoadshowSchedule' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'roadshowScheduleId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteRoadshowSchedule' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'roadshowScheduleId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'roadshowScheduleId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type OfferingSetup_DeleteRoadshowScheduleMutationFn = Apollo.MutationFunction<
  OfferingSetup_DeleteRoadshowScheduleMutation,
  OfferingSetup_DeleteRoadshowScheduleMutationVariables
>;

/**
 * __useOfferingSetup_DeleteRoadshowScheduleMutation__
 *
 * To run a mutation, you first call `useOfferingSetup_DeleteRoadshowScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOfferingSetup_DeleteRoadshowScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [offeringSetupDeleteRoadshowScheduleMutation, { data, loading, error }] = useOfferingSetup_DeleteRoadshowScheduleMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      roadshowScheduleId: // value for 'roadshowScheduleId'
 *   },
 * });
 */
export function useOfferingSetup_DeleteRoadshowScheduleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OfferingSetup_DeleteRoadshowScheduleMutation,
    OfferingSetup_DeleteRoadshowScheduleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OfferingSetup_DeleteRoadshowScheduleMutation,
    OfferingSetup_DeleteRoadshowScheduleMutationVariables
  >(OfferingSetup_DeleteRoadshowScheduleDocument, options);
}
export type OfferingSetup_DeleteRoadshowScheduleMutationHookResult = ReturnType<
  typeof useOfferingSetup_DeleteRoadshowScheduleMutation
>;
export type OfferingSetup_DeleteRoadshowScheduleMutationResult =
  Apollo.MutationResult<OfferingSetup_DeleteRoadshowScheduleMutation>;
export type OfferingSetup_DeleteRoadshowScheduleMutationOptions = Apollo.BaseMutationOptions<
  OfferingSetup_DeleteRoadshowScheduleMutation,
  OfferingSetup_DeleteRoadshowScheduleMutationVariables
>;

import { makeMockResponse } from '../../../../../graphql/mock-response';

export const makeOfferingSetup_MarketingMockResponse = makeMockResponse<
  OfferingSetup_MarketingQueryVariables,
  OfferingSetup_MarketingQuery
>(OfferingSetup_MarketingDocument);
