/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CrmSelection_AllCoveredInvestorFirmsMappedToCmgEntityKeyQueryVariables = Types.Exact<{
  cmgEntityKey: Types.Scalars['String'];
}>;

export type CrmSelection_AllCoveredInvestorFirmsMappedToCmgEntityKeyQuery = {
  readonly __typename?: 'Query';
  readonly allCoveredInvestorFirmsMappedToCmgEntityKey: ReadonlyArray<{
    readonly __typename?: 'InvestorFirmBasic';
    readonly id: string;
    readonly cmgEntityKey?: string | null;
    readonly firmKey?: string | null;
    readonly name: string;
    readonly address1?: string | null;
    readonly address2?: string | null;
  }>;
};

export type CrmSelection_AllInvestorFirmsMappedToCmgEntityKeyQueryVariables = Types.Exact<{
  cmgEntityKey: Types.Scalars['String'];
}>;

export type CrmSelection_AllInvestorFirmsMappedToCmgEntityKeyQuery = {
  readonly __typename?: 'Query';
  readonly allInvestorFirmsMappedToCmgEntityKey: ReadonlyArray<{
    readonly __typename?: 'InvestorFirmBasic';
    readonly id: string;
    readonly cmgEntityKey?: string | null;
    readonly firmKey?: string | null;
    readonly name: string;
    readonly address1?: string | null;
    readonly address2?: string | null;
  }>;
};

export type CrmSelection_AssignCoveredInvestorKeyMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  indicationId: Types.Scalars['UUID'];
  investorCrmKey: Types.Scalars['String'];
}>;

export type CrmSelection_AssignCoveredInvestorKeyMutation = {
  readonly __typename?: 'Mutation';
  readonly assignCoveredInvestorKey: {
    readonly __typename?: 'UpdateInvestorCrmKeyResult';
    readonly indicationId: string;
  };
};

export type CrmSelection_AssignInvestorKeyMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  indicationId: Types.Scalars['UUID'];
  investorCrmKey: Types.Scalars['String'];
}>;

export type CrmSelection_AssignInvestorKeyMutation = {
  readonly __typename?: 'Mutation';
  readonly assignInvestorKey: {
    readonly __typename?: 'UpdateInvestorCrmKeyResult';
    readonly indicationId: string;
  };
};

export type CrmSelection_InstitutionalIndicationsQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  filters?: Types.InputMaybe<Types.InstitutionalIndicationListFiltersInput>;
}>;

export type CrmSelection_InstitutionalIndicationsQuery = {
  readonly __typename?: 'Query';
  readonly institutionalIndications: ReadonlyArray<{
    readonly __typename?: 'InstitutionalIndication';
    readonly id: string;
    readonly investorInformation: {
      readonly __typename?: 'InvestorInformation';
      readonly cmgEntityKey: string;
      readonly cmgEntityName: string;
    };
  }>;
};

export type InvestorFirmFieldsFragment = {
  readonly __typename?: 'InvestorFirmBasic';
  readonly cmgEntityKey?: string | null;
  readonly id: string;
  readonly firmKey?: string | null;
  readonly name: string;
  readonly address1?: string | null;
  readonly address2?: string | null;
};

export type CrmSelection_InvestorFirmsMappedToCmgEntityKeysQueryVariables = Types.Exact<{
  cmgEntityKeys: ReadonlyArray<Types.Scalars['String']> | Types.Scalars['String'];
}>;

export type CrmSelection_InvestorFirmsMappedToCmgEntityKeysQuery = {
  readonly __typename?: 'Query';
  readonly allInvestorFirmsMappedToCmgEntityKeys: ReadonlyArray<{
    readonly __typename?: 'InvestorFirmMappedWithCmgEntityKey';
    readonly investors: ReadonlyArray<{
      readonly __typename?: 'InvestorFirmBasic';
      readonly cmgEntityKey?: string | null;
      readonly id: string;
      readonly firmKey?: string | null;
      readonly name: string;
      readonly address1?: string | null;
      readonly address2?: string | null;
    }>;
  }>;
};

export const InvestorFirmFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InvestorFirmFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'InvestorFirmBasic' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const CrmSelection_AllCoveredInvestorFirmsMappedToCmgEntityKeyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CrmSelection_AllCoveredInvestorFirmsMappedToCmgEntityKey' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKey' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allCoveredInvestorFirmsMappedToCmgEntityKey' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cmgEntityKey' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKey' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firmKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useCrmSelection_AllCoveredInvestorFirmsMappedToCmgEntityKeyQuery__
 *
 * To run a query within a React component, call `useCrmSelection_AllCoveredInvestorFirmsMappedToCmgEntityKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useCrmSelection_AllCoveredInvestorFirmsMappedToCmgEntityKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCrmSelection_AllCoveredInvestorFirmsMappedToCmgEntityKeyQuery({
 *   variables: {
 *      cmgEntityKey: // value for 'cmgEntityKey'
 *   },
 * });
 */
export function useCrmSelection_AllCoveredInvestorFirmsMappedToCmgEntityKeyQuery(
  baseOptions: Apollo.QueryHookOptions<
    CrmSelection_AllCoveredInvestorFirmsMappedToCmgEntityKeyQuery,
    CrmSelection_AllCoveredInvestorFirmsMappedToCmgEntityKeyQueryVariables
  > &
    (
      | {
          variables: CrmSelection_AllCoveredInvestorFirmsMappedToCmgEntityKeyQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CrmSelection_AllCoveredInvestorFirmsMappedToCmgEntityKeyQuery,
    CrmSelection_AllCoveredInvestorFirmsMappedToCmgEntityKeyQueryVariables
  >(CrmSelection_AllCoveredInvestorFirmsMappedToCmgEntityKeyDocument, options);
}
export function useCrmSelection_AllCoveredInvestorFirmsMappedToCmgEntityKeyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CrmSelection_AllCoveredInvestorFirmsMappedToCmgEntityKeyQuery,
    CrmSelection_AllCoveredInvestorFirmsMappedToCmgEntityKeyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CrmSelection_AllCoveredInvestorFirmsMappedToCmgEntityKeyQuery,
    CrmSelection_AllCoveredInvestorFirmsMappedToCmgEntityKeyQueryVariables
  >(CrmSelection_AllCoveredInvestorFirmsMappedToCmgEntityKeyDocument, options);
}
export function useCrmSelection_AllCoveredInvestorFirmsMappedToCmgEntityKeySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CrmSelection_AllCoveredInvestorFirmsMappedToCmgEntityKeyQuery,
        CrmSelection_AllCoveredInvestorFirmsMappedToCmgEntityKeyQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CrmSelection_AllCoveredInvestorFirmsMappedToCmgEntityKeyQuery,
    CrmSelection_AllCoveredInvestorFirmsMappedToCmgEntityKeyQueryVariables
  >(CrmSelection_AllCoveredInvestorFirmsMappedToCmgEntityKeyDocument, options);
}
export type CrmSelection_AllCoveredInvestorFirmsMappedToCmgEntityKeyQueryHookResult = ReturnType<
  typeof useCrmSelection_AllCoveredInvestorFirmsMappedToCmgEntityKeyQuery
>;
export type CrmSelection_AllCoveredInvestorFirmsMappedToCmgEntityKeyLazyQueryHookResult =
  ReturnType<typeof useCrmSelection_AllCoveredInvestorFirmsMappedToCmgEntityKeyLazyQuery>;
export type CrmSelection_AllCoveredInvestorFirmsMappedToCmgEntityKeySuspenseQueryHookResult =
  ReturnType<typeof useCrmSelection_AllCoveredInvestorFirmsMappedToCmgEntityKeySuspenseQuery>;
export type CrmSelection_AllCoveredInvestorFirmsMappedToCmgEntityKeyQueryResult =
  Apollo.QueryResult<
    CrmSelection_AllCoveredInvestorFirmsMappedToCmgEntityKeyQuery,
    CrmSelection_AllCoveredInvestorFirmsMappedToCmgEntityKeyQueryVariables
  >;
export const CrmSelection_AllInvestorFirmsMappedToCmgEntityKeyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CrmSelection_AllInvestorFirmsMappedToCmgEntityKey' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKey' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allInvestorFirmsMappedToCmgEntityKey' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cmgEntityKey' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKey' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firmKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useCrmSelection_AllInvestorFirmsMappedToCmgEntityKeyQuery__
 *
 * To run a query within a React component, call `useCrmSelection_AllInvestorFirmsMappedToCmgEntityKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useCrmSelection_AllInvestorFirmsMappedToCmgEntityKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCrmSelection_AllInvestorFirmsMappedToCmgEntityKeyQuery({
 *   variables: {
 *      cmgEntityKey: // value for 'cmgEntityKey'
 *   },
 * });
 */
export function useCrmSelection_AllInvestorFirmsMappedToCmgEntityKeyQuery(
  baseOptions: Apollo.QueryHookOptions<
    CrmSelection_AllInvestorFirmsMappedToCmgEntityKeyQuery,
    CrmSelection_AllInvestorFirmsMappedToCmgEntityKeyQueryVariables
  > &
    (
      | {
          variables: CrmSelection_AllInvestorFirmsMappedToCmgEntityKeyQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CrmSelection_AllInvestorFirmsMappedToCmgEntityKeyQuery,
    CrmSelection_AllInvestorFirmsMappedToCmgEntityKeyQueryVariables
  >(CrmSelection_AllInvestorFirmsMappedToCmgEntityKeyDocument, options);
}
export function useCrmSelection_AllInvestorFirmsMappedToCmgEntityKeyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CrmSelection_AllInvestorFirmsMappedToCmgEntityKeyQuery,
    CrmSelection_AllInvestorFirmsMappedToCmgEntityKeyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CrmSelection_AllInvestorFirmsMappedToCmgEntityKeyQuery,
    CrmSelection_AllInvestorFirmsMappedToCmgEntityKeyQueryVariables
  >(CrmSelection_AllInvestorFirmsMappedToCmgEntityKeyDocument, options);
}
export function useCrmSelection_AllInvestorFirmsMappedToCmgEntityKeySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CrmSelection_AllInvestorFirmsMappedToCmgEntityKeyQuery,
        CrmSelection_AllInvestorFirmsMappedToCmgEntityKeyQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CrmSelection_AllInvestorFirmsMappedToCmgEntityKeyQuery,
    CrmSelection_AllInvestorFirmsMappedToCmgEntityKeyQueryVariables
  >(CrmSelection_AllInvestorFirmsMappedToCmgEntityKeyDocument, options);
}
export type CrmSelection_AllInvestorFirmsMappedToCmgEntityKeyQueryHookResult = ReturnType<
  typeof useCrmSelection_AllInvestorFirmsMappedToCmgEntityKeyQuery
>;
export type CrmSelection_AllInvestorFirmsMappedToCmgEntityKeyLazyQueryHookResult = ReturnType<
  typeof useCrmSelection_AllInvestorFirmsMappedToCmgEntityKeyLazyQuery
>;
export type CrmSelection_AllInvestorFirmsMappedToCmgEntityKeySuspenseQueryHookResult = ReturnType<
  typeof useCrmSelection_AllInvestorFirmsMappedToCmgEntityKeySuspenseQuery
>;
export type CrmSelection_AllInvestorFirmsMappedToCmgEntityKeyQueryResult = Apollo.QueryResult<
  CrmSelection_AllInvestorFirmsMappedToCmgEntityKeyQuery,
  CrmSelection_AllInvestorFirmsMappedToCmgEntityKeyQueryVariables
>;
export const CrmSelection_AssignCoveredInvestorKeyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CrmSelection_AssignCoveredInvestorKey' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'indicationId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'investorCrmKey' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignCoveredInvestorKey' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'indicationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'indicationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'investorCrmKey' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'investorCrmKey' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'indicationId' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type CrmSelection_AssignCoveredInvestorKeyMutationFn = Apollo.MutationFunction<
  CrmSelection_AssignCoveredInvestorKeyMutation,
  CrmSelection_AssignCoveredInvestorKeyMutationVariables
>;

/**
 * __useCrmSelection_AssignCoveredInvestorKeyMutation__
 *
 * To run a mutation, you first call `useCrmSelection_AssignCoveredInvestorKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCrmSelection_AssignCoveredInvestorKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [crmSelectionAssignCoveredInvestorKeyMutation, { data, loading, error }] = useCrmSelection_AssignCoveredInvestorKeyMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      indicationId: // value for 'indicationId'
 *      investorCrmKey: // value for 'investorCrmKey'
 *   },
 * });
 */
export function useCrmSelection_AssignCoveredInvestorKeyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CrmSelection_AssignCoveredInvestorKeyMutation,
    CrmSelection_AssignCoveredInvestorKeyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CrmSelection_AssignCoveredInvestorKeyMutation,
    CrmSelection_AssignCoveredInvestorKeyMutationVariables
  >(CrmSelection_AssignCoveredInvestorKeyDocument, options);
}
export type CrmSelection_AssignCoveredInvestorKeyMutationHookResult = ReturnType<
  typeof useCrmSelection_AssignCoveredInvestorKeyMutation
>;
export type CrmSelection_AssignCoveredInvestorKeyMutationResult =
  Apollo.MutationResult<CrmSelection_AssignCoveredInvestorKeyMutation>;
export type CrmSelection_AssignCoveredInvestorKeyMutationOptions = Apollo.BaseMutationOptions<
  CrmSelection_AssignCoveredInvestorKeyMutation,
  CrmSelection_AssignCoveredInvestorKeyMutationVariables
>;
export const CrmSelection_AssignInvestorKeyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CrmSelection_AssignInvestorKey' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'indicationId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'investorCrmKey' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignInvestorKey' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'indicationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'indicationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'investorCrmKey' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'investorCrmKey' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'indicationId' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type CrmSelection_AssignInvestorKeyMutationFn = Apollo.MutationFunction<
  CrmSelection_AssignInvestorKeyMutation,
  CrmSelection_AssignInvestorKeyMutationVariables
>;

/**
 * __useCrmSelection_AssignInvestorKeyMutation__
 *
 * To run a mutation, you first call `useCrmSelection_AssignInvestorKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCrmSelection_AssignInvestorKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [crmSelectionAssignInvestorKeyMutation, { data, loading, error }] = useCrmSelection_AssignInvestorKeyMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      indicationId: // value for 'indicationId'
 *      investorCrmKey: // value for 'investorCrmKey'
 *   },
 * });
 */
export function useCrmSelection_AssignInvestorKeyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CrmSelection_AssignInvestorKeyMutation,
    CrmSelection_AssignInvestorKeyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CrmSelection_AssignInvestorKeyMutation,
    CrmSelection_AssignInvestorKeyMutationVariables
  >(CrmSelection_AssignInvestorKeyDocument, options);
}
export type CrmSelection_AssignInvestorKeyMutationHookResult = ReturnType<
  typeof useCrmSelection_AssignInvestorKeyMutation
>;
export type CrmSelection_AssignInvestorKeyMutationResult =
  Apollo.MutationResult<CrmSelection_AssignInvestorKeyMutation>;
export type CrmSelection_AssignInvestorKeyMutationOptions = Apollo.BaseMutationOptions<
  CrmSelection_AssignInvestorKeyMutation,
  CrmSelection_AssignInvestorKeyMutationVariables
>;
export const CrmSelection_InstitutionalIndicationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CrmSelection_InstitutionalIndications' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'InstitutionalIndicationListFiltersInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institutionalIndications' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'investorInformation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityName' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useCrmSelection_InstitutionalIndicationsQuery__
 *
 * To run a query within a React component, call `useCrmSelection_InstitutionalIndicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCrmSelection_InstitutionalIndicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCrmSelection_InstitutionalIndicationsQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useCrmSelection_InstitutionalIndicationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CrmSelection_InstitutionalIndicationsQuery,
    CrmSelection_InstitutionalIndicationsQueryVariables
  > &
    (
      | { variables: CrmSelection_InstitutionalIndicationsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CrmSelection_InstitutionalIndicationsQuery,
    CrmSelection_InstitutionalIndicationsQueryVariables
  >(CrmSelection_InstitutionalIndicationsDocument, options);
}
export function useCrmSelection_InstitutionalIndicationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CrmSelection_InstitutionalIndicationsQuery,
    CrmSelection_InstitutionalIndicationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CrmSelection_InstitutionalIndicationsQuery,
    CrmSelection_InstitutionalIndicationsQueryVariables
  >(CrmSelection_InstitutionalIndicationsDocument, options);
}
export function useCrmSelection_InstitutionalIndicationsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CrmSelection_InstitutionalIndicationsQuery,
        CrmSelection_InstitutionalIndicationsQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CrmSelection_InstitutionalIndicationsQuery,
    CrmSelection_InstitutionalIndicationsQueryVariables
  >(CrmSelection_InstitutionalIndicationsDocument, options);
}
export type CrmSelection_InstitutionalIndicationsQueryHookResult = ReturnType<
  typeof useCrmSelection_InstitutionalIndicationsQuery
>;
export type CrmSelection_InstitutionalIndicationsLazyQueryHookResult = ReturnType<
  typeof useCrmSelection_InstitutionalIndicationsLazyQuery
>;
export type CrmSelection_InstitutionalIndicationsSuspenseQueryHookResult = ReturnType<
  typeof useCrmSelection_InstitutionalIndicationsSuspenseQuery
>;
export type CrmSelection_InstitutionalIndicationsQueryResult = Apollo.QueryResult<
  CrmSelection_InstitutionalIndicationsQuery,
  CrmSelection_InstitutionalIndicationsQueryVariables
>;
export const CrmSelection_InvestorFirmsMappedToCmgEntityKeysDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CrmSelection_InvestorFirmsMappedToCmgEntityKeys' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKeys' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allInvestorFirmsMappedToCmgEntityKeys' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cmgEntityKeys' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKeys' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'investors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'InvestorFirmFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InvestorFirmFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'InvestorFirmBasic' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useCrmSelection_InvestorFirmsMappedToCmgEntityKeysQuery__
 *
 * To run a query within a React component, call `useCrmSelection_InvestorFirmsMappedToCmgEntityKeysQuery` and pass it any options that fit your needs.
 * When your component renders, `useCrmSelection_InvestorFirmsMappedToCmgEntityKeysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCrmSelection_InvestorFirmsMappedToCmgEntityKeysQuery({
 *   variables: {
 *      cmgEntityKeys: // value for 'cmgEntityKeys'
 *   },
 * });
 */
export function useCrmSelection_InvestorFirmsMappedToCmgEntityKeysQuery(
  baseOptions: Apollo.QueryHookOptions<
    CrmSelection_InvestorFirmsMappedToCmgEntityKeysQuery,
    CrmSelection_InvestorFirmsMappedToCmgEntityKeysQueryVariables
  > &
    (
      | { variables: CrmSelection_InvestorFirmsMappedToCmgEntityKeysQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CrmSelection_InvestorFirmsMappedToCmgEntityKeysQuery,
    CrmSelection_InvestorFirmsMappedToCmgEntityKeysQueryVariables
  >(CrmSelection_InvestorFirmsMappedToCmgEntityKeysDocument, options);
}
export function useCrmSelection_InvestorFirmsMappedToCmgEntityKeysLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CrmSelection_InvestorFirmsMappedToCmgEntityKeysQuery,
    CrmSelection_InvestorFirmsMappedToCmgEntityKeysQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CrmSelection_InvestorFirmsMappedToCmgEntityKeysQuery,
    CrmSelection_InvestorFirmsMappedToCmgEntityKeysQueryVariables
  >(CrmSelection_InvestorFirmsMappedToCmgEntityKeysDocument, options);
}
export function useCrmSelection_InvestorFirmsMappedToCmgEntityKeysSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CrmSelection_InvestorFirmsMappedToCmgEntityKeysQuery,
        CrmSelection_InvestorFirmsMappedToCmgEntityKeysQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CrmSelection_InvestorFirmsMappedToCmgEntityKeysQuery,
    CrmSelection_InvestorFirmsMappedToCmgEntityKeysQueryVariables
  >(CrmSelection_InvestorFirmsMappedToCmgEntityKeysDocument, options);
}
export type CrmSelection_InvestorFirmsMappedToCmgEntityKeysQueryHookResult = ReturnType<
  typeof useCrmSelection_InvestorFirmsMappedToCmgEntityKeysQuery
>;
export type CrmSelection_InvestorFirmsMappedToCmgEntityKeysLazyQueryHookResult = ReturnType<
  typeof useCrmSelection_InvestorFirmsMappedToCmgEntityKeysLazyQuery
>;
export type CrmSelection_InvestorFirmsMappedToCmgEntityKeysSuspenseQueryHookResult = ReturnType<
  typeof useCrmSelection_InvestorFirmsMappedToCmgEntityKeysSuspenseQuery
>;
export type CrmSelection_InvestorFirmsMappedToCmgEntityKeysQueryResult = Apollo.QueryResult<
  CrmSelection_InvestorFirmsMappedToCmgEntityKeysQuery,
  CrmSelection_InvestorFirmsMappedToCmgEntityKeysQueryVariables
>;

import { makeMockResponse } from '../../../../../../../../graphql/mock-response';

export const makeCrmSelection_AllCoveredInvestorFirmsMappedToCmgEntityKeyMockResponse =
  makeMockResponse<
    CrmSelection_AllCoveredInvestorFirmsMappedToCmgEntityKeyQueryVariables,
    CrmSelection_AllCoveredInvestorFirmsMappedToCmgEntityKeyQuery
  >(CrmSelection_AllCoveredInvestorFirmsMappedToCmgEntityKeyDocument);

export const makeCrmSelection_AllInvestorFirmsMappedToCmgEntityKeyMockResponse = makeMockResponse<
  CrmSelection_AllInvestorFirmsMappedToCmgEntityKeyQueryVariables,
  CrmSelection_AllInvestorFirmsMappedToCmgEntityKeyQuery
>(CrmSelection_AllInvestorFirmsMappedToCmgEntityKeyDocument);

export const makeCrmSelection_InstitutionalIndicationsMockResponse = makeMockResponse<
  CrmSelection_InstitutionalIndicationsQueryVariables,
  CrmSelection_InstitutionalIndicationsQuery
>(CrmSelection_InstitutionalIndicationsDocument);

export const makeCrmSelection_InvestorFirmsMappedToCmgEntityKeysMockResponse = makeMockResponse<
  CrmSelection_InvestorFirmsMappedToCmgEntityKeysQueryVariables,
  CrmSelection_InvestorFirmsMappedToCmgEntityKeysQuery
>(CrmSelection_InvestorFirmsMappedToCmgEntityKeysDocument);
