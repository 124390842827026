import { useCheckAccountTraits } from '@cmg/auth';
import { filterSidePanelEvent, useDebouncedCalendarRefreshEvent, UUID } from '@cmg/common';
import {
  BusinessCenterOutlinedIcon,
  Collapse,
  Divider,
  MenuList,
  MessageOutlinedIcon,
  NoteAltOutlinedIcon,
  RequestQuoteOutlinedIcon,
} from '@cmg/design-system';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';

import ErrorRenderer from '../../../../../../common/components/errorRenderer/Error-Renderer';
import { useDocumentTitle } from '../../../../../../common/hooks/useDocumentTitle/useDocumentTitle';
import useCheckOrderBookAccess from '../../../../../../common/util/check-access/useCheckOrderBookAccess';
import routeFactory, { checkRouteIsActive } from '../../../../../../common/util/routeFactory';
import { useCmgEntityKeyContext } from '../../../../contexts/CmgEntityKeyContext';
import { useSupersededOfferingContext } from '../../../../contexts/SupersededOfferingContext';
import { OfferingSidePanelRouteContext } from '../../../../OfferingSidePanelContainer';
import offeringSidePanelSellSideRouteFactory from '../../../../offeringSidePanelSellSideRouteFactory';
import {
  OrderBookPersonaType,
  useIdentifyOrderBookPersona,
} from '../../../../order-book/hooks/useIdentifyOrderBookPersona';
import { useGetLimitedPublishedOfferingQuery } from '../../hooks/useGetLimitedPublishedOfferingQuery';
import OfferingStatusIndicator from '../offering-status-indicator/OfferingStatusIndicator';
import { OrderBookSubNavigation } from './OrderBookSubNavigation';
import { SideNavbarItem } from './SideNavbarItem';
import { Layout } from './SidePanelNavbar.styles';

export type Props = {
  readonly offeringId: UUID;
};

export const SidePanelSellSideNavbar: React.FC<Props> = ({ offeringId }) => {
  const { pathname } = useLocation();
  const { canUpdateIndications, type } = useIdentifyOrderBookPersona({ offeringId });
  const canViewSyndicateNotes = useCheckAccountTraits(['XC_IOI_NOTES']);
  const { canRead: canReadOrderBook } = useCheckOrderBookAccess({ offeringId });
  const baseSidePanelUrl = useContext(OfferingSidePanelRouteContext);
  const { cmgEntityKey } = useCmgEntityKeyContext();
  const { data, error, loading, refetch } = useGetLimitedPublishedOfferingQuery({
    variables: {
      offeringId: offeringId,
    },
  });

  const { isObsoleteOffering } = useSupersededOfferingContext();
  // check if the internal /order-book routePath matches the existing route
  const expandOrderBookSubNav = !!useRouteMatch(
    offeringSidePanelSellSideRouteFactory.orderBook.routePath
  );
  const [orderBookSectionOpen, setOrderBookSectionOpen] = useState(expandOrderBookSubNav);
  const onOrderBookSectionClick = useCallback(() => {
    setOrderBookSectionOpen(!orderBookSectionOpen);
  }, [orderBookSectionOpen]);

  useDocumentTitle(
    routeFactory.offeringSidePanel.getDocumentTitle({
      ticker: data?.limitedPublishedOffering.pricingInstrumentStockSymbol,
    })
  );

  const filterFn = useMemo(() => {
    return filterSidePanelEvent(offeringId);
  }, [offeringId]);

  useDebouncedCalendarRefreshEvent({
    callback: useCallback(() => {
      refetch();
    }, [refetch]),
    filterFn,
  });

  if (error) {
    return <ErrorRenderer error={error} />;
  }

  const offeringDetailsUrl =
    baseSidePanelUrl +
    offeringSidePanelSellSideRouteFactory.offeringDetails.getUrlPath({
      cmgEntityKey,
    });
  const notesUrl =
    baseSidePanelUrl +
    offeringSidePanelSellSideRouteFactory.orderBookNotes.getUrlPath({
      cmgEntityKey,
    });
  const feedbackUrl =
    baseSidePanelUrl + offeringSidePanelSellSideRouteFactory.feedback.getUrlPath({ cmgEntityKey });

  return (
    <Layout error={isObsoleteOffering}>
      <React.Fragment>
        {data && (
          <React.Fragment>
            <OfferingStatusIndicator
              publishedOffering={data.limitedPublishedOffering}
              isBusy={loading}
            />
            <Divider />
          </React.Fragment>
        )}
        {!isObsoleteOffering && (
          <MenuList
            disablePadding={false}
            disableListWrap={false}
            component="nav"
            aria-labelledby="sell-side-nav"
          >
            <SideNavbarItem
              icon={<BusinessCenterOutlinedIcon />}
              href={offeringDetailsUrl}
              selected={checkRouteIsActive(
                offeringSidePanelSellSideRouteFactory.offeringDetails.routePath,
                pathname
              )}
            >
              Offering Details
            </SideNavbarItem>
            {canReadOrderBook && (
              <SideNavbarItem
                icon={<RequestQuoteOutlinedIcon />}
                expanded={orderBookSectionOpen}
                onClick={onOrderBookSectionClick}
              >
                Order Book
              </SideNavbarItem>
            )}
            {canReadOrderBook && (
              <Collapse in={orderBookSectionOpen} timeout="auto" unmountOnExit>
                <OrderBookSubNavigation
                  offeringId={offeringId}
                  baseSidePanelUrl={baseSidePanelUrl}
                />
              </Collapse>
            )}
            <SideNavbarItem
              icon={<MessageOutlinedIcon />}
              href={feedbackUrl}
              selected={checkRouteIsActive(
                offeringSidePanelSellSideRouteFactory.feedback.routePath,
                pathname
              )}
            >
              Feedback
            </SideNavbarItem>
            {type !== OrderBookPersonaType.SALES_AND_TRADING &&
              canUpdateIndications &&
              canViewSyndicateNotes && (
                <SideNavbarItem
                  icon={<NoteAltOutlinedIcon />}
                  href={notesUrl}
                  selected={checkRouteIsActive(
                    offeringSidePanelSellSideRouteFactory.orderBookNotes.routePath,
                    pathname
                  )}
                >
                  Notes
                </SideNavbarItem>
              )}
          </MenuList>
        )}
      </React.Fragment>
    </Layout>
  );
};
