/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CheckEntitlements_PublishedOfferingEntitlementsQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type CheckEntitlements_PublishedOfferingEntitlementsQuery = {
  readonly __typename?: 'Query';
  readonly publishedOffering: {
    readonly __typename?: 'OfferingProfile';
    readonly id: string;
    readonly entitlements: {
      readonly __typename?: 'PublishedSyndicateEntitlements';
      readonly cmgEntityKey: string;
      readonly entitlements: ReadonlyArray<string>;
    };
  };
};

export const CheckEntitlements_PublishedOfferingEntitlementsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CheckEntitlements_PublishedOfferingEntitlements' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'publishedOffering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'entitlements' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'entitlements' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useCheckEntitlements_PublishedOfferingEntitlementsQuery__
 *
 * To run a query within a React component, call `useCheckEntitlements_PublishedOfferingEntitlementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckEntitlements_PublishedOfferingEntitlementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckEntitlements_PublishedOfferingEntitlementsQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useCheckEntitlements_PublishedOfferingEntitlementsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CheckEntitlements_PublishedOfferingEntitlementsQuery,
    CheckEntitlements_PublishedOfferingEntitlementsQueryVariables
  > &
    (
      | { variables: CheckEntitlements_PublishedOfferingEntitlementsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CheckEntitlements_PublishedOfferingEntitlementsQuery,
    CheckEntitlements_PublishedOfferingEntitlementsQueryVariables
  >(CheckEntitlements_PublishedOfferingEntitlementsDocument, options);
}
export function useCheckEntitlements_PublishedOfferingEntitlementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CheckEntitlements_PublishedOfferingEntitlementsQuery,
    CheckEntitlements_PublishedOfferingEntitlementsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CheckEntitlements_PublishedOfferingEntitlementsQuery,
    CheckEntitlements_PublishedOfferingEntitlementsQueryVariables
  >(CheckEntitlements_PublishedOfferingEntitlementsDocument, options);
}
export function useCheckEntitlements_PublishedOfferingEntitlementsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CheckEntitlements_PublishedOfferingEntitlementsQuery,
        CheckEntitlements_PublishedOfferingEntitlementsQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CheckEntitlements_PublishedOfferingEntitlementsQuery,
    CheckEntitlements_PublishedOfferingEntitlementsQueryVariables
  >(CheckEntitlements_PublishedOfferingEntitlementsDocument, options);
}
export type CheckEntitlements_PublishedOfferingEntitlementsQueryHookResult = ReturnType<
  typeof useCheckEntitlements_PublishedOfferingEntitlementsQuery
>;
export type CheckEntitlements_PublishedOfferingEntitlementsLazyQueryHookResult = ReturnType<
  typeof useCheckEntitlements_PublishedOfferingEntitlementsLazyQuery
>;
export type CheckEntitlements_PublishedOfferingEntitlementsSuspenseQueryHookResult = ReturnType<
  typeof useCheckEntitlements_PublishedOfferingEntitlementsSuspenseQuery
>;
export type CheckEntitlements_PublishedOfferingEntitlementsQueryResult = Apollo.QueryResult<
  CheckEntitlements_PublishedOfferingEntitlementsQuery,
  CheckEntitlements_PublishedOfferingEntitlementsQueryVariables
>;

import { makeMockResponse } from '../../../../../graphql/mock-response';

export const makeCheckEntitlements_PublishedOfferingEntitlementsMockResponse = makeMockResponse<
  CheckEntitlements_PublishedOfferingEntitlementsQueryVariables,
  CheckEntitlements_PublishedOfferingEntitlementsQuery
>(CheckEntitlements_PublishedOfferingEntitlementsDocument);
