import { Option } from '@cmg/common';
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableGroupHeader,
  TableGroupHeaderCell,
  TableHead,
  TableRow,
} from '@cmg/design-system';
import { FormikTextField } from '@cmg/design-system-formik';
import { useFormikContext } from 'formik';
import React from 'react';

import Panel from '../../../../../common/components/layout/panel/Panel';
import MandatoryAsterisk from '../../../../../design-system/components/data-display/mandatory-asterisk/MandatoryAsterisk';
import { MemberFirmType } from '../../../../../graphql';
import { useGroupAffiliates } from '../../../hooks/useGroupAffiliates';
import { RPNFilingFormValues } from './RPNForm.model';

export const excusedOrPassiveOptions: Option<MemberFirmType>[] = [
  {
    label: 'Excused',
    value: MemberFirmType.Excused,
  },
  {
    label: 'Passive',
    value: MemberFirmType.Passive,
  },
];

const title = 'FINRA Member Firms';

const MemberFirmsSubForm: React.FC = () => {
  const { values, initialValues, handleChange } = useFormikContext<RPNFilingFormValues>();
  const groupedRPNMemberFirmsTableData = useGroupAffiliates(values.finraMemberFirms, true);
  const memberFirmsInitialValues = initialValues.finraMemberFirms;

  if (!values.finraMemberFirms.length || !groupedRPNMemberFirmsTableData) {
    return null;
  }

  return (
    <Panel>
      <Panel.Header title={title} />
      <Panel.Content>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width="30%">
                  Firm Name
                  <MandatoryAsterisk />
                </TableCell>
                <TableCell width="30%">
                  Member Firm CRD# <MandatoryAsterisk />
                </TableCell>
                <TableCell>Firm MPID</TableCell>
                <TableCell>
                  Excused or Passive <MandatoryAsterisk />
                </TableCell>
              </TableRow>
            </TableHead>
            {groupedRPNMemberFirmsTableData.map(([manager, memberFirms], groupIndex) => (
              <TableBody key={manager} aria-label={manager}>
                <TableGroupHeader>
                  <TableGroupHeaderCell colSpan={4} aria-label={`${groupIndex + 1}. Manager`}>
                    {manager}
                  </TableGroupHeaderCell>
                </TableGroupHeader>
                {memberFirms.map((firm, index) => {
                  const formIndex = firm.formIndex!; // this will be nonNullable if isOfferingRegMRolodexAffiliatesOn = true
                  const firmNamePlaceholder = memberFirmsInitialValues[formIndex].firmName
                    ? `Original value: ${memberFirmsInitialValues[formIndex].firmName}`
                    : '';
                  return (
                    <TableRow key={formIndex}>
                      <TableCell size="medium">
                        <FormikTextField
                          inputProps={{
                            'aria-label': `${index + 1}. Firm Name`,
                          }}
                          showHelperTextInTooltip
                          name={`finraMemberFirms.${formIndex}.firmName`}
                          placeholder={firmNamePlaceholder}
                          required
                        />
                      </TableCell>
                      <TableCell size="medium">
                        <FormikTextField
                          inputProps={{
                            'aria-label': `${index + 1}. Member Firm CRD#`,
                          }}
                          showHelperTextInTooltip
                          name={`finraMemberFirms.${formIndex}.firmCrdNumber`}
                          placeholder={`Original value: ${memberFirmsInitialValues[formIndex].firmCrdNumber}`}
                          required
                        />
                      </TableCell>
                      <TableCell size="medium">
                        <FormikTextField
                          required
                          name={`finraMemberFirms.${formIndex}.firmMpid`}
                          inputProps={{
                            'aria-label': `${index + 1}. Member Firm MPID`,
                          }}
                        />
                      </TableCell>
                      <TableCell size="medium">
                        <RadioGroup row name={`finraMemberFirms.${formIndex}.excusedOrPassive`}>
                          {excusedOrPassiveOptions.map(option => (
                            <FormControlLabel
                              key={option.value}
                              value={option.value}
                              control={
                                <Radio
                                  checked={
                                    values.finraMemberFirms[formIndex].excusedOrPassive ===
                                    option.value
                                  }
                                  onChange={handleChange}
                                />
                              }
                              label={option.label}
                            />
                          ))}
                        </RadioGroup>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            ))}
          </Table>
        </TableContainer>
      </Panel.Content>
    </Panel>
  );
};

export default MemberFirmsSubForm;
