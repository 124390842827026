import { useCheckAccountType } from '@cmg/auth';
import { FlexContainer, UUID } from '@cmg/common';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import ServerErrorsBanner from '../../../../common/components/indicators/server-error/ServerErrorsBanner';
import NotAccessible from '../../../../common/components/layout/not-accessible/NotAccessible';
import Spinner from '../../../../common/components/overlays/spinner/Spinner';
import useCheckCoveredInstitutionalIndicationAccess from '../../../../common/util/check-access/useCheckCoveredInstitutionalIndicationAccess';
import useGetPublishedOfferingEntitlementsAndCheckInstitutionalIndicationAccess from '../../../../common/util/check-access/useGetPublishedOfferingEntitlementsAndCheckInstitutionalIndicationAccess';
import { useCmgEntityKeyContext } from '../../contexts/CmgEntityKeyContext';
import BuySideUpdateHistory from './components/indication-update-history/BuySideUpdateHistory';
import CoveredIndicationUpdateHistory from './components/indication-update-history/CoveredIndicationUpdateHistory';
import IndicationUpdateHistory from './components/indication-update-history/IndicationUpdateHistory';
import {
  useUpdateHistory_BasicPublishedOfferingQuery,
  useUpdateHistory_CoveredIndicationQuery,
  useUpdateHistory_IndicationQuery,
} from './graphql';
import { HistoryPanel } from './HistoryPanel';

export type Props = RouteComponentProps<{
  readonly offeringId: UUID;
}>;

const UpdateHistoryRoute: React.FC<Props> = ({ match }) => {
  const { cmgEntityKey } = useCmgEntityKeyContext();
  const isBuySide = useCheckAccountType('BUY_SIDE');
  const offeringId = match.params.offeringId;

  const { canRead: canReadIndication, loading: indicationAccessLoading } =
    useGetPublishedOfferingEntitlementsAndCheckInstitutionalIndicationAccess({ offeringId });

  const { canRead: canReadCoveredIndication } = useCheckCoveredInstitutionalIndicationAccess();

  const {
    data: offeringData,
    loading: offeringLoading,
    error: offeringError,
  } = useUpdateHistory_BasicPublishedOfferingQuery({ variables: { id: offeringId } });

  const { data, loading, error } = useUpdateHistory_IndicationQuery({
    variables: {
      offeringId,
      cmgEntityKey,
    },
    skip: canReadCoveredIndication || !cmgEntityKey || !canReadIndication,
    fetchPolicy: 'cache-and-network',
  });

  const {
    data: coveredIndicationsQueryData,
    error: coveredIndicationsQueryError,
    loading: coveredIndicationsQueryLoading,
  } = useUpdateHistory_CoveredIndicationQuery({
    variables: { offeringId, cmgEntityKey: cmgEntityKey! },
    skip: !canReadCoveredIndication || !cmgEntityKey,
    fetchPolicy: 'cache-and-network',
  });

  const indicationId =
    data?.institutionalIndication?.id ||
    coveredIndicationsQueryData?.coveredIndicationByCmgEntityKey?.id;

  if (!indicationAccessLoading && !canReadIndication) {
    return (
      <HistoryPanel>
        <NotAccessible subject="Update History" />
      </HistoryPanel>
    );
  }

  if (indicationAccessLoading || loading || coveredIndicationsQueryLoading || offeringLoading) {
    return <Spinner show={true} fullHeight={true} />;
  }

  const bannerError = error || offeringError;
  if (bannerError) {
    return (
      <HistoryPanel>
        <ServerErrorsBanner error={bannerError} />
      </HistoryPanel>
    );
  }

  if (coveredIndicationsQueryError) {
    return (
      <HistoryPanel>
        <ServerErrorsBanner error={coveredIndicationsQueryError} />
      </HistoryPanel>
    );
  }

  if (canReadCoveredIndication) {
    return (
      <FlexContainer direction="column">
        <HistoryPanel>
          {indicationId && (
            <CoveredIndicationUpdateHistory
              offeringId={offeringId}
              indicationId={indicationId}
              pricingCurrencyCode={offeringData?.basicPublishedOffering.pricingCurrencyCode}
              securityTypeDisplayName={
                offeringData?.basicPublishedOffering.securityTypeDisplayName ?? undefined
              }
            />
          )}
        </HistoryPanel>
      </FlexContainer>
    );
  }

  if (isBuySide) {
    return (
      <FlexContainer direction="column">
        <HistoryPanel>
          <BuySideUpdateHistory
            offeringId={offeringId}
            pricingCurrencyCode={offeringData?.basicPublishedOffering.pricingCurrencyCode}
            securityTypeDisplayName={
              offeringData?.basicPublishedOffering.securityTypeDisplayName ?? undefined
            }
          />
        </HistoryPanel>
      </FlexContainer>
    );
  }

  return (
    <FlexContainer direction="column">
      <HistoryPanel>
        {indicationId && (
          <IndicationUpdateHistory
            offeringId={offeringId}
            indicationId={indicationId}
            pricingCurrencyCode={offeringData?.basicPublishedOffering.pricingCurrencyCode}
            securityTypeDisplayName={
              offeringData?.basicPublishedOffering.securityTypeDisplayName ?? undefined
            }
          />
        )}
      </HistoryPanel>
    </FlexContainer>
  );
};

export default UpdateHistoryRoute;
