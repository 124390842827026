import { useStoredPreference } from '@cmg/auth';

import { DemandConfigValues } from '../types';

export const useStoredDemandConfig = ({
  offeringId,
  oidcUserId,
  initialValue,
}: {
  offeringId: string;
  oidcUserId: string;
  initialValue: DemandConfigValues | null;
}) => {
  const [storedDemandConfig, setStoredDemandConfig] =
    useStoredPreference<DemandConfigValues | null>({
      key: `demand_config_${offeringId}_${oidcUserId}`,
      initialValue,
      storageType: 'Local',
    });
  return { storedDemandConfig, setStoredDemandConfig };
};
