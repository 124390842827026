import { UUID } from '@cmg/common';
import { Alert, Box, Stack } from '@cmg/design-system';
import React from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';

import Loading from '../../../../common/components/indicators/loading/Loading';
import InvestorSearch from '../../../order-book/components/InvestorSearch';
import { SidePanelTitle } from '../../components/side-bar/components/design-system';
import { useCmgEntityKeyContext } from '../../contexts/CmgEntityKeyContext';
import { OfferingSidePanelRouteContext } from '../../OfferingSidePanelContainer';
import offeringSidePanelSellSideRouteFactory from '../../offeringSidePanelSellSideRouteFactory';
import { useNotes_IndicationInvestorQuery } from './graphql';
import Notes from './Notes';

export type Props = RouteComponentProps<{
  offeringId: UUID;
}>;

const NotesRoute: React.FC<Props> = ({ match }) => {
  const { cmgEntityKey } = useCmgEntityKeyContext();
  const baseSidePanelUrl = React.useContext(OfferingSidePanelRouteContext);
  const history = useHistory();
  const { offeringId } = match.params;

  const {
    data: indicationsData,
    loading: indicationsLoading,
    error: indicationsError,
  } = useNotes_IndicationInvestorQuery({ variables: { offeringId } });

  const indications =
    indicationsData?.basicPublishedOffering?.orderBook.institutionalIndications ?? [];
  const currentIndication = indications.find(
    i => i.investorInformation.cmgEntityKey === cmgEntityKey
  );

  if (indicationsLoading) {
    return <Loading />;
  }
  if (indicationsError) {
    return <Alert severity="error">{indicationsError.message}</Alert>;
  }

  return (
    <div>
      <SidePanelTitle>Notes</SidePanelTitle>
      <Stack direction="row" justifyContent="center" alignItems="center">
        <Box data-testid="investor-search" width={502}>
          <InvestorSearch
            investors={indications.map(ds => {
              return {
                key: ds.investorInformation.cmgEntityKey!,
                name:
                  ds.investorInformation.bankInvestorName ?? ds.investorInformation.cmgEntityName!,
              };
            })}
            onChange={key => {
              key &&
                history.push(
                  baseSidePanelUrl +
                    offeringSidePanelSellSideRouteFactory.orderBookNotes.getUrlPath({
                      cmgEntityKey: key,
                    })
                );
            }}
            currentKey={cmgEntityKey!}
            placehorder="Select an Investor"
          />
        </Box>
      </Stack>

      {currentIndication && (
        <Notes offeringId={offeringId} indicationId={currentIndication.id}></Notes>
      )}
    </div>
  );
};

export default NotesRoute;
