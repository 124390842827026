import { CellRenderers, formatters } from '@cmg/data-grid';

import { IndicationStatus } from '../../../../../../graphql';
import type { DemandGridServerColDef } from '../../types';
import { getCellRendererSelector, getValueFormatter } from '../columns.model';
import { getIndicationStatusColor } from './IndicationStatusCellRenderer.model';

/**
 * Exported for testing purposes
 */
export const cellRendererSelector = getCellRendererSelector<IndicationStatus>({
  gridRow: {
    component: CellRenderers.DotStatusCellRenderer,
    params: {
      getStatusColor: getIndicationStatusColor,
    },
  },
});

/**
 * Exported for testing purposes
 */
export const valueFormatter = getValueFormatter<IndicationStatus>({
  gridRow: formatters.enumValueFormatter,
});

const statusColDef: DemandGridServerColDef<IndicationStatus> = {
  field: 'status',
  headerName: 'IOI Status',
  width: 130,
  valueFormatter,
  cellRendererSelector,
  filter: 'agSetColumnFilter',
  filterParams: {
    values: [IndicationStatus.Active, IndicationStatus.Cancelled],
    valueFormatter: formatters.enumValueFormatter,
  },
};

export default statusColDef;
