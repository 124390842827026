/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SideBar_LimitedPublishedOfferingPartsFragment = {
  readonly __typename?: 'OfferingLimited';
  readonly id: string;
  readonly type: Types.OfferingType;
  readonly status: Types.OfferingStatus;
  readonly isExecutingOnPlatform: boolean;
  readonly issuerName: string;
  readonly issuerSector?: Types.Sector | null;
  readonly isUsOffering?: boolean | null;
  readonly pricingCurrencyCode: string;
  readonly pricingInstrumentStockSymbol?: string | null;
  readonly pricingDate?: string | null;
  readonly publicFilingDate?: string | null;
  readonly launchDate?: string | null;
  readonly firstTradeDate?: string | null;
  readonly postponedDate?: string | null;
  readonly sizeInCurrency?: number | null;
  readonly sizeInShares?: number | null;
  readonly priceRangeLow?: number | null;
  readonly priceRangeHigh?: number | null;
  readonly pricingInstrumentCountryCode?: string | null;
  readonly pricingInstrumentCountryDisplayName?: string | null;
  readonly pricingInstrumentRegion?: string | null;
  readonly managers: ReadonlyArray<{
    readonly __typename?: 'ManagerLimited';
    readonly cmgEntityKey: string;
    readonly firmName: string;
    readonly role: Types.ManagerRole;
  }>;
};

export type SideBar_LimitedPublishedOfferingQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type SideBar_LimitedPublishedOfferingQuery = {
  readonly __typename?: 'Query';
  readonly limitedPublishedOffering: {
    readonly __typename?: 'OfferingLimited';
    readonly id: string;
    readonly type: Types.OfferingType;
    readonly status: Types.OfferingStatus;
    readonly isExecutingOnPlatform: boolean;
    readonly issuerName: string;
    readonly issuerSector?: Types.Sector | null;
    readonly isUsOffering?: boolean | null;
    readonly pricingCurrencyCode: string;
    readonly pricingInstrumentStockSymbol?: string | null;
    readonly pricingDate?: string | null;
    readonly publicFilingDate?: string | null;
    readonly launchDate?: string | null;
    readonly firstTradeDate?: string | null;
    readonly postponedDate?: string | null;
    readonly sizeInCurrency?: number | null;
    readonly sizeInShares?: number | null;
    readonly priceRangeLow?: number | null;
    readonly priceRangeHigh?: number | null;
    readonly pricingInstrumentCountryCode?: string | null;
    readonly pricingInstrumentCountryDisplayName?: string | null;
    readonly pricingInstrumentRegion?: string | null;
    readonly managers: ReadonlyArray<{
      readonly __typename?: 'ManagerLimited';
      readonly cmgEntityKey: string;
      readonly firmName: string;
      readonly role: Types.ManagerRole;
    }>;
  };
};

export const SideBar_LimitedPublishedOfferingPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SideBar_LimitedPublishedOfferingParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'OfferingLimited' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isExecutingOnPlatform' } },
          { kind: 'Field', name: { kind: 'Name', value: 'issuerName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'issuerSector' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isUsOffering' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingCurrencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingInstrumentStockSymbol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'publicFilingDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'launchDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstTradeDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postponedDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sizeInCurrency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sizeInShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceRangeLow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceRangeHigh' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingInstrumentCountryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingInstrumentCountryDisplayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingInstrumentRegion' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'managers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const SideBar_LimitedPublishedOfferingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SideBar_LimitedPublishedOffering' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'limitedPublishedOffering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'offeringId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SideBar_LimitedPublishedOfferingParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SideBar_LimitedPublishedOfferingParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'OfferingLimited' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isExecutingOnPlatform' } },
          { kind: 'Field', name: { kind: 'Name', value: 'issuerName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'issuerSector' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isUsOffering' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingCurrencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingInstrumentStockSymbol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'publicFilingDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'launchDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstTradeDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postponedDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sizeInCurrency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sizeInShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceRangeLow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceRangeHigh' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingInstrumentCountryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingInstrumentCountryDisplayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingInstrumentRegion' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'managers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useSideBar_LimitedPublishedOfferingQuery__
 *
 * To run a query within a React component, call `useSideBar_LimitedPublishedOfferingQuery` and pass it any options that fit your needs.
 * When your component renders, `useSideBar_LimitedPublishedOfferingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSideBar_LimitedPublishedOfferingQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useSideBar_LimitedPublishedOfferingQuery(
  baseOptions: Apollo.QueryHookOptions<
    SideBar_LimitedPublishedOfferingQuery,
    SideBar_LimitedPublishedOfferingQueryVariables
  > &
    (
      | { variables: SideBar_LimitedPublishedOfferingQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SideBar_LimitedPublishedOfferingQuery,
    SideBar_LimitedPublishedOfferingQueryVariables
  >(SideBar_LimitedPublishedOfferingDocument, options);
}
export function useSideBar_LimitedPublishedOfferingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SideBar_LimitedPublishedOfferingQuery,
    SideBar_LimitedPublishedOfferingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SideBar_LimitedPublishedOfferingQuery,
    SideBar_LimitedPublishedOfferingQueryVariables
  >(SideBar_LimitedPublishedOfferingDocument, options);
}
export function useSideBar_LimitedPublishedOfferingSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        SideBar_LimitedPublishedOfferingQuery,
        SideBar_LimitedPublishedOfferingQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SideBar_LimitedPublishedOfferingQuery,
    SideBar_LimitedPublishedOfferingQueryVariables
  >(SideBar_LimitedPublishedOfferingDocument, options);
}
export type SideBar_LimitedPublishedOfferingQueryHookResult = ReturnType<
  typeof useSideBar_LimitedPublishedOfferingQuery
>;
export type SideBar_LimitedPublishedOfferingLazyQueryHookResult = ReturnType<
  typeof useSideBar_LimitedPublishedOfferingLazyQuery
>;
export type SideBar_LimitedPublishedOfferingSuspenseQueryHookResult = ReturnType<
  typeof useSideBar_LimitedPublishedOfferingSuspenseQuery
>;
export type SideBar_LimitedPublishedOfferingQueryResult = Apollo.QueryResult<
  SideBar_LimitedPublishedOfferingQuery,
  SideBar_LimitedPublishedOfferingQueryVariables
>;

import { makeMockResponse } from '../../../../../../graphql/mock-response';

export const makeSideBar_LimitedPublishedOfferingMockResponse = makeMockResponse<
  SideBar_LimitedPublishedOfferingQueryVariables,
  SideBar_LimitedPublishedOfferingQuery
>(SideBar_LimitedPublishedOfferingDocument);
