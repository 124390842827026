import isEmpty from 'lodash/isEmpty';
import { useMemo } from 'react';
import * as yup from 'yup';

import {
  UseFormikErrorsProps as UseFormikErrorsPropsBase,
  UseFormikErrorsResult,
} from '../../../common/util/useFormikErrors';
import { getFormikErrorFieldLabels } from '../../../common/util/useFormikErrors.model';

export type UseFormikErrorsProps<T extends yup.Maybe<yup.AnyObject>> = Pick<
  UseFormikErrorsPropsBase<T>,
  'errors' | 'touched' | 'validationSchema'
>;

export function useFormikErrors<TValues extends yup.Maybe<yup.AnyObject>>({
  errors,
  touched,
  validationSchema,
}: UseFormikErrorsProps<TValues>): UseFormikErrorsResult {
  const invalidFormFieldLabels = useMemo(
    () => getFormikErrorFieldLabels(errors, validationSchema),
    [errors, validationSchema]
  );

  const showInvalidFields = useMemo(() => {
    return !isEmpty(touched) && !!invalidFormFieldLabels.length;
  }, [invalidFormFieldLabels.length, touched]);

  return {
    invalidFormFieldLabels,
    showInvalidFields,
  };
}
