import { Typography } from '@cmg/design-system';
import React from 'react';

import { IndicationStatus } from '../../../../../../graphql';
import { getFirmDisplayName } from '../../../../utils';
import { DemandGridCellRendererProps } from '../../types';
import { sortAcknowledgements } from './AcknowledgementsCellRenderer.model';

export type Props = DemandGridCellRendererProps<string>;

const AcknowledgementsCellRenderer: React.FC<Props> = ({ data, context }) => {
  const sortedAcknowledgements = React.useMemo(
    () => sortAcknowledgements(data?.acknowledgements, context.oidcUserCmgEntityKey),
    [data?.acknowledgements, context.oidcUserCmgEntityKey]
  );
  const hasRevisedInAdditional = React.useMemo(
    () => sortedAcknowledgements.slice(3).some(ack => ack.isRevised),
    [sortedAcknowledgements]
  );
  const content = React.useMemo(
    () =>
      sortedAcknowledgements.slice(0, 3).map(({ isRevised, manager }, index) => {
        const firmName = getFirmDisplayName(manager);
        const isUsersFirm = manager.firmKey === context.oidcUserCmgEntityKey;
        return (
          <Typography
            key={index}
            variant="body2"
            component="span"
            fontWeight={isUsersFirm && isRevised ? 'bold' : undefined}
            color={isRevised ? theme => theme.palette.warning.dark : undefined}
          >
            {firmName}
            {index < 2 && index < sortedAcknowledgements.length - 1 && ', '}
          </Typography>
        );
      }),
    [context.oidcUserCmgEntityKey, sortedAcknowledgements]
  );

  if (data?.status === IndicationStatus.Duplicate) {
    return <span>-</span>;
  }

  return (
    <Typography variant="body2" noWrap component="span" overflow="hidden" textOverflow="ellipsis">
      {content}
      {sortedAcknowledgements.length > 3 && (
        <Typography
          variant="body2"
          component="span"
          color={hasRevisedInAdditional ? theme => theme.palette.warning.dark : undefined}
        >
          &nbsp;+{sortedAcknowledgements.length - 3}...
        </Typography>
      )}
    </Typography>
  );
};

export default AcknowledgementsCellRenderer;
