/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UnderwriterFirmSearch_UnderwriterFragment = {
  readonly __typename?: 'FirmLimited';
  readonly id: string;
  readonly cmgEntityKey: string;
  readonly displayName?: string | null;
  readonly isOnPlatform: boolean;
  readonly mpid?: string | null;
  readonly crd?: string | null;
};

export type UnderwriterFirmSearch_UnderwritersQueryVariables = Types.Exact<{
  searchedString: Types.Scalars['String'];
  page: Types.Scalars['Int'];
}>;

export type UnderwriterFirmSearch_UnderwritersQuery = {
  readonly __typename?: 'Query';
  readonly underwriters: {
    readonly __typename?: 'FirmLimitedPagedList';
    readonly data: ReadonlyArray<{
      readonly __typename?: 'FirmLimited';
      readonly id: string;
      readonly cmgEntityKey: string;
      readonly displayName?: string | null;
      readonly isOnPlatform: boolean;
      readonly mpid?: string | null;
      readonly crd?: string | null;
    }>;
    readonly pagination: {
      readonly __typename?: 'PaginationData';
      readonly hasNext?: boolean | null;
    };
  };
};

export const UnderwriterFirmSearch_UnderwriterFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UnderwriterFirmSearch_Underwriter' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FirmLimited' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isOnPlatform' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mpid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'crd' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const UnderwriterFirmSearch_UnderwritersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UnderwriterFirmSearch_Underwriters' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'searchedString' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'underwriters' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'searchedString' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'page' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'perPage' },
                      value: { kind: 'IntValue', value: '25' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UnderwriterFirmSearch_Underwriter' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pagination' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'hasNext' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UnderwriterFirmSearch_Underwriter' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FirmLimited' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isOnPlatform' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mpid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'crd' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useUnderwriterFirmSearch_UnderwritersQuery__
 *
 * To run a query within a React component, call `useUnderwriterFirmSearch_UnderwritersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnderwriterFirmSearch_UnderwritersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnderwriterFirmSearch_UnderwritersQuery({
 *   variables: {
 *      searchedString: // value for 'searchedString'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useUnderwriterFirmSearch_UnderwritersQuery(
  baseOptions: Apollo.QueryHookOptions<
    UnderwriterFirmSearch_UnderwritersQuery,
    UnderwriterFirmSearch_UnderwritersQueryVariables
  > &
    (
      | { variables: UnderwriterFirmSearch_UnderwritersQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    UnderwriterFirmSearch_UnderwritersQuery,
    UnderwriterFirmSearch_UnderwritersQueryVariables
  >(UnderwriterFirmSearch_UnderwritersDocument, options);
}
export function useUnderwriterFirmSearch_UnderwritersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UnderwriterFirmSearch_UnderwritersQuery,
    UnderwriterFirmSearch_UnderwritersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    UnderwriterFirmSearch_UnderwritersQuery,
    UnderwriterFirmSearch_UnderwritersQueryVariables
  >(UnderwriterFirmSearch_UnderwritersDocument, options);
}
export function useUnderwriterFirmSearch_UnderwritersSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        UnderwriterFirmSearch_UnderwritersQuery,
        UnderwriterFirmSearch_UnderwritersQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    UnderwriterFirmSearch_UnderwritersQuery,
    UnderwriterFirmSearch_UnderwritersQueryVariables
  >(UnderwriterFirmSearch_UnderwritersDocument, options);
}
export type UnderwriterFirmSearch_UnderwritersQueryHookResult = ReturnType<
  typeof useUnderwriterFirmSearch_UnderwritersQuery
>;
export type UnderwriterFirmSearch_UnderwritersLazyQueryHookResult = ReturnType<
  typeof useUnderwriterFirmSearch_UnderwritersLazyQuery
>;
export type UnderwriterFirmSearch_UnderwritersSuspenseQueryHookResult = ReturnType<
  typeof useUnderwriterFirmSearch_UnderwritersSuspenseQuery
>;
export type UnderwriterFirmSearch_UnderwritersQueryResult = Apollo.QueryResult<
  UnderwriterFirmSearch_UnderwritersQuery,
  UnderwriterFirmSearch_UnderwritersQueryVariables
>;

import { makeMockResponse } from '../../../../../../graphql/mock-response';

export const makeUnderwriterFirmSearch_UnderwritersMockResponse = makeMockResponse<
  UnderwriterFirmSearch_UnderwritersQueryVariables,
  UnderwriterFirmSearch_UnderwritersQuery
>(UnderwriterFirmSearch_UnderwritersDocument);
