import type { UUID } from '@cmg/common';
import { numericUtil } from '@cmg/common';
import type {
  AddFilterExtrasFn,
  CellRendererSelectorFunc,
  GetFilterValueFn,
  GridApi,
  ValueGetterParams,
  ValueSetterParams,
} from '@cmg/data-grid';
import { getListFilterValueGetter, getSortFieldPath } from '@cmg/data-grid';
import find from 'lodash/find';
import has from 'lodash/has';
import set from 'lodash/set';
import * as yup from 'yup';

import type { AllocationDtoFilterInput } from '../../../../../../graphql';
import type {
  OrderBook_InstitutionalDemand_AllocationPartsFragment,
  OrderBook_InstitutionalDemand_AllocationSetPartsFragment,
  OrderBook_InstitutionalDemand_GridRowPartsFragment,
} from '../../../graphql/__generated__';
import type { DemandGridDataContext, DemandGridRowData, DemandGridServerColDef } from '../../types';
import { getUpdatedAllocations, getValueFormatter } from '../columns.model';
import { AllocationTotalCellRenderer } from './AllocationTotalCellRenderer';

/**
 * Supporting an empty string as potential value as hitting a backspace in the grid calls value setter with an empty string as new value
 */
export type Value = OrderBook_InstitutionalDemand_AllocationPartsFragment['shares'] | '';

type Allocation = { allocationSetId: string; shares: number | null | undefined };

/**
 * Exported for testing purposes only
 */
export const valueFormatter = getValueFormatter<Value>({
  gridRow: ({ value }) => numericUtil.getDisplayValueForInteger(value),
  totalRow: ({ value }) => numericUtil.getDisplayValueForInteger(value),
});

type TotalAllocationWithExplicitChangesProps = {
  calculateExplicitChanges: boolean;
  allocations: Readonly<Allocation[]>;
  allocationSetId: UUID;
  explicitChanges: DemandGridDataContext['explicitChanges'];
  api: GridApi<DemandGridRowData>;
};

const getTotalAllocationWithExplicitChanges = ({
  allocations,
  allocationSetId,
  explicitChanges,
  api,
  calculateExplicitChanges,
}: TotalAllocationWithExplicitChangesProps) => {
  const allocationShares = find(allocations, { allocationSetId })?.shares ?? null;

  if (!calculateExplicitChanges || allocationShares === null) {
    return allocationShares;
  }

  const totalExplicitChangesDiff = Object.entries(explicitChanges).reduce((prev, [rowId, curr]) => {
    const explicitChange = curr.allocations[allocationSetId];

    if (explicitChange === undefined) {
      return prev;
    }

    const allocations: OrderBook_InstitutionalDemand_GridRowPartsFragment['allocations'] =
      api.getRowNode(rowId)?.data.allocations;

    const allocation = allocations?.find(item => item.allocationSetId === allocationSetId);

    return prev + (explicitChange ?? 0) - (allocation?.shares ?? 0);
  }, 0);

  return allocationShares + totalExplicitChangesDiff;
};

/**
 * Exported for testing purposes only
 *
 * @param allocationSetId - The allocation set id to get the value for
 * @param calculateExplicitChanges - If true, the explicit changes diff will be calculated
 */
export const createValueGetter =
  (allocationSetId: string, calculateExplicitChanges: boolean) =>
  ({ data, context, api }: ValueGetterParams<DemandGridRowData, Value>) => {
    const { explicitChanges } = context as DemandGridDataContext;
    const isGridRow = data?.__typename === 'SyndicateInstitutionalGridRow';
    const isTotalRow = data?.__typename === 'SyndicateGridTotalsRow';

    if (!data) {
      return null;
    }

    if (isGridRow && has(explicitChanges[data.id]?.allocations, allocationSetId)) {
      return explicitChanges[data.id]?.allocations[allocationSetId];
    }

    if (isGridRow) {
      return find(data.allocations, { allocationSetId })?.shares ?? null;
    }

    if (isTotalRow) {
      return getTotalAllocationWithExplicitChanges({
        api,
        allocations: data?.allocations ?? [],
        allocationSetId,
        explicitChanges,
        calculateExplicitChanges,
      });
    }

    return null;
  };

export const createValueSetter =
  (allocationSet: OrderBook_InstitutionalDemand_AllocationSetPartsFragment) =>
  ({ data, newValue }: ValueSetterParams<DemandGridRowData, Value>) => {
    const isGridRow = data?.__typename === 'SyndicateInstitutionalGridRow';

    if (!isGridRow || (allocationSet.isFinal && allocationSet.isReleased)) {
      return false;
    }

    /**
     * The newValue equals to an empty string when user clears the cell by pressing backspace.
     */
    const nextValue = newValue === '' ? null : newValue;
    const updatedAllocations = getUpdatedAllocations(data.allocations, allocationSet.id, nextValue);

    set(data, 'allocations', updatedAllocations);

    return true;
  };

export const createCellRendererSelector =
  (allocationSetId: UUID): CellRendererSelectorFunc<DemandGridRowData, Value> =>
  params => {
    if (params.data?.__typename === 'SyndicateGridTotalsRow') {
      return { component: AllocationTotalCellRenderer, params: { ...params, allocationSetId } };
    }
  };

export const allocationColIdMatch = /^allocations_.+$/i;

const getFilterValue: GetFilterValueFn<AllocationDtoFilterInput> = value => {
  return { shares: { ...value } };
};

const createAddFilterExtras =
  (
    allocationSetId: string
  ): AddFilterExtrasFn<AllocationDtoFilterInput, AllocationDtoFilterInput> =>
  filterValue => {
    return {
      some: {
        ...filterValue.some,
        allocationSetId: { eq: allocationSetId },
      },
    };
  };

/**
 * Exported for testing purposes only
 */
export const sortFieldPath = getSortFieldPath(allocationColIdMatch, 'allocations_');

/**
 * Exported for testing purposes only
 */
export const isEditable = (isAuthor: boolean, data: DemandGridRowData | null | undefined) => {
  return isAuthor && data?.__typename === 'SyndicateInstitutionalGridRow';
};

export const cellEditorValidationSchema = yup.object().shape({
  cellEditorField: yup.number().integer().min(0).required().nullable().label('Allocation shares'),
});

const getAllocationColDefs = (
  allocationSets: DemandGridDataContext['allocationSets'],
  oidcUserCmgEntityKey: DemandGridDataContext['oidcUserCmgEntityKey']
) => {
  const { drafts, final } = allocationSets;

  const constColDefBase: Partial<DemandGridServerColDef<Value>> = {
    type: ['numericColumn', 'number'],
    cellDataType: 'number',
    filter: 'agNumberColumnFilter',
    valueFormatter,
    cellEditor: 'agNumberCellEditor',
    cellEditorParams: {
      validationSchema: cellEditorValidationSchema,
    },
    server: {
      sortFieldPath,
      filterFieldPath: 'allocations',
    },
  };

  // Draft allocation set column, including default and shared drafts
  const columnDefs = Object.values(drafts).map<DemandGridServerColDef<Value>>(
    (allocationSet, index) => {
      const isAuthor = allocationSet.author.firmKey === oidcUserCmgEntityKey;
      const headerName = `${allocationSet.name}${
        allocationSet.isDefault || !isAuthor ? ' - Allos' : ''
      }`;

      return {
        ...constColDefBase,
        colId: `allocations_${allocationSet.id}`,
        headerName,
        editable: ({ data }) => isEditable(isAuthor, data),
        cellRendererSelector: createCellRendererSelector(allocationSet.id),
        valueGetter: createValueGetter(allocationSet.id, false),
        valueSetter: createValueSetter(allocationSet),
        server: {
          ...constColDefBase.server,
          sortValueGetter: order => ({ allocationSetId: allocationSet.id, shares: order }),
          filterValueGetter: getListFilterValueGetter<
            AllocationDtoFilterInput,
            AllocationDtoFilterInput
          >(getFilterValue, createAddFilterExtras(allocationSet.id)),
        },
      };
    }
  );

  // Final allocation set column
  if (final) {
    const isAuthor = final.author.firmKey === oidcUserCmgEntityKey;

    columnDefs.unshift({
      ...constColDefBase,
      colId: `allocations_${final.id}`,
      headerName: `Final Allocations ${final.isReleased ? 'Released' : 'Unreleased'}`,
      cellRendererSelector: createCellRendererSelector(final.id),
      valueGetter: createValueGetter(final.id, final.isReleased),
      valueSetter: createValueSetter(final),
      editable: ({ data }) => {
        return isEditable(isAuthor, data);
      },
      server: {
        ...constColDefBase.server,
        sortValueGetter: order => ({ allocationSetId: final.id, shares: order }),
        filterValueGetter: getListFilterValueGetter<
          AllocationDtoFilterInput,
          AllocationDtoFilterInput
        >(getFilterValue, createAddFilterExtras(final.id)),
      },
    });
  }

  return columnDefs;
};

export default getAllocationColDefs;
