import { SelectField } from '@cmg/common';
import React from 'react';

type DeliveryInstrument = {
  id: string;
  isDepositaryReceipt: boolean;
  stockSymbol?: string | null;
  currencyCode?: string | null;
  countryCode?: string | null;
};

export type Props = {
  /**
   * The Delivery Instrument Select field name
   */
  name: string;
  /**
   * The field's test ID
   */
  testId?: string;
  /**
   * Takes up the width of it's parent when set
   */
  fullWidth?: boolean;
  /**
   * Whether or not the input is disabled.
   */
  disabled?: boolean;
  /**
   * Whether or not the field is required
   */
  required?: boolean;
  /**
   * Whether or not the field is clearable
   */
  isClearable?: boolean;
  /**
   * The list of Delivery Instruments;
   */
  deliveryInstruments: DeliveryInstrument[];
  securityTypeDisplayName: string;
};

type GetDeliveryInstrumentLabel = {
  deliveryInstrument?: DeliveryInstrument;
  securityTypeDisplayName?: string;
};

export const getDeliveryInstrumentLabel = ({
  deliveryInstrument,
  securityTypeDisplayName,
}: GetDeliveryInstrumentLabel) => {
  const deliveryInstrumentType = [
    deliveryInstrument?.isDepositaryReceipt && 'Depositary Receipt',
    securityTypeDisplayName,
  ]
    .filter(Boolean)
    .join(' ');
  const deliveryInstrumentCountry = `Country - ${deliveryInstrument?.countryCode}`;
  const deliveryInstrumentCurrency = `Currency - ${deliveryInstrument?.currencyCode}`;
  const deliveryInstrumentSymbol = `Symbol - ${deliveryInstrument?.stockSymbol}`;
  return `${deliveryInstrumentType}: ${deliveryInstrumentCountry}; ${deliveryInstrumentCurrency}${
    deliveryInstrument?.stockSymbol ? `; ${deliveryInstrumentSymbol}` : ''
  }`;
};

/**
 * Displays a Select Field for Delivery Instruments.
 */
export const DeliveryInstrumentSelectField: React.FC<Props> = ({
  name,
  testId,
  fullWidth,
  deliveryInstruments,
  disabled,
  required,
  isClearable,
  securityTypeDisplayName,
}) => {
  const options = deliveryInstruments.map(deliveryInstrument => {
    const label = getDeliveryInstrumentLabel({ deliveryInstrument, securityTypeDisplayName });

    return {
      label,
      value: deliveryInstrument.id,
    };
  });

  return (
    <SelectField
      placeholder="Select Instrument..."
      name={name}
      options={options}
      fullWidth={fullWidth}
      testId={testId}
      disabled={disabled}
      required={required}
      isClearable={isClearable}
    />
  );
};

export default DeliveryInstrumentSelectField;
