import { CellRenderers, getRowAlertCol } from '@cmg/data-grid';

import type { OrderBook_InstitutionalDemand_GridRowPartsFragment } from '../../../graphql/__generated__';
import type { DemandGridServerColDef } from '../../types';
import { AlertCodes, alertCodesMap, getCellRendererSelector } from '../columns.model';
import { getRowTooltipContent } from './TooltipContent';
import { getVariant } from './TooltipContent.model';

export type Value = OrderBook_InstitutionalDemand_GridRowPartsFragment['alert'];

/**
 * Exported for testing purposes
 */
export const cellRendererSelector = getCellRendererSelector<Value>({
  gridRow: { component: CellRenderers.RowAlertCellRenderer },
});

const rowAlertColDef: DemandGridServerColDef<Value> = {
  ...getRowAlertCol({
    field: 'alert',
    pinned: 'left',
    tooltipField: 'alert',
    tooltipComponentParams: {
      getContent: getRowTooltipContent,
      getVariant,
    },
    cellRenderer: undefined,
    cellRendererSelector,
    filterParams: {
      values: Object.keys(alertCodesMap),
      valueFormatter: ({ value }: { value: string }) => {
        return alertCodesMap[value].filterLabel ?? value;
      },
    },
  }),
  server: {
    sortFieldPath: 'alert.sortValue',
    filterFieldPath: 'alert.alertCodes',
    filterValueGetter: filterModel => {
      const filterParam = filterModel?.in;
      if (Array.isArray(filterParam) && filterParam.length === 0) {
        return {
          all: {
            nin: Object.values(AlertCodes),
          },
        };
      }
      return { some: { ...filterModel } };
    },
  },
};

export default rowAlertColDef;
