/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FinalSettlement_DesignationAllocationPartsFragment = {
  readonly __typename?: 'DesignationAllocation';
  readonly id: string;
  readonly shares: number;
  readonly investor: {
    readonly __typename?: 'DesignationInvestor';
    readonly id: string;
    readonly cmgEntityKey: string;
    readonly cmgEntityName: string;
    readonly bankInvestorKey?: string | null;
    readonly bankInvestorName?: string | null;
  };
};

export type FinalSettlement_DesignationInvestorPartsFragment = {
  readonly __typename?: 'DesignationInvestor';
  readonly id: string;
  readonly cmgEntityKey: string;
  readonly cmgEntityName: string;
  readonly bankInvestorKey?: string | null;
  readonly bankInvestorName?: string | null;
};

export type FinalSettlement_DesignationManagerPartsFragment = {
  readonly __typename?: 'DesignationManager';
  readonly id: string;
  readonly cmgEntityKey: string;
  readonly cmgEntityName: string;
};

export type FinalSettlement_InvestorDesignationPartsFragment = {
  readonly __typename?: 'InvestorDesignation';
  readonly id: string;
  readonly version?: string | null;
  readonly isPublished?: boolean | null;
  readonly allocation: {
    readonly __typename?: 'DesignationAllocation';
    readonly id: string;
    readonly shares: number;
    readonly investor: {
      readonly __typename?: 'DesignationInvestor';
      readonly id: string;
      readonly cmgEntityKey: string;
      readonly cmgEntityName: string;
      readonly bankInvestorKey?: string | null;
      readonly bankInvestorName?: string | null;
    };
  };
  readonly designations: ReadonlyArray<{
    readonly __typename?: 'ManagerDesignation';
    readonly id: string;
    readonly shares?: number | null;
    readonly fixedEconomicsShares?: number | null;
    readonly sellingConcessionPercentageChanged: boolean;
    readonly manager: {
      readonly __typename?: 'DesignationManager';
      readonly id: string;
      readonly cmgEntityKey: string;
      readonly cmgEntityName: string;
    };
  }>;
};

export type FinalSettlement_ManagerDesignationPartsFragment = {
  readonly __typename?: 'ManagerDesignation';
  readonly id: string;
  readonly shares?: number | null;
  readonly fixedEconomicsShares?: number | null;
  readonly sellingConcessionPercentageChanged: boolean;
  readonly manager: {
    readonly __typename?: 'DesignationManager';
    readonly id: string;
    readonly cmgEntityKey: string;
    readonly cmgEntityName: string;
  };
};

export type FinalSettlement_SettlementInvestorDesignationQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  indicationId: Types.Scalars['UUID'];
}>;

export type FinalSettlement_SettlementInvestorDesignationQuery = {
  readonly __typename?: 'Query';
  readonly designationByIndicationId: {
    readonly __typename?: 'InvestorDesignation';
    readonly id: string;
    readonly version?: string | null;
    readonly isPublished?: boolean | null;
    readonly allocation: {
      readonly __typename?: 'DesignationAllocation';
      readonly id: string;
      readonly shares: number;
      readonly investor: {
        readonly __typename?: 'DesignationInvestor';
        readonly id: string;
        readonly cmgEntityKey: string;
        readonly cmgEntityName: string;
        readonly bankInvestorKey?: string | null;
        readonly bankInvestorName?: string | null;
      };
    };
    readonly designations: ReadonlyArray<{
      readonly __typename?: 'ManagerDesignation';
      readonly id: string;
      readonly shares?: number | null;
      readonly fixedEconomicsShares?: number | null;
      readonly sellingConcessionPercentageChanged: boolean;
      readonly manager: {
        readonly __typename?: 'DesignationManager';
        readonly id: string;
        readonly cmgEntityKey: string;
        readonly cmgEntityName: string;
      };
    }>;
  };
  readonly publishedOffering: {
    readonly __typename?: 'OfferingProfile';
    readonly id: string;
    readonly syndicate: {
      readonly __typename?: 'SyndicateProfile';
      readonly managers: ReadonlyArray<{
        readonly __typename?: 'ManagerProfile';
        readonly cmgEntityKey: string;
        readonly firmName: string;
      }>;
    };
  };
};

export type FinalSettlement_SettlementInvestorsQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type FinalSettlement_SettlementInvestorsQuery = {
  readonly __typename?: 'Query';
  readonly designationsGrid: {
    readonly __typename?: 'DesignationsGrid';
    readonly id: string;
    readonly designations: ReadonlyArray<{
      readonly __typename?: 'DesignationsGridItem';
      readonly allocation: {
        readonly __typename?: 'DesignationsGridAllocation';
        readonly id: string;
        readonly indicationId: string;
        readonly shares: number;
        readonly investorName: string;
        readonly investorCmgEntityKey: string;
      };
    }>;
  };
};

export const FinalSettlement_DesignationInvestorPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FinalSettlement_DesignationInvestorParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DesignationInvestor' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bankInvestorKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bankInvestorName' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const FinalSettlement_DesignationAllocationPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FinalSettlement_DesignationAllocationParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DesignationAllocation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FinalSettlement_DesignationInvestorParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FinalSettlement_DesignationInvestorParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DesignationInvestor' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bankInvestorKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bankInvestorName' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const FinalSettlement_DesignationManagerPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FinalSettlement_DesignationManagerParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DesignationManager' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityName' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const FinalSettlement_ManagerDesignationPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FinalSettlement_ManagerDesignationParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ManagerDesignation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'manager' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FinalSettlement_DesignationManagerParts' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fixedEconomicsShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sellingConcessionPercentageChanged' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FinalSettlement_DesignationManagerParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DesignationManager' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityName' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const FinalSettlement_InvestorDesignationPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FinalSettlement_InvestorDesignationParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'InvestorDesignation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FinalSettlement_DesignationAllocationParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'designations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FinalSettlement_ManagerDesignationParts' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPublished' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FinalSettlement_DesignationAllocationParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DesignationAllocation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FinalSettlement_DesignationInvestorParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FinalSettlement_DesignationInvestorParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DesignationInvestor' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bankInvestorKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bankInvestorName' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FinalSettlement_DesignationManagerParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DesignationManager' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityName' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FinalSettlement_ManagerDesignationParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ManagerDesignation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'manager' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FinalSettlement_DesignationManagerParts' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fixedEconomicsShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sellingConcessionPercentageChanged' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const FinalSettlement_SettlementInvestorDesignationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FinalSettlement_SettlementInvestorDesignation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'indicationId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'designationByIndicationId' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'indicationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'indicationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FinalSettlement_InvestorDesignationParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'publishedOffering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'syndicate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'managers' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FinalSettlement_DesignationAllocationParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DesignationAllocation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FinalSettlement_DesignationInvestorParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FinalSettlement_DesignationInvestorParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DesignationInvestor' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bankInvestorKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bankInvestorName' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FinalSettlement_DesignationManagerParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DesignationManager' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityName' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FinalSettlement_InvestorDesignationParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'InvestorDesignation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FinalSettlement_DesignationAllocationParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'designations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FinalSettlement_ManagerDesignationParts' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPublished' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FinalSettlement_ManagerDesignationParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ManagerDesignation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'manager' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FinalSettlement_DesignationManagerParts' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fixedEconomicsShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sellingConcessionPercentageChanged' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useFinalSettlement_SettlementInvestorDesignationQuery__
 *
 * To run a query within a React component, call `useFinalSettlement_SettlementInvestorDesignationQuery` and pass it any options that fit your needs.
 * When your component renders, `useFinalSettlement_SettlementInvestorDesignationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFinalSettlement_SettlementInvestorDesignationQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      indicationId: // value for 'indicationId'
 *   },
 * });
 */
export function useFinalSettlement_SettlementInvestorDesignationQuery(
  baseOptions: Apollo.QueryHookOptions<
    FinalSettlement_SettlementInvestorDesignationQuery,
    FinalSettlement_SettlementInvestorDesignationQueryVariables
  > &
    (
      | { variables: FinalSettlement_SettlementInvestorDesignationQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FinalSettlement_SettlementInvestorDesignationQuery,
    FinalSettlement_SettlementInvestorDesignationQueryVariables
  >(FinalSettlement_SettlementInvestorDesignationDocument, options);
}
export function useFinalSettlement_SettlementInvestorDesignationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FinalSettlement_SettlementInvestorDesignationQuery,
    FinalSettlement_SettlementInvestorDesignationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FinalSettlement_SettlementInvestorDesignationQuery,
    FinalSettlement_SettlementInvestorDesignationQueryVariables
  >(FinalSettlement_SettlementInvestorDesignationDocument, options);
}
export function useFinalSettlement_SettlementInvestorDesignationSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FinalSettlement_SettlementInvestorDesignationQuery,
        FinalSettlement_SettlementInvestorDesignationQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FinalSettlement_SettlementInvestorDesignationQuery,
    FinalSettlement_SettlementInvestorDesignationQueryVariables
  >(FinalSettlement_SettlementInvestorDesignationDocument, options);
}
export type FinalSettlement_SettlementInvestorDesignationQueryHookResult = ReturnType<
  typeof useFinalSettlement_SettlementInvestorDesignationQuery
>;
export type FinalSettlement_SettlementInvestorDesignationLazyQueryHookResult = ReturnType<
  typeof useFinalSettlement_SettlementInvestorDesignationLazyQuery
>;
export type FinalSettlement_SettlementInvestorDesignationSuspenseQueryHookResult = ReturnType<
  typeof useFinalSettlement_SettlementInvestorDesignationSuspenseQuery
>;
export type FinalSettlement_SettlementInvestorDesignationQueryResult = Apollo.QueryResult<
  FinalSettlement_SettlementInvestorDesignationQuery,
  FinalSettlement_SettlementInvestorDesignationQueryVariables
>;
export const FinalSettlement_SettlementInvestorsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FinalSettlement_SettlementInvestors' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'designationsGrid' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'designations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'allocation' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'FinalSettlement_DesignationsGridAllocation',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FinalSettlement_DesignationsGridAllocation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DesignationsGridAllocation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'indicationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorCmgEntityKey' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useFinalSettlement_SettlementInvestorsQuery__
 *
 * To run a query within a React component, call `useFinalSettlement_SettlementInvestorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFinalSettlement_SettlementInvestorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFinalSettlement_SettlementInvestorsQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useFinalSettlement_SettlementInvestorsQuery(
  baseOptions: Apollo.QueryHookOptions<
    FinalSettlement_SettlementInvestorsQuery,
    FinalSettlement_SettlementInvestorsQueryVariables
  > &
    (
      | { variables: FinalSettlement_SettlementInvestorsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FinalSettlement_SettlementInvestorsQuery,
    FinalSettlement_SettlementInvestorsQueryVariables
  >(FinalSettlement_SettlementInvestorsDocument, options);
}
export function useFinalSettlement_SettlementInvestorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FinalSettlement_SettlementInvestorsQuery,
    FinalSettlement_SettlementInvestorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FinalSettlement_SettlementInvestorsQuery,
    FinalSettlement_SettlementInvestorsQueryVariables
  >(FinalSettlement_SettlementInvestorsDocument, options);
}
export function useFinalSettlement_SettlementInvestorsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FinalSettlement_SettlementInvestorsQuery,
        FinalSettlement_SettlementInvestorsQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FinalSettlement_SettlementInvestorsQuery,
    FinalSettlement_SettlementInvestorsQueryVariables
  >(FinalSettlement_SettlementInvestorsDocument, options);
}
export type FinalSettlement_SettlementInvestorsQueryHookResult = ReturnType<
  typeof useFinalSettlement_SettlementInvestorsQuery
>;
export type FinalSettlement_SettlementInvestorsLazyQueryHookResult = ReturnType<
  typeof useFinalSettlement_SettlementInvestorsLazyQuery
>;
export type FinalSettlement_SettlementInvestorsSuspenseQueryHookResult = ReturnType<
  typeof useFinalSettlement_SettlementInvestorsSuspenseQuery
>;
export type FinalSettlement_SettlementInvestorsQueryResult = Apollo.QueryResult<
  FinalSettlement_SettlementInvestorsQuery,
  FinalSettlement_SettlementInvestorsQueryVariables
>;

import { makeMockResponse } from '../../../../../../../graphql/mock-response';

export const makeFinalSettlement_SettlementInvestorDesignationMockResponse = makeMockResponse<
  FinalSettlement_SettlementInvestorDesignationQueryVariables,
  FinalSettlement_SettlementInvestorDesignationQuery
>(FinalSettlement_SettlementInvestorDesignationDocument);

export const makeFinalSettlement_SettlementInvestorsMockResponse = makeMockResponse<
  FinalSettlement_SettlementInvestorsQueryVariables,
  FinalSettlement_SettlementInvestorsQuery
>(FinalSettlement_SettlementInvestorsDocument);
