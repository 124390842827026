import { routeUtil, urlUtil } from '@cmg/common';

import routeFactory from '../../common/util/routeFactory';
import { NotesFilters } from './order-book/notes/NotesModel.util';

const { createRoute } = routeUtil;

const root = createRoute({
  routePath: `${routeFactory.offeringSidePanel.routePath}/`,
  getUrlPath: () => `/`,
});

const offeringDetails = createRoute({
  routePath: `${routeFactory.offeringSidePanel.routePath}/details`,
  getUrlPath: (props: { cmgEntityKey?: string }) =>
    `/details${urlUtil.queryStringify({ cmgEntityKey: props.cmgEntityKey })}`,
});

const orderBook = createRoute({
  routePath: `${routeFactory.offeringSidePanel.routePath}/order-book`,
  getUrlPath: (props: { cmgEntityKey?: string }) =>
    `/order-book${urlUtil.queryStringify({ cmgEntityKey: props.cmgEntityKey })}`,
});

const orderBookNewIndication = createRoute({
  routePath: `${orderBook.routePath}/indication-activity/new`,
  getUrlPath: (props: { cmgEntityKey?: string }) => {
    return `/order-book/indication-activity/new${urlUtil.queryStringify({
      cmgEntityKey: props.cmgEntityKey,
    })}`;
  },
});

const orderBookIndicationActivity = createRoute({
  routePath: `${orderBook.routePath}/indication-activity`,
  getUrlPath: (props: { cmgEntityKey?: string; indicationId?: string }) => {
    return `/order-book/indication-activity${urlUtil.queryStringify({
      cmgEntityKey: props.cmgEntityKey,
      indicationId: props.indicationId,
    })}`;
  },
});

const orderBookUpdateHistory = createRoute({
  routePath: `${orderBook.routePath}/update-history`,
  getUrlPath: (props: { cmgEntityKey: string }) => {
    return `/order-book/update-history${urlUtil.queryStringify({
      cmgEntityKey: props.cmgEntityKey,
    })}`;
  },
});

const orderBookCompliance = createRoute({
  routePath: `${orderBook.routePath}/compliance`,
  getUrlPath: (props: { cmgEntityKey?: string }) =>
    `/order-book/compliance${props.cmgEntityKey ? urlUtil.queryStringify(props) : ''}`,
});

const orderBookNotes = createRoute({
  routePath: `${routeFactory.offeringSidePanel.routePath}/notes`,
  getUrlPath: (props: { cmgEntityKey?: string; noteType?: NotesFilters }) =>
    `/notes${urlUtil.queryStringify({
      cmgEntityKey: props.cmgEntityKey,
      noteType: props.noteType,
    })}`,
});

const feedback = createRoute({
  routePath: `${routeFactory.offeringSidePanel.routePath}/feedback`,
  getUrlPath: (props: { cmgEntityKey?: string }) =>
    `/feedback${urlUtil.queryStringify({ cmgEntityKey: props.cmgEntityKey })}`,
});

const offeringSidePanelSellSideRoutes = {
  root,
  offeringDetails,
  orderBook,
  orderBookIndicationActivity,
  orderBookNewIndication,
  orderBookUpdateHistory,
  orderBookNotes,
  orderBookCompliance,
  feedback,
};

export default offeringSidePanelSellSideRoutes;
