import { CellRenderers, formatters } from '@cmg/data-grid';

import { AttestationFormStatus } from '../../../../../../graphql';
import type { OrderBook_InstitutionalDemand_GridRowPartsFragment } from '../../../graphql/__generated__';
import type { DemandGridServerColDef } from '../../types';
import { getCellRendererSelector, getValueFormatter } from '../columns.model';
import { getAttestationStatusColor } from './Attestation.model';

export type Value = OrderBook_InstitutionalDemand_GridRowPartsFragment['attestationStatus'];

/**
 * Exported for testing purposes
 */
export const cellRendererSelector = getCellRendererSelector<Value>({
  gridRow: {
    component: CellRenderers.DotStatusCellRenderer,
    params: { getStatusColor: getAttestationStatusColor },
  },
});

/**
 * Exported for testing purposes
 */
export const valueFormatter = getValueFormatter<Value>({
  gridRow: params => formatters.enumValueFormatter(params) ?? 'Not on file',
});

const attestationColDef: DemandGridServerColDef<Value> = {
  field: 'attestationStatus',
  headerName: '5130/31 Status',
  enableRowGroup: true,
  cellRendererSelector,
  filter: 'agSetColumnFilter',
  valueFormatter,
  filterParams: {
    values: Object.values(AttestationFormStatus),
    valueFormatter: formatters.enumValueFormatter,
  },
};

export default attestationColDef;
