/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateIndication_AssignMyIndicationMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['ID'];
  input: Types.AssignInvestorInstitutionalIndicationInput;
}>;

export type CreateIndication_AssignMyIndicationMutation = {
  readonly __typename?: 'Mutation';
  readonly assignMyInstitutionalIndication:
    | {
        readonly __typename?: 'MyInstitutionalIndication';
        readonly id: string;
        readonly status: Types.IndicationStatus;
        readonly type: Types.InstitutionalIndicationOrderType;
        readonly currencyCode?: string | null;
        readonly submittedByCmgEntityKey: string;
        readonly version: string;
        readonly trancheId?: string | null;
        readonly instrumentId?: string | null;
        readonly interestLevels: ReadonlyArray<{
          readonly __typename?: 'InterestLevel';
          readonly interestUnit: Types.InterestLevelInterestUnit;
          readonly interestQuantity: number;
          readonly limitPrice?: number | null;
          readonly limitType?: Types.InterestLevelLimitType | null;
        }>;
        readonly acknowledgements: ReadonlyArray<{
          readonly __typename?: 'InstitutionalIndicationAcknowledgement';
          readonly managerCmgEntityKey: string;
          readonly indicationVersion: string;
          readonly acknowledgedIndicationVersion: string;
        }>;
        readonly auditInfo: {
          readonly __typename?: 'AuditInfo';
          readonly createdAt: string;
          readonly modifiedByFirmKey?: string | null;
        };
      }
    | {
        readonly __typename: 'ServiceError';
        readonly code: Types.ServiceErrorCode;
        readonly message: string;
        readonly target?: string | null;
        readonly details?: ReadonlyArray<{
          readonly __typename: 'ErrorDetail';
          readonly code?: string | null;
          readonly message?: string | null;
          readonly target?: string | null;
        }> | null;
      };
};

export type CreateIndication_PassOnOfferingMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  input: Types.SubmitPassIndicationInput;
}>;

export type CreateIndication_PassOnOfferingMutation = {
  readonly __typename?: 'Mutation';
  readonly passOfferingWithoutCoveredInstitutionalIndication:
    | {
        readonly __typename?: 'InstitutionalIndication';
        readonly id: string;
        readonly type: Types.InstitutionalIndicationOrderType;
      }
    | {
        readonly __typename: 'ServiceError';
        readonly code: Types.ServiceErrorCode;
        readonly message: string;
        readonly target?: string | null;
        readonly details?: ReadonlyArray<{
          readonly __typename: 'ErrorDetail';
          readonly code?: string | null;
          readonly message?: string | null;
          readonly target?: string | null;
        }> | null;
      };
};

export type CreateIndication_SubmitCoveredIndicationMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['ID'];
  input: Types.InstitutionalIndicationSubmitInput;
}>;

export type CreateIndication_SubmitCoveredIndicationMutation = {
  readonly __typename?: 'Mutation';
  readonly submitCoveredInstitutionalIndication:
    | {
        readonly __typename?: 'InstitutionalIndication';
        readonly id: string;
        readonly status: Types.IndicationStatus;
        readonly type: Types.InstitutionalIndicationOrderType;
        readonly currencyCode?: string | null;
        readonly submittedByCmgEntityKey: string;
        readonly version: string;
        readonly duplicateOfIndicationId?: string | null;
        readonly billingAndDeliveryAgentCmgEntityKey?: string | null;
        readonly duplicateIndicationIds: ReadonlyArray<string>;
        readonly trancheId?: string | null;
        readonly instrumentId?: string | null;
        readonly coveringManagerCmgEntityKeys: ReadonlyArray<string>;
        readonly interestLevels: ReadonlyArray<{
          readonly __typename?: 'InterestLevel';
          readonly interestUnit: Types.InterestLevelInterestUnit;
          readonly interestQuantity: number;
          readonly limitPrice?: number | null;
          readonly limitType?: Types.InterestLevelLimitType | null;
        }>;
        readonly investorInformation: {
          readonly __typename?: 'InvestorInformation';
          readonly bankInvestorName?: string | null;
          readonly bankInvestorKey?: string | null;
          readonly cmgEntityName: string;
          readonly cmgEntityKey: string;
          readonly contactName?: string | null;
          readonly contactEmail?: string | null;
        };
        readonly auditInfo: {
          readonly __typename?: 'AuditInfo';
          readonly createdAt: string;
          readonly createdByFirmKey?: string | null;
          readonly modifiedByFirmKey?: string | null;
        };
      }
    | {
        readonly __typename: 'ServiceError';
        readonly code: Types.ServiceErrorCode;
        readonly message: string;
        readonly target?: string | null;
        readonly details?: ReadonlyArray<{
          readonly __typename: 'ErrorDetail';
          readonly code?: string | null;
          readonly message?: string | null;
          readonly target?: string | null;
        }> | null;
      };
};

export type CreateIndication_SubmitIndicationMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['ID'];
  input: Types.InstitutionalIndicationSubmitInput;
}>;

export type CreateIndication_SubmitIndicationMutation = {
  readonly __typename?: 'Mutation';
  readonly submitInstitutionalIndication:
    | {
        readonly __typename?: 'InstitutionalIndication';
        readonly id: string;
        readonly status: Types.IndicationStatus;
        readonly type: Types.InstitutionalIndicationOrderType;
        readonly currencyCode?: string | null;
        readonly submittedByCmgEntityKey: string;
        readonly version: string;
        readonly duplicateOfIndicationId?: string | null;
        readonly billingAndDeliveryAgentCmgEntityKey?: string | null;
        readonly duplicateIndicationIds: ReadonlyArray<string>;
        readonly trancheId?: string | null;
        readonly instrumentId?: string | null;
        readonly coveringManagerCmgEntityKeys: ReadonlyArray<string>;
        readonly interestLevels: ReadonlyArray<{
          readonly __typename?: 'InterestLevel';
          readonly interestUnit: Types.InterestLevelInterestUnit;
          readonly interestQuantity: number;
          readonly limitPrice?: number | null;
          readonly limitType?: Types.InterestLevelLimitType | null;
        }>;
        readonly investorInformation: {
          readonly __typename?: 'InvestorInformation';
          readonly bankInvestorName?: string | null;
          readonly bankInvestorKey?: string | null;
          readonly cmgEntityName: string;
          readonly cmgEntityKey: string;
          readonly contactName?: string | null;
          readonly contactEmail?: string | null;
        };
        readonly auditInfo: {
          readonly __typename?: 'AuditInfo';
          readonly createdAt: string;
          readonly createdByFirmKey?: string | null;
          readonly modifiedByFirmKey?: string | null;
        };
      }
    | {
        readonly __typename: 'ServiceError';
        readonly code: Types.ServiceErrorCode;
        readonly message: string;
        readonly target?: string | null;
        readonly details?: ReadonlyArray<{
          readonly __typename: 'ErrorDetail';
          readonly code?: string | null;
          readonly message?: string | null;
          readonly target?: string | null;
        }> | null;
      };
};

export type ManageIndication_IndicationPartsFragment = {
  readonly __typename?: 'InstitutionalIndication';
  readonly id: string;
  readonly type: Types.InstitutionalIndicationOrderType;
  readonly status: Types.IndicationStatus;
  readonly currencyCode?: string | null;
  readonly submittedByCmgEntityKey: string;
  readonly version: string;
  readonly duplicateOfIndicationId?: string | null;
  readonly billingAndDeliveryAgentCmgEntityKey?: string | null;
  readonly duplicateIndicationIds: ReadonlyArray<string>;
  readonly trancheId?: string | null;
  readonly instrumentId?: string | null;
  readonly coveringManagerCmgEntityKeys: ReadonlyArray<string>;
  readonly finalAllocation?: {
    readonly __typename?: 'FinalAllocation';
    readonly id: string;
    readonly indicationId: string;
    readonly shareQuantity?: number | null;
    readonly version: string;
    readonly investorReply?: {
      readonly __typename?: 'InvestorReply';
      readonly status: Types.AllocationAcknowledgement;
    } | null;
  } | null;
  readonly interestLevels: ReadonlyArray<{
    readonly __typename?: 'InterestLevel';
    readonly interestUnit: Types.InterestLevelInterestUnit;
    readonly interestQuantity: number;
    readonly limitPrice?: number | null;
    readonly limitType?: Types.InterestLevelLimitType | null;
  }>;
  readonly investorInformation: {
    readonly __typename?: 'InvestorInformation';
    readonly bankInvestorName?: string | null;
    readonly bankInvestorKey?: string | null;
    readonly cmgEntityName: string;
    readonly cmgEntityKey: string;
    readonly contactName?: string | null;
    readonly contactEmail?: string | null;
  };
  readonly auditInfo: {
    readonly __typename?: 'AuditInfo';
    readonly createdAt: string;
    readonly createdByFirmKey?: string | null;
    readonly modifiedByFirmKey?: string | null;
  };
};

export type ManageIndication_MyIndicationPartsFragment = {
  readonly __typename?: 'MyInstitutionalIndication';
  readonly id: string;
  readonly type: Types.InstitutionalIndicationOrderType;
  readonly billingAndDeliveryAgentCmgEntityKey?: string | null;
  readonly status: Types.IndicationStatus;
  readonly currencyCode?: string | null;
  readonly submittedByCmgEntityKey: string;
  readonly version: string;
  readonly trancheId?: string | null;
  readonly instrumentId?: string | null;
  readonly finalAllocation?: {
    readonly __typename?: 'FinalAllocation';
    readonly id: string;
    readonly indicationId: string;
    readonly shareQuantity?: number | null;
    readonly version: string;
    readonly investorReply?: {
      readonly __typename?: 'InvestorReply';
      readonly status: Types.AllocationAcknowledgement;
    } | null;
  } | null;
  readonly interestLevels: ReadonlyArray<{
    readonly __typename?: 'InterestLevel';
    readonly interestUnit: Types.InterestLevelInterestUnit;
    readonly interestQuantity: number;
    readonly limitPrice?: number | null;
    readonly limitType?: Types.InterestLevelLimitType | null;
  }>;
  readonly acknowledgements: ReadonlyArray<{
    readonly __typename?: 'InstitutionalIndicationAcknowledgement';
    readonly managerCmgEntityKey: string;
    readonly indicationVersion: string;
    readonly acknowledgedIndicationVersion: string;
  }>;
  readonly auditInfo: {
    readonly __typename?: 'AuditInfo';
    readonly createdAt: string;
    readonly modifiedByFirmKey?: string | null;
  };
};

export type ManageIndication_FinalAllocationSetPartsFragment = {
  readonly __typename?: 'FinalInstitutionalAllocationSet';
  readonly releaseTimestamp?: string | null;
  readonly isReleased: boolean;
  readonly isVisibleToNonSyndicate: boolean;
};

export type ManageIndication_OfferingPartsFragment = {
  readonly __typename?: 'BasicPublishedOfferingProfile';
  readonly id: string;
  readonly offerPrice?: number | null;
  readonly pricingCurrencyCode: string;
  readonly securityType: Types.SecurityType;
  readonly securityTypeDisplayName?: string | null;
  readonly type: Types.OfferingType;
  readonly status: Types.OfferingStatus;
  readonly disclaimers: {
    readonly __typename?: 'DisclaimersProfile';
    readonly restrictions?: string | null;
    readonly footnotes?: string | null;
    readonly registrationLinks: ReadonlyArray<string>;
    readonly rule134Legend?: string | null;
  };
  readonly orderEntryProfile: {
    readonly __typename?: 'OfferingOrderEntryProfile';
    readonly id: string;
    readonly isExecutingOnPlatform: boolean;
    readonly tranches: ReadonlyArray<{
      readonly __typename?: 'TranchePrivileged';
      readonly id: string;
      readonly defaultInstrumentId?: string | null;
      readonly name: string;
      readonly demandCurrencies: ReadonlyArray<string>;
      readonly deliveryInstrumentIds: ReadonlyArray<string>;
    }>;
    readonly instruments: ReadonlyArray<{
      readonly __typename?: 'InstrumentOrderEntryProfile';
      readonly id: string;
      readonly currencyCode?: string | null;
      readonly countryCode?: string | null;
      readonly stockSymbol?: string | null;
      readonly isDepositaryReceipt: boolean;
    }>;
  };
  readonly syndicate: {
    readonly __typename?: 'SyndicateProfile';
    readonly managers: ReadonlyArray<{
      readonly __typename?: 'ManagerProfile';
      readonly cmgEntityKey: string;
      readonly firmName: string;
    }>;
  };
};

export type ManageIndication_MdlAllocationRankingQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type ManageIndication_MdlAllocationRankingQuery = {
  readonly __typename?: 'Query';
  readonly mdlAllocationRanking?: {
    readonly __typename?: 'MdlAllocationRankingResponse';
    readonly id: string;
    readonly ranking: Types.MdlAllocationRanking;
  } | null;
};

export type ManageIndication_UpdateIndicationMutationVariables = Types.Exact<{
  indicationId: Types.Scalars['ID'];
  offeringId: Types.Scalars['ID'];
  input: Types.InstitutionalIndicationUpdateInput;
}>;

export type ManageIndication_UpdateIndicationMutation = {
  readonly __typename?: 'Mutation';
  readonly updateInstitutionalIndication:
    | {
        readonly __typename?: 'InstitutionalIndication';
        readonly id: string;
        readonly status: Types.IndicationStatus;
        readonly type: Types.InstitutionalIndicationOrderType;
        readonly currencyCode?: string | null;
        readonly submittedByCmgEntityKey: string;
        readonly version: string;
        readonly duplicateOfIndicationId?: string | null;
        readonly billingAndDeliveryAgentCmgEntityKey?: string | null;
        readonly duplicateIndicationIds: ReadonlyArray<string>;
        readonly trancheId?: string | null;
        readonly instrumentId?: string | null;
        readonly coveringManagerCmgEntityKeys: ReadonlyArray<string>;
        readonly interestLevels: ReadonlyArray<{
          readonly __typename?: 'InterestLevel';
          readonly interestUnit: Types.InterestLevelInterestUnit;
          readonly interestQuantity: number;
          readonly limitPrice?: number | null;
          readonly limitType?: Types.InterestLevelLimitType | null;
        }>;
        readonly investorInformation: {
          readonly __typename?: 'InvestorInformation';
          readonly bankInvestorName?: string | null;
          readonly bankInvestorKey?: string | null;
          readonly cmgEntityName: string;
          readonly cmgEntityKey: string;
          readonly contactName?: string | null;
          readonly contactEmail?: string | null;
        };
        readonly auditInfo: {
          readonly __typename?: 'AuditInfo';
          readonly createdAt: string;
          readonly createdByFirmKey?: string | null;
          readonly modifiedByFirmKey?: string | null;
        };
      }
    | {
        readonly __typename: 'ServiceError';
        readonly code: Types.ServiceErrorCode;
        readonly message: string;
        readonly target?: string | null;
        readonly details?: ReadonlyArray<{
          readonly __typename: 'ErrorDetail';
          readonly code?: string | null;
          readonly message?: string | null;
          readonly target?: string | null;
        }> | null;
      };
};

export type ManageIndication_UpdateCoveredIndicationMutationVariables = Types.Exact<{
  indicationId: Types.Scalars['ID'];
  offeringId: Types.Scalars['ID'];
  input: Types.InstitutionalIndicationUpdateInput;
}>;

export type ManageIndication_UpdateCoveredIndicationMutation = {
  readonly __typename?: 'Mutation';
  readonly updateCoveredIndication:
    | {
        readonly __typename?: 'InstitutionalIndication';
        readonly id: string;
        readonly status: Types.IndicationStatus;
        readonly type: Types.InstitutionalIndicationOrderType;
        readonly currencyCode?: string | null;
        readonly submittedByCmgEntityKey: string;
        readonly version: string;
        readonly duplicateOfIndicationId?: string | null;
        readonly billingAndDeliveryAgentCmgEntityKey?: string | null;
        readonly duplicateIndicationIds: ReadonlyArray<string>;
        readonly trancheId?: string | null;
        readonly instrumentId?: string | null;
        readonly coveringManagerCmgEntityKeys: ReadonlyArray<string>;
        readonly interestLevels: ReadonlyArray<{
          readonly __typename?: 'InterestLevel';
          readonly interestUnit: Types.InterestLevelInterestUnit;
          readonly interestQuantity: number;
          readonly limitPrice?: number | null;
          readonly limitType?: Types.InterestLevelLimitType | null;
        }>;
        readonly investorInformation: {
          readonly __typename?: 'InvestorInformation';
          readonly bankInvestorName?: string | null;
          readonly bankInvestorKey?: string | null;
          readonly cmgEntityName: string;
          readonly cmgEntityKey: string;
          readonly contactName?: string | null;
          readonly contactEmail?: string | null;
        };
        readonly auditInfo: {
          readonly __typename?: 'AuditInfo';
          readonly createdAt: string;
          readonly createdByFirmKey?: string | null;
          readonly modifiedByFirmKey?: string | null;
        };
      }
    | {
        readonly __typename: 'ServiceError';
        readonly code: Types.ServiceErrorCode;
        readonly message: string;
        readonly target?: string | null;
        readonly details?: ReadonlyArray<{
          readonly __typename: 'ErrorDetail';
          readonly code?: string | null;
          readonly message?: string | null;
          readonly target?: string | null;
        }> | null;
      };
};

export type ManageIndication_UpdateMyIndicationMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['ID'];
  input: Types.AssignInvestorInstitutionalIndicationInput;
}>;

export type ManageIndication_UpdateMyIndicationMutation = {
  readonly __typename?: 'Mutation';
  readonly assignMyInstitutionalIndication:
    | {
        readonly __typename?: 'MyInstitutionalIndication';
        readonly id: string;
        readonly status: Types.IndicationStatus;
        readonly type: Types.InstitutionalIndicationOrderType;
        readonly currencyCode?: string | null;
        readonly submittedByCmgEntityKey: string;
        readonly version: string;
        readonly trancheId?: string | null;
        readonly instrumentId?: string | null;
        readonly interestLevels: ReadonlyArray<{
          readonly __typename?: 'InterestLevel';
          readonly interestUnit: Types.InterestLevelInterestUnit;
          readonly interestQuantity: number;
          readonly limitPrice?: number | null;
          readonly limitType?: Types.InterestLevelLimitType | null;
        }>;
        readonly acknowledgements: ReadonlyArray<{
          readonly __typename?: 'InstitutionalIndicationAcknowledgement';
          readonly managerCmgEntityKey: string;
          readonly indicationVersion: string;
          readonly acknowledgedIndicationVersion: string;
        }>;
        readonly auditInfo: {
          readonly __typename?: 'AuditInfo';
          readonly createdAt: string;
          readonly modifiedByFirmKey?: string | null;
        };
      }
    | {
        readonly __typename: 'ServiceError';
        readonly code: Types.ServiceErrorCode;
        readonly message: string;
        readonly target?: string | null;
        readonly details?: ReadonlyArray<{
          readonly __typename: 'ErrorDetail';
          readonly code?: string | null;
          readonly message?: string | null;
          readonly target?: string | null;
        }> | null;
      };
};

export type ManageIndication_UpdateIndicationStatusMutationVariables = Types.Exact<{
  indicationId: Types.Scalars['ID'];
  offeringId: Types.Scalars['ID'];
  status: Types.IndicationStatus;
}>;

export type ManageIndication_UpdateIndicationStatusMutation = {
  readonly __typename?: 'Mutation';
  readonly updateInstitutionalIndicationStatus: {
    readonly __typename?: 'InstitutionalIndication';
    readonly id: string;
    readonly status: Types.IndicationStatus;
    readonly type: Types.InstitutionalIndicationOrderType;
    readonly currencyCode?: string | null;
    readonly submittedByCmgEntityKey: string;
    readonly version: string;
    readonly duplicateOfIndicationId?: string | null;
    readonly billingAndDeliveryAgentCmgEntityKey?: string | null;
    readonly duplicateIndicationIds: ReadonlyArray<string>;
    readonly trancheId?: string | null;
    readonly instrumentId?: string | null;
    readonly coveringManagerCmgEntityKeys: ReadonlyArray<string>;
    readonly interestLevels: ReadonlyArray<{
      readonly __typename?: 'InterestLevel';
      readonly interestUnit: Types.InterestLevelInterestUnit;
      readonly interestQuantity: number;
      readonly limitPrice?: number | null;
      readonly limitType?: Types.InterestLevelLimitType | null;
    }>;
    readonly investorInformation: {
      readonly __typename?: 'InvestorInformation';
      readonly bankInvestorName?: string | null;
      readonly bankInvestorKey?: string | null;
      readonly cmgEntityName: string;
      readonly cmgEntityKey: string;
      readonly contactName?: string | null;
      readonly contactEmail?: string | null;
    };
    readonly auditInfo: {
      readonly __typename?: 'AuditInfo';
      readonly createdAt: string;
      readonly createdByFirmKey?: string | null;
      readonly modifiedByFirmKey?: string | null;
    };
  };
};

export type ManageIndication_UpdateCoveredIndicationStatusMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['ID'];
  indicationId: Types.Scalars['ID'];
  status: Types.IndicationStatus;
}>;

export type ManageIndication_UpdateCoveredIndicationStatusMutation = {
  readonly __typename?: 'Mutation';
  readonly updateCoveredIndicationStatus:
    | {
        readonly __typename?: 'InstitutionalIndication';
        readonly id: string;
        readonly status: Types.IndicationStatus;
        readonly type: Types.InstitutionalIndicationOrderType;
        readonly currencyCode?: string | null;
        readonly submittedByCmgEntityKey: string;
        readonly version: string;
        readonly duplicateOfIndicationId?: string | null;
        readonly billingAndDeliveryAgentCmgEntityKey?: string | null;
        readonly duplicateIndicationIds: ReadonlyArray<string>;
        readonly trancheId?: string | null;
        readonly instrumentId?: string | null;
        readonly coveringManagerCmgEntityKeys: ReadonlyArray<string>;
        readonly interestLevels: ReadonlyArray<{
          readonly __typename?: 'InterestLevel';
          readonly interestUnit: Types.InterestLevelInterestUnit;
          readonly interestQuantity: number;
          readonly limitPrice?: number | null;
          readonly limitType?: Types.InterestLevelLimitType | null;
        }>;
        readonly investorInformation: {
          readonly __typename?: 'InvestorInformation';
          readonly bankInvestorName?: string | null;
          readonly bankInvestorKey?: string | null;
          readonly cmgEntityName: string;
          readonly cmgEntityKey: string;
          readonly contactName?: string | null;
          readonly contactEmail?: string | null;
        };
        readonly auditInfo: {
          readonly __typename?: 'AuditInfo';
          readonly createdAt: string;
          readonly createdByFirmKey?: string | null;
          readonly modifiedByFirmKey?: string | null;
        };
      }
    | {
        readonly __typename: 'ServiceError';
        readonly code: Types.ServiceErrorCode;
        readonly message: string;
        readonly target?: string | null;
        readonly details?: ReadonlyArray<{
          readonly __typename: 'ErrorDetail';
          readonly code?: string | null;
          readonly message?: string | null;
          readonly target?: string | null;
        }> | null;
      };
};

export type ManageIndication_SetAllocationRankingOnOfferingMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  ranking: Types.MdlAllocationRanking;
}>;

export type ManageIndication_SetAllocationRankingOnOfferingMutation = {
  readonly __typename?: 'Mutation';
  readonly setAllocationRankingOnOffering: {
    readonly __typename?: 'MdlAllocationRankingResponse';
    readonly id: string;
    readonly ranking: Types.MdlAllocationRanking;
  };
};

export type ManageIndication_PassOnCoveredIndicationMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  indicationId: Types.Scalars['UUID'];
  activateIfCancelled: Types.Scalars['Boolean'];
}>;

export type ManageIndication_PassOnCoveredIndicationMutation = {
  readonly __typename?: 'Mutation';
  readonly passCoveredInstitutionalIndication:
    | {
        readonly __typename?: 'InstitutionalIndication';
        readonly id: string;
        readonly type: Types.InstitutionalIndicationOrderType;
      }
    | {
        readonly __typename: 'ServiceError';
        readonly code: Types.ServiceErrorCode;
        readonly message: string;
        readonly target?: string | null;
        readonly details?: ReadonlyArray<{
          readonly __typename: 'ErrorDetail';
          readonly code?: string | null;
          readonly message?: string | null;
          readonly target?: string | null;
        }> | null;
      };
};

export type ManageIndication_PassOnOfferingWithoutInstitutionalIndicationMutationVariables =
  Types.Exact<{
    offeringId: Types.Scalars['UUID'];
    input: Types.SubmitPassIndicationInput;
  }>;

export type ManageIndication_PassOnOfferingWithoutInstitutionalIndicationMutation = {
  readonly __typename?: 'Mutation';
  readonly passOnOfferingWithoutInstitutionalIndication:
    | {
        readonly __typename?: 'InstitutionalIndication';
        readonly id: string;
        readonly type: Types.InstitutionalIndicationOrderType;
      }
    | {
        readonly __typename: 'ServiceError';
        readonly code: Types.ServiceErrorCode;
        readonly message: string;
        readonly target?: string | null;
        readonly details?: ReadonlyArray<{
          readonly __typename: 'ErrorDetail';
          readonly code?: string | null;
          readonly message?: string | null;
          readonly target?: string | null;
        }> | null;
      };
};

export type ManageIndication_PassOnInstitutionalIndicationMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  indicationId: Types.Scalars['UUID'];
  activateIfCancelled: Types.Scalars['Boolean'];
}>;

export type ManageIndication_PassOnInstitutionalIndicationMutation = {
  readonly __typename?: 'Mutation';
  readonly passInstitutionalIndication:
    | {
        readonly __typename?: 'InstitutionalIndication';
        readonly id: string;
        readonly type: Types.InstitutionalIndicationOrderType;
      }
    | {
        readonly __typename: 'ServiceError';
        readonly code: Types.ServiceErrorCode;
        readonly message: string;
        readonly target?: string | null;
        readonly details?: ReadonlyArray<{
          readonly __typename: 'ErrorDetail';
          readonly code?: string | null;
          readonly message?: string | null;
          readonly target?: string | null;
        }> | null;
      };
};

export type ManageIndication_UpdateMyInstitutionalIndicationStatusMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  status: Types.IndicationStatus;
}>;

export type ManageIndication_UpdateMyInstitutionalIndicationStatusMutation = {
  readonly __typename?: 'Mutation';
  readonly updateMyIndicationStatus: {
    readonly __typename?: 'MyInstitutionalIndication';
    readonly id: string;
    readonly status: Types.IndicationStatus;
  };
};

export const ManageIndication_IndicationPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ManageIndication_IndicationParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstitutionalIndication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'IndicationForm_IndicationParts' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ManageIndicationStatus_IndicationParts' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'finalAllocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'IndicationActivity_FinalAllocationParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'IndicationForm_IndicationParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstitutionalIndication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'interestLevels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'interestUnit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'interestQuantity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limitPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limitType' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'submittedByCmgEntityKey' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investorInformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'bankInvestorName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bankInvestorKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          { kind: 'Field', name: { kind: 'Name', value: 'duplicateOfIndicationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'billingAndDeliveryAgentCmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'duplicateIndicationIds' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'IndicationForm_IndicationDetails_IndicationParts' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'IndicationForm_IndicationDetails_IndicationParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstitutionalIndication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trancheId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'instrumentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'auditInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdByFirmKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modifiedByFirmKey' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investorInformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'contactName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contactEmail' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'coveringManagerCmgEntityKeys' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ManageIndicationStatus_IndicationParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstitutionalIndication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'IndicationActivity_FinalAllocationParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FinalAllocation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'indicationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shareQuantity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investorReply' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'status' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const ManageIndication_MyIndicationPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ManageIndication_MyIndicationParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MyInstitutionalIndication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'IndicationForm_MyIndicationParts' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ManageIndicationStatus_MyIndicationParts' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'finalAllocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'IndicationActivity_FinalAllocationParts' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'billingAndDeliveryAgentCmgEntityKey' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'IndicationForm_MyIndicationParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MyInstitutionalIndication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'submittedByCmgEntityKey' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'interestLevels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'interestUnit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'interestQuantity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limitPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limitType' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'acknowledgements' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'managerCmgEntityKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'indicationVersion' } },
                { kind: 'Field', name: { kind: 'Name', value: 'acknowledgedIndicationVersion' } },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'IndicationForm_IndicationDetails_MyIndicationParts' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'IndicationForm_IndicationDetails_MyIndicationParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MyInstitutionalIndication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trancheId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'instrumentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'auditInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modifiedByFirmKey' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'IndicationActivity_FinalAllocationParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FinalAllocation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'indicationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shareQuantity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investorReply' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'status' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ManageIndicationStatus_MyIndicationParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MyInstitutionalIndication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const ManageIndication_FinalAllocationSetPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ManageIndication_FinalAllocationSetParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FinalInstitutionalAllocationSet' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'IndicationActivity_FinalAllocationSetParts' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'IndicationActivity_FinalAllocationSetParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FinalInstitutionalAllocationSet' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'releaseTimestamp' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isReleased' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isVisibleToNonSyndicate' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const ManageIndication_OfferingPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ManageIndication_OfferingParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BasicPublishedOfferingProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'IndicationForm_OfferingParts' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'IndicationForm_OfferingParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BasicPublishedOfferingProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offerPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingCurrencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'securityType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'securityTypeDisplayName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'disclaimers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'restrictions' } },
                { kind: 'Field', name: { kind: 'Name', value: 'footnotes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'registrationLinks' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rule134Legend' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orderEntryProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tranches' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'defaultInstrumentId' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isExecutingOnPlatform' } },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'IndicationForm_IndicationDetails_OfferingParts' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'IndicationActivity_AllocationOfferingParts' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'IndicationForm_IndicationDetails_OfferingParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BasicPublishedOfferingProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orderEntryProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tranches' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'defaultInstrumentId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'demandCurrencies' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryInstrumentIds' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'instruments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'stockSymbol' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isDepositaryReceipt' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'syndicate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'managers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'IndicationActivity_AllocationOfferingParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BasicPublishedOfferingProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offerPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingCurrencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const CreateIndication_AssignMyIndicationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateIndication_AssignMyIndication' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AssignInvestorInstitutionalIndicationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignMyInstitutionalIndication' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ServiceErrorParts' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'IndicationForm_MyIndicationParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ServiceErrorParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ServiceError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'target' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'target' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'IndicationForm_MyIndicationParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MyInstitutionalIndication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'submittedByCmgEntityKey' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'interestLevels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'interestUnit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'interestQuantity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limitPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limitType' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'acknowledgements' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'managerCmgEntityKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'indicationVersion' } },
                { kind: 'Field', name: { kind: 'Name', value: 'acknowledgedIndicationVersion' } },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'IndicationForm_IndicationDetails_MyIndicationParts' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'IndicationForm_IndicationDetails_MyIndicationParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MyInstitutionalIndication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trancheId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'instrumentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'auditInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modifiedByFirmKey' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type CreateIndication_AssignMyIndicationMutationFn = Apollo.MutationFunction<
  CreateIndication_AssignMyIndicationMutation,
  CreateIndication_AssignMyIndicationMutationVariables
>;

/**
 * __useCreateIndication_AssignMyIndicationMutation__
 *
 * To run a mutation, you first call `useCreateIndication_AssignMyIndicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateIndication_AssignMyIndicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createIndicationAssignMyIndicationMutation, { data, loading, error }] = useCreateIndication_AssignMyIndicationMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateIndication_AssignMyIndicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateIndication_AssignMyIndicationMutation,
    CreateIndication_AssignMyIndicationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateIndication_AssignMyIndicationMutation,
    CreateIndication_AssignMyIndicationMutationVariables
  >(CreateIndication_AssignMyIndicationDocument, options);
}
export type CreateIndication_AssignMyIndicationMutationHookResult = ReturnType<
  typeof useCreateIndication_AssignMyIndicationMutation
>;
export type CreateIndication_AssignMyIndicationMutationResult =
  Apollo.MutationResult<CreateIndication_AssignMyIndicationMutation>;
export type CreateIndication_AssignMyIndicationMutationOptions = Apollo.BaseMutationOptions<
  CreateIndication_AssignMyIndicationMutation,
  CreateIndication_AssignMyIndicationMutationVariables
>;
export const CreateIndication_PassOnOfferingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateIndication_PassOnOffering' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SubmitPassIndicationInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'passOfferingWithoutCoveredInstitutionalIndication' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ServiceErrorParts' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'InstitutionalIndication' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ServiceErrorParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ServiceError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'target' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'target' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type CreateIndication_PassOnOfferingMutationFn = Apollo.MutationFunction<
  CreateIndication_PassOnOfferingMutation,
  CreateIndication_PassOnOfferingMutationVariables
>;

/**
 * __useCreateIndication_PassOnOfferingMutation__
 *
 * To run a mutation, you first call `useCreateIndication_PassOnOfferingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateIndication_PassOnOfferingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createIndicationPassOnOfferingMutation, { data, loading, error }] = useCreateIndication_PassOnOfferingMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateIndication_PassOnOfferingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateIndication_PassOnOfferingMutation,
    CreateIndication_PassOnOfferingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateIndication_PassOnOfferingMutation,
    CreateIndication_PassOnOfferingMutationVariables
  >(CreateIndication_PassOnOfferingDocument, options);
}
export type CreateIndication_PassOnOfferingMutationHookResult = ReturnType<
  typeof useCreateIndication_PassOnOfferingMutation
>;
export type CreateIndication_PassOnOfferingMutationResult =
  Apollo.MutationResult<CreateIndication_PassOnOfferingMutation>;
export type CreateIndication_PassOnOfferingMutationOptions = Apollo.BaseMutationOptions<
  CreateIndication_PassOnOfferingMutation,
  CreateIndication_PassOnOfferingMutationVariables
>;
export const CreateIndication_SubmitCoveredIndicationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateIndication_submitCoveredIndication' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'InstitutionalIndicationSubmitInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submitCoveredInstitutionalIndication' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ServiceErrorParts' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'IndicationForm_IndicationParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ServiceErrorParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ServiceError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'target' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'target' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'IndicationForm_IndicationParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstitutionalIndication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'interestLevels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'interestUnit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'interestQuantity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limitPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limitType' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'submittedByCmgEntityKey' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investorInformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'bankInvestorName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bankInvestorKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          { kind: 'Field', name: { kind: 'Name', value: 'duplicateOfIndicationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'billingAndDeliveryAgentCmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'duplicateIndicationIds' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'IndicationForm_IndicationDetails_IndicationParts' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'IndicationForm_IndicationDetails_IndicationParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstitutionalIndication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trancheId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'instrumentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'auditInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdByFirmKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modifiedByFirmKey' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investorInformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'contactName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contactEmail' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'coveringManagerCmgEntityKeys' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type CreateIndication_SubmitCoveredIndicationMutationFn = Apollo.MutationFunction<
  CreateIndication_SubmitCoveredIndicationMutation,
  CreateIndication_SubmitCoveredIndicationMutationVariables
>;

/**
 * __useCreateIndication_SubmitCoveredIndicationMutation__
 *
 * To run a mutation, you first call `useCreateIndication_SubmitCoveredIndicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateIndication_SubmitCoveredIndicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createIndicationSubmitCoveredIndicationMutation, { data, loading, error }] = useCreateIndication_SubmitCoveredIndicationMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateIndication_SubmitCoveredIndicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateIndication_SubmitCoveredIndicationMutation,
    CreateIndication_SubmitCoveredIndicationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateIndication_SubmitCoveredIndicationMutation,
    CreateIndication_SubmitCoveredIndicationMutationVariables
  >(CreateIndication_SubmitCoveredIndicationDocument, options);
}
export type CreateIndication_SubmitCoveredIndicationMutationHookResult = ReturnType<
  typeof useCreateIndication_SubmitCoveredIndicationMutation
>;
export type CreateIndication_SubmitCoveredIndicationMutationResult =
  Apollo.MutationResult<CreateIndication_SubmitCoveredIndicationMutation>;
export type CreateIndication_SubmitCoveredIndicationMutationOptions = Apollo.BaseMutationOptions<
  CreateIndication_SubmitCoveredIndicationMutation,
  CreateIndication_SubmitCoveredIndicationMutationVariables
>;
export const CreateIndication_SubmitIndicationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateIndication_SubmitIndication' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'InstitutionalIndicationSubmitInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submitInstitutionalIndication' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ServiceErrorParts' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'IndicationForm_IndicationParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ServiceErrorParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ServiceError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'target' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'target' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'IndicationForm_IndicationParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstitutionalIndication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'interestLevels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'interestUnit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'interestQuantity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limitPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limitType' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'submittedByCmgEntityKey' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investorInformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'bankInvestorName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bankInvestorKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          { kind: 'Field', name: { kind: 'Name', value: 'duplicateOfIndicationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'billingAndDeliveryAgentCmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'duplicateIndicationIds' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'IndicationForm_IndicationDetails_IndicationParts' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'IndicationForm_IndicationDetails_IndicationParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstitutionalIndication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trancheId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'instrumentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'auditInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdByFirmKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modifiedByFirmKey' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investorInformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'contactName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contactEmail' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'coveringManagerCmgEntityKeys' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type CreateIndication_SubmitIndicationMutationFn = Apollo.MutationFunction<
  CreateIndication_SubmitIndicationMutation,
  CreateIndication_SubmitIndicationMutationVariables
>;

/**
 * __useCreateIndication_SubmitIndicationMutation__
 *
 * To run a mutation, you first call `useCreateIndication_SubmitIndicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateIndication_SubmitIndicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createIndicationSubmitIndicationMutation, { data, loading, error }] = useCreateIndication_SubmitIndicationMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateIndication_SubmitIndicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateIndication_SubmitIndicationMutation,
    CreateIndication_SubmitIndicationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateIndication_SubmitIndicationMutation,
    CreateIndication_SubmitIndicationMutationVariables
  >(CreateIndication_SubmitIndicationDocument, options);
}
export type CreateIndication_SubmitIndicationMutationHookResult = ReturnType<
  typeof useCreateIndication_SubmitIndicationMutation
>;
export type CreateIndication_SubmitIndicationMutationResult =
  Apollo.MutationResult<CreateIndication_SubmitIndicationMutation>;
export type CreateIndication_SubmitIndicationMutationOptions = Apollo.BaseMutationOptions<
  CreateIndication_SubmitIndicationMutation,
  CreateIndication_SubmitIndicationMutationVariables
>;
export const ManageIndication_MdlAllocationRankingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ManageIndication_MdlAllocationRanking' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mdlAllocationRanking' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ranking' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useManageIndication_MdlAllocationRankingQuery__
 *
 * To run a query within a React component, call `useManageIndication_MdlAllocationRankingQuery` and pass it any options that fit your needs.
 * When your component renders, `useManageIndication_MdlAllocationRankingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManageIndication_MdlAllocationRankingQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useManageIndication_MdlAllocationRankingQuery(
  baseOptions: Apollo.QueryHookOptions<
    ManageIndication_MdlAllocationRankingQuery,
    ManageIndication_MdlAllocationRankingQueryVariables
  > &
    (
      | { variables: ManageIndication_MdlAllocationRankingQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ManageIndication_MdlAllocationRankingQuery,
    ManageIndication_MdlAllocationRankingQueryVariables
  >(ManageIndication_MdlAllocationRankingDocument, options);
}
export function useManageIndication_MdlAllocationRankingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ManageIndication_MdlAllocationRankingQuery,
    ManageIndication_MdlAllocationRankingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ManageIndication_MdlAllocationRankingQuery,
    ManageIndication_MdlAllocationRankingQueryVariables
  >(ManageIndication_MdlAllocationRankingDocument, options);
}
export function useManageIndication_MdlAllocationRankingSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ManageIndication_MdlAllocationRankingQuery,
        ManageIndication_MdlAllocationRankingQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ManageIndication_MdlAllocationRankingQuery,
    ManageIndication_MdlAllocationRankingQueryVariables
  >(ManageIndication_MdlAllocationRankingDocument, options);
}
export type ManageIndication_MdlAllocationRankingQueryHookResult = ReturnType<
  typeof useManageIndication_MdlAllocationRankingQuery
>;
export type ManageIndication_MdlAllocationRankingLazyQueryHookResult = ReturnType<
  typeof useManageIndication_MdlAllocationRankingLazyQuery
>;
export type ManageIndication_MdlAllocationRankingSuspenseQueryHookResult = ReturnType<
  typeof useManageIndication_MdlAllocationRankingSuspenseQuery
>;
export type ManageIndication_MdlAllocationRankingQueryResult = Apollo.QueryResult<
  ManageIndication_MdlAllocationRankingQuery,
  ManageIndication_MdlAllocationRankingQueryVariables
>;
export const ManageIndication_UpdateIndicationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ManageIndication_UpdateIndication' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'indicationId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'InstitutionalIndicationUpdateInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateInstitutionalIndication' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'indicationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'indicationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ServiceErrorParts' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'IndicationForm_IndicationParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ServiceErrorParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ServiceError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'target' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'target' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'IndicationForm_IndicationParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstitutionalIndication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'interestLevels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'interestUnit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'interestQuantity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limitPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limitType' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'submittedByCmgEntityKey' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investorInformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'bankInvestorName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bankInvestorKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          { kind: 'Field', name: { kind: 'Name', value: 'duplicateOfIndicationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'billingAndDeliveryAgentCmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'duplicateIndicationIds' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'IndicationForm_IndicationDetails_IndicationParts' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'IndicationForm_IndicationDetails_IndicationParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstitutionalIndication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trancheId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'instrumentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'auditInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdByFirmKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modifiedByFirmKey' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investorInformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'contactName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contactEmail' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'coveringManagerCmgEntityKeys' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type ManageIndication_UpdateIndicationMutationFn = Apollo.MutationFunction<
  ManageIndication_UpdateIndicationMutation,
  ManageIndication_UpdateIndicationMutationVariables
>;

/**
 * __useManageIndication_UpdateIndicationMutation__
 *
 * To run a mutation, you first call `useManageIndication_UpdateIndicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManageIndication_UpdateIndicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [manageIndicationUpdateIndicationMutation, { data, loading, error }] = useManageIndication_UpdateIndicationMutation({
 *   variables: {
 *      indicationId: // value for 'indicationId'
 *      offeringId: // value for 'offeringId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useManageIndication_UpdateIndicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ManageIndication_UpdateIndicationMutation,
    ManageIndication_UpdateIndicationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ManageIndication_UpdateIndicationMutation,
    ManageIndication_UpdateIndicationMutationVariables
  >(ManageIndication_UpdateIndicationDocument, options);
}
export type ManageIndication_UpdateIndicationMutationHookResult = ReturnType<
  typeof useManageIndication_UpdateIndicationMutation
>;
export type ManageIndication_UpdateIndicationMutationResult =
  Apollo.MutationResult<ManageIndication_UpdateIndicationMutation>;
export type ManageIndication_UpdateIndicationMutationOptions = Apollo.BaseMutationOptions<
  ManageIndication_UpdateIndicationMutation,
  ManageIndication_UpdateIndicationMutationVariables
>;
export const ManageIndication_UpdateCoveredIndicationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ManageIndication_UpdateCoveredIndication' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'indicationId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'InstitutionalIndicationUpdateInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCoveredIndication' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'indicationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'indicationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ServiceErrorParts' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'IndicationForm_IndicationParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ServiceErrorParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ServiceError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'target' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'target' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'IndicationForm_IndicationParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstitutionalIndication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'interestLevels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'interestUnit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'interestQuantity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limitPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limitType' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'submittedByCmgEntityKey' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investorInformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'bankInvestorName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bankInvestorKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          { kind: 'Field', name: { kind: 'Name', value: 'duplicateOfIndicationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'billingAndDeliveryAgentCmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'duplicateIndicationIds' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'IndicationForm_IndicationDetails_IndicationParts' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'IndicationForm_IndicationDetails_IndicationParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstitutionalIndication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trancheId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'instrumentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'auditInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdByFirmKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modifiedByFirmKey' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investorInformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'contactName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contactEmail' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'coveringManagerCmgEntityKeys' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type ManageIndication_UpdateCoveredIndicationMutationFn = Apollo.MutationFunction<
  ManageIndication_UpdateCoveredIndicationMutation,
  ManageIndication_UpdateCoveredIndicationMutationVariables
>;

/**
 * __useManageIndication_UpdateCoveredIndicationMutation__
 *
 * To run a mutation, you first call `useManageIndication_UpdateCoveredIndicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManageIndication_UpdateCoveredIndicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [manageIndicationUpdateCoveredIndicationMutation, { data, loading, error }] = useManageIndication_UpdateCoveredIndicationMutation({
 *   variables: {
 *      indicationId: // value for 'indicationId'
 *      offeringId: // value for 'offeringId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useManageIndication_UpdateCoveredIndicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ManageIndication_UpdateCoveredIndicationMutation,
    ManageIndication_UpdateCoveredIndicationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ManageIndication_UpdateCoveredIndicationMutation,
    ManageIndication_UpdateCoveredIndicationMutationVariables
  >(ManageIndication_UpdateCoveredIndicationDocument, options);
}
export type ManageIndication_UpdateCoveredIndicationMutationHookResult = ReturnType<
  typeof useManageIndication_UpdateCoveredIndicationMutation
>;
export type ManageIndication_UpdateCoveredIndicationMutationResult =
  Apollo.MutationResult<ManageIndication_UpdateCoveredIndicationMutation>;
export type ManageIndication_UpdateCoveredIndicationMutationOptions = Apollo.BaseMutationOptions<
  ManageIndication_UpdateCoveredIndicationMutation,
  ManageIndication_UpdateCoveredIndicationMutationVariables
>;
export const ManageIndication_UpdateMyIndicationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ManageIndication_UpdateMyIndication' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AssignInvestorInstitutionalIndicationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignMyInstitutionalIndication' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ServiceErrorParts' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'IndicationForm_MyIndicationParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ServiceErrorParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ServiceError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'target' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'target' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'IndicationForm_MyIndicationParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MyInstitutionalIndication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'submittedByCmgEntityKey' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'interestLevels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'interestUnit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'interestQuantity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limitPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limitType' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'acknowledgements' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'managerCmgEntityKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'indicationVersion' } },
                { kind: 'Field', name: { kind: 'Name', value: 'acknowledgedIndicationVersion' } },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'IndicationForm_IndicationDetails_MyIndicationParts' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'IndicationForm_IndicationDetails_MyIndicationParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MyInstitutionalIndication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trancheId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'instrumentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'auditInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modifiedByFirmKey' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type ManageIndication_UpdateMyIndicationMutationFn = Apollo.MutationFunction<
  ManageIndication_UpdateMyIndicationMutation,
  ManageIndication_UpdateMyIndicationMutationVariables
>;

/**
 * __useManageIndication_UpdateMyIndicationMutation__
 *
 * To run a mutation, you first call `useManageIndication_UpdateMyIndicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManageIndication_UpdateMyIndicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [manageIndicationUpdateMyIndicationMutation, { data, loading, error }] = useManageIndication_UpdateMyIndicationMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useManageIndication_UpdateMyIndicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ManageIndication_UpdateMyIndicationMutation,
    ManageIndication_UpdateMyIndicationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ManageIndication_UpdateMyIndicationMutation,
    ManageIndication_UpdateMyIndicationMutationVariables
  >(ManageIndication_UpdateMyIndicationDocument, options);
}
export type ManageIndication_UpdateMyIndicationMutationHookResult = ReturnType<
  typeof useManageIndication_UpdateMyIndicationMutation
>;
export type ManageIndication_UpdateMyIndicationMutationResult =
  Apollo.MutationResult<ManageIndication_UpdateMyIndicationMutation>;
export type ManageIndication_UpdateMyIndicationMutationOptions = Apollo.BaseMutationOptions<
  ManageIndication_UpdateMyIndicationMutation,
  ManageIndication_UpdateMyIndicationMutationVariables
>;
export const ManageIndication_UpdateIndicationStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ManageIndication_UpdateIndicationStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'indicationId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'IndicationStatus' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateInstitutionalIndicationStatus' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'indicationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'indicationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'status' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'IndicationForm_IndicationParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'IndicationForm_IndicationParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstitutionalIndication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'interestLevels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'interestUnit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'interestQuantity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limitPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limitType' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'submittedByCmgEntityKey' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investorInformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'bankInvestorName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bankInvestorKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          { kind: 'Field', name: { kind: 'Name', value: 'duplicateOfIndicationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'billingAndDeliveryAgentCmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'duplicateIndicationIds' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'IndicationForm_IndicationDetails_IndicationParts' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'IndicationForm_IndicationDetails_IndicationParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstitutionalIndication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trancheId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'instrumentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'auditInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdByFirmKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modifiedByFirmKey' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investorInformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'contactName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contactEmail' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'coveringManagerCmgEntityKeys' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type ManageIndication_UpdateIndicationStatusMutationFn = Apollo.MutationFunction<
  ManageIndication_UpdateIndicationStatusMutation,
  ManageIndication_UpdateIndicationStatusMutationVariables
>;

/**
 * __useManageIndication_UpdateIndicationStatusMutation__
 *
 * To run a mutation, you first call `useManageIndication_UpdateIndicationStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManageIndication_UpdateIndicationStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [manageIndicationUpdateIndicationStatusMutation, { data, loading, error }] = useManageIndication_UpdateIndicationStatusMutation({
 *   variables: {
 *      indicationId: // value for 'indicationId'
 *      offeringId: // value for 'offeringId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useManageIndication_UpdateIndicationStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ManageIndication_UpdateIndicationStatusMutation,
    ManageIndication_UpdateIndicationStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ManageIndication_UpdateIndicationStatusMutation,
    ManageIndication_UpdateIndicationStatusMutationVariables
  >(ManageIndication_UpdateIndicationStatusDocument, options);
}
export type ManageIndication_UpdateIndicationStatusMutationHookResult = ReturnType<
  typeof useManageIndication_UpdateIndicationStatusMutation
>;
export type ManageIndication_UpdateIndicationStatusMutationResult =
  Apollo.MutationResult<ManageIndication_UpdateIndicationStatusMutation>;
export type ManageIndication_UpdateIndicationStatusMutationOptions = Apollo.BaseMutationOptions<
  ManageIndication_UpdateIndicationStatusMutation,
  ManageIndication_UpdateIndicationStatusMutationVariables
>;
export const ManageIndication_UpdateCoveredIndicationStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ManageIndication_UpdateCoveredIndicationStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'indicationId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'IndicationStatus' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCoveredIndicationStatus' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'indicationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'indicationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'status' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ServiceErrorParts' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'IndicationForm_IndicationParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ServiceErrorParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ServiceError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'target' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'target' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'IndicationForm_IndicationParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstitutionalIndication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'interestLevels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'interestUnit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'interestQuantity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limitPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limitType' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'submittedByCmgEntityKey' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investorInformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'bankInvestorName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bankInvestorKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          { kind: 'Field', name: { kind: 'Name', value: 'duplicateOfIndicationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'billingAndDeliveryAgentCmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'duplicateIndicationIds' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'IndicationForm_IndicationDetails_IndicationParts' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'IndicationForm_IndicationDetails_IndicationParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstitutionalIndication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trancheId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'instrumentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'auditInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdByFirmKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modifiedByFirmKey' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investorInformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'contactName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contactEmail' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'coveringManagerCmgEntityKeys' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type ManageIndication_UpdateCoveredIndicationStatusMutationFn = Apollo.MutationFunction<
  ManageIndication_UpdateCoveredIndicationStatusMutation,
  ManageIndication_UpdateCoveredIndicationStatusMutationVariables
>;

/**
 * __useManageIndication_UpdateCoveredIndicationStatusMutation__
 *
 * To run a mutation, you first call `useManageIndication_UpdateCoveredIndicationStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManageIndication_UpdateCoveredIndicationStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [manageIndicationUpdateCoveredIndicationStatusMutation, { data, loading, error }] = useManageIndication_UpdateCoveredIndicationStatusMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      indicationId: // value for 'indicationId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useManageIndication_UpdateCoveredIndicationStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ManageIndication_UpdateCoveredIndicationStatusMutation,
    ManageIndication_UpdateCoveredIndicationStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ManageIndication_UpdateCoveredIndicationStatusMutation,
    ManageIndication_UpdateCoveredIndicationStatusMutationVariables
  >(ManageIndication_UpdateCoveredIndicationStatusDocument, options);
}
export type ManageIndication_UpdateCoveredIndicationStatusMutationHookResult = ReturnType<
  typeof useManageIndication_UpdateCoveredIndicationStatusMutation
>;
export type ManageIndication_UpdateCoveredIndicationStatusMutationResult =
  Apollo.MutationResult<ManageIndication_UpdateCoveredIndicationStatusMutation>;
export type ManageIndication_UpdateCoveredIndicationStatusMutationOptions =
  Apollo.BaseMutationOptions<
    ManageIndication_UpdateCoveredIndicationStatusMutation,
    ManageIndication_UpdateCoveredIndicationStatusMutationVariables
  >;
export const ManageIndication_SetAllocationRankingOnOfferingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ManageIndication_SetAllocationRankingOnOffering' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ranking' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'MdlAllocationRanking' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setAllocationRankingOnOffering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ranking' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'ranking' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ranking' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type ManageIndication_SetAllocationRankingOnOfferingMutationFn = Apollo.MutationFunction<
  ManageIndication_SetAllocationRankingOnOfferingMutation,
  ManageIndication_SetAllocationRankingOnOfferingMutationVariables
>;

/**
 * __useManageIndication_SetAllocationRankingOnOfferingMutation__
 *
 * To run a mutation, you first call `useManageIndication_SetAllocationRankingOnOfferingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManageIndication_SetAllocationRankingOnOfferingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [manageIndicationSetAllocationRankingOnOfferingMutation, { data, loading, error }] = useManageIndication_SetAllocationRankingOnOfferingMutation({
 *   variables: {
 *      id: // value for 'id'
 *      ranking: // value for 'ranking'
 *   },
 * });
 */
export function useManageIndication_SetAllocationRankingOnOfferingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ManageIndication_SetAllocationRankingOnOfferingMutation,
    ManageIndication_SetAllocationRankingOnOfferingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ManageIndication_SetAllocationRankingOnOfferingMutation,
    ManageIndication_SetAllocationRankingOnOfferingMutationVariables
  >(ManageIndication_SetAllocationRankingOnOfferingDocument, options);
}
export type ManageIndication_SetAllocationRankingOnOfferingMutationHookResult = ReturnType<
  typeof useManageIndication_SetAllocationRankingOnOfferingMutation
>;
export type ManageIndication_SetAllocationRankingOnOfferingMutationResult =
  Apollo.MutationResult<ManageIndication_SetAllocationRankingOnOfferingMutation>;
export type ManageIndication_SetAllocationRankingOnOfferingMutationOptions =
  Apollo.BaseMutationOptions<
    ManageIndication_SetAllocationRankingOnOfferingMutation,
    ManageIndication_SetAllocationRankingOnOfferingMutationVariables
  >;
export const ManageIndication_PassOnCoveredIndicationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ManageIndication_PassOnCoveredIndication' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'indicationId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'activateIfCancelled' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'passCoveredInstitutionalIndication' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'indicationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'indicationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'activateIfCancelled' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'activateIfCancelled' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ServiceErrorParts' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'InstitutionalIndication' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ServiceErrorParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ServiceError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'target' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'target' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type ManageIndication_PassOnCoveredIndicationMutationFn = Apollo.MutationFunction<
  ManageIndication_PassOnCoveredIndicationMutation,
  ManageIndication_PassOnCoveredIndicationMutationVariables
>;

/**
 * __useManageIndication_PassOnCoveredIndicationMutation__
 *
 * To run a mutation, you first call `useManageIndication_PassOnCoveredIndicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManageIndication_PassOnCoveredIndicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [manageIndicationPassOnCoveredIndicationMutation, { data, loading, error }] = useManageIndication_PassOnCoveredIndicationMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      indicationId: // value for 'indicationId'
 *      activateIfCancelled: // value for 'activateIfCancelled'
 *   },
 * });
 */
export function useManageIndication_PassOnCoveredIndicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ManageIndication_PassOnCoveredIndicationMutation,
    ManageIndication_PassOnCoveredIndicationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ManageIndication_PassOnCoveredIndicationMutation,
    ManageIndication_PassOnCoveredIndicationMutationVariables
  >(ManageIndication_PassOnCoveredIndicationDocument, options);
}
export type ManageIndication_PassOnCoveredIndicationMutationHookResult = ReturnType<
  typeof useManageIndication_PassOnCoveredIndicationMutation
>;
export type ManageIndication_PassOnCoveredIndicationMutationResult =
  Apollo.MutationResult<ManageIndication_PassOnCoveredIndicationMutation>;
export type ManageIndication_PassOnCoveredIndicationMutationOptions = Apollo.BaseMutationOptions<
  ManageIndication_PassOnCoveredIndicationMutation,
  ManageIndication_PassOnCoveredIndicationMutationVariables
>;
export const ManageIndication_PassOnOfferingWithoutInstitutionalIndicationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {
        kind: 'Name',
        value: 'ManageIndication_PassOnOfferingWithoutInstitutionalIndication',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SubmitPassIndicationInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'passOnOfferingWithoutInstitutionalIndication' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ServiceErrorParts' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'InstitutionalIndication' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ServiceErrorParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ServiceError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'target' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'target' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type ManageIndication_PassOnOfferingWithoutInstitutionalIndicationMutationFn =
  Apollo.MutationFunction<
    ManageIndication_PassOnOfferingWithoutInstitutionalIndicationMutation,
    ManageIndication_PassOnOfferingWithoutInstitutionalIndicationMutationVariables
  >;

/**
 * __useManageIndication_PassOnOfferingWithoutInstitutionalIndicationMutation__
 *
 * To run a mutation, you first call `useManageIndication_PassOnOfferingWithoutInstitutionalIndicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManageIndication_PassOnOfferingWithoutInstitutionalIndicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [manageIndicationPassOnOfferingWithoutInstitutionalIndicationMutation, { data, loading, error }] = useManageIndication_PassOnOfferingWithoutInstitutionalIndicationMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useManageIndication_PassOnOfferingWithoutInstitutionalIndicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ManageIndication_PassOnOfferingWithoutInstitutionalIndicationMutation,
    ManageIndication_PassOnOfferingWithoutInstitutionalIndicationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ManageIndication_PassOnOfferingWithoutInstitutionalIndicationMutation,
    ManageIndication_PassOnOfferingWithoutInstitutionalIndicationMutationVariables
  >(ManageIndication_PassOnOfferingWithoutInstitutionalIndicationDocument, options);
}
export type ManageIndication_PassOnOfferingWithoutInstitutionalIndicationMutationHookResult =
  ReturnType<typeof useManageIndication_PassOnOfferingWithoutInstitutionalIndicationMutation>;
export type ManageIndication_PassOnOfferingWithoutInstitutionalIndicationMutationResult =
  Apollo.MutationResult<ManageIndication_PassOnOfferingWithoutInstitutionalIndicationMutation>;
export type ManageIndication_PassOnOfferingWithoutInstitutionalIndicationMutationOptions =
  Apollo.BaseMutationOptions<
    ManageIndication_PassOnOfferingWithoutInstitutionalIndicationMutation,
    ManageIndication_PassOnOfferingWithoutInstitutionalIndicationMutationVariables
  >;
export const ManageIndication_PassOnInstitutionalIndicationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ManageIndication_PassOnInstitutionalIndication' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'indicationId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'activateIfCancelled' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'passInstitutionalIndication' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'indicationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'indicationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'activateIfCancelled' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'activateIfCancelled' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ServiceErrorParts' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'InstitutionalIndication' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ServiceErrorParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ServiceError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'target' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'target' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type ManageIndication_PassOnInstitutionalIndicationMutationFn = Apollo.MutationFunction<
  ManageIndication_PassOnInstitutionalIndicationMutation,
  ManageIndication_PassOnInstitutionalIndicationMutationVariables
>;

/**
 * __useManageIndication_PassOnInstitutionalIndicationMutation__
 *
 * To run a mutation, you first call `useManageIndication_PassOnInstitutionalIndicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManageIndication_PassOnInstitutionalIndicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [manageIndicationPassOnInstitutionalIndicationMutation, { data, loading, error }] = useManageIndication_PassOnInstitutionalIndicationMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      indicationId: // value for 'indicationId'
 *      activateIfCancelled: // value for 'activateIfCancelled'
 *   },
 * });
 */
export function useManageIndication_PassOnInstitutionalIndicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ManageIndication_PassOnInstitutionalIndicationMutation,
    ManageIndication_PassOnInstitutionalIndicationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ManageIndication_PassOnInstitutionalIndicationMutation,
    ManageIndication_PassOnInstitutionalIndicationMutationVariables
  >(ManageIndication_PassOnInstitutionalIndicationDocument, options);
}
export type ManageIndication_PassOnInstitutionalIndicationMutationHookResult = ReturnType<
  typeof useManageIndication_PassOnInstitutionalIndicationMutation
>;
export type ManageIndication_PassOnInstitutionalIndicationMutationResult =
  Apollo.MutationResult<ManageIndication_PassOnInstitutionalIndicationMutation>;
export type ManageIndication_PassOnInstitutionalIndicationMutationOptions =
  Apollo.BaseMutationOptions<
    ManageIndication_PassOnInstitutionalIndicationMutation,
    ManageIndication_PassOnInstitutionalIndicationMutationVariables
  >;
export const ManageIndication_UpdateMyInstitutionalIndicationStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ManageIndication_UpdateMyInstitutionalIndicationStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'IndicationStatus' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateMyIndicationStatus' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'status' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'MyInstitutionalIndication' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type ManageIndication_UpdateMyInstitutionalIndicationStatusMutationFn =
  Apollo.MutationFunction<
    ManageIndication_UpdateMyInstitutionalIndicationStatusMutation,
    ManageIndication_UpdateMyInstitutionalIndicationStatusMutationVariables
  >;

/**
 * __useManageIndication_UpdateMyInstitutionalIndicationStatusMutation__
 *
 * To run a mutation, you first call `useManageIndication_UpdateMyInstitutionalIndicationStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManageIndication_UpdateMyInstitutionalIndicationStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [manageIndicationUpdateMyInstitutionalIndicationStatusMutation, { data, loading, error }] = useManageIndication_UpdateMyInstitutionalIndicationStatusMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useManageIndication_UpdateMyInstitutionalIndicationStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ManageIndication_UpdateMyInstitutionalIndicationStatusMutation,
    ManageIndication_UpdateMyInstitutionalIndicationStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ManageIndication_UpdateMyInstitutionalIndicationStatusMutation,
    ManageIndication_UpdateMyInstitutionalIndicationStatusMutationVariables
  >(ManageIndication_UpdateMyInstitutionalIndicationStatusDocument, options);
}
export type ManageIndication_UpdateMyInstitutionalIndicationStatusMutationHookResult = ReturnType<
  typeof useManageIndication_UpdateMyInstitutionalIndicationStatusMutation
>;
export type ManageIndication_UpdateMyInstitutionalIndicationStatusMutationResult =
  Apollo.MutationResult<ManageIndication_UpdateMyInstitutionalIndicationStatusMutation>;
export type ManageIndication_UpdateMyInstitutionalIndicationStatusMutationOptions =
  Apollo.BaseMutationOptions<
    ManageIndication_UpdateMyInstitutionalIndicationStatusMutation,
    ManageIndication_UpdateMyInstitutionalIndicationStatusMutationVariables
  >;

import { makeMockResponse } from '../../../../../../../../graphql/mock-response';

export const makeManageIndication_MdlAllocationRankingMockResponse = makeMockResponse<
  ManageIndication_MdlAllocationRankingQueryVariables,
  ManageIndication_MdlAllocationRankingQuery
>(ManageIndication_MdlAllocationRankingDocument);
