/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FinalSettlement_AccountingManagerPartsFragment = {
  readonly __typename?: 'AccountingManager';
  readonly id: string;
  readonly managerCmgEntityKey: string;
  readonly managerFirmName: string;
  readonly managerRole: Types.ExpensesManagerRole;
  readonly incentiveFeePercentage?: number | null;
  readonly managementFeesPercentage?: number | null;
  readonly potEconomics?: number | null;
  readonly baseUnderwritingPercentage?: number | null;
  readonly potEconomicsWithOverwrites?: number | null;
  readonly designatedShares?: number | null;
  readonly baseUnderwritingShares?: number | null;
  readonly overallotmentUnderwritingShares?: number | null;
  readonly retailRetention?: number | null;
  readonly managementFees?: number | null;
  readonly underwritingFees?: number | null;
  readonly designationFees?: number | null;
  readonly retailRetentionFees?: number | null;
  readonly stabilizationGainLoss?: number | null;
  readonly managerExpenseReimbursement?: number | null;
  readonly dealExpenses?: number | null;
  readonly netDealFees?: number | null;
  readonly manualAdjustment?: number | null;
  readonly description?: string | null;
  readonly finalSettlement?: number | null;
};

export type FinalSettlement_AccountingPartsFragment = {
  readonly __typename?: 'Accounting';
  readonly id: string;
  readonly notes?: string | null;
  readonly baseDealShares: number;
  readonly retailRetention?: number | null;
  readonly potAllocated: number;
  readonly excludedShares: number;
  readonly managerExpenseReimbursement?: number | null;
  readonly dealRelatedExpenses?: number | null;
  readonly offeringPrice: number;
  readonly totalSharesRepurchased?: number | null;
  readonly totalSharesRefreshed?: number | null;
  readonly overallotmentExercised?: number | null;
  readonly baseGrossSpreadValue?: number | null;
  readonly baseGrossSpreadPercent?: number | null;
  readonly incentiveFeeValue?: number | null;
  readonly incentiveFeePercent?: number | null;
  readonly baseManagementFee?: number | null;
  readonly incentiveManagementFee?: number | null;
  readonly baseUnderwritingFee?: number | null;
  readonly incentiveUnderwritingFee?: number | null;
  readonly baseSellingConcession?: number | null;
  readonly incentiveSellingConcession?: number | null;
  readonly averagePriceOfBuybackShares?: number | null;
  readonly stabilizationGainLoss?: number | null;
  readonly managers: ReadonlyArray<{
    readonly __typename?: 'AccountingManager';
    readonly id: string;
    readonly managerCmgEntityKey: string;
    readonly managerFirmName: string;
    readonly managerRole: Types.ExpensesManagerRole;
    readonly incentiveFeePercentage?: number | null;
    readonly managementFeesPercentage?: number | null;
    readonly potEconomics?: number | null;
    readonly baseUnderwritingPercentage?: number | null;
    readonly potEconomicsWithOverwrites?: number | null;
    readonly designatedShares?: number | null;
    readonly baseUnderwritingShares?: number | null;
    readonly overallotmentUnderwritingShares?: number | null;
    readonly retailRetention?: number | null;
    readonly managementFees?: number | null;
    readonly underwritingFees?: number | null;
    readonly designationFees?: number | null;
    readonly retailRetentionFees?: number | null;
    readonly stabilizationGainLoss?: number | null;
    readonly managerExpenseReimbursement?: number | null;
    readonly dealExpenses?: number | null;
    readonly netDealFees?: number | null;
    readonly manualAdjustment?: number | null;
    readonly description?: string | null;
    readonly finalSettlement?: number | null;
  }>;
};

export type FinalSettlement_SettlementAccountingQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type FinalSettlement_SettlementAccountingQuery = {
  readonly __typename?: 'Query';
  readonly settlementAccounting: {
    readonly __typename?: 'Accounting';
    readonly id: string;
    readonly notes?: string | null;
    readonly baseDealShares: number;
    readonly retailRetention?: number | null;
    readonly potAllocated: number;
    readonly excludedShares: number;
    readonly managerExpenseReimbursement?: number | null;
    readonly dealRelatedExpenses?: number | null;
    readonly offeringPrice: number;
    readonly totalSharesRepurchased?: number | null;
    readonly totalSharesRefreshed?: number | null;
    readonly overallotmentExercised?: number | null;
    readonly baseGrossSpreadValue?: number | null;
    readonly baseGrossSpreadPercent?: number | null;
    readonly incentiveFeeValue?: number | null;
    readonly incentiveFeePercent?: number | null;
    readonly baseManagementFee?: number | null;
    readonly incentiveManagementFee?: number | null;
    readonly baseUnderwritingFee?: number | null;
    readonly incentiveUnderwritingFee?: number | null;
    readonly baseSellingConcession?: number | null;
    readonly incentiveSellingConcession?: number | null;
    readonly averagePriceOfBuybackShares?: number | null;
    readonly stabilizationGainLoss?: number | null;
    readonly managers: ReadonlyArray<{
      readonly __typename?: 'AccountingManager';
      readonly id: string;
      readonly managerCmgEntityKey: string;
      readonly managerFirmName: string;
      readonly managerRole: Types.ExpensesManagerRole;
      readonly incentiveFeePercentage?: number | null;
      readonly managementFeesPercentage?: number | null;
      readonly potEconomics?: number | null;
      readonly baseUnderwritingPercentage?: number | null;
      readonly potEconomicsWithOverwrites?: number | null;
      readonly designatedShares?: number | null;
      readonly baseUnderwritingShares?: number | null;
      readonly overallotmentUnderwritingShares?: number | null;
      readonly retailRetention?: number | null;
      readonly managementFees?: number | null;
      readonly underwritingFees?: number | null;
      readonly designationFees?: number | null;
      readonly retailRetentionFees?: number | null;
      readonly stabilizationGainLoss?: number | null;
      readonly managerExpenseReimbursement?: number | null;
      readonly dealExpenses?: number | null;
      readonly netDealFees?: number | null;
      readonly manualAdjustment?: number | null;
      readonly description?: string | null;
      readonly finalSettlement?: number | null;
    }>;
  };
};

export const FinalSettlement_AccountingManagerPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FinalSettlement_AccountingManagerParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AccountingManager' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'managerCmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'managerFirmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'managerRole' } },
          { kind: 'Field', name: { kind: 'Name', value: 'incentiveFeePercentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'managementFeesPercentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'potEconomics' } },
          { kind: 'Field', name: { kind: 'Name', value: 'baseUnderwritingPercentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'potEconomicsWithOverwrites' } },
          { kind: 'Field', name: { kind: 'Name', value: 'designatedShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'baseUnderwritingShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overallotmentUnderwritingShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'retailRetention' } },
          { kind: 'Field', name: { kind: 'Name', value: 'managementFees' } },
          { kind: 'Field', name: { kind: 'Name', value: 'underwritingFees' } },
          { kind: 'Field', name: { kind: 'Name', value: 'designationFees' } },
          { kind: 'Field', name: { kind: 'Name', value: 'retailRetentionFees' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stabilizationGainLoss' } },
          { kind: 'Field', name: { kind: 'Name', value: 'managerExpenseReimbursement' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dealExpenses' } },
          { kind: 'Field', name: { kind: 'Name', value: 'netDealFees' } },
          { kind: 'Field', name: { kind: 'Name', value: 'manualAdjustment' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'finalSettlement' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const FinalSettlement_AccountingPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FinalSettlement_AccountingParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Accounting' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'baseDealShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'retailRetention' } },
          { kind: 'Field', name: { kind: 'Name', value: 'potAllocated' } },
          { kind: 'Field', name: { kind: 'Name', value: 'excludedShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'managerExpenseReimbursement' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dealRelatedExpenses' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalSharesRepurchased' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalSharesRefreshed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overallotmentExercised' } },
          { kind: 'Field', name: { kind: 'Name', value: 'baseGrossSpreadValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'baseGrossSpreadPercent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'incentiveFeeValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'incentiveFeePercent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'baseManagementFee' } },
          { kind: 'Field', name: { kind: 'Name', value: 'incentiveManagementFee' } },
          { kind: 'Field', name: { kind: 'Name', value: 'baseUnderwritingFee' } },
          { kind: 'Field', name: { kind: 'Name', value: 'incentiveUnderwritingFee' } },
          { kind: 'Field', name: { kind: 'Name', value: 'baseSellingConcession' } },
          { kind: 'Field', name: { kind: 'Name', value: 'incentiveSellingConcession' } },
          { kind: 'Field', name: { kind: 'Name', value: 'averagePriceOfBuybackShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stabilizationGainLoss' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'managers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FinalSettlement_AccountingManagerParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FinalSettlement_AccountingManagerParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AccountingManager' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'managerCmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'managerFirmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'managerRole' } },
          { kind: 'Field', name: { kind: 'Name', value: 'incentiveFeePercentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'managementFeesPercentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'potEconomics' } },
          { kind: 'Field', name: { kind: 'Name', value: 'baseUnderwritingPercentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'potEconomicsWithOverwrites' } },
          { kind: 'Field', name: { kind: 'Name', value: 'designatedShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'baseUnderwritingShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overallotmentUnderwritingShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'retailRetention' } },
          { kind: 'Field', name: { kind: 'Name', value: 'managementFees' } },
          { kind: 'Field', name: { kind: 'Name', value: 'underwritingFees' } },
          { kind: 'Field', name: { kind: 'Name', value: 'designationFees' } },
          { kind: 'Field', name: { kind: 'Name', value: 'retailRetentionFees' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stabilizationGainLoss' } },
          { kind: 'Field', name: { kind: 'Name', value: 'managerExpenseReimbursement' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dealExpenses' } },
          { kind: 'Field', name: { kind: 'Name', value: 'netDealFees' } },
          { kind: 'Field', name: { kind: 'Name', value: 'manualAdjustment' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'finalSettlement' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const FinalSettlement_SettlementAccountingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FinalSettlement_SettlementAccounting' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'settlementAccounting' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FinalSettlement_AccountingParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FinalSettlement_AccountingManagerParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AccountingManager' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'managerCmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'managerFirmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'managerRole' } },
          { kind: 'Field', name: { kind: 'Name', value: 'incentiveFeePercentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'managementFeesPercentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'potEconomics' } },
          { kind: 'Field', name: { kind: 'Name', value: 'baseUnderwritingPercentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'potEconomicsWithOverwrites' } },
          { kind: 'Field', name: { kind: 'Name', value: 'designatedShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'baseUnderwritingShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overallotmentUnderwritingShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'retailRetention' } },
          { kind: 'Field', name: { kind: 'Name', value: 'managementFees' } },
          { kind: 'Field', name: { kind: 'Name', value: 'underwritingFees' } },
          { kind: 'Field', name: { kind: 'Name', value: 'designationFees' } },
          { kind: 'Field', name: { kind: 'Name', value: 'retailRetentionFees' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stabilizationGainLoss' } },
          { kind: 'Field', name: { kind: 'Name', value: 'managerExpenseReimbursement' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dealExpenses' } },
          { kind: 'Field', name: { kind: 'Name', value: 'netDealFees' } },
          { kind: 'Field', name: { kind: 'Name', value: 'manualAdjustment' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'finalSettlement' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FinalSettlement_AccountingParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Accounting' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'baseDealShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'retailRetention' } },
          { kind: 'Field', name: { kind: 'Name', value: 'potAllocated' } },
          { kind: 'Field', name: { kind: 'Name', value: 'excludedShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'managerExpenseReimbursement' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dealRelatedExpenses' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalSharesRepurchased' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalSharesRefreshed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overallotmentExercised' } },
          { kind: 'Field', name: { kind: 'Name', value: 'baseGrossSpreadValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'baseGrossSpreadPercent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'incentiveFeeValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'incentiveFeePercent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'baseManagementFee' } },
          { kind: 'Field', name: { kind: 'Name', value: 'incentiveManagementFee' } },
          { kind: 'Field', name: { kind: 'Name', value: 'baseUnderwritingFee' } },
          { kind: 'Field', name: { kind: 'Name', value: 'incentiveUnderwritingFee' } },
          { kind: 'Field', name: { kind: 'Name', value: 'baseSellingConcession' } },
          { kind: 'Field', name: { kind: 'Name', value: 'incentiveSellingConcession' } },
          { kind: 'Field', name: { kind: 'Name', value: 'averagePriceOfBuybackShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stabilizationGainLoss' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'managers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FinalSettlement_AccountingManagerParts' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useFinalSettlement_SettlementAccountingQuery__
 *
 * To run a query within a React component, call `useFinalSettlement_SettlementAccountingQuery` and pass it any options that fit your needs.
 * When your component renders, `useFinalSettlement_SettlementAccountingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFinalSettlement_SettlementAccountingQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useFinalSettlement_SettlementAccountingQuery(
  baseOptions: Apollo.QueryHookOptions<
    FinalSettlement_SettlementAccountingQuery,
    FinalSettlement_SettlementAccountingQueryVariables
  > &
    (
      | { variables: FinalSettlement_SettlementAccountingQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FinalSettlement_SettlementAccountingQuery,
    FinalSettlement_SettlementAccountingQueryVariables
  >(FinalSettlement_SettlementAccountingDocument, options);
}
export function useFinalSettlement_SettlementAccountingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FinalSettlement_SettlementAccountingQuery,
    FinalSettlement_SettlementAccountingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FinalSettlement_SettlementAccountingQuery,
    FinalSettlement_SettlementAccountingQueryVariables
  >(FinalSettlement_SettlementAccountingDocument, options);
}
export function useFinalSettlement_SettlementAccountingSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FinalSettlement_SettlementAccountingQuery,
        FinalSettlement_SettlementAccountingQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FinalSettlement_SettlementAccountingQuery,
    FinalSettlement_SettlementAccountingQueryVariables
  >(FinalSettlement_SettlementAccountingDocument, options);
}
export type FinalSettlement_SettlementAccountingQueryHookResult = ReturnType<
  typeof useFinalSettlement_SettlementAccountingQuery
>;
export type FinalSettlement_SettlementAccountingLazyQueryHookResult = ReturnType<
  typeof useFinalSettlement_SettlementAccountingLazyQuery
>;
export type FinalSettlement_SettlementAccountingSuspenseQueryHookResult = ReturnType<
  typeof useFinalSettlement_SettlementAccountingSuspenseQuery
>;
export type FinalSettlement_SettlementAccountingQueryResult = Apollo.QueryResult<
  FinalSettlement_SettlementAccountingQuery,
  FinalSettlement_SettlementAccountingQueryVariables
>;

import { makeMockResponse } from '../../../../../graphql/mock-response';

export const makeFinalSettlement_SettlementAccountingMockResponse = makeMockResponse<
  FinalSettlement_SettlementAccountingQueryVariables,
  FinalSettlement_SettlementAccountingQuery
>(FinalSettlement_SettlementAccountingDocument);
