import isNil from 'lodash/isNil';
import * as yup from 'yup';

import { UnderwritingTermsFeesAndDiscounts } from '../../../graphql';
import { FormFields, FormStatus } from './UnderwritingTermsDiscountsAndFeesSection';

const INVALID_BASE_GROSS_SPREAD_ALLOCATION_MESSAGE =
  'Base Gross Spread Allocation must equal 0 or sum up to Base Gross Spread.';
const INVALID_INCENTIVE_FEE_ALLOCATION_MESSAGE =
  'Incentive Fee Allocation must equal 0 or sum up to Incentive Fee.';

export const UnderwritingDiscountsAndFeesSchema = yup.object().shape({
  totalBaseGrossSpreadAllocationPercentageDiff: yup
    .number()
    .nullable()
    .oneOf([0, 1], INVALID_BASE_GROSS_SPREAD_ALLOCATION_MESSAGE),
  totalBaseGrossSpreadAllocationDiff: yup
    .number()
    .nullable()
    .when('grossSpreadBaseData.grossSpreadBase', ([grossSpreadBaseCurrency], schema) => {
      if (grossSpreadBaseCurrency === null) return schema;
      return schema.oneOf(
        [0, grossSpreadBaseCurrency],
        INVALID_BASE_GROSS_SPREAD_ALLOCATION_MESSAGE
      );
    }),
  totalIncentiveGrossSpreadAllocationPercentageDiff: yup
    .number()
    .nullable()
    .oneOf([0, 1], INVALID_INCENTIVE_FEE_ALLOCATION_MESSAGE),
  totalIncentiveGrossSpreadAllocationDiff: yup
    .number()
    .nullable()
    .when('incentiveFeeData.incentiveFee', ([incentiveFeeBaseCurrency], schema) => {
      if (incentiveFeeBaseCurrency === null) return schema;
      return schema.oneOf([0, incentiveFeeBaseCurrency], INVALID_INCENTIVE_FEE_ALLOCATION_MESSAGE);
    }),
  grossSpreadBaseData: yup.object().shape({
    grossSpreadBase: yup
      .number()
      .nullable()
      .min(0, 'Base Gross Spread must be greater or equal to 0')
      .test(
        'Check Base Gross Spread Allocation lower than Offer Price',
        'Base Gross Spread must be less than or equal to the Offer Price',
        function (this, value) {
          if (!this.options.context!.values.offerPrice) return true;
          return this.options.context!.values.offerPrice >= value!;
        }
      ),
    managementFee: yup
      .number()
      .nullable()
      .min(0, 'Base Gross Spread Management Fee must be greater or equal to 0')
      .test(
        'Check Base Gross Spread Allocation difference',
        INVALID_BASE_GROSS_SPREAD_ALLOCATION_MESSAGE,
        function (this) {
          const { values, submitClicks } = this.options.context!;
          if (submitClicks.current === 0 || values.grossSpreadBaseData.grossSpreadBase === null)
            return true;
          return [null, 0, values.grossSpreadBaseData.grossSpreadBase].includes(
            values.totalBaseGrossSpreadAllocationDiff
          );
        }
      ),
    underwritingFee: yup
      .number()
      .nullable()
      .min(0, 'Base Gross Spread Underwriting Fee must be greater or equal to 0')
      .test(
        'Check Base Gross Spread Allocation difference',
        INVALID_BASE_GROSS_SPREAD_ALLOCATION_MESSAGE,
        function (this) {
          const { values, submitClicks } = this.options.context!;
          if (submitClicks.current === 0 || values.grossSpreadBaseData.grossSpreadBase === null)
            return true;
          return [null, 0, values.grossSpreadBaseData.grossSpreadBase].includes(
            values.totalBaseGrossSpreadAllocationDiff
          );
        }
      ),
    sellingConcession: yup
      .number()
      .nullable()
      .min(0, 'Base Gross Spread Selling Concession must be greater or equal to 0')
      .test(
        'Check Base Gross Spread Allocation difference',
        INVALID_BASE_GROSS_SPREAD_ALLOCATION_MESSAGE,
        function (this) {
          const { values, submitClicks } = this.options.context!;
          if (submitClicks.current === 0 || values.grossSpreadBaseData.grossSpreadBase === null)
            return true;
          return [null, 0, values.grossSpreadBaseData.grossSpreadBase].includes(
            values.totalBaseGrossSpreadAllocationDiff
          );
        }
      ),
    managementFeePercentage: yup
      .number()
      .nullable()
      .min(0, 'Base Gross Spread Management Fee must be greater or equal to 0')
      .test(
        'Check Base Gross Spread Allocation difference',
        INVALID_BASE_GROSS_SPREAD_ALLOCATION_MESSAGE,
        function (this) {
          const { values, submitClicks } = this.options.context!;
          if (submitClicks.current === 0) return true;
          if (values.grossSpreadBaseData.managementFeePercentage === null) return true;
          return [null, 0, 1].includes(values.totalBaseGrossSpreadAllocationPercentageDiff);
        }
      ),
    underwritingFeePercentage: yup
      .number()
      .nullable()
      .min(0, 'Base Gross Spread Underwriting Fee must be greater or equal to 0')
      .test(
        'Check Base Gross Spread Allocation difference',
        INVALID_BASE_GROSS_SPREAD_ALLOCATION_MESSAGE,
        function (this) {
          const { values, submitClicks } = this.options.context!;
          if (submitClicks.current === 0) return true;
          if (values.grossSpreadBaseData.underwritingFeePercentage === null) return true;
          return [null, 0, 1].includes(values.totalBaseGrossSpreadAllocationPercentageDiff);
        }
      ),
    sellingConcessionPercentage: yup
      .number()
      .nullable()
      .min(0, 'Base Gross Spread Selling Concession must be greater or equal to 0')
      .test(
        'Check Base Gross Spread Allocation difference',
        INVALID_BASE_GROSS_SPREAD_ALLOCATION_MESSAGE,
        function (this) {
          const { values, submitClicks } = this.options.context!;
          if (submitClicks.current === 0) return true;
          if (values.grossSpreadBaseData.sellingConcessionPercentage === null) return true;
          return [null, 0, 1].includes(values.totalBaseGrossSpreadAllocationPercentageDiff);
        }
      ),
  }),
  incentiveFeeData: yup.object().shape({
    incentiveFee: yup
      .number()
      .nullable()
      .min(0, 'Incentive Fee must be greater or equal to 0')
      .test(
        'Check Incentive Fee Allocation lower than Offer Price',
        'Incentive Fee must be less than or equal to the Offer Price',
        function (this, value) {
          if (!this.options.context!.values.offerPrice) return true;
          return this.options.context!.values.offerPrice >= value!;
        }
      ),
    managementFee: yup
      .number()
      .nullable()
      .min(0, 'Incentive Fee Management Fee must be greater or equal to 0')
      .test(
        'Check Incentive Fee Allocation difference',
        INVALID_INCENTIVE_FEE_ALLOCATION_MESSAGE,
        function (this) {
          const { values, submitClicks } = this.options.context!;
          if (submitClicks.current === 0 || values.incentiveFeeData.incentiveFee === null)
            return true;
          return [null, 0, values.incentiveFeeData.incentiveFee].includes(
            values.totalIncentiveGrossSpreadAllocationDiff
          );
        }
      ),
    underwritingFee: yup
      .number()
      .nullable()
      .min(0, 'Incentive Fee Underwriting Fee must be greater or equal to 0')
      .test(
        'Check Incentive Fee Allocation difference',
        INVALID_INCENTIVE_FEE_ALLOCATION_MESSAGE,
        function (this) {
          const { values, submitClicks } = this.options.context!;
          if (submitClicks.current === 0 || values.incentiveFeeData.incentiveFee === null)
            return true;
          return [null, 0, values.incentiveFeeData.incentiveFee].includes(
            values.totalIncentiveGrossSpreadAllocationDiff
          );
        }
      ),
    sellingConcession: yup
      .number()
      .nullable()
      .min(0, 'Incentive Fee Selling Concession must be greater or equal to 0')
      .test(
        'Check Incentive Fee Allocation difference',
        INVALID_INCENTIVE_FEE_ALLOCATION_MESSAGE,
        function (this) {
          const { values, submitClicks } = this.options.context!;
          if (submitClicks.current === 0 || values.incentiveFeeData.incentiveFee === null)
            return true;
          return [null, 0, values.incentiveFeeData.incentiveFee].includes(
            values.totalIncentiveGrossSpreadAllocationDiff
          );
        }
      ),
    managementFeePercentage: yup
      .number()
      .nullable()
      .min(0, 'Incentive Fee Management Fee must be greater or equal to 0')
      .test(
        'Check Incentive Fee Allocation difference',
        INVALID_INCENTIVE_FEE_ALLOCATION_MESSAGE,
        function (this) {
          const { values, submitClicks } = this.options.context!;
          if (submitClicks.current === 0) return true;
          if (values.incentiveFeeData.sellingConcessionPercentage === null) return true;
          return [null, 0, 1].includes(values.totalIncentiveGrossSpreadAllocationPercentageDiff);
        }
      ),
    underwritingFeePercentage: yup
      .number()
      .nullable()
      .min(0, 'Incentive Fee Underwriting Fee must be greater or equal to 0')
      .test(
        'Check Incentive Fee Allocation difference',
        INVALID_INCENTIVE_FEE_ALLOCATION_MESSAGE,
        function (this) {
          const { values, submitClicks } = this.options.context!;
          if (submitClicks.current === 0) return true;
          if (values.incentiveFeeData.underwritingFeePercentage === null) return true;
          return [null, 0, 1].includes(values.totalIncentiveGrossSpreadAllocationPercentageDiff);
        }
      ),
    sellingConcessionPercentage: yup
      .number()
      .nullable()
      .min(0, 'Incentive Fee Selling Concession must be greater or equal to 0')
      .test(
        'Check Incentive Fee Allocation difference',
        INVALID_INCENTIVE_FEE_ALLOCATION_MESSAGE,
        function (this) {
          const { values, submitClicks } = this.options.context!;
          if (submitClicks.current === 0) return true;
          if (values.incentiveFeeData.sellingConcessionPercentage === null) return true;
          return [null, 0, 1].includes(values.totalIncentiveGrossSpreadAllocationPercentageDiff);
        }
      ),
  }),
});

export type TotalCalculations = {
  netPrice: number | null;
  grossSpreadTotalPercentage: number | null;
  grossSpreadTotal: number | null;
  managementFee: number | null;
  underwritingFee: number | null;
  sellingConcession: number | null;
  totalBaseGrossSpreadAllocationPercentage: number | null;
  totalBaseGrossSpreadAllocationPercentageDiff: number | null;
  totalBaseGrossSpreadAllocation: number | null;
  totalBaseGrossSpreadAllocationDiff: number | null;
  totalIncentiveGrossSpreadAllocationPercentage: number | null;
  totalIncentiveGrossSpreadAllocationPercentageDiff: number | null;
  totalIncentiveGrossSpreadAllocation: number | null;
  totalIncentiveGrossSpreadAllocationDiff: number | null;
  totalCurrency: number | null;
  totalCurrencyDiff: number | null;
};

function numberWithPrecision(value: number, precision = 6) {
  return Number(value.toFixed(precision));
}

function getNullWhenAllMissing(inputs: (number | null | undefined)[], value: number) {
  return inputs.every(isNil) ? null : value;
}

export function getTotalCalculations(values: FormFields): TotalCalculations {
  const totalBaseGrossSpreadAllocationPercentage = getNullWhenAllMissing(
    [
      values.grossSpreadBaseData?.managementFeePercentage,
      values.grossSpreadBaseData?.underwritingFeePercentage,
      values.grossSpreadBaseData?.sellingConcessionPercentage,
    ],
    numberWithPrecision(
      values.grossSpreadBaseData!.managementFeePercentage! +
        values.grossSpreadBaseData!.underwritingFeePercentage! +
        values.grossSpreadBaseData!.sellingConcessionPercentage!,
      4
    )
  );

  const totalBaseGrossSpreadAllocation = getNullWhenAllMissing(
    [
      values.grossSpreadBaseData?.managementFee,
      values.grossSpreadBaseData?.underwritingFee,
      values.grossSpreadBaseData?.sellingConcession,
    ],
    numberWithPrecision(
      values.grossSpreadBaseData!.managementFee! +
        values.grossSpreadBaseData!.underwritingFee! +
        values.grossSpreadBaseData!.sellingConcession!
    )
  );
  const totalIncentiveGrossSpreadAllocation = getNullWhenAllMissing(
    [
      values.incentiveFeeData?.managementFee,
      values.incentiveFeeData?.underwritingFee,
      values.incentiveFeeData?.sellingConcession,
    ],
    numberWithPrecision(
      values.incentiveFeeData!.managementFee! +
        values.incentiveFeeData!.underwritingFee! +
        values.incentiveFeeData!.sellingConcession!
    )
  );
  const totalIncentiveGrossSpreadAllocationPercentage = getNullWhenAllMissing(
    [
      values.incentiveFeeData?.managementFeePercentage,
      values.incentiveFeeData?.underwritingFeePercentage,
      values.incentiveFeeData?.sellingConcessionPercentage,
    ],
    numberWithPrecision(
      values.incentiveFeeData!.managementFeePercentage! +
        values.incentiveFeeData!.underwritingFeePercentage! +
        values.incentiveFeeData!.sellingConcessionPercentage!,
      4
    )
  );
  const totalCurrency = getNullWhenAllMissing(
    [totalBaseGrossSpreadAllocation, totalIncentiveGrossSpreadAllocation],
    numberWithPrecision(
      (totalBaseGrossSpreadAllocation || 0) + (totalIncentiveGrossSpreadAllocation || 0)
    )
  );
  const grossSpreadTotal = getNullWhenAllMissing(
    [values.grossSpreadBaseData?.grossSpreadBase, values.incentiveFeeData?.incentiveFee],
    numberWithPrecision(
      values.grossSpreadBaseData!.grossSpreadBase! + values.incentiveFeeData!.incentiveFee!
    )
  );
  return {
    netPrice: [values.offerPrice, grossSpreadTotal].some(isNil)
      ? null
      : numberWithPrecision(values.offerPrice! - grossSpreadTotal!),
    grossSpreadTotalPercentage: getNullWhenAllMissing(
      [
        values.grossSpreadBaseData?.grossSpreadBasePercentage,
        values.incentiveFeeData?.incentiveFeePercentage,
      ],
      numberWithPrecision(
        values.grossSpreadBaseData!.grossSpreadBasePercentage! +
          values.incentiveFeeData!.incentiveFeePercentage!,
        4
      )
    ),
    grossSpreadTotal,
    managementFee: getNullWhenAllMissing(
      [values.grossSpreadBaseData?.managementFee, values.incentiveFeeData?.managementFee],
      numberWithPrecision(
        values.grossSpreadBaseData!.managementFee! + values.incentiveFeeData!.managementFee!
      )
    ),
    underwritingFee: getNullWhenAllMissing(
      [values.grossSpreadBaseData?.underwritingFee, values.incentiveFeeData?.underwritingFee],
      numberWithPrecision(
        values.grossSpreadBaseData!.underwritingFee! + values.incentiveFeeData!.underwritingFee!
      )
    ),
    sellingConcession: getNullWhenAllMissing(
      [values.grossSpreadBaseData?.sellingConcession, values.incentiveFeeData?.sellingConcession],
      numberWithPrecision(
        values.grossSpreadBaseData!.sellingConcession! + values.incentiveFeeData!.sellingConcession!
      )
    ),
    totalBaseGrossSpreadAllocationPercentage,
    totalBaseGrossSpreadAllocationPercentageDiff: isNil(totalBaseGrossSpreadAllocationPercentage)
      ? null
      : numberWithPrecision(1 - totalBaseGrossSpreadAllocationPercentage),
    totalBaseGrossSpreadAllocation,
    totalBaseGrossSpreadAllocationDiff: isNil(totalBaseGrossSpreadAllocation)
      ? null
      : numberWithPrecision(
          values.grossSpreadBaseData!.grossSpreadBase! - totalBaseGrossSpreadAllocation
        ),
    totalIncentiveGrossSpreadAllocationPercentage,
    totalIncentiveGrossSpreadAllocationPercentageDiff: isNil(
      totalIncentiveGrossSpreadAllocationPercentage
    )
      ? null
      : numberWithPrecision(1 - totalIncentiveGrossSpreadAllocationPercentage),
    totalIncentiveGrossSpreadAllocation,
    totalIncentiveGrossSpreadAllocationDiff: isNil(totalIncentiveGrossSpreadAllocation)
      ? null
      : numberWithPrecision(
          values.incentiveFeeData!.incentiveFee! - totalIncentiveGrossSpreadAllocation
        ),
    totalCurrency,
    totalCurrencyDiff: isNil(totalCurrency)
      ? null
      : numberWithPrecision(
          values.grossSpreadBaseData!.grossSpreadBase! +
            values.incentiveFeeData!.incentiveFee! -
            totalCurrency
        ),
  };
}

export function getFormIsEditing(status: FormStatus) {
  const isEditingPercentage = status === FormStatus.EditingPercentage;
  const isEditingCurrency = status === FormStatus.EditingCurrency;
  const isEditing = isEditingPercentage || isEditingCurrency;
  return {
    isEditing,
    isEditingPercentage,
    isEditingCurrency,
  };
}

type GetFormInitialValuesParams = {
  offerPrice: number | null;
  underwritingDiscountsFeesData?: UnderwritingTermsFeesAndDiscounts;
};

export function getFormInitialValues({
  offerPrice,
  underwritingDiscountsFeesData,
}: GetFormInitialValuesParams) {
  const initialValues = {
    offerPrice,
    ...underwritingDiscountsFeesData,
    grossSpreadBaseData: {
      ...underwritingDiscountsFeesData?.grossSpreadBaseData,
    },
    incentiveFeeData: {
      ...underwritingDiscountsFeesData?.incentiveFeeData,
    },
  };
  return {
    ...initialValues,
    ...getTotalCalculations(initialValues),
  };
}
