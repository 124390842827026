/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OfferingSetup_BasicInfoModal_IssuerPartsFragment = {
  readonly __typename?: 'Issuer';
  readonly id: string;
  readonly name: string;
  readonly lei?: string | null;
  readonly cik?: string | null;
  readonly address?: string | null;
  readonly businessDescription?: string | null;
  readonly website?: string | null;
  readonly sector?: Types.Sector | null;
};

export type OfferingSetup_BasicInfoModal_SecurityPartsFragment = {
  readonly __typename?: 'Security';
  readonly id: string;
  readonly type: Types.SecurityType;
  readonly isDepositaryReceipt: boolean;
  readonly shareClass?: Types.SecurityShareClass | null;
};

export type OfferingSetup_BasicInfoModal_ServerErrorPartsFragment = {
  readonly __typename?: 'ServiceError';
  readonly code: Types.ServiceErrorCode;
  readonly message: string;
  readonly target?: string | null;
  readonly details?: ReadonlyArray<{
    readonly __typename?: 'ErrorDetail';
    readonly code?: string | null;
    readonly message?: string | null;
    readonly target?: string | null;
  }> | null;
};

export type OfferingSetup_BasicInfoRoute_BasicInfoQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type OfferingSetup_BasicInfoRoute_BasicInfoQuery = {
  readonly __typename?: 'Query';
  readonly offering: {
    readonly __typename?: 'Offering';
    readonly id: string;
    readonly type: Types.OfferingType;
    readonly stage: Types.OfferingStage;
    readonly pricingCurrencyCode: string;
    readonly pricingInstrumentId?: string | null;
    readonly instruments: ReadonlyArray<{
      readonly __typename?: 'DeliveryInstrument';
      readonly id: string;
      readonly countryCode: string;
    }>;
    readonly issuer: {
      readonly __typename?: 'Issuer';
      readonly id: string;
      readonly name: string;
      readonly lei?: string | null;
      readonly cik?: string | null;
      readonly address?: string | null;
      readonly businessDescription?: string | null;
      readonly website?: string | null;
      readonly sector?: Types.Sector | null;
    };
    readonly security: {
      readonly __typename?: 'Security';
      readonly id: string;
      readonly type: Types.SecurityType;
      readonly isDepositaryReceipt: boolean;
      readonly shareClass?: Types.SecurityShareClass | null;
    };
  };
};

export type OfferingSetup_BasicInfoRoute_PublishedOfferingQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type OfferingSetup_BasicInfoRoute_PublishedOfferingQuery = {
  readonly __typename?: 'Query';
  readonly publishedOffering: {
    readonly __typename?: 'OfferingProfile';
    readonly id: string;
    readonly status: Types.OfferingStatus;
  };
};

export type OfferingSetup_BasicInfoRoute_UpdateBasicInfoMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  input: Types.UpdateBasicInfoInput;
}>;

export type OfferingSetup_BasicInfoRoute_UpdateBasicInfoMutation = {
  readonly __typename?: 'Mutation';
  readonly updateBasicInfo:
    | {
        readonly __typename?: 'Offering';
        readonly id: string;
        readonly type: Types.OfferingType;
        readonly stage: Types.OfferingStage;
        readonly pricingCurrencyCode: string;
        readonly issuer: {
          readonly __typename?: 'Issuer';
          readonly id: string;
          readonly name: string;
          readonly lei?: string | null;
          readonly cik?: string | null;
          readonly address?: string | null;
          readonly businessDescription?: string | null;
          readonly website?: string | null;
          readonly sector?: Types.Sector | null;
        };
        readonly security: {
          readonly __typename?: 'Security';
          readonly id: string;
          readonly type: Types.SecurityType;
          readonly isDepositaryReceipt: boolean;
          readonly shareClass?: Types.SecurityShareClass | null;
        };
      }
    | {
        readonly __typename?: 'ServiceError';
        readonly code: Types.ServiceErrorCode;
        readonly message: string;
        readonly target?: string | null;
        readonly details?: ReadonlyArray<{
          readonly __typename?: 'ErrorDetail';
          readonly code?: string | null;
          readonly message?: string | null;
          readonly target?: string | null;
        }> | null;
      };
};

export type OfferingSetup_BasicInfoRoute_CreateOfferingDraftMutationVariables = Types.Exact<{
  input: Types.CreateOfferingInput;
}>;

export type OfferingSetup_BasicInfoRoute_CreateOfferingDraftMutation = {
  readonly __typename?: 'Mutation';
  readonly createOfferingDraft:
    | { readonly __typename?: 'Offering'; readonly id: string }
    | {
        readonly __typename?: 'ServiceError';
        readonly code: Types.ServiceErrorCode;
        readonly message: string;
        readonly target?: string | null;
        readonly details?: ReadonlyArray<{
          readonly __typename?: 'ErrorDetail';
          readonly code?: string | null;
          readonly message?: string | null;
          readonly target?: string | null;
        }> | null;
      };
};

export type OfferingSetup_Components_SearchUnderwritersQueryVariables = Types.Exact<{
  name: Types.Scalars['String'];
  page: Types.Scalars['Int'];
  perPage: Types.Scalars['Int'];
}>;

export type OfferingSetup_Components_SearchUnderwritersQuery = {
  readonly __typename?: 'Query';
  readonly underwriters: {
    readonly __typename?: 'FirmLimitedPagedList';
    readonly data: ReadonlyArray<{
      readonly __typename?: 'FirmLimited';
      readonly cmgEntityKey: string;
      readonly displayName?: string | null;
      readonly mpid?: string | null;
      readonly crd?: string | null;
      readonly isOnPlatform: boolean;
    }>;
    readonly pagination: {
      readonly __typename?: 'PaginationData';
      readonly hasNext?: boolean | null;
    };
  };
};

export type OfferingSetup_HeaderQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type OfferingSetup_HeaderQuery = {
  readonly __typename?: 'Query';
  readonly offering: {
    readonly __typename?: 'Offering';
    readonly id: string;
    readonly version: string;
    readonly status: Types.OfferingStatus;
    readonly stage: Types.OfferingStage;
    readonly isDiscarded: boolean;
    readonly hasUnpublishedChanges: boolean;
    readonly issuer: { readonly __typename?: 'Issuer'; readonly name: string };
  };
};

export type OfferingSetup_InProgressOffering_DisclaimersPartsFragment = {
  readonly __typename?: 'Disclaimers';
  readonly id: string;
  readonly restrictions?: string | null;
  readonly footnotes?: string | null;
  readonly registrationLinks: ReadonlyArray<string>;
  readonly rule134Legend?: string | null;
};

export type OfferingSetup_InProgressOffering_FilingPartsFragment = {
  readonly __typename?: 'Filing';
  readonly id: string;
  readonly filingType: Types.FilingType;
  readonly initialRegistrationValue?: number | null;
  readonly primarySharesBaseOffering?: number | null;
  readonly secondarySharesBaseOffering?: number | null;
  readonly primarySharesOverAllotmentAuthorized?: number | null;
  readonly secondarySharesOverAllotmentAuthorized?: number | null;
  readonly notionalOverAllotmentExercised?: number | null;
  readonly primarySharesOverAllotmentExercised?: number | null;
  readonly secondarySharesOverAllotmentExercised?: number | null;
  readonly notionalOverAllotmentAuthorized?: number | null;
  readonly totalSharesBaseOffering?: number | null;
  readonly totalSharesOverAllotmentAuthorized?: number | null;
  readonly totalSharesOverAllotmentExercised?: number | null;
  readonly ipoRangeLow?: number | null;
  readonly ipoRangeHigh?: number | null;
  readonly offerPrice?: number | null;
  readonly postOfferingShares?: number | null;
  readonly useOfProceeds: ReadonlyArray<Types.UseOfProceeds>;
  readonly lockUpPeriod?: number | null;
};

export type OfferingSetup_InProgressOffering_FollowOnPricingPartsFragment = {
  readonly __typename?: 'FollowOnPricing';
  readonly id: string;
  readonly lastTradeBeforeFiling?: number | null;
  readonly lastTradeBeforeLaunch?: number | null;
  readonly lastTradeBeforeOffer?: number | null;
  readonly reOfferLow?: number | null;
  readonly reOfferHigh?: number | null;
};

export type OfferingSetup_InProgressOffering_ManagerEconomicsPartsFragment = {
  readonly __typename?: 'ManagerEconomics';
  readonly id: string;
  readonly incentiveFeeDistribution: ReadonlyArray<{
    readonly __typename?: 'PercentagePerCmgEntity';
    readonly cmgEntityKey: string;
    readonly percentageValue: number;
  }>;
  readonly underwritingFeeBaseDistribution: ReadonlyArray<{
    readonly __typename?: 'PercentagePerCmgEntity';
    readonly cmgEntityKey: string;
    readonly percentageValue: number;
  }>;
  readonly managementFeeDistribution: ReadonlyArray<{
    readonly __typename?: 'PercentagePerCmgEntity';
    readonly cmgEntityKey: string;
    readonly percentageValue: number;
  }>;
  readonly sellingConcessionDistribution: ReadonlyArray<{
    readonly __typename?: 'PercentagePerCmgEntity';
    readonly cmgEntityKey: string;
    readonly percentageValue: number;
  }>;
  readonly underwritingFeeTotalDistribution: ReadonlyArray<{
    readonly __typename?: 'PercentagePerCmgEntity';
    readonly cmgEntityKey: string;
    readonly percentageValue: number;
  }>;
  readonly underwritingBaseSharesDistribution: ReadonlyArray<{
    readonly __typename?: 'SharesPerCmgEntity';
    readonly cmgEntityKey: string;
    readonly sharesValue: number;
  }>;
  readonly overallotmentExercisedSharesDistribution: ReadonlyArray<{
    readonly __typename?: 'SharesPerCmgEntity';
    readonly cmgEntityKey: string;
    readonly sharesValue: number;
  }>;
  readonly underwritingTotalSharesDistribution: ReadonlyArray<{
    readonly __typename?: 'SharesPerCmgEntity';
    readonly cmgEntityKey: string;
    readonly sharesValue: number;
  }>;
};

export type OfferingSetup_InProgressOffering_ManagerResponsibilitiePartsFragment = {
  readonly __typename?: 'ManagerResponsibilities';
  readonly id: string;
  readonly stabilizationAgent?: string | null;
  readonly settlementAgent?: string | null;
  readonly logisticsAgent?: string | null;
  readonly leftLead?: string | null;
};

export type OfferingSetup_InProgressOffering_MarketingPartsFragment = {
  readonly __typename?: 'Marketing';
  readonly id: string;
  readonly managementTeam?: string | null;
  readonly callDetails?: string | null;
};

export type OfferingSetup_InProgressOffering_UnderwritingFeesAndDiscountsPartsFragment = {
  readonly __typename?: 'UnderwritingFeesAndDiscounts';
  readonly reallowance?: number | null;
  readonly sharesExcludedFromGrossSpread?: number | null;
  readonly managementFee?: number | null;
  readonly underwritingFee?: number | null;
  readonly sellingConcession?: number | null;
  readonly grossSpreadTotal?: number | null;
  readonly netPrice?: number | null;
  readonly grossSpreadBaseData?: {
    readonly __typename?: 'GrossSpreadBase';
    readonly grossSpreadBase?: number | null;
    readonly managementFeePercentage?: number | null;
    readonly underwritingFeePercentage?: number | null;
    readonly sellingConcessionPercentage?: number | null;
  } | null;
  readonly incentiveFeeData?: {
    readonly __typename?: 'IncentiveFee';
    readonly incentiveFee?: number | null;
    readonly managementFeePercentage?: number | null;
    readonly underwritingFeePercentage?: number | null;
    readonly sellingConcessionPercentage?: number | null;
  } | null;
};

export type OfferingSetup_InProgressOfferingQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type OfferingSetup_InProgressOfferingQuery = {
  readonly __typename?: 'Query';
  readonly offering: {
    readonly __typename?: 'Offering';
    readonly pricingInstrumentId?: string | null;
    readonly dspAgent?: string | null;
    readonly version: string;
    readonly id: string;
    readonly type: Types.OfferingType;
    readonly status: Types.OfferingStatus;
    readonly stage: Types.OfferingStage;
    readonly sizeInCurrency?: number | null;
    readonly sizeInShares?: number | null;
    readonly pricingCurrencyCode: string;
    readonly syndicate: {
      readonly __typename?: 'Syndicate';
      readonly managers: ReadonlyArray<{
        readonly __typename?: 'Manager';
        readonly cmgEntityKey: string;
        readonly role: Types.ManagerRole;
        readonly isParticipating: boolean;
        readonly economics?: number | null;
        readonly firmName: string;
        readonly firmNameAbbreviation?: string | null;
        readonly isJuniorRole: boolean;
      }>;
      readonly managerResponsibilities: {
        readonly __typename?: 'ManagerResponsibilities';
        readonly id: string;
        readonly stabilizationAgent?: string | null;
        readonly settlementAgent?: string | null;
        readonly logisticsAgent?: string | null;
        readonly leftLead?: string | null;
      };
    };
    readonly issuer: {
      readonly __typename?: 'Issuer';
      readonly name: string;
      readonly lei?: string | null;
      readonly cik?: string | null;
      readonly address?: string | null;
      readonly businessDescription?: string | null;
      readonly website?: string | null;
      readonly sector?: Types.Sector | null;
    };
    readonly security: {
      readonly __typename?: 'Security';
      readonly type: Types.SecurityType;
      readonly isDepositaryReceipt: boolean;
      readonly shareClass?: Types.SecurityShareClass | null;
    };
    readonly disclaimers: {
      readonly __typename?: 'Disclaimers';
      readonly id: string;
      readonly restrictions?: string | null;
      readonly footnotes?: string | null;
      readonly registrationLinks: ReadonlyArray<string>;
      readonly rule134Legend?: string | null;
    };
    readonly marketing: {
      readonly __typename?: 'Marketing';
      readonly id: string;
      readonly managementTeam?: string | null;
      readonly callDetails?: string | null;
    };
    readonly timing: {
      readonly __typename?: 'Timing';
      readonly confidentialFilingDate?: string | null;
      readonly publicFilingDate?: string | null;
      readonly filingOccurred?: Types.MarketTiming | null;
      readonly launchOccurred?: Types.MarketTiming | null;
      readonly launchDate?: string | null;
      readonly booksCloseAt?: string | null;
      readonly timeZone?: string | null;
      readonly pricingDate?: string | null;
      readonly firstTradeDate?: string | null;
      readonly tradeDate?: string | null;
      readonly settlementDate?: string | null;
      readonly postponedDate?: string | null;
      readonly withdrawnDate?: string | null;
      readonly terminatedDate?: string | null;
    };
    readonly terms: {
      readonly __typename?: 'Terms';
      readonly useOfProceeds: ReadonlyArray<Types.UseOfProceeds>;
      readonly lockUpPeriod?: number | null;
      readonly followOnPricing?: {
        readonly __typename?: 'FollowOnPricing';
        readonly id: string;
        readonly lastTradeBeforeFiling?: number | null;
        readonly lastTradeBeforeLaunch?: number | null;
        readonly lastTradeBeforeOffer?: number | null;
        readonly reOfferLow?: number | null;
        readonly reOfferHigh?: number | null;
      } | null;
      readonly underwritingFeesAndDiscounts?: {
        readonly __typename?: 'UnderwritingFeesAndDiscounts';
        readonly reallowance?: number | null;
        readonly sharesExcludedFromGrossSpread?: number | null;
        readonly managementFee?: number | null;
        readonly underwritingFee?: number | null;
        readonly sellingConcession?: number | null;
        readonly grossSpreadTotal?: number | null;
        readonly netPrice?: number | null;
        readonly grossSpreadBaseData?: {
          readonly __typename?: 'GrossSpreadBase';
          readonly grossSpreadBase?: number | null;
          readonly managementFeePercentage?: number | null;
          readonly underwritingFeePercentage?: number | null;
          readonly sellingConcessionPercentage?: number | null;
        } | null;
        readonly incentiveFeeData?: {
          readonly __typename?: 'IncentiveFee';
          readonly incentiveFee?: number | null;
          readonly managementFeePercentage?: number | null;
          readonly underwritingFeePercentage?: number | null;
          readonly sellingConcessionPercentage?: number | null;
        } | null;
      } | null;
      readonly overAllotmentExercisedData?: {
        readonly __typename?: 'OverAllotmentExercisedData';
        readonly primaryShares?: number | null;
        readonly secondaryShares?: number | null;
        readonly notional?: number | null;
        readonly totalShares?: number | null;
      } | null;
      readonly filings: ReadonlyArray<{
        readonly __typename?: 'Filing';
        readonly id: string;
        readonly filingType: Types.FilingType;
        readonly initialRegistrationValue?: number | null;
        readonly primarySharesBaseOffering?: number | null;
        readonly secondarySharesBaseOffering?: number | null;
        readonly primarySharesOverAllotmentAuthorized?: number | null;
        readonly secondarySharesOverAllotmentAuthorized?: number | null;
        readonly notionalOverAllotmentExercised?: number | null;
        readonly primarySharesOverAllotmentExercised?: number | null;
        readonly secondarySharesOverAllotmentExercised?: number | null;
        readonly notionalOverAllotmentAuthorized?: number | null;
        readonly totalSharesBaseOffering?: number | null;
        readonly totalSharesOverAllotmentAuthorized?: number | null;
        readonly totalSharesOverAllotmentExercised?: number | null;
        readonly ipoRangeLow?: number | null;
        readonly ipoRangeHigh?: number | null;
        readonly offerPrice?: number | null;
        readonly postOfferingShares?: number | null;
        readonly useOfProceeds: ReadonlyArray<Types.UseOfProceeds>;
        readonly lockUpPeriod?: number | null;
      }>;
    };
    readonly demandCurrencies: ReadonlyArray<{
      readonly __typename?: 'DemandCurrency';
      readonly currencyCode: string;
      readonly exchangeRate: number;
    }>;
    readonly deliveryInstruments: ReadonlyArray<{
      readonly __typename?: 'DeliveryInstrument';
      readonly id: string;
      readonly currencyCode: string;
      readonly countryCode: string;
      readonly isDepositaryReceipt: boolean;
      readonly stockSymbol?: string | null;
      readonly isin?: string | null;
      readonly cusip?: string | null;
      readonly figi?: string | null;
      readonly exchange?: string | null;
      readonly exchangeMic?: Types.ExchangeMic | null;
      readonly depositaryReceiptConversionRatio?: {
        readonly __typename?: 'DepositaryReceiptConversionRatio';
        readonly numerator: number;
        readonly denominator: number;
      } | null;
    }>;
    readonly tranches: ReadonlyArray<{
      readonly __typename?: 'Tranche';
      readonly id: string;
      readonly name: string;
      readonly region?: string | null;
      readonly defaultInstrumentId?: string | null;
      readonly deliveryInstrumentIds: ReadonlyArray<string>;
      readonly currencyIds: ReadonlyArray<string>;
    }>;
    readonly roadshowSchedules: ReadonlyArray<{
      readonly __typename?: 'RoadshowSchedule';
      readonly id: string;
      readonly date: string;
      readonly details: string;
    }>;
    readonly managerEconomics: {
      readonly __typename?: 'ManagerEconomics';
      readonly id: string;
      readonly incentiveFeeDistribution: ReadonlyArray<{
        readonly __typename?: 'PercentagePerCmgEntity';
        readonly cmgEntityKey: string;
        readonly percentageValue: number;
      }>;
      readonly underwritingFeeBaseDistribution: ReadonlyArray<{
        readonly __typename?: 'PercentagePerCmgEntity';
        readonly cmgEntityKey: string;
        readonly percentageValue: number;
      }>;
      readonly managementFeeDistribution: ReadonlyArray<{
        readonly __typename?: 'PercentagePerCmgEntity';
        readonly cmgEntityKey: string;
        readonly percentageValue: number;
      }>;
      readonly sellingConcessionDistribution: ReadonlyArray<{
        readonly __typename?: 'PercentagePerCmgEntity';
        readonly cmgEntityKey: string;
        readonly percentageValue: number;
      }>;
      readonly underwritingFeeTotalDistribution: ReadonlyArray<{
        readonly __typename?: 'PercentagePerCmgEntity';
        readonly cmgEntityKey: string;
        readonly percentageValue: number;
      }>;
      readonly underwritingBaseSharesDistribution: ReadonlyArray<{
        readonly __typename?: 'SharesPerCmgEntity';
        readonly cmgEntityKey: string;
        readonly sharesValue: number;
      }>;
      readonly overallotmentExercisedSharesDistribution: ReadonlyArray<{
        readonly __typename?: 'SharesPerCmgEntity';
        readonly cmgEntityKey: string;
        readonly sharesValue: number;
      }>;
      readonly underwritingTotalSharesDistribution: ReadonlyArray<{
        readonly __typename?: 'SharesPerCmgEntity';
        readonly cmgEntityKey: string;
        readonly sharesValue: number;
      }>;
    };
  };
};

export type OfferingSetup_ManagerEconomics_GrossSpreadBaseDataFragment = {
  readonly __typename?: 'GrossSpreadBase';
  readonly grossSpreadBase?: number | null;
  readonly managementFeePercentage?: number | null;
  readonly underwritingFeePercentage?: number | null;
  readonly sellingConcessionPercentage?: number | null;
};

export type OfferingSetup_ManagerEconomics_IncentiveFeeDataFragment = {
  readonly __typename?: 'IncentiveFee';
  readonly incentiveFee?: number | null;
  readonly managementFeePercentage?: number | null;
  readonly underwritingFeePercentage?: number | null;
  readonly sellingConcessionPercentage?: number | null;
};

export type OfferingSetup_ManagerEconomics_PercentagePerCmgEntityFragment = {
  readonly __typename?: 'PercentagePerCmgEntity';
  readonly id: string;
  readonly cmgEntityKey: string;
  readonly percentageValue: number;
};

export type OfferingSetup_ManagerEconomics_SharesPerCmgEntityFragment = {
  readonly __typename?: 'SharesPerCmgEntity';
  readonly id: string;
  readonly cmgEntityKey: string;
  readonly sharesValue: number;
};

export type OfferingSetup_ManagerEconomics_ManagerEconomicsFragment = {
  readonly __typename?: 'ManagerEconomics';
  readonly id: string;
  readonly incentiveFeeDistribution: ReadonlyArray<{
    readonly __typename?: 'PercentagePerCmgEntity';
    readonly id: string;
    readonly cmgEntityKey: string;
    readonly percentageValue: number;
  }>;
  readonly managementFeeDistribution: ReadonlyArray<{
    readonly __typename?: 'PercentagePerCmgEntity';
    readonly id: string;
    readonly cmgEntityKey: string;
    readonly percentageValue: number;
  }>;
  readonly sellingConcessionDistribution: ReadonlyArray<{
    readonly __typename?: 'PercentagePerCmgEntity';
    readonly id: string;
    readonly cmgEntityKey: string;
    readonly percentageValue: number;
  }>;
  readonly underwritingFeeTotalDistribution: ReadonlyArray<{
    readonly __typename?: 'PercentagePerCmgEntity';
    readonly id: string;
    readonly cmgEntityKey: string;
    readonly percentageValue: number;
  }>;
  readonly underwritingFeeBaseDistribution: ReadonlyArray<{
    readonly __typename?: 'PercentagePerCmgEntity';
    readonly id: string;
    readonly cmgEntityKey: string;
    readonly percentageValue: number;
  }>;
  readonly underwritingBaseSharesDistribution: ReadonlyArray<{
    readonly __typename?: 'SharesPerCmgEntity';
    readonly id: string;
    readonly cmgEntityKey: string;
    readonly sharesValue: number;
  }>;
  readonly overallotmentExercisedSharesDistribution: ReadonlyArray<{
    readonly __typename?: 'SharesPerCmgEntity';
    readonly id: string;
    readonly cmgEntityKey: string;
    readonly sharesValue: number;
  }>;
};

export type OfferingSetup_ManagerEconomics_ManagerEconomicsQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type OfferingSetup_ManagerEconomics_ManagerEconomicsQuery = {
  readonly __typename?: 'Query';
  readonly offering: {
    readonly __typename?: 'Offering';
    readonly id: string;
    readonly pricingCurrencyCode: string;
    readonly syndicate: {
      readonly __typename?: 'Syndicate';
      readonly id: string;
      readonly managerEconomics: {
        readonly __typename?: 'ManagerEconomics';
        readonly id: string;
        readonly incentiveFeeDistribution: ReadonlyArray<{
          readonly __typename?: 'PercentagePerCmgEntity';
          readonly id: string;
          readonly cmgEntityKey: string;
          readonly percentageValue: number;
        }>;
        readonly managementFeeDistribution: ReadonlyArray<{
          readonly __typename?: 'PercentagePerCmgEntity';
          readonly id: string;
          readonly cmgEntityKey: string;
          readonly percentageValue: number;
        }>;
        readonly sellingConcessionDistribution: ReadonlyArray<{
          readonly __typename?: 'PercentagePerCmgEntity';
          readonly id: string;
          readonly cmgEntityKey: string;
          readonly percentageValue: number;
        }>;
        readonly underwritingFeeTotalDistribution: ReadonlyArray<{
          readonly __typename?: 'PercentagePerCmgEntity';
          readonly id: string;
          readonly cmgEntityKey: string;
          readonly percentageValue: number;
        }>;
        readonly underwritingFeeBaseDistribution: ReadonlyArray<{
          readonly __typename?: 'PercentagePerCmgEntity';
          readonly id: string;
          readonly cmgEntityKey: string;
          readonly percentageValue: number;
        }>;
        readonly underwritingBaseSharesDistribution: ReadonlyArray<{
          readonly __typename?: 'SharesPerCmgEntity';
          readonly id: string;
          readonly cmgEntityKey: string;
          readonly sharesValue: number;
        }>;
        readonly overallotmentExercisedSharesDistribution: ReadonlyArray<{
          readonly __typename?: 'SharesPerCmgEntity';
          readonly id: string;
          readonly cmgEntityKey: string;
          readonly sharesValue: number;
        }>;
      };
    };
  };
};

export type OfferingSetup_ManagerEconomics_UnderwritingFeesAndDiscountsQueryVariables =
  Types.Exact<{
    offeringId: Types.Scalars['UUID'];
  }>;

export type OfferingSetup_ManagerEconomics_UnderwritingFeesAndDiscountsQuery = {
  readonly __typename?: 'Query';
  readonly getUnderwritingFeesAndDiscounts: {
    readonly __typename?: 'UnderwritingFeesAndDiscounts';
    readonly grossSpreadTotal?: number | null;
    readonly netPrice?: number | null;
    readonly grossSpreadBaseData?: {
      readonly __typename?: 'GrossSpreadBase';
      readonly grossSpreadBase?: number | null;
      readonly managementFeePercentage?: number | null;
      readonly underwritingFeePercentage?: number | null;
      readonly sellingConcessionPercentage?: number | null;
    } | null;
    readonly incentiveFeeData?: {
      readonly __typename?: 'IncentiveFee';
      readonly incentiveFee?: number | null;
      readonly managementFeePercentage?: number | null;
      readonly underwritingFeePercentage?: number | null;
      readonly sellingConcessionPercentage?: number | null;
    } | null;
  };
};

export type OfferingSetup_ManagerEconomics_CalculateFeesDistributionsMutationVariables =
  Types.Exact<{
    offeringId: Types.Scalars['UUID'];
    input: Types.CalculateFeesDistributionsInput;
  }>;

export type OfferingSetup_ManagerEconomics_CalculateFeesDistributionsMutation = {
  readonly __typename?: 'Mutation';
  readonly calculateFeesDistributions: {
    readonly __typename?: 'CalculatedPercentageDistributions';
    readonly managementFeeDistribution: ReadonlyArray<{
      readonly __typename?: 'PercentagePerCmgEntity';
      readonly id: string;
      readonly cmgEntityKey: string;
      readonly percentageValue: number;
    }>;
    readonly underwritingFeeTotalDistribution: ReadonlyArray<{
      readonly __typename?: 'PercentagePerCmgEntity';
      readonly id: string;
      readonly cmgEntityKey: string;
      readonly percentageValue: number;
    }>;
    readonly sellingConcessionDistribution: ReadonlyArray<{
      readonly __typename?: 'PercentagePerCmgEntity';
      readonly id: string;
      readonly cmgEntityKey: string;
      readonly percentageValue: number;
    }>;
  };
};

export type OfferingSetup_ManagerEconomics_CalculateSharesDistributionsMutationVariables =
  Types.Exact<{
    offeringId: Types.Scalars['UUID'];
    input: Types.CalculateSharesDistributionsInput;
  }>;

export type OfferingSetup_ManagerEconomics_CalculateSharesDistributionsMutation = {
  readonly __typename?: 'Mutation';
  readonly calculateSharesDistributions: {
    readonly __typename?: 'CalculateShareDistributions';
    readonly underwritingBaseShareDistribution: ReadonlyArray<{
      readonly __typename?: 'SharesPerCmgEntity';
      readonly id: string;
      readonly cmgEntityKey: string;
      readonly sharesValue: number;
    }>;
    readonly overallotmentExercisedShareDistribution: ReadonlyArray<{
      readonly __typename?: 'SharesPerCmgEntity';
      readonly id: string;
      readonly cmgEntityKey: string;
      readonly sharesValue: number;
    }>;
    readonly underwritingTotalSharesDistribution: ReadonlyArray<{
      readonly __typename?: 'SharesPerCmgEntity';
      readonly id: string;
      readonly cmgEntityKey: string;
      readonly sharesValue: number;
    }>;
  };
};

export type OfferingSetup_ManagerEconomics_UpdateManagerEconomicsMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  incentiveFeeDistribution:
    | ReadonlyArray<Types.PercentagePerCmgEntityInput>
    | Types.PercentagePerCmgEntityInput;
  underwritingFeeBaseDistribution:
    | ReadonlyArray<Types.PercentagePerCmgEntityInput>
    | Types.PercentagePerCmgEntityInput;
  sellingConcessionDistribution:
    | ReadonlyArray<Types.PercentagePerCmgEntityInput>
    | Types.PercentagePerCmgEntityInput;
  managementFeeDistribution:
    | ReadonlyArray<Types.PercentagePerCmgEntityInput>
    | Types.PercentagePerCmgEntityInput;
  underwritingFeeTotalDistribution:
    | ReadonlyArray<Types.PercentagePerCmgEntityInput>
    | Types.PercentagePerCmgEntityInput;
  overallotmentExercisedSharesDistribution:
    | ReadonlyArray<Types.SharesPerCmgEntityInput>
    | Types.SharesPerCmgEntityInput;
  underwritingBaseSharesDistribution:
    | ReadonlyArray<Types.SharesPerCmgEntityInput>
    | Types.SharesPerCmgEntityInput;
}>;

export type OfferingSetup_ManagerEconomics_UpdateManagerEconomicsMutation = {
  readonly __typename?: 'Mutation';
  readonly updateIncentiveFeeDistribution: ReadonlyArray<{
    readonly __typename?: 'PercentagePerCmgEntity';
    readonly id: string;
    readonly cmgEntityKey: string;
    readonly percentageValue: number;
  }>;
  readonly updateUnderwritingFeeBaseDistribution: ReadonlyArray<{
    readonly __typename?: 'PercentagePerCmgEntity';
    readonly id: string;
    readonly cmgEntityKey: string;
    readonly percentageValue: number;
  }>;
  readonly updateSellingConcessionDistribution: ReadonlyArray<{
    readonly __typename?: 'PercentagePerCmgEntity';
    readonly id: string;
    readonly cmgEntityKey: string;
    readonly percentageValue: number;
  }>;
  readonly updateManagementFeeDistribution: ReadonlyArray<{
    readonly __typename?: 'PercentagePerCmgEntity';
    readonly id: string;
    readonly cmgEntityKey: string;
    readonly percentageValue: number;
  }>;
  readonly updateUnderwritingFeeTotalDistribution: ReadonlyArray<{
    readonly __typename?: 'PercentagePerCmgEntity';
    readonly id: string;
    readonly cmgEntityKey: string;
    readonly percentageValue: number;
  }>;
  readonly updateOverallotmentExercisedSharesDistribution: ReadonlyArray<{
    readonly __typename?: 'SharesPerCmgEntity';
    readonly id: string;
    readonly cmgEntityKey: string;
    readonly sharesValue: number;
  }>;
  readonly updateUnderwritingBaseSharesDistribution: ReadonlyArray<{
    readonly __typename?: 'SharesPerCmgEntity';
    readonly id: string;
    readonly cmgEntityKey: string;
    readonly sharesValue: number;
  }>;
};

export type OfferingSetup_ManagerEconomics_SyndicateQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type OfferingSetup_ManagerEconomics_SyndicateQuery = {
  readonly __typename?: 'Query';
  readonly offering: {
    readonly __typename?: 'Offering';
    readonly id: string;
    readonly isAuthor: boolean;
    readonly syndicate: {
      readonly __typename?: 'Syndicate';
      readonly id: string;
      readonly grossSpread?: number | null;
      readonly managers: ReadonlyArray<{
        readonly __typename?: 'Manager';
        readonly id: string;
        readonly cmgEntityKey: string;
        readonly role: Types.ManagerRole;
        readonly isJuniorRole: boolean;
        readonly isParticipating: boolean;
        readonly economics?: number | null;
        readonly firmName: string;
        readonly recordStatus: Types.RecordStatus;
      }>;
      readonly managerResponsibilities: {
        readonly __typename?: 'ManagerResponsibilities';
        readonly id: string;
        readonly stabilizationAgent?: string | null;
        readonly settlementAgent?: string | null;
        readonly logisticsAgent?: string | null;
        readonly leftLead?: string | null;
      };
    };
  };
};

export type OfferingSetup_ManagerEconomics_ManagerFragment = {
  readonly __typename?: 'Manager';
  readonly id: string;
  readonly cmgEntityKey: string;
  readonly role: Types.ManagerRole;
  readonly isJuniorRole: boolean;
  readonly isParticipating: boolean;
  readonly economics?: number | null;
  readonly firmName: string;
  readonly recordStatus: Types.RecordStatus;
};

export type OfferingSetup_ManagerEconomics_ManagerResponsibilitiesFragment = {
  readonly __typename?: 'ManagerResponsibilities';
  readonly id: string;
  readonly stabilizationAgent?: string | null;
  readonly settlementAgent?: string | null;
  readonly logisticsAgent?: string | null;
  readonly leftLead?: string | null;
};

export type OfferingSetup_ManagerEconomics_FilingsQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type OfferingSetup_ManagerEconomics_FilingsQuery = {
  readonly __typename?: 'Query';
  readonly getFilings: ReadonlyArray<{
    readonly __typename?: 'Filing';
    readonly id: string;
    readonly filingType: Types.FilingType;
    readonly initialRegistrationValue?: number | null;
    readonly primarySharesBaseOffering?: number | null;
    readonly secondarySharesBaseOffering?: number | null;
    readonly notionalOverAllotmentAuthorized?: number | null;
    readonly primarySharesOverAllotmentAuthorized?: number | null;
    readonly notionalOverAllotmentExercised?: number | null;
    readonly primarySharesOverAllotmentExercised?: number | null;
    readonly totalSharesOverAllotmentAuthorized?: number | null;
    readonly totalSharesOverAllotmentExercised?: number | null;
    readonly totalSharesBaseOffering?: number | null;
    readonly ipoRangeLow?: number | null;
    readonly ipoRangeHigh?: number | null;
    readonly offerPrice?: number | null;
    readonly postOfferingShares?: number | null;
    readonly useOfProceeds: ReadonlyArray<Types.UseOfProceeds>;
    readonly lockUpPeriod?: number | null;
    readonly recordStatus: Types.RecordStatus;
  }>;
};

export type OfferingSetup_Prospectus_DocumentMetadataFragment = {
  readonly __typename?: 'ProspectusDocument';
  readonly id: string;
  readonly documentName: string;
  readonly includeOnPublish: boolean;
  readonly publicUrl?: string | null;
  readonly uploadedAt: string;
  readonly firstPublishedAt?: string | null;
};

export type OfferingSetup_Prospectus_PublishedOfferingVersionQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type OfferingSetup_Prospectus_PublishedOfferingVersionQuery = {
  readonly __typename?: 'Query';
  readonly publishedOffering: { readonly __typename?: 'OfferingProfile'; readonly version: string };
};

export type OfferingSetup_Prospectus_ProspectusDocumentsQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type OfferingSetup_Prospectus_ProspectusDocumentsQuery = {
  readonly __typename?: 'Query';
  readonly offering: {
    readonly __typename?: 'Offering';
    readonly id: string;
    readonly stage: Types.OfferingStage;
    readonly versions: ReadonlyArray<{
      readonly __typename?: 'OfferingVersion';
      readonly version: string;
      readonly timestamp: string;
    }>;
    readonly prospectusDocuments: ReadonlyArray<{
      readonly __typename?: 'ProspectusDocument';
      readonly id: string;
      readonly documentName: string;
      readonly includeOnPublish: boolean;
      readonly publicUrl?: string | null;
      readonly uploadedAt: string;
      readonly firstPublishedAt?: string | null;
    }>;
  };
};

export type OfferingSetup_Prospectus_UpdateDocumentMetadataMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  prospectusDocuments:
    | ReadonlyArray<Types.ProspectusDocumentUpdateMetadata>
    | Types.ProspectusDocumentUpdateMetadata;
}>;

export type OfferingSetup_Prospectus_UpdateDocumentMetadataMutation = {
  readonly __typename?: 'Mutation';
  readonly updateProspectusDocumentsMetadata: ReadonlyArray<{
    readonly __typename?: 'ProspectusDocument';
    readonly id: string;
    readonly documentName: string;
    readonly includeOnPublish: boolean;
    readonly publicUrl?: string | null;
    readonly uploadedAt: string;
    readonly firstPublishedAt?: string | null;
  }>;
};

export type OfferingSetup_PublishedHeaderQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type OfferingSetup_PublishedHeaderQuery = {
  readonly __typename?: 'Query';
  readonly publishedOffering: {
    readonly __typename?: 'OfferingProfile';
    readonly id: string;
    readonly status: Types.OfferingStatus;
  };
};

export type OfferingSetup_Summary_ServerErrorPartsFragment = {
  readonly __typename?: 'ServiceError';
  readonly code: Types.ServiceErrorCode;
  readonly message: string;
  readonly target?: string | null;
  readonly details?: ReadonlyArray<{
    readonly __typename?: 'ErrorDetail';
    readonly code?: string | null;
    readonly message?: string | null;
    readonly target?: string | null;
  }> | null;
};

export type OfferingSetup_Summary_DiscardOfferingMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type OfferingSetup_Summary_DiscardOfferingMutation = {
  readonly __typename?: 'Mutation';
  readonly discardOffering:
    | { readonly __typename?: 'Offering'; readonly id: string; readonly stage: Types.OfferingStage }
    | {
        readonly __typename?: 'ServiceError';
        readonly code: Types.ServiceErrorCode;
        readonly message: string;
        readonly target?: string | null;
        readonly details?: ReadonlyArray<{
          readonly __typename?: 'ErrorDetail';
          readonly code?: string | null;
          readonly message?: string | null;
          readonly target?: string | null;
        }> | null;
      };
};

export type OfferingSetup_Summary_ManagerLimitedFragment = {
  readonly __typename?: 'ManagerLimited';
  readonly cmgEntityKey: string;
  readonly firmName: string;
  readonly role: Types.ManagerRole;
};

export type OfferingSetup_Summary_AvailableShareDraftManagersQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type OfferingSetup_Summary_AvailableShareDraftManagersQuery = {
  readonly __typename?: 'Query';
  readonly availableShareDraftManagers: ReadonlyArray<{
    readonly __typename?: 'ManagerLimited';
    readonly cmgEntityKey: string;
    readonly firmName: string;
    readonly role: Types.ManagerRole;
  }>;
};

export type OfferingSetup_Summary_PublishedOfferingStatusQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type OfferingSetup_Summary_PublishedOfferingStatusQuery = {
  readonly __typename?: 'Query';
  readonly publishedOffering: {
    readonly __typename?: 'OfferingProfile';
    readonly status: Types.OfferingStatus;
  };
};

export type OfferingSetup_Summary_OfferingShareHistoryFragment = {
  readonly __typename?: 'SharedDraftHistory';
  readonly version: string;
  readonly sharedTime: string;
  readonly managerKeys: ReadonlyArray<string>;
};

export type OfferingSetup_Summary_OfferingShareHistoryQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type OfferingSetup_Summary_OfferingShareHistoryQuery = {
  readonly __typename?: 'Query';
  readonly shareHistory: ReadonlyArray<{
    readonly __typename?: 'SharedDraftHistory';
    readonly version: string;
    readonly sharedTime: string;
    readonly managerKeys: ReadonlyArray<string>;
  }>;
};

export type OfferingSetup_Summary_PublishOfferingMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type OfferingSetup_Summary_PublishOfferingMutation = {
  readonly __typename?: 'Mutation';
  readonly publishOffering:
    | {
        readonly __typename?: 'Offering';
        readonly id: string;
        readonly stage: Types.OfferingStage;
        readonly status: Types.OfferingStatus;
      }
    | {
        readonly __typename?: 'ServiceError';
        readonly code: Types.ServiceErrorCode;
        readonly message: string;
        readonly target?: string | null;
        readonly details?: ReadonlyArray<{
          readonly __typename?: 'ErrorDetail';
          readonly code?: string | null;
          readonly message?: string | null;
          readonly target?: string | null;
        }> | null;
      };
};

export type OfferingSetup_Summary_ShareDraftOfferingMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  managerKeys: ReadonlyArray<Types.Scalars['String']> | Types.Scalars['String'];
}>;

export type OfferingSetup_Summary_ShareDraftOfferingMutation = {
  readonly __typename?: 'Mutation';
  readonly shareDraft: {
    readonly __typename?: 'Offering';
    readonly id: string;
    readonly version: string;
  };
};

export type OfferingSetup_Summary_UpdateOfferingStatusMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  status: Types.OfferingStatus;
}>;

export type OfferingSetup_Summary_UpdateOfferingStatusMutation = {
  readonly __typename?: 'Mutation';
  readonly updateOfferingStatus:
    | {
        readonly __typename?: 'Offering';
        readonly id: string;
        readonly status: Types.OfferingStatus;
      }
    | {
        readonly __typename?: 'ServiceError';
        readonly code: Types.ServiceErrorCode;
        readonly message: string;
        readonly target?: string | null;
        readonly details?: ReadonlyArray<{
          readonly __typename?: 'ErrorDetail';
          readonly code?: string | null;
          readonly message?: string | null;
          readonly target?: string | null;
        }> | null;
      };
};

export type OfferingSetup_Summary_SyndicateManagerFragment = {
  readonly __typename?: 'Manager';
  readonly id: string;
  readonly cmgEntityKey: string;
  readonly role: Types.ManagerRole;
  readonly isJuniorRole: boolean;
  readonly isParticipating: boolean;
  readonly economics?: number | null;
  readonly firmName: string;
};

export type OfferingSetup_Summary_DisclaimersFragment = {
  readonly __typename?: 'Disclaimers';
  readonly id: string;
  readonly restrictions?: string | null;
  readonly footnotes?: string | null;
  readonly registrationLinks: ReadonlyArray<string>;
  readonly rule134Legend?: string | null;
};

export type OfferingSetup_Summary_OfferingSummaryQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type OfferingSetup_Summary_OfferingSummaryQuery = {
  readonly __typename?: 'Query';
  readonly offering: {
    readonly __typename?: 'Offering';
    readonly id: string;
    readonly version: string;
    readonly hasUnpublishedChanges: boolean;
    readonly type: Types.OfferingType;
    readonly status: Types.OfferingStatus;
    readonly stage: Types.OfferingStage;
    readonly isDiscarded: boolean;
    readonly pricingCurrencyCode: string;
    readonly pricingInstrumentId?: string | null;
    readonly pricingInstrumentStockSymbol?: string | null;
    readonly sizeInCurrency?: number | null;
    readonly instruments: ReadonlyArray<{
      readonly __typename?: 'DeliveryInstrument';
      readonly id: string;
      readonly countryCode: string;
    }>;
    readonly lenders: ReadonlyArray<{
      readonly __typename?: 'Lender';
      readonly id: string;
      readonly name: string;
    }>;
    readonly sponsors: ReadonlyArray<{
      readonly __typename?: 'Sponsor';
      readonly id: string;
      readonly name: string;
      readonly preOfferingOwnership?: number | null;
      readonly postOfferingOwnership?: number | null;
      readonly postOfferingOwnershipIncludingShoe?: number | null;
    }>;
    readonly advisors: ReadonlyArray<{
      readonly __typename?: 'Advisor';
      readonly id: string;
      readonly name: string;
      readonly role: Types.AdvisorRole;
    }>;
    readonly roadshowSchedules: ReadonlyArray<{
      readonly __typename?: 'RoadshowSchedule';
      readonly id: string;
      readonly date: string;
      readonly details: string;
    }>;
    readonly issuer: {
      readonly __typename?: 'Issuer';
      readonly name: string;
      readonly lei?: string | null;
      readonly cik?: string | null;
      readonly website?: string | null;
      readonly address?: string | null;
      readonly sector?: Types.Sector | null;
      readonly businessDescription?: string | null;
    };
    readonly security: {
      readonly __typename?: 'Security';
      readonly type: Types.SecurityType;
      readonly typeDisplayName?: string | null;
      readonly isDepositaryReceipt: boolean;
      readonly shareClass?: Types.SecurityShareClass | null;
    };
    readonly timing: {
      readonly __typename?: 'Timing';
      readonly confidentialFilingDate?: string | null;
      readonly publicFilingDate?: string | null;
      readonly filingOccurred?: Types.MarketTiming | null;
      readonly launchOccurred?: Types.MarketTiming | null;
      readonly launchDate?: string | null;
      readonly booksCloseAt?: string | null;
      readonly pricingDate?: string | null;
      readonly firstTradeDate?: string | null;
      readonly tradeDate?: string | null;
      readonly settlementDate?: string | null;
      readonly postponedDate?: string | null;
      readonly withdrawnDate?: string | null;
      readonly terminatedDate?: string | null;
      readonly timeZone?: string | null;
    };
    readonly terms: {
      readonly __typename?: 'Terms';
      readonly followOnPricing?: {
        readonly __typename?: 'FollowOnPricing';
        readonly lastTradeBeforeFiling?: number | null;
        readonly lastTradeBeforeLaunch?: number | null;
        readonly lastTradeBeforeOffer?: number | null;
        readonly reOfferLow?: number | null;
        readonly reOfferHigh?: number | null;
      } | null;
    };
    readonly filings: ReadonlyArray<{
      readonly __typename?: 'Filing';
      readonly id: string;
      readonly filingType: Types.FilingType;
      readonly initialRegistrationValue?: number | null;
      readonly primarySharesBaseOffering?: number | null;
      readonly secondarySharesBaseOffering?: number | null;
      readonly notionalOverAllotmentAuthorized?: number | null;
      readonly primarySharesOverAllotmentAuthorized?: number | null;
      readonly secondarySharesOverAllotmentAuthorized?: number | null;
      readonly notionalOverAllotmentExercised?: number | null;
      readonly primarySharesOverAllotmentExercised?: number | null;
      readonly secondarySharesOverAllotmentExercised?: number | null;
      readonly ipoRangeLow?: number | null;
      readonly ipoRangeHigh?: number | null;
      readonly offerPrice?: number | null;
      readonly postOfferingShares?: number | null;
      readonly useOfProceeds: ReadonlyArray<Types.UseOfProceeds>;
      readonly lockUpPeriod?: number | null;
    }>;
    readonly disclaimers: {
      readonly __typename?: 'Disclaimers';
      readonly id: string;
      readonly restrictions?: string | null;
      readonly footnotes?: string | null;
      readonly registrationLinks: ReadonlyArray<string>;
      readonly rule134Legend?: string | null;
    };
    readonly currencies: ReadonlyArray<{
      readonly __typename?: 'DemandCurrency';
      readonly currencyCode: string;
      readonly exchangeRate: number;
    }>;
    readonly deliveryInstruments: ReadonlyArray<{
      readonly __typename?: 'DeliveryInstrument';
      readonly id: string;
      readonly currencyCode: string;
      readonly countryCode: string;
      readonly isDepositaryReceipt: boolean;
      readonly stockSymbol?: string | null;
      readonly isin?: string | null;
      readonly cusip?: string | null;
      readonly figi?: string | null;
      readonly exchangeMic?: Types.ExchangeMic | null;
      readonly depositaryReceiptConversionRatio?: {
        readonly __typename?: 'DepositaryReceiptConversionRatio';
        readonly numerator: number;
        readonly denominator: number;
      } | null;
    }>;
    readonly tranches: ReadonlyArray<{
      readonly __typename?: 'Tranche';
      readonly id: string;
      readonly name: string;
      readonly region?: string | null;
      readonly defaultInstrumentId?: string | null;
      readonly currencyIds: ReadonlyArray<string>;
    }>;
    readonly syndicate: {
      readonly __typename?: 'Syndicate';
      readonly grossSpread?: number | null;
      readonly managers: ReadonlyArray<{
        readonly __typename?: 'Manager';
        readonly id: string;
        readonly cmgEntityKey: string;
        readonly role: Types.ManagerRole;
        readonly isJuniorRole: boolean;
        readonly isParticipating: boolean;
        readonly economics?: number | null;
        readonly firmName: string;
      }>;
      readonly managerResponsibilities: {
        readonly __typename?: 'ManagerResponsibilities';
        readonly stabilizationAgent?: string | null;
        readonly settlementAgent?: string | null;
        readonly logisticsAgent?: string | null;
        readonly leftLead?: string | null;
      };
    };
    readonly prospectusDocuments: ReadonlyArray<{
      readonly __typename?: 'ProspectusDocument';
      readonly id: string;
      readonly documentName: string;
      readonly includeOnPublish: boolean;
      readonly publicUrl?: string | null;
      readonly uploadedAt: string;
      readonly firstPublishedAt?: string | null;
    }>;
  };
};

export type OfferinSetup_UnderwritingDiscountsFees_FilingFragment = {
  readonly __typename?: 'Filing';
  readonly id: string;
  readonly filingType: Types.FilingType;
  readonly initialRegistrationValue?: number | null;
  readonly primarySharesBaseOffering?: number | null;
  readonly secondarySharesBaseOffering?: number | null;
  readonly notionalOverAllotmentAuthorized?: number | null;
  readonly primarySharesOverAllotmentAuthorized?: number | null;
  readonly secondarySharesOverAllotmentAuthorized?: number | null;
  readonly notionalOverAllotmentExercised?: number | null;
  readonly primarySharesOverAllotmentExercised?: number | null;
  readonly secondarySharesOverAllotmentExercised?: number | null;
  readonly ipoRangeLow?: number | null;
  readonly ipoRangeHigh?: number | null;
  readonly offerPrice?: number | null;
  readonly postOfferingShares?: number | null;
  readonly useOfProceeds: ReadonlyArray<Types.UseOfProceeds>;
  readonly lockUpPeriod?: number | null;
  readonly recordStatus: Types.RecordStatus;
};

export type OfferinSetup_UnderwritingDiscountsFees_UpdateUnderwritingFeesAndDiscountsMutationVariables =
  Types.Exact<{
    offeringId: Types.Scalars['UUID'];
    input: Types.UpdateUnderwritingFeesAndDiscountsInput;
  }>;

export type OfferinSetup_UnderwritingDiscountsFees_UpdateUnderwritingFeesAndDiscountsMutation = {
  readonly __typename?: 'Mutation';
  readonly updateUnderwritingFeesAndDiscounts: {
    readonly __typename?: 'Terms';
    readonly filings: ReadonlyArray<{
      readonly __typename?: 'Filing';
      readonly id: string;
      readonly filingType: Types.FilingType;
      readonly initialRegistrationValue?: number | null;
      readonly primarySharesBaseOffering?: number | null;
      readonly secondarySharesBaseOffering?: number | null;
      readonly notionalOverAllotmentAuthorized?: number | null;
      readonly primarySharesOverAllotmentAuthorized?: number | null;
      readonly secondarySharesOverAllotmentAuthorized?: number | null;
      readonly notionalOverAllotmentExercised?: number | null;
      readonly primarySharesOverAllotmentExercised?: number | null;
      readonly secondarySharesOverAllotmentExercised?: number | null;
      readonly ipoRangeLow?: number | null;
      readonly ipoRangeHigh?: number | null;
      readonly offerPrice?: number | null;
      readonly postOfferingShares?: number | null;
      readonly useOfProceeds: ReadonlyArray<Types.UseOfProceeds>;
      readonly lockUpPeriod?: number | null;
      readonly recordStatus: Types.RecordStatus;
    }>;
  };
};

export type OfferinSetup_UnderwritingDiscountsFees_UnderwritingFeesAndDiscountsQueryVariables =
  Types.Exact<{
    offeringId: Types.Scalars['UUID'];
  }>;

export type OfferinSetup_UnderwritingDiscountsFees_UnderwritingFeesAndDiscountsQuery = {
  readonly __typename?: 'Query';
  readonly getUnderwritingFeesAndDiscounts: {
    readonly __typename?: 'UnderwritingFeesAndDiscounts';
    readonly reallowance?: number | null;
    readonly sharesExcludedFromGrossSpread?: number | null;
    readonly managementFee?: number | null;
    readonly underwritingFee?: number | null;
    readonly sellingConcession?: number | null;
    readonly netPrice?: number | null;
    readonly grossSpreadTotal?: number | null;
    readonly grossSpreadBaseData?: {
      readonly __typename?: 'GrossSpreadBase';
      readonly grossSpreadBase?: number | null;
      readonly managementFeePercentage?: number | null;
      readonly underwritingFeePercentage?: number | null;
      readonly sellingConcessionPercentage?: number | null;
    } | null;
    readonly incentiveFeeData?: {
      readonly __typename?: 'IncentiveFee';
      readonly incentiveFee?: number | null;
      readonly managementFeePercentage?: number | null;
      readonly underwritingFeePercentage?: number | null;
      readonly sellingConcessionPercentage?: number | null;
    } | null;
  };
};

export type OfferinSetup_UnderwritingDiscountsFees_TermsQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type OfferinSetup_UnderwritingDiscountsFees_TermsQuery = {
  readonly __typename?: 'Query';
  readonly offering: {
    readonly __typename?: 'Offering';
    readonly id: string;
    readonly type: Types.OfferingType;
    readonly pricingCurrencyCode: string;
    readonly filings: ReadonlyArray<{
      readonly __typename?: 'Filing';
      readonly totalSharesBaseOffering?: number | null;
      readonly id: string;
      readonly filingType: Types.FilingType;
      readonly initialRegistrationValue?: number | null;
      readonly primarySharesBaseOffering?: number | null;
      readonly secondarySharesBaseOffering?: number | null;
      readonly notionalOverAllotmentAuthorized?: number | null;
      readonly primarySharesOverAllotmentAuthorized?: number | null;
      readonly secondarySharesOverAllotmentAuthorized?: number | null;
      readonly notionalOverAllotmentExercised?: number | null;
      readonly primarySharesOverAllotmentExercised?: number | null;
      readonly secondarySharesOverAllotmentExercised?: number | null;
      readonly ipoRangeLow?: number | null;
      readonly ipoRangeHigh?: number | null;
      readonly offerPrice?: number | null;
      readonly postOfferingShares?: number | null;
      readonly useOfProceeds: ReadonlyArray<Types.UseOfProceeds>;
      readonly lockUpPeriod?: number | null;
      readonly recordStatus: Types.RecordStatus;
    }>;
    readonly followOnPricing: {
      readonly __typename?: 'FollowOnPricing';
      readonly id: string;
      readonly lastTradeBeforeFiling?: number | null;
      readonly lastTradeBeforeLaunch?: number | null;
      readonly lastTradeBeforeOffer?: number | null;
      readonly reOfferLow?: number | null;
      readonly reOfferHigh?: number | null;
    };
  };
};

export type OfferinSetup_UnderwritingDiscountsFees_FollowOnPricingFragment = {
  readonly __typename?: 'FollowOnPricing';
  readonly id: string;
  readonly lastTradeBeforeFiling?: number | null;
  readonly lastTradeBeforeLaunch?: number | null;
  readonly lastTradeBeforeOffer?: number | null;
  readonly reOfferLow?: number | null;
  readonly reOfferHigh?: number | null;
};

export type OfferingSetup_UnderwritingTerms_FilingsQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type OfferingSetup_UnderwritingTerms_FilingsQuery = {
  readonly __typename?: 'Query';
  readonly offering: {
    readonly __typename?: 'Offering';
    readonly id: string;
    readonly pricingCurrencyCode: string;
    readonly filings: ReadonlyArray<{
      readonly __typename?: 'Filing';
      readonly id: string;
      readonly filingType: Types.FilingType;
      readonly offerPrice?: number | null;
      readonly totalSharesOverAllotmentAuthorized?: number | null;
      readonly totalSharesOverAllotmentExercised?: number | null;
    }>;
  };
};

export type OfferingSetup_UnderwritingTerms_UnderwritingFeesAndDiscountsQueryVariables =
  Types.Exact<{
    offeringId: Types.Scalars['UUID'];
  }>;

export type OfferingSetup_UnderwritingTerms_UnderwritingFeesAndDiscountsQuery = {
  readonly __typename?: 'Query';
  readonly underwritingTermsFeesAndDiscounts: {
    readonly __typename?: 'UnderwritingTermsFeesAndDiscounts';
    readonly netPrice?: number | null;
    readonly sharesExcludedFromGrossSpread?: number | null;
    readonly reallowance?: number | null;
    readonly sellingConcession?: number | null;
    readonly managementFee?: number | null;
    readonly underwritingFee?: number | null;
    readonly grossSpreadTotal?: number | null;
    readonly incentiveFeeData?: {
      readonly __typename?: 'UnderwritingTermsIncentiveFee';
      readonly incentiveFee?: number | null;
      readonly incentiveFeePercentage?: number | null;
      readonly managementFee?: number | null;
      readonly managementFeePercentage?: number | null;
      readonly underwritingFee?: number | null;
      readonly underwritingFeePercentage?: number | null;
      readonly sellingConcession?: number | null;
      readonly sellingConcessionPercentage?: number | null;
    } | null;
    readonly grossSpreadBaseData?: {
      readonly __typename?: 'UnderwritingTermsGrossSpreadBase';
      readonly managementFee?: number | null;
      readonly managementFeePercentage?: number | null;
      readonly underwritingFee?: number | null;
      readonly underwritingFeePercentage?: number | null;
      readonly sellingConcession?: number | null;
      readonly sellingConcessionPercentage?: number | null;
      readonly grossSpreadBase?: number | null;
      readonly grossSpreadBasePercentage?: number | null;
    } | null;
  };
};

export type OfferingSetup_UnderwritingTerms_UpdateUnderwritingFeesAndDiscountsMutationVariables =
  Types.Exact<{
    input: Types.UpdateUnderwritingTermsFeesAndDiscountsInput;
    offeringId: Types.Scalars['UUID'];
  }>;

export type OfferingSetup_UnderwritingTerms_UpdateUnderwritingFeesAndDiscountsMutation = {
  readonly __typename?: 'Mutation';
  readonly updateUnderwritingTermsFeesAndDiscounts: {
    readonly __typename?: 'UpdateUnderwritingTermsFeesAndDiscountsPayload';
    readonly underwritingTermsFeesAndDiscounts?: {
      readonly __typename?: 'UnderwritingTermsFeesAndDiscounts';
      readonly netPrice?: number | null;
      readonly sharesExcludedFromGrossSpread?: number | null;
      readonly reallowance?: number | null;
      readonly sellingConcession?: number | null;
      readonly managementFee?: number | null;
      readonly underwritingFee?: number | null;
      readonly grossSpreadTotal?: number | null;
      readonly incentiveFeeData?: {
        readonly __typename?: 'UnderwritingTermsIncentiveFee';
        readonly incentiveFee?: number | null;
        readonly incentiveFeePercentage?: number | null;
        readonly managementFee?: number | null;
        readonly managementFeePercentage?: number | null;
        readonly underwritingFee?: number | null;
        readonly underwritingFeePercentage?: number | null;
        readonly sellingConcession?: number | null;
        readonly sellingConcessionPercentage?: number | null;
      } | null;
      readonly grossSpreadBaseData?: {
        readonly __typename?: 'UnderwritingTermsGrossSpreadBase';
        readonly managementFee?: number | null;
        readonly managementFeePercentage?: number | null;
        readonly underwritingFee?: number | null;
        readonly underwritingFeePercentage?: number | null;
        readonly sellingConcession?: number | null;
        readonly sellingConcessionPercentage?: number | null;
        readonly grossSpreadBase?: number | null;
        readonly grossSpreadBasePercentage?: number | null;
      } | null;
    } | null;
  };
};

export type OfferingSetup_UnderwritingTerms_ManagerFragment = {
  readonly __typename?: 'Manager';
  readonly id: string;
  readonly cmgEntityKey: string;
  readonly role: Types.ManagerRole;
  readonly isJuniorRole: boolean;
  readonly isParticipating: boolean;
  readonly economics?: number | null;
  readonly firmName: string;
  readonly recordStatus: Types.RecordStatus;
};

export type OfferingSetup_UnderwritingTerms_ManagerResponsibilitiesFragment = {
  readonly __typename?: 'ManagerResponsibilities';
  readonly id: string;
  readonly stabilizationAgent?: string | null;
  readonly settlementAgent?: string | null;
  readonly logisticsAgent?: string | null;
  readonly leftLead?: string | null;
};

export type OfferingSetup_UnderwritingTerms_GrossSpreadBaseDataFragment = {
  readonly __typename?: 'GrossSpreadBase';
  readonly grossSpreadBase?: number | null;
  readonly managementFeePercentage?: number | null;
  readonly underwritingFeePercentage?: number | null;
  readonly sellingConcessionPercentage?: number | null;
};

export type OfferingSetup_UnderwritingTerms_IncentiveFeeDataFragment = {
  readonly __typename?: 'IncentiveFee';
  readonly incentiveFee?: number | null;
  readonly managementFeePercentage?: number | null;
  readonly underwritingFeePercentage?: number | null;
  readonly sellingConcessionPercentage?: number | null;
};

export type OfferingSetup_UnderwritingTerms_PercentagePerCmgEntityFragment = {
  readonly __typename?: 'PercentagePerCmgEntity';
  readonly id: string;
  readonly cmgEntityKey: string;
  readonly percentageValue: number;
};

export type OfferingSetup_UnderwritingTerms_SharesPerCmgEntityFragment = {
  readonly __typename?: 'SharesPerCmgEntity';
  readonly id: string;
  readonly cmgEntityKey: string;
  readonly sharesValue: number;
};

export type OfferingSetup_UnderwritingTerms_ManagerEconomicsFragment = {
  readonly __typename?: 'ManagerEconomics';
  readonly id: string;
  readonly incentiveFeeDistribution: ReadonlyArray<{
    readonly __typename?: 'PercentagePerCmgEntity';
    readonly id: string;
    readonly cmgEntityKey: string;
    readonly percentageValue: number;
  }>;
  readonly managementFeeDistribution: ReadonlyArray<{
    readonly __typename?: 'PercentagePerCmgEntity';
    readonly id: string;
    readonly cmgEntityKey: string;
    readonly percentageValue: number;
  }>;
  readonly sellingConcessionDistribution: ReadonlyArray<{
    readonly __typename?: 'PercentagePerCmgEntity';
    readonly id: string;
    readonly cmgEntityKey: string;
    readonly percentageValue: number;
  }>;
  readonly underwritingFeeTotalDistribution: ReadonlyArray<{
    readonly __typename?: 'PercentagePerCmgEntity';
    readonly id: string;
    readonly cmgEntityKey: string;
    readonly percentageValue: number;
  }>;
  readonly underwritingFeeBaseDistribution: ReadonlyArray<{
    readonly __typename?: 'PercentagePerCmgEntity';
    readonly id: string;
    readonly cmgEntityKey: string;
    readonly percentageValue: number;
  }>;
  readonly underwritingBaseSharesDistribution: ReadonlyArray<{
    readonly __typename?: 'SharesPerCmgEntity';
    readonly id: string;
    readonly cmgEntityKey: string;
    readonly sharesValue: number;
  }>;
  readonly overallotmentExercisedSharesDistribution: ReadonlyArray<{
    readonly __typename?: 'SharesPerCmgEntity';
    readonly id: string;
    readonly cmgEntityKey: string;
    readonly sharesValue: number;
  }>;
};

export type OfferingSetup_UnderwritingTerms_SyndicateQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type OfferingSetup_UnderwritingTerms_SyndicateQuery = {
  readonly __typename?: 'Query';
  readonly offering: {
    readonly __typename?: 'Offering';
    readonly id: string;
    readonly isAuthor: boolean;
    readonly syndicate: {
      readonly __typename?: 'Syndicate';
      readonly id: string;
      readonly grossSpread?: number | null;
      readonly managers: ReadonlyArray<{
        readonly __typename?: 'Manager';
        readonly id: string;
        readonly cmgEntityKey: string;
        readonly role: Types.ManagerRole;
        readonly isJuniorRole: boolean;
        readonly isParticipating: boolean;
        readonly economics?: number | null;
        readonly firmName: string;
        readonly recordStatus: Types.RecordStatus;
      }>;
      readonly managerResponsibilities: {
        readonly __typename?: 'ManagerResponsibilities';
        readonly id: string;
        readonly stabilizationAgent?: string | null;
        readonly settlementAgent?: string | null;
        readonly logisticsAgent?: string | null;
        readonly leftLead?: string | null;
      };
      readonly managerEconomics: {
        readonly __typename?: 'ManagerEconomics';
        readonly id: string;
        readonly incentiveFeeDistribution: ReadonlyArray<{
          readonly __typename?: 'PercentagePerCmgEntity';
          readonly id: string;
          readonly cmgEntityKey: string;
          readonly percentageValue: number;
        }>;
        readonly managementFeeDistribution: ReadonlyArray<{
          readonly __typename?: 'PercentagePerCmgEntity';
          readonly id: string;
          readonly cmgEntityKey: string;
          readonly percentageValue: number;
        }>;
        readonly sellingConcessionDistribution: ReadonlyArray<{
          readonly __typename?: 'PercentagePerCmgEntity';
          readonly id: string;
          readonly cmgEntityKey: string;
          readonly percentageValue: number;
        }>;
        readonly underwritingFeeTotalDistribution: ReadonlyArray<{
          readonly __typename?: 'PercentagePerCmgEntity';
          readonly id: string;
          readonly cmgEntityKey: string;
          readonly percentageValue: number;
        }>;
        readonly underwritingFeeBaseDistribution: ReadonlyArray<{
          readonly __typename?: 'PercentagePerCmgEntity';
          readonly id: string;
          readonly cmgEntityKey: string;
          readonly percentageValue: number;
        }>;
        readonly underwritingBaseSharesDistribution: ReadonlyArray<{
          readonly __typename?: 'SharesPerCmgEntity';
          readonly id: string;
          readonly cmgEntityKey: string;
          readonly sharesValue: number;
        }>;
        readonly overallotmentExercisedSharesDistribution: ReadonlyArray<{
          readonly __typename?: 'SharesPerCmgEntity';
          readonly id: string;
          readonly cmgEntityKey: string;
          readonly sharesValue: number;
        }>;
      };
    };
  };
};

export type OfferingSetup_UnderwritingTerms_CalculateFeesDistributionsMutationVariables =
  Types.Exact<{
    offeringId: Types.Scalars['UUID'];
    input: Types.CalculateFeesDistributionsInput;
  }>;

export type OfferingSetup_UnderwritingTerms_CalculateFeesDistributionsMutation = {
  readonly __typename?: 'Mutation';
  readonly calculateFeesDistributions: {
    readonly __typename?: 'CalculatedPercentageDistributions';
    readonly managementFeeDistribution: ReadonlyArray<{
      readonly __typename?: 'PercentagePerCmgEntity';
      readonly id: string;
      readonly cmgEntityKey: string;
      readonly percentageValue: number;
    }>;
    readonly underwritingFeeTotalDistribution: ReadonlyArray<{
      readonly __typename?: 'PercentagePerCmgEntity';
      readonly id: string;
      readonly cmgEntityKey: string;
      readonly percentageValue: number;
    }>;
    readonly sellingConcessionDistribution: ReadonlyArray<{
      readonly __typename?: 'PercentagePerCmgEntity';
      readonly id: string;
      readonly cmgEntityKey: string;
      readonly percentageValue: number;
    }>;
  };
};

export type OfferingSetup_UnderwritingTerms_CalculateSharesDistributionsMutationVariables =
  Types.Exact<{
    offeringId: Types.Scalars['UUID'];
    input: Types.CalculateSharesDistributionsInput;
  }>;

export type OfferingSetup_UnderwritingTerms_CalculateSharesDistributionsMutation = {
  readonly __typename?: 'Mutation';
  readonly calculateSharesDistributions: {
    readonly __typename?: 'CalculateShareDistributions';
    readonly underwritingBaseShareDistribution: ReadonlyArray<{
      readonly __typename?: 'SharesPerCmgEntity';
      readonly id: string;
      readonly cmgEntityKey: string;
      readonly sharesValue: number;
    }>;
    readonly overallotmentExercisedShareDistribution: ReadonlyArray<{
      readonly __typename?: 'SharesPerCmgEntity';
      readonly id: string;
      readonly cmgEntityKey: string;
      readonly sharesValue: number;
    }>;
    readonly underwritingTotalSharesDistribution: ReadonlyArray<{
      readonly __typename?: 'SharesPerCmgEntity';
      readonly id: string;
      readonly cmgEntityKey: string;
      readonly sharesValue: number;
    }>;
  };
};

export type OfferingSetup_UnderwritingTerms_UpdateManagerEconomicsMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  incentiveFeeDistribution:
    | ReadonlyArray<Types.PercentagePerCmgEntityInput>
    | Types.PercentagePerCmgEntityInput;
  underwritingFeeBaseDistribution:
    | ReadonlyArray<Types.PercentagePerCmgEntityInput>
    | Types.PercentagePerCmgEntityInput;
  sellingConcessionDistribution:
    | ReadonlyArray<Types.PercentagePerCmgEntityInput>
    | Types.PercentagePerCmgEntityInput;
  managementFeeDistribution:
    | ReadonlyArray<Types.PercentagePerCmgEntityInput>
    | Types.PercentagePerCmgEntityInput;
  underwritingFeeTotalDistribution:
    | ReadonlyArray<Types.PercentagePerCmgEntityInput>
    | Types.PercentagePerCmgEntityInput;
  overallotmentExercisedSharesDistribution:
    | ReadonlyArray<Types.SharesPerCmgEntityInput>
    | Types.SharesPerCmgEntityInput;
  underwritingBaseSharesDistribution:
    | ReadonlyArray<Types.SharesPerCmgEntityInput>
    | Types.SharesPerCmgEntityInput;
}>;

export type OfferingSetup_UnderwritingTerms_UpdateManagerEconomicsMutation = {
  readonly __typename?: 'Mutation';
  readonly updateIncentiveFeeDistribution: ReadonlyArray<{
    readonly __typename?: 'PercentagePerCmgEntity';
    readonly id: string;
    readonly cmgEntityKey: string;
    readonly percentageValue: number;
  }>;
  readonly updateUnderwritingFeeBaseDistribution: ReadonlyArray<{
    readonly __typename?: 'PercentagePerCmgEntity';
    readonly id: string;
    readonly cmgEntityKey: string;
    readonly percentageValue: number;
  }>;
  readonly updateSellingConcessionDistribution: ReadonlyArray<{
    readonly __typename?: 'PercentagePerCmgEntity';
    readonly id: string;
    readonly cmgEntityKey: string;
    readonly percentageValue: number;
  }>;
  readonly updateManagementFeeDistribution: ReadonlyArray<{
    readonly __typename?: 'PercentagePerCmgEntity';
    readonly id: string;
    readonly cmgEntityKey: string;
    readonly percentageValue: number;
  }>;
  readonly updateUnderwritingFeeTotalDistribution: ReadonlyArray<{
    readonly __typename?: 'PercentagePerCmgEntity';
    readonly id: string;
    readonly cmgEntityKey: string;
    readonly percentageValue: number;
  }>;
  readonly updateOverallotmentExercisedSharesDistribution: ReadonlyArray<{
    readonly __typename?: 'SharesPerCmgEntity';
    readonly id: string;
    readonly cmgEntityKey: string;
    readonly sharesValue: number;
  }>;
  readonly updateUnderwritingBaseSharesDistribution: ReadonlyArray<{
    readonly __typename?: 'SharesPerCmgEntity';
    readonly id: string;
    readonly cmgEntityKey: string;
    readonly sharesValue: number;
  }>;
};

export type OfferingSetup_UnderwritingTerms_Economics_FilingsQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type OfferingSetup_UnderwritingTerms_Economics_FilingsQuery = {
  readonly __typename?: 'Query';
  readonly getFilings: ReadonlyArray<{
    readonly __typename?: 'Filing';
    readonly id: string;
    readonly filingType: Types.FilingType;
    readonly initialRegistrationValue?: number | null;
    readonly primarySharesBaseOffering?: number | null;
    readonly secondarySharesBaseOffering?: number | null;
    readonly notionalOverAllotmentAuthorized?: number | null;
    readonly primarySharesOverAllotmentAuthorized?: number | null;
    readonly notionalOverAllotmentExercised?: number | null;
    readonly primarySharesOverAllotmentExercised?: number | null;
    readonly totalSharesOverAllotmentAuthorized?: number | null;
    readonly totalSharesOverAllotmentExercised?: number | null;
    readonly totalSharesBaseOffering?: number | null;
    readonly ipoRangeLow?: number | null;
    readonly ipoRangeHigh?: number | null;
    readonly offerPrice?: number | null;
    readonly postOfferingShares?: number | null;
    readonly useOfProceeds: ReadonlyArray<Types.UseOfProceeds>;
    readonly lockUpPeriod?: number | null;
    readonly recordStatus: Types.RecordStatus;
  }>;
};

export type OfferingSetup_Validation_OfferingErrorFragment = {
  readonly __typename?: 'OfferingError';
  readonly type: Types.OfferingErrorType;
  readonly category: Types.OfferingErrorCategory;
  readonly description: string;
};

export type OfferingSetup_Validation_OfferingValidationQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type OfferingSetup_Validation_OfferingValidationQuery = {
  readonly __typename?: 'Query';
  readonly offering: {
    readonly __typename?: 'Offering';
    readonly validation: {
      readonly __typename?: 'OfferingValidation';
      readonly errors: ReadonlyArray<{
        readonly __typename?: 'OfferingError';
        readonly type: Types.OfferingErrorType;
        readonly category: Types.OfferingErrorCategory;
        readonly description: string;
      }>;
    };
  };
};

export const OfferingSetup_BasicInfoModal_IssuerPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_BasicInfoModal_IssuerParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Issuer' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lei' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cik' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'businessDescription' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sector' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const OfferingSetup_BasicInfoModal_SecurityPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_BasicInfoModal_SecurityParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Security' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isDepositaryReceipt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shareClass' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const OfferingSetup_BasicInfoModal_ServerErrorPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_BasicInfoModal_ServerErrorParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ServiceError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'target' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'target' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const OfferingSetup_InProgressOffering_DisclaimersPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_InProgressOffering_DisclaimersParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Disclaimers' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'restrictions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'footnotes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'registrationLinks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rule134Legend' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const OfferingSetup_InProgressOffering_FilingPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_InProgressOffering_FilingParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Filing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'filingType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'initialRegistrationValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primarySharesBaseOffering' } },
          { kind: 'Field', name: { kind: 'Name', value: 'secondarySharesBaseOffering' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primarySharesOverAllotmentAuthorized' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'secondarySharesOverAllotmentAuthorized' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'notionalOverAllotmentExercised' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primarySharesOverAllotmentExercised' } },
          { kind: 'Field', name: { kind: 'Name', value: 'secondarySharesOverAllotmentExercised' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notionalOverAllotmentAuthorized' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalSharesBaseOffering' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalSharesOverAllotmentAuthorized' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalSharesOverAllotmentExercised' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipoRangeLow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipoRangeHigh' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offerPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postOfferingShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'useOfProceeds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lockUpPeriod' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const OfferingSetup_InProgressOffering_FollowOnPricingPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_InProgressOffering_FollowOnPricingParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FollowOnPricing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeFiling' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeLaunch' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeOffer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reOfferLow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reOfferHigh' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const OfferingSetup_InProgressOffering_ManagerEconomicsPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_InProgressOffering_ManagerEconomicsParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ManagerEconomics' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'incentiveFeeDistribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'percentageValue' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'underwritingFeeBaseDistribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'percentageValue' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'managementFeeDistribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'percentageValue' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sellingConcessionDistribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'percentageValue' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'underwritingFeeTotalDistribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'percentageValue' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'underwritingBaseSharesDistribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sharesValue' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'overallotmentExercisedSharesDistribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sharesValue' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'underwritingTotalSharesDistribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sharesValue' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const OfferingSetup_InProgressOffering_ManagerResponsibilitiePartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_InProgressOffering_ManagerResponsibilitieParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ManagerResponsibilities' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stabilizationAgent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'settlementAgent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logisticsAgent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'leftLead' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const OfferingSetup_InProgressOffering_MarketingPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_InProgressOffering_MarketingParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Marketing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'managementTeam' } },
          { kind: 'Field', name: { kind: 'Name', value: 'callDetails' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const OfferingSetup_InProgressOffering_UnderwritingFeesAndDiscountsPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'OfferingSetup_InProgressOffering_UnderwritingFeesAndDiscountsParts',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UnderwritingFeesAndDiscounts' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'grossSpreadBaseData' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'grossSpreadBase' } },
                { kind: 'Field', name: { kind: 'Name', value: 'managementFeePercentage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'underwritingFeePercentage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sellingConcessionPercentage' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'incentiveFeeData' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'incentiveFee' } },
                { kind: 'Field', name: { kind: 'Name', value: 'managementFeePercentage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'underwritingFeePercentage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sellingConcessionPercentage' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'reallowance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharesExcludedFromGrossSpread' } },
          { kind: 'Field', name: { kind: 'Name', value: 'managementFee' } },
          { kind: 'Field', name: { kind: 'Name', value: 'underwritingFee' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sellingConcession' } },
          { kind: 'Field', name: { kind: 'Name', value: 'grossSpreadTotal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'netPrice' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const OfferingSetup_ManagerEconomics_GrossSpreadBaseDataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_ManagerEconomics_GrossSpreadBaseData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GrossSpreadBase' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'grossSpreadBase' } },
          { kind: 'Field', name: { kind: 'Name', value: 'managementFeePercentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'underwritingFeePercentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sellingConcessionPercentage' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const OfferingSetup_ManagerEconomics_IncentiveFeeDataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_ManagerEconomics_IncentiveFeeData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'IncentiveFee' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'incentiveFee' } },
          { kind: 'Field', name: { kind: 'Name', value: 'managementFeePercentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'underwritingFeePercentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sellingConcessionPercentage' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const OfferingSetup_ManagerEconomics_PercentagePerCmgEntityFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_ManagerEconomics_PercentagePerCmgEntity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PercentagePerCmgEntity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'percentageValue' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const OfferingSetup_ManagerEconomics_SharesPerCmgEntityFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_ManagerEconomics_SharesPerCmgEntity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SharesPerCmgEntity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharesValue' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const OfferingSetup_ManagerEconomics_ManagerEconomicsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_ManagerEconomics_ManagerEconomics' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ManagerEconomics' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'incentiveFeeDistribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'OfferingSetup_ManagerEconomics_PercentagePerCmgEntity',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'managementFeeDistribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'OfferingSetup_ManagerEconomics_PercentagePerCmgEntity',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sellingConcessionDistribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'OfferingSetup_ManagerEconomics_PercentagePerCmgEntity',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'underwritingFeeTotalDistribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'OfferingSetup_ManagerEconomics_PercentagePerCmgEntity',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'underwritingFeeBaseDistribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'OfferingSetup_ManagerEconomics_PercentagePerCmgEntity',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'underwritingBaseSharesDistribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'OfferingSetup_ManagerEconomics_SharesPerCmgEntity',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'overallotmentExercisedSharesDistribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'OfferingSetup_ManagerEconomics_SharesPerCmgEntity',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_ManagerEconomics_PercentagePerCmgEntity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PercentagePerCmgEntity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'percentageValue' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_ManagerEconomics_SharesPerCmgEntity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SharesPerCmgEntity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharesValue' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const OfferingSetup_ManagerEconomics_ManagerFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_ManagerEconomics_Manager' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Manager' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isJuniorRole' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isParticipating' } },
          { kind: 'Field', name: { kind: 'Name', value: 'economics' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recordStatus' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const OfferingSetup_ManagerEconomics_ManagerResponsibilitiesFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_ManagerEconomics_ManagerResponsibilities' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ManagerResponsibilities' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stabilizationAgent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'settlementAgent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logisticsAgent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'leftLead' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const OfferingSetup_Prospectus_DocumentMetadataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_Prospectus_DocumentMetadata' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProspectusDocument' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'documentName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'includeOnPublish' } },
          { kind: 'Field', name: { kind: 'Name', value: 'publicUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uploadedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstPublishedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const OfferingSetup_Summary_ServerErrorPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_Summary_ServerErrorParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ServiceError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'target' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'target' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const OfferingSetup_Summary_ManagerLimitedFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_Summary_ManagerLimited' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ManagerLimited' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const OfferingSetup_Summary_OfferingShareHistoryFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_Summary_OfferingShareHistory' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SharedDraftHistory' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharedTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'managerKeys' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const OfferingSetup_Summary_SyndicateManagerFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_Summary_SyndicateManager' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Manager' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isJuniorRole' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isParticipating' } },
          { kind: 'Field', name: { kind: 'Name', value: 'economics' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const OfferingSetup_Summary_DisclaimersFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_Summary_Disclaimers' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Disclaimers' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'restrictions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'footnotes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'registrationLinks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rule134Legend' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const OfferinSetup_UnderwritingDiscountsFees_FilingFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferinSetup_UnderwritingDiscountsFees_Filing' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Filing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'filingType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'initialRegistrationValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primarySharesBaseOffering' } },
          { kind: 'Field', name: { kind: 'Name', value: 'secondarySharesBaseOffering' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notionalOverAllotmentAuthorized' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primarySharesOverAllotmentAuthorized' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'secondarySharesOverAllotmentAuthorized' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'notionalOverAllotmentExercised' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primarySharesOverAllotmentExercised' } },
          { kind: 'Field', name: { kind: 'Name', value: 'secondarySharesOverAllotmentExercised' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipoRangeLow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipoRangeHigh' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offerPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postOfferingShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'useOfProceeds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lockUpPeriod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recordStatus' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const OfferinSetup_UnderwritingDiscountsFees_FollowOnPricingFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferinSetup_UnderwritingDiscountsFees_FollowOnPricing' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FollowOnPricing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeFiling' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeLaunch' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeOffer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reOfferLow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reOfferHigh' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const OfferingSetup_UnderwritingTerms_ManagerFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_UnderwritingTerms_Manager' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Manager' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isJuniorRole' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isParticipating' } },
          { kind: 'Field', name: { kind: 'Name', value: 'economics' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recordStatus' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const OfferingSetup_UnderwritingTerms_ManagerResponsibilitiesFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_UnderwritingTerms_ManagerResponsibilities' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ManagerResponsibilities' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stabilizationAgent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'settlementAgent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logisticsAgent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'leftLead' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const OfferingSetup_UnderwritingTerms_GrossSpreadBaseDataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_UnderwritingTerms_GrossSpreadBaseData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GrossSpreadBase' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'grossSpreadBase' } },
          { kind: 'Field', name: { kind: 'Name', value: 'managementFeePercentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'underwritingFeePercentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sellingConcessionPercentage' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const OfferingSetup_UnderwritingTerms_IncentiveFeeDataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_UnderwritingTerms_IncentiveFeeData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'IncentiveFee' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'incentiveFee' } },
          { kind: 'Field', name: { kind: 'Name', value: 'managementFeePercentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'underwritingFeePercentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sellingConcessionPercentage' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const OfferingSetup_UnderwritingTerms_PercentagePerCmgEntityFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_UnderwritingTerms_PercentagePerCmgEntity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PercentagePerCmgEntity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'percentageValue' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const OfferingSetup_UnderwritingTerms_SharesPerCmgEntityFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_UnderwritingTerms_SharesPerCmgEntity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SharesPerCmgEntity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharesValue' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const OfferingSetup_UnderwritingTerms_ManagerEconomicsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_UnderwritingTerms_ManagerEconomics' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ManagerEconomics' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'incentiveFeeDistribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'OfferingSetup_UnderwritingTerms_PercentagePerCmgEntity',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'managementFeeDistribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'OfferingSetup_UnderwritingTerms_PercentagePerCmgEntity',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sellingConcessionDistribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'OfferingSetup_UnderwritingTerms_PercentagePerCmgEntity',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'underwritingFeeTotalDistribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'OfferingSetup_UnderwritingTerms_PercentagePerCmgEntity',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'underwritingFeeBaseDistribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'OfferingSetup_UnderwritingTerms_PercentagePerCmgEntity',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'underwritingBaseSharesDistribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'OfferingSetup_UnderwritingTerms_SharesPerCmgEntity',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'overallotmentExercisedSharesDistribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'OfferingSetup_UnderwritingTerms_SharesPerCmgEntity',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_UnderwritingTerms_PercentagePerCmgEntity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PercentagePerCmgEntity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'percentageValue' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_UnderwritingTerms_SharesPerCmgEntity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SharesPerCmgEntity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharesValue' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const OfferingSetup_Validation_OfferingErrorFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_Validation_OfferingError' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'OfferingError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const OfferingSetup_BasicInfoRoute_BasicInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'OfferingSetup_BasicInfoRoute_BasicInfo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'offering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pricingCurrencyCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pricingInstrumentId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'instruments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'issuer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'OfferingSetup_BasicInfoModal_IssuerParts' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'security' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'OfferingSetup_BasicInfoModal_SecurityParts' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_BasicInfoModal_IssuerParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Issuer' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lei' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cik' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'businessDescription' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sector' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_BasicInfoModal_SecurityParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Security' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isDepositaryReceipt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shareClass' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useOfferingSetup_BasicInfoRoute_BasicInfoQuery__
 *
 * To run a query within a React component, call `useOfferingSetup_BasicInfoRoute_BasicInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfferingSetup_BasicInfoRoute_BasicInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferingSetup_BasicInfoRoute_BasicInfoQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useOfferingSetup_BasicInfoRoute_BasicInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    OfferingSetup_BasicInfoRoute_BasicInfoQuery,
    OfferingSetup_BasicInfoRoute_BasicInfoQueryVariables
  > &
    (
      | { variables: OfferingSetup_BasicInfoRoute_BasicInfoQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OfferingSetup_BasicInfoRoute_BasicInfoQuery,
    OfferingSetup_BasicInfoRoute_BasicInfoQueryVariables
  >(OfferingSetup_BasicInfoRoute_BasicInfoDocument, options);
}
export function useOfferingSetup_BasicInfoRoute_BasicInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OfferingSetup_BasicInfoRoute_BasicInfoQuery,
    OfferingSetup_BasicInfoRoute_BasicInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OfferingSetup_BasicInfoRoute_BasicInfoQuery,
    OfferingSetup_BasicInfoRoute_BasicInfoQueryVariables
  >(OfferingSetup_BasicInfoRoute_BasicInfoDocument, options);
}
export function useOfferingSetup_BasicInfoRoute_BasicInfoSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        OfferingSetup_BasicInfoRoute_BasicInfoQuery,
        OfferingSetup_BasicInfoRoute_BasicInfoQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    OfferingSetup_BasicInfoRoute_BasicInfoQuery,
    OfferingSetup_BasicInfoRoute_BasicInfoQueryVariables
  >(OfferingSetup_BasicInfoRoute_BasicInfoDocument, options);
}
export type OfferingSetup_BasicInfoRoute_BasicInfoQueryHookResult = ReturnType<
  typeof useOfferingSetup_BasicInfoRoute_BasicInfoQuery
>;
export type OfferingSetup_BasicInfoRoute_BasicInfoLazyQueryHookResult = ReturnType<
  typeof useOfferingSetup_BasicInfoRoute_BasicInfoLazyQuery
>;
export type OfferingSetup_BasicInfoRoute_BasicInfoSuspenseQueryHookResult = ReturnType<
  typeof useOfferingSetup_BasicInfoRoute_BasicInfoSuspenseQuery
>;
export type OfferingSetup_BasicInfoRoute_BasicInfoQueryResult = Apollo.QueryResult<
  OfferingSetup_BasicInfoRoute_BasicInfoQuery,
  OfferingSetup_BasicInfoRoute_BasicInfoQueryVariables
>;
export const OfferingSetup_BasicInfoRoute_PublishedOfferingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'OfferingSetup_BasicInfoRoute_PublishedOffering' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'publishedOffering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useOfferingSetup_BasicInfoRoute_PublishedOfferingQuery__
 *
 * To run a query within a React component, call `useOfferingSetup_BasicInfoRoute_PublishedOfferingQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfferingSetup_BasicInfoRoute_PublishedOfferingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferingSetup_BasicInfoRoute_PublishedOfferingQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useOfferingSetup_BasicInfoRoute_PublishedOfferingQuery(
  baseOptions: Apollo.QueryHookOptions<
    OfferingSetup_BasicInfoRoute_PublishedOfferingQuery,
    OfferingSetup_BasicInfoRoute_PublishedOfferingQueryVariables
  > &
    (
      | { variables: OfferingSetup_BasicInfoRoute_PublishedOfferingQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OfferingSetup_BasicInfoRoute_PublishedOfferingQuery,
    OfferingSetup_BasicInfoRoute_PublishedOfferingQueryVariables
  >(OfferingSetup_BasicInfoRoute_PublishedOfferingDocument, options);
}
export function useOfferingSetup_BasicInfoRoute_PublishedOfferingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OfferingSetup_BasicInfoRoute_PublishedOfferingQuery,
    OfferingSetup_BasicInfoRoute_PublishedOfferingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OfferingSetup_BasicInfoRoute_PublishedOfferingQuery,
    OfferingSetup_BasicInfoRoute_PublishedOfferingQueryVariables
  >(OfferingSetup_BasicInfoRoute_PublishedOfferingDocument, options);
}
export function useOfferingSetup_BasicInfoRoute_PublishedOfferingSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        OfferingSetup_BasicInfoRoute_PublishedOfferingQuery,
        OfferingSetup_BasicInfoRoute_PublishedOfferingQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    OfferingSetup_BasicInfoRoute_PublishedOfferingQuery,
    OfferingSetup_BasicInfoRoute_PublishedOfferingQueryVariables
  >(OfferingSetup_BasicInfoRoute_PublishedOfferingDocument, options);
}
export type OfferingSetup_BasicInfoRoute_PublishedOfferingQueryHookResult = ReturnType<
  typeof useOfferingSetup_BasicInfoRoute_PublishedOfferingQuery
>;
export type OfferingSetup_BasicInfoRoute_PublishedOfferingLazyQueryHookResult = ReturnType<
  typeof useOfferingSetup_BasicInfoRoute_PublishedOfferingLazyQuery
>;
export type OfferingSetup_BasicInfoRoute_PublishedOfferingSuspenseQueryHookResult = ReturnType<
  typeof useOfferingSetup_BasicInfoRoute_PublishedOfferingSuspenseQuery
>;
export type OfferingSetup_BasicInfoRoute_PublishedOfferingQueryResult = Apollo.QueryResult<
  OfferingSetup_BasicInfoRoute_PublishedOfferingQuery,
  OfferingSetup_BasicInfoRoute_PublishedOfferingQueryVariables
>;
export const OfferingSetup_BasicInfoRoute_UpdateBasicInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'OfferingSetup_BasicInfoRoute_UpdateBasicInfo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateBasicInfoInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateBasicInfo' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Offering' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'pricingCurrencyCode' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'issuer' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'OfferingSetup_BasicInfoModal_IssuerParts',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'security' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'OfferingSetup_BasicInfoModal_SecurityParts',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OfferingSetup_BasicInfoModal_ServerErrorParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_BasicInfoModal_IssuerParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Issuer' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lei' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cik' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'businessDescription' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sector' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_BasicInfoModal_SecurityParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Security' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isDepositaryReceipt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shareClass' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_BasicInfoModal_ServerErrorParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ServiceError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'target' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'target' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type OfferingSetup_BasicInfoRoute_UpdateBasicInfoMutationFn = Apollo.MutationFunction<
  OfferingSetup_BasicInfoRoute_UpdateBasicInfoMutation,
  OfferingSetup_BasicInfoRoute_UpdateBasicInfoMutationVariables
>;

/**
 * __useOfferingSetup_BasicInfoRoute_UpdateBasicInfoMutation__
 *
 * To run a mutation, you first call `useOfferingSetup_BasicInfoRoute_UpdateBasicInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOfferingSetup_BasicInfoRoute_UpdateBasicInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [offeringSetupBasicInfoRouteUpdateBasicInfoMutation, { data, loading, error }] = useOfferingSetup_BasicInfoRoute_UpdateBasicInfoMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOfferingSetup_BasicInfoRoute_UpdateBasicInfoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OfferingSetup_BasicInfoRoute_UpdateBasicInfoMutation,
    OfferingSetup_BasicInfoRoute_UpdateBasicInfoMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OfferingSetup_BasicInfoRoute_UpdateBasicInfoMutation,
    OfferingSetup_BasicInfoRoute_UpdateBasicInfoMutationVariables
  >(OfferingSetup_BasicInfoRoute_UpdateBasicInfoDocument, options);
}
export type OfferingSetup_BasicInfoRoute_UpdateBasicInfoMutationHookResult = ReturnType<
  typeof useOfferingSetup_BasicInfoRoute_UpdateBasicInfoMutation
>;
export type OfferingSetup_BasicInfoRoute_UpdateBasicInfoMutationResult =
  Apollo.MutationResult<OfferingSetup_BasicInfoRoute_UpdateBasicInfoMutation>;
export type OfferingSetup_BasicInfoRoute_UpdateBasicInfoMutationOptions =
  Apollo.BaseMutationOptions<
    OfferingSetup_BasicInfoRoute_UpdateBasicInfoMutation,
    OfferingSetup_BasicInfoRoute_UpdateBasicInfoMutationVariables
  >;
export const OfferingSetup_BasicInfoRoute_CreateOfferingDraftDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'OfferingSetup_BasicInfoRoute_CreateOfferingDraft' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateOfferingInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createOfferingDraft' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Offering' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OfferingSetup_BasicInfoModal_ServerErrorParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_BasicInfoModal_ServerErrorParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ServiceError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'target' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'target' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type OfferingSetup_BasicInfoRoute_CreateOfferingDraftMutationFn = Apollo.MutationFunction<
  OfferingSetup_BasicInfoRoute_CreateOfferingDraftMutation,
  OfferingSetup_BasicInfoRoute_CreateOfferingDraftMutationVariables
>;

/**
 * __useOfferingSetup_BasicInfoRoute_CreateOfferingDraftMutation__
 *
 * To run a mutation, you first call `useOfferingSetup_BasicInfoRoute_CreateOfferingDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOfferingSetup_BasicInfoRoute_CreateOfferingDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [offeringSetupBasicInfoRouteCreateOfferingDraftMutation, { data, loading, error }] = useOfferingSetup_BasicInfoRoute_CreateOfferingDraftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOfferingSetup_BasicInfoRoute_CreateOfferingDraftMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OfferingSetup_BasicInfoRoute_CreateOfferingDraftMutation,
    OfferingSetup_BasicInfoRoute_CreateOfferingDraftMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OfferingSetup_BasicInfoRoute_CreateOfferingDraftMutation,
    OfferingSetup_BasicInfoRoute_CreateOfferingDraftMutationVariables
  >(OfferingSetup_BasicInfoRoute_CreateOfferingDraftDocument, options);
}
export type OfferingSetup_BasicInfoRoute_CreateOfferingDraftMutationHookResult = ReturnType<
  typeof useOfferingSetup_BasicInfoRoute_CreateOfferingDraftMutation
>;
export type OfferingSetup_BasicInfoRoute_CreateOfferingDraftMutationResult =
  Apollo.MutationResult<OfferingSetup_BasicInfoRoute_CreateOfferingDraftMutation>;
export type OfferingSetup_BasicInfoRoute_CreateOfferingDraftMutationOptions =
  Apollo.BaseMutationOptions<
    OfferingSetup_BasicInfoRoute_CreateOfferingDraftMutation,
    OfferingSetup_BasicInfoRoute_CreateOfferingDraftMutationVariables
  >;
export const OfferingSetup_Components_SearchUnderwritersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'OfferingSetup_Components_SearchUnderwriters' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'perPage' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'underwriters' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'page' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'perPage' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'perPage' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mpid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'crd' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isOnPlatform' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pagination' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'hasNext' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useOfferingSetup_Components_SearchUnderwritersQuery__
 *
 * To run a query within a React component, call `useOfferingSetup_Components_SearchUnderwritersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfferingSetup_Components_SearchUnderwritersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferingSetup_Components_SearchUnderwritersQuery({
 *   variables: {
 *      name: // value for 'name'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *   },
 * });
 */
export function useOfferingSetup_Components_SearchUnderwritersQuery(
  baseOptions: Apollo.QueryHookOptions<
    OfferingSetup_Components_SearchUnderwritersQuery,
    OfferingSetup_Components_SearchUnderwritersQueryVariables
  > &
    (
      | { variables: OfferingSetup_Components_SearchUnderwritersQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OfferingSetup_Components_SearchUnderwritersQuery,
    OfferingSetup_Components_SearchUnderwritersQueryVariables
  >(OfferingSetup_Components_SearchUnderwritersDocument, options);
}
export function useOfferingSetup_Components_SearchUnderwritersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OfferingSetup_Components_SearchUnderwritersQuery,
    OfferingSetup_Components_SearchUnderwritersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OfferingSetup_Components_SearchUnderwritersQuery,
    OfferingSetup_Components_SearchUnderwritersQueryVariables
  >(OfferingSetup_Components_SearchUnderwritersDocument, options);
}
export function useOfferingSetup_Components_SearchUnderwritersSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        OfferingSetup_Components_SearchUnderwritersQuery,
        OfferingSetup_Components_SearchUnderwritersQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    OfferingSetup_Components_SearchUnderwritersQuery,
    OfferingSetup_Components_SearchUnderwritersQueryVariables
  >(OfferingSetup_Components_SearchUnderwritersDocument, options);
}
export type OfferingSetup_Components_SearchUnderwritersQueryHookResult = ReturnType<
  typeof useOfferingSetup_Components_SearchUnderwritersQuery
>;
export type OfferingSetup_Components_SearchUnderwritersLazyQueryHookResult = ReturnType<
  typeof useOfferingSetup_Components_SearchUnderwritersLazyQuery
>;
export type OfferingSetup_Components_SearchUnderwritersSuspenseQueryHookResult = ReturnType<
  typeof useOfferingSetup_Components_SearchUnderwritersSuspenseQuery
>;
export type OfferingSetup_Components_SearchUnderwritersQueryResult = Apollo.QueryResult<
  OfferingSetup_Components_SearchUnderwritersQuery,
  OfferingSetup_Components_SearchUnderwritersQueryVariables
>;
export const OfferingSetup_HeaderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'OfferingSetup_Header' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'offering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isDiscarded' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'issuer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'hasUnpublishedChanges' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useOfferingSetup_HeaderQuery__
 *
 * To run a query within a React component, call `useOfferingSetup_HeaderQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfferingSetup_HeaderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferingSetup_HeaderQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useOfferingSetup_HeaderQuery(
  baseOptions: Apollo.QueryHookOptions<
    OfferingSetup_HeaderQuery,
    OfferingSetup_HeaderQueryVariables
  > &
    ({ variables: OfferingSetup_HeaderQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OfferingSetup_HeaderQuery, OfferingSetup_HeaderQueryVariables>(
    OfferingSetup_HeaderDocument,
    options
  );
}
export function useOfferingSetup_HeaderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OfferingSetup_HeaderQuery,
    OfferingSetup_HeaderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OfferingSetup_HeaderQuery, OfferingSetup_HeaderQueryVariables>(
    OfferingSetup_HeaderDocument,
    options
  );
}
export function useOfferingSetup_HeaderSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<OfferingSetup_HeaderQuery, OfferingSetup_HeaderQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<OfferingSetup_HeaderQuery, OfferingSetup_HeaderQueryVariables>(
    OfferingSetup_HeaderDocument,
    options
  );
}
export type OfferingSetup_HeaderQueryHookResult = ReturnType<typeof useOfferingSetup_HeaderQuery>;
export type OfferingSetup_HeaderLazyQueryHookResult = ReturnType<
  typeof useOfferingSetup_HeaderLazyQuery
>;
export type OfferingSetup_HeaderSuspenseQueryHookResult = ReturnType<
  typeof useOfferingSetup_HeaderSuspenseQuery
>;
export type OfferingSetup_HeaderQueryResult = Apollo.QueryResult<
  OfferingSetup_HeaderQuery,
  OfferingSetup_HeaderQueryVariables
>;
export const OfferingSetup_InProgressOfferingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'OfferingSetup_InProgressOffering' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'offering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'pricingInstrumentId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dspAgent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sizeInCurrency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sizeInShares' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pricingCurrencyCode' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'syndicate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'managers' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isParticipating' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'economics' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'firmNameAbbreviation' },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'isJuniorRole' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'managerResponsibilities' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value:
                                  'OfferingSetup_InProgressOffering_ManagerResponsibilitieParts',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'issuer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lei' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cik' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'businessDescription' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'website' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sector' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'security' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isDepositaryReceipt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shareClass' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'disclaimers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'OfferingSetup_InProgressOffering_DisclaimersParts',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'marketing' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'OfferingSetup_InProgressOffering_MarketingParts',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'timing' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'confidentialFilingDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'publicFilingDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'filingOccurred' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'launchOccurred' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'launchDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'booksCloseAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'timeZone' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'pricingDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstTradeDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tradeDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'settlementDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postponedDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'withdrawnDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'terminatedDate' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'terms' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'followOnPricing' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'OfferingSetup_InProgressOffering_FollowOnPricingParts',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'underwritingFeesAndDiscounts' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value:
                                  'OfferingSetup_InProgressOffering_UnderwritingFeesAndDiscountsParts',
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'useOfProceeds' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lockUpPeriod' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'overAllotmentExercisedData' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'primaryShares' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'secondaryShares' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'notional' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'totalShares' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'filings' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'OfferingSetup_InProgressOffering_FilingParts',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'demandCurrencies' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'exchangeRate' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deliveryInstruments' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filters' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'recordStatus' },
                            value: { kind: 'EnumValue', value: 'EFFECTIVE' },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isDepositaryReceipt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'depositaryReceiptConversionRatio' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'numerator' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'denominator' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'stockSymbol' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isin' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cusip' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'figi' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'exchange' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'exchangeMic' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tranches' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'region' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'defaultInstrumentId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryInstrumentIds' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currencyIds' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'roadshowSchedules' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'details' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'managerEconomics' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'OfferingSetup_InProgressOffering_ManagerEconomicsParts',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_InProgressOffering_DisclaimersParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Disclaimers' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'restrictions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'footnotes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'registrationLinks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rule134Legend' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_InProgressOffering_FilingParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Filing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'filingType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'initialRegistrationValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primarySharesBaseOffering' } },
          { kind: 'Field', name: { kind: 'Name', value: 'secondarySharesBaseOffering' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primarySharesOverAllotmentAuthorized' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'secondarySharesOverAllotmentAuthorized' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'notionalOverAllotmentExercised' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primarySharesOverAllotmentExercised' } },
          { kind: 'Field', name: { kind: 'Name', value: 'secondarySharesOverAllotmentExercised' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notionalOverAllotmentAuthorized' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalSharesBaseOffering' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalSharesOverAllotmentAuthorized' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalSharesOverAllotmentExercised' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipoRangeLow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipoRangeHigh' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offerPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postOfferingShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'useOfProceeds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lockUpPeriod' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_InProgressOffering_FollowOnPricingParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FollowOnPricing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeFiling' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeLaunch' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeOffer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reOfferLow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reOfferHigh' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_InProgressOffering_ManagerEconomicsParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ManagerEconomics' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'incentiveFeeDistribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'percentageValue' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'underwritingFeeBaseDistribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'percentageValue' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'managementFeeDistribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'percentageValue' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sellingConcessionDistribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'percentageValue' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'underwritingFeeTotalDistribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'percentageValue' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'underwritingBaseSharesDistribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sharesValue' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'overallotmentExercisedSharesDistribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sharesValue' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'underwritingTotalSharesDistribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sharesValue' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_InProgressOffering_ManagerResponsibilitieParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ManagerResponsibilities' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stabilizationAgent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'settlementAgent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logisticsAgent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'leftLead' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_InProgressOffering_MarketingParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Marketing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'managementTeam' } },
          { kind: 'Field', name: { kind: 'Name', value: 'callDetails' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'OfferingSetup_InProgressOffering_UnderwritingFeesAndDiscountsParts',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UnderwritingFeesAndDiscounts' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'grossSpreadBaseData' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'grossSpreadBase' } },
                { kind: 'Field', name: { kind: 'Name', value: 'managementFeePercentage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'underwritingFeePercentage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sellingConcessionPercentage' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'incentiveFeeData' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'incentiveFee' } },
                { kind: 'Field', name: { kind: 'Name', value: 'managementFeePercentage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'underwritingFeePercentage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sellingConcessionPercentage' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'reallowance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharesExcludedFromGrossSpread' } },
          { kind: 'Field', name: { kind: 'Name', value: 'managementFee' } },
          { kind: 'Field', name: { kind: 'Name', value: 'underwritingFee' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sellingConcession' } },
          { kind: 'Field', name: { kind: 'Name', value: 'grossSpreadTotal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'netPrice' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useOfferingSetup_InProgressOfferingQuery__
 *
 * To run a query within a React component, call `useOfferingSetup_InProgressOfferingQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfferingSetup_InProgressOfferingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferingSetup_InProgressOfferingQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useOfferingSetup_InProgressOfferingQuery(
  baseOptions: Apollo.QueryHookOptions<
    OfferingSetup_InProgressOfferingQuery,
    OfferingSetup_InProgressOfferingQueryVariables
  > &
    (
      | { variables: OfferingSetup_InProgressOfferingQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OfferingSetup_InProgressOfferingQuery,
    OfferingSetup_InProgressOfferingQueryVariables
  >(OfferingSetup_InProgressOfferingDocument, options);
}
export function useOfferingSetup_InProgressOfferingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OfferingSetup_InProgressOfferingQuery,
    OfferingSetup_InProgressOfferingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OfferingSetup_InProgressOfferingQuery,
    OfferingSetup_InProgressOfferingQueryVariables
  >(OfferingSetup_InProgressOfferingDocument, options);
}
export function useOfferingSetup_InProgressOfferingSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        OfferingSetup_InProgressOfferingQuery,
        OfferingSetup_InProgressOfferingQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    OfferingSetup_InProgressOfferingQuery,
    OfferingSetup_InProgressOfferingQueryVariables
  >(OfferingSetup_InProgressOfferingDocument, options);
}
export type OfferingSetup_InProgressOfferingQueryHookResult = ReturnType<
  typeof useOfferingSetup_InProgressOfferingQuery
>;
export type OfferingSetup_InProgressOfferingLazyQueryHookResult = ReturnType<
  typeof useOfferingSetup_InProgressOfferingLazyQuery
>;
export type OfferingSetup_InProgressOfferingSuspenseQueryHookResult = ReturnType<
  typeof useOfferingSetup_InProgressOfferingSuspenseQuery
>;
export type OfferingSetup_InProgressOfferingQueryResult = Apollo.QueryResult<
  OfferingSetup_InProgressOfferingQuery,
  OfferingSetup_InProgressOfferingQueryVariables
>;
export const OfferingSetup_ManagerEconomics_ManagerEconomicsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'OfferingSetup_ManagerEconomics_ManagerEconomics' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'offering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pricingCurrencyCode' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'syndicate' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filters' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'recordStatus' },
                            value: { kind: 'EnumValue', value: 'EFFECTIVE' },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'managerEconomics' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'OfferingSetup_ManagerEconomics_ManagerEconomics',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_ManagerEconomics_PercentagePerCmgEntity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PercentagePerCmgEntity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'percentageValue' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_ManagerEconomics_SharesPerCmgEntity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SharesPerCmgEntity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharesValue' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_ManagerEconomics_ManagerEconomics' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ManagerEconomics' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'incentiveFeeDistribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'OfferingSetup_ManagerEconomics_PercentagePerCmgEntity',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'managementFeeDistribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'OfferingSetup_ManagerEconomics_PercentagePerCmgEntity',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sellingConcessionDistribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'OfferingSetup_ManagerEconomics_PercentagePerCmgEntity',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'underwritingFeeTotalDistribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'OfferingSetup_ManagerEconomics_PercentagePerCmgEntity',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'underwritingFeeBaseDistribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'OfferingSetup_ManagerEconomics_PercentagePerCmgEntity',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'underwritingBaseSharesDistribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'OfferingSetup_ManagerEconomics_SharesPerCmgEntity',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'overallotmentExercisedSharesDistribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'OfferingSetup_ManagerEconomics_SharesPerCmgEntity',
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useOfferingSetup_ManagerEconomics_ManagerEconomicsQuery__
 *
 * To run a query within a React component, call `useOfferingSetup_ManagerEconomics_ManagerEconomicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfferingSetup_ManagerEconomics_ManagerEconomicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferingSetup_ManagerEconomics_ManagerEconomicsQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useOfferingSetup_ManagerEconomics_ManagerEconomicsQuery(
  baseOptions: Apollo.QueryHookOptions<
    OfferingSetup_ManagerEconomics_ManagerEconomicsQuery,
    OfferingSetup_ManagerEconomics_ManagerEconomicsQueryVariables
  > &
    (
      | { variables: OfferingSetup_ManagerEconomics_ManagerEconomicsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OfferingSetup_ManagerEconomics_ManagerEconomicsQuery,
    OfferingSetup_ManagerEconomics_ManagerEconomicsQueryVariables
  >(OfferingSetup_ManagerEconomics_ManagerEconomicsDocument, options);
}
export function useOfferingSetup_ManagerEconomics_ManagerEconomicsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OfferingSetup_ManagerEconomics_ManagerEconomicsQuery,
    OfferingSetup_ManagerEconomics_ManagerEconomicsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OfferingSetup_ManagerEconomics_ManagerEconomicsQuery,
    OfferingSetup_ManagerEconomics_ManagerEconomicsQueryVariables
  >(OfferingSetup_ManagerEconomics_ManagerEconomicsDocument, options);
}
export function useOfferingSetup_ManagerEconomics_ManagerEconomicsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        OfferingSetup_ManagerEconomics_ManagerEconomicsQuery,
        OfferingSetup_ManagerEconomics_ManagerEconomicsQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    OfferingSetup_ManagerEconomics_ManagerEconomicsQuery,
    OfferingSetup_ManagerEconomics_ManagerEconomicsQueryVariables
  >(OfferingSetup_ManagerEconomics_ManagerEconomicsDocument, options);
}
export type OfferingSetup_ManagerEconomics_ManagerEconomicsQueryHookResult = ReturnType<
  typeof useOfferingSetup_ManagerEconomics_ManagerEconomicsQuery
>;
export type OfferingSetup_ManagerEconomics_ManagerEconomicsLazyQueryHookResult = ReturnType<
  typeof useOfferingSetup_ManagerEconomics_ManagerEconomicsLazyQuery
>;
export type OfferingSetup_ManagerEconomics_ManagerEconomicsSuspenseQueryHookResult = ReturnType<
  typeof useOfferingSetup_ManagerEconomics_ManagerEconomicsSuspenseQuery
>;
export type OfferingSetup_ManagerEconomics_ManagerEconomicsQueryResult = Apollo.QueryResult<
  OfferingSetup_ManagerEconomics_ManagerEconomicsQuery,
  OfferingSetup_ManagerEconomics_ManagerEconomicsQueryVariables
>;
export const OfferingSetup_ManagerEconomics_UnderwritingFeesAndDiscountsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'OfferingSetup_ManagerEconomics_UnderwritingFeesAndDiscounts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getUnderwritingFeesAndDiscounts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'grossSpreadTotal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'netPrice' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'grossSpreadBaseData' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'OfferingSetup_ManagerEconomics_GrossSpreadBaseData',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'incentiveFeeData' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'OfferingSetup_ManagerEconomics_IncentiveFeeData',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_ManagerEconomics_GrossSpreadBaseData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GrossSpreadBase' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'grossSpreadBase' } },
          { kind: 'Field', name: { kind: 'Name', value: 'managementFeePercentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'underwritingFeePercentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sellingConcessionPercentage' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_ManagerEconomics_IncentiveFeeData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'IncentiveFee' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'incentiveFee' } },
          { kind: 'Field', name: { kind: 'Name', value: 'managementFeePercentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'underwritingFeePercentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sellingConcessionPercentage' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useOfferingSetup_ManagerEconomics_UnderwritingFeesAndDiscountsQuery__
 *
 * To run a query within a React component, call `useOfferingSetup_ManagerEconomics_UnderwritingFeesAndDiscountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfferingSetup_ManagerEconomics_UnderwritingFeesAndDiscountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferingSetup_ManagerEconomics_UnderwritingFeesAndDiscountsQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useOfferingSetup_ManagerEconomics_UnderwritingFeesAndDiscountsQuery(
  baseOptions: Apollo.QueryHookOptions<
    OfferingSetup_ManagerEconomics_UnderwritingFeesAndDiscountsQuery,
    OfferingSetup_ManagerEconomics_UnderwritingFeesAndDiscountsQueryVariables
  > &
    (
      | {
          variables: OfferingSetup_ManagerEconomics_UnderwritingFeesAndDiscountsQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OfferingSetup_ManagerEconomics_UnderwritingFeesAndDiscountsQuery,
    OfferingSetup_ManagerEconomics_UnderwritingFeesAndDiscountsQueryVariables
  >(OfferingSetup_ManagerEconomics_UnderwritingFeesAndDiscountsDocument, options);
}
export function useOfferingSetup_ManagerEconomics_UnderwritingFeesAndDiscountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OfferingSetup_ManagerEconomics_UnderwritingFeesAndDiscountsQuery,
    OfferingSetup_ManagerEconomics_UnderwritingFeesAndDiscountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OfferingSetup_ManagerEconomics_UnderwritingFeesAndDiscountsQuery,
    OfferingSetup_ManagerEconomics_UnderwritingFeesAndDiscountsQueryVariables
  >(OfferingSetup_ManagerEconomics_UnderwritingFeesAndDiscountsDocument, options);
}
export function useOfferingSetup_ManagerEconomics_UnderwritingFeesAndDiscountsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        OfferingSetup_ManagerEconomics_UnderwritingFeesAndDiscountsQuery,
        OfferingSetup_ManagerEconomics_UnderwritingFeesAndDiscountsQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    OfferingSetup_ManagerEconomics_UnderwritingFeesAndDiscountsQuery,
    OfferingSetup_ManagerEconomics_UnderwritingFeesAndDiscountsQueryVariables
  >(OfferingSetup_ManagerEconomics_UnderwritingFeesAndDiscountsDocument, options);
}
export type OfferingSetup_ManagerEconomics_UnderwritingFeesAndDiscountsQueryHookResult = ReturnType<
  typeof useOfferingSetup_ManagerEconomics_UnderwritingFeesAndDiscountsQuery
>;
export type OfferingSetup_ManagerEconomics_UnderwritingFeesAndDiscountsLazyQueryHookResult =
  ReturnType<typeof useOfferingSetup_ManagerEconomics_UnderwritingFeesAndDiscountsLazyQuery>;
export type OfferingSetup_ManagerEconomics_UnderwritingFeesAndDiscountsSuspenseQueryHookResult =
  ReturnType<typeof useOfferingSetup_ManagerEconomics_UnderwritingFeesAndDiscountsSuspenseQuery>;
export type OfferingSetup_ManagerEconomics_UnderwritingFeesAndDiscountsQueryResult =
  Apollo.QueryResult<
    OfferingSetup_ManagerEconomics_UnderwritingFeesAndDiscountsQuery,
    OfferingSetup_ManagerEconomics_UnderwritingFeesAndDiscountsQueryVariables
  >;
export const OfferingSetup_ManagerEconomics_CalculateFeesDistributionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'OfferingSetup_ManagerEconomics_CalculateFeesDistributions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CalculateFeesDistributionsInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'calculateFeesDistributions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'managementFeeDistribution' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'OfferingSetup_ManagerEconomics_PercentagePerCmgEntity',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'underwritingFeeTotalDistribution' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'OfferingSetup_ManagerEconomics_PercentagePerCmgEntity',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sellingConcessionDistribution' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'OfferingSetup_ManagerEconomics_PercentagePerCmgEntity',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_ManagerEconomics_PercentagePerCmgEntity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PercentagePerCmgEntity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'percentageValue' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type OfferingSetup_ManagerEconomics_CalculateFeesDistributionsMutationFn =
  Apollo.MutationFunction<
    OfferingSetup_ManagerEconomics_CalculateFeesDistributionsMutation,
    OfferingSetup_ManagerEconomics_CalculateFeesDistributionsMutationVariables
  >;

/**
 * __useOfferingSetup_ManagerEconomics_CalculateFeesDistributionsMutation__
 *
 * To run a mutation, you first call `useOfferingSetup_ManagerEconomics_CalculateFeesDistributionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOfferingSetup_ManagerEconomics_CalculateFeesDistributionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [offeringSetupManagerEconomicsCalculateFeesDistributionsMutation, { data, loading, error }] = useOfferingSetup_ManagerEconomics_CalculateFeesDistributionsMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOfferingSetup_ManagerEconomics_CalculateFeesDistributionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OfferingSetup_ManagerEconomics_CalculateFeesDistributionsMutation,
    OfferingSetup_ManagerEconomics_CalculateFeesDistributionsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OfferingSetup_ManagerEconomics_CalculateFeesDistributionsMutation,
    OfferingSetup_ManagerEconomics_CalculateFeesDistributionsMutationVariables
  >(OfferingSetup_ManagerEconomics_CalculateFeesDistributionsDocument, options);
}
export type OfferingSetup_ManagerEconomics_CalculateFeesDistributionsMutationHookResult =
  ReturnType<typeof useOfferingSetup_ManagerEconomics_CalculateFeesDistributionsMutation>;
export type OfferingSetup_ManagerEconomics_CalculateFeesDistributionsMutationResult =
  Apollo.MutationResult<OfferingSetup_ManagerEconomics_CalculateFeesDistributionsMutation>;
export type OfferingSetup_ManagerEconomics_CalculateFeesDistributionsMutationOptions =
  Apollo.BaseMutationOptions<
    OfferingSetup_ManagerEconomics_CalculateFeesDistributionsMutation,
    OfferingSetup_ManagerEconomics_CalculateFeesDistributionsMutationVariables
  >;
export const OfferingSetup_ManagerEconomics_CalculateSharesDistributionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'OfferingSetup_ManagerEconomics_CalculateSharesDistributions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CalculateSharesDistributionsInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'calculateSharesDistributions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'underwritingBaseShareDistribution' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'OfferingSetup_ManagerEconomics_SharesPerCmgEntity',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'overallotmentExercisedShareDistribution' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'OfferingSetup_ManagerEconomics_SharesPerCmgEntity',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'underwritingTotalSharesDistribution' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'OfferingSetup_ManagerEconomics_SharesPerCmgEntity',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_ManagerEconomics_SharesPerCmgEntity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SharesPerCmgEntity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharesValue' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type OfferingSetup_ManagerEconomics_CalculateSharesDistributionsMutationFn =
  Apollo.MutationFunction<
    OfferingSetup_ManagerEconomics_CalculateSharesDistributionsMutation,
    OfferingSetup_ManagerEconomics_CalculateSharesDistributionsMutationVariables
  >;

/**
 * __useOfferingSetup_ManagerEconomics_CalculateSharesDistributionsMutation__
 *
 * To run a mutation, you first call `useOfferingSetup_ManagerEconomics_CalculateSharesDistributionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOfferingSetup_ManagerEconomics_CalculateSharesDistributionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [offeringSetupManagerEconomicsCalculateSharesDistributionsMutation, { data, loading, error }] = useOfferingSetup_ManagerEconomics_CalculateSharesDistributionsMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOfferingSetup_ManagerEconomics_CalculateSharesDistributionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OfferingSetup_ManagerEconomics_CalculateSharesDistributionsMutation,
    OfferingSetup_ManagerEconomics_CalculateSharesDistributionsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OfferingSetup_ManagerEconomics_CalculateSharesDistributionsMutation,
    OfferingSetup_ManagerEconomics_CalculateSharesDistributionsMutationVariables
  >(OfferingSetup_ManagerEconomics_CalculateSharesDistributionsDocument, options);
}
export type OfferingSetup_ManagerEconomics_CalculateSharesDistributionsMutationHookResult =
  ReturnType<typeof useOfferingSetup_ManagerEconomics_CalculateSharesDistributionsMutation>;
export type OfferingSetup_ManagerEconomics_CalculateSharesDistributionsMutationResult =
  Apollo.MutationResult<OfferingSetup_ManagerEconomics_CalculateSharesDistributionsMutation>;
export type OfferingSetup_ManagerEconomics_CalculateSharesDistributionsMutationOptions =
  Apollo.BaseMutationOptions<
    OfferingSetup_ManagerEconomics_CalculateSharesDistributionsMutation,
    OfferingSetup_ManagerEconomics_CalculateSharesDistributionsMutationVariables
  >;
export const OfferingSetup_ManagerEconomics_UpdateManagerEconomicsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'OfferingSetup_ManagerEconomics_UpdateManagerEconomics' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'incentiveFeeDistribution' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'PercentagePerCmgEntityInput' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'underwritingFeeBaseDistribution' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'PercentagePerCmgEntityInput' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sellingConcessionDistribution' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'PercentagePerCmgEntityInput' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'managementFeeDistribution' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'PercentagePerCmgEntityInput' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'underwritingFeeTotalDistribution' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'PercentagePerCmgEntityInput' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'overallotmentExercisedSharesDistribution' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'SharesPerCmgEntityInput' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'underwritingBaseSharesDistribution' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'SharesPerCmgEntityInput' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateIncentiveFeeDistribution' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'incentiveFeeDistribution' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'OfferingSetup_ManagerEconomics_PercentagePerCmgEntity',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUnderwritingFeeBaseDistribution' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'underwritingFeeBaseDistribution' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'OfferingSetup_ManagerEconomics_PercentagePerCmgEntity',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSellingConcessionDistribution' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sellingConcessionDistribution' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'OfferingSetup_ManagerEconomics_PercentagePerCmgEntity',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateManagementFeeDistribution' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'managementFeeDistribution' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'OfferingSetup_ManagerEconomics_PercentagePerCmgEntity',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUnderwritingFeeTotalDistribution' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'underwritingFeeTotalDistribution' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'OfferingSetup_ManagerEconomics_PercentagePerCmgEntity',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateOverallotmentExercisedSharesDistribution' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'overallotmentExercisedSharesDistribution' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'OfferingSetup_ManagerEconomics_SharesPerCmgEntity',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUnderwritingBaseSharesDistribution' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'underwritingBaseSharesDistribution' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'OfferingSetup_ManagerEconomics_SharesPerCmgEntity',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_ManagerEconomics_PercentagePerCmgEntity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PercentagePerCmgEntity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'percentageValue' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_ManagerEconomics_SharesPerCmgEntity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SharesPerCmgEntity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharesValue' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type OfferingSetup_ManagerEconomics_UpdateManagerEconomicsMutationFn =
  Apollo.MutationFunction<
    OfferingSetup_ManagerEconomics_UpdateManagerEconomicsMutation,
    OfferingSetup_ManagerEconomics_UpdateManagerEconomicsMutationVariables
  >;

/**
 * __useOfferingSetup_ManagerEconomics_UpdateManagerEconomicsMutation__
 *
 * To run a mutation, you first call `useOfferingSetup_ManagerEconomics_UpdateManagerEconomicsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOfferingSetup_ManagerEconomics_UpdateManagerEconomicsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [offeringSetupManagerEconomicsUpdateManagerEconomicsMutation, { data, loading, error }] = useOfferingSetup_ManagerEconomics_UpdateManagerEconomicsMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      incentiveFeeDistribution: // value for 'incentiveFeeDistribution'
 *      underwritingFeeBaseDistribution: // value for 'underwritingFeeBaseDistribution'
 *      sellingConcessionDistribution: // value for 'sellingConcessionDistribution'
 *      managementFeeDistribution: // value for 'managementFeeDistribution'
 *      underwritingFeeTotalDistribution: // value for 'underwritingFeeTotalDistribution'
 *      overallotmentExercisedSharesDistribution: // value for 'overallotmentExercisedSharesDistribution'
 *      underwritingBaseSharesDistribution: // value for 'underwritingBaseSharesDistribution'
 *   },
 * });
 */
export function useOfferingSetup_ManagerEconomics_UpdateManagerEconomicsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OfferingSetup_ManagerEconomics_UpdateManagerEconomicsMutation,
    OfferingSetup_ManagerEconomics_UpdateManagerEconomicsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OfferingSetup_ManagerEconomics_UpdateManagerEconomicsMutation,
    OfferingSetup_ManagerEconomics_UpdateManagerEconomicsMutationVariables
  >(OfferingSetup_ManagerEconomics_UpdateManagerEconomicsDocument, options);
}
export type OfferingSetup_ManagerEconomics_UpdateManagerEconomicsMutationHookResult = ReturnType<
  typeof useOfferingSetup_ManagerEconomics_UpdateManagerEconomicsMutation
>;
export type OfferingSetup_ManagerEconomics_UpdateManagerEconomicsMutationResult =
  Apollo.MutationResult<OfferingSetup_ManagerEconomics_UpdateManagerEconomicsMutation>;
export type OfferingSetup_ManagerEconomics_UpdateManagerEconomicsMutationOptions =
  Apollo.BaseMutationOptions<
    OfferingSetup_ManagerEconomics_UpdateManagerEconomicsMutation,
    OfferingSetup_ManagerEconomics_UpdateManagerEconomicsMutationVariables
  >;
export const OfferingSetup_ManagerEconomics_SyndicateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'OfferingSetup_ManagerEconomics_Syndicate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'offering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isAuthor' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'syndicate' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filters' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'recordStatus' },
                            value: { kind: 'EnumValue', value: 'EFFECTIVE' },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'grossSpread' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'managers' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'OfferingSetup_ManagerEconomics_Manager',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'managerResponsibilities' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'OfferingSetup_ManagerEconomics_ManagerResponsibilities',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_ManagerEconomics_Manager' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Manager' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isJuniorRole' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isParticipating' } },
          { kind: 'Field', name: { kind: 'Name', value: 'economics' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recordStatus' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_ManagerEconomics_ManagerResponsibilities' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ManagerResponsibilities' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stabilizationAgent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'settlementAgent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logisticsAgent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'leftLead' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useOfferingSetup_ManagerEconomics_SyndicateQuery__
 *
 * To run a query within a React component, call `useOfferingSetup_ManagerEconomics_SyndicateQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfferingSetup_ManagerEconomics_SyndicateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferingSetup_ManagerEconomics_SyndicateQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useOfferingSetup_ManagerEconomics_SyndicateQuery(
  baseOptions: Apollo.QueryHookOptions<
    OfferingSetup_ManagerEconomics_SyndicateQuery,
    OfferingSetup_ManagerEconomics_SyndicateQueryVariables
  > &
    (
      | { variables: OfferingSetup_ManagerEconomics_SyndicateQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OfferingSetup_ManagerEconomics_SyndicateQuery,
    OfferingSetup_ManagerEconomics_SyndicateQueryVariables
  >(OfferingSetup_ManagerEconomics_SyndicateDocument, options);
}
export function useOfferingSetup_ManagerEconomics_SyndicateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OfferingSetup_ManagerEconomics_SyndicateQuery,
    OfferingSetup_ManagerEconomics_SyndicateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OfferingSetup_ManagerEconomics_SyndicateQuery,
    OfferingSetup_ManagerEconomics_SyndicateQueryVariables
  >(OfferingSetup_ManagerEconomics_SyndicateDocument, options);
}
export function useOfferingSetup_ManagerEconomics_SyndicateSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        OfferingSetup_ManagerEconomics_SyndicateQuery,
        OfferingSetup_ManagerEconomics_SyndicateQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    OfferingSetup_ManagerEconomics_SyndicateQuery,
    OfferingSetup_ManagerEconomics_SyndicateQueryVariables
  >(OfferingSetup_ManagerEconomics_SyndicateDocument, options);
}
export type OfferingSetup_ManagerEconomics_SyndicateQueryHookResult = ReturnType<
  typeof useOfferingSetup_ManagerEconomics_SyndicateQuery
>;
export type OfferingSetup_ManagerEconomics_SyndicateLazyQueryHookResult = ReturnType<
  typeof useOfferingSetup_ManagerEconomics_SyndicateLazyQuery
>;
export type OfferingSetup_ManagerEconomics_SyndicateSuspenseQueryHookResult = ReturnType<
  typeof useOfferingSetup_ManagerEconomics_SyndicateSuspenseQuery
>;
export type OfferingSetup_ManagerEconomics_SyndicateQueryResult = Apollo.QueryResult<
  OfferingSetup_ManagerEconomics_SyndicateQuery,
  OfferingSetup_ManagerEconomics_SyndicateQueryVariables
>;
export const OfferingSetup_ManagerEconomics_FilingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'OfferingSetup_ManagerEconomics_Filings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getFilings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'filingType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'initialRegistrationValue' } },
                { kind: 'Field', name: { kind: 'Name', value: 'primarySharesBaseOffering' } },
                { kind: 'Field', name: { kind: 'Name', value: 'secondarySharesBaseOffering' } },
                { kind: 'Field', name: { kind: 'Name', value: 'notionalOverAllotmentAuthorized' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'primarySharesOverAllotmentAuthorized' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'notionalOverAllotmentExercised' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'primarySharesOverAllotmentExercised' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'totalSharesOverAllotmentAuthorized' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'totalSharesOverAllotmentExercised' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalSharesBaseOffering' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ipoRangeLow' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ipoRangeHigh' } },
                { kind: 'Field', name: { kind: 'Name', value: 'offerPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postOfferingShares' } },
                { kind: 'Field', name: { kind: 'Name', value: 'useOfProceeds' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lockUpPeriod' } },
                { kind: 'Field', name: { kind: 'Name', value: 'recordStatus' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useOfferingSetup_ManagerEconomics_FilingsQuery__
 *
 * To run a query within a React component, call `useOfferingSetup_ManagerEconomics_FilingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfferingSetup_ManagerEconomics_FilingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferingSetup_ManagerEconomics_FilingsQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useOfferingSetup_ManagerEconomics_FilingsQuery(
  baseOptions: Apollo.QueryHookOptions<
    OfferingSetup_ManagerEconomics_FilingsQuery,
    OfferingSetup_ManagerEconomics_FilingsQueryVariables
  > &
    (
      | { variables: OfferingSetup_ManagerEconomics_FilingsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OfferingSetup_ManagerEconomics_FilingsQuery,
    OfferingSetup_ManagerEconomics_FilingsQueryVariables
  >(OfferingSetup_ManagerEconomics_FilingsDocument, options);
}
export function useOfferingSetup_ManagerEconomics_FilingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OfferingSetup_ManagerEconomics_FilingsQuery,
    OfferingSetup_ManagerEconomics_FilingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OfferingSetup_ManagerEconomics_FilingsQuery,
    OfferingSetup_ManagerEconomics_FilingsQueryVariables
  >(OfferingSetup_ManagerEconomics_FilingsDocument, options);
}
export function useOfferingSetup_ManagerEconomics_FilingsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        OfferingSetup_ManagerEconomics_FilingsQuery,
        OfferingSetup_ManagerEconomics_FilingsQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    OfferingSetup_ManagerEconomics_FilingsQuery,
    OfferingSetup_ManagerEconomics_FilingsQueryVariables
  >(OfferingSetup_ManagerEconomics_FilingsDocument, options);
}
export type OfferingSetup_ManagerEconomics_FilingsQueryHookResult = ReturnType<
  typeof useOfferingSetup_ManagerEconomics_FilingsQuery
>;
export type OfferingSetup_ManagerEconomics_FilingsLazyQueryHookResult = ReturnType<
  typeof useOfferingSetup_ManagerEconomics_FilingsLazyQuery
>;
export type OfferingSetup_ManagerEconomics_FilingsSuspenseQueryHookResult = ReturnType<
  typeof useOfferingSetup_ManagerEconomics_FilingsSuspenseQuery
>;
export type OfferingSetup_ManagerEconomics_FilingsQueryResult = Apollo.QueryResult<
  OfferingSetup_ManagerEconomics_FilingsQuery,
  OfferingSetup_ManagerEconomics_FilingsQueryVariables
>;
export const OfferingSetup_Prospectus_PublishedOfferingVersionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'OfferingSetup_Prospectus_PublishedOfferingVersion' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'publishedOffering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'version' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useOfferingSetup_Prospectus_PublishedOfferingVersionQuery__
 *
 * To run a query within a React component, call `useOfferingSetup_Prospectus_PublishedOfferingVersionQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfferingSetup_Prospectus_PublishedOfferingVersionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferingSetup_Prospectus_PublishedOfferingVersionQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useOfferingSetup_Prospectus_PublishedOfferingVersionQuery(
  baseOptions: Apollo.QueryHookOptions<
    OfferingSetup_Prospectus_PublishedOfferingVersionQuery,
    OfferingSetup_Prospectus_PublishedOfferingVersionQueryVariables
  > &
    (
      | {
          variables: OfferingSetup_Prospectus_PublishedOfferingVersionQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OfferingSetup_Prospectus_PublishedOfferingVersionQuery,
    OfferingSetup_Prospectus_PublishedOfferingVersionQueryVariables
  >(OfferingSetup_Prospectus_PublishedOfferingVersionDocument, options);
}
export function useOfferingSetup_Prospectus_PublishedOfferingVersionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OfferingSetup_Prospectus_PublishedOfferingVersionQuery,
    OfferingSetup_Prospectus_PublishedOfferingVersionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OfferingSetup_Prospectus_PublishedOfferingVersionQuery,
    OfferingSetup_Prospectus_PublishedOfferingVersionQueryVariables
  >(OfferingSetup_Prospectus_PublishedOfferingVersionDocument, options);
}
export function useOfferingSetup_Prospectus_PublishedOfferingVersionSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        OfferingSetup_Prospectus_PublishedOfferingVersionQuery,
        OfferingSetup_Prospectus_PublishedOfferingVersionQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    OfferingSetup_Prospectus_PublishedOfferingVersionQuery,
    OfferingSetup_Prospectus_PublishedOfferingVersionQueryVariables
  >(OfferingSetup_Prospectus_PublishedOfferingVersionDocument, options);
}
export type OfferingSetup_Prospectus_PublishedOfferingVersionQueryHookResult = ReturnType<
  typeof useOfferingSetup_Prospectus_PublishedOfferingVersionQuery
>;
export type OfferingSetup_Prospectus_PublishedOfferingVersionLazyQueryHookResult = ReturnType<
  typeof useOfferingSetup_Prospectus_PublishedOfferingVersionLazyQuery
>;
export type OfferingSetup_Prospectus_PublishedOfferingVersionSuspenseQueryHookResult = ReturnType<
  typeof useOfferingSetup_Prospectus_PublishedOfferingVersionSuspenseQuery
>;
export type OfferingSetup_Prospectus_PublishedOfferingVersionQueryResult = Apollo.QueryResult<
  OfferingSetup_Prospectus_PublishedOfferingVersionQuery,
  OfferingSetup_Prospectus_PublishedOfferingVersionQueryVariables
>;
export const OfferingSetup_Prospectus_ProspectusDocumentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'OfferingSetup_Prospectus_ProspectusDocuments' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'offering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'versions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'prospectusDocuments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'OfferingSetup_Prospectus_DocumentMetadata' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_Prospectus_DocumentMetadata' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProspectusDocument' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'documentName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'includeOnPublish' } },
          { kind: 'Field', name: { kind: 'Name', value: 'publicUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uploadedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstPublishedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useOfferingSetup_Prospectus_ProspectusDocumentsQuery__
 *
 * To run a query within a React component, call `useOfferingSetup_Prospectus_ProspectusDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfferingSetup_Prospectus_ProspectusDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferingSetup_Prospectus_ProspectusDocumentsQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useOfferingSetup_Prospectus_ProspectusDocumentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    OfferingSetup_Prospectus_ProspectusDocumentsQuery,
    OfferingSetup_Prospectus_ProspectusDocumentsQueryVariables
  > &
    (
      | { variables: OfferingSetup_Prospectus_ProspectusDocumentsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OfferingSetup_Prospectus_ProspectusDocumentsQuery,
    OfferingSetup_Prospectus_ProspectusDocumentsQueryVariables
  >(OfferingSetup_Prospectus_ProspectusDocumentsDocument, options);
}
export function useOfferingSetup_Prospectus_ProspectusDocumentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OfferingSetup_Prospectus_ProspectusDocumentsQuery,
    OfferingSetup_Prospectus_ProspectusDocumentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OfferingSetup_Prospectus_ProspectusDocumentsQuery,
    OfferingSetup_Prospectus_ProspectusDocumentsQueryVariables
  >(OfferingSetup_Prospectus_ProspectusDocumentsDocument, options);
}
export function useOfferingSetup_Prospectus_ProspectusDocumentsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        OfferingSetup_Prospectus_ProspectusDocumentsQuery,
        OfferingSetup_Prospectus_ProspectusDocumentsQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    OfferingSetup_Prospectus_ProspectusDocumentsQuery,
    OfferingSetup_Prospectus_ProspectusDocumentsQueryVariables
  >(OfferingSetup_Prospectus_ProspectusDocumentsDocument, options);
}
export type OfferingSetup_Prospectus_ProspectusDocumentsQueryHookResult = ReturnType<
  typeof useOfferingSetup_Prospectus_ProspectusDocumentsQuery
>;
export type OfferingSetup_Prospectus_ProspectusDocumentsLazyQueryHookResult = ReturnType<
  typeof useOfferingSetup_Prospectus_ProspectusDocumentsLazyQuery
>;
export type OfferingSetup_Prospectus_ProspectusDocumentsSuspenseQueryHookResult = ReturnType<
  typeof useOfferingSetup_Prospectus_ProspectusDocumentsSuspenseQuery
>;
export type OfferingSetup_Prospectus_ProspectusDocumentsQueryResult = Apollo.QueryResult<
  OfferingSetup_Prospectus_ProspectusDocumentsQuery,
  OfferingSetup_Prospectus_ProspectusDocumentsQueryVariables
>;
export const OfferingSetup_Prospectus_UpdateDocumentMetadataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'OfferingSetup_Prospectus_UpdateDocumentMetadata' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'prospectusDocuments' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'ProspectusDocumentUpdateMetadata' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateProspectusDocumentsMetadata' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'prospectusDocuments' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'prospectusDocuments' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OfferingSetup_Prospectus_DocumentMetadata' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_Prospectus_DocumentMetadata' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProspectusDocument' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'documentName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'includeOnPublish' } },
          { kind: 'Field', name: { kind: 'Name', value: 'publicUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uploadedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstPublishedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type OfferingSetup_Prospectus_UpdateDocumentMetadataMutationFn = Apollo.MutationFunction<
  OfferingSetup_Prospectus_UpdateDocumentMetadataMutation,
  OfferingSetup_Prospectus_UpdateDocumentMetadataMutationVariables
>;

/**
 * __useOfferingSetup_Prospectus_UpdateDocumentMetadataMutation__
 *
 * To run a mutation, you first call `useOfferingSetup_Prospectus_UpdateDocumentMetadataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOfferingSetup_Prospectus_UpdateDocumentMetadataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [offeringSetupProspectusUpdateDocumentMetadataMutation, { data, loading, error }] = useOfferingSetup_Prospectus_UpdateDocumentMetadataMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      prospectusDocuments: // value for 'prospectusDocuments'
 *   },
 * });
 */
export function useOfferingSetup_Prospectus_UpdateDocumentMetadataMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OfferingSetup_Prospectus_UpdateDocumentMetadataMutation,
    OfferingSetup_Prospectus_UpdateDocumentMetadataMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OfferingSetup_Prospectus_UpdateDocumentMetadataMutation,
    OfferingSetup_Prospectus_UpdateDocumentMetadataMutationVariables
  >(OfferingSetup_Prospectus_UpdateDocumentMetadataDocument, options);
}
export type OfferingSetup_Prospectus_UpdateDocumentMetadataMutationHookResult = ReturnType<
  typeof useOfferingSetup_Prospectus_UpdateDocumentMetadataMutation
>;
export type OfferingSetup_Prospectus_UpdateDocumentMetadataMutationResult =
  Apollo.MutationResult<OfferingSetup_Prospectus_UpdateDocumentMetadataMutation>;
export type OfferingSetup_Prospectus_UpdateDocumentMetadataMutationOptions =
  Apollo.BaseMutationOptions<
    OfferingSetup_Prospectus_UpdateDocumentMetadataMutation,
    OfferingSetup_Prospectus_UpdateDocumentMetadataMutationVariables
  >;
export const OfferingSetup_PublishedHeaderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'OfferingSetup_PublishedHeader' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'publishedOffering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useOfferingSetup_PublishedHeaderQuery__
 *
 * To run a query within a React component, call `useOfferingSetup_PublishedHeaderQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfferingSetup_PublishedHeaderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferingSetup_PublishedHeaderQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useOfferingSetup_PublishedHeaderQuery(
  baseOptions: Apollo.QueryHookOptions<
    OfferingSetup_PublishedHeaderQuery,
    OfferingSetup_PublishedHeaderQueryVariables
  > &
    ({ variables: OfferingSetup_PublishedHeaderQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OfferingSetup_PublishedHeaderQuery,
    OfferingSetup_PublishedHeaderQueryVariables
  >(OfferingSetup_PublishedHeaderDocument, options);
}
export function useOfferingSetup_PublishedHeaderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OfferingSetup_PublishedHeaderQuery,
    OfferingSetup_PublishedHeaderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OfferingSetup_PublishedHeaderQuery,
    OfferingSetup_PublishedHeaderQueryVariables
  >(OfferingSetup_PublishedHeaderDocument, options);
}
export function useOfferingSetup_PublishedHeaderSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        OfferingSetup_PublishedHeaderQuery,
        OfferingSetup_PublishedHeaderQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    OfferingSetup_PublishedHeaderQuery,
    OfferingSetup_PublishedHeaderQueryVariables
  >(OfferingSetup_PublishedHeaderDocument, options);
}
export type OfferingSetup_PublishedHeaderQueryHookResult = ReturnType<
  typeof useOfferingSetup_PublishedHeaderQuery
>;
export type OfferingSetup_PublishedHeaderLazyQueryHookResult = ReturnType<
  typeof useOfferingSetup_PublishedHeaderLazyQuery
>;
export type OfferingSetup_PublishedHeaderSuspenseQueryHookResult = ReturnType<
  typeof useOfferingSetup_PublishedHeaderSuspenseQuery
>;
export type OfferingSetup_PublishedHeaderQueryResult = Apollo.QueryResult<
  OfferingSetup_PublishedHeaderQuery,
  OfferingSetup_PublishedHeaderQueryVariables
>;
export const OfferingSetup_Summary_DiscardOfferingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'OfferingSetup_Summary_DiscardOffering' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'discardOffering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Offering' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
                    ],
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OfferingSetup_Summary_ServerErrorParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_Summary_ServerErrorParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ServiceError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'target' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'target' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type OfferingSetup_Summary_DiscardOfferingMutationFn = Apollo.MutationFunction<
  OfferingSetup_Summary_DiscardOfferingMutation,
  OfferingSetup_Summary_DiscardOfferingMutationVariables
>;

/**
 * __useOfferingSetup_Summary_DiscardOfferingMutation__
 *
 * To run a mutation, you first call `useOfferingSetup_Summary_DiscardOfferingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOfferingSetup_Summary_DiscardOfferingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [offeringSetupSummaryDiscardOfferingMutation, { data, loading, error }] = useOfferingSetup_Summary_DiscardOfferingMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useOfferingSetup_Summary_DiscardOfferingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OfferingSetup_Summary_DiscardOfferingMutation,
    OfferingSetup_Summary_DiscardOfferingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OfferingSetup_Summary_DiscardOfferingMutation,
    OfferingSetup_Summary_DiscardOfferingMutationVariables
  >(OfferingSetup_Summary_DiscardOfferingDocument, options);
}
export type OfferingSetup_Summary_DiscardOfferingMutationHookResult = ReturnType<
  typeof useOfferingSetup_Summary_DiscardOfferingMutation
>;
export type OfferingSetup_Summary_DiscardOfferingMutationResult =
  Apollo.MutationResult<OfferingSetup_Summary_DiscardOfferingMutation>;
export type OfferingSetup_Summary_DiscardOfferingMutationOptions = Apollo.BaseMutationOptions<
  OfferingSetup_Summary_DiscardOfferingMutation,
  OfferingSetup_Summary_DiscardOfferingMutationVariables
>;
export const OfferingSetup_Summary_AvailableShareDraftManagersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'OfferingSetup_Summary_AvailableShareDraftManagers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'availableShareDraftManagers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OfferingSetup_Summary_ManagerLimited' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_Summary_ManagerLimited' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ManagerLimited' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useOfferingSetup_Summary_AvailableShareDraftManagersQuery__
 *
 * To run a query within a React component, call `useOfferingSetup_Summary_AvailableShareDraftManagersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfferingSetup_Summary_AvailableShareDraftManagersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferingSetup_Summary_AvailableShareDraftManagersQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useOfferingSetup_Summary_AvailableShareDraftManagersQuery(
  baseOptions: Apollo.QueryHookOptions<
    OfferingSetup_Summary_AvailableShareDraftManagersQuery,
    OfferingSetup_Summary_AvailableShareDraftManagersQueryVariables
  > &
    (
      | {
          variables: OfferingSetup_Summary_AvailableShareDraftManagersQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OfferingSetup_Summary_AvailableShareDraftManagersQuery,
    OfferingSetup_Summary_AvailableShareDraftManagersQueryVariables
  >(OfferingSetup_Summary_AvailableShareDraftManagersDocument, options);
}
export function useOfferingSetup_Summary_AvailableShareDraftManagersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OfferingSetup_Summary_AvailableShareDraftManagersQuery,
    OfferingSetup_Summary_AvailableShareDraftManagersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OfferingSetup_Summary_AvailableShareDraftManagersQuery,
    OfferingSetup_Summary_AvailableShareDraftManagersQueryVariables
  >(OfferingSetup_Summary_AvailableShareDraftManagersDocument, options);
}
export function useOfferingSetup_Summary_AvailableShareDraftManagersSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        OfferingSetup_Summary_AvailableShareDraftManagersQuery,
        OfferingSetup_Summary_AvailableShareDraftManagersQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    OfferingSetup_Summary_AvailableShareDraftManagersQuery,
    OfferingSetup_Summary_AvailableShareDraftManagersQueryVariables
  >(OfferingSetup_Summary_AvailableShareDraftManagersDocument, options);
}
export type OfferingSetup_Summary_AvailableShareDraftManagersQueryHookResult = ReturnType<
  typeof useOfferingSetup_Summary_AvailableShareDraftManagersQuery
>;
export type OfferingSetup_Summary_AvailableShareDraftManagersLazyQueryHookResult = ReturnType<
  typeof useOfferingSetup_Summary_AvailableShareDraftManagersLazyQuery
>;
export type OfferingSetup_Summary_AvailableShareDraftManagersSuspenseQueryHookResult = ReturnType<
  typeof useOfferingSetup_Summary_AvailableShareDraftManagersSuspenseQuery
>;
export type OfferingSetup_Summary_AvailableShareDraftManagersQueryResult = Apollo.QueryResult<
  OfferingSetup_Summary_AvailableShareDraftManagersQuery,
  OfferingSetup_Summary_AvailableShareDraftManagersQueryVariables
>;
export const OfferingSetup_Summary_PublishedOfferingStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'OfferingSetup_Summary_PublishedOfferingStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'publishedOffering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'status' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useOfferingSetup_Summary_PublishedOfferingStatusQuery__
 *
 * To run a query within a React component, call `useOfferingSetup_Summary_PublishedOfferingStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfferingSetup_Summary_PublishedOfferingStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferingSetup_Summary_PublishedOfferingStatusQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useOfferingSetup_Summary_PublishedOfferingStatusQuery(
  baseOptions: Apollo.QueryHookOptions<
    OfferingSetup_Summary_PublishedOfferingStatusQuery,
    OfferingSetup_Summary_PublishedOfferingStatusQueryVariables
  > &
    (
      | { variables: OfferingSetup_Summary_PublishedOfferingStatusQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OfferingSetup_Summary_PublishedOfferingStatusQuery,
    OfferingSetup_Summary_PublishedOfferingStatusQueryVariables
  >(OfferingSetup_Summary_PublishedOfferingStatusDocument, options);
}
export function useOfferingSetup_Summary_PublishedOfferingStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OfferingSetup_Summary_PublishedOfferingStatusQuery,
    OfferingSetup_Summary_PublishedOfferingStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OfferingSetup_Summary_PublishedOfferingStatusQuery,
    OfferingSetup_Summary_PublishedOfferingStatusQueryVariables
  >(OfferingSetup_Summary_PublishedOfferingStatusDocument, options);
}
export function useOfferingSetup_Summary_PublishedOfferingStatusSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        OfferingSetup_Summary_PublishedOfferingStatusQuery,
        OfferingSetup_Summary_PublishedOfferingStatusQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    OfferingSetup_Summary_PublishedOfferingStatusQuery,
    OfferingSetup_Summary_PublishedOfferingStatusQueryVariables
  >(OfferingSetup_Summary_PublishedOfferingStatusDocument, options);
}
export type OfferingSetup_Summary_PublishedOfferingStatusQueryHookResult = ReturnType<
  typeof useOfferingSetup_Summary_PublishedOfferingStatusQuery
>;
export type OfferingSetup_Summary_PublishedOfferingStatusLazyQueryHookResult = ReturnType<
  typeof useOfferingSetup_Summary_PublishedOfferingStatusLazyQuery
>;
export type OfferingSetup_Summary_PublishedOfferingStatusSuspenseQueryHookResult = ReturnType<
  typeof useOfferingSetup_Summary_PublishedOfferingStatusSuspenseQuery
>;
export type OfferingSetup_Summary_PublishedOfferingStatusQueryResult = Apollo.QueryResult<
  OfferingSetup_Summary_PublishedOfferingStatusQuery,
  OfferingSetup_Summary_PublishedOfferingStatusQueryVariables
>;
export const OfferingSetup_Summary_OfferingShareHistoryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'OfferingSetup_Summary_OfferingShareHistory' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shareHistory' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OfferingSetup_Summary_OfferingShareHistory' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_Summary_OfferingShareHistory' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SharedDraftHistory' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharedTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'managerKeys' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useOfferingSetup_Summary_OfferingShareHistoryQuery__
 *
 * To run a query within a React component, call `useOfferingSetup_Summary_OfferingShareHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfferingSetup_Summary_OfferingShareHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferingSetup_Summary_OfferingShareHistoryQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useOfferingSetup_Summary_OfferingShareHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    OfferingSetup_Summary_OfferingShareHistoryQuery,
    OfferingSetup_Summary_OfferingShareHistoryQueryVariables
  > &
    (
      | { variables: OfferingSetup_Summary_OfferingShareHistoryQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OfferingSetup_Summary_OfferingShareHistoryQuery,
    OfferingSetup_Summary_OfferingShareHistoryQueryVariables
  >(OfferingSetup_Summary_OfferingShareHistoryDocument, options);
}
export function useOfferingSetup_Summary_OfferingShareHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OfferingSetup_Summary_OfferingShareHistoryQuery,
    OfferingSetup_Summary_OfferingShareHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OfferingSetup_Summary_OfferingShareHistoryQuery,
    OfferingSetup_Summary_OfferingShareHistoryQueryVariables
  >(OfferingSetup_Summary_OfferingShareHistoryDocument, options);
}
export function useOfferingSetup_Summary_OfferingShareHistorySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        OfferingSetup_Summary_OfferingShareHistoryQuery,
        OfferingSetup_Summary_OfferingShareHistoryQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    OfferingSetup_Summary_OfferingShareHistoryQuery,
    OfferingSetup_Summary_OfferingShareHistoryQueryVariables
  >(OfferingSetup_Summary_OfferingShareHistoryDocument, options);
}
export type OfferingSetup_Summary_OfferingShareHistoryQueryHookResult = ReturnType<
  typeof useOfferingSetup_Summary_OfferingShareHistoryQuery
>;
export type OfferingSetup_Summary_OfferingShareHistoryLazyQueryHookResult = ReturnType<
  typeof useOfferingSetup_Summary_OfferingShareHistoryLazyQuery
>;
export type OfferingSetup_Summary_OfferingShareHistorySuspenseQueryHookResult = ReturnType<
  typeof useOfferingSetup_Summary_OfferingShareHistorySuspenseQuery
>;
export type OfferingSetup_Summary_OfferingShareHistoryQueryResult = Apollo.QueryResult<
  OfferingSetup_Summary_OfferingShareHistoryQuery,
  OfferingSetup_Summary_OfferingShareHistoryQueryVariables
>;
export const OfferingSetup_Summary_PublishOfferingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'OfferingSetup_Summary_PublishOffering' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'publishOffering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Offering' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    ],
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OfferingSetup_Summary_ServerErrorParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_Summary_ServerErrorParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ServiceError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'target' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'target' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type OfferingSetup_Summary_PublishOfferingMutationFn = Apollo.MutationFunction<
  OfferingSetup_Summary_PublishOfferingMutation,
  OfferingSetup_Summary_PublishOfferingMutationVariables
>;

/**
 * __useOfferingSetup_Summary_PublishOfferingMutation__
 *
 * To run a mutation, you first call `useOfferingSetup_Summary_PublishOfferingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOfferingSetup_Summary_PublishOfferingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [offeringSetupSummaryPublishOfferingMutation, { data, loading, error }] = useOfferingSetup_Summary_PublishOfferingMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useOfferingSetup_Summary_PublishOfferingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OfferingSetup_Summary_PublishOfferingMutation,
    OfferingSetup_Summary_PublishOfferingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OfferingSetup_Summary_PublishOfferingMutation,
    OfferingSetup_Summary_PublishOfferingMutationVariables
  >(OfferingSetup_Summary_PublishOfferingDocument, options);
}
export type OfferingSetup_Summary_PublishOfferingMutationHookResult = ReturnType<
  typeof useOfferingSetup_Summary_PublishOfferingMutation
>;
export type OfferingSetup_Summary_PublishOfferingMutationResult =
  Apollo.MutationResult<OfferingSetup_Summary_PublishOfferingMutation>;
export type OfferingSetup_Summary_PublishOfferingMutationOptions = Apollo.BaseMutationOptions<
  OfferingSetup_Summary_PublishOfferingMutation,
  OfferingSetup_Summary_PublishOfferingMutationVariables
>;
export const OfferingSetup_Summary_ShareDraftOfferingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'OfferingSetup_Summary_ShareDraftOffering' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'managerKeys' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shareDraft' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'managerKeys' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'managerKeys' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type OfferingSetup_Summary_ShareDraftOfferingMutationFn = Apollo.MutationFunction<
  OfferingSetup_Summary_ShareDraftOfferingMutation,
  OfferingSetup_Summary_ShareDraftOfferingMutationVariables
>;

/**
 * __useOfferingSetup_Summary_ShareDraftOfferingMutation__
 *
 * To run a mutation, you first call `useOfferingSetup_Summary_ShareDraftOfferingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOfferingSetup_Summary_ShareDraftOfferingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [offeringSetupSummaryShareDraftOfferingMutation, { data, loading, error }] = useOfferingSetup_Summary_ShareDraftOfferingMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      managerKeys: // value for 'managerKeys'
 *   },
 * });
 */
export function useOfferingSetup_Summary_ShareDraftOfferingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OfferingSetup_Summary_ShareDraftOfferingMutation,
    OfferingSetup_Summary_ShareDraftOfferingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OfferingSetup_Summary_ShareDraftOfferingMutation,
    OfferingSetup_Summary_ShareDraftOfferingMutationVariables
  >(OfferingSetup_Summary_ShareDraftOfferingDocument, options);
}
export type OfferingSetup_Summary_ShareDraftOfferingMutationHookResult = ReturnType<
  typeof useOfferingSetup_Summary_ShareDraftOfferingMutation
>;
export type OfferingSetup_Summary_ShareDraftOfferingMutationResult =
  Apollo.MutationResult<OfferingSetup_Summary_ShareDraftOfferingMutation>;
export type OfferingSetup_Summary_ShareDraftOfferingMutationOptions = Apollo.BaseMutationOptions<
  OfferingSetup_Summary_ShareDraftOfferingMutation,
  OfferingSetup_Summary_ShareDraftOfferingMutationVariables
>;
export const OfferingSetup_Summary_UpdateOfferingStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'OfferingSetup_Summary_UpdateOfferingStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'OfferingStatus' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateOfferingStatus' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'status' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Offering' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    ],
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OfferingSetup_Summary_ServerErrorParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_Summary_ServerErrorParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ServiceError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'target' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'target' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type OfferingSetup_Summary_UpdateOfferingStatusMutationFn = Apollo.MutationFunction<
  OfferingSetup_Summary_UpdateOfferingStatusMutation,
  OfferingSetup_Summary_UpdateOfferingStatusMutationVariables
>;

/**
 * __useOfferingSetup_Summary_UpdateOfferingStatusMutation__
 *
 * To run a mutation, you first call `useOfferingSetup_Summary_UpdateOfferingStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOfferingSetup_Summary_UpdateOfferingStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [offeringSetupSummaryUpdateOfferingStatusMutation, { data, loading, error }] = useOfferingSetup_Summary_UpdateOfferingStatusMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useOfferingSetup_Summary_UpdateOfferingStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OfferingSetup_Summary_UpdateOfferingStatusMutation,
    OfferingSetup_Summary_UpdateOfferingStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OfferingSetup_Summary_UpdateOfferingStatusMutation,
    OfferingSetup_Summary_UpdateOfferingStatusMutationVariables
  >(OfferingSetup_Summary_UpdateOfferingStatusDocument, options);
}
export type OfferingSetup_Summary_UpdateOfferingStatusMutationHookResult = ReturnType<
  typeof useOfferingSetup_Summary_UpdateOfferingStatusMutation
>;
export type OfferingSetup_Summary_UpdateOfferingStatusMutationResult =
  Apollo.MutationResult<OfferingSetup_Summary_UpdateOfferingStatusMutation>;
export type OfferingSetup_Summary_UpdateOfferingStatusMutationOptions = Apollo.BaseMutationOptions<
  OfferingSetup_Summary_UpdateOfferingStatusMutation,
  OfferingSetup_Summary_UpdateOfferingStatusMutationVariables
>;
export const OfferingSetup_Summary_OfferingSummaryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'OfferingSetup_Summary_OfferingSummary' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'offering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasUnpublishedChanges' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isDiscarded' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pricingCurrencyCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pricingInstrumentId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'instruments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'pricingInstrumentStockSymbol' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sizeInCurrency' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lenders' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sponsors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'preOfferingOwnership' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postOfferingOwnership' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'postOfferingOwnershipIncludingShoe' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'advisors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'roadshowSchedules' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filters' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'recordStatus' },
                            value: { kind: 'EnumValue', value: 'EFFECTIVE' },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'details' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'issuer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lei' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cik' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'website' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sector' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'businessDescription' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'security' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'typeDisplayName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isDepositaryReceipt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shareClass' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'timing' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'confidentialFilingDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'publicFilingDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'filingOccurred' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'launchOccurred' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'launchDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'booksCloseAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'pricingDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstTradeDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tradeDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'settlementDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postponedDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'withdrawnDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'terminatedDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'timeZone' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'terms' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'followOnPricing' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lastTradeBeforeFiling' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lastTradeBeforeLaunch' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lastTradeBeforeOffer' },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'reOfferLow' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'reOfferHigh' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'filings' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filters' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'recordStatus' },
                            value: { kind: 'EnumValue', value: 'EFFECTIVE' },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'filingType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'initialRegistrationValue' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'primarySharesBaseOffering' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'secondarySharesBaseOffering' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'notionalOverAllotmentAuthorized' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'primarySharesOverAllotmentAuthorized' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'secondarySharesOverAllotmentAuthorized' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'notionalOverAllotmentExercised' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'primarySharesOverAllotmentExercised' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'secondarySharesOverAllotmentExercised' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'ipoRangeLow' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'ipoRangeHigh' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'offerPrice' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postOfferingShares' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'useOfProceeds' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lockUpPeriod' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'disclaimers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'OfferingSetup_Summary_Disclaimers' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currencies' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filters' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'recordStatus' },
                            value: { kind: 'EnumValue', value: 'EFFECTIVE' },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'exchangeRate' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deliveryInstruments' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filters' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'recordStatus' },
                            value: { kind: 'EnumValue', value: 'EFFECTIVE' },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isDepositaryReceipt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'depositaryReceiptConversionRatio' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'numerator' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'denominator' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'stockSymbol' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isin' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cusip' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'figi' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'exchangeMic' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tranches' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filters' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'recordStatus' },
                            value: { kind: 'EnumValue', value: 'EFFECTIVE' },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'region' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'defaultInstrumentId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currencyIds' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'syndicate' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filters' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'recordStatus' },
                            value: { kind: 'EnumValue', value: 'EFFECTIVE' },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'managers' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'OfferingSetup_Summary_SyndicateManager',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'managerResponsibilities' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'stabilizationAgent' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'settlementAgent' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'logisticsAgent' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'leftLead' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'grossSpread' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'prospectusDocuments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'OfferingSetup_Prospectus_DocumentMetadata' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_Prospectus_DocumentMetadata' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProspectusDocument' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'documentName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'includeOnPublish' } },
          { kind: 'Field', name: { kind: 'Name', value: 'publicUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uploadedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstPublishedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_Summary_SyndicateManager' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Manager' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isJuniorRole' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isParticipating' } },
          { kind: 'Field', name: { kind: 'Name', value: 'economics' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_Summary_Disclaimers' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Disclaimers' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'restrictions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'footnotes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'registrationLinks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rule134Legend' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useOfferingSetup_Summary_OfferingSummaryQuery__
 *
 * To run a query within a React component, call `useOfferingSetup_Summary_OfferingSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfferingSetup_Summary_OfferingSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferingSetup_Summary_OfferingSummaryQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useOfferingSetup_Summary_OfferingSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    OfferingSetup_Summary_OfferingSummaryQuery,
    OfferingSetup_Summary_OfferingSummaryQueryVariables
  > &
    (
      | { variables: OfferingSetup_Summary_OfferingSummaryQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OfferingSetup_Summary_OfferingSummaryQuery,
    OfferingSetup_Summary_OfferingSummaryQueryVariables
  >(OfferingSetup_Summary_OfferingSummaryDocument, options);
}
export function useOfferingSetup_Summary_OfferingSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OfferingSetup_Summary_OfferingSummaryQuery,
    OfferingSetup_Summary_OfferingSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OfferingSetup_Summary_OfferingSummaryQuery,
    OfferingSetup_Summary_OfferingSummaryQueryVariables
  >(OfferingSetup_Summary_OfferingSummaryDocument, options);
}
export function useOfferingSetup_Summary_OfferingSummarySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        OfferingSetup_Summary_OfferingSummaryQuery,
        OfferingSetup_Summary_OfferingSummaryQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    OfferingSetup_Summary_OfferingSummaryQuery,
    OfferingSetup_Summary_OfferingSummaryQueryVariables
  >(OfferingSetup_Summary_OfferingSummaryDocument, options);
}
export type OfferingSetup_Summary_OfferingSummaryQueryHookResult = ReturnType<
  typeof useOfferingSetup_Summary_OfferingSummaryQuery
>;
export type OfferingSetup_Summary_OfferingSummaryLazyQueryHookResult = ReturnType<
  typeof useOfferingSetup_Summary_OfferingSummaryLazyQuery
>;
export type OfferingSetup_Summary_OfferingSummarySuspenseQueryHookResult = ReturnType<
  typeof useOfferingSetup_Summary_OfferingSummarySuspenseQuery
>;
export type OfferingSetup_Summary_OfferingSummaryQueryResult = Apollo.QueryResult<
  OfferingSetup_Summary_OfferingSummaryQuery,
  OfferingSetup_Summary_OfferingSummaryQueryVariables
>;
export const OfferinSetup_UnderwritingDiscountsFees_UpdateUnderwritingFeesAndDiscountsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {
        kind: 'Name',
        value: 'OfferinSetup_UnderwritingDiscountsFees_UpdateUnderwritingFeesAndDiscounts',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateUnderwritingFeesAndDiscountsInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUnderwritingFeesAndDiscounts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'filings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'OfferinSetup_UnderwritingDiscountsFees_Filing',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferinSetup_UnderwritingDiscountsFees_Filing' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Filing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'filingType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'initialRegistrationValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primarySharesBaseOffering' } },
          { kind: 'Field', name: { kind: 'Name', value: 'secondarySharesBaseOffering' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notionalOverAllotmentAuthorized' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primarySharesOverAllotmentAuthorized' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'secondarySharesOverAllotmentAuthorized' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'notionalOverAllotmentExercised' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primarySharesOverAllotmentExercised' } },
          { kind: 'Field', name: { kind: 'Name', value: 'secondarySharesOverAllotmentExercised' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipoRangeLow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipoRangeHigh' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offerPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postOfferingShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'useOfProceeds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lockUpPeriod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recordStatus' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type OfferinSetup_UnderwritingDiscountsFees_UpdateUnderwritingFeesAndDiscountsMutationFn =
  Apollo.MutationFunction<
    OfferinSetup_UnderwritingDiscountsFees_UpdateUnderwritingFeesAndDiscountsMutation,
    OfferinSetup_UnderwritingDiscountsFees_UpdateUnderwritingFeesAndDiscountsMutationVariables
  >;

/**
 * __useOfferinSetup_UnderwritingDiscountsFees_UpdateUnderwritingFeesAndDiscountsMutation__
 *
 * To run a mutation, you first call `useOfferinSetup_UnderwritingDiscountsFees_UpdateUnderwritingFeesAndDiscountsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOfferinSetup_UnderwritingDiscountsFees_UpdateUnderwritingFeesAndDiscountsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [offerinSetupUnderwritingDiscountsFeesUpdateUnderwritingFeesAndDiscountsMutation, { data, loading, error }] = useOfferinSetup_UnderwritingDiscountsFees_UpdateUnderwritingFeesAndDiscountsMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOfferinSetup_UnderwritingDiscountsFees_UpdateUnderwritingFeesAndDiscountsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OfferinSetup_UnderwritingDiscountsFees_UpdateUnderwritingFeesAndDiscountsMutation,
    OfferinSetup_UnderwritingDiscountsFees_UpdateUnderwritingFeesAndDiscountsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OfferinSetup_UnderwritingDiscountsFees_UpdateUnderwritingFeesAndDiscountsMutation,
    OfferinSetup_UnderwritingDiscountsFees_UpdateUnderwritingFeesAndDiscountsMutationVariables
  >(OfferinSetup_UnderwritingDiscountsFees_UpdateUnderwritingFeesAndDiscountsDocument, options);
}
export type OfferinSetup_UnderwritingDiscountsFees_UpdateUnderwritingFeesAndDiscountsMutationHookResult =
  ReturnType<
    typeof useOfferinSetup_UnderwritingDiscountsFees_UpdateUnderwritingFeesAndDiscountsMutation
  >;
export type OfferinSetup_UnderwritingDiscountsFees_UpdateUnderwritingFeesAndDiscountsMutationResult =
  Apollo.MutationResult<OfferinSetup_UnderwritingDiscountsFees_UpdateUnderwritingFeesAndDiscountsMutation>;
export type OfferinSetup_UnderwritingDiscountsFees_UpdateUnderwritingFeesAndDiscountsMutationOptions =
  Apollo.BaseMutationOptions<
    OfferinSetup_UnderwritingDiscountsFees_UpdateUnderwritingFeesAndDiscountsMutation,
    OfferinSetup_UnderwritingDiscountsFees_UpdateUnderwritingFeesAndDiscountsMutationVariables
  >;
export const OfferinSetup_UnderwritingDiscountsFees_UnderwritingFeesAndDiscountsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {
        kind: 'Name',
        value: 'OfferinSetup_UnderwritingDiscountsFees_UnderwritingFeesAndDiscounts',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getUnderwritingFeesAndDiscounts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'reallowance' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sharesExcludedFromGrossSpread' } },
                { kind: 'Field', name: { kind: 'Name', value: 'managementFee' } },
                { kind: 'Field', name: { kind: 'Name', value: 'underwritingFee' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sellingConcession' } },
                { kind: 'Field', name: { kind: 'Name', value: 'netPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'grossSpreadTotal' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'grossSpreadBaseData' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'grossSpreadBase' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'managementFeePercentage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'underwritingFeePercentage' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sellingConcessionPercentage' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'incentiveFeeData' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'incentiveFee' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'managementFeePercentage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'underwritingFeePercentage' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sellingConcessionPercentage' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useOfferinSetup_UnderwritingDiscountsFees_UnderwritingFeesAndDiscountsQuery__
 *
 * To run a query within a React component, call `useOfferinSetup_UnderwritingDiscountsFees_UnderwritingFeesAndDiscountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfferinSetup_UnderwritingDiscountsFees_UnderwritingFeesAndDiscountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferinSetup_UnderwritingDiscountsFees_UnderwritingFeesAndDiscountsQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useOfferinSetup_UnderwritingDiscountsFees_UnderwritingFeesAndDiscountsQuery(
  baseOptions: Apollo.QueryHookOptions<
    OfferinSetup_UnderwritingDiscountsFees_UnderwritingFeesAndDiscountsQuery,
    OfferinSetup_UnderwritingDiscountsFees_UnderwritingFeesAndDiscountsQueryVariables
  > &
    (
      | {
          variables: OfferinSetup_UnderwritingDiscountsFees_UnderwritingFeesAndDiscountsQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OfferinSetup_UnderwritingDiscountsFees_UnderwritingFeesAndDiscountsQuery,
    OfferinSetup_UnderwritingDiscountsFees_UnderwritingFeesAndDiscountsQueryVariables
  >(OfferinSetup_UnderwritingDiscountsFees_UnderwritingFeesAndDiscountsDocument, options);
}
export function useOfferinSetup_UnderwritingDiscountsFees_UnderwritingFeesAndDiscountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OfferinSetup_UnderwritingDiscountsFees_UnderwritingFeesAndDiscountsQuery,
    OfferinSetup_UnderwritingDiscountsFees_UnderwritingFeesAndDiscountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OfferinSetup_UnderwritingDiscountsFees_UnderwritingFeesAndDiscountsQuery,
    OfferinSetup_UnderwritingDiscountsFees_UnderwritingFeesAndDiscountsQueryVariables
  >(OfferinSetup_UnderwritingDiscountsFees_UnderwritingFeesAndDiscountsDocument, options);
}
export function useOfferinSetup_UnderwritingDiscountsFees_UnderwritingFeesAndDiscountsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        OfferinSetup_UnderwritingDiscountsFees_UnderwritingFeesAndDiscountsQuery,
        OfferinSetup_UnderwritingDiscountsFees_UnderwritingFeesAndDiscountsQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    OfferinSetup_UnderwritingDiscountsFees_UnderwritingFeesAndDiscountsQuery,
    OfferinSetup_UnderwritingDiscountsFees_UnderwritingFeesAndDiscountsQueryVariables
  >(OfferinSetup_UnderwritingDiscountsFees_UnderwritingFeesAndDiscountsDocument, options);
}
export type OfferinSetup_UnderwritingDiscountsFees_UnderwritingFeesAndDiscountsQueryHookResult =
  ReturnType<typeof useOfferinSetup_UnderwritingDiscountsFees_UnderwritingFeesAndDiscountsQuery>;
export type OfferinSetup_UnderwritingDiscountsFees_UnderwritingFeesAndDiscountsLazyQueryHookResult =
  ReturnType<
    typeof useOfferinSetup_UnderwritingDiscountsFees_UnderwritingFeesAndDiscountsLazyQuery
  >;
export type OfferinSetup_UnderwritingDiscountsFees_UnderwritingFeesAndDiscountsSuspenseQueryHookResult =
  ReturnType<
    typeof useOfferinSetup_UnderwritingDiscountsFees_UnderwritingFeesAndDiscountsSuspenseQuery
  >;
export type OfferinSetup_UnderwritingDiscountsFees_UnderwritingFeesAndDiscountsQueryResult =
  Apollo.QueryResult<
    OfferinSetup_UnderwritingDiscountsFees_UnderwritingFeesAndDiscountsQuery,
    OfferinSetup_UnderwritingDiscountsFees_UnderwritingFeesAndDiscountsQueryVariables
  >;
export const OfferinSetup_UnderwritingDiscountsFees_TermsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'OfferinSetup_UnderwritingDiscountsFees_Terms' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'offering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pricingCurrencyCode' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'filings' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filters' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'recordStatus' },
                            value: { kind: 'EnumValue', value: 'EFFECTIVE' },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'OfferinSetup_UnderwritingDiscountsFees_Filing',
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalSharesBaseOffering' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'followOnPricing' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'OfferinSetup_UnderwritingDiscountsFees_FollowOnPricing',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferinSetup_UnderwritingDiscountsFees_Filing' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Filing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'filingType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'initialRegistrationValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primarySharesBaseOffering' } },
          { kind: 'Field', name: { kind: 'Name', value: 'secondarySharesBaseOffering' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notionalOverAllotmentAuthorized' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primarySharesOverAllotmentAuthorized' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'secondarySharesOverAllotmentAuthorized' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'notionalOverAllotmentExercised' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primarySharesOverAllotmentExercised' } },
          { kind: 'Field', name: { kind: 'Name', value: 'secondarySharesOverAllotmentExercised' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipoRangeLow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipoRangeHigh' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offerPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postOfferingShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'useOfProceeds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lockUpPeriod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recordStatus' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferinSetup_UnderwritingDiscountsFees_FollowOnPricing' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FollowOnPricing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeFiling' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeLaunch' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeOffer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reOfferLow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reOfferHigh' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useOfferinSetup_UnderwritingDiscountsFees_TermsQuery__
 *
 * To run a query within a React component, call `useOfferinSetup_UnderwritingDiscountsFees_TermsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfferinSetup_UnderwritingDiscountsFees_TermsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferinSetup_UnderwritingDiscountsFees_TermsQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useOfferinSetup_UnderwritingDiscountsFees_TermsQuery(
  baseOptions: Apollo.QueryHookOptions<
    OfferinSetup_UnderwritingDiscountsFees_TermsQuery,
    OfferinSetup_UnderwritingDiscountsFees_TermsQueryVariables
  > &
    (
      | { variables: OfferinSetup_UnderwritingDiscountsFees_TermsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OfferinSetup_UnderwritingDiscountsFees_TermsQuery,
    OfferinSetup_UnderwritingDiscountsFees_TermsQueryVariables
  >(OfferinSetup_UnderwritingDiscountsFees_TermsDocument, options);
}
export function useOfferinSetup_UnderwritingDiscountsFees_TermsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OfferinSetup_UnderwritingDiscountsFees_TermsQuery,
    OfferinSetup_UnderwritingDiscountsFees_TermsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OfferinSetup_UnderwritingDiscountsFees_TermsQuery,
    OfferinSetup_UnderwritingDiscountsFees_TermsQueryVariables
  >(OfferinSetup_UnderwritingDiscountsFees_TermsDocument, options);
}
export function useOfferinSetup_UnderwritingDiscountsFees_TermsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        OfferinSetup_UnderwritingDiscountsFees_TermsQuery,
        OfferinSetup_UnderwritingDiscountsFees_TermsQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    OfferinSetup_UnderwritingDiscountsFees_TermsQuery,
    OfferinSetup_UnderwritingDiscountsFees_TermsQueryVariables
  >(OfferinSetup_UnderwritingDiscountsFees_TermsDocument, options);
}
export type OfferinSetup_UnderwritingDiscountsFees_TermsQueryHookResult = ReturnType<
  typeof useOfferinSetup_UnderwritingDiscountsFees_TermsQuery
>;
export type OfferinSetup_UnderwritingDiscountsFees_TermsLazyQueryHookResult = ReturnType<
  typeof useOfferinSetup_UnderwritingDiscountsFees_TermsLazyQuery
>;
export type OfferinSetup_UnderwritingDiscountsFees_TermsSuspenseQueryHookResult = ReturnType<
  typeof useOfferinSetup_UnderwritingDiscountsFees_TermsSuspenseQuery
>;
export type OfferinSetup_UnderwritingDiscountsFees_TermsQueryResult = Apollo.QueryResult<
  OfferinSetup_UnderwritingDiscountsFees_TermsQuery,
  OfferinSetup_UnderwritingDiscountsFees_TermsQueryVariables
>;
export const OfferingSetup_UnderwritingTerms_FilingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'OfferingSetup_UnderwritingTerms_Filings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'offering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pricingCurrencyCode' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'filings' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filters' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'recordStatus' },
                            value: { kind: 'EnumValue', value: 'EFFECTIVE' },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'filingType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'offerPrice' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'totalSharesOverAllotmentAuthorized' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'totalSharesOverAllotmentExercised' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useOfferingSetup_UnderwritingTerms_FilingsQuery__
 *
 * To run a query within a React component, call `useOfferingSetup_UnderwritingTerms_FilingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfferingSetup_UnderwritingTerms_FilingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferingSetup_UnderwritingTerms_FilingsQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useOfferingSetup_UnderwritingTerms_FilingsQuery(
  baseOptions: Apollo.QueryHookOptions<
    OfferingSetup_UnderwritingTerms_FilingsQuery,
    OfferingSetup_UnderwritingTerms_FilingsQueryVariables
  > &
    (
      | { variables: OfferingSetup_UnderwritingTerms_FilingsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OfferingSetup_UnderwritingTerms_FilingsQuery,
    OfferingSetup_UnderwritingTerms_FilingsQueryVariables
  >(OfferingSetup_UnderwritingTerms_FilingsDocument, options);
}
export function useOfferingSetup_UnderwritingTerms_FilingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OfferingSetup_UnderwritingTerms_FilingsQuery,
    OfferingSetup_UnderwritingTerms_FilingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OfferingSetup_UnderwritingTerms_FilingsQuery,
    OfferingSetup_UnderwritingTerms_FilingsQueryVariables
  >(OfferingSetup_UnderwritingTerms_FilingsDocument, options);
}
export function useOfferingSetup_UnderwritingTerms_FilingsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        OfferingSetup_UnderwritingTerms_FilingsQuery,
        OfferingSetup_UnderwritingTerms_FilingsQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    OfferingSetup_UnderwritingTerms_FilingsQuery,
    OfferingSetup_UnderwritingTerms_FilingsQueryVariables
  >(OfferingSetup_UnderwritingTerms_FilingsDocument, options);
}
export type OfferingSetup_UnderwritingTerms_FilingsQueryHookResult = ReturnType<
  typeof useOfferingSetup_UnderwritingTerms_FilingsQuery
>;
export type OfferingSetup_UnderwritingTerms_FilingsLazyQueryHookResult = ReturnType<
  typeof useOfferingSetup_UnderwritingTerms_FilingsLazyQuery
>;
export type OfferingSetup_UnderwritingTerms_FilingsSuspenseQueryHookResult = ReturnType<
  typeof useOfferingSetup_UnderwritingTerms_FilingsSuspenseQuery
>;
export type OfferingSetup_UnderwritingTerms_FilingsQueryResult = Apollo.QueryResult<
  OfferingSetup_UnderwritingTerms_FilingsQuery,
  OfferingSetup_UnderwritingTerms_FilingsQueryVariables
>;
export const OfferingSetup_UnderwritingTerms_UnderwritingFeesAndDiscountsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'OfferingSetup_UnderwritingTerms_UnderwritingFeesAndDiscounts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'underwritingTermsFeesAndDiscounts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'netPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sharesExcludedFromGrossSpread' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reallowance' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sellingConcession' } },
                { kind: 'Field', name: { kind: 'Name', value: 'managementFee' } },
                { kind: 'Field', name: { kind: 'Name', value: 'underwritingFee' } },
                { kind: 'Field', name: { kind: 'Name', value: 'grossSpreadTotal' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'incentiveFeeData' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'incentiveFee' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'incentiveFeePercentage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'managementFee' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'managementFeePercentage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'underwritingFee' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'underwritingFeePercentage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sellingConcession' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sellingConcessionPercentage' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'grossSpreadBaseData' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'managementFee' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'managementFeePercentage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'underwritingFee' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'underwritingFeePercentage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sellingConcession' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sellingConcessionPercentage' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'grossSpreadBase' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'grossSpreadBasePercentage' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useOfferingSetup_UnderwritingTerms_UnderwritingFeesAndDiscountsQuery__
 *
 * To run a query within a React component, call `useOfferingSetup_UnderwritingTerms_UnderwritingFeesAndDiscountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfferingSetup_UnderwritingTerms_UnderwritingFeesAndDiscountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferingSetup_UnderwritingTerms_UnderwritingFeesAndDiscountsQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useOfferingSetup_UnderwritingTerms_UnderwritingFeesAndDiscountsQuery(
  baseOptions: Apollo.QueryHookOptions<
    OfferingSetup_UnderwritingTerms_UnderwritingFeesAndDiscountsQuery,
    OfferingSetup_UnderwritingTerms_UnderwritingFeesAndDiscountsQueryVariables
  > &
    (
      | {
          variables: OfferingSetup_UnderwritingTerms_UnderwritingFeesAndDiscountsQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OfferingSetup_UnderwritingTerms_UnderwritingFeesAndDiscountsQuery,
    OfferingSetup_UnderwritingTerms_UnderwritingFeesAndDiscountsQueryVariables
  >(OfferingSetup_UnderwritingTerms_UnderwritingFeesAndDiscountsDocument, options);
}
export function useOfferingSetup_UnderwritingTerms_UnderwritingFeesAndDiscountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OfferingSetup_UnderwritingTerms_UnderwritingFeesAndDiscountsQuery,
    OfferingSetup_UnderwritingTerms_UnderwritingFeesAndDiscountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OfferingSetup_UnderwritingTerms_UnderwritingFeesAndDiscountsQuery,
    OfferingSetup_UnderwritingTerms_UnderwritingFeesAndDiscountsQueryVariables
  >(OfferingSetup_UnderwritingTerms_UnderwritingFeesAndDiscountsDocument, options);
}
export function useOfferingSetup_UnderwritingTerms_UnderwritingFeesAndDiscountsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        OfferingSetup_UnderwritingTerms_UnderwritingFeesAndDiscountsQuery,
        OfferingSetup_UnderwritingTerms_UnderwritingFeesAndDiscountsQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    OfferingSetup_UnderwritingTerms_UnderwritingFeesAndDiscountsQuery,
    OfferingSetup_UnderwritingTerms_UnderwritingFeesAndDiscountsQueryVariables
  >(OfferingSetup_UnderwritingTerms_UnderwritingFeesAndDiscountsDocument, options);
}
export type OfferingSetup_UnderwritingTerms_UnderwritingFeesAndDiscountsQueryHookResult =
  ReturnType<typeof useOfferingSetup_UnderwritingTerms_UnderwritingFeesAndDiscountsQuery>;
export type OfferingSetup_UnderwritingTerms_UnderwritingFeesAndDiscountsLazyQueryHookResult =
  ReturnType<typeof useOfferingSetup_UnderwritingTerms_UnderwritingFeesAndDiscountsLazyQuery>;
export type OfferingSetup_UnderwritingTerms_UnderwritingFeesAndDiscountsSuspenseQueryHookResult =
  ReturnType<typeof useOfferingSetup_UnderwritingTerms_UnderwritingFeesAndDiscountsSuspenseQuery>;
export type OfferingSetup_UnderwritingTerms_UnderwritingFeesAndDiscountsQueryResult =
  Apollo.QueryResult<
    OfferingSetup_UnderwritingTerms_UnderwritingFeesAndDiscountsQuery,
    OfferingSetup_UnderwritingTerms_UnderwritingFeesAndDiscountsQueryVariables
  >;
export const OfferingSetup_UnderwritingTerms_UpdateUnderwritingFeesAndDiscountsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {
        kind: 'Name',
        value: 'OfferingSetup_UnderwritingTerms_UpdateUnderwritingFeesAndDiscounts',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateUnderwritingTermsFeesAndDiscountsInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUnderwritingTermsFeesAndDiscounts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'underwritingTermsFeesAndDiscounts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'netPrice' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sharesExcludedFromGrossSpread' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'reallowance' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sellingConcession' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'managementFee' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'underwritingFee' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'grossSpreadTotal' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'incentiveFeeData' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'incentiveFee' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'incentiveFeePercentage' },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'managementFee' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'managementFeePercentage' },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'underwritingFee' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'underwritingFeePercentage' },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'sellingConcession' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sellingConcessionPercentage' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'grossSpreadBaseData' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'managementFee' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'managementFeePercentage' },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'underwritingFee' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'underwritingFeePercentage' },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'sellingConcession' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sellingConcessionPercentage' },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'grossSpreadBase' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'grossSpreadBasePercentage' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type OfferingSetup_UnderwritingTerms_UpdateUnderwritingFeesAndDiscountsMutationFn =
  Apollo.MutationFunction<
    OfferingSetup_UnderwritingTerms_UpdateUnderwritingFeesAndDiscountsMutation,
    OfferingSetup_UnderwritingTerms_UpdateUnderwritingFeesAndDiscountsMutationVariables
  >;

/**
 * __useOfferingSetup_UnderwritingTerms_UpdateUnderwritingFeesAndDiscountsMutation__
 *
 * To run a mutation, you first call `useOfferingSetup_UnderwritingTerms_UpdateUnderwritingFeesAndDiscountsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOfferingSetup_UnderwritingTerms_UpdateUnderwritingFeesAndDiscountsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [offeringSetupUnderwritingTermsUpdateUnderwritingFeesAndDiscountsMutation, { data, loading, error }] = useOfferingSetup_UnderwritingTerms_UpdateUnderwritingFeesAndDiscountsMutation({
 *   variables: {
 *      input: // value for 'input'
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useOfferingSetup_UnderwritingTerms_UpdateUnderwritingFeesAndDiscountsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OfferingSetup_UnderwritingTerms_UpdateUnderwritingFeesAndDiscountsMutation,
    OfferingSetup_UnderwritingTerms_UpdateUnderwritingFeesAndDiscountsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OfferingSetup_UnderwritingTerms_UpdateUnderwritingFeesAndDiscountsMutation,
    OfferingSetup_UnderwritingTerms_UpdateUnderwritingFeesAndDiscountsMutationVariables
  >(OfferingSetup_UnderwritingTerms_UpdateUnderwritingFeesAndDiscountsDocument, options);
}
export type OfferingSetup_UnderwritingTerms_UpdateUnderwritingFeesAndDiscountsMutationHookResult =
  ReturnType<typeof useOfferingSetup_UnderwritingTerms_UpdateUnderwritingFeesAndDiscountsMutation>;
export type OfferingSetup_UnderwritingTerms_UpdateUnderwritingFeesAndDiscountsMutationResult =
  Apollo.MutationResult<OfferingSetup_UnderwritingTerms_UpdateUnderwritingFeesAndDiscountsMutation>;
export type OfferingSetup_UnderwritingTerms_UpdateUnderwritingFeesAndDiscountsMutationOptions =
  Apollo.BaseMutationOptions<
    OfferingSetup_UnderwritingTerms_UpdateUnderwritingFeesAndDiscountsMutation,
    OfferingSetup_UnderwritingTerms_UpdateUnderwritingFeesAndDiscountsMutationVariables
  >;
export const OfferingSetup_UnderwritingTerms_SyndicateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'OfferingSetup_UnderwritingTerms_Syndicate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'offering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isAuthor' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'syndicate' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filters' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'recordStatus' },
                            value: { kind: 'EnumValue', value: 'EFFECTIVE' },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'grossSpread' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'managers' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'OfferingSetup_UnderwritingTerms_Manager',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'managerResponsibilities' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'OfferingSetup_UnderwritingTerms_ManagerResponsibilities',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'managerEconomics' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'OfferingSetup_UnderwritingTerms_ManagerEconomics',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_UnderwritingTerms_Manager' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Manager' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isJuniorRole' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isParticipating' } },
          { kind: 'Field', name: { kind: 'Name', value: 'economics' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recordStatus' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_UnderwritingTerms_ManagerResponsibilities' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ManagerResponsibilities' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stabilizationAgent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'settlementAgent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logisticsAgent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'leftLead' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_UnderwritingTerms_PercentagePerCmgEntity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PercentagePerCmgEntity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'percentageValue' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_UnderwritingTerms_SharesPerCmgEntity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SharesPerCmgEntity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharesValue' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_UnderwritingTerms_ManagerEconomics' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ManagerEconomics' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'incentiveFeeDistribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'OfferingSetup_UnderwritingTerms_PercentagePerCmgEntity',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'managementFeeDistribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'OfferingSetup_UnderwritingTerms_PercentagePerCmgEntity',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sellingConcessionDistribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'OfferingSetup_UnderwritingTerms_PercentagePerCmgEntity',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'underwritingFeeTotalDistribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'OfferingSetup_UnderwritingTerms_PercentagePerCmgEntity',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'underwritingFeeBaseDistribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'OfferingSetup_UnderwritingTerms_PercentagePerCmgEntity',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'underwritingBaseSharesDistribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'OfferingSetup_UnderwritingTerms_SharesPerCmgEntity',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'overallotmentExercisedSharesDistribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'OfferingSetup_UnderwritingTerms_SharesPerCmgEntity',
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useOfferingSetup_UnderwritingTerms_SyndicateQuery__
 *
 * To run a query within a React component, call `useOfferingSetup_UnderwritingTerms_SyndicateQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfferingSetup_UnderwritingTerms_SyndicateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferingSetup_UnderwritingTerms_SyndicateQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useOfferingSetup_UnderwritingTerms_SyndicateQuery(
  baseOptions: Apollo.QueryHookOptions<
    OfferingSetup_UnderwritingTerms_SyndicateQuery,
    OfferingSetup_UnderwritingTerms_SyndicateQueryVariables
  > &
    (
      | { variables: OfferingSetup_UnderwritingTerms_SyndicateQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OfferingSetup_UnderwritingTerms_SyndicateQuery,
    OfferingSetup_UnderwritingTerms_SyndicateQueryVariables
  >(OfferingSetup_UnderwritingTerms_SyndicateDocument, options);
}
export function useOfferingSetup_UnderwritingTerms_SyndicateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OfferingSetup_UnderwritingTerms_SyndicateQuery,
    OfferingSetup_UnderwritingTerms_SyndicateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OfferingSetup_UnderwritingTerms_SyndicateQuery,
    OfferingSetup_UnderwritingTerms_SyndicateQueryVariables
  >(OfferingSetup_UnderwritingTerms_SyndicateDocument, options);
}
export function useOfferingSetup_UnderwritingTerms_SyndicateSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        OfferingSetup_UnderwritingTerms_SyndicateQuery,
        OfferingSetup_UnderwritingTerms_SyndicateQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    OfferingSetup_UnderwritingTerms_SyndicateQuery,
    OfferingSetup_UnderwritingTerms_SyndicateQueryVariables
  >(OfferingSetup_UnderwritingTerms_SyndicateDocument, options);
}
export type OfferingSetup_UnderwritingTerms_SyndicateQueryHookResult = ReturnType<
  typeof useOfferingSetup_UnderwritingTerms_SyndicateQuery
>;
export type OfferingSetup_UnderwritingTerms_SyndicateLazyQueryHookResult = ReturnType<
  typeof useOfferingSetup_UnderwritingTerms_SyndicateLazyQuery
>;
export type OfferingSetup_UnderwritingTerms_SyndicateSuspenseQueryHookResult = ReturnType<
  typeof useOfferingSetup_UnderwritingTerms_SyndicateSuspenseQuery
>;
export type OfferingSetup_UnderwritingTerms_SyndicateQueryResult = Apollo.QueryResult<
  OfferingSetup_UnderwritingTerms_SyndicateQuery,
  OfferingSetup_UnderwritingTerms_SyndicateQueryVariables
>;
export const OfferingSetup_UnderwritingTerms_CalculateFeesDistributionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'OfferingSetup_UnderwritingTerms_CalculateFeesDistributions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CalculateFeesDistributionsInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'calculateFeesDistributions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'managementFeeDistribution' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'OfferingSetup_UnderwritingTerms_PercentagePerCmgEntity',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'underwritingFeeTotalDistribution' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'OfferingSetup_UnderwritingTerms_PercentagePerCmgEntity',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sellingConcessionDistribution' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'OfferingSetup_UnderwritingTerms_PercentagePerCmgEntity',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_UnderwritingTerms_PercentagePerCmgEntity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PercentagePerCmgEntity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'percentageValue' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type OfferingSetup_UnderwritingTerms_CalculateFeesDistributionsMutationFn =
  Apollo.MutationFunction<
    OfferingSetup_UnderwritingTerms_CalculateFeesDistributionsMutation,
    OfferingSetup_UnderwritingTerms_CalculateFeesDistributionsMutationVariables
  >;

/**
 * __useOfferingSetup_UnderwritingTerms_CalculateFeesDistributionsMutation__
 *
 * To run a mutation, you first call `useOfferingSetup_UnderwritingTerms_CalculateFeesDistributionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOfferingSetup_UnderwritingTerms_CalculateFeesDistributionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [offeringSetupUnderwritingTermsCalculateFeesDistributionsMutation, { data, loading, error }] = useOfferingSetup_UnderwritingTerms_CalculateFeesDistributionsMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOfferingSetup_UnderwritingTerms_CalculateFeesDistributionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OfferingSetup_UnderwritingTerms_CalculateFeesDistributionsMutation,
    OfferingSetup_UnderwritingTerms_CalculateFeesDistributionsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OfferingSetup_UnderwritingTerms_CalculateFeesDistributionsMutation,
    OfferingSetup_UnderwritingTerms_CalculateFeesDistributionsMutationVariables
  >(OfferingSetup_UnderwritingTerms_CalculateFeesDistributionsDocument, options);
}
export type OfferingSetup_UnderwritingTerms_CalculateFeesDistributionsMutationHookResult =
  ReturnType<typeof useOfferingSetup_UnderwritingTerms_CalculateFeesDistributionsMutation>;
export type OfferingSetup_UnderwritingTerms_CalculateFeesDistributionsMutationResult =
  Apollo.MutationResult<OfferingSetup_UnderwritingTerms_CalculateFeesDistributionsMutation>;
export type OfferingSetup_UnderwritingTerms_CalculateFeesDistributionsMutationOptions =
  Apollo.BaseMutationOptions<
    OfferingSetup_UnderwritingTerms_CalculateFeesDistributionsMutation,
    OfferingSetup_UnderwritingTerms_CalculateFeesDistributionsMutationVariables
  >;
export const OfferingSetup_UnderwritingTerms_CalculateSharesDistributionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'OfferingSetup_UnderwritingTerms_CalculateSharesDistributions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CalculateSharesDistributionsInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'calculateSharesDistributions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'underwritingBaseShareDistribution' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'OfferingSetup_UnderwritingTerms_SharesPerCmgEntity',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'overallotmentExercisedShareDistribution' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'OfferingSetup_UnderwritingTerms_SharesPerCmgEntity',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'underwritingTotalSharesDistribution' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'OfferingSetup_UnderwritingTerms_SharesPerCmgEntity',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_UnderwritingTerms_SharesPerCmgEntity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SharesPerCmgEntity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharesValue' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type OfferingSetup_UnderwritingTerms_CalculateSharesDistributionsMutationFn =
  Apollo.MutationFunction<
    OfferingSetup_UnderwritingTerms_CalculateSharesDistributionsMutation,
    OfferingSetup_UnderwritingTerms_CalculateSharesDistributionsMutationVariables
  >;

/**
 * __useOfferingSetup_UnderwritingTerms_CalculateSharesDistributionsMutation__
 *
 * To run a mutation, you first call `useOfferingSetup_UnderwritingTerms_CalculateSharesDistributionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOfferingSetup_UnderwritingTerms_CalculateSharesDistributionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [offeringSetupUnderwritingTermsCalculateSharesDistributionsMutation, { data, loading, error }] = useOfferingSetup_UnderwritingTerms_CalculateSharesDistributionsMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOfferingSetup_UnderwritingTerms_CalculateSharesDistributionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OfferingSetup_UnderwritingTerms_CalculateSharesDistributionsMutation,
    OfferingSetup_UnderwritingTerms_CalculateSharesDistributionsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OfferingSetup_UnderwritingTerms_CalculateSharesDistributionsMutation,
    OfferingSetup_UnderwritingTerms_CalculateSharesDistributionsMutationVariables
  >(OfferingSetup_UnderwritingTerms_CalculateSharesDistributionsDocument, options);
}
export type OfferingSetup_UnderwritingTerms_CalculateSharesDistributionsMutationHookResult =
  ReturnType<typeof useOfferingSetup_UnderwritingTerms_CalculateSharesDistributionsMutation>;
export type OfferingSetup_UnderwritingTerms_CalculateSharesDistributionsMutationResult =
  Apollo.MutationResult<OfferingSetup_UnderwritingTerms_CalculateSharesDistributionsMutation>;
export type OfferingSetup_UnderwritingTerms_CalculateSharesDistributionsMutationOptions =
  Apollo.BaseMutationOptions<
    OfferingSetup_UnderwritingTerms_CalculateSharesDistributionsMutation,
    OfferingSetup_UnderwritingTerms_CalculateSharesDistributionsMutationVariables
  >;
export const OfferingSetup_UnderwritingTerms_UpdateManagerEconomicsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'OfferingSetup_UnderwritingTerms_UpdateManagerEconomics' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'incentiveFeeDistribution' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'PercentagePerCmgEntityInput' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'underwritingFeeBaseDistribution' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'PercentagePerCmgEntityInput' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sellingConcessionDistribution' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'PercentagePerCmgEntityInput' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'managementFeeDistribution' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'PercentagePerCmgEntityInput' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'underwritingFeeTotalDistribution' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'PercentagePerCmgEntityInput' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'overallotmentExercisedSharesDistribution' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'SharesPerCmgEntityInput' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'underwritingBaseSharesDistribution' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'SharesPerCmgEntityInput' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateIncentiveFeeDistribution' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'incentiveFeeDistribution' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'OfferingSetup_UnderwritingTerms_PercentagePerCmgEntity',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUnderwritingFeeBaseDistribution' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'underwritingFeeBaseDistribution' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'OfferingSetup_UnderwritingTerms_PercentagePerCmgEntity',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSellingConcessionDistribution' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sellingConcessionDistribution' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'OfferingSetup_UnderwritingTerms_PercentagePerCmgEntity',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateManagementFeeDistribution' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'managementFeeDistribution' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'OfferingSetup_UnderwritingTerms_PercentagePerCmgEntity',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUnderwritingFeeTotalDistribution' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'underwritingFeeTotalDistribution' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'OfferingSetup_UnderwritingTerms_PercentagePerCmgEntity',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateOverallotmentExercisedSharesDistribution' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'overallotmentExercisedSharesDistribution' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'OfferingSetup_UnderwritingTerms_SharesPerCmgEntity',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUnderwritingBaseSharesDistribution' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'underwritingBaseSharesDistribution' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'OfferingSetup_UnderwritingTerms_SharesPerCmgEntity',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_UnderwritingTerms_PercentagePerCmgEntity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PercentagePerCmgEntity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'percentageValue' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_UnderwritingTerms_SharesPerCmgEntity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SharesPerCmgEntity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharesValue' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type OfferingSetup_UnderwritingTerms_UpdateManagerEconomicsMutationFn =
  Apollo.MutationFunction<
    OfferingSetup_UnderwritingTerms_UpdateManagerEconomicsMutation,
    OfferingSetup_UnderwritingTerms_UpdateManagerEconomicsMutationVariables
  >;

/**
 * __useOfferingSetup_UnderwritingTerms_UpdateManagerEconomicsMutation__
 *
 * To run a mutation, you first call `useOfferingSetup_UnderwritingTerms_UpdateManagerEconomicsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOfferingSetup_UnderwritingTerms_UpdateManagerEconomicsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [offeringSetupUnderwritingTermsUpdateManagerEconomicsMutation, { data, loading, error }] = useOfferingSetup_UnderwritingTerms_UpdateManagerEconomicsMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      incentiveFeeDistribution: // value for 'incentiveFeeDistribution'
 *      underwritingFeeBaseDistribution: // value for 'underwritingFeeBaseDistribution'
 *      sellingConcessionDistribution: // value for 'sellingConcessionDistribution'
 *      managementFeeDistribution: // value for 'managementFeeDistribution'
 *      underwritingFeeTotalDistribution: // value for 'underwritingFeeTotalDistribution'
 *      overallotmentExercisedSharesDistribution: // value for 'overallotmentExercisedSharesDistribution'
 *      underwritingBaseSharesDistribution: // value for 'underwritingBaseSharesDistribution'
 *   },
 * });
 */
export function useOfferingSetup_UnderwritingTerms_UpdateManagerEconomicsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OfferingSetup_UnderwritingTerms_UpdateManagerEconomicsMutation,
    OfferingSetup_UnderwritingTerms_UpdateManagerEconomicsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OfferingSetup_UnderwritingTerms_UpdateManagerEconomicsMutation,
    OfferingSetup_UnderwritingTerms_UpdateManagerEconomicsMutationVariables
  >(OfferingSetup_UnderwritingTerms_UpdateManagerEconomicsDocument, options);
}
export type OfferingSetup_UnderwritingTerms_UpdateManagerEconomicsMutationHookResult = ReturnType<
  typeof useOfferingSetup_UnderwritingTerms_UpdateManagerEconomicsMutation
>;
export type OfferingSetup_UnderwritingTerms_UpdateManagerEconomicsMutationResult =
  Apollo.MutationResult<OfferingSetup_UnderwritingTerms_UpdateManagerEconomicsMutation>;
export type OfferingSetup_UnderwritingTerms_UpdateManagerEconomicsMutationOptions =
  Apollo.BaseMutationOptions<
    OfferingSetup_UnderwritingTerms_UpdateManagerEconomicsMutation,
    OfferingSetup_UnderwritingTerms_UpdateManagerEconomicsMutationVariables
  >;
export const OfferingSetup_UnderwritingTerms_Economics_FilingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'OfferingSetup_UnderwritingTerms_Economics_Filings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getFilings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'filingType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'initialRegistrationValue' } },
                { kind: 'Field', name: { kind: 'Name', value: 'primarySharesBaseOffering' } },
                { kind: 'Field', name: { kind: 'Name', value: 'secondarySharesBaseOffering' } },
                { kind: 'Field', name: { kind: 'Name', value: 'notionalOverAllotmentAuthorized' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'primarySharesOverAllotmentAuthorized' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'notionalOverAllotmentExercised' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'primarySharesOverAllotmentExercised' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'totalSharesOverAllotmentAuthorized' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'totalSharesOverAllotmentExercised' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalSharesBaseOffering' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ipoRangeLow' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ipoRangeHigh' } },
                { kind: 'Field', name: { kind: 'Name', value: 'offerPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postOfferingShares' } },
                { kind: 'Field', name: { kind: 'Name', value: 'useOfProceeds' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lockUpPeriod' } },
                { kind: 'Field', name: { kind: 'Name', value: 'recordStatus' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useOfferingSetup_UnderwritingTerms_Economics_FilingsQuery__
 *
 * To run a query within a React component, call `useOfferingSetup_UnderwritingTerms_Economics_FilingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfferingSetup_UnderwritingTerms_Economics_FilingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferingSetup_UnderwritingTerms_Economics_FilingsQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useOfferingSetup_UnderwritingTerms_Economics_FilingsQuery(
  baseOptions: Apollo.QueryHookOptions<
    OfferingSetup_UnderwritingTerms_Economics_FilingsQuery,
    OfferingSetup_UnderwritingTerms_Economics_FilingsQueryVariables
  > &
    (
      | {
          variables: OfferingSetup_UnderwritingTerms_Economics_FilingsQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OfferingSetup_UnderwritingTerms_Economics_FilingsQuery,
    OfferingSetup_UnderwritingTerms_Economics_FilingsQueryVariables
  >(OfferingSetup_UnderwritingTerms_Economics_FilingsDocument, options);
}
export function useOfferingSetup_UnderwritingTerms_Economics_FilingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OfferingSetup_UnderwritingTerms_Economics_FilingsQuery,
    OfferingSetup_UnderwritingTerms_Economics_FilingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OfferingSetup_UnderwritingTerms_Economics_FilingsQuery,
    OfferingSetup_UnderwritingTerms_Economics_FilingsQueryVariables
  >(OfferingSetup_UnderwritingTerms_Economics_FilingsDocument, options);
}
export function useOfferingSetup_UnderwritingTerms_Economics_FilingsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        OfferingSetup_UnderwritingTerms_Economics_FilingsQuery,
        OfferingSetup_UnderwritingTerms_Economics_FilingsQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    OfferingSetup_UnderwritingTerms_Economics_FilingsQuery,
    OfferingSetup_UnderwritingTerms_Economics_FilingsQueryVariables
  >(OfferingSetup_UnderwritingTerms_Economics_FilingsDocument, options);
}
export type OfferingSetup_UnderwritingTerms_Economics_FilingsQueryHookResult = ReturnType<
  typeof useOfferingSetup_UnderwritingTerms_Economics_FilingsQuery
>;
export type OfferingSetup_UnderwritingTerms_Economics_FilingsLazyQueryHookResult = ReturnType<
  typeof useOfferingSetup_UnderwritingTerms_Economics_FilingsLazyQuery
>;
export type OfferingSetup_UnderwritingTerms_Economics_FilingsSuspenseQueryHookResult = ReturnType<
  typeof useOfferingSetup_UnderwritingTerms_Economics_FilingsSuspenseQuery
>;
export type OfferingSetup_UnderwritingTerms_Economics_FilingsQueryResult = Apollo.QueryResult<
  OfferingSetup_UnderwritingTerms_Economics_FilingsQuery,
  OfferingSetup_UnderwritingTerms_Economics_FilingsQueryVariables
>;
export const OfferingSetup_Validation_OfferingValidationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'OfferingSetup_Validation_OfferingValidation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'offering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'validation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'errors' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'OfferingSetup_Validation_OfferingError',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_Validation_OfferingError' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'OfferingError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useOfferingSetup_Validation_OfferingValidationQuery__
 *
 * To run a query within a React component, call `useOfferingSetup_Validation_OfferingValidationQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfferingSetup_Validation_OfferingValidationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferingSetup_Validation_OfferingValidationQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useOfferingSetup_Validation_OfferingValidationQuery(
  baseOptions: Apollo.QueryHookOptions<
    OfferingSetup_Validation_OfferingValidationQuery,
    OfferingSetup_Validation_OfferingValidationQueryVariables
  > &
    (
      | { variables: OfferingSetup_Validation_OfferingValidationQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OfferingSetup_Validation_OfferingValidationQuery,
    OfferingSetup_Validation_OfferingValidationQueryVariables
  >(OfferingSetup_Validation_OfferingValidationDocument, options);
}
export function useOfferingSetup_Validation_OfferingValidationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OfferingSetup_Validation_OfferingValidationQuery,
    OfferingSetup_Validation_OfferingValidationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OfferingSetup_Validation_OfferingValidationQuery,
    OfferingSetup_Validation_OfferingValidationQueryVariables
  >(OfferingSetup_Validation_OfferingValidationDocument, options);
}
export function useOfferingSetup_Validation_OfferingValidationSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        OfferingSetup_Validation_OfferingValidationQuery,
        OfferingSetup_Validation_OfferingValidationQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    OfferingSetup_Validation_OfferingValidationQuery,
    OfferingSetup_Validation_OfferingValidationQueryVariables
  >(OfferingSetup_Validation_OfferingValidationDocument, options);
}
export type OfferingSetup_Validation_OfferingValidationQueryHookResult = ReturnType<
  typeof useOfferingSetup_Validation_OfferingValidationQuery
>;
export type OfferingSetup_Validation_OfferingValidationLazyQueryHookResult = ReturnType<
  typeof useOfferingSetup_Validation_OfferingValidationLazyQuery
>;
export type OfferingSetup_Validation_OfferingValidationSuspenseQueryHookResult = ReturnType<
  typeof useOfferingSetup_Validation_OfferingValidationSuspenseQuery
>;
export type OfferingSetup_Validation_OfferingValidationQueryResult = Apollo.QueryResult<
  OfferingSetup_Validation_OfferingValidationQuery,
  OfferingSetup_Validation_OfferingValidationQueryVariables
>;

import { makeMockResponse } from '../../../../graphql/mock-response';

export const makeOfferingSetup_BasicInfoRoute_BasicInfoMockResponse = makeMockResponse<
  OfferingSetup_BasicInfoRoute_BasicInfoQueryVariables,
  OfferingSetup_BasicInfoRoute_BasicInfoQuery
>(OfferingSetup_BasicInfoRoute_BasicInfoDocument);

export const makeOfferingSetup_BasicInfoRoute_PublishedOfferingMockResponse = makeMockResponse<
  OfferingSetup_BasicInfoRoute_PublishedOfferingQueryVariables,
  OfferingSetup_BasicInfoRoute_PublishedOfferingQuery
>(OfferingSetup_BasicInfoRoute_PublishedOfferingDocument);

export const makeOfferingSetup_Components_SearchUnderwritersMockResponse = makeMockResponse<
  OfferingSetup_Components_SearchUnderwritersQueryVariables,
  OfferingSetup_Components_SearchUnderwritersQuery
>(OfferingSetup_Components_SearchUnderwritersDocument);

export const makeOfferingSetup_HeaderMockResponse = makeMockResponse<
  OfferingSetup_HeaderQueryVariables,
  OfferingSetup_HeaderQuery
>(OfferingSetup_HeaderDocument);

export const makeOfferingSetup_InProgressOfferingMockResponse = makeMockResponse<
  OfferingSetup_InProgressOfferingQueryVariables,
  OfferingSetup_InProgressOfferingQuery
>(OfferingSetup_InProgressOfferingDocument);

export const makeOfferingSetup_ManagerEconomics_ManagerEconomicsMockResponse = makeMockResponse<
  OfferingSetup_ManagerEconomics_ManagerEconomicsQueryVariables,
  OfferingSetup_ManagerEconomics_ManagerEconomicsQuery
>(OfferingSetup_ManagerEconomics_ManagerEconomicsDocument);

export const makeOfferingSetup_ManagerEconomics_UnderwritingFeesAndDiscountsMockResponse =
  makeMockResponse<
    OfferingSetup_ManagerEconomics_UnderwritingFeesAndDiscountsQueryVariables,
    OfferingSetup_ManagerEconomics_UnderwritingFeesAndDiscountsQuery
  >(OfferingSetup_ManagerEconomics_UnderwritingFeesAndDiscountsDocument);

export const makeOfferingSetup_ManagerEconomics_SyndicateMockResponse = makeMockResponse<
  OfferingSetup_ManagerEconomics_SyndicateQueryVariables,
  OfferingSetup_ManagerEconomics_SyndicateQuery
>(OfferingSetup_ManagerEconomics_SyndicateDocument);

export const makeOfferingSetup_ManagerEconomics_FilingsMockResponse = makeMockResponse<
  OfferingSetup_ManagerEconomics_FilingsQueryVariables,
  OfferingSetup_ManagerEconomics_FilingsQuery
>(OfferingSetup_ManagerEconomics_FilingsDocument);

export const makeOfferingSetup_Prospectus_PublishedOfferingVersionMockResponse = makeMockResponse<
  OfferingSetup_Prospectus_PublishedOfferingVersionQueryVariables,
  OfferingSetup_Prospectus_PublishedOfferingVersionQuery
>(OfferingSetup_Prospectus_PublishedOfferingVersionDocument);

export const makeOfferingSetup_Prospectus_ProspectusDocumentsMockResponse = makeMockResponse<
  OfferingSetup_Prospectus_ProspectusDocumentsQueryVariables,
  OfferingSetup_Prospectus_ProspectusDocumentsQuery
>(OfferingSetup_Prospectus_ProspectusDocumentsDocument);

export const makeOfferingSetup_PublishedHeaderMockResponse = makeMockResponse<
  OfferingSetup_PublishedHeaderQueryVariables,
  OfferingSetup_PublishedHeaderQuery
>(OfferingSetup_PublishedHeaderDocument);

export const makeOfferingSetup_Summary_AvailableShareDraftManagersMockResponse = makeMockResponse<
  OfferingSetup_Summary_AvailableShareDraftManagersQueryVariables,
  OfferingSetup_Summary_AvailableShareDraftManagersQuery
>(OfferingSetup_Summary_AvailableShareDraftManagersDocument);

export const makeOfferingSetup_Summary_PublishedOfferingStatusMockResponse = makeMockResponse<
  OfferingSetup_Summary_PublishedOfferingStatusQueryVariables,
  OfferingSetup_Summary_PublishedOfferingStatusQuery
>(OfferingSetup_Summary_PublishedOfferingStatusDocument);

export const makeOfferingSetup_Summary_OfferingShareHistoryMockResponse = makeMockResponse<
  OfferingSetup_Summary_OfferingShareHistoryQueryVariables,
  OfferingSetup_Summary_OfferingShareHistoryQuery
>(OfferingSetup_Summary_OfferingShareHistoryDocument);

export const makeOfferingSetup_Summary_OfferingSummaryMockResponse = makeMockResponse<
  OfferingSetup_Summary_OfferingSummaryQueryVariables,
  OfferingSetup_Summary_OfferingSummaryQuery
>(OfferingSetup_Summary_OfferingSummaryDocument);

export const makeOfferinSetup_UnderwritingDiscountsFees_UnderwritingFeesAndDiscountsMockResponse =
  makeMockResponse<
    OfferinSetup_UnderwritingDiscountsFees_UnderwritingFeesAndDiscountsQueryVariables,
    OfferinSetup_UnderwritingDiscountsFees_UnderwritingFeesAndDiscountsQuery
  >(OfferinSetup_UnderwritingDiscountsFees_UnderwritingFeesAndDiscountsDocument);

export const makeOfferinSetup_UnderwritingDiscountsFees_TermsMockResponse = makeMockResponse<
  OfferinSetup_UnderwritingDiscountsFees_TermsQueryVariables,
  OfferinSetup_UnderwritingDiscountsFees_TermsQuery
>(OfferinSetup_UnderwritingDiscountsFees_TermsDocument);

export const makeOfferingSetup_UnderwritingTerms_FilingsMockResponse = makeMockResponse<
  OfferingSetup_UnderwritingTerms_FilingsQueryVariables,
  OfferingSetup_UnderwritingTerms_FilingsQuery
>(OfferingSetup_UnderwritingTerms_FilingsDocument);

export const makeOfferingSetup_UnderwritingTerms_UnderwritingFeesAndDiscountsMockResponse =
  makeMockResponse<
    OfferingSetup_UnderwritingTerms_UnderwritingFeesAndDiscountsQueryVariables,
    OfferingSetup_UnderwritingTerms_UnderwritingFeesAndDiscountsQuery
  >(OfferingSetup_UnderwritingTerms_UnderwritingFeesAndDiscountsDocument);

export const makeOfferingSetup_UnderwritingTerms_SyndicateMockResponse = makeMockResponse<
  OfferingSetup_UnderwritingTerms_SyndicateQueryVariables,
  OfferingSetup_UnderwritingTerms_SyndicateQuery
>(OfferingSetup_UnderwritingTerms_SyndicateDocument);

export const makeOfferingSetup_UnderwritingTerms_Economics_FilingsMockResponse = makeMockResponse<
  OfferingSetup_UnderwritingTerms_Economics_FilingsQueryVariables,
  OfferingSetup_UnderwritingTerms_Economics_FilingsQuery
>(OfferingSetup_UnderwritingTerms_Economics_FilingsDocument);

export const makeOfferingSetup_Validation_OfferingValidationMockResponse = makeMockResponse<
  OfferingSetup_Validation_OfferingValidationQueryVariables,
  OfferingSetup_Validation_OfferingValidationQuery
>(OfferingSetup_Validation_OfferingValidationDocument);
