import { ColDef, ColGroupDef, GridState } from '@cmg/data-grid';

import type { OrderBook_InstitutionalDemand_AllocationSetPartsFragment } from '../../graphql/__generated__';
import {
  acknowledgementsColDef,
  allocationAckStatusColDef,
  allocationColIdMatch,
  allocationConfirmedByColDef,
  attestationColDef,
  cmgEntityNameColDef,
  createdAtColDef,
  demandAtMarketColDef,
  demandLevelColIdMatch,
  demandMaxColDef,
  firmKeyColDef,
  getBndAgentColDef,
  modifiedAtDolDef,
  onPlatformColDef,
  prospectusColDef,
  relationshipColDef,
  rowAlertColDef,
  salesCoverageColDef,
  statusColDef,
  submittedByColDef,
  typeColDef,
} from '../columns';
import { DemandGridDataContext } from '../types';

type ColDefsType = (ColDef | ColGroupDef)[] | undefined;

export const CMG_TEMPLATES = {
  'CMG Default': getCMGDefaultGridState,
} as const;

export function isDynamicField(field: string) {
  return !!field.match(/^demandLevelAtPrice_.+$|^allocations_.+$/);
}

export function isStaticField(field: string) {
  return !isDynamicField(field);
}

export function getNotesFields({ colDefs }: { colDefs: ColDefsType }) {
  return colDefs?.reduce<string[]>((acc, item) => {
    if ('field' in item && item.field?.match(/^notes\..+$/)) {
      return [...acc, item.field];
    }
    return acc;
  }, []);
}

export function getDemandFields({ colDefs }: { colDefs: ColDefsType }) {
  return colDefs?.reduce<string[]>((acc, item) => {
    if ('colId' in item && item.colId?.match(demandLevelColIdMatch)) {
      return [...acc, item.colId];
    }
    return acc;
  }, []);
}

const isPrivateDraftAllocationSet = (
  allocationSet: OrderBook_InstitutionalDemand_AllocationSetPartsFragment,
  oidcUserCmgEntityKey: string | null
) => {
  return !allocationSet.isFinal && allocationSet.author.firmKey === oidcUserCmgEntityKey;
};

const isSharedDraftAllocationSet = (
  allocationSet: OrderBook_InstitutionalDemand_AllocationSetPartsFragment,
  oidcUserCmgEntityKey: string | null
) => {
  return !allocationSet.isFinal && allocationSet.author.firmKey !== oidcUserCmgEntityKey;
};

const isPrivateDefaultDraftAllocationSet = (
  allocationSet: OrderBook_InstitutionalDemand_AllocationSetPartsFragment,
  oidcUserCmgEntityKey: string | null
) => {
  return (
    isPrivateDraftAllocationSet(allocationSet, oidcUserCmgEntityKey) && allocationSet.isDefault
  );
};

const getSortedAllocationSetIds = (
  allocationSets: DemandGridDataContext['allocationSets'],
  oidcUserCmgEntityKey: string | null
) => {
  const sortedDrafts = Object.values(allocationSets.drafts)
    .reduce<[string[], string[], string[]]>(
      (acc, curr) => {
        const [privateDrafts, sharedDrafts, defaultDrafts] = acc;

        if (isPrivateDefaultDraftAllocationSet(curr, oidcUserCmgEntityKey)) {
          return [privateDrafts, sharedDrafts, [...defaultDrafts, curr.id]];
        }

        if (isSharedDraftAllocationSet(curr, oidcUserCmgEntityKey)) {
          return [privateDrafts, [...sharedDrafts, curr.id], defaultDrafts];
        }

        if (isPrivateDraftAllocationSet(curr, oidcUserCmgEntityKey)) {
          return [[...privateDrafts, curr.id], sharedDrafts, defaultDrafts];
        }

        return acc;
      },
      [[], [], []]
    )
    .flatMap(ids => ids);

  return allocationSets.final ? [...sortedDrafts, allocationSets.final.id] : sortedDrafts;
};

export function getAllocationFields({
  colDefs,
  demandGridContext,
}: {
  colDefs: ColDefsType;
  demandGridContext: DemandGridDataContext | null;
}) {
  const { allocationSets = { drafts: {} }, oidcUserCmgEntityKey = null } = demandGridContext ?? {};
  const sortedAllocationSetIds = getSortedAllocationSetIds(allocationSets, oidcUserCmgEntityKey);

  return colDefs
    ?.reduce<string[]>((acc, item) => {
      if ('colId' in item && item.colId?.match(allocationColIdMatch)) {
        return [...acc, item.colId];
      }
      return acc;
    }, [])
    .sort((lhsColId, rhsColId) => {
      const lhsAllocationSetIndex = sortedAllocationSetIds.indexOf(lhsColId.split('_')[1]);
      const rhsAllocationSetIndex = sortedAllocationSetIds.indexOf(rhsColId.split('_')[1]);

      return lhsAllocationSetIndex - rhsAllocationSetIndex;
    });
}

export function getCustomInvestorFields({ colDefs }: { colDefs: ColDefsType }) {
  return colDefs?.reduce<string[]>((acc, item) => {
    if ('field' in item && item.field?.match(/^investor.extensions\..+$/)) {
      return [...acc, item.field];
    }
    return acc;
  }, []);
}

export function getCMGDefaultGridState({
  colDefs,
  demandGridContext,
}: {
  colDefs: ColDefsType;
  demandGridContext: DemandGridDataContext | null;
}): GridState {
  const notesFields = getNotesFields({ colDefs }) ?? [];
  const demandFields = getDemandFields({ colDefs }) ?? [];
  const allocationFields = getAllocationFields({ colDefs, demandGridContext }) ?? [];
  const customInvestorFields = getCustomInvestorFields({ colDefs }) ?? [];

  return {
    columnVisibility: {
      hiddenColIds: [
        cmgEntityNameColDef.field!,
        firmKeyColDef.field!,
        ...notesFields,
        ...customInvestorFields,
      ],
    },
    columnOrder: {
      orderedColIds: [
        rowAlertColDef.field!,
        'ag-Grid-AutoColumn',
        onPlatformColDef.field!,
        statusColDef.field!,
        typeColDef.field!,
        demandMaxColDef.field!,
        demandAtMarketColDef.field!,
        ...demandFields,
        acknowledgementsColDef.field!,
        relationshipColDef.field!,
        allocationAckStatusColDef.field!,
        getBndAgentColDef({ canUpdate: true }).field!,
        allocationConfirmedByColDef.field!,
        salesCoverageColDef.field!,
        submittedByColDef.field!,
        createdAtColDef.field!,
        modifiedAtDolDef.field!,
        attestationColDef.field!,
        prospectusColDef.field!,
        cmgEntityNameColDef.field!,
        firmKeyColDef.field!,
        ...notesFields,
        ...customInvestorFields,
        ...allocationFields,
      ],
    },
    columnPinning: {
      leftColIds: [rowAlertColDef.field!, 'ag-Grid-AutoColumn'],
      rightColIds: [],
    },
  };
}
