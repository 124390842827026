import {
  ActionPanelSection,
  ActionPanelSectionContentSkeleton,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@cmg/design-system';
import React from 'react';
import { Link } from 'react-router-dom';

import { OfferingSidePanelRouteContext } from '../../../../../../OfferingSidePanelContainer';
import offeringSidePanelSellSideRouteFactory from '../../../../../../offeringSidePanelSellSideRouteFactory';
import { useManageIndication_DuplicateIndicationsQuery } from './graphql/__generated__';

export type Props = {
  duplicateIndicationIds: readonly string[];
  offeringId: string;
};

const DuplicateIndications: React.FC<Props> = ({ duplicateIndicationIds, offeringId }) => {
  const { data, loading, error } = useManageIndication_DuplicateIndicationsQuery({
    variables: { offeringId },
  });
  const indications = React.useMemo(
    () =>
      (data?.basicPublishedOffering?.orderBook.institutionalIndications ?? []).filter(i =>
        duplicateIndicationIds.includes(i.id)
      ),
    [data?.basicPublishedOffering?.orderBook.institutionalIndications, duplicateIndicationIds]
  );

  const baseSidePanelUrl = React.useContext(OfferingSidePanelRouteContext);

  return (
    <ActionPanelSection id="duplicate-indications" title="Linked Duplicates Indications">
      {loading && <ActionPanelSectionContentSkeleton />}
      {error && <Alert severity="error"> {error.message}</Alert>}
      {!error && (
        <TableContainer>
          <Table arial-label="duplicate indications">
            <TableHead>
              <TableRow>
                <TableCell>Investor Name</TableCell>
                <TableCell>Submitted By</TableCell>
                <TableCell>CMG Entity Name</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {indications.map(indication => (
                <TableRow key={indication.id}>
                  <TableCell>
                    <Link
                      to={
                        baseSidePanelUrl +
                        offeringSidePanelSellSideRouteFactory.orderBookIndicationActivity.getUrlPath(
                          {
                            cmgEntityKey: indication.investorInformation.cmgEntityKey!,
                          }
                        )
                      }
                    >
                      {indication.investorInformation.bankInvestorName ??
                        indication.investorInformation.cmgEntityName}
                    </Link>
                  </TableCell>
                  <TableCell>{indication.auditInfo.createdByFirmName}</TableCell>
                  <TableCell>{indication.investorInformation.cmgEntityName}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </ActionPanelSection>
  );
};

export default DuplicateIndications;
