import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@cmg/design-system';
import React from 'react';

import MandatoryAsterisk from '../../../../../../../design-system/components/data-display/mandatory-asterisk/MandatoryAsterisk';
import { InvestorContact } from '../InvestorContactInformationForm.model';
import InvestorContactsRow from './InvestorContactsRow';

export type Props = {
  editing: boolean;
  buySideContacts: InvestorContact[];
  sellSideContacts: InvestorContact[];
  handleRemoveContact: (index: number) => void;
  bankInvestorName?: string | null;
  usersFirmName?: string | null;
  contactsMandatory: boolean;
};

const InvestorContactsTable: React.FC<Props> = ({
  editing,
  buySideContacts,
  sellSideContacts,
  handleRemoveContact,
  bankInvestorName,
  usersFirmName,
  contactsMandatory,
}) => {
  const colspan = editing ? 3 : 2;

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Contact Name {contactsMandatory && <MandatoryAsterisk />}</TableCell>
            <TableCell>Email Address {contactsMandatory && <MandatoryAsterisk />}</TableCell>
            {editing && <TableCell sx={{ width: '2%' }}></TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {sellSideContacts.length > 0 && (
            <TableRow>
              <TableCell sx={{ background: theme => theme.palette.grey['100'] }} colSpan={colspan}>
                <Typography variant="highlight2">
                  Contact Source:{usersFirmName ?? 'Your Firm'}
                </Typography>
              </TableCell>
            </TableRow>
          )}
          {sellSideContacts.map((item, index) => (
            <InvestorContactsRow
              key={index}
              item={item}
              index={index}
              editing={editing}
              handleRemoveContact={() => handleRemoveContact(index)}
            />
          ))}
          {buySideContacts.length > 0 && (
            <TableRow>
              <TableCell sx={{ background: theme => theme.palette.grey['100'] }} colSpan={colspan}>
                <Typography variant="highlight2">
                  Contact Source: {bankInvestorName ?? 'Investor'}
                </Typography>
              </TableCell>
            </TableRow>
          )}
          {buySideContacts.map((item, index) => (
            <InvestorContactsRow key={index} item={item} index={index} editing={false} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default InvestorContactsTable;
