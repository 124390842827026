import { permissionsByEntity, useCheckPermissions } from '@cmg/auth';
import { Icon, Popover, timeUtil } from '@cmg/common';
import React from 'react';

import BooksClose from '../../common/components/format/books-close/BooksClose';
import Banner from '../../common/components/indicators/banner/Banner';
import { getFeatureToggles } from '../../common/config/appSettings';
import { OfferingStage, OfferingStatus } from '../../graphql';
import { managerRoleLabels } from '../../types/domain/manager/constants';
import OfferingProfileFilings from './components/OfferingProfileFilings';
import { OfferingProfile_OfferingProfilePartsFragment } from './graphql';
import {
  isBasicPublishedProfile,
  ManagersTypeFix,
  OfferingDetails,
  syndicateManagerGroups,
} from './OfferingProfile.model';
import {
  SContent,
  SField,
  SFootnote,
  SHeader,
  SHr,
  SLabel,
  SPopoverIcon,
  SProspectusLink,
  SProspectusLinkContainer,
  SRegistrationLinksField,
  SRole,
  SRule134Legend,
  STableHeader,
  STimingHeader,
  STitle,
  StyledFlexLayout,
  StyledProspectusLinksField,
} from './OfferingProfile.styles';

export type Props = {
  offering: OfferingDetails;
};

/**
 * Offering Profile Component
 */
export const OfferingProfile: React.FC<Props> = ({ offering }) => {
  const isDiscarded = offering.stage === OfferingStage.Erroneous;
  const isPricedOffering = offering.status === OfferingStatus.Priced;

  const { managerResponsibilities, managers } = offering.syndicate;
  const { isInternationalDisplayOn } = getFeatureToggles();
  const hasInternational = useCheckPermissions([permissionsByEntity.InternationalOffering.READ]);

  const managersTypeFix: ManagersTypeFix = managers;

  const { leftLead, settlementAgent, stabilizationAgent, logisticsAgent } = React.useMemo(
    () => ({
      leftLead: managersTypeFix.find(
        ({ cmgEntityKey }) => cmgEntityKey === managerResponsibilities.leftLead
      )?.firmName,
      settlementAgent: managersTypeFix.find(
        ({ cmgEntityKey }) => cmgEntityKey === managerResponsibilities.settlementAgent
      )?.firmName,
      stabilizationAgent: managersTypeFix.find(
        ({ cmgEntityKey }) => cmgEntityKey === managerResponsibilities.stabilizationAgent
      )?.firmName,
      logisticsAgent: managersTypeFix.find(
        ({ cmgEntityKey }) => cmgEntityKey === managerResponsibilities.logisticsAgent
      )?.firmName,
    }),
    [managerResponsibilities, managersTypeFix]
  );

  const prospectus = React.useMemo(() => {
    const documents = (
      (offering as OfferingProfile_OfferingProfilePartsFragment).prospectusDocuments ?? []
    ).filter(doc => doc.publicUrl && doc.publicUrl.trim());
    return {
      documents,
      shouldDisplay: documents.length > 0,
    };
  }, [offering]);

  const {
    issuerBusinessDescription,
    securityTypeDisplayName,
    terminatedDate,
    pricingDate,
    firstTradeDate,
    settlementDate,
    booksCloseAt,
    timeZone,
  } = React.useMemo(
    () =>
      isBasicPublishedProfile(offering)
        ? {
            issuerBusinessDescription: offering.issuerBusinessDescription,
            securityTypeDisplayName: offering.securityTypeDisplayName,
            terminatedDate: offering.terminatedDate,
            pricingDate: offering.pricingDate,
            firstTradeDate: offering.firstTradeDate,
            settlementDate: offering.settlementDate,
            booksCloseAt: offering.booksCloseAt,
            timeZone: offering.timeZone,
          }
        : {
            issuerBusinessDescription: offering.issuer.businessDescription,
            securityTypeDisplayName: offering.security.typeDisplayName,
            terminatedDate: offering.timing.terminatedDate,
            pricingDate: offering.timing.pricingDate,
            firstTradeDate: offering.timing.firstTradeDate,
            settlementDate: offering.timing.settlementDate,
            booksCloseAt: offering.timing.booksCloseAt,
            timeZone: offering.timing.timeZone,
          },
    [offering]
  );

  return (
    <div data-testid="offering-profile">
      {isDiscarded && <Banner variant="information">This offering has been discarded</Banner>}
      <SHeader>
        <STitle>Geographic/Investor Restrictions</STitle>
      </SHeader>
      <SContent>
        {offering.disclaimers.restrictions ?? '-'}
        <SRegistrationLinksField>
          <SLabel>Registration Statement</SLabel>
          {offering.disclaimers.registrationLinks
            .filter(link => !!link && link.trim())
            .map((link, index) => [
              index > 0 && ', ',
              <a
                key={index}
                href={link}
                data-testid="registration-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                Registration Link {index + 1}
              </a>,
            ])}
        </SRegistrationLinksField>
      </SContent>
      {prospectus.shouldDisplay && (
        <React.Fragment>
          <SHeader>
            <STitle>Prospectus</STitle>
          </SHeader>
          <SContent>
            <StyledProspectusLinksField>
              {prospectus.documents.map(document => (
                <SProspectusLinkContainer key={document.id}>
                  <SProspectusLink
                    href={document.publicUrl!}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span>{document.documentName}</span>
                    <Icon name="external-link-alt" />
                  </SProspectusLink>
                </SProspectusLinkContainer>
              ))}
            </StyledProspectusLinksField>
          </SContent>
        </React.Fragment>
      )}
      <SHeader>
        <STitle>Basic Info</STitle>
      </SHeader>
      <SContent>
        <SField>
          <SLabel>Business Description</SLabel>
          {issuerBusinessDescription}
        </SField>
        <SField>
          <SLabel>Security</SLabel>
          {securityTypeDisplayName}
        </SField>
      </SContent>
      <SHeader>
        {isInternationalDisplayOn && hasInternational ? (
          <Popover
            content="All dates are presented in exchange country’s local time zone"
            variant="DARK"
            placement="right"
            autoAdjustOverflow
          >
            <STimingHeader role="tooltip" aria-label="Timing popover">
              <STitle role="heading" aria-label="Timing header">
                Timing
              </STitle>
              <SPopoverIcon
                name="info-circle"
                variant="solid"
                size="sm"
                color="black"
                fixedWidth={true}
              />
            </STimingHeader>
          </Popover>
        ) : (
          <STitle role="heading" aria-label="Timing header">
            Timing
          </STitle>
        )}
      </SHeader>
      <SContent>
        {terminatedDate && (
          <StyledFlexLayout direction="row">
            <SField>
              <SLabel>Terminated Date</SLabel>
              {terminatedDate ? timeUtil.formatAsDisplayDate(terminatedDate) : '-'}
            </SField>
          </StyledFlexLayout>
        )}
        <StyledFlexLayout direction="row">
          <SField>
            <SLabel>{isPricedOffering ? '' : 'Expected '}Pricing Date</SLabel>
            {pricingDate ? timeUtil.formatAsDisplayDate(pricingDate) : '-'}
          </SField>
          <SField>
            <SLabel>{isPricedOffering ? '' : 'Expected '}First Trade Date</SLabel>
            {firstTradeDate ? timeUtil.formatAsDisplayDate(firstTradeDate) : '-'}
          </SField>
          <SField>
            <SLabel>{isPricedOffering ? '' : 'Expected '}Settlement Date</SLabel>
            {settlementDate ? timeUtil.formatAsDisplayDate(settlementDate) : '-'}
          </SField>
        </StyledFlexLayout>
        <StyledFlexLayout direction="row">
          <SField>
            <SLabel>Books Close</SLabel>
            <BooksClose booksCloseAt={booksCloseAt} timeZone={timeZone} />
          </SField>
        </StyledFlexLayout>
      </SContent>
      <OfferingProfileFilings
        hasForwardAgreement={offering.hasForwardAgreement ?? null}
        terms={offering.terms}
        offeringType={offering.type}
        currency={offering.pricingCurrencyCode}
      />
      <SHeader>
        <STitle>Underwriters and Roles</STitle>
      </SHeader>
      <SContent>
        <StyledFlexLayout direction="row">
          <SRole>Left Lead: </SRole>
          <SField>{leftLead ?? '-'}</SField>
        </StyledFlexLayout>
        {syndicateManagerGroups.map(role => {
          const managersFilteredByRole = managersTypeFix.filter(d => d.role === role);
          return managersFilteredByRole.length ? (
            <StyledFlexLayout direction="row" key={role}>
              <SRole>{`${managerRoleLabels[role]}s:`}</SRole>
              <SField>{managersFilteredByRole.map(d => d.firmName).join(', ')}</SField>
            </StyledFlexLayout>
          ) : null;
        })}
        <StyledFlexLayout direction="row">
          <SRole>Settlement Agent:</SRole>
          {settlementAgent ?? '-'}
        </StyledFlexLayout>
        <StyledFlexLayout direction="row">
          <SRole>Stabilization Agent:</SRole>
          {stabilizationAgent ?? '-'}
        </StyledFlexLayout>
        <StyledFlexLayout direction="row">
          <SRole>Logistics Agent:</SRole>
          {logisticsAgent ?? '-'}
        </StyledFlexLayout>
      </SContent>
      <SHeader>
        <STitle>Roadshow Details</STitle>
      </SHeader>
      <SContent>
        <SField>
          <SLabel>Management Team</SLabel>
          {offering.marketing.managementTeam}
        </SField>
      </SContent>
      <SContent>
        <StyledFlexLayout direction="row" withBorder>
          <STableHeader>Days</STableHeader>
          <STableHeader>Meeting Details</STableHeader>
        </StyledFlexLayout>
        {offering.roadshowSchedules.map(roadshowSchedule => (
          <StyledFlexLayout direction="row" key={roadshowSchedule.id} withBorder>
            <SField>
              {roadshowSchedule.date
                ? timeUtil.formatAsDisplayDayAndDate(roadshowSchedule.date)
                : '-'}
            </SField>
            <SField>{roadshowSchedule.details ?? '-'}</SField>
          </StyledFlexLayout>
        ))}
      </SContent>
      <SHeader>
        <STitle>Group Investor Call</STitle>
      </SHeader>
      <SContent>{offering.marketing.callDetails ?? '-'}</SContent>
      <SHeader>
        <STitle>Notices and Disclaimers</STitle>
      </SHeader>
      <SContent>
        <SFootnote>{offering.disclaimers.footnotes}</SFootnote>
      </SContent>
      <SHr />
      <SContent>
        <SRule134Legend>{offering.disclaimers.rule134Legend ?? '-'}</SRule134Legend>
      </SContent>
    </div>
  );
};

export default OfferingProfile;
