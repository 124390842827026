/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SyndicateWires_CreateUnderwriterInvitationWireMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  payload: Types.CreateUnderwriterInvitationWireInput;
}>;

export type SyndicateWires_CreateUnderwriterInvitationWireMutation = {
  readonly __typename?: 'Mutation';
  readonly createUnderwriterInvitationWire:
    | {
        readonly __typename: 'ServiceError';
        readonly code: Types.ServiceErrorCode;
        readonly message: string;
        readonly target?: string | null;
        readonly details?: ReadonlyArray<{
          readonly __typename: 'ErrorDetail';
          readonly code?: string | null;
          readonly message?: string | null;
          readonly target?: string | null;
        }> | null;
      }
    | {
        readonly __typename?: 'UnderwriterInvitationWire';
        readonly id: string;
        readonly disclaimer?: string | null;
        readonly masterAauDate: string;
        readonly status: Types.WireStatus;
        readonly createdAt: string;
        readonly sender: string;
        readonly wireMessages: ReadonlyArray<{
          readonly __typename?: 'WireMessage';
          readonly id: string;
          readonly emailAddresses: ReadonlyArray<string>;
          readonly expiredAt?: string | null;
          readonly respondedAt?: string | null;
          readonly responseStatus?: Types.SentWireResponseStatus | null;
          readonly wireMessageAndResponseStatus: Types.WireMessageAndResponseStatus;
          readonly sentAt?: string | null;
          readonly htmlBody: string;
          readonly firmSnapshot?: {
            readonly __typename?: 'FirmSnapshot';
            readonly cmgEntityKey: string;
            readonly firmName: string;
            readonly offeringManagerRole?: Types.SyndicateWiresManagerRole | null;
          } | null;
        }>;
      };
};

export type SyndicateWires_DeleteUnderwriterInvitationWireMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  syndicateWireId: Types.Scalars['UUID'];
}>;

export type SyndicateWires_DeleteUnderwriterInvitationWireMutation = {
  readonly __typename?: 'Mutation';
  readonly deleteUnderwriterInvitationWire:
    | { readonly __typename?: 'DeleteWire'; readonly id: string }
    | {
        readonly __typename: 'ServiceError';
        readonly code: Types.ServiceErrorCode;
        readonly message: string;
        readonly target?: string | null;
        readonly details?: ReadonlyArray<{
          readonly __typename: 'ErrorDetail';
          readonly code?: string | null;
          readonly message?: string | null;
          readonly target?: string | null;
        }> | null;
      };
};

export type SyndicateWires_MasterAauDateQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type SyndicateWires_MasterAauDateQuery = {
  readonly __typename?: 'Query';
  readonly syndicateWires: {
    readonly __typename?: 'SyndicateWires';
    readonly masterAauDate?: string | null;
  };
};

export type SyndicateWires_SendUnderwriterInvitationWireMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  syndicateWireId: Types.Scalars['UUID'];
  managerIds: ReadonlyArray<Types.Scalars['String']> | Types.Scalars['String'];
  sendDateTimeZone: Types.Scalars['String'];
  shouldAddSenderToCc: Types.Scalars['Boolean'];
}>;

export type SyndicateWires_SendUnderwriterInvitationWireMutation = {
  readonly __typename?: 'Mutation';
  readonly sendUnderwriterInvitationWire:
    | {
        readonly __typename: 'ServiceError';
        readonly code: Types.ServiceErrorCode;
        readonly message: string;
        readonly target?: string | null;
        readonly details?: ReadonlyArray<{
          readonly __typename: 'ErrorDetail';
          readonly code?: string | null;
          readonly message?: string | null;
          readonly target?: string | null;
        }> | null;
      }
    | {
        readonly __typename?: 'UnderwriterInvitationWire';
        readonly id: string;
        readonly disclaimer?: string | null;
        readonly masterAauDate: string;
        readonly status: Types.WireStatus;
        readonly createdAt: string;
        readonly sender: string;
        readonly wireMessages: ReadonlyArray<{
          readonly __typename?: 'WireMessage';
          readonly id: string;
          readonly emailAddresses: ReadonlyArray<string>;
          readonly expiredAt?: string | null;
          readonly respondedAt?: string | null;
          readonly responseStatus?: Types.SentWireResponseStatus | null;
          readonly wireMessageAndResponseStatus: Types.WireMessageAndResponseStatus;
          readonly sentAt?: string | null;
          readonly htmlBody: string;
          readonly firmSnapshot?: {
            readonly __typename?: 'FirmSnapshot';
            readonly cmgEntityKey: string;
            readonly firmName: string;
            readonly offeringManagerRole?: Types.SyndicateWiresManagerRole | null;
          } | null;
        }>;
      };
};

export type SyndicateWires_UnderwriterInvitationWireDetailsQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  syndicateWireId: Types.Scalars['UUID'];
}>;

export type SyndicateWires_UnderwriterInvitationWireDetailsQuery = {
  readonly __typename?: 'Query';
  readonly offering: {
    readonly __typename?: 'Offering';
    readonly id: string;
    readonly stage: Types.OfferingStage;
  };
  readonly underwriterInvitationWireDetails: {
    readonly __typename?: 'UnderwriterInvitationWire';
    readonly id: string;
    readonly disclaimer?: string | null;
    readonly masterAauDate: string;
    readonly status: Types.WireStatus;
    readonly createdAt: string;
    readonly sender: string;
    readonly wireMessages: ReadonlyArray<{
      readonly __typename?: 'WireMessage';
      readonly id: string;
      readonly emailAddresses: ReadonlyArray<string>;
      readonly expiredAt?: string | null;
      readonly respondedAt?: string | null;
      readonly responseStatus?: Types.SentWireResponseStatus | null;
      readonly wireMessageAndResponseStatus: Types.WireMessageAndResponseStatus;
      readonly sentAt?: string | null;
      readonly htmlBody: string;
      readonly firmSnapshot?: {
        readonly __typename?: 'FirmSnapshot';
        readonly cmgEntityKey: string;
        readonly firmName: string;
        readonly offeringManagerRole?: Types.SyndicateWiresManagerRole | null;
      } | null;
    }>;
  };
};

export type SyndicateWires_UnderwriterInvitationWirePartsFragment = {
  readonly __typename?: 'UnderwriterInvitationWire';
  readonly id: string;
  readonly disclaimer?: string | null;
  readonly masterAauDate: string;
  readonly status: Types.WireStatus;
  readonly createdAt: string;
  readonly sender: string;
  readonly wireMessages: ReadonlyArray<{
    readonly __typename?: 'WireMessage';
    readonly id: string;
    readonly emailAddresses: ReadonlyArray<string>;
    readonly expiredAt?: string | null;
    readonly respondedAt?: string | null;
    readonly responseStatus?: Types.SentWireResponseStatus | null;
    readonly wireMessageAndResponseStatus: Types.WireMessageAndResponseStatus;
    readonly sentAt?: string | null;
    readonly htmlBody: string;
    readonly firmSnapshot?: {
      readonly __typename?: 'FirmSnapshot';
      readonly cmgEntityKey: string;
      readonly firmName: string;
      readonly offeringManagerRole?: Types.SyndicateWiresManagerRole | null;
    } | null;
  }>;
};

export type SyndicateWires_UnderwriterInvitationWirePreviewQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  syndicateWireId?: Types.InputMaybe<Types.Scalars['UUID']>;
  cmgEntityKey?: Types.InputMaybe<Types.Scalars['String']>;
}>;

export type SyndicateWires_UnderwriterInvitationWirePreviewQuery = {
  readonly __typename?: 'Query';
  readonly underwriterInvitationWirePreview: {
    readonly __typename?: 'WirePreview';
    readonly htmlContent: string;
  };
};

export type SyndicateWires_UnderwriterInvitationWireValidationPartsFragment = {
  readonly __typename?: 'UnderwriterInvitationWireValidation';
  readonly orderBookErrors: ReadonlyArray<Types.OrderBookErrorType>;
  readonly offeringErrors: ReadonlyArray<Types.UnderwriterInvitationWireValidationOfferingErrors>;
  readonly settlementErrors: ReadonlyArray<Types.SettlementErrorType>;
  readonly managers: ReadonlyArray<{
    readonly __typename?: 'ManagerValidation';
    readonly cmgEntityKey: string;
    readonly fields: ReadonlyArray<Types.ManagerErrorType>;
  }>;
  readonly sender: {
    readonly __typename?: 'ManagerValidation';
    readonly cmgEntityKey: string;
    readonly fields: ReadonlyArray<Types.ManagerErrorType>;
  };
};

export type SyndicateWires_UnderwriterInvitationWireValidationQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type SyndicateWires_UnderwriterInvitationWireValidationQuery = {
  readonly __typename?: 'Query';
  readonly underwriterInvitationWireValidation: {
    readonly __typename?: 'UnderwriterInvitationWireValidation';
    readonly orderBookErrors: ReadonlyArray<Types.OrderBookErrorType>;
    readonly offeringErrors: ReadonlyArray<Types.UnderwriterInvitationWireValidationOfferingErrors>;
    readonly settlementErrors: ReadonlyArray<Types.SettlementErrorType>;
    readonly managers: ReadonlyArray<{
      readonly __typename?: 'ManagerValidation';
      readonly cmgEntityKey: string;
      readonly fields: ReadonlyArray<Types.ManagerErrorType>;
    }>;
    readonly sender: {
      readonly __typename?: 'ManagerValidation';
      readonly cmgEntityKey: string;
      readonly fields: ReadonlyArray<Types.ManagerErrorType>;
    };
  };
};

export type SyndicateWires_UnderwriterInvitationWiresQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type SyndicateWires_UnderwriterInvitationWiresQuery = {
  readonly __typename?: 'Query';
  readonly underwriterInvitationWireList: ReadonlyArray<{
    readonly __typename?: 'UnderwriterInvitationWire';
    readonly id: string;
    readonly disclaimer?: string | null;
    readonly masterAauDate: string;
    readonly status: Types.WireStatus;
    readonly createdAt: string;
    readonly sender: string;
    readonly wireMessages: ReadonlyArray<{
      readonly __typename?: 'WireMessage';
      readonly id: string;
      readonly emailAddresses: ReadonlyArray<string>;
      readonly expiredAt?: string | null;
      readonly respondedAt?: string | null;
      readonly responseStatus?: Types.SentWireResponseStatus | null;
      readonly wireMessageAndResponseStatus: Types.WireMessageAndResponseStatus;
      readonly sentAt?: string | null;
      readonly htmlBody: string;
      readonly firmSnapshot?: {
        readonly __typename?: 'FirmSnapshot';
        readonly cmgEntityKey: string;
        readonly firmName: string;
        readonly offeringManagerRole?: Types.SyndicateWiresManagerRole | null;
      } | null;
    }>;
  }>;
};

export type SyndicateWires_UpdateUnderwriterInvitationWireMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  syndicateWireId: Types.Scalars['UUID'];
  payload: Types.UpdateUnderwriterInvitationWireInput;
}>;

export type SyndicateWires_UpdateUnderwriterInvitationWireMutation = {
  readonly __typename?: 'Mutation';
  readonly updateUnderwriterInvitationWire:
    | {
        readonly __typename: 'ServiceError';
        readonly code: Types.ServiceErrorCode;
        readonly message: string;
        readonly target?: string | null;
        readonly details?: ReadonlyArray<{
          readonly __typename: 'ErrorDetail';
          readonly code?: string | null;
          readonly message?: string | null;
          readonly target?: string | null;
        }> | null;
      }
    | {
        readonly __typename?: 'UnderwriterInvitationWire';
        readonly id: string;
        readonly disclaimer?: string | null;
        readonly masterAauDate: string;
        readonly status: Types.WireStatus;
        readonly createdAt: string;
        readonly sender: string;
        readonly wireMessages: ReadonlyArray<{
          readonly __typename?: 'WireMessage';
          readonly id: string;
          readonly emailAddresses: ReadonlyArray<string>;
          readonly expiredAt?: string | null;
          readonly respondedAt?: string | null;
          readonly responseStatus?: Types.SentWireResponseStatus | null;
          readonly wireMessageAndResponseStatus: Types.WireMessageAndResponseStatus;
          readonly sentAt?: string | null;
          readonly htmlBody: string;
          readonly firmSnapshot?: {
            readonly __typename?: 'FirmSnapshot';
            readonly cmgEntityKey: string;
            readonly firmName: string;
            readonly offeringManagerRole?: Types.SyndicateWiresManagerRole | null;
          } | null;
        }>;
      };
};

export const SyndicateWires_UnderwriterInvitationWirePartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_UnderwriterInvitationWireParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UnderwriterInvitationWire' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'disclaimer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'masterAauDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wireMessages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_WireMessageParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_WireMessageParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WireMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddresses' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'firmSnapshot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_FirmSnapshotParts' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'respondedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'responseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wireMessageAndResponseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sentAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'htmlBody' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_FirmSnapshotParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FirmSnapshot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringManagerRole' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const SyndicateWires_UnderwriterInvitationWireValidationPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_UnderwriterInvitationWireValidationParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UnderwriterInvitationWireValidation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'orderBookErrors' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringErrors' } },
          { kind: 'Field', name: { kind: 'Name', value: 'settlementErrors' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'managers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_ManagerValidationParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sender' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_ManagerValidationParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_ManagerValidationParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ManagerValidation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fields' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const SyndicateWires_CreateUnderwriterInvitationWireDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SyndicateWires_CreateUnderwriterInvitationWire' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'payload' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateUnderwriterInvitationWireInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createUnderwriterInvitationWire' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'payload' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'payload' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_UnderwriterInvitationWireParts' },
                },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ServiceErrorParts' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_UnderwriterInvitationWireParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UnderwriterInvitationWire' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'disclaimer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'masterAauDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wireMessages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_WireMessageParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_WireMessageParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WireMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddresses' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'firmSnapshot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_FirmSnapshotParts' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'respondedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'responseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wireMessageAndResponseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sentAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'htmlBody' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_FirmSnapshotParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FirmSnapshot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringManagerRole' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ServiceErrorParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ServiceError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'target' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'target' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type SyndicateWires_CreateUnderwriterInvitationWireMutationFn = Apollo.MutationFunction<
  SyndicateWires_CreateUnderwriterInvitationWireMutation,
  SyndicateWires_CreateUnderwriterInvitationWireMutationVariables
>;

/**
 * __useSyndicateWires_CreateUnderwriterInvitationWireMutation__
 *
 * To run a mutation, you first call `useSyndicateWires_CreateUnderwriterInvitationWireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyndicateWires_CreateUnderwriterInvitationWireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syndicateWiresCreateUnderwriterInvitationWireMutation, { data, loading, error }] = useSyndicateWires_CreateUnderwriterInvitationWireMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useSyndicateWires_CreateUnderwriterInvitationWireMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SyndicateWires_CreateUnderwriterInvitationWireMutation,
    SyndicateWires_CreateUnderwriterInvitationWireMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SyndicateWires_CreateUnderwriterInvitationWireMutation,
    SyndicateWires_CreateUnderwriterInvitationWireMutationVariables
  >(SyndicateWires_CreateUnderwriterInvitationWireDocument, options);
}
export type SyndicateWires_CreateUnderwriterInvitationWireMutationHookResult = ReturnType<
  typeof useSyndicateWires_CreateUnderwriterInvitationWireMutation
>;
export type SyndicateWires_CreateUnderwriterInvitationWireMutationResult =
  Apollo.MutationResult<SyndicateWires_CreateUnderwriterInvitationWireMutation>;
export type SyndicateWires_CreateUnderwriterInvitationWireMutationOptions =
  Apollo.BaseMutationOptions<
    SyndicateWires_CreateUnderwriterInvitationWireMutation,
    SyndicateWires_CreateUnderwriterInvitationWireMutationVariables
  >;
export const SyndicateWires_DeleteUnderwriterInvitationWireDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SyndicateWires_DeleteUnderwriterInvitationWire' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'syndicateWireId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteUnderwriterInvitationWire' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'syndicateWireId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'syndicateWireId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_DeleteWireParts' },
                },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ServiceErrorParts' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ServiceErrorParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ServiceError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'target' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'target' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_DeleteWireParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DeleteWire' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
      },
    },
  ],
} as unknown as DocumentNode;
export type SyndicateWires_DeleteUnderwriterInvitationWireMutationFn = Apollo.MutationFunction<
  SyndicateWires_DeleteUnderwriterInvitationWireMutation,
  SyndicateWires_DeleteUnderwriterInvitationWireMutationVariables
>;

/**
 * __useSyndicateWires_DeleteUnderwriterInvitationWireMutation__
 *
 * To run a mutation, you first call `useSyndicateWires_DeleteUnderwriterInvitationWireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyndicateWires_DeleteUnderwriterInvitationWireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syndicateWiresDeleteUnderwriterInvitationWireMutation, { data, loading, error }] = useSyndicateWires_DeleteUnderwriterInvitationWireMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      syndicateWireId: // value for 'syndicateWireId'
 *   },
 * });
 */
export function useSyndicateWires_DeleteUnderwriterInvitationWireMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SyndicateWires_DeleteUnderwriterInvitationWireMutation,
    SyndicateWires_DeleteUnderwriterInvitationWireMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SyndicateWires_DeleteUnderwriterInvitationWireMutation,
    SyndicateWires_DeleteUnderwriterInvitationWireMutationVariables
  >(SyndicateWires_DeleteUnderwriterInvitationWireDocument, options);
}
export type SyndicateWires_DeleteUnderwriterInvitationWireMutationHookResult = ReturnType<
  typeof useSyndicateWires_DeleteUnderwriterInvitationWireMutation
>;
export type SyndicateWires_DeleteUnderwriterInvitationWireMutationResult =
  Apollo.MutationResult<SyndicateWires_DeleteUnderwriterInvitationWireMutation>;
export type SyndicateWires_DeleteUnderwriterInvitationWireMutationOptions =
  Apollo.BaseMutationOptions<
    SyndicateWires_DeleteUnderwriterInvitationWireMutation,
    SyndicateWires_DeleteUnderwriterInvitationWireMutationVariables
  >;
export const SyndicateWires_MasterAauDateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SyndicateWires_MasterAauDate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'syndicateWires' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'masterAauDate' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useSyndicateWires_MasterAauDateQuery__
 *
 * To run a query within a React component, call `useSyndicateWires_MasterAauDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useSyndicateWires_MasterAauDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSyndicateWires_MasterAauDateQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useSyndicateWires_MasterAauDateQuery(
  baseOptions: Apollo.QueryHookOptions<
    SyndicateWires_MasterAauDateQuery,
    SyndicateWires_MasterAauDateQueryVariables
  > &
    ({ variables: SyndicateWires_MasterAauDateQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SyndicateWires_MasterAauDateQuery,
    SyndicateWires_MasterAauDateQueryVariables
  >(SyndicateWires_MasterAauDateDocument, options);
}
export function useSyndicateWires_MasterAauDateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SyndicateWires_MasterAauDateQuery,
    SyndicateWires_MasterAauDateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SyndicateWires_MasterAauDateQuery,
    SyndicateWires_MasterAauDateQueryVariables
  >(SyndicateWires_MasterAauDateDocument, options);
}
export function useSyndicateWires_MasterAauDateSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        SyndicateWires_MasterAauDateQuery,
        SyndicateWires_MasterAauDateQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SyndicateWires_MasterAauDateQuery,
    SyndicateWires_MasterAauDateQueryVariables
  >(SyndicateWires_MasterAauDateDocument, options);
}
export type SyndicateWires_MasterAauDateQueryHookResult = ReturnType<
  typeof useSyndicateWires_MasterAauDateQuery
>;
export type SyndicateWires_MasterAauDateLazyQueryHookResult = ReturnType<
  typeof useSyndicateWires_MasterAauDateLazyQuery
>;
export type SyndicateWires_MasterAauDateSuspenseQueryHookResult = ReturnType<
  typeof useSyndicateWires_MasterAauDateSuspenseQuery
>;
export type SyndicateWires_MasterAauDateQueryResult = Apollo.QueryResult<
  SyndicateWires_MasterAauDateQuery,
  SyndicateWires_MasterAauDateQueryVariables
>;
export const SyndicateWires_SendUnderwriterInvitationWireDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SyndicateWires_SendUnderwriterInvitationWire' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'syndicateWireId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'managerIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sendDateTimeZone' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'shouldAddSenderToCc' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendUnderwriterInvitationWire' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'syndicateWireId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'syndicateWireId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'payload' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'underwriterList' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'managerIds' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'sendDateTimeZone' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'sendDateTimeZone' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'shouldAddSenderToCc' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'shouldAddSenderToCc' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_UnderwriterInvitationWireParts' },
                },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ServiceErrorParts' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_UnderwriterInvitationWireParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UnderwriterInvitationWire' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'disclaimer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'masterAauDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wireMessages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_WireMessageParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_WireMessageParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WireMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddresses' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'firmSnapshot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_FirmSnapshotParts' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'respondedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'responseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wireMessageAndResponseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sentAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'htmlBody' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_FirmSnapshotParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FirmSnapshot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringManagerRole' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ServiceErrorParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ServiceError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'target' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'target' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type SyndicateWires_SendUnderwriterInvitationWireMutationFn = Apollo.MutationFunction<
  SyndicateWires_SendUnderwriterInvitationWireMutation,
  SyndicateWires_SendUnderwriterInvitationWireMutationVariables
>;

/**
 * __useSyndicateWires_SendUnderwriterInvitationWireMutation__
 *
 * To run a mutation, you first call `useSyndicateWires_SendUnderwriterInvitationWireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyndicateWires_SendUnderwriterInvitationWireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syndicateWiresSendUnderwriterInvitationWireMutation, { data, loading, error }] = useSyndicateWires_SendUnderwriterInvitationWireMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      syndicateWireId: // value for 'syndicateWireId'
 *      managerIds: // value for 'managerIds'
 *      sendDateTimeZone: // value for 'sendDateTimeZone'
 *      shouldAddSenderToCc: // value for 'shouldAddSenderToCc'
 *   },
 * });
 */
export function useSyndicateWires_SendUnderwriterInvitationWireMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SyndicateWires_SendUnderwriterInvitationWireMutation,
    SyndicateWires_SendUnderwriterInvitationWireMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SyndicateWires_SendUnderwriterInvitationWireMutation,
    SyndicateWires_SendUnderwriterInvitationWireMutationVariables
  >(SyndicateWires_SendUnderwriterInvitationWireDocument, options);
}
export type SyndicateWires_SendUnderwriterInvitationWireMutationHookResult = ReturnType<
  typeof useSyndicateWires_SendUnderwriterInvitationWireMutation
>;
export type SyndicateWires_SendUnderwriterInvitationWireMutationResult =
  Apollo.MutationResult<SyndicateWires_SendUnderwriterInvitationWireMutation>;
export type SyndicateWires_SendUnderwriterInvitationWireMutationOptions =
  Apollo.BaseMutationOptions<
    SyndicateWires_SendUnderwriterInvitationWireMutation,
    SyndicateWires_SendUnderwriterInvitationWireMutationVariables
  >;
export const SyndicateWires_UnderwriterInvitationWireDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SyndicateWires_UnderwriterInvitationWireDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'syndicateWireId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'offering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'underwriterInvitationWireDetails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'syndicateWireId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'syndicateWireId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_UnderwriterInvitationWireParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_UnderwriterInvitationWireParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UnderwriterInvitationWire' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'disclaimer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'masterAauDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wireMessages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_WireMessageParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_WireMessageParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WireMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddresses' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'firmSnapshot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_FirmSnapshotParts' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'respondedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'responseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wireMessageAndResponseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sentAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'htmlBody' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_FirmSnapshotParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FirmSnapshot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringManagerRole' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useSyndicateWires_UnderwriterInvitationWireDetailsQuery__
 *
 * To run a query within a React component, call `useSyndicateWires_UnderwriterInvitationWireDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSyndicateWires_UnderwriterInvitationWireDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSyndicateWires_UnderwriterInvitationWireDetailsQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      syndicateWireId: // value for 'syndicateWireId'
 *   },
 * });
 */
export function useSyndicateWires_UnderwriterInvitationWireDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    SyndicateWires_UnderwriterInvitationWireDetailsQuery,
    SyndicateWires_UnderwriterInvitationWireDetailsQueryVariables
  > &
    (
      | { variables: SyndicateWires_UnderwriterInvitationWireDetailsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SyndicateWires_UnderwriterInvitationWireDetailsQuery,
    SyndicateWires_UnderwriterInvitationWireDetailsQueryVariables
  >(SyndicateWires_UnderwriterInvitationWireDetailsDocument, options);
}
export function useSyndicateWires_UnderwriterInvitationWireDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SyndicateWires_UnderwriterInvitationWireDetailsQuery,
    SyndicateWires_UnderwriterInvitationWireDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SyndicateWires_UnderwriterInvitationWireDetailsQuery,
    SyndicateWires_UnderwriterInvitationWireDetailsQueryVariables
  >(SyndicateWires_UnderwriterInvitationWireDetailsDocument, options);
}
export function useSyndicateWires_UnderwriterInvitationWireDetailsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        SyndicateWires_UnderwriterInvitationWireDetailsQuery,
        SyndicateWires_UnderwriterInvitationWireDetailsQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SyndicateWires_UnderwriterInvitationWireDetailsQuery,
    SyndicateWires_UnderwriterInvitationWireDetailsQueryVariables
  >(SyndicateWires_UnderwriterInvitationWireDetailsDocument, options);
}
export type SyndicateWires_UnderwriterInvitationWireDetailsQueryHookResult = ReturnType<
  typeof useSyndicateWires_UnderwriterInvitationWireDetailsQuery
>;
export type SyndicateWires_UnderwriterInvitationWireDetailsLazyQueryHookResult = ReturnType<
  typeof useSyndicateWires_UnderwriterInvitationWireDetailsLazyQuery
>;
export type SyndicateWires_UnderwriterInvitationWireDetailsSuspenseQueryHookResult = ReturnType<
  typeof useSyndicateWires_UnderwriterInvitationWireDetailsSuspenseQuery
>;
export type SyndicateWires_UnderwriterInvitationWireDetailsQueryResult = Apollo.QueryResult<
  SyndicateWires_UnderwriterInvitationWireDetailsQuery,
  SyndicateWires_UnderwriterInvitationWireDetailsQueryVariables
>;
export const SyndicateWires_UnderwriterInvitationWirePreviewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SyndicateWires_UnderwriterInvitationWirePreview' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'syndicateWireId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKey' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'underwriterInvitationWirePreview' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'syndicateWireId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'syndicateWireId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cmgEntityKey' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKey' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'htmlContent' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useSyndicateWires_UnderwriterInvitationWirePreviewQuery__
 *
 * To run a query within a React component, call `useSyndicateWires_UnderwriterInvitationWirePreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useSyndicateWires_UnderwriterInvitationWirePreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSyndicateWires_UnderwriterInvitationWirePreviewQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      syndicateWireId: // value for 'syndicateWireId'
 *      cmgEntityKey: // value for 'cmgEntityKey'
 *   },
 * });
 */
export function useSyndicateWires_UnderwriterInvitationWirePreviewQuery(
  baseOptions: Apollo.QueryHookOptions<
    SyndicateWires_UnderwriterInvitationWirePreviewQuery,
    SyndicateWires_UnderwriterInvitationWirePreviewQueryVariables
  > &
    (
      | { variables: SyndicateWires_UnderwriterInvitationWirePreviewQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SyndicateWires_UnderwriterInvitationWirePreviewQuery,
    SyndicateWires_UnderwriterInvitationWirePreviewQueryVariables
  >(SyndicateWires_UnderwriterInvitationWirePreviewDocument, options);
}
export function useSyndicateWires_UnderwriterInvitationWirePreviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SyndicateWires_UnderwriterInvitationWirePreviewQuery,
    SyndicateWires_UnderwriterInvitationWirePreviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SyndicateWires_UnderwriterInvitationWirePreviewQuery,
    SyndicateWires_UnderwriterInvitationWirePreviewQueryVariables
  >(SyndicateWires_UnderwriterInvitationWirePreviewDocument, options);
}
export function useSyndicateWires_UnderwriterInvitationWirePreviewSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        SyndicateWires_UnderwriterInvitationWirePreviewQuery,
        SyndicateWires_UnderwriterInvitationWirePreviewQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SyndicateWires_UnderwriterInvitationWirePreviewQuery,
    SyndicateWires_UnderwriterInvitationWirePreviewQueryVariables
  >(SyndicateWires_UnderwriterInvitationWirePreviewDocument, options);
}
export type SyndicateWires_UnderwriterInvitationWirePreviewQueryHookResult = ReturnType<
  typeof useSyndicateWires_UnderwriterInvitationWirePreviewQuery
>;
export type SyndicateWires_UnderwriterInvitationWirePreviewLazyQueryHookResult = ReturnType<
  typeof useSyndicateWires_UnderwriterInvitationWirePreviewLazyQuery
>;
export type SyndicateWires_UnderwriterInvitationWirePreviewSuspenseQueryHookResult = ReturnType<
  typeof useSyndicateWires_UnderwriterInvitationWirePreviewSuspenseQuery
>;
export type SyndicateWires_UnderwriterInvitationWirePreviewQueryResult = Apollo.QueryResult<
  SyndicateWires_UnderwriterInvitationWirePreviewQuery,
  SyndicateWires_UnderwriterInvitationWirePreviewQueryVariables
>;
export const SyndicateWires_UnderwriterInvitationWireValidationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SyndicateWires_UnderwriterInvitationWireValidation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'underwriterInvitationWireValidation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'SyndicateWires_UnderwriterInvitationWireValidationParts',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_UnderwriterInvitationWireValidationParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UnderwriterInvitationWireValidation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'orderBookErrors' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringErrors' } },
          { kind: 'Field', name: { kind: 'Name', value: 'settlementErrors' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'managers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_ManagerValidationParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sender' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_ManagerValidationParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_ManagerValidationParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ManagerValidation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fields' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useSyndicateWires_UnderwriterInvitationWireValidationQuery__
 *
 * To run a query within a React component, call `useSyndicateWires_UnderwriterInvitationWireValidationQuery` and pass it any options that fit your needs.
 * When your component renders, `useSyndicateWires_UnderwriterInvitationWireValidationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSyndicateWires_UnderwriterInvitationWireValidationQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useSyndicateWires_UnderwriterInvitationWireValidationQuery(
  baseOptions: Apollo.QueryHookOptions<
    SyndicateWires_UnderwriterInvitationWireValidationQuery,
    SyndicateWires_UnderwriterInvitationWireValidationQueryVariables
  > &
    (
      | {
          variables: SyndicateWires_UnderwriterInvitationWireValidationQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SyndicateWires_UnderwriterInvitationWireValidationQuery,
    SyndicateWires_UnderwriterInvitationWireValidationQueryVariables
  >(SyndicateWires_UnderwriterInvitationWireValidationDocument, options);
}
export function useSyndicateWires_UnderwriterInvitationWireValidationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SyndicateWires_UnderwriterInvitationWireValidationQuery,
    SyndicateWires_UnderwriterInvitationWireValidationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SyndicateWires_UnderwriterInvitationWireValidationQuery,
    SyndicateWires_UnderwriterInvitationWireValidationQueryVariables
  >(SyndicateWires_UnderwriterInvitationWireValidationDocument, options);
}
export function useSyndicateWires_UnderwriterInvitationWireValidationSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        SyndicateWires_UnderwriterInvitationWireValidationQuery,
        SyndicateWires_UnderwriterInvitationWireValidationQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SyndicateWires_UnderwriterInvitationWireValidationQuery,
    SyndicateWires_UnderwriterInvitationWireValidationQueryVariables
  >(SyndicateWires_UnderwriterInvitationWireValidationDocument, options);
}
export type SyndicateWires_UnderwriterInvitationWireValidationQueryHookResult = ReturnType<
  typeof useSyndicateWires_UnderwriterInvitationWireValidationQuery
>;
export type SyndicateWires_UnderwriterInvitationWireValidationLazyQueryHookResult = ReturnType<
  typeof useSyndicateWires_UnderwriterInvitationWireValidationLazyQuery
>;
export type SyndicateWires_UnderwriterInvitationWireValidationSuspenseQueryHookResult = ReturnType<
  typeof useSyndicateWires_UnderwriterInvitationWireValidationSuspenseQuery
>;
export type SyndicateWires_UnderwriterInvitationWireValidationQueryResult = Apollo.QueryResult<
  SyndicateWires_UnderwriterInvitationWireValidationQuery,
  SyndicateWires_UnderwriterInvitationWireValidationQueryVariables
>;
export const SyndicateWires_UnderwriterInvitationWiresDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SyndicateWires_UnderwriterInvitationWires' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'underwriterInvitationWireList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_UnderwriterInvitationWireParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_UnderwriterInvitationWireParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UnderwriterInvitationWire' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'disclaimer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'masterAauDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wireMessages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_WireMessageParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_WireMessageParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WireMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddresses' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'firmSnapshot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_FirmSnapshotParts' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'respondedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'responseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wireMessageAndResponseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sentAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'htmlBody' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_FirmSnapshotParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FirmSnapshot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringManagerRole' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useSyndicateWires_UnderwriterInvitationWiresQuery__
 *
 * To run a query within a React component, call `useSyndicateWires_UnderwriterInvitationWiresQuery` and pass it any options that fit your needs.
 * When your component renders, `useSyndicateWires_UnderwriterInvitationWiresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSyndicateWires_UnderwriterInvitationWiresQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useSyndicateWires_UnderwriterInvitationWiresQuery(
  baseOptions: Apollo.QueryHookOptions<
    SyndicateWires_UnderwriterInvitationWiresQuery,
    SyndicateWires_UnderwriterInvitationWiresQueryVariables
  > &
    (
      | { variables: SyndicateWires_UnderwriterInvitationWiresQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SyndicateWires_UnderwriterInvitationWiresQuery,
    SyndicateWires_UnderwriterInvitationWiresQueryVariables
  >(SyndicateWires_UnderwriterInvitationWiresDocument, options);
}
export function useSyndicateWires_UnderwriterInvitationWiresLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SyndicateWires_UnderwriterInvitationWiresQuery,
    SyndicateWires_UnderwriterInvitationWiresQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SyndicateWires_UnderwriterInvitationWiresQuery,
    SyndicateWires_UnderwriterInvitationWiresQueryVariables
  >(SyndicateWires_UnderwriterInvitationWiresDocument, options);
}
export function useSyndicateWires_UnderwriterInvitationWiresSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        SyndicateWires_UnderwriterInvitationWiresQuery,
        SyndicateWires_UnderwriterInvitationWiresQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SyndicateWires_UnderwriterInvitationWiresQuery,
    SyndicateWires_UnderwriterInvitationWiresQueryVariables
  >(SyndicateWires_UnderwriterInvitationWiresDocument, options);
}
export type SyndicateWires_UnderwriterInvitationWiresQueryHookResult = ReturnType<
  typeof useSyndicateWires_UnderwriterInvitationWiresQuery
>;
export type SyndicateWires_UnderwriterInvitationWiresLazyQueryHookResult = ReturnType<
  typeof useSyndicateWires_UnderwriterInvitationWiresLazyQuery
>;
export type SyndicateWires_UnderwriterInvitationWiresSuspenseQueryHookResult = ReturnType<
  typeof useSyndicateWires_UnderwriterInvitationWiresSuspenseQuery
>;
export type SyndicateWires_UnderwriterInvitationWiresQueryResult = Apollo.QueryResult<
  SyndicateWires_UnderwriterInvitationWiresQuery,
  SyndicateWires_UnderwriterInvitationWiresQueryVariables
>;
export const SyndicateWires_UpdateUnderwriterInvitationWireDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SyndicateWires_UpdateUnderwriterInvitationWire' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'syndicateWireId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'payload' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateUnderwriterInvitationWireInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUnderwriterInvitationWire' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'syndicateWireId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'syndicateWireId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'payload' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'payload' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_UnderwriterInvitationWireParts' },
                },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ServiceErrorParts' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_UnderwriterInvitationWireParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UnderwriterInvitationWire' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'disclaimer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'masterAauDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wireMessages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_WireMessageParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_WireMessageParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WireMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddresses' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'firmSnapshot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_FirmSnapshotParts' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'respondedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'responseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wireMessageAndResponseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sentAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'htmlBody' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_FirmSnapshotParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FirmSnapshot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringManagerRole' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ServiceErrorParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ServiceError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'target' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'target' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type SyndicateWires_UpdateUnderwriterInvitationWireMutationFn = Apollo.MutationFunction<
  SyndicateWires_UpdateUnderwriterInvitationWireMutation,
  SyndicateWires_UpdateUnderwriterInvitationWireMutationVariables
>;

/**
 * __useSyndicateWires_UpdateUnderwriterInvitationWireMutation__
 *
 * To run a mutation, you first call `useSyndicateWires_UpdateUnderwriterInvitationWireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyndicateWires_UpdateUnderwriterInvitationWireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syndicateWiresUpdateUnderwriterInvitationWireMutation, { data, loading, error }] = useSyndicateWires_UpdateUnderwriterInvitationWireMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      syndicateWireId: // value for 'syndicateWireId'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useSyndicateWires_UpdateUnderwriterInvitationWireMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SyndicateWires_UpdateUnderwriterInvitationWireMutation,
    SyndicateWires_UpdateUnderwriterInvitationWireMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SyndicateWires_UpdateUnderwriterInvitationWireMutation,
    SyndicateWires_UpdateUnderwriterInvitationWireMutationVariables
  >(SyndicateWires_UpdateUnderwriterInvitationWireDocument, options);
}
export type SyndicateWires_UpdateUnderwriterInvitationWireMutationHookResult = ReturnType<
  typeof useSyndicateWires_UpdateUnderwriterInvitationWireMutation
>;
export type SyndicateWires_UpdateUnderwriterInvitationWireMutationResult =
  Apollo.MutationResult<SyndicateWires_UpdateUnderwriterInvitationWireMutation>;
export type SyndicateWires_UpdateUnderwriterInvitationWireMutationOptions =
  Apollo.BaseMutationOptions<
    SyndicateWires_UpdateUnderwriterInvitationWireMutation,
    SyndicateWires_UpdateUnderwriterInvitationWireMutationVariables
  >;

import { makeMockResponse } from '../../../../../../../graphql/mock-response';

export const makeSyndicateWires_MasterAauDateMockResponse = makeMockResponse<
  SyndicateWires_MasterAauDateQueryVariables,
  SyndicateWires_MasterAauDateQuery
>(SyndicateWires_MasterAauDateDocument);

export const makeSyndicateWires_UnderwriterInvitationWireDetailsMockResponse = makeMockResponse<
  SyndicateWires_UnderwriterInvitationWireDetailsQueryVariables,
  SyndicateWires_UnderwriterInvitationWireDetailsQuery
>(SyndicateWires_UnderwriterInvitationWireDetailsDocument);

export const makeSyndicateWires_UnderwriterInvitationWirePreviewMockResponse = makeMockResponse<
  SyndicateWires_UnderwriterInvitationWirePreviewQueryVariables,
  SyndicateWires_UnderwriterInvitationWirePreviewQuery
>(SyndicateWires_UnderwriterInvitationWirePreviewDocument);

export const makeSyndicateWires_UnderwriterInvitationWireValidationMockResponse = makeMockResponse<
  SyndicateWires_UnderwriterInvitationWireValidationQueryVariables,
  SyndicateWires_UnderwriterInvitationWireValidationQuery
>(SyndicateWires_UnderwriterInvitationWireValidationDocument);

export const makeSyndicateWires_UnderwriterInvitationWiresMockResponse = makeMockResponse<
  SyndicateWires_UnderwriterInvitationWiresQueryVariables,
  SyndicateWires_UnderwriterInvitationWiresQuery
>(SyndicateWires_UnderwriterInvitationWiresDocument);
