/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BuySideIndicationQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type BuySideIndicationQuery = {
  readonly __typename?: 'Query';
  readonly basicPublishedOffering: {
    readonly __typename?: 'BasicPublishedOfferingProfile';
    readonly id: string;
    readonly offerPrice?: number | null;
    readonly pricingCurrencyCode: string;
    readonly securityType: Types.SecurityType;
    readonly securityTypeDisplayName?: string | null;
    readonly type: Types.OfferingType;
    readonly status: Types.OfferingStatus;
    readonly publicOrderBook: {
      readonly __typename?: 'PublicOrderBook';
      readonly offeringId: string;
      readonly state: Types.OrderBookState;
    };
    readonly investorRelationship: {
      readonly __typename?: 'InvestorRelationship';
      readonly hasRelationshipWithSyndicate: boolean;
      readonly hasRelationshipWithActiveBookrunner: boolean;
    };
    readonly myInstitutionalIndication?: {
      readonly __typename?: 'MyInstitutionalIndication';
      readonly id: string;
      readonly type: Types.InstitutionalIndicationOrderType;
      readonly billingAndDeliveryAgentCmgEntityKey?: string | null;
      readonly status: Types.IndicationStatus;
      readonly currencyCode?: string | null;
      readonly submittedByCmgEntityKey: string;
      readonly version: string;
      readonly trancheId?: string | null;
      readonly instrumentId?: string | null;
      readonly finalAllocation?: {
        readonly __typename?: 'FinalAllocation';
        readonly id: string;
        readonly indicationId: string;
        readonly shareQuantity?: number | null;
        readonly version: string;
        readonly investorReply?: {
          readonly __typename?: 'InvestorReply';
          readonly status: Types.AllocationAcknowledgement;
        } | null;
      } | null;
      readonly interestLevels: ReadonlyArray<{
        readonly __typename?: 'InterestLevel';
        readonly interestUnit: Types.InterestLevelInterestUnit;
        readonly interestQuantity: number;
        readonly limitPrice?: number | null;
        readonly limitType?: Types.InterestLevelLimitType | null;
      }>;
      readonly acknowledgements: ReadonlyArray<{
        readonly __typename?: 'InstitutionalIndicationAcknowledgement';
        readonly managerCmgEntityKey: string;
        readonly indicationVersion: string;
        readonly acknowledgedIndicationVersion: string;
      }>;
      readonly auditInfo: {
        readonly __typename?: 'AuditInfo';
        readonly createdAt: string;
        readonly modifiedByFirmKey?: string | null;
      };
    } | null;
    readonly disclaimers: {
      readonly __typename?: 'DisclaimersProfile';
      readonly restrictions?: string | null;
      readonly footnotes?: string | null;
      readonly registrationLinks: ReadonlyArray<string>;
      readonly rule134Legend?: string | null;
    };
    readonly orderEntryProfile: {
      readonly __typename?: 'OfferingOrderEntryProfile';
      readonly id: string;
      readonly isExecutingOnPlatform: boolean;
      readonly tranches: ReadonlyArray<{
        readonly __typename?: 'TranchePrivileged';
        readonly id: string;
        readonly defaultInstrumentId?: string | null;
        readonly name: string;
        readonly demandCurrencies: ReadonlyArray<string>;
        readonly deliveryInstrumentIds: ReadonlyArray<string>;
      }>;
      readonly instruments: ReadonlyArray<{
        readonly __typename?: 'InstrumentOrderEntryProfile';
        readonly id: string;
        readonly currencyCode?: string | null;
        readonly countryCode?: string | null;
        readonly stockSymbol?: string | null;
        readonly isDepositaryReceipt: boolean;
      }>;
    };
    readonly syndicate: {
      readonly __typename?: 'SyndicateProfile';
      readonly managers: ReadonlyArray<{
        readonly __typename?: 'ManagerProfile';
        readonly cmgEntityKey: string;
        readonly firmName: string;
      }>;
    };
  };
};

export const BuySideIndicationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BuySideIndication' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'basicPublishedOffering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'publicOrderBook' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'offeringId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'investorRelationship' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasRelationshipWithSyndicate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasRelationshipWithActiveBookrunner' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'myInstitutionalIndication' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ManageIndication_MyIndicationParts' },
                      },
                    ],
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ManageIndication_OfferingParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ManageIndication_MyIndicationParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MyInstitutionalIndication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'IndicationForm_MyIndicationParts' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ManageIndicationStatus_MyIndicationParts' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'finalAllocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'IndicationActivity_FinalAllocationParts' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'billingAndDeliveryAgentCmgEntityKey' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'IndicationForm_MyIndicationParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MyInstitutionalIndication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'submittedByCmgEntityKey' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'interestLevels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'interestUnit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'interestQuantity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limitPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limitType' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'acknowledgements' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'managerCmgEntityKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'indicationVersion' } },
                { kind: 'Field', name: { kind: 'Name', value: 'acknowledgedIndicationVersion' } },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'IndicationForm_IndicationDetails_MyIndicationParts' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'IndicationForm_IndicationDetails_MyIndicationParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MyInstitutionalIndication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trancheId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'instrumentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'auditInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modifiedByFirmKey' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ManageIndicationStatus_MyIndicationParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MyInstitutionalIndication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'IndicationActivity_FinalAllocationParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FinalAllocation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'indicationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shareQuantity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investorReply' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'status' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ManageIndication_OfferingParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BasicPublishedOfferingProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'IndicationForm_OfferingParts' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'IndicationForm_OfferingParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BasicPublishedOfferingProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offerPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingCurrencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'securityType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'securityTypeDisplayName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'disclaimers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'restrictions' } },
                { kind: 'Field', name: { kind: 'Name', value: 'footnotes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'registrationLinks' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rule134Legend' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orderEntryProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tranches' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'defaultInstrumentId' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isExecutingOnPlatform' } },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'IndicationForm_IndicationDetails_OfferingParts' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'IndicationActivity_AllocationOfferingParts' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'IndicationForm_IndicationDetails_OfferingParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BasicPublishedOfferingProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orderEntryProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tranches' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'defaultInstrumentId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'demandCurrencies' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryInstrumentIds' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'instruments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'stockSymbol' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isDepositaryReceipt' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'syndicate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'managers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'IndicationActivity_AllocationOfferingParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BasicPublishedOfferingProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offerPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingCurrencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useBuySideIndicationQuery__
 *
 * To run a query within a React component, call `useBuySideIndicationQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuySideIndicationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuySideIndicationQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useBuySideIndicationQuery(
  baseOptions: Apollo.QueryHookOptions<BuySideIndicationQuery, BuySideIndicationQueryVariables> &
    ({ variables: BuySideIndicationQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BuySideIndicationQuery, BuySideIndicationQueryVariables>(
    BuySideIndicationDocument,
    options
  );
}
export function useBuySideIndicationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BuySideIndicationQuery, BuySideIndicationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BuySideIndicationQuery, BuySideIndicationQueryVariables>(
    BuySideIndicationDocument,
    options
  );
}
export function useBuySideIndicationSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<BuySideIndicationQuery, BuySideIndicationQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BuySideIndicationQuery, BuySideIndicationQueryVariables>(
    BuySideIndicationDocument,
    options
  );
}
export type BuySideIndicationQueryHookResult = ReturnType<typeof useBuySideIndicationQuery>;
export type BuySideIndicationLazyQueryHookResult = ReturnType<typeof useBuySideIndicationLazyQuery>;
export type BuySideIndicationSuspenseQueryHookResult = ReturnType<
  typeof useBuySideIndicationSuspenseQuery
>;
export type BuySideIndicationQueryResult = Apollo.QueryResult<
  BuySideIndicationQuery,
  BuySideIndicationQueryVariables
>;

import { makeMockResponse } from '../../../../../../../../graphql/mock-response';

export const makeBuySideIndicationMockResponse = makeMockResponse<
  BuySideIndicationQueryVariables,
  BuySideIndicationQuery
>(BuySideIndicationDocument);
