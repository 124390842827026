/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MyOfferings_DraftsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type MyOfferings_DraftsQuery = {
  readonly __typename?: 'Query';
  readonly allOfferings: {
    readonly __typename?: 'OfferingList';
    readonly data: ReadonlyArray<{
      readonly __typename?: 'OfferingLimited';
      readonly id: string;
      readonly type: Types.OfferingType;
      readonly issuerName: string;
      readonly pricingDate?: string | null;
      readonly pricingInstrumentStockSymbol?: string | null;
      readonly issuerSector?: Types.Sector | null;
      readonly priceRangeLow?: number | null;
      readonly priceRangeHigh?: number | null;
      readonly sizeInCurrency?: number | null;
      readonly sizeInShares?: number | null;
      readonly leftLeadFirmName?: string | null;
      readonly stage: Types.OfferingStage;
      readonly isExecutingOnPlatform: boolean;
      readonly pricingInstrumentCountryCode?: string | null;
      readonly pricingCurrencyCode: string;
      readonly managers: ReadonlyArray<{
        readonly __typename?: 'ManagerLimited';
        readonly cmgEntityKey: string;
        readonly firmName: string;
      }>;
      readonly shareHistory: {
        readonly __typename?: 'OfferingSharedDraftHistory';
        readonly id: string;
        readonly shareDraftHistories: ReadonlyArray<{
          readonly __typename?: 'SharedDraftHistory';
          readonly version: string;
          readonly managerKeys: ReadonlyArray<string>;
          readonly sharedTime: string;
        }>;
      };
    }>;
    readonly offeringTypes: ReadonlyArray<{
      readonly __typename?: 'OfferingLimited';
      readonly id: string;
      readonly type: Types.OfferingType;
      readonly pricingInstrumentCountryCode?: string | null;
    }>;
    readonly offeringStatuses: ReadonlyArray<{
      readonly __typename?: 'OfferingLimited';
      readonly id: string;
      readonly status: Types.OfferingStatus;
    }>;
    readonly issuerSectors: ReadonlyArray<{
      readonly __typename?: 'OfferingLimited';
      readonly id: string;
      readonly issuerSector?: Types.Sector | null;
    }>;
  };
};

export type MyOfferings_PrivateOferingsQueryVariables = Types.Exact<{
  skip?: Types.InputMaybe<Types.Scalars['Int']>;
  take?: Types.InputMaybe<Types.Scalars['Int']>;
  where?: Types.InputMaybe<Types.OfferingFullFilterInput>;
}>;

export type MyOfferings_PrivateOferingsQuery = {
  readonly __typename?: 'Query';
  readonly privateOfferings?: {
    readonly __typename?: 'PrivateOfferingsCollectionSegment';
    readonly items?: ReadonlyArray<{
      readonly __typename?: 'OfferingFull';
      readonly id: string;
      readonly type: Types.OfferingType;
      readonly stage: Types.OfferingStage;
      readonly priceRangeLow?: number | null;
      readonly priceRangeHigh?: number | null;
      readonly sizeInCurrency?: number | null;
      readonly sizeInShares?: number | null;
      readonly pricingInstrumentStockSymbol?: string | null;
      readonly isExecutingOnPlatform: boolean;
      readonly pricingCurrencyCode: string;
      readonly pricingInstrumentId?: string | null;
      readonly issuer: {
        readonly __typename?: 'IssuerFull';
        readonly name: string;
        readonly sector?: Types.Sector | null;
      };
      readonly timing: { readonly __typename?: 'TimingFull'; readonly pricingDate?: string | null };
      readonly instruments: ReadonlyArray<{
        readonly __typename?: 'InstrumentFull';
        readonly countryCode?: string | null;
        readonly id: string;
        readonly currencyCode?: string | null;
      }>;
      readonly syndicate: {
        readonly __typename?: 'SyndicateFull';
        readonly managerResponsibilities: {
          readonly __typename?: 'ManagerResponsibilitiesFull';
          readonly leftLead?: string | null;
        };
        readonly managers: ReadonlyArray<{
          readonly __typename?: 'ManagerFull';
          readonly cmgEntityKey: string;
          readonly firmName: string;
          readonly firmNameAbbreviation?: string | null;
          readonly isJuniorRole?: boolean | null;
          readonly isParticipating: boolean;
          readonly role: Types.ManagerRole;
        }>;
      };
      readonly shareHistory?: ReadonlyArray<{
        readonly __typename?: 'ShareHistoryFull';
        readonly id: string;
        readonly managerKeys: ReadonlyArray<string>;
        readonly sharedAt: string;
        readonly version: number;
      }> | null;
    }> | null;
    readonly pageInfo: {
      readonly __typename?: 'CollectionSegmentInfo';
      readonly hasNextPage: boolean;
    };
  } | null;
};

export const MyOfferings_DraftsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MyOfferings_Drafts' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allOfferings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'stage' },
                      value: { kind: 'EnumValue', value: 'DRAFT' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'issuerName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'pricingDate' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pricingInstrumentStockSymbol' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'issuerSector' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'priceRangeLow' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'priceRangeHigh' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sizeInCurrency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sizeInShares' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'leftLeadFirmName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'managers' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shareHistory' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'shareDraftHistories' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'managerKeys' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'sharedTime' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'isExecutingOnPlatform' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pricingInstrumentCountryCode' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'pricingCurrencyCode' } },
                    ],
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MyOfferings_Filters_OfferingListParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MyOfferings_Filters_OfferingListParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'OfferingList' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'offeringTypes' },
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pricingInstrumentCountryCode' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'offeringStatuses' },
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'issuerSectors' },
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'issuerSector' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useMyOfferings_DraftsQuery__
 *
 * To run a query within a React component, call `useMyOfferings_DraftsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyOfferings_DraftsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyOfferings_DraftsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyOfferings_DraftsQuery(
  baseOptions?: Apollo.QueryHookOptions<MyOfferings_DraftsQuery, MyOfferings_DraftsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MyOfferings_DraftsQuery, MyOfferings_DraftsQueryVariables>(
    MyOfferings_DraftsDocument,
    options
  );
}
export function useMyOfferings_DraftsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MyOfferings_DraftsQuery,
    MyOfferings_DraftsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MyOfferings_DraftsQuery, MyOfferings_DraftsQueryVariables>(
    MyOfferings_DraftsDocument,
    options
  );
}
export function useMyOfferings_DraftsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<MyOfferings_DraftsQuery, MyOfferings_DraftsQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MyOfferings_DraftsQuery, MyOfferings_DraftsQueryVariables>(
    MyOfferings_DraftsDocument,
    options
  );
}
export type MyOfferings_DraftsQueryHookResult = ReturnType<typeof useMyOfferings_DraftsQuery>;
export type MyOfferings_DraftsLazyQueryHookResult = ReturnType<
  typeof useMyOfferings_DraftsLazyQuery
>;
export type MyOfferings_DraftsSuspenseQueryHookResult = ReturnType<
  typeof useMyOfferings_DraftsSuspenseQuery
>;
export type MyOfferings_DraftsQueryResult = Apollo.QueryResult<
  MyOfferings_DraftsQuery,
  MyOfferings_DraftsQueryVariables
>;
export const MyOfferings_PrivateOferingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MyOfferings_PrivateOferings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'take' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'OfferingFullFilterInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'privateOfferings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'take' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'take' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'issuer' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sector' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timing' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'pricingDate' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'priceRangeLow' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'priceRangeHigh' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sizeInCurrency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sizeInShares' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pricingInstrumentStockSymbol' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'isExecutingOnPlatform' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'pricingCurrencyCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'pricingInstrumentId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'instruments' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'syndicate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'managerResponsibilities' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'leftLead' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'managers' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'firmNameAbbreviation' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'isJuniorRole' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'isParticipating' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shareHistory' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'managerKeys' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sharedAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useMyOfferings_PrivateOferingsQuery__
 *
 * To run a query within a React component, call `useMyOfferings_PrivateOferingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyOfferings_PrivateOferingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyOfferings_PrivateOferingsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useMyOfferings_PrivateOferingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MyOfferings_PrivateOferingsQuery,
    MyOfferings_PrivateOferingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MyOfferings_PrivateOferingsQuery,
    MyOfferings_PrivateOferingsQueryVariables
  >(MyOfferings_PrivateOferingsDocument, options);
}
export function useMyOfferings_PrivateOferingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MyOfferings_PrivateOferingsQuery,
    MyOfferings_PrivateOferingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MyOfferings_PrivateOferingsQuery,
    MyOfferings_PrivateOferingsQueryVariables
  >(MyOfferings_PrivateOferingsDocument, options);
}
export function useMyOfferings_PrivateOferingsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        MyOfferings_PrivateOferingsQuery,
        MyOfferings_PrivateOferingsQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    MyOfferings_PrivateOferingsQuery,
    MyOfferings_PrivateOferingsQueryVariables
  >(MyOfferings_PrivateOferingsDocument, options);
}
export type MyOfferings_PrivateOferingsQueryHookResult = ReturnType<
  typeof useMyOfferings_PrivateOferingsQuery
>;
export type MyOfferings_PrivateOferingsLazyQueryHookResult = ReturnType<
  typeof useMyOfferings_PrivateOferingsLazyQuery
>;
export type MyOfferings_PrivateOferingsSuspenseQueryHookResult = ReturnType<
  typeof useMyOfferings_PrivateOferingsSuspenseQuery
>;
export type MyOfferings_PrivateOferingsQueryResult = Apollo.QueryResult<
  MyOfferings_PrivateOferingsQuery,
  MyOfferings_PrivateOferingsQueryVariables
>;

import { makeMockResponse } from '../../../../../graphql/mock-response';

export const makeMyOfferings_DraftsMockResponse = makeMockResponse<
  MyOfferings_DraftsQueryVariables,
  MyOfferings_DraftsQuery
>(MyOfferings_DraftsDocument);

export const makeMyOfferings_PrivateOferingsMockResponse = makeMockResponse<
  MyOfferings_PrivateOferingsQueryVariables,
  MyOfferings_PrivateOferingsQuery
>(MyOfferings_PrivateOferingsDocument);
