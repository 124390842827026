import { CellRenderers, formatters } from '@cmg/data-grid';

import { AllocationAcknowledgement } from '../../../../../../graphql';
import type { DemandGridServerColDef } from '../../types';
import { getCellRendererSelector, getValueFormatter } from '../columns.model';
import { getAllocationStatusColor } from './AllocationStatusCellRenderer.model';

/**
 * Exported for testing purposes
 */
export const cellRendererSelector = getCellRendererSelector<AllocationAcknowledgement>({
  gridRow: {
    component: CellRenderers.DotStatusCellRenderer,
    params: { getStatusColor: getAllocationStatusColor },
  },
});

export const valueFormatter = getValueFormatter<AllocationAcknowledgement>({
  gridRow: formatters.enumValueFormatter,
});

const allocationAckStatusColDef: DemandGridServerColDef<AllocationAcknowledgement> = {
  field: 'investorReplyStatus',
  headerName: 'Allo Status',
  width: 130,
  valueFormatter,
  cellRendererSelector,
  filter: 'agSetColumnFilter',
  filterParams: {
    values: Object.values(AllocationAcknowledgement),
    valueFormatter: formatters.enumValueFormatter,
  },
};

export default allocationAckStatusColDef;
