/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InstitutionalDemandGrid_AttestationStatusesQueryVariables = Types.Exact<{
  cmgEntityKeys: ReadonlyArray<Types.Scalars['String']> | Types.Scalars['String'];
}>;

export type InstitutionalDemandGrid_AttestationStatusesQuery = {
  readonly __typename?: 'Query';
  readonly attestationStatuses: ReadonlyArray<{
    readonly __typename?: 'AttestationStatus';
    readonly cmgEntityKey: string;
    readonly status: Types.AttestationFormStatus;
    readonly firmId: string;
  }>;
};

export type InstitutionalDemandGrid_OrderBookPartsFragment = {
  readonly __typename?: 'OfferingProfile';
  readonly orderBook: {
    readonly __typename?: 'OrderBook';
    readonly id: string;
    readonly state: Types.OrderBookState;
  };
};

export type InstitutionalDemandGrid_SyndicatePartsFragment = {
  readonly __typename?: 'OfferingProfile';
  readonly syndicate: {
    readonly __typename?: 'SyndicateProfile';
    readonly managers: ReadonlyArray<{
      readonly __typename?: 'ManagerProfile';
      readonly cmgEntityKey: string;
      readonly firmName: string;
      readonly firmNameAbbreviation?: string | null;
    }>;
    readonly managerResponsibilities: {
      readonly __typename?: 'ManagerResponsibilitiesProfile';
      readonly settlementAgent?: string | null;
    };
  };
};

export type InstitutionalDemandGrid_SyndicateManagerPartsFragment = {
  readonly __typename?: 'SyndicateManager';
  readonly managers: ReadonlyArray<{
    readonly __typename?: 'SyndicateManagerProfile';
    readonly cmgEntityKey: string;
    readonly firmName: string;
    readonly firmNameAbbreviation?: string | null;
  }>;
  readonly managerResponsibilities: {
    readonly __typename?: 'ManagerResponsibilities';
    readonly settlementAgent?: string | null;
  };
};

export type InstitutionalDemandGrid_ColumnConfigQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  canManagePassiveOrderBook: Types.Scalars['Boolean'];
}>;

export type InstitutionalDemandGrid_ColumnConfigQuery = {
  readonly __typename?: 'Query';
  readonly publishedOffering: {
    readonly __typename?: 'OfferingProfile';
    readonly id: string;
    readonly derivedOfferPrice?: number | null;
    readonly pricingCurrencyCode: string;
    readonly latestFilingPriceRangeLow?: number | null;
    readonly latestFilingPriceRangeHigh?: number | null;
    readonly initialFilingPriceRangeLow?: number | null;
    readonly initialFilingPriceRangeHigh?: number | null;
    readonly orderBook: {
      readonly __typename?: 'OrderBook';
      readonly id: string;
      readonly state: Types.OrderBookState;
    };
    readonly syndicate: {
      readonly __typename?: 'SyndicateProfile';
      readonly managers: ReadonlyArray<{
        readonly __typename?: 'ManagerProfile';
        readonly cmgEntityKey: string;
        readonly firmName: string;
        readonly firmNameAbbreviation?: string | null;
      }>;
      readonly managerResponsibilities: {
        readonly __typename?: 'ManagerResponsibilitiesProfile';
        readonly settlementAgent?: string | null;
      };
    };
  };
  readonly syndicateManagers?: {
    readonly __typename?: 'SyndicateManager';
    readonly managers: ReadonlyArray<{
      readonly __typename?: 'SyndicateManagerProfile';
      readonly cmgEntityKey: string;
      readonly firmName: string;
      readonly firmNameAbbreviation?: string | null;
    }>;
    readonly managerResponsibilities: {
      readonly __typename?: 'ManagerResponsibilities';
      readonly settlementAgent?: string | null;
    };
  };
};

export type InstitutionalDemandGrid_DeleteIndicationBillingAndDeliveryAgentMutationVariables =
  Types.Exact<{
    offeringId: Types.Scalars['UUID'];
    indicationId: Types.Scalars['UUID'];
  }>;

export type InstitutionalDemandGrid_DeleteIndicationBillingAndDeliveryAgentMutation = {
  readonly __typename?: 'Mutation';
  readonly deleteInstitutionalIndicationBillingAndDeliveryAgent: {
    readonly __typename?: 'DeleteBillingAndDeliveryAgentResult';
    readonly indicationId: string;
  };
};

export type InstitutionalDemandGrid_FirmInvestorExtendedDataQueryVariables = Types.Exact<{
  firmKey: Types.Scalars['String'];
}>;

export type InstitutionalDemandGrid_FirmInvestorExtendedDataQuery = {
  readonly __typename?: 'Query';
  readonly firmInvestorExtendedData: {
    readonly __typename?: 'InvestorExtendedData';
    readonly firmKey: string;
    readonly investorTypes: ReadonlyArray<{
      readonly __typename?: 'StringExtendedData';
      readonly key: string;
      readonly name?: string | null;
      readonly value: string;
    }>;
    readonly investorRegion: ReadonlyArray<{
      readonly __typename?: 'StringExtendedData';
      readonly key: string;
      readonly name?: string | null;
      readonly value: string;
    }>;
    readonly revenues: ReadonlyArray<{
      readonly __typename?: 'DecimalExtendedData';
      readonly key: string;
      readonly name?: string | null;
      readonly value: number;
    }>;
    readonly compliance: ReadonlyArray<{
      readonly __typename?: 'StringExtendedData';
      readonly key: string;
      readonly name?: string | null;
      readonly value: string;
    }>;
    readonly investorComments: ReadonlyArray<{
      readonly __typename?: 'StringExtendedData';
      readonly key: string;
      readonly name?: string | null;
      readonly value: string;
    }>;
    readonly otherInvestorAttributes: ReadonlyArray<{
      readonly __typename?: 'StringExtendedData';
      readonly key: string;
      readonly name?: string | null;
      readonly value: string;
    }>;
  };
};

export type InstitutionalDemandGrid_FirmInvestorsExtendedDataQueryVariables = Types.Exact<{
  firmKeys: ReadonlyArray<Types.Scalars['String']> | Types.Scalars['String'];
}>;

export type InstitutionalDemandGrid_FirmInvestorsExtendedDataQuery = {
  readonly __typename?: 'Query';
  readonly firmInvestorsExtendedData: ReadonlyArray<{
    readonly __typename?: 'InvestorExtendedData';
    readonly firmKey: string;
    readonly investorTypes: ReadonlyArray<{
      readonly __typename?: 'StringExtendedData';
      readonly key: string;
      readonly name?: string | null;
      readonly value: string;
    }>;
    readonly investorRegion: ReadonlyArray<{
      readonly __typename?: 'StringExtendedData';
      readonly key: string;
      readonly name?: string | null;
      readonly value: string;
    }>;
    readonly revenues: ReadonlyArray<{
      readonly __typename?: 'DecimalExtendedData';
      readonly key: string;
      readonly name?: string | null;
      readonly value: number;
    }>;
    readonly compliance: ReadonlyArray<{
      readonly __typename?: 'StringExtendedData';
      readonly key: string;
      readonly name?: string | null;
      readonly value: string;
    }>;
    readonly investorComments: ReadonlyArray<{
      readonly __typename?: 'StringExtendedData';
      readonly key: string;
      readonly name?: string | null;
      readonly value: string;
    }>;
    readonly otherInvestorAttributes: ReadonlyArray<{
      readonly __typename?: 'StringExtendedData';
      readonly key: string;
      readonly name?: string | null;
      readonly value: string;
    }>;
  }>;
};

export type InstitutionalDemandGrid_InvestorFirmsEmployeeRelationshipsQueryVariables = Types.Exact<{
  bankInvestorKeys: ReadonlyArray<Types.Scalars['String']> | Types.Scalars['String'];
}>;

export type InstitutionalDemandGrid_InvestorFirmsEmployeeRelationshipsQuery = {
  readonly __typename?: 'Query';
  readonly investorFirmsEmployeeRelationships: ReadonlyArray<{
    readonly __typename?: 'InvestorFirmsEmployeeRelationships';
    readonly bankInvestorKey: string;
    readonly relationships: ReadonlyArray<{
      readonly __typename?: 'InvestorFirmEmployeeRelationship';
      readonly underwriterEmployeeName?: string | null;
      readonly relationshipType: Types.RelationshipType;
      readonly status: Types.RolodexRecordStatus;
    }>;
  }>;
};

export type InstitutionalDemandGrid_AllocationSetManagerShareInfoPartsFragment = {
  readonly __typename?: 'AllocationSetManagerShareInfo';
  readonly id: string;
  readonly cmgEntityKey: string;
  readonly timestamp: string;
  readonly isActive: boolean;
};

export type InstitutionalDemandGrid_DealLayoutPartsFragment = {
  readonly __typename?: 'DealLayout';
  readonly id: string;
  readonly offeringSize?: number | null;
  readonly institutionalTarget?: number | null;
};

export type InstitutionalDemandGrid_DraftAllocationSetsPartsFragment = {
  readonly __typename?: 'InstitutionalDemandGridSummary';
  readonly institutionalDraftAllocationSets: ReadonlyArray<{
    readonly __typename?: 'InstitutionalDraftAllocationSetSummary';
    readonly id: string;
    readonly name: string;
    readonly authorCmgEntityKey: string;
    readonly isDefault: boolean;
    readonly shareTimestamp?: string | null;
    readonly version: string;
    readonly sharedWithManagers: ReadonlyArray<{
      readonly __typename?: 'AllocationSetManagerShareInfo';
      readonly id: string;
      readonly cmgEntityKey: string;
      readonly timestamp: string;
      readonly isActive: boolean;
    }>;
    readonly allocations: ReadonlyArray<{
      readonly __typename?: 'DraftAllocation';
      readonly id: string;
      readonly allocationSetId: string;
      readonly indicationId: string;
      readonly shareQuantity?: number | null;
      readonly version: string;
      readonly auditInfo: {
        readonly __typename?: 'AuditInfo';
        readonly modifiedByFirmKey?: string | null;
        readonly modifiedAt: string;
        readonly modifiedByUserEmail?: string | null;
        readonly createdAt: string;
      };
    }>;
    readonly auditInfo: {
      readonly __typename?: 'AuditInfo';
      readonly createdAt: string;
      readonly modifiedByFirmKey?: string | null;
      readonly modifiedAt: string;
      readonly modifiedByUserEmail?: string | null;
    };
    readonly dealLayout: {
      readonly __typename?: 'DealLayout';
      readonly id: string;
      readonly offeringSize?: number | null;
      readonly institutionalTarget?: number | null;
    };
  }>;
};

export type InstitutionalDemandGrid_FinalAllocationSetPartsFragment = {
  readonly __typename?: 'InstitutionalDemandGridSummary';
  readonly institutionalFinalAllocationSet?: {
    readonly __typename?: 'InstitutionalFinalAllocationSetSummary';
    readonly id: string;
    readonly isReleased: boolean;
    readonly name: string;
    readonly releaseTimestamp?: string | null;
    readonly totalAllocatedShareQuantity: number;
    readonly shareTimestamp?: string | null;
    readonly authorCmgEntityKey: string;
    readonly version: string;
    readonly sharedWithManagers: ReadonlyArray<{
      readonly __typename?: 'AllocationSetManagerShareInfo';
      readonly id: string;
      readonly cmgEntityKey: string;
      readonly timestamp: string;
      readonly isActive: boolean;
    }>;
    readonly allocations: ReadonlyArray<{
      readonly __typename?: 'InstitutionalDemandGridFinalAllocation';
      readonly id: string;
      readonly indicationId: string;
      readonly shareQuantity?: number | null;
      readonly investorReplySubmitterName?: string | null;
      readonly version: string;
      readonly investorReply?: {
        readonly __typename?: 'InvestorReply';
        readonly status: Types.AllocationAcknowledgement;
        readonly version: string;
        readonly auditInfo: {
          readonly __typename?: 'AuditInfo';
          readonly modifiedByFirmKey?: string | null;
          readonly modifiedAt: string;
          readonly modifiedByUserEmail?: string | null;
          readonly createdByUserEmail?: string | null;
        };
      } | null;
      readonly auditInfo: {
        readonly __typename?: 'AuditInfo';
        readonly modifiedByFirmKey?: string | null;
        readonly modifiedAt: string;
        readonly modifiedByUserEmail?: string | null;
      };
    }>;
    readonly auditInfo: {
      readonly __typename?: 'AuditInfo';
      readonly createdAt: string;
      readonly modifiedByFirmKey?: string | null;
      readonly modifiedAt: string;
      readonly modifiedByUserEmail?: string | null;
    };
    readonly dealLayout: {
      readonly __typename?: 'DealLayout';
      readonly id: string;
      readonly offeringSize?: number | null;
      readonly institutionalTarget?: number | null;
    };
  } | null;
};

export type InstitutionalDemandGrid_IndicationNotePartsFragment = {
  readonly __typename?: 'InstitutionalIndicationNote';
  readonly text: string;
  readonly type: Types.IndicationNoteType;
  readonly auditInfo: {
    readonly __typename?: 'AuditInfo';
    readonly createdAt: string;
    readonly modifiedAt: string;
  };
};

export type InstitutionalDemandGrid_IndicationAcknowledgementPartsFragment = {
  readonly __typename?: 'InstitutionalIndicationAcknowledgement';
  readonly id: string;
  readonly offeringId: string;
  readonly indicationId: string;
  readonly indicationVersion: string;
  readonly acknowledgedIndicationVersion: string;
  readonly managerCmgEntityKey: string;
  readonly version: string;
  readonly auditInfo: {
    readonly __typename?: 'AuditInfo';
    readonly createdAt: string;
    readonly modifiedByFirmKey?: string | null;
    readonly modifiedAt: string;
    readonly modifiedByUserEmail?: string | null;
    readonly createdByUserEmail?: string | null;
  };
};

export type InstitutionalDemandGrid_InvestorDeliveryStatusPartsFragment = {
  readonly __typename?: 'InvestorDeliveryStatus';
  readonly id: string;
  readonly offeringId: string;
  readonly cmgEntityKey: string;
  readonly overallDeliveryStatus: Types.ProspectusDeliveryStatus;
  readonly sentEmailCount: number;
  readonly totalEmailCount: number;
  readonly failedEmailCount: number;
  readonly inProgressEmailCount: number;
};

export type InstitutionalDemandGrid_IndicationDemandsPartsFragment = {
  readonly __typename?: 'InstitutionalDemandGridSummary';
  readonly institutionalIndicationDemands: ReadonlyArray<{
    readonly __typename?: 'InstitutionalIndicationDemand';
    readonly id: string;
    readonly demandMax: number;
    readonly demandAtMarket: number;
    readonly billingAndDeliveryAgentCmgEntityKey?: string | null;
    readonly acknowledgingUser?: string | null;
    readonly trancheId?: string | null;
    readonly instrumentId?: string | null;
    readonly currencyCode?: string | null;
    readonly coveringManagerCmgEntityKeys: ReadonlyArray<string>;
    readonly offeringId: string;
    readonly status: Types.IndicationStatus;
    readonly type: Types.InstitutionalIndicationOrderType;
    readonly submittedByCmgEntityKey: string;
    readonly duplicateOfIndicationId?: string | null;
    readonly duplicateIndicationIds: ReadonlyArray<string>;
    readonly version: string;
    readonly demandLevels: ReadonlyArray<{
      readonly __typename?: 'InstitutionalIndicationDemandLevel';
      readonly price: number;
      readonly interestInShares: number;
    }>;
    readonly investorInformation: {
      readonly __typename?: 'InvestorInformationWithAbbreviation';
      readonly bankInvestorName?: string | null;
      readonly bankInvestorKey?: string | null;
      readonly cmgEntityName: string;
      readonly cmgEntityKey: string;
      readonly isOnPlatform: boolean;
      readonly firmNameAbbreviation?: string | null;
    };
    readonly auditInfo: {
      readonly __typename?: 'AuditInfo';
      readonly createdAt: string;
      readonly modifiedByFirmKey?: string | null;
      readonly modifiedAt: string;
      readonly modifiedByUserEmail?: string | null;
    };
    readonly interestLevels: ReadonlyArray<{
      readonly __typename?: 'InterestLevel';
      readonly interestUnit: Types.InterestLevelInterestUnit;
      readonly interestQuantity: number;
      readonly limitPrice?: number | null;
      readonly limitType?: Types.InterestLevelLimitType | null;
    }>;
    readonly notes: ReadonlyArray<{
      readonly __typename?: 'InstitutionalIndicationNote';
      readonly text: string;
      readonly type: Types.IndicationNoteType;
      readonly auditInfo: {
        readonly __typename?: 'AuditInfo';
        readonly createdAt: string;
        readonly modifiedAt: string;
      };
    }>;
    readonly institutionalIndicationAcknowledgements: ReadonlyArray<{
      readonly __typename?: 'InstitutionalIndicationAcknowledgement';
      readonly id: string;
      readonly offeringId: string;
      readonly indicationId: string;
      readonly indicationVersion: string;
      readonly acknowledgedIndicationVersion: string;
      readonly managerCmgEntityKey: string;
      readonly version: string;
      readonly auditInfo: {
        readonly __typename?: 'AuditInfo';
        readonly createdAt: string;
        readonly modifiedByFirmKey?: string | null;
        readonly modifiedAt: string;
        readonly modifiedByUserEmail?: string | null;
        readonly createdByUserEmail?: string | null;
      };
    }>;
    readonly prospectusDeliveryStatus?: {
      readonly __typename?: 'InvestorDeliveryStatus';
      readonly id: string;
      readonly offeringId: string;
      readonly cmgEntityKey: string;
      readonly overallDeliveryStatus: Types.ProspectusDeliveryStatus;
      readonly sentEmailCount: number;
      readonly totalEmailCount: number;
      readonly failedEmailCount: number;
      readonly inProgressEmailCount: number;
    } | null;
  }>;
};

export type InstitutionalDemandGrid_SummaryQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  input: Types.InstitutionalIndicationDemandQueryInput;
}>;

export type InstitutionalDemandGrid_SummaryQuery = {
  readonly __typename?: 'Query';
  readonly institutionalDemandGridSummary: {
    readonly __typename?: 'InstitutionalDemandGridSummary';
    readonly institutionalDraftAllocationSets: ReadonlyArray<{
      readonly __typename?: 'InstitutionalDraftAllocationSetSummary';
      readonly id: string;
      readonly name: string;
      readonly authorCmgEntityKey: string;
      readonly isDefault: boolean;
      readonly shareTimestamp?: string | null;
      readonly version: string;
      readonly sharedWithManagers: ReadonlyArray<{
        readonly __typename?: 'AllocationSetManagerShareInfo';
        readonly id: string;
        readonly cmgEntityKey: string;
        readonly timestamp: string;
        readonly isActive: boolean;
      }>;
      readonly allocations: ReadonlyArray<{
        readonly __typename?: 'DraftAllocation';
        readonly id: string;
        readonly allocationSetId: string;
        readonly indicationId: string;
        readonly shareQuantity?: number | null;
        readonly version: string;
        readonly auditInfo: {
          readonly __typename?: 'AuditInfo';
          readonly modifiedByFirmKey?: string | null;
          readonly modifiedAt: string;
          readonly modifiedByUserEmail?: string | null;
          readonly createdAt: string;
        };
      }>;
      readonly auditInfo: {
        readonly __typename?: 'AuditInfo';
        readonly createdAt: string;
        readonly modifiedByFirmKey?: string | null;
        readonly modifiedAt: string;
        readonly modifiedByUserEmail?: string | null;
      };
      readonly dealLayout: {
        readonly __typename?: 'DealLayout';
        readonly id: string;
        readonly offeringSize?: number | null;
        readonly institutionalTarget?: number | null;
      };
    }>;
    readonly institutionalFinalAllocationSet?: {
      readonly __typename?: 'InstitutionalFinalAllocationSetSummary';
      readonly id: string;
      readonly isReleased: boolean;
      readonly name: string;
      readonly releaseTimestamp?: string | null;
      readonly totalAllocatedShareQuantity: number;
      readonly shareTimestamp?: string | null;
      readonly authorCmgEntityKey: string;
      readonly version: string;
      readonly sharedWithManagers: ReadonlyArray<{
        readonly __typename?: 'AllocationSetManagerShareInfo';
        readonly id: string;
        readonly cmgEntityKey: string;
        readonly timestamp: string;
        readonly isActive: boolean;
      }>;
      readonly allocations: ReadonlyArray<{
        readonly __typename?: 'InstitutionalDemandGridFinalAllocation';
        readonly id: string;
        readonly indicationId: string;
        readonly shareQuantity?: number | null;
        readonly investorReplySubmitterName?: string | null;
        readonly version: string;
        readonly investorReply?: {
          readonly __typename?: 'InvestorReply';
          readonly status: Types.AllocationAcknowledgement;
          readonly version: string;
          readonly auditInfo: {
            readonly __typename?: 'AuditInfo';
            readonly modifiedByFirmKey?: string | null;
            readonly modifiedAt: string;
            readonly modifiedByUserEmail?: string | null;
            readonly createdByUserEmail?: string | null;
          };
        } | null;
        readonly auditInfo: {
          readonly __typename?: 'AuditInfo';
          readonly modifiedByFirmKey?: string | null;
          readonly modifiedAt: string;
          readonly modifiedByUserEmail?: string | null;
        };
      }>;
      readonly auditInfo: {
        readonly __typename?: 'AuditInfo';
        readonly createdAt: string;
        readonly modifiedByFirmKey?: string | null;
        readonly modifiedAt: string;
        readonly modifiedByUserEmail?: string | null;
      };
      readonly dealLayout: {
        readonly __typename?: 'DealLayout';
        readonly id: string;
        readonly offeringSize?: number | null;
        readonly institutionalTarget?: number | null;
      };
    } | null;
    readonly institutionalIndicationDemands: ReadonlyArray<{
      readonly __typename?: 'InstitutionalIndicationDemand';
      readonly id: string;
      readonly demandMax: number;
      readonly demandAtMarket: number;
      readonly billingAndDeliveryAgentCmgEntityKey?: string | null;
      readonly acknowledgingUser?: string | null;
      readonly trancheId?: string | null;
      readonly instrumentId?: string | null;
      readonly currencyCode?: string | null;
      readonly coveringManagerCmgEntityKeys: ReadonlyArray<string>;
      readonly offeringId: string;
      readonly status: Types.IndicationStatus;
      readonly type: Types.InstitutionalIndicationOrderType;
      readonly submittedByCmgEntityKey: string;
      readonly duplicateOfIndicationId?: string | null;
      readonly duplicateIndicationIds: ReadonlyArray<string>;
      readonly version: string;
      readonly demandLevels: ReadonlyArray<{
        readonly __typename?: 'InstitutionalIndicationDemandLevel';
        readonly price: number;
        readonly interestInShares: number;
      }>;
      readonly investorInformation: {
        readonly __typename?: 'InvestorInformationWithAbbreviation';
        readonly bankInvestorName?: string | null;
        readonly bankInvestorKey?: string | null;
        readonly cmgEntityName: string;
        readonly cmgEntityKey: string;
        readonly isOnPlatform: boolean;
        readonly firmNameAbbreviation?: string | null;
      };
      readonly auditInfo: {
        readonly __typename?: 'AuditInfo';
        readonly createdAt: string;
        readonly modifiedByFirmKey?: string | null;
        readonly modifiedAt: string;
        readonly modifiedByUserEmail?: string | null;
      };
      readonly interestLevels: ReadonlyArray<{
        readonly __typename?: 'InterestLevel';
        readonly interestUnit: Types.InterestLevelInterestUnit;
        readonly interestQuantity: number;
        readonly limitPrice?: number | null;
        readonly limitType?: Types.InterestLevelLimitType | null;
      }>;
      readonly notes: ReadonlyArray<{
        readonly __typename?: 'InstitutionalIndicationNote';
        readonly text: string;
        readonly type: Types.IndicationNoteType;
        readonly auditInfo: {
          readonly __typename?: 'AuditInfo';
          readonly createdAt: string;
          readonly modifiedAt: string;
        };
      }>;
      readonly institutionalIndicationAcknowledgements: ReadonlyArray<{
        readonly __typename?: 'InstitutionalIndicationAcknowledgement';
        readonly id: string;
        readonly offeringId: string;
        readonly indicationId: string;
        readonly indicationVersion: string;
        readonly acknowledgedIndicationVersion: string;
        readonly managerCmgEntityKey: string;
        readonly version: string;
        readonly auditInfo: {
          readonly __typename?: 'AuditInfo';
          readonly createdAt: string;
          readonly modifiedByFirmKey?: string | null;
          readonly modifiedAt: string;
          readonly modifiedByUserEmail?: string | null;
          readonly createdByUserEmail?: string | null;
        };
      }>;
      readonly prospectusDeliveryStatus?: {
        readonly __typename?: 'InvestorDeliveryStatus';
        readonly id: string;
        readonly offeringId: string;
        readonly cmgEntityKey: string;
        readonly overallDeliveryStatus: Types.ProspectusDeliveryStatus;
        readonly sentEmailCount: number;
        readonly totalEmailCount: number;
        readonly failedEmailCount: number;
        readonly inProgressEmailCount: number;
      } | null;
    }>;
  };
};

export type InstitutionalDemandGrid_UpdateIndicationBillingAndDeliveryAgentMutationVariables =
  Types.Exact<{
    offeringId: Types.Scalars['UUID'];
    indicationId: Types.Scalars['UUID'];
    input: Types.BillingAndDeliveryAgentUpdateInput;
  }>;

export type InstitutionalDemandGrid_UpdateIndicationBillingAndDeliveryAgentMutation = {
  readonly __typename?: 'Mutation';
  readonly updateInstitutionalIndicationBillingAndDeliveryAgent: {
    readonly __typename?: 'BillingAndDeliveryAgent';
    readonly managerCmgEntityKey: string;
  };
};

export const InstitutionalDemandGrid_OrderBookPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstitutionalDemandGrid_OrderBookParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'OfferingProfile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orderBook' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const InstitutionalDemandGrid_SyndicatePartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstitutionalDemandGrid_SyndicateParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'OfferingProfile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'syndicate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'managers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firmNameAbbreviation' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'managerResponsibilities' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'settlementAgent' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const InstitutionalDemandGrid_SyndicateManagerPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstitutionalDemandGrid_SyndicateManagerParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SyndicateManager' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'managers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firmNameAbbreviation' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'managerResponsibilities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'settlementAgent' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const InstitutionalDemandGrid_AllocationSetManagerShareInfoPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstitutionalDemandGrid_AllocationSetManagerShareInfoParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AllocationSetManagerShareInfo' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const InstitutionalDemandGrid_DealLayoutPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstitutionalDemandGrid_DealLayoutParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DealLayout' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'institutionalTarget' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const InstitutionalDemandGrid_DraftAllocationSetsPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstitutionalDemandGrid_DraftAllocationSetsParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstitutionalDemandGridSummary' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institutionalDraftAllocationSets' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'authorCmgEntityKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isDefault' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shareTimestamp' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sharedWithManagers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'InstitutionalDemandGrid_AllocationSetManagerShareInfoParts',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'allocations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'allocationSetId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'indicationId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shareQuantity' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'auditInfo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'modifiedByFirmKey' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'modifiedAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'modifiedByUserEmail' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'auditInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'modifiedByFirmKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'modifiedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'modifiedByUserEmail' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dealLayout' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'InstitutionalDemandGrid_DealLayoutParts' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstitutionalDemandGrid_AllocationSetManagerShareInfoParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AllocationSetManagerShareInfo' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstitutionalDemandGrid_DealLayoutParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DealLayout' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'institutionalTarget' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const InstitutionalDemandGrid_FinalAllocationSetPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstitutionalDemandGrid_FinalAllocationSetParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstitutionalDemandGridSummary' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institutionalFinalAllocationSet' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isReleased' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'releaseTimestamp' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalAllocatedShareQuantity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shareTimestamp' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sharedWithManagers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'InstitutionalDemandGrid_AllocationSetManagerShareInfoParts',
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'authorCmgEntityKey' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'allocations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'indicationId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shareQuantity' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'investorReplySubmitterName' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'investorReply' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'auditInfo' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'modifiedByFirmKey' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'modifiedAt' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'modifiedByUserEmail' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'createdByUserEmail' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'auditInfo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'modifiedByFirmKey' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'modifiedAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'modifiedByUserEmail' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'auditInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'modifiedByFirmKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'modifiedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'modifiedByUserEmail' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dealLayout' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'InstitutionalDemandGrid_DealLayoutParts' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstitutionalDemandGrid_AllocationSetManagerShareInfoParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AllocationSetManagerShareInfo' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstitutionalDemandGrid_DealLayoutParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DealLayout' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'institutionalTarget' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const InstitutionalDemandGrid_IndicationNotePartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstitutionalDemandGrid_IndicationNoteParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstitutionalIndicationNote' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'auditInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modifiedAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const InstitutionalDemandGrid_IndicationAcknowledgementPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstitutionalDemandGrid_IndicationAcknowledgementParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstitutionalIndicationAcknowledgement' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'indicationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'indicationVersion' } },
          { kind: 'Field', name: { kind: 'Name', value: 'acknowledgedIndicationVersion' } },
          { kind: 'Field', name: { kind: 'Name', value: 'managerCmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'auditInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modifiedByFirmKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modifiedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modifiedByUserEmail' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdByUserEmail' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const InstitutionalDemandGrid_InvestorDeliveryStatusPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstitutionalDemandGrid_InvestorDeliveryStatusParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'InvestorDeliveryStatus' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overallDeliveryStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sentEmailCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalEmailCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'failedEmailCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inProgressEmailCount' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const InstitutionalDemandGrid_IndicationDemandsPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstitutionalDemandGrid_IndicationDemandsParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstitutionalDemandGridSummary' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institutionalIndicationDemands' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'demandMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'demandAtMarket' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'billingAndDeliveryAgentCmgEntityKey' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'acknowledgingUser' } },
                { kind: 'Field', name: { kind: 'Name', value: 'trancheId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'instrumentId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'coveringManagerCmgEntityKeys' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'demandLevels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'interestInShares' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'offeringId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'investorInformation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'bankInvestorName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bankInvestorKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isOnPlatform' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firmNameAbbreviation' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'submittedByCmgEntityKey' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'auditInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'modifiedByFirmKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'modifiedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'modifiedByUserEmail' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'interestLevels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'interestUnit' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'interestQuantity' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'limitPrice' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'limitType' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'duplicateOfIndicationId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'duplicateIndicationIds' } },
                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'notes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'InstitutionalDemandGrid_IndicationNoteParts',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'institutionalIndicationAcknowledgements' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'InstitutionalDemandGrid_IndicationAcknowledgementParts',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'prospectusDeliveryStatus' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'InstitutionalDemandGrid_InvestorDeliveryStatusParts',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstitutionalDemandGrid_IndicationNoteParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstitutionalIndicationNote' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'auditInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modifiedAt' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstitutionalDemandGrid_IndicationAcknowledgementParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstitutionalIndicationAcknowledgement' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'indicationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'indicationVersion' } },
          { kind: 'Field', name: { kind: 'Name', value: 'acknowledgedIndicationVersion' } },
          { kind: 'Field', name: { kind: 'Name', value: 'managerCmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'auditInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modifiedByFirmKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modifiedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modifiedByUserEmail' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdByUserEmail' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstitutionalDemandGrid_InvestorDeliveryStatusParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'InvestorDeliveryStatus' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overallDeliveryStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sentEmailCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalEmailCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'failedEmailCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inProgressEmailCount' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const InstitutionalDemandGrid_AttestationStatusesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'InstitutionalDemandGrid_AttestationStatuses' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKeys' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attestationStatuses' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cmgEntityKeys' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKeys' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firmId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useInstitutionalDemandGrid_AttestationStatusesQuery__
 *
 * To run a query within a React component, call `useInstitutionalDemandGrid_AttestationStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useInstitutionalDemandGrid_AttestationStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInstitutionalDemandGrid_AttestationStatusesQuery({
 *   variables: {
 *      cmgEntityKeys: // value for 'cmgEntityKeys'
 *   },
 * });
 */
export function useInstitutionalDemandGrid_AttestationStatusesQuery(
  baseOptions: Apollo.QueryHookOptions<
    InstitutionalDemandGrid_AttestationStatusesQuery,
    InstitutionalDemandGrid_AttestationStatusesQueryVariables
  > &
    (
      | { variables: InstitutionalDemandGrid_AttestationStatusesQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    InstitutionalDemandGrid_AttestationStatusesQuery,
    InstitutionalDemandGrid_AttestationStatusesQueryVariables
  >(InstitutionalDemandGrid_AttestationStatusesDocument, options);
}
export function useInstitutionalDemandGrid_AttestationStatusesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InstitutionalDemandGrid_AttestationStatusesQuery,
    InstitutionalDemandGrid_AttestationStatusesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    InstitutionalDemandGrid_AttestationStatusesQuery,
    InstitutionalDemandGrid_AttestationStatusesQueryVariables
  >(InstitutionalDemandGrid_AttestationStatusesDocument, options);
}
export function useInstitutionalDemandGrid_AttestationStatusesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        InstitutionalDemandGrid_AttestationStatusesQuery,
        InstitutionalDemandGrid_AttestationStatusesQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    InstitutionalDemandGrid_AttestationStatusesQuery,
    InstitutionalDemandGrid_AttestationStatusesQueryVariables
  >(InstitutionalDemandGrid_AttestationStatusesDocument, options);
}
export type InstitutionalDemandGrid_AttestationStatusesQueryHookResult = ReturnType<
  typeof useInstitutionalDemandGrid_AttestationStatusesQuery
>;
export type InstitutionalDemandGrid_AttestationStatusesLazyQueryHookResult = ReturnType<
  typeof useInstitutionalDemandGrid_AttestationStatusesLazyQuery
>;
export type InstitutionalDemandGrid_AttestationStatusesSuspenseQueryHookResult = ReturnType<
  typeof useInstitutionalDemandGrid_AttestationStatusesSuspenseQuery
>;
export type InstitutionalDemandGrid_AttestationStatusesQueryResult = Apollo.QueryResult<
  InstitutionalDemandGrid_AttestationStatusesQuery,
  InstitutionalDemandGrid_AttestationStatusesQueryVariables
>;
export const InstitutionalDemandGrid_ColumnConfigDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'InstitutionalDemandGrid_ColumnConfig' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'canManagePassiveOrderBook' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'publishedOffering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'derivedOfferPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pricingCurrencyCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'latestFilingPriceRangeLow' } },
                { kind: 'Field', name: { kind: 'Name', value: 'latestFilingPriceRangeHigh' } },
                { kind: 'Field', name: { kind: 'Name', value: 'initialFilingPriceRangeLow' } },
                { kind: 'Field', name: { kind: 'Name', value: 'initialFilingPriceRangeHigh' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'InstitutionalDemandGrid_OrderBookParts' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'InstitutionalDemandGrid_SyndicateParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'syndicateManagers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'skip' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'canManagePassiveOrderBook' },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'InstitutionalDemandGrid_SyndicateManagerParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstitutionalDemandGrid_OrderBookParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'OfferingProfile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orderBook' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstitutionalDemandGrid_SyndicateParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'OfferingProfile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'syndicate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'managers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firmNameAbbreviation' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'managerResponsibilities' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'settlementAgent' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstitutionalDemandGrid_SyndicateManagerParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SyndicateManager' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'managers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firmNameAbbreviation' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'managerResponsibilities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'settlementAgent' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useInstitutionalDemandGrid_ColumnConfigQuery__
 *
 * To run a query within a React component, call `useInstitutionalDemandGrid_ColumnConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useInstitutionalDemandGrid_ColumnConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInstitutionalDemandGrid_ColumnConfigQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      canManagePassiveOrderBook: // value for 'canManagePassiveOrderBook'
 *   },
 * });
 */
export function useInstitutionalDemandGrid_ColumnConfigQuery(
  baseOptions: Apollo.QueryHookOptions<
    InstitutionalDemandGrid_ColumnConfigQuery,
    InstitutionalDemandGrid_ColumnConfigQueryVariables
  > &
    (
      | { variables: InstitutionalDemandGrid_ColumnConfigQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    InstitutionalDemandGrid_ColumnConfigQuery,
    InstitutionalDemandGrid_ColumnConfigQueryVariables
  >(InstitutionalDemandGrid_ColumnConfigDocument, options);
}
export function useInstitutionalDemandGrid_ColumnConfigLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InstitutionalDemandGrid_ColumnConfigQuery,
    InstitutionalDemandGrid_ColumnConfigQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    InstitutionalDemandGrid_ColumnConfigQuery,
    InstitutionalDemandGrid_ColumnConfigQueryVariables
  >(InstitutionalDemandGrid_ColumnConfigDocument, options);
}
export function useInstitutionalDemandGrid_ColumnConfigSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        InstitutionalDemandGrid_ColumnConfigQuery,
        InstitutionalDemandGrid_ColumnConfigQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    InstitutionalDemandGrid_ColumnConfigQuery,
    InstitutionalDemandGrid_ColumnConfigQueryVariables
  >(InstitutionalDemandGrid_ColumnConfigDocument, options);
}
export type InstitutionalDemandGrid_ColumnConfigQueryHookResult = ReturnType<
  typeof useInstitutionalDemandGrid_ColumnConfigQuery
>;
export type InstitutionalDemandGrid_ColumnConfigLazyQueryHookResult = ReturnType<
  typeof useInstitutionalDemandGrid_ColumnConfigLazyQuery
>;
export type InstitutionalDemandGrid_ColumnConfigSuspenseQueryHookResult = ReturnType<
  typeof useInstitutionalDemandGrid_ColumnConfigSuspenseQuery
>;
export type InstitutionalDemandGrid_ColumnConfigQueryResult = Apollo.QueryResult<
  InstitutionalDemandGrid_ColumnConfigQuery,
  InstitutionalDemandGrid_ColumnConfigQueryVariables
>;
export const InstitutionalDemandGrid_DeleteIndicationBillingAndDeliveryAgentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {
        kind: 'Name',
        value: 'InstitutionalDemandGrid_DeleteIndicationBillingAndDeliveryAgent',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'indicationId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteInstitutionalIndicationBillingAndDeliveryAgent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'indicationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'indicationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'indicationId' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type InstitutionalDemandGrid_DeleteIndicationBillingAndDeliveryAgentMutationFn =
  Apollo.MutationFunction<
    InstitutionalDemandGrid_DeleteIndicationBillingAndDeliveryAgentMutation,
    InstitutionalDemandGrid_DeleteIndicationBillingAndDeliveryAgentMutationVariables
  >;

/**
 * __useInstitutionalDemandGrid_DeleteIndicationBillingAndDeliveryAgentMutation__
 *
 * To run a mutation, you first call `useInstitutionalDemandGrid_DeleteIndicationBillingAndDeliveryAgentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInstitutionalDemandGrid_DeleteIndicationBillingAndDeliveryAgentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [institutionalDemandGridDeleteIndicationBillingAndDeliveryAgentMutation, { data, loading, error }] = useInstitutionalDemandGrid_DeleteIndicationBillingAndDeliveryAgentMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      indicationId: // value for 'indicationId'
 *   },
 * });
 */
export function useInstitutionalDemandGrid_DeleteIndicationBillingAndDeliveryAgentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InstitutionalDemandGrid_DeleteIndicationBillingAndDeliveryAgentMutation,
    InstitutionalDemandGrid_DeleteIndicationBillingAndDeliveryAgentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InstitutionalDemandGrid_DeleteIndicationBillingAndDeliveryAgentMutation,
    InstitutionalDemandGrid_DeleteIndicationBillingAndDeliveryAgentMutationVariables
  >(InstitutionalDemandGrid_DeleteIndicationBillingAndDeliveryAgentDocument, options);
}
export type InstitutionalDemandGrid_DeleteIndicationBillingAndDeliveryAgentMutationHookResult =
  ReturnType<typeof useInstitutionalDemandGrid_DeleteIndicationBillingAndDeliveryAgentMutation>;
export type InstitutionalDemandGrid_DeleteIndicationBillingAndDeliveryAgentMutationResult =
  Apollo.MutationResult<InstitutionalDemandGrid_DeleteIndicationBillingAndDeliveryAgentMutation>;
export type InstitutionalDemandGrid_DeleteIndicationBillingAndDeliveryAgentMutationOptions =
  Apollo.BaseMutationOptions<
    InstitutionalDemandGrid_DeleteIndicationBillingAndDeliveryAgentMutation,
    InstitutionalDemandGrid_DeleteIndicationBillingAndDeliveryAgentMutationVariables
  >;
export const InstitutionalDemandGrid_FirmInvestorExtendedDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'InstitutionalDemandGrid_FirmInvestorExtendedData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'firmKey' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'firmInvestorExtendedData' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'firmKey' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'firmKey' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firmKey' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'investorTypes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'investorRegion' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'revenues' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'compliance' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'investorComments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'otherInvestorAttributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useInstitutionalDemandGrid_FirmInvestorExtendedDataQuery__
 *
 * To run a query within a React component, call `useInstitutionalDemandGrid_FirmInvestorExtendedDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useInstitutionalDemandGrid_FirmInvestorExtendedDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInstitutionalDemandGrid_FirmInvestorExtendedDataQuery({
 *   variables: {
 *      firmKey: // value for 'firmKey'
 *   },
 * });
 */
export function useInstitutionalDemandGrid_FirmInvestorExtendedDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    InstitutionalDemandGrid_FirmInvestorExtendedDataQuery,
    InstitutionalDemandGrid_FirmInvestorExtendedDataQueryVariables
  > &
    (
      | {
          variables: InstitutionalDemandGrid_FirmInvestorExtendedDataQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    InstitutionalDemandGrid_FirmInvestorExtendedDataQuery,
    InstitutionalDemandGrid_FirmInvestorExtendedDataQueryVariables
  >(InstitutionalDemandGrid_FirmInvestorExtendedDataDocument, options);
}
export function useInstitutionalDemandGrid_FirmInvestorExtendedDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InstitutionalDemandGrid_FirmInvestorExtendedDataQuery,
    InstitutionalDemandGrid_FirmInvestorExtendedDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    InstitutionalDemandGrid_FirmInvestorExtendedDataQuery,
    InstitutionalDemandGrid_FirmInvestorExtendedDataQueryVariables
  >(InstitutionalDemandGrid_FirmInvestorExtendedDataDocument, options);
}
export function useInstitutionalDemandGrid_FirmInvestorExtendedDataSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        InstitutionalDemandGrid_FirmInvestorExtendedDataQuery,
        InstitutionalDemandGrid_FirmInvestorExtendedDataQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    InstitutionalDemandGrid_FirmInvestorExtendedDataQuery,
    InstitutionalDemandGrid_FirmInvestorExtendedDataQueryVariables
  >(InstitutionalDemandGrid_FirmInvestorExtendedDataDocument, options);
}
export type InstitutionalDemandGrid_FirmInvestorExtendedDataQueryHookResult = ReturnType<
  typeof useInstitutionalDemandGrid_FirmInvestorExtendedDataQuery
>;
export type InstitutionalDemandGrid_FirmInvestorExtendedDataLazyQueryHookResult = ReturnType<
  typeof useInstitutionalDemandGrid_FirmInvestorExtendedDataLazyQuery
>;
export type InstitutionalDemandGrid_FirmInvestorExtendedDataSuspenseQueryHookResult = ReturnType<
  typeof useInstitutionalDemandGrid_FirmInvestorExtendedDataSuspenseQuery
>;
export type InstitutionalDemandGrid_FirmInvestorExtendedDataQueryResult = Apollo.QueryResult<
  InstitutionalDemandGrid_FirmInvestorExtendedDataQuery,
  InstitutionalDemandGrid_FirmInvestorExtendedDataQueryVariables
>;
export const InstitutionalDemandGrid_FirmInvestorsExtendedDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'InstitutionalDemandGrid_FirmInvestorsExtendedData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'firmKeys' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'firmInvestorsExtendedData' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'firmKeys' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'firmKeys' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firmKey' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'investorTypes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'investorRegion' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'revenues' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'compliance' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'investorComments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'otherInvestorAttributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useInstitutionalDemandGrid_FirmInvestorsExtendedDataQuery__
 *
 * To run a query within a React component, call `useInstitutionalDemandGrid_FirmInvestorsExtendedDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useInstitutionalDemandGrid_FirmInvestorsExtendedDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInstitutionalDemandGrid_FirmInvestorsExtendedDataQuery({
 *   variables: {
 *      firmKeys: // value for 'firmKeys'
 *   },
 * });
 */
export function useInstitutionalDemandGrid_FirmInvestorsExtendedDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    InstitutionalDemandGrid_FirmInvestorsExtendedDataQuery,
    InstitutionalDemandGrid_FirmInvestorsExtendedDataQueryVariables
  > &
    (
      | {
          variables: InstitutionalDemandGrid_FirmInvestorsExtendedDataQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    InstitutionalDemandGrid_FirmInvestorsExtendedDataQuery,
    InstitutionalDemandGrid_FirmInvestorsExtendedDataQueryVariables
  >(InstitutionalDemandGrid_FirmInvestorsExtendedDataDocument, options);
}
export function useInstitutionalDemandGrid_FirmInvestorsExtendedDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InstitutionalDemandGrid_FirmInvestorsExtendedDataQuery,
    InstitutionalDemandGrid_FirmInvestorsExtendedDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    InstitutionalDemandGrid_FirmInvestorsExtendedDataQuery,
    InstitutionalDemandGrid_FirmInvestorsExtendedDataQueryVariables
  >(InstitutionalDemandGrid_FirmInvestorsExtendedDataDocument, options);
}
export function useInstitutionalDemandGrid_FirmInvestorsExtendedDataSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        InstitutionalDemandGrid_FirmInvestorsExtendedDataQuery,
        InstitutionalDemandGrid_FirmInvestorsExtendedDataQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    InstitutionalDemandGrid_FirmInvestorsExtendedDataQuery,
    InstitutionalDemandGrid_FirmInvestorsExtendedDataQueryVariables
  >(InstitutionalDemandGrid_FirmInvestorsExtendedDataDocument, options);
}
export type InstitutionalDemandGrid_FirmInvestorsExtendedDataQueryHookResult = ReturnType<
  typeof useInstitutionalDemandGrid_FirmInvestorsExtendedDataQuery
>;
export type InstitutionalDemandGrid_FirmInvestorsExtendedDataLazyQueryHookResult = ReturnType<
  typeof useInstitutionalDemandGrid_FirmInvestorsExtendedDataLazyQuery
>;
export type InstitutionalDemandGrid_FirmInvestorsExtendedDataSuspenseQueryHookResult = ReturnType<
  typeof useInstitutionalDemandGrid_FirmInvestorsExtendedDataSuspenseQuery
>;
export type InstitutionalDemandGrid_FirmInvestorsExtendedDataQueryResult = Apollo.QueryResult<
  InstitutionalDemandGrid_FirmInvestorsExtendedDataQuery,
  InstitutionalDemandGrid_FirmInvestorsExtendedDataQueryVariables
>;
export const InstitutionalDemandGrid_InvestorFirmsEmployeeRelationshipsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'InstitutionalDemandGrid_InvestorFirmsEmployeeRelationships' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bankInvestorKeys' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investorFirmsEmployeeRelationships' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bankInvestorKeys' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bankInvestorKeys' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'bankInvestorKey' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'relationships' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'underwriterEmployeeName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'relationshipType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useInstitutionalDemandGrid_InvestorFirmsEmployeeRelationshipsQuery__
 *
 * To run a query within a React component, call `useInstitutionalDemandGrid_InvestorFirmsEmployeeRelationshipsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInstitutionalDemandGrid_InvestorFirmsEmployeeRelationshipsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInstitutionalDemandGrid_InvestorFirmsEmployeeRelationshipsQuery({
 *   variables: {
 *      bankInvestorKeys: // value for 'bankInvestorKeys'
 *   },
 * });
 */
export function useInstitutionalDemandGrid_InvestorFirmsEmployeeRelationshipsQuery(
  baseOptions: Apollo.QueryHookOptions<
    InstitutionalDemandGrid_InvestorFirmsEmployeeRelationshipsQuery,
    InstitutionalDemandGrid_InvestorFirmsEmployeeRelationshipsQueryVariables
  > &
    (
      | {
          variables: InstitutionalDemandGrid_InvestorFirmsEmployeeRelationshipsQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    InstitutionalDemandGrid_InvestorFirmsEmployeeRelationshipsQuery,
    InstitutionalDemandGrid_InvestorFirmsEmployeeRelationshipsQueryVariables
  >(InstitutionalDemandGrid_InvestorFirmsEmployeeRelationshipsDocument, options);
}
export function useInstitutionalDemandGrid_InvestorFirmsEmployeeRelationshipsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InstitutionalDemandGrid_InvestorFirmsEmployeeRelationshipsQuery,
    InstitutionalDemandGrid_InvestorFirmsEmployeeRelationshipsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    InstitutionalDemandGrid_InvestorFirmsEmployeeRelationshipsQuery,
    InstitutionalDemandGrid_InvestorFirmsEmployeeRelationshipsQueryVariables
  >(InstitutionalDemandGrid_InvestorFirmsEmployeeRelationshipsDocument, options);
}
export function useInstitutionalDemandGrid_InvestorFirmsEmployeeRelationshipsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        InstitutionalDemandGrid_InvestorFirmsEmployeeRelationshipsQuery,
        InstitutionalDemandGrid_InvestorFirmsEmployeeRelationshipsQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    InstitutionalDemandGrid_InvestorFirmsEmployeeRelationshipsQuery,
    InstitutionalDemandGrid_InvestorFirmsEmployeeRelationshipsQueryVariables
  >(InstitutionalDemandGrid_InvestorFirmsEmployeeRelationshipsDocument, options);
}
export type InstitutionalDemandGrid_InvestorFirmsEmployeeRelationshipsQueryHookResult = ReturnType<
  typeof useInstitutionalDemandGrid_InvestorFirmsEmployeeRelationshipsQuery
>;
export type InstitutionalDemandGrid_InvestorFirmsEmployeeRelationshipsLazyQueryHookResult =
  ReturnType<typeof useInstitutionalDemandGrid_InvestorFirmsEmployeeRelationshipsLazyQuery>;
export type InstitutionalDemandGrid_InvestorFirmsEmployeeRelationshipsSuspenseQueryHookResult =
  ReturnType<typeof useInstitutionalDemandGrid_InvestorFirmsEmployeeRelationshipsSuspenseQuery>;
export type InstitutionalDemandGrid_InvestorFirmsEmployeeRelationshipsQueryResult =
  Apollo.QueryResult<
    InstitutionalDemandGrid_InvestorFirmsEmployeeRelationshipsQuery,
    InstitutionalDemandGrid_InvestorFirmsEmployeeRelationshipsQueryVariables
  >;
export const InstitutionalDemandGrid_SummaryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'InstitutionalDemandGrid_Summary' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'InstitutionalIndicationDemandQueryInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institutionalDemandGridSummary' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'InstitutionalDemandGrid_DraftAllocationSetsParts' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'InstitutionalDemandGrid_FinalAllocationSetParts' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'InstitutionalDemandGrid_IndicationDemandsParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstitutionalDemandGrid_AllocationSetManagerShareInfoParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AllocationSetManagerShareInfo' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstitutionalDemandGrid_DealLayoutParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DealLayout' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'institutionalTarget' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstitutionalDemandGrid_DraftAllocationSetsParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstitutionalDemandGridSummary' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institutionalDraftAllocationSets' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'authorCmgEntityKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isDefault' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shareTimestamp' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sharedWithManagers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'InstitutionalDemandGrid_AllocationSetManagerShareInfoParts',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'allocations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'allocationSetId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'indicationId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shareQuantity' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'auditInfo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'modifiedByFirmKey' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'modifiedAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'modifiedByUserEmail' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'auditInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'modifiedByFirmKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'modifiedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'modifiedByUserEmail' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dealLayout' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'InstitutionalDemandGrid_DealLayoutParts' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstitutionalDemandGrid_FinalAllocationSetParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstitutionalDemandGridSummary' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institutionalFinalAllocationSet' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isReleased' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'releaseTimestamp' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalAllocatedShareQuantity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shareTimestamp' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sharedWithManagers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'InstitutionalDemandGrid_AllocationSetManagerShareInfoParts',
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'authorCmgEntityKey' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'allocations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'indicationId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shareQuantity' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'investorReplySubmitterName' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'investorReply' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'auditInfo' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'modifiedByFirmKey' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'modifiedAt' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'modifiedByUserEmail' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'createdByUserEmail' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'auditInfo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'modifiedByFirmKey' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'modifiedAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'modifiedByUserEmail' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'auditInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'modifiedByFirmKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'modifiedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'modifiedByUserEmail' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dealLayout' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'InstitutionalDemandGrid_DealLayoutParts' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstitutionalDemandGrid_IndicationNoteParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstitutionalIndicationNote' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'auditInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modifiedAt' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstitutionalDemandGrid_IndicationAcknowledgementParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstitutionalIndicationAcknowledgement' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'indicationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'indicationVersion' } },
          { kind: 'Field', name: { kind: 'Name', value: 'acknowledgedIndicationVersion' } },
          { kind: 'Field', name: { kind: 'Name', value: 'managerCmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'auditInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modifiedByFirmKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modifiedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modifiedByUserEmail' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdByUserEmail' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstitutionalDemandGrid_InvestorDeliveryStatusParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'InvestorDeliveryStatus' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overallDeliveryStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sentEmailCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalEmailCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'failedEmailCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inProgressEmailCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstitutionalDemandGrid_IndicationDemandsParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstitutionalDemandGridSummary' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institutionalIndicationDemands' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'demandMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'demandAtMarket' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'billingAndDeliveryAgentCmgEntityKey' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'acknowledgingUser' } },
                { kind: 'Field', name: { kind: 'Name', value: 'trancheId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'instrumentId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'coveringManagerCmgEntityKeys' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'demandLevels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'interestInShares' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'offeringId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'investorInformation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'bankInvestorName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bankInvestorKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isOnPlatform' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firmNameAbbreviation' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'submittedByCmgEntityKey' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'auditInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'modifiedByFirmKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'modifiedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'modifiedByUserEmail' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'interestLevels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'interestUnit' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'interestQuantity' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'limitPrice' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'limitType' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'duplicateOfIndicationId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'duplicateIndicationIds' } },
                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'notes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'InstitutionalDemandGrid_IndicationNoteParts',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'institutionalIndicationAcknowledgements' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'InstitutionalDemandGrid_IndicationAcknowledgementParts',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'prospectusDeliveryStatus' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'InstitutionalDemandGrid_InvestorDeliveryStatusParts',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useInstitutionalDemandGrid_SummaryQuery__
 *
 * To run a query within a React component, call `useInstitutionalDemandGrid_SummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useInstitutionalDemandGrid_SummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInstitutionalDemandGrid_SummaryQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInstitutionalDemandGrid_SummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    InstitutionalDemandGrid_SummaryQuery,
    InstitutionalDemandGrid_SummaryQueryVariables
  > &
    (
      | { variables: InstitutionalDemandGrid_SummaryQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    InstitutionalDemandGrid_SummaryQuery,
    InstitutionalDemandGrid_SummaryQueryVariables
  >(InstitutionalDemandGrid_SummaryDocument, options);
}
export function useInstitutionalDemandGrid_SummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InstitutionalDemandGrid_SummaryQuery,
    InstitutionalDemandGrid_SummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    InstitutionalDemandGrid_SummaryQuery,
    InstitutionalDemandGrid_SummaryQueryVariables
  >(InstitutionalDemandGrid_SummaryDocument, options);
}
export function useInstitutionalDemandGrid_SummarySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        InstitutionalDemandGrid_SummaryQuery,
        InstitutionalDemandGrid_SummaryQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    InstitutionalDemandGrid_SummaryQuery,
    InstitutionalDemandGrid_SummaryQueryVariables
  >(InstitutionalDemandGrid_SummaryDocument, options);
}
export type InstitutionalDemandGrid_SummaryQueryHookResult = ReturnType<
  typeof useInstitutionalDemandGrid_SummaryQuery
>;
export type InstitutionalDemandGrid_SummaryLazyQueryHookResult = ReturnType<
  typeof useInstitutionalDemandGrid_SummaryLazyQuery
>;
export type InstitutionalDemandGrid_SummarySuspenseQueryHookResult = ReturnType<
  typeof useInstitutionalDemandGrid_SummarySuspenseQuery
>;
export type InstitutionalDemandGrid_SummaryQueryResult = Apollo.QueryResult<
  InstitutionalDemandGrid_SummaryQuery,
  InstitutionalDemandGrid_SummaryQueryVariables
>;
export const InstitutionalDemandGrid_UpdateIndicationBillingAndDeliveryAgentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {
        kind: 'Name',
        value: 'InstitutionalDemandGrid_UpdateIndicationBillingAndDeliveryAgent',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'indicationId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BillingAndDeliveryAgentUpdateInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateInstitutionalIndicationBillingAndDeliveryAgent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'indicationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'indicationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'managerCmgEntityKey' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type InstitutionalDemandGrid_UpdateIndicationBillingAndDeliveryAgentMutationFn =
  Apollo.MutationFunction<
    InstitutionalDemandGrid_UpdateIndicationBillingAndDeliveryAgentMutation,
    InstitutionalDemandGrid_UpdateIndicationBillingAndDeliveryAgentMutationVariables
  >;

/**
 * __useInstitutionalDemandGrid_UpdateIndicationBillingAndDeliveryAgentMutation__
 *
 * To run a mutation, you first call `useInstitutionalDemandGrid_UpdateIndicationBillingAndDeliveryAgentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInstitutionalDemandGrid_UpdateIndicationBillingAndDeliveryAgentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [institutionalDemandGridUpdateIndicationBillingAndDeliveryAgentMutation, { data, loading, error }] = useInstitutionalDemandGrid_UpdateIndicationBillingAndDeliveryAgentMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      indicationId: // value for 'indicationId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInstitutionalDemandGrid_UpdateIndicationBillingAndDeliveryAgentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InstitutionalDemandGrid_UpdateIndicationBillingAndDeliveryAgentMutation,
    InstitutionalDemandGrid_UpdateIndicationBillingAndDeliveryAgentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InstitutionalDemandGrid_UpdateIndicationBillingAndDeliveryAgentMutation,
    InstitutionalDemandGrid_UpdateIndicationBillingAndDeliveryAgentMutationVariables
  >(InstitutionalDemandGrid_UpdateIndicationBillingAndDeliveryAgentDocument, options);
}
export type InstitutionalDemandGrid_UpdateIndicationBillingAndDeliveryAgentMutationHookResult =
  ReturnType<typeof useInstitutionalDemandGrid_UpdateIndicationBillingAndDeliveryAgentMutation>;
export type InstitutionalDemandGrid_UpdateIndicationBillingAndDeliveryAgentMutationResult =
  Apollo.MutationResult<InstitutionalDemandGrid_UpdateIndicationBillingAndDeliveryAgentMutation>;
export type InstitutionalDemandGrid_UpdateIndicationBillingAndDeliveryAgentMutationOptions =
  Apollo.BaseMutationOptions<
    InstitutionalDemandGrid_UpdateIndicationBillingAndDeliveryAgentMutation,
    InstitutionalDemandGrid_UpdateIndicationBillingAndDeliveryAgentMutationVariables
  >;

import { makeMockResponse } from '../../../../../../../graphql/mock-response';

export const makeInstitutionalDemandGrid_AttestationStatusesMockResponse = makeMockResponse<
  InstitutionalDemandGrid_AttestationStatusesQueryVariables,
  InstitutionalDemandGrid_AttestationStatusesQuery
>(InstitutionalDemandGrid_AttestationStatusesDocument);

export const makeInstitutionalDemandGrid_ColumnConfigMockResponse = makeMockResponse<
  InstitutionalDemandGrid_ColumnConfigQueryVariables,
  InstitutionalDemandGrid_ColumnConfigQuery
>(InstitutionalDemandGrid_ColumnConfigDocument);

export const makeInstitutionalDemandGrid_FirmInvestorExtendedDataMockResponse = makeMockResponse<
  InstitutionalDemandGrid_FirmInvestorExtendedDataQueryVariables,
  InstitutionalDemandGrid_FirmInvestorExtendedDataQuery
>(InstitutionalDemandGrid_FirmInvestorExtendedDataDocument);

export const makeInstitutionalDemandGrid_FirmInvestorsExtendedDataMockResponse = makeMockResponse<
  InstitutionalDemandGrid_FirmInvestorsExtendedDataQueryVariables,
  InstitutionalDemandGrid_FirmInvestorsExtendedDataQuery
>(InstitutionalDemandGrid_FirmInvestorsExtendedDataDocument);

export const makeInstitutionalDemandGrid_InvestorFirmsEmployeeRelationshipsMockResponse =
  makeMockResponse<
    InstitutionalDemandGrid_InvestorFirmsEmployeeRelationshipsQueryVariables,
    InstitutionalDemandGrid_InvestorFirmsEmployeeRelationshipsQuery
  >(InstitutionalDemandGrid_InvestorFirmsEmployeeRelationshipsDocument);

export const makeInstitutionalDemandGrid_SummaryMockResponse = makeMockResponse<
  InstitutionalDemandGrid_SummaryQueryVariables,
  InstitutionalDemandGrid_SummaryQuery
>(InstitutionalDemandGrid_SummaryDocument);
