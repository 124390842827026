import type { UUID } from '@cmg/common';
import type { GridRowSelectionState } from '@cmg/data-grid';
import { useDataGridContext } from '@cmg/data-grid';
import { SnackbarManager } from '@cmg/design-system';
import { useCallback } from 'react';

import { useDialogState } from '../../institutional-demand/components/institutional-demand-grid/toolbar/allocations-dropdown-menu/allocations-dropdown-buton/common/hooks/useDialogState';
import { getBulkActionsFilterInput } from '../demand-grid/DemandGrid.model';
import type { DemandConfigValues } from '../demand-grid/types';
import { useOrderBook_InstitutionalDemand_BulkUpdateBndAgentMutation } from '../graphql/__generated__';
import { refetchDemandGridReactiveVar } from './useRefetchDemandGridReactiveVar';

export type Props = Readonly<{
  offeringId: UUID;
  demandConfig: DemandConfigValues | null;
  rowSelectionState: GridRowSelectionState;
}>;

export const useBulkAssignBndAgentDialogState = ({
  offeringId,
  rowSelectionState,
  demandConfig,
}: Props) => {
  const dialogState = useDialogState();
  const { getFilters } = useDataGridContext();

  const [bulkUpdateBndAgent, { error, loading }] =
    useOrderBook_InstitutionalDemand_BulkUpdateBndAgentMutation();

  const onSave = useCallback(
    async (bndAgentKey: string | null) => {
      if (!demandConfig) {
        return;
      }

      await bulkUpdateBndAgent({
        variables: {
          offeringId,
          bndAgentKey,
          applyTo: {
            ...demandConfig,
            where: getBulkActionsFilterInput(rowSelectionState, getFilters()?.filterInput),
          },
        },
      });

      refetchDemandGridReactiveVar(true);
      SnackbarManager.success('B&D Agent have been successfully assigned.');
    },
    [bulkUpdateBndAgent, demandConfig, getFilters, offeringId, rowSelectionState]
  );

  return { ...dialogState, onSave, error, loading };
};
