import type { UUID } from '@cmg/common';
import { SnackbarManager } from '@cmg/design-system';
import { useCallback } from 'react';

import {
  useOrderBook_InstitutionalDemand_UnassignBndAgentMutation,
  useOrderBook_InstitutionalDemand_UpdateBndAgentMutation,
} from '../graphql/__generated__';
import { refetchDemandGridReactiveVar } from './useRefetchDemandGridReactiveVar';

export type Props = Readonly<{
  offeringId: UUID;
}>;

export const useAssignBndAgent = ({ offeringId }: Props) => {
  const [updateBndAgent] = useOrderBook_InstitutionalDemand_UpdateBndAgentMutation();
  const [unassignBndAgent] = useOrderBook_InstitutionalDemand_UnassignBndAgentMutation();

  return useCallback(
    async (indicationId: UUID, bndAgentKey: string | null) => {
      const variables = {
        offeringId,
        indicationId,
      };

      try {
        if (bndAgentKey) {
          await updateBndAgent({ variables: { ...variables, bndAgentKey } });
        } else {
          await unassignBndAgent({ variables });
        }

        refetchDemandGridReactiveVar(true);
      } catch {
        SnackbarManager.error('Failed to update B&D Agent.');
      }
    },
    [offeringId, unassignBndAgent, updateBndAgent]
  );
};
