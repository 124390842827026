/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IsOfferingAuthor_OfferingQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type IsOfferingAuthor_OfferingQuery = {
  readonly __typename?: 'Query';
  readonly offering: {
    readonly __typename?: 'Offering';
    readonly id: string;
    readonly isAuthor: boolean;
  };
};

export const IsOfferingAuthor_OfferingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'IsOfferingAuthor_Offering' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'offering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isAuthor' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useIsOfferingAuthor_OfferingQuery__
 *
 * To run a query within a React component, call `useIsOfferingAuthor_OfferingQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsOfferingAuthor_OfferingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsOfferingAuthor_OfferingQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useIsOfferingAuthor_OfferingQuery(
  baseOptions: Apollo.QueryHookOptions<
    IsOfferingAuthor_OfferingQuery,
    IsOfferingAuthor_OfferingQueryVariables
  > &
    ({ variables: IsOfferingAuthor_OfferingQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IsOfferingAuthor_OfferingQuery, IsOfferingAuthor_OfferingQueryVariables>(
    IsOfferingAuthor_OfferingDocument,
    options
  );
}
export function useIsOfferingAuthor_OfferingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IsOfferingAuthor_OfferingQuery,
    IsOfferingAuthor_OfferingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    IsOfferingAuthor_OfferingQuery,
    IsOfferingAuthor_OfferingQueryVariables
  >(IsOfferingAuthor_OfferingDocument, options);
}
export function useIsOfferingAuthor_OfferingSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        IsOfferingAuthor_OfferingQuery,
        IsOfferingAuthor_OfferingQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    IsOfferingAuthor_OfferingQuery,
    IsOfferingAuthor_OfferingQueryVariables
  >(IsOfferingAuthor_OfferingDocument, options);
}
export type IsOfferingAuthor_OfferingQueryHookResult = ReturnType<
  typeof useIsOfferingAuthor_OfferingQuery
>;
export type IsOfferingAuthor_OfferingLazyQueryHookResult = ReturnType<
  typeof useIsOfferingAuthor_OfferingLazyQuery
>;
export type IsOfferingAuthor_OfferingSuspenseQueryHookResult = ReturnType<
  typeof useIsOfferingAuthor_OfferingSuspenseQuery
>;
export type IsOfferingAuthor_OfferingQueryResult = Apollo.QueryResult<
  IsOfferingAuthor_OfferingQuery,
  IsOfferingAuthor_OfferingQueryVariables
>;

import { makeMockResponse } from '../../../../graphql/mock-response';

export const makeIsOfferingAuthor_OfferingMockResponse = makeMockResponse<
  IsOfferingAuthor_OfferingQueryVariables,
  IsOfferingAuthor_OfferingQuery
>(IsOfferingAuthor_OfferingDocument);
