import {
  OIDCLogin,
  OIDCLoginCallback,
  OIDCLogout,
  OIDCLogoutCallback,
  permissionsByEntity,
  useCheckAccountType,
} from '@cmg/auth';
import { apiTypes, ApplicationError, urlUtil } from '@cmg/common';
import React from 'react';
import { StaticContext } from 'react-router';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';

import SupportRequestModal from '../../common/components/modals/support-request-modal/SupportRequestModal';
import useCertificateLibraryAccess from '../../common/hooks/useCertificateLibraryAccess';
import useCertificateReviewAccess from '../../common/hooks/useCertificateReviewAccess';
import useHasMyOrders from '../../common/hooks/useHasMyOrders';
import { PrivateRoute } from '../../common/routing/private-route/PrivateRoute';
import routeFactory from '../../common/util/routeFactory';
import CalendarRoute from '../calendar/CalendarRoute';
import CertificateLibraryRoute from '../certificate-library/CertificateLibraryPage';
import { BrowserNotificationsDocumentationRoute } from '../documentation/BrowserNotificationsDocumentationRoute';
import LoggedOutRoute from '../logged-out/LoggedOutRoute';
import MyOfferingsRoute from '../my-offerings/MyOfferingsRoute';
import MyOrdersRoute from '../my-orders/MyOrdersRoute';
import { useHasOfferingAccess } from '../offering/hooks/useHasOfferingAccess';
import OfferingRoute from '../offering/OfferingRoute';
import { CmgEntityKeyProvider } from '../offering-side-panel/contexts/CmgEntityKeyContext';
import { FirmKeyOrIdProvider } from '../offering-side-panel/contexts/FirmKeyOrIdContext';
import { OfferingSidePanelContainer } from '../offering-side-panel/OfferingSidePanelContainer';
import CrmSelectModal from '../offering-side-panel/order-book/indication-activity/components/crm-selection/CrmSelectModal';
import SharedDraftOfferingRoute from '../shared-draft-offering/SharedDraftOfferingRoute';
import { InvitationWireResponseRoute } from '../syndicate-wires/public-routes/invitation-wire-response/InvitationWireResponseRoute';

type NoAccessProps = {
  isLoggedIn: boolean;
} & RouteComponentProps;

/**
 * NoAccessRedirect fallback redirect wrapper
 *
 * When the user is not logged in - redirect the user back
 * to the oidc login route url and set the location state to the current location.
 * When the user is logged in - show an error page.
 */
export const NoAccessRedirect: React.FC<NoAccessProps> = ({ location, isLoggedIn }) => {
  // User is not logged in - redirect to login
  if (!isLoggedIn) {
    return (
      <Redirect
        to={{
          pathname: routeFactory.oidcLogin.getUrlPath(),
          state: routeFactory.oidcLogin.getRouteState({
            location: location,
          }),
        }}
      />
    );
  }

  // User is logged in but does not have permissions to view this route
  return <div>Access Denied</div>;
};

/**
 * Responsible for application level routes. Most routes in our sitemap should be represented here.
 * Occasionally we will use the ReactRouter v4 feature of nesting <Routes> further down the component tree.
 */
export const RootRouter: React.FC = () => {
  const hasMyOrders = useHasMyOrders();
  const isSellSide = useCheckAccountType('SELL_SIDE');
  const hasOfferingAccess = useHasOfferingAccess();
  const hasMyOfferings = hasOfferingAccess && isSellSide && !hasMyOrders;
  const {
    canRenderRoute: canRenderCertificateLibraryRoute,
    permissions: certificateLibraryPermissions,
  } = useCertificateLibraryAccess();
  const {
    canRenderRoute: canRenderCertificateReviewRoute,
    permissions: certificateReviewPermissions,
  } = useCertificateReviewAccess();

  return (
    <React.Fragment>
      <Switch>
        {/* OIDC flow */}
        <Route exact path={routeFactory.loggedOut.routePath} component={LoggedOutRoute} />
        <Route
          exact
          path={routeFactory.oidcLogin.routePath}
          render={routeProps => {
            const { location } = routeProps as RouteComponentProps<
              {},
              StaticContext,
              { from?: { pathname: string; search: string } }
            >;
            /**
             * if location is set, then it is set to { from: pathname }
             */
            const pathname = location.state?.from?.pathname || '/';
            const search = location.state?.from?.search ?? '';
            const returnUrl = pathname + search;

            return (
              <OIDCLogin
                onError={() => {
                  routeProps.history.push(routeFactory.error.getUrlPath({}));
                }}
                returnUrl={returnUrl}
              />
            );
          }}
        />
        <Route exact path={routeFactory.oidcLogout.routePath} component={OIDCLogout} />
        <Route
          exact
          path={routeFactory.oidcLoginCallback.routePath}
          render={routeProps => (
            <OIDCLoginCallback
              onSuccess={returnUrl => {
                routeProps.history.push(returnUrl || routeFactory.root.getUrlPath());
              }}
            />
          )}
        />
        <Route
          exact
          path={routeFactory.oidcLogoutCallback.routePath}
          render={routeProps => (
            <OIDCLogoutCallback
              onSuccess={() => {
                routeProps.history.push(routeFactory.loggedOut.getUrlPath({}));
              }}
            />
          )}
        />
        <PrivateRoute
          path={routeFactory.calendar.routePath}
          renderNoAccess={({ isLoggedIn, ...routeProps }) => (
            <NoAccessRedirect {...routeProps} isLoggedIn={isLoggedIn} />
          )}
          component={CalendarRoute}
        />
        <PrivateRoute
          path={routeFactory.myOfferings.routePath}
          renderNoAccess={({ isLoggedIn, ...routeProps }) => (
            <NoAccessRedirect {...routeProps} isLoggedIn={isLoggedIn} />
          )}
          accessCheck={() => hasMyOfferings}
          component={MyOfferingsRoute}
        />
        <PrivateRoute
          path={routeFactory.myOrders.routePath}
          requiredPermissions={[permissionsByEntity.CoveredAccountsInstitutionalIndication.READ]}
          renderNoAccess={({ isLoggedIn, ...routeProps }) => (
            <NoAccessRedirect {...routeProps} isLoggedIn={isLoggedIn} />
          )}
          component={MyOrdersRoute}
        />

        <PrivateRoute
          path={routeFactory.offeringCreate.routePath}
          renderNoAccess={({ isLoggedIn, ...routeProps }) => (
            <NoAccessRedirect {...routeProps} isLoggedIn={isLoggedIn} />
          )}
          component={OfferingRoute}
        />
        <PrivateRoute
          path={routeFactory.offering.routePath}
          renderNoAccess={({ isLoggedIn, ...routeProps }) => (
            <NoAccessRedirect {...routeProps} isLoggedIn={isLoggedIn} />
          )}
          component={OfferingRoute}
        />
        <PrivateRoute
          path={routeFactory.sharedDraftOffering.routePath}
          requiredPermissions={[permissionsByEntity.Offering.READ]}
          renderNoAccess={({ isLoggedIn, ...routeProps }) => (
            <NoAccessRedirect {...routeProps} isLoggedIn={isLoggedIn} />
          )}
          component={SharedDraftOfferingRoute}
        />
        <PrivateRoute
          path={routeFactory.certificateLibrary.routePath}
          renderNoAccess={({ isLoggedIn, ...routeProps }) => (
            <NoAccessRedirect {...routeProps} isLoggedIn={isLoggedIn} />
          )}
          accessCheck={() => canRenderCertificateLibraryRoute || canRenderCertificateReviewRoute}
          requiredPermissions={[...certificateLibraryPermissions, ...certificateReviewPermissions]}
          requireAllPermissions={false}
          component={CertificateLibraryRoute}
        />
        <PrivateRoute
          path={routeFactory.browserNotificationDocumentation.routePath}
          renderNoAccess={({ isLoggedIn, ...routeProps }) => (
            <NoAccessRedirect {...routeProps} isLoggedIn={isLoggedIn} />
          )}
          component={BrowserNotificationsDocumentationRoute}
        />
        <Route
          path={routeFactory.error.routePath}
          render={props => {
            const query = urlUtil.queryParse<{
              errorCode?: apiTypes.ServiceErrorCode;
              returnUrl?: string;
            }>(props.location.search);

            return (
              <ApplicationError
                errorCode={query.errorCode}
                returnUrl={query.returnUrl ?? routeFactory.root.routePath}
              />
            );
          }}
        />
        <Route
          path={routeFactory.syndicateWiresInvitationWireResponse.routePath}
          component={InvitationWireResponseRoute}
        />
        <PrivateRoute
          path="/"
          renderNoAccess={({ isLoggedIn, ...routeProps }) => (
            <NoAccessRedirect {...routeProps} isLoggedIn={isLoggedIn} />
          )}
          render={() => (
            <Redirect
              to={
                hasMyOfferings
                  ? routeFactory.myOfferings.getUrlPath()
                  : routeFactory.calendar.getUrlPath()
              }
            />
          )}
        />
        {/* Fallback */}
        <Route path="*" render={() => <div>404</div>} />
      </Switch>
      {/*
      Side Panels that can be invoked and displayed by multiple different routes should be placed here
    */}
      <CmgEntityKeyProvider>
        <FirmKeyOrIdProvider>
          <OfferingSidePanelContainer />
        </FirmKeyOrIdProvider>
      </CmgEntityKeyProvider>

      {/* Modals shared accross multiple routes */}
      <SupportRequestModal />
      <CrmSelectModal />
    </React.Fragment>
  );
};

export default RootRouter;
