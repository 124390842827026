/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MyOfferingsQuickAccess_PublishedOfferings_OfferingListPartsFragment = {
  readonly __typename?: 'OfferingList';
  readonly data: ReadonlyArray<{
    readonly __typename?: 'OfferingLimited';
    readonly id: string;
    readonly type: Types.OfferingType;
    readonly status: Types.OfferingStatus;
    readonly issuerName: string;
    readonly sizeInCurrency?: number | null;
    readonly pricingDate?: string | null;
    readonly firstTradeDate?: string | null;
    readonly pricingInstrumentStockSymbol?: string | null;
    readonly isAuthor: boolean;
  }>;
};

export type MyOfferingsQuickAccess_PublishedOfferingsQueryVariables = Types.Exact<{
  startDate: Types.Scalars['Date'];
  endDate: Types.Scalars['Date'];
  cmgEntityKey?: Types.InputMaybe<Types.Scalars['String']>;
}>;

export type MyOfferingsQuickAccess_PublishedOfferingsQuery = {
  readonly __typename?: 'Query';
  readonly livePublishedOfferings: {
    readonly __typename?: 'OfferingList';
    readonly data: ReadonlyArray<{
      readonly __typename?: 'OfferingLimited';
      readonly id: string;
      readonly type: Types.OfferingType;
      readonly status: Types.OfferingStatus;
      readonly issuerName: string;
      readonly sizeInCurrency?: number | null;
      readonly pricingDate?: string | null;
      readonly firstTradeDate?: string | null;
      readonly pricingInstrumentStockSymbol?: string | null;
      readonly isAuthor: boolean;
    }>;
  };
  readonly pricedPublishedOfferings: {
    readonly __typename?: 'OfferingList';
    readonly data: ReadonlyArray<{
      readonly __typename?: 'OfferingLimited';
      readonly id: string;
      readonly type: Types.OfferingType;
      readonly status: Types.OfferingStatus;
      readonly issuerName: string;
      readonly sizeInCurrency?: number | null;
      readonly pricingDate?: string | null;
      readonly firstTradeDate?: string | null;
      readonly pricingInstrumentStockSymbol?: string | null;
      readonly isAuthor: boolean;
    }>;
  };
};

export const MyOfferingsQuickAccess_PublishedOfferings_OfferingListPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MyOfferingsQuickAccess_PublishedOfferings_OfferingListParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'OfferingList' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'issuerName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sizeInCurrency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pricingDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstTradeDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pricingInstrumentStockSymbol' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isAuthor' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const MyOfferingsQuickAccess_PublishedOfferingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MyOfferingsQuickAccess_PublishedOfferings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKey' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'livePublishedOfferings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'cmgEntityKey' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKey' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'MyOfferingsQuickAccess_PublishedOfferings_OfferingListParts',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pricedPublishedOfferings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'startPricingDate' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'endPricingDate' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'cmgEntityKey' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKey' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'MyOfferingsQuickAccess_PublishedOfferings_OfferingListParts',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MyOfferingsQuickAccess_PublishedOfferings_OfferingListParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'OfferingList' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'issuerName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sizeInCurrency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pricingDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstTradeDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pricingInstrumentStockSymbol' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isAuthor' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useMyOfferingsQuickAccess_PublishedOfferingsQuery__
 *
 * To run a query within a React component, call `useMyOfferingsQuickAccess_PublishedOfferingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyOfferingsQuickAccess_PublishedOfferingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyOfferingsQuickAccess_PublishedOfferingsQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      cmgEntityKey: // value for 'cmgEntityKey'
 *   },
 * });
 */
export function useMyOfferingsQuickAccess_PublishedOfferingsQuery(
  baseOptions: Apollo.QueryHookOptions<
    MyOfferingsQuickAccess_PublishedOfferingsQuery,
    MyOfferingsQuickAccess_PublishedOfferingsQueryVariables
  > &
    (
      | { variables: MyOfferingsQuickAccess_PublishedOfferingsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MyOfferingsQuickAccess_PublishedOfferingsQuery,
    MyOfferingsQuickAccess_PublishedOfferingsQueryVariables
  >(MyOfferingsQuickAccess_PublishedOfferingsDocument, options);
}
export function useMyOfferingsQuickAccess_PublishedOfferingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MyOfferingsQuickAccess_PublishedOfferingsQuery,
    MyOfferingsQuickAccess_PublishedOfferingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MyOfferingsQuickAccess_PublishedOfferingsQuery,
    MyOfferingsQuickAccess_PublishedOfferingsQueryVariables
  >(MyOfferingsQuickAccess_PublishedOfferingsDocument, options);
}
export function useMyOfferingsQuickAccess_PublishedOfferingsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        MyOfferingsQuickAccess_PublishedOfferingsQuery,
        MyOfferingsQuickAccess_PublishedOfferingsQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    MyOfferingsQuickAccess_PublishedOfferingsQuery,
    MyOfferingsQuickAccess_PublishedOfferingsQueryVariables
  >(MyOfferingsQuickAccess_PublishedOfferingsDocument, options);
}
export type MyOfferingsQuickAccess_PublishedOfferingsQueryHookResult = ReturnType<
  typeof useMyOfferingsQuickAccess_PublishedOfferingsQuery
>;
export type MyOfferingsQuickAccess_PublishedOfferingsLazyQueryHookResult = ReturnType<
  typeof useMyOfferingsQuickAccess_PublishedOfferingsLazyQuery
>;
export type MyOfferingsQuickAccess_PublishedOfferingsSuspenseQueryHookResult = ReturnType<
  typeof useMyOfferingsQuickAccess_PublishedOfferingsSuspenseQuery
>;
export type MyOfferingsQuickAccess_PublishedOfferingsQueryResult = Apollo.QueryResult<
  MyOfferingsQuickAccess_PublishedOfferingsQuery,
  MyOfferingsQuickAccess_PublishedOfferingsQueryVariables
>;

import { makeMockResponse } from '../../../../../../graphql/mock-response';

export const makeMyOfferingsQuickAccess_PublishedOfferingsMockResponse = makeMockResponse<
  MyOfferingsQuickAccess_PublishedOfferingsQueryVariables,
  MyOfferingsQuickAccess_PublishedOfferingsQuery
>(MyOfferingsQuickAccess_PublishedOfferingsDocument);
