import { ActionPanelSection, LoadingButton } from '@cmg/design-system';
import { Form, FormikProvider, useFormik } from 'formik';
import React from 'react';

import { useIndicationActivityBuySideContext } from '../../../../context/IndicationActivityBuySideContext';
import IndicationSectionForm from '../../common/indication-section-form/IndicationSectionForm';
import IndicationStatus from '../../common/indication-status/IndicationStatus';
import {
  IndicationFormValues,
  validationSchema,
} from '../../common/utils/IndicationSectionValidation';
import PassOfferingConfirmDialog from '../common/pass-offering-confirm-dialog/PassOfferingConfirmDialog';
import { useCreateMyIndication } from './hooks/useCreateMyIndication';
import { createInitialValues } from './IndicationSectionCreateView.model';

const IndicationSectionCreateView: React.FC = () => {
  const { basicPublishedOffering, offeringId, loading } = useIndicationActivityBuySideContext();
  const offering = basicPublishedOffering!;

  const [createMyIndication] = useCreateMyIndication(offeringId);

  const [isPassDialogOpen, setIsPassDialogOpen] = React.useState(false);

  const formik = useFormik<IndicationFormValues>({
    initialValues: createInitialValues(offering),
    validationSchema,
    onSubmit: createMyIndication,
  });
  const { isSubmitting, handleSubmit, handleReset } = formik;
  const isLoading = loading || isSubmitting;

  return (
    <ActionPanelSection
      data-testid="create-indication-view"
      title="Indication"
      titleItems={<IndicationStatus />}
      actions={[
        <LoadingButton
          key="pass"
          disabled={isLoading}
          onClick={() => setIsPassDialogOpen(true)}
          variant="outlined"
        >
          Pass
        </LoadingButton>,
        <LoadingButton key="discard" disabled={isLoading} onClick={handleReset} variant="outlined">
          Discard Changes
        </LoadingButton>,
        <LoadingButton
          key="submit"
          loading={isLoading}
          onClick={() => handleSubmit()}
          variant="contained"
        >
          Submit Indication
        </LoadingButton>,
      ]}
    >
      <FormikProvider value={formik}>
        <Form>
          <IndicationSectionForm
            tranches={offering.orderEntryProfile.tranches}
            instruments={offering.orderEntryProfile.instruments}
            offeringType={offering.type}
            pricingCurrencyCode={offering.pricingCurrencyCode}
            securityTypeDisplayName={offering.securityTypeDisplayName!}
          />
        </Form>
      </FormikProvider>

      <PassOfferingConfirmDialog
        isOpen={isPassDialogOpen}
        onClose={() => setIsPassDialogOpen(false)}
        offeringId={offeringId}
        indicationStatus={null}
      />
    </ActionPanelSection>
  );
};

export default IndicationSectionCreateView;
