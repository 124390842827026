/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SharedDraftOffering_OfferingHeader_OfferingFilingsPartsFragment = {
  readonly __typename?: 'Filing';
  readonly filingType: Types.FilingType;
  readonly offerPrice?: number | null;
  readonly ipoRangeLow?: number | null;
  readonly ipoRangeHigh?: number | null;
};

export type SharedDraftOffering_OfferingHeader_OfferingFollowOnPricingPartsFragment = {
  readonly __typename?: 'FollowOnPricing';
  readonly lastTradeBeforeFiling?: number | null;
  readonly lastTradeBeforeOffer?: number | null;
  readonly lastTradeBeforeLaunch?: number | null;
  readonly reOfferLow?: number | null;
  readonly reOfferHigh?: number | null;
};

export type SharedDraftOffering_OfferingHeader_OfferingTermsPartsFragment = {
  readonly __typename?: 'Terms';
  readonly filings: ReadonlyArray<{
    readonly __typename?: 'Filing';
    readonly filingType: Types.FilingType;
    readonly offerPrice?: number | null;
    readonly ipoRangeLow?: number | null;
    readonly ipoRangeHigh?: number | null;
  }>;
  readonly followOnPricing?: {
    readonly __typename?: 'FollowOnPricing';
    readonly lastTradeBeforeFiling?: number | null;
    readonly lastTradeBeforeOffer?: number | null;
    readonly lastTradeBeforeLaunch?: number | null;
    readonly reOfferLow?: number | null;
    readonly reOfferHigh?: number | null;
  } | null;
};

export type SharedDraftOffering_OfferingHeader_OfferingPartsFragment = {
  readonly __typename?: 'Offering';
  readonly id: string;
  readonly status: Types.OfferingStatus;
  readonly stage: Types.OfferingStage;
  readonly isDiscarded: boolean;
  readonly isAuthor: boolean;
  readonly type: Types.OfferingType;
  readonly sizeInShares?: number | null;
  readonly issuer: {
    readonly __typename?: 'Issuer';
    readonly id: string;
    readonly name: string;
    readonly sector?: Types.Sector | null;
  };
  readonly security: { readonly __typename?: 'Security'; readonly type: Types.SecurityType };
  readonly terms: {
    readonly __typename?: 'Terms';
    readonly filings: ReadonlyArray<{
      readonly __typename?: 'Filing';
      readonly filingType: Types.FilingType;
      readonly offerPrice?: number | null;
      readonly ipoRangeLow?: number | null;
      readonly ipoRangeHigh?: number | null;
      readonly recordStatus: Types.RecordStatus;
    }>;
    readonly followOnPricing?: {
      readonly __typename?: 'FollowOnPricing';
      readonly lastTradeBeforeFiling?: number | null;
      readonly lastTradeBeforeOffer?: number | null;
      readonly lastTradeBeforeLaunch?: number | null;
      readonly reOfferLow?: number | null;
      readonly reOfferHigh?: number | null;
    } | null;
  };
};

export type SharedDraftOffering_OfferingHeader_OfferingProfileFilingsPartsFragment = {
  readonly __typename?: 'FilingProfile';
  readonly filingType: Types.FilingType;
  readonly offerPrice?: number | null;
  readonly ipoRangeLow?: number | null;
  readonly ipoRangeHigh?: number | null;
};

export type SharedDraftOffering_OfferingHeader_OfferingProfileFollowOnPricingPartsFragment = {
  readonly __typename?: 'FollowOnPricingProfile';
  readonly lastTradeBeforeFiling?: number | null;
  readonly lastTradeBeforeOffer?: number | null;
  readonly lastTradeBeforeLaunch?: number | null;
  readonly reOfferLow?: number | null;
  readonly reOfferHigh?: number | null;
};

export type SharedDraftOffering_OfferingHeader_OfferingProfileTermsPartsFragment = {
  readonly __typename?: 'TermsProfile';
  readonly filings: ReadonlyArray<{
    readonly __typename?: 'FilingProfile';
    readonly filingType: Types.FilingType;
    readonly offerPrice?: number | null;
    readonly ipoRangeLow?: number | null;
    readonly ipoRangeHigh?: number | null;
  }>;
  readonly followOnPricing?: {
    readonly __typename?: 'FollowOnPricingProfile';
    readonly lastTradeBeforeFiling?: number | null;
    readonly lastTradeBeforeOffer?: number | null;
    readonly lastTradeBeforeLaunch?: number | null;
    readonly reOfferLow?: number | null;
    readonly reOfferHigh?: number | null;
  } | null;
};

export type SharedDraftOffering_OfferingHeader_OfferingProfilePartsFragment = {
  readonly __typename?: 'OfferingProfile';
  readonly id: string;
  readonly status: Types.OfferingStatus;
  readonly stage: Types.OfferingStage;
  readonly isDiscarded: boolean;
  readonly isAuthor: boolean;
  readonly type: Types.OfferingType;
  readonly sizeInShares?: number | null;
  readonly issuer: {
    readonly __typename?: 'Issuer';
    readonly id: string;
    readonly name: string;
    readonly sector?: Types.Sector | null;
  };
  readonly security: { readonly __typename?: 'SecurityProfile'; readonly type: Types.SecurityType };
  readonly terms: {
    readonly __typename?: 'TermsProfile';
    readonly filings: ReadonlyArray<{
      readonly __typename?: 'FilingProfile';
      readonly filingType: Types.FilingType;
      readonly offerPrice?: number | null;
      readonly ipoRangeLow?: number | null;
      readonly ipoRangeHigh?: number | null;
    }>;
    readonly followOnPricing?: {
      readonly __typename?: 'FollowOnPricingProfile';
      readonly lastTradeBeforeFiling?: number | null;
      readonly lastTradeBeforeOffer?: number | null;
      readonly lastTradeBeforeLaunch?: number | null;
      readonly reOfferLow?: number | null;
      readonly reOfferHigh?: number | null;
    } | null;
  };
};

export type SharedDraftOffering_OfferingHeaderQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type SharedDraftOffering_OfferingHeaderQuery = {
  readonly __typename?: 'Query';
  readonly offeringHeader:
    | {
        readonly __typename?: 'Offering';
        readonly id: string;
        readonly status: Types.OfferingStatus;
        readonly stage: Types.OfferingStage;
        readonly isDiscarded: boolean;
        readonly isAuthor: boolean;
        readonly type: Types.OfferingType;
        readonly sizeInShares?: number | null;
        readonly issuer: {
          readonly __typename?: 'Issuer';
          readonly id: string;
          readonly name: string;
          readonly sector?: Types.Sector | null;
        };
        readonly security: { readonly __typename?: 'Security'; readonly type: Types.SecurityType };
        readonly terms: {
          readonly __typename?: 'Terms';
          readonly filings: ReadonlyArray<{
            readonly __typename?: 'Filing';
            readonly filingType: Types.FilingType;
            readonly offerPrice?: number | null;
            readonly ipoRangeLow?: number | null;
            readonly ipoRangeHigh?: number | null;
            readonly recordStatus: Types.RecordStatus;
          }>;
          readonly followOnPricing?: {
            readonly __typename?: 'FollowOnPricing';
            readonly lastTradeBeforeFiling?: number | null;
            readonly lastTradeBeforeOffer?: number | null;
            readonly lastTradeBeforeLaunch?: number | null;
            readonly reOfferLow?: number | null;
            readonly reOfferHigh?: number | null;
          } | null;
        };
      }
    | {
        readonly __typename?: 'OfferingProfile';
        readonly id: string;
        readonly status: Types.OfferingStatus;
        readonly stage: Types.OfferingStage;
        readonly isDiscarded: boolean;
        readonly isAuthor: boolean;
        readonly type: Types.OfferingType;
        readonly sizeInShares?: number | null;
        readonly issuer: {
          readonly __typename?: 'Issuer';
          readonly id: string;
          readonly name: string;
          readonly sector?: Types.Sector | null;
        };
        readonly security: {
          readonly __typename?: 'SecurityProfile';
          readonly type: Types.SecurityType;
        };
        readonly terms: {
          readonly __typename?: 'TermsProfile';
          readonly filings: ReadonlyArray<{
            readonly __typename?: 'FilingProfile';
            readonly filingType: Types.FilingType;
            readonly offerPrice?: number | null;
            readonly ipoRangeLow?: number | null;
            readonly ipoRangeHigh?: number | null;
          }>;
          readonly followOnPricing?: {
            readonly __typename?: 'FollowOnPricingProfile';
            readonly lastTradeBeforeFiling?: number | null;
            readonly lastTradeBeforeOffer?: number | null;
            readonly lastTradeBeforeLaunch?: number | null;
            readonly reOfferLow?: number | null;
            readonly reOfferHigh?: number | null;
          } | null;
        };
      };
};

export type SharedDraftOffering_SharedDraftOfferingQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type SharedDraftOffering_SharedDraftOfferingQuery = {
  readonly __typename?: 'Query';
  readonly sharedDraftOffering: {
    readonly __typename?: 'OfferingProfile';
    readonly id: string;
    readonly version: string;
    readonly type: Types.OfferingType;
    readonly status: Types.OfferingStatus;
    readonly stage: Types.OfferingStage;
    readonly isExecutingOnPlatform: boolean;
    readonly pricingInstrumentId: string;
    readonly pricingInstrumentStockSymbol?: string | null;
    readonly pricingCurrencyCode: string;
    readonly supersededByOfferingId?: string | null;
    readonly supersededOfferingId?: string | null;
    readonly sizeInShares?: number | null;
    readonly sizeInCurrency?: number | null;
    readonly hasForwardAgreement?: boolean | null;
    readonly syndicate: {
      readonly __typename?: 'SyndicateProfile';
      readonly managers: ReadonlyArray<{
        readonly __typename?: 'ManagerProfile';
        readonly id: string;
        readonly cmgEntityKey: string;
        readonly role: Types.ManagerRole;
        readonly firmName: string;
      }>;
      readonly managerResponsibilities: {
        readonly __typename?: 'ManagerResponsibilitiesProfile';
        readonly stabilizationAgent?: string | null;
        readonly settlementAgent?: string | null;
        readonly logisticsAgent?: string | null;
        readonly leftLead?: string | null;
      };
    };
    readonly issuer: {
      readonly __typename?: 'Issuer';
      readonly name: string;
      readonly businessDescription?: string | null;
      readonly sector?: Types.Sector | null;
    };
    readonly security: {
      readonly __typename?: 'SecurityProfile';
      readonly type: Types.SecurityType;
      readonly typeDisplayName?: string | null;
      readonly shareClass?: Types.SecurityShareClass | null;
    };
    readonly disclaimers: {
      readonly __typename?: 'DisclaimersProfile';
      readonly restrictions?: string | null;
      readonly footnotes?: string | null;
      readonly registrationLinks: ReadonlyArray<string>;
      readonly rule134Legend?: string | null;
    };
    readonly marketing: {
      readonly __typename?: 'Marketing';
      readonly id: string;
      readonly managementTeam?: string | null;
      readonly callDetails?: string | null;
    };
    readonly timing: {
      readonly __typename?: 'TimingProfile';
      readonly booksCloseAt?: string | null;
      readonly timeZone?: string | null;
      readonly pricingDate?: string | null;
      readonly firstTradeDate?: string | null;
      readonly settlementDate?: string | null;
      readonly terminatedDate?: string | null;
      readonly launchDate?: string | null;
      readonly publicFilingDate?: string | null;
      readonly confidentialFilingDate?: string | null;
      readonly filingOccurred?: Types.MarketTiming | null;
      readonly launchOccurred?: Types.MarketTiming | null;
      readonly withdrawnDate?: string | null;
    };
    readonly demandCurrenciesWithExchangeRates: ReadonlyArray<{
      readonly __typename?: 'DemandCurrencyProfile';
      readonly currencyCode: string;
      readonly exchangeRate?: number | null;
    }>;
    readonly terms: {
      readonly __typename?: 'TermsProfile';
      readonly followOnPricing?: {
        readonly __typename?: 'FollowOnPricingProfile';
        readonly lastTradeBeforeFiling?: number | null;
        readonly lastTradeBeforeLaunch?: number | null;
        readonly lastTradeBeforeOffer?: number | null;
        readonly reOfferLow?: number | null;
        readonly reOfferHigh?: number | null;
      } | null;
      readonly filings: ReadonlyArray<{
        readonly __typename?: 'FilingProfile';
        readonly id: string;
        readonly filingType: Types.FilingType;
        readonly initialRegistrationValue?: number | null;
        readonly primarySharesBaseOffering?: number | null;
        readonly secondarySharesBaseOffering?: number | null;
        readonly notionalOverAllotmentAuthorized?: number | null;
        readonly totalSharesOverAllotmentAuthorized?: number | null;
        readonly primarySharesOverAllotmentAuthorized?: number | null;
        readonly secondarySharesOverAllotmentAuthorized?: number | null;
        readonly totalSharesBaseOffering?: number | null;
        readonly ipoRangeLow?: number | null;
        readonly ipoRangeHigh?: number | null;
        readonly offerPrice?: number | null;
        readonly postOfferingShares?: number | null;
        readonly useOfProceeds: ReadonlyArray<Types.UseOfProceeds>;
        readonly lockUpPeriod?: number | null;
      }>;
    };
    readonly instruments: ReadonlyArray<{
      readonly __typename?: 'DeliveryInstrumentPrivileged';
      readonly id: string;
      readonly currencyCode: string;
      readonly countryCode: string;
      readonly isDepositaryReceipt: boolean;
      readonly stockSymbol?: string | null;
      readonly exchange?: string | null;
      readonly exchangeMic?: Types.ExchangeMic | null;
      readonly cusip?: string | null;
      readonly isin?: string | null;
      readonly figi?: string | null;
      readonly depositaryReceiptConversionRatio?: {
        readonly __typename?: 'DepositaryReceiptConversionRatio';
        readonly numerator: number;
        readonly denominator: number;
      } | null;
    }>;
    readonly roadshowSchedules: ReadonlyArray<{
      readonly __typename?: 'RoadshowScheduleProfile';
      readonly id: string;
      readonly date: string;
      readonly details: string;
    }>;
    readonly prospectusDocuments: ReadonlyArray<{
      readonly __typename?: 'ProspectusDocumentProfile';
      readonly id: string;
      readonly documentName: string;
      readonly publicUrl: string;
      readonly firstPublishedAt?: string | null;
    }>;
    readonly managerEconomics?: {
      readonly __typename?: 'ManagerEconomicsProfile';
      readonly underwritingBaseSharesDistribution: ReadonlyArray<{
        readonly __typename?: 'SharesPerCmgEntity';
        readonly id: string;
        readonly cmgEntityKey: string;
        readonly sharesValue: number;
      }>;
    } | null;
    readonly tranches?: ReadonlyArray<{
      readonly __typename?: 'TranchePrivileged';
      readonly id: string;
      readonly name: string;
      readonly region?: string | null;
      readonly deliveryInstrumentIds: ReadonlyArray<string>;
      readonly demandCurrencies: ReadonlyArray<string>;
      readonly defaultInstrumentId?: string | null;
    }> | null;
  };
};

export const SharedDraftOffering_OfferingHeader_OfferingFilingsPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SharedDraftOffering_OfferingHeader_OfferingFilingsParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Filing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'filingType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offerPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipoRangeLow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipoRangeHigh' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const SharedDraftOffering_OfferingHeader_OfferingFollowOnPricingPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'SharedDraftOffering_OfferingHeader_OfferingFollowOnPricingParts',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FollowOnPricing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeFiling' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeOffer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeLaunch' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reOfferLow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reOfferHigh' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const SharedDraftOffering_OfferingHeader_OfferingTermsPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SharedDraftOffering_OfferingHeader_OfferingTermsParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Terms' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'filings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'SharedDraftOffering_OfferingHeader_OfferingFilingsParts',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'followOnPricing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'SharedDraftOffering_OfferingHeader_OfferingFollowOnPricingParts',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SharedDraftOffering_OfferingHeader_OfferingFilingsParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Filing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'filingType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offerPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipoRangeLow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipoRangeHigh' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'SharedDraftOffering_OfferingHeader_OfferingFollowOnPricingParts',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FollowOnPricing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeFiling' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeOffer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeLaunch' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reOfferLow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reOfferHigh' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const SharedDraftOffering_OfferingHeader_OfferingPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SharedDraftOffering_OfferingHeader_OfferingParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Offering' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isDiscarded' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAuthor' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'issuer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sector' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sizeInShares' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'security' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'terms' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Offering_OfferingHeader_OfferingTermsParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Offering_OfferingHeader_OfferingTermsParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Terms' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'filings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Offering_OfferingHeader_OfferingFilingsParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'followOnPricing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'Offering_OfferingHeader_OfferingFollowOnPricingParts',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Offering_OfferingHeader_OfferingFilingsParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Filing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'filingType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offerPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipoRangeLow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipoRangeHigh' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recordStatus' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Offering_OfferingHeader_OfferingFollowOnPricingParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FollowOnPricing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeFiling' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeOffer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeLaunch' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reOfferLow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reOfferHigh' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const SharedDraftOffering_OfferingHeader_OfferingProfileFilingsPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'SharedDraftOffering_OfferingHeader_OfferingProfileFilingsParts',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FilingProfile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'filingType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offerPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipoRangeLow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipoRangeHigh' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const SharedDraftOffering_OfferingHeader_OfferingProfileFollowOnPricingPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'SharedDraftOffering_OfferingHeader_OfferingProfileFollowOnPricingParts',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FollowOnPricingProfile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeFiling' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeOffer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeLaunch' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reOfferLow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reOfferHigh' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const SharedDraftOffering_OfferingHeader_OfferingProfileTermsPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SharedDraftOffering_OfferingHeader_OfferingProfileTermsParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TermsProfile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'filings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'SharedDraftOffering_OfferingHeader_OfferingProfileFilingsParts',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'followOnPricing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'SharedDraftOffering_OfferingHeader_OfferingProfileFollowOnPricingParts',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'SharedDraftOffering_OfferingHeader_OfferingProfileFilingsParts',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FilingProfile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'filingType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offerPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipoRangeLow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipoRangeHigh' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'SharedDraftOffering_OfferingHeader_OfferingProfileFollowOnPricingParts',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FollowOnPricingProfile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeFiling' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeOffer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeLaunch' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reOfferLow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reOfferHigh' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const SharedDraftOffering_OfferingHeader_OfferingProfilePartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SharedDraftOffering_OfferingHeader_OfferingProfileParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'OfferingProfile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isDiscarded' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAuthor' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'issuer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sector' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sizeInShares' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'security' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'terms' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'SharedDraftOffering_OfferingHeader_OfferingProfileTermsParts',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'SharedDraftOffering_OfferingHeader_OfferingProfileFilingsParts',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FilingProfile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'filingType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offerPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipoRangeLow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipoRangeHigh' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'SharedDraftOffering_OfferingHeader_OfferingProfileFollowOnPricingParts',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FollowOnPricingProfile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeFiling' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeOffer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeLaunch' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reOfferLow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reOfferHigh' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SharedDraftOffering_OfferingHeader_OfferingProfileTermsParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TermsProfile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'filings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'SharedDraftOffering_OfferingHeader_OfferingProfileFilingsParts',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'followOnPricing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'SharedDraftOffering_OfferingHeader_OfferingProfileFollowOnPricingParts',
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const SharedDraftOffering_OfferingHeaderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SharedDraftOffering_OfferingHeader' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'offeringHeader' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'isSharedDraft' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SharedDraftOffering_OfferingHeader_OfferingParts' },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'SharedDraftOffering_OfferingHeader_OfferingProfileParts',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SharedDraftOffering_OfferingHeader_OfferingParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Offering' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isDiscarded' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAuthor' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'issuer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sector' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sizeInShares' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'security' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'terms' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Offering_OfferingHeader_OfferingTermsParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'SharedDraftOffering_OfferingHeader_OfferingProfileFilingsParts',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FilingProfile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'filingType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offerPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipoRangeLow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipoRangeHigh' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'SharedDraftOffering_OfferingHeader_OfferingProfileFollowOnPricingParts',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FollowOnPricingProfile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeFiling' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeOffer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeLaunch' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reOfferLow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reOfferHigh' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SharedDraftOffering_OfferingHeader_OfferingProfileTermsParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TermsProfile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'filings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'SharedDraftOffering_OfferingHeader_OfferingProfileFilingsParts',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'followOnPricing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'SharedDraftOffering_OfferingHeader_OfferingProfileFollowOnPricingParts',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SharedDraftOffering_OfferingHeader_OfferingProfileParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'OfferingProfile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isDiscarded' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAuthor' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'issuer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sector' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sizeInShares' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'security' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'terms' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'SharedDraftOffering_OfferingHeader_OfferingProfileTermsParts',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Offering_OfferingHeader_OfferingTermsParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Terms' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'filings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Offering_OfferingHeader_OfferingFilingsParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'followOnPricing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'Offering_OfferingHeader_OfferingFollowOnPricingParts',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Offering_OfferingHeader_OfferingFilingsParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Filing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'filingType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offerPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipoRangeLow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipoRangeHigh' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recordStatus' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Offering_OfferingHeader_OfferingFollowOnPricingParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FollowOnPricing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeFiling' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeOffer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeLaunch' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reOfferLow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reOfferHigh' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useSharedDraftOffering_OfferingHeaderQuery__
 *
 * To run a query within a React component, call `useSharedDraftOffering_OfferingHeaderQuery` and pass it any options that fit your needs.
 * When your component renders, `useSharedDraftOffering_OfferingHeaderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSharedDraftOffering_OfferingHeaderQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useSharedDraftOffering_OfferingHeaderQuery(
  baseOptions: Apollo.QueryHookOptions<
    SharedDraftOffering_OfferingHeaderQuery,
    SharedDraftOffering_OfferingHeaderQueryVariables
  > &
    (
      | { variables: SharedDraftOffering_OfferingHeaderQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SharedDraftOffering_OfferingHeaderQuery,
    SharedDraftOffering_OfferingHeaderQueryVariables
  >(SharedDraftOffering_OfferingHeaderDocument, options);
}
export function useSharedDraftOffering_OfferingHeaderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SharedDraftOffering_OfferingHeaderQuery,
    SharedDraftOffering_OfferingHeaderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SharedDraftOffering_OfferingHeaderQuery,
    SharedDraftOffering_OfferingHeaderQueryVariables
  >(SharedDraftOffering_OfferingHeaderDocument, options);
}
export function useSharedDraftOffering_OfferingHeaderSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        SharedDraftOffering_OfferingHeaderQuery,
        SharedDraftOffering_OfferingHeaderQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SharedDraftOffering_OfferingHeaderQuery,
    SharedDraftOffering_OfferingHeaderQueryVariables
  >(SharedDraftOffering_OfferingHeaderDocument, options);
}
export type SharedDraftOffering_OfferingHeaderQueryHookResult = ReturnType<
  typeof useSharedDraftOffering_OfferingHeaderQuery
>;
export type SharedDraftOffering_OfferingHeaderLazyQueryHookResult = ReturnType<
  typeof useSharedDraftOffering_OfferingHeaderLazyQuery
>;
export type SharedDraftOffering_OfferingHeaderSuspenseQueryHookResult = ReturnType<
  typeof useSharedDraftOffering_OfferingHeaderSuspenseQuery
>;
export type SharedDraftOffering_OfferingHeaderQueryResult = Apollo.QueryResult<
  SharedDraftOffering_OfferingHeaderQuery,
  SharedDraftOffering_OfferingHeaderQueryVariables
>;
export const SharedDraftOffering_SharedDraftOfferingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SharedDraftOffering_SharedDraftOffering' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sharedDraftOffering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Offering_OfferingDetails_PublishedOfferingParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Offering_OfferingDetails_PublishedOfferingParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'OfferingProfile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isExecutingOnPlatform' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingInstrumentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingInstrumentStockSymbol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingCurrencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'supersededByOfferingId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'supersededOfferingId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'syndicate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'managers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'managerResponsibilities' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value:
                            'Offering_OfferingDetails_PublishedOffering_ManagerResponsibilitiesProfileParts',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'issuer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'businessDescription' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sector' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'security' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'typeDisplayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shareClass' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'disclaimers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'Offering_OfferingDetails_PublishedOffering_DisclaimersProfileParts',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'marketing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'Offering_OfferingDetails_PublishedOffering_MarketingParts',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'timing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'booksCloseAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timeZone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pricingDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstTradeDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'settlementDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'terminatedDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'launchDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'publicFilingDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'confidentialFilingDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'filingOccurred' } },
                { kind: 'Field', name: { kind: 'Name', value: 'launchOccurred' } },
                { kind: 'Field', name: { kind: 'Name', value: 'withdrawnDate' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'demandCurrenciesWithExchangeRates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'exchangeRate' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'terms' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'followOnPricing' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value:
                            'Offering_OfferingDetails_PublishedOffering_FollowOnPricingProfileParts',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'filings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'Offering_OfferingDetails_PublishedOffering_FilingProfileParts',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'instruments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isDepositaryReceipt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'depositaryReceiptConversionRatio' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'numerator' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'denominator' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'stockSymbol' } },
                { kind: 'Field', name: { kind: 'Name', value: 'exchange' } },
                { kind: 'Field', name: { kind: 'Name', value: 'exchangeMic' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cusip' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'figi' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roadshowSchedules' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'details' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'sizeInShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sizeInCurrency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasForwardAgreement' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'prospectusDocuments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'Offering_OfferingDetails_PublishedOffering_ProspectusDocumentProfileParts',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'managerEconomics' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'underwritingBaseSharesDistribution' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sharesValue' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tranches' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'region' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryInstrumentIds' } },
                { kind: 'Field', name: { kind: 'Name', value: 'demandCurrencies' } },
                { kind: 'Field', name: { kind: 'Name', value: 'defaultInstrumentId' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'Offering_OfferingDetails_PublishedOffering_ManagerResponsibilitiesProfileParts',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ManagerResponsibilitiesProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'stabilizationAgent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'settlementAgent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logisticsAgent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'leftLead' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'Offering_OfferingDetails_PublishedOffering_DisclaimersProfileParts',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DisclaimersProfile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'restrictions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'footnotes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'registrationLinks' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rule134Legend' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Offering_OfferingDetails_PublishedOffering_MarketingParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Marketing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'managementTeam' } },
          { kind: 'Field', name: { kind: 'Name', value: 'callDetails' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'Offering_OfferingDetails_PublishedOffering_FollowOnPricingProfileParts',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FollowOnPricingProfile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeFiling' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeLaunch' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastTradeBeforeOffer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reOfferLow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reOfferHigh' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'Offering_OfferingDetails_PublishedOffering_FilingProfileParts',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FilingProfile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'filingType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'initialRegistrationValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primarySharesBaseOffering' } },
          { kind: 'Field', name: { kind: 'Name', value: 'secondarySharesBaseOffering' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notionalOverAllotmentAuthorized' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalSharesOverAllotmentAuthorized' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primarySharesOverAllotmentAuthorized' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'secondarySharesOverAllotmentAuthorized' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'totalSharesBaseOffering' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipoRangeLow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipoRangeHigh' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offerPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postOfferingShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'useOfProceeds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lockUpPeriod' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'Offering_OfferingDetails_PublishedOffering_ProspectusDocumentProfileParts',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProspectusDocumentProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'documentName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'publicUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstPublishedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useSharedDraftOffering_SharedDraftOfferingQuery__
 *
 * To run a query within a React component, call `useSharedDraftOffering_SharedDraftOfferingQuery` and pass it any options that fit your needs.
 * When your component renders, `useSharedDraftOffering_SharedDraftOfferingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSharedDraftOffering_SharedDraftOfferingQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useSharedDraftOffering_SharedDraftOfferingQuery(
  baseOptions: Apollo.QueryHookOptions<
    SharedDraftOffering_SharedDraftOfferingQuery,
    SharedDraftOffering_SharedDraftOfferingQueryVariables
  > &
    (
      | { variables: SharedDraftOffering_SharedDraftOfferingQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SharedDraftOffering_SharedDraftOfferingQuery,
    SharedDraftOffering_SharedDraftOfferingQueryVariables
  >(SharedDraftOffering_SharedDraftOfferingDocument, options);
}
export function useSharedDraftOffering_SharedDraftOfferingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SharedDraftOffering_SharedDraftOfferingQuery,
    SharedDraftOffering_SharedDraftOfferingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SharedDraftOffering_SharedDraftOfferingQuery,
    SharedDraftOffering_SharedDraftOfferingQueryVariables
  >(SharedDraftOffering_SharedDraftOfferingDocument, options);
}
export function useSharedDraftOffering_SharedDraftOfferingSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        SharedDraftOffering_SharedDraftOfferingQuery,
        SharedDraftOffering_SharedDraftOfferingQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SharedDraftOffering_SharedDraftOfferingQuery,
    SharedDraftOffering_SharedDraftOfferingQueryVariables
  >(SharedDraftOffering_SharedDraftOfferingDocument, options);
}
export type SharedDraftOffering_SharedDraftOfferingQueryHookResult = ReturnType<
  typeof useSharedDraftOffering_SharedDraftOfferingQuery
>;
export type SharedDraftOffering_SharedDraftOfferingLazyQueryHookResult = ReturnType<
  typeof useSharedDraftOffering_SharedDraftOfferingLazyQuery
>;
export type SharedDraftOffering_SharedDraftOfferingSuspenseQueryHookResult = ReturnType<
  typeof useSharedDraftOffering_SharedDraftOfferingSuspenseQuery
>;
export type SharedDraftOffering_SharedDraftOfferingQueryResult = Apollo.QueryResult<
  SharedDraftOffering_SharedDraftOfferingQuery,
  SharedDraftOffering_SharedDraftOfferingQueryVariables
>;

import { makeMockResponse } from '../../../../graphql/mock-response';

export const makeSharedDraftOffering_OfferingHeaderMockResponse = makeMockResponse<
  SharedDraftOffering_OfferingHeaderQueryVariables,
  SharedDraftOffering_OfferingHeaderQuery
>(SharedDraftOffering_OfferingHeaderDocument);

export const makeSharedDraftOffering_SharedDraftOfferingMockResponse = makeMockResponse<
  SharedDraftOffering_SharedDraftOfferingQueryVariables,
  SharedDraftOffering_SharedDraftOfferingQuery
>(SharedDraftOffering_SharedDraftOfferingDocument);
