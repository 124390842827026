import { ActionPanelSection, Button, LoadingButton, Stack } from '@cmg/design-system';
import React from 'react';

import useCheckPassiveOrderBookAccess from '../../../../../../../../common/util/check-access/useCheckInstitutionalPassiveOrderBookAccess';
import {
  IndicationStatus as IndicationStatusType,
  InstitutionalIndicationOrderType,
} from '../../../../../../../../graphql';
import { useSupersededOfferingContext } from '../../../../../../contexts/SupersededOfferingContext';
import { useIndicationActivitySalesAndTradingContext } from '../../../../context/IndicationActivitySalesAndTradingContext';
import IndicationSectionPassedReadContent from '../../common/indication-section-passed-read-content/IndicationSectionPassedReadContent';
import IndicationSectionReadContent from '../../common/indication-section-read-content/IndicationSectionReadContent';
import IndicationStatus from '../../common/indication-status/IndicationStatus';
import PassCoveredIndicationDialog from '../common/pass-covered-indication-dialog/PassCoveredIndicationDialog';
import IndicationAcknowledgementBanner from '../indication-acknowledgement-banner/IndicationAcknowledgementBanner';
import CancelCoveredIndicationConfirmDialog from './cancel-covered-indication-confirm-dialog/CancelCoveredIndicationConfirmDialog';
import { useUpdateCoveredIndicationStatus } from './hooks/useUpdateCoveredIndicationStatus';

export type Props = Readonly<{
  onStartEditing: () => void;
  isIndicationEditable: boolean;
  displayMissingContacts: boolean;
}>;

const IndicationSectionReadView: React.FC<Props> = ({
  onStartEditing,
  isIndicationEditable,
  displayMissingContacts,
}) => {
  const {
    offeringId,
    complianceWarning: { hasComplianceWarning },
    coveredIndication,
    basicPublishedOffering,
    access: { canUpdateCoveredIndication },
  } = useIndicationActivitySalesAndTradingContext();
  const { isObsoleteOffering } = useSupersededOfferingContext();
  const offering = basicPublishedOffering!;
  const indication = coveredIndication!;
  const { canManage: canManagePassiveOrderBook } = useCheckPassiveOrderBookAccess({ offeringId });

  const [isPassDialogOpen, setIsPassDialogOpen] = React.useState(false);
  const [isCancelDialogOpen, setIsCancelDialogOpen] = React.useState(false);

  const [updateStatus, isUpdatingStatus] = useUpdateCoveredIndicationStatus(
    offeringId,
    indication.id
  );

  const baseButtonAccess = isIndicationEditable && canUpdateCoveredIndication;

  const isPassButtonVisible = indication.type !== InstitutionalIndicationOrderType.Pass;
  const isReactivateButtonVisible =
    indication.status === IndicationStatusType.Cancelled && !hasComplianceWarning;
  const isEditAndCancelButtonVisible =
    indication.status !== IndicationStatusType.Cancelled && !hasComplianceWarning;

  return (
    <ActionPanelSection
      data-testid="read-indication-view"
      title="Indication"
      titleItems={<IndicationStatus status={indication.status} />}
      actions={[
        ...(baseButtonAccess && isPassButtonVisible
          ? [
              <Button
                key="pass"
                variant="outlined"
                disabled={isUpdatingStatus}
                onClick={() => setIsPassDialogOpen(true)}
              >
                Pass
              </Button>,
            ]
          : []),
        ...(baseButtonAccess && isReactivateButtonVisible
          ? [
              <LoadingButton
                key="reactivate"
                variant="outlined"
                loading={isUpdatingStatus}
                onClick={() => updateStatus(IndicationStatusType.Active)}
              >
                Re-activate
              </LoadingButton>,
            ]
          : []),
        ...(baseButtonAccess && isEditAndCancelButtonVisible
          ? [
              <Button
                key="cancel"
                variant="outlined"
                disabled={isUpdatingStatus}
                onClick={() => setIsCancelDialogOpen(true)}
              >
                Cancel
              </Button>,
              <Button
                key="edit"
                variant="contained"
                disabled={isObsoleteOffering || isUpdatingStatus}
                onClick={onStartEditing}
              >
                Edit
              </Button>,
            ]
          : []),
      ]}
    >
      <Stack gap={1}>
        {!hasComplianceWarning && isIndicationEditable && !canManagePassiveOrderBook && (
          <IndicationAcknowledgementBanner
            acknowledgeButtonDisabledNoContacts={displayMissingContacts}
          />
        )}

        {indication.type === InstitutionalIndicationOrderType.Pass && (
          <IndicationSectionPassedReadContent
            submittedOn={indication.auditInfo.createdAt}
            firmName={
              indication.investorInformation.bankInvestorName ??
              indication.investorInformation.cmgEntityName
            }
          />
        )}
        {indication.type !== InstitutionalIndicationOrderType.Pass && (
          <IndicationSectionReadContent
            submittedOn={indication.auditInfo.createdAt}
            indicationTrancheId={indication.trancheId}
            indicationInstrumentId={indication.instrumentId}
            demandCurrencyCode={indication.currencyCode}
            pricingCurrencyCode={offering.pricingCurrencyCode}
            acknowledgements={indication.institutionalIndicationAcknowledgements}
            indicationVersion={indication.version}
            bndAgentCmgEntityKey={indication.billingAndDeliveryAgentCmgEntityKey}
            interestLevels={indication.interestLevels}
            tranches={offering.orderEntryProfile.tranches}
            demandInstruments={offering.orderEntryProfile.instruments}
            managers={offering.syndicate.managers}
            securityTypeDisplayName={offering.securityTypeDisplayName!}
          />
        )}

        <PassCoveredIndicationDialog
          indicationId={indication.id}
          offeringId={offeringId}
          indicationStatus={indication.status}
          onClose={() => setIsPassDialogOpen(false)}
          isOpen={isPassDialogOpen}
        />
        <CancelCoveredIndicationConfirmDialog
          indicationId={indication.id}
          offeringId={offeringId}
          isOpen={isCancelDialogOpen}
          onClose={() => setIsCancelDialogOpen(false)}
        />
      </Stack>
    </ActionPanelSection>
  );
};

export default IndicationSectionReadView;
