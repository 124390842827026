import { SelectFieldCellEditor } from '@cmg/data-grid';
import React, { useCallback, useMemo } from 'react';
import * as yup from 'yup';

import type { FirmInfoPartsFragment } from '../../../../../../common/graphql/fragments';
import { getFirmDisplayName, managerProfileToFirmInfo } from '../../../utils';
import { UNASSIGN_BND_AGENT_VALUE } from '../../DemandGrid.model';
import type { DemandGridCellEditorProps } from '../../types';

export const validationSchema = yup.object().shape({
  cellEditorField: yup.string().label('B&D Agent').required(),
});

export type Props = DemandGridCellEditorProps<FirmInfoPartsFragment>;

export const BndAgentCellEditor: React.FC<Props> = ({
  initialValue,
  eventKey,
  onValueChange,
  parseValue,
  context,
}) => {
  const options = useMemo(() => {
    const managerOptions = Object.entries(context?.syndicateManagers ?? {}).map(
      ([cmgEntityKey, manager]) => ({
        label: getFirmDisplayName(manager),
        value: cmgEntityKey,
      })
    );

    return [...managerOptions, { label: 'Unassigned', value: UNASSIGN_BND_AGENT_VALUE }];
  }, [context?.syndicateManagers]);

  const handleValueChange = useCallback(
    (cmgEntityKey: string | null | undefined) => {
      const selectedManager = cmgEntityKey ? context?.syndicateManagers[cmgEntityKey] : null;

      /**
       * BnD agent cell works with FirmInfoPartsFragment as value.
       * We need to convert the selected cmgEntityKey to FirmInfoPartsFragment.
       */
      const nextValue: FirmInfoPartsFragment | null = selectedManager
        ? managerProfileToFirmInfo(selectedManager)
        : null;

      onValueChange(nextValue);
    },
    [context?.syndicateManagers, onValueChange]
  );

  return (
    <SelectFieldCellEditor<string>
      options={options}
      eventKey={eventKey}
      initialValue={initialValue?.firmKey ?? UNASSIGN_BND_AGENT_VALUE}
      parseValue={value => parseValue(value)?.firmKey ?? UNASSIGN_BND_AGENT_VALUE}
      onValueChange={handleValueChange}
      validationSchema={validationSchema}
      SelectProps={{
        defaultOpen: true, // Select needs to be opened when rendered
      }}
    />
  );
};
