/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SyndicateWires_CreateGreenShoeWireMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  payload: Types.GreenShoeWireInput;
}>;

export type SyndicateWires_CreateGreenShoeWireMutation = {
  readonly __typename?: 'Mutation';
  readonly createGreenShoeWire:
    | {
        readonly __typename?: 'GreenShoeWire';
        readonly id: string;
        readonly status: Types.WireStatus;
        readonly createdAt: string;
        readonly sender: string;
        readonly recipientsUserData: ReadonlyArray<{
          readonly __typename?: 'GreenShoeRecipientData';
          readonly recipient: string;
          readonly disclaimer?: string | null;
        }>;
        readonly wireMessages: ReadonlyArray<{
          readonly __typename?: 'WireMessage';
          readonly id: string;
          readonly emailAddresses: ReadonlyArray<string>;
          readonly expiredAt?: string | null;
          readonly respondedAt?: string | null;
          readonly responseStatus?: Types.SentWireResponseStatus | null;
          readonly wireMessageAndResponseStatus: Types.WireMessageAndResponseStatus;
          readonly sentAt?: string | null;
          readonly htmlBody: string;
          readonly firmSnapshot?: {
            readonly __typename?: 'FirmSnapshot';
            readonly cmgEntityKey: string;
            readonly firmName: string;
            readonly offeringManagerRole?: Types.SyndicateWiresManagerRole | null;
          } | null;
        }>;
      }
    | {
        readonly __typename: 'ServiceError';
        readonly code: Types.ServiceErrorCode;
        readonly message: string;
        readonly target?: string | null;
        readonly details?: ReadonlyArray<{
          readonly __typename: 'ErrorDetail';
          readonly code?: string | null;
          readonly message?: string | null;
          readonly target?: string | null;
        }> | null;
      };
};

export type SyndicateWires_DeleteGreenShoeWireMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  syndicateWireId: Types.Scalars['UUID'];
}>;

export type SyndicateWires_DeleteGreenShoeWireMutation = {
  readonly __typename?: 'Mutation';
  readonly deleteGreenShoeWire:
    | { readonly __typename?: 'DeleteWire'; readonly id: string }
    | {
        readonly __typename: 'ServiceError';
        readonly code: Types.ServiceErrorCode;
        readonly message: string;
        readonly target?: string | null;
        readonly details?: ReadonlyArray<{
          readonly __typename: 'ErrorDetail';
          readonly code?: string | null;
          readonly message?: string | null;
          readonly target?: string | null;
        }> | null;
      };
};

export type SyndicateWires_GreenShoeWireDetailsQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  syndicateWireId: Types.Scalars['UUID'];
}>;

export type SyndicateWires_GreenShoeWireDetailsQuery = {
  readonly __typename?: 'Query';
  readonly offering: {
    readonly __typename?: 'Offering';
    readonly id: string;
    readonly stage: Types.OfferingStage;
  };
  readonly greenShoeWireDetails: {
    readonly __typename?: 'GreenShoeWire';
    readonly id: string;
    readonly status: Types.WireStatus;
    readonly createdAt: string;
    readonly sender: string;
    readonly recipientsUserData: ReadonlyArray<{
      readonly __typename?: 'GreenShoeRecipientData';
      readonly recipient: string;
      readonly disclaimer?: string | null;
    }>;
    readonly wireMessages: ReadonlyArray<{
      readonly __typename?: 'WireMessage';
      readonly id: string;
      readonly emailAddresses: ReadonlyArray<string>;
      readonly expiredAt?: string | null;
      readonly respondedAt?: string | null;
      readonly responseStatus?: Types.SentWireResponseStatus | null;
      readonly wireMessageAndResponseStatus: Types.WireMessageAndResponseStatus;
      readonly sentAt?: string | null;
      readonly htmlBody: string;
      readonly firmSnapshot?: {
        readonly __typename?: 'FirmSnapshot';
        readonly cmgEntityKey: string;
        readonly firmName: string;
        readonly offeringManagerRole?: Types.SyndicateWiresManagerRole | null;
      } | null;
    }>;
  };
};

export type SyndicateWires_GreenShoeRecipientDataPartsFragment = {
  readonly __typename?: 'GreenShoeRecipientData';
  readonly recipient: string;
  readonly disclaimer?: string | null;
};

export type SyndicateWires_GreenShoeWirePartsFragment = {
  readonly __typename?: 'GreenShoeWire';
  readonly id: string;
  readonly status: Types.WireStatus;
  readonly createdAt: string;
  readonly sender: string;
  readonly recipientsUserData: ReadonlyArray<{
    readonly __typename?: 'GreenShoeRecipientData';
    readonly recipient: string;
    readonly disclaimer?: string | null;
  }>;
  readonly wireMessages: ReadonlyArray<{
    readonly __typename?: 'WireMessage';
    readonly id: string;
    readonly emailAddresses: ReadonlyArray<string>;
    readonly expiredAt?: string | null;
    readonly respondedAt?: string | null;
    readonly responseStatus?: Types.SentWireResponseStatus | null;
    readonly wireMessageAndResponseStatus: Types.WireMessageAndResponseStatus;
    readonly sentAt?: string | null;
    readonly htmlBody: string;
    readonly firmSnapshot?: {
      readonly __typename?: 'FirmSnapshot';
      readonly cmgEntityKey: string;
      readonly firmName: string;
      readonly offeringManagerRole?: Types.SyndicateWiresManagerRole | null;
    } | null;
  }>;
};

export type SyndicateWires_GreenShoeWirePreviewQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  syndicateWireId?: Types.InputMaybe<Types.Scalars['UUID']>;
  cmgEntityKey?: Types.InputMaybe<Types.Scalars['String']>;
}>;

export type SyndicateWires_GreenShoeWirePreviewQuery = {
  readonly __typename?: 'Query';
  readonly greenShoeWirePreview: {
    readonly __typename?: 'WirePreview';
    readonly htmlContent: string;
  };
};

export type SyndicateWires_GreenShoeWireValidationPartsFragment = {
  readonly __typename?: 'GreenShoeWireValidation';
  readonly orderBookErrors: ReadonlyArray<Types.OrderBookErrorType>;
  readonly offeringErrors: ReadonlyArray<Types.GreenShoeWireValidationOfferingErrors>;
  readonly settlementErrors: ReadonlyArray<Types.SettlementErrorType>;
  readonly managers: ReadonlyArray<{
    readonly __typename?: 'ManagerValidation';
    readonly cmgEntityKey: string;
    readonly fields: ReadonlyArray<Types.ManagerErrorType>;
  }>;
  readonly sender: {
    readonly __typename?: 'ManagerValidation';
    readonly cmgEntityKey: string;
    readonly fields: ReadonlyArray<Types.ManagerErrorType>;
  };
};

export type SyndicateWires_GreenShoeWireValidationQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type SyndicateWires_GreenShoeWireValidationQuery = {
  readonly __typename?: 'Query';
  readonly greenShoeWireValidation: {
    readonly __typename?: 'GreenShoeWireValidation';
    readonly orderBookErrors: ReadonlyArray<Types.OrderBookErrorType>;
    readonly offeringErrors: ReadonlyArray<Types.GreenShoeWireValidationOfferingErrors>;
    readonly settlementErrors: ReadonlyArray<Types.SettlementErrorType>;
    readonly managers: ReadonlyArray<{
      readonly __typename?: 'ManagerValidation';
      readonly cmgEntityKey: string;
      readonly fields: ReadonlyArray<Types.ManagerErrorType>;
    }>;
    readonly sender: {
      readonly __typename?: 'ManagerValidation';
      readonly cmgEntityKey: string;
      readonly fields: ReadonlyArray<Types.ManagerErrorType>;
    };
  };
};

export type SyndicateWires_GreenShoeWiresQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type SyndicateWires_GreenShoeWiresQuery = {
  readonly __typename?: 'Query';
  readonly greenShoeWireList: ReadonlyArray<{
    readonly __typename?: 'GreenShoeWire';
    readonly id: string;
    readonly status: Types.WireStatus;
    readonly createdAt: string;
    readonly sender: string;
    readonly recipientsUserData: ReadonlyArray<{
      readonly __typename?: 'GreenShoeRecipientData';
      readonly recipient: string;
      readonly disclaimer?: string | null;
    }>;
    readonly wireMessages: ReadonlyArray<{
      readonly __typename?: 'WireMessage';
      readonly id: string;
      readonly emailAddresses: ReadonlyArray<string>;
      readonly expiredAt?: string | null;
      readonly respondedAt?: string | null;
      readonly responseStatus?: Types.SentWireResponseStatus | null;
      readonly wireMessageAndResponseStatus: Types.WireMessageAndResponseStatus;
      readonly sentAt?: string | null;
      readonly htmlBody: string;
      readonly firmSnapshot?: {
        readonly __typename?: 'FirmSnapshot';
        readonly cmgEntityKey: string;
        readonly firmName: string;
        readonly offeringManagerRole?: Types.SyndicateWiresManagerRole | null;
      } | null;
    }>;
  }>;
};

export type SyndicateWires_SendGreenShoeWireMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  syndicateWireId: Types.Scalars['UUID'];
  managerIds: ReadonlyArray<Types.Scalars['String']> | Types.Scalars['String'];
  sendDateTimeZone: Types.Scalars['String'];
  shouldAddSenderToCc: Types.Scalars['Boolean'];
}>;

export type SyndicateWires_SendGreenShoeWireMutation = {
  readonly __typename?: 'Mutation';
  readonly sendGreenShoeWire:
    | {
        readonly __typename?: 'GreenShoeWire';
        readonly id: string;
        readonly status: Types.WireStatus;
        readonly createdAt: string;
        readonly sender: string;
        readonly recipientsUserData: ReadonlyArray<{
          readonly __typename?: 'GreenShoeRecipientData';
          readonly recipient: string;
          readonly disclaimer?: string | null;
        }>;
        readonly wireMessages: ReadonlyArray<{
          readonly __typename?: 'WireMessage';
          readonly id: string;
          readonly emailAddresses: ReadonlyArray<string>;
          readonly expiredAt?: string | null;
          readonly respondedAt?: string | null;
          readonly responseStatus?: Types.SentWireResponseStatus | null;
          readonly wireMessageAndResponseStatus: Types.WireMessageAndResponseStatus;
          readonly sentAt?: string | null;
          readonly htmlBody: string;
          readonly firmSnapshot?: {
            readonly __typename?: 'FirmSnapshot';
            readonly cmgEntityKey: string;
            readonly firmName: string;
            readonly offeringManagerRole?: Types.SyndicateWiresManagerRole | null;
          } | null;
        }>;
      }
    | {
        readonly __typename: 'ServiceError';
        readonly code: Types.ServiceErrorCode;
        readonly message: string;
        readonly target?: string | null;
        readonly details?: ReadonlyArray<{
          readonly __typename: 'ErrorDetail';
          readonly code?: string | null;
          readonly message?: string | null;
          readonly target?: string | null;
        }> | null;
      };
};

export type SyndicateWires_UpdateGreenShoeWireMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  syndicateWireId: Types.Scalars['UUID'];
  payload: Types.GreenShoeWireInput;
}>;

export type SyndicateWires_UpdateGreenShoeWireMutation = {
  readonly __typename?: 'Mutation';
  readonly updateGreenShoeWire:
    | {
        readonly __typename?: 'GreenShoeWire';
        readonly id: string;
        readonly status: Types.WireStatus;
        readonly createdAt: string;
        readonly sender: string;
        readonly recipientsUserData: ReadonlyArray<{
          readonly __typename?: 'GreenShoeRecipientData';
          readonly recipient: string;
          readonly disclaimer?: string | null;
        }>;
        readonly wireMessages: ReadonlyArray<{
          readonly __typename?: 'WireMessage';
          readonly id: string;
          readonly emailAddresses: ReadonlyArray<string>;
          readonly expiredAt?: string | null;
          readonly respondedAt?: string | null;
          readonly responseStatus?: Types.SentWireResponseStatus | null;
          readonly wireMessageAndResponseStatus: Types.WireMessageAndResponseStatus;
          readonly sentAt?: string | null;
          readonly htmlBody: string;
          readonly firmSnapshot?: {
            readonly __typename?: 'FirmSnapshot';
            readonly cmgEntityKey: string;
            readonly firmName: string;
            readonly offeringManagerRole?: Types.SyndicateWiresManagerRole | null;
          } | null;
        }>;
      }
    | {
        readonly __typename: 'ServiceError';
        readonly code: Types.ServiceErrorCode;
        readonly message: string;
        readonly target?: string | null;
        readonly details?: ReadonlyArray<{
          readonly __typename: 'ErrorDetail';
          readonly code?: string | null;
          readonly message?: string | null;
          readonly target?: string | null;
        }> | null;
      };
};

export const SyndicateWires_GreenShoeRecipientDataPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_GreenShoeRecipientDataParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GreenShoeRecipientData' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'recipient' } },
          { kind: 'Field', name: { kind: 'Name', value: 'disclaimer' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const SyndicateWires_GreenShoeWirePartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_GreenShoeWireParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GreenShoeWire' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recipientsUserData' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_GreenShoeRecipientDataParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wireMessages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_WireMessageParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_GreenShoeRecipientDataParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GreenShoeRecipientData' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'recipient' } },
          { kind: 'Field', name: { kind: 'Name', value: 'disclaimer' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_WireMessageParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WireMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddresses' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'firmSnapshot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_FirmSnapshotParts' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'respondedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'responseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wireMessageAndResponseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sentAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'htmlBody' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_FirmSnapshotParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FirmSnapshot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringManagerRole' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const SyndicateWires_GreenShoeWireValidationPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_GreenShoeWireValidationParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GreenShoeWireValidation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'orderBookErrors' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringErrors' } },
          { kind: 'Field', name: { kind: 'Name', value: 'settlementErrors' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'managers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_ManagerValidationParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sender' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_ManagerValidationParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_ManagerValidationParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ManagerValidation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fields' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const SyndicateWires_CreateGreenShoeWireDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SyndicateWires_CreateGreenShoeWire' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'payload' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'GreenShoeWireInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createGreenShoeWire' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'payload' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'payload' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_GreenShoeWireParts' },
                },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ServiceErrorParts' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_GreenShoeRecipientDataParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GreenShoeRecipientData' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'recipient' } },
          { kind: 'Field', name: { kind: 'Name', value: 'disclaimer' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_GreenShoeWireParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GreenShoeWire' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recipientsUserData' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_GreenShoeRecipientDataParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wireMessages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_WireMessageParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_WireMessageParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WireMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddresses' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'firmSnapshot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_FirmSnapshotParts' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'respondedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'responseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wireMessageAndResponseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sentAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'htmlBody' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_FirmSnapshotParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FirmSnapshot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringManagerRole' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ServiceErrorParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ServiceError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'target' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'target' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type SyndicateWires_CreateGreenShoeWireMutationFn = Apollo.MutationFunction<
  SyndicateWires_CreateGreenShoeWireMutation,
  SyndicateWires_CreateGreenShoeWireMutationVariables
>;

/**
 * __useSyndicateWires_CreateGreenShoeWireMutation__
 *
 * To run a mutation, you first call `useSyndicateWires_CreateGreenShoeWireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyndicateWires_CreateGreenShoeWireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syndicateWiresCreateGreenShoeWireMutation, { data, loading, error }] = useSyndicateWires_CreateGreenShoeWireMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useSyndicateWires_CreateGreenShoeWireMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SyndicateWires_CreateGreenShoeWireMutation,
    SyndicateWires_CreateGreenShoeWireMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SyndicateWires_CreateGreenShoeWireMutation,
    SyndicateWires_CreateGreenShoeWireMutationVariables
  >(SyndicateWires_CreateGreenShoeWireDocument, options);
}
export type SyndicateWires_CreateGreenShoeWireMutationHookResult = ReturnType<
  typeof useSyndicateWires_CreateGreenShoeWireMutation
>;
export type SyndicateWires_CreateGreenShoeWireMutationResult =
  Apollo.MutationResult<SyndicateWires_CreateGreenShoeWireMutation>;
export type SyndicateWires_CreateGreenShoeWireMutationOptions = Apollo.BaseMutationOptions<
  SyndicateWires_CreateGreenShoeWireMutation,
  SyndicateWires_CreateGreenShoeWireMutationVariables
>;
export const SyndicateWires_DeleteGreenShoeWireDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SyndicateWires_DeleteGreenShoeWire' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'syndicateWireId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteGreenShoeWire' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'syndicateWireId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'syndicateWireId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_DeleteWireParts' },
                },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ServiceErrorParts' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ServiceErrorParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ServiceError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'target' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'target' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_DeleteWireParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DeleteWire' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
      },
    },
  ],
} as unknown as DocumentNode;
export type SyndicateWires_DeleteGreenShoeWireMutationFn = Apollo.MutationFunction<
  SyndicateWires_DeleteGreenShoeWireMutation,
  SyndicateWires_DeleteGreenShoeWireMutationVariables
>;

/**
 * __useSyndicateWires_DeleteGreenShoeWireMutation__
 *
 * To run a mutation, you first call `useSyndicateWires_DeleteGreenShoeWireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyndicateWires_DeleteGreenShoeWireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syndicateWiresDeleteGreenShoeWireMutation, { data, loading, error }] = useSyndicateWires_DeleteGreenShoeWireMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      syndicateWireId: // value for 'syndicateWireId'
 *   },
 * });
 */
export function useSyndicateWires_DeleteGreenShoeWireMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SyndicateWires_DeleteGreenShoeWireMutation,
    SyndicateWires_DeleteGreenShoeWireMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SyndicateWires_DeleteGreenShoeWireMutation,
    SyndicateWires_DeleteGreenShoeWireMutationVariables
  >(SyndicateWires_DeleteGreenShoeWireDocument, options);
}
export type SyndicateWires_DeleteGreenShoeWireMutationHookResult = ReturnType<
  typeof useSyndicateWires_DeleteGreenShoeWireMutation
>;
export type SyndicateWires_DeleteGreenShoeWireMutationResult =
  Apollo.MutationResult<SyndicateWires_DeleteGreenShoeWireMutation>;
export type SyndicateWires_DeleteGreenShoeWireMutationOptions = Apollo.BaseMutationOptions<
  SyndicateWires_DeleteGreenShoeWireMutation,
  SyndicateWires_DeleteGreenShoeWireMutationVariables
>;
export const SyndicateWires_GreenShoeWireDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SyndicateWires_GreenShoeWireDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'syndicateWireId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'offering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'greenShoeWireDetails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'syndicateWireId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'syndicateWireId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_GreenShoeWireParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_GreenShoeRecipientDataParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GreenShoeRecipientData' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'recipient' } },
          { kind: 'Field', name: { kind: 'Name', value: 'disclaimer' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_GreenShoeWireParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GreenShoeWire' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recipientsUserData' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_GreenShoeRecipientDataParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wireMessages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_WireMessageParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_WireMessageParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WireMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddresses' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'firmSnapshot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_FirmSnapshotParts' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'respondedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'responseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wireMessageAndResponseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sentAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'htmlBody' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_FirmSnapshotParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FirmSnapshot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringManagerRole' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useSyndicateWires_GreenShoeWireDetailsQuery__
 *
 * To run a query within a React component, call `useSyndicateWires_GreenShoeWireDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSyndicateWires_GreenShoeWireDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSyndicateWires_GreenShoeWireDetailsQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      syndicateWireId: // value for 'syndicateWireId'
 *   },
 * });
 */
export function useSyndicateWires_GreenShoeWireDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    SyndicateWires_GreenShoeWireDetailsQuery,
    SyndicateWires_GreenShoeWireDetailsQueryVariables
  > &
    (
      | { variables: SyndicateWires_GreenShoeWireDetailsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SyndicateWires_GreenShoeWireDetailsQuery,
    SyndicateWires_GreenShoeWireDetailsQueryVariables
  >(SyndicateWires_GreenShoeWireDetailsDocument, options);
}
export function useSyndicateWires_GreenShoeWireDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SyndicateWires_GreenShoeWireDetailsQuery,
    SyndicateWires_GreenShoeWireDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SyndicateWires_GreenShoeWireDetailsQuery,
    SyndicateWires_GreenShoeWireDetailsQueryVariables
  >(SyndicateWires_GreenShoeWireDetailsDocument, options);
}
export function useSyndicateWires_GreenShoeWireDetailsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        SyndicateWires_GreenShoeWireDetailsQuery,
        SyndicateWires_GreenShoeWireDetailsQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SyndicateWires_GreenShoeWireDetailsQuery,
    SyndicateWires_GreenShoeWireDetailsQueryVariables
  >(SyndicateWires_GreenShoeWireDetailsDocument, options);
}
export type SyndicateWires_GreenShoeWireDetailsQueryHookResult = ReturnType<
  typeof useSyndicateWires_GreenShoeWireDetailsQuery
>;
export type SyndicateWires_GreenShoeWireDetailsLazyQueryHookResult = ReturnType<
  typeof useSyndicateWires_GreenShoeWireDetailsLazyQuery
>;
export type SyndicateWires_GreenShoeWireDetailsSuspenseQueryHookResult = ReturnType<
  typeof useSyndicateWires_GreenShoeWireDetailsSuspenseQuery
>;
export type SyndicateWires_GreenShoeWireDetailsQueryResult = Apollo.QueryResult<
  SyndicateWires_GreenShoeWireDetailsQuery,
  SyndicateWires_GreenShoeWireDetailsQueryVariables
>;
export const SyndicateWires_GreenShoeWirePreviewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SyndicateWires_GreenShoeWirePreview' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'syndicateWireId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKey' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'greenShoeWirePreview' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'syndicateWireId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'syndicateWireId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cmgEntityKey' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKey' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'htmlContent' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useSyndicateWires_GreenShoeWirePreviewQuery__
 *
 * To run a query within a React component, call `useSyndicateWires_GreenShoeWirePreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useSyndicateWires_GreenShoeWirePreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSyndicateWires_GreenShoeWirePreviewQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      syndicateWireId: // value for 'syndicateWireId'
 *      cmgEntityKey: // value for 'cmgEntityKey'
 *   },
 * });
 */
export function useSyndicateWires_GreenShoeWirePreviewQuery(
  baseOptions: Apollo.QueryHookOptions<
    SyndicateWires_GreenShoeWirePreviewQuery,
    SyndicateWires_GreenShoeWirePreviewQueryVariables
  > &
    (
      | { variables: SyndicateWires_GreenShoeWirePreviewQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SyndicateWires_GreenShoeWirePreviewQuery,
    SyndicateWires_GreenShoeWirePreviewQueryVariables
  >(SyndicateWires_GreenShoeWirePreviewDocument, options);
}
export function useSyndicateWires_GreenShoeWirePreviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SyndicateWires_GreenShoeWirePreviewQuery,
    SyndicateWires_GreenShoeWirePreviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SyndicateWires_GreenShoeWirePreviewQuery,
    SyndicateWires_GreenShoeWirePreviewQueryVariables
  >(SyndicateWires_GreenShoeWirePreviewDocument, options);
}
export function useSyndicateWires_GreenShoeWirePreviewSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        SyndicateWires_GreenShoeWirePreviewQuery,
        SyndicateWires_GreenShoeWirePreviewQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SyndicateWires_GreenShoeWirePreviewQuery,
    SyndicateWires_GreenShoeWirePreviewQueryVariables
  >(SyndicateWires_GreenShoeWirePreviewDocument, options);
}
export type SyndicateWires_GreenShoeWirePreviewQueryHookResult = ReturnType<
  typeof useSyndicateWires_GreenShoeWirePreviewQuery
>;
export type SyndicateWires_GreenShoeWirePreviewLazyQueryHookResult = ReturnType<
  typeof useSyndicateWires_GreenShoeWirePreviewLazyQuery
>;
export type SyndicateWires_GreenShoeWirePreviewSuspenseQueryHookResult = ReturnType<
  typeof useSyndicateWires_GreenShoeWirePreviewSuspenseQuery
>;
export type SyndicateWires_GreenShoeWirePreviewQueryResult = Apollo.QueryResult<
  SyndicateWires_GreenShoeWirePreviewQuery,
  SyndicateWires_GreenShoeWirePreviewQueryVariables
>;
export const SyndicateWires_GreenShoeWireValidationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SyndicateWires_GreenShoeWireValidation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'greenShoeWireValidation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_GreenShoeWireValidationParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_GreenShoeWireValidationParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GreenShoeWireValidation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'orderBookErrors' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringErrors' } },
          { kind: 'Field', name: { kind: 'Name', value: 'settlementErrors' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'managers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_ManagerValidationParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sender' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_ManagerValidationParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_ManagerValidationParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ManagerValidation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fields' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useSyndicateWires_GreenShoeWireValidationQuery__
 *
 * To run a query within a React component, call `useSyndicateWires_GreenShoeWireValidationQuery` and pass it any options that fit your needs.
 * When your component renders, `useSyndicateWires_GreenShoeWireValidationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSyndicateWires_GreenShoeWireValidationQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useSyndicateWires_GreenShoeWireValidationQuery(
  baseOptions: Apollo.QueryHookOptions<
    SyndicateWires_GreenShoeWireValidationQuery,
    SyndicateWires_GreenShoeWireValidationQueryVariables
  > &
    (
      | { variables: SyndicateWires_GreenShoeWireValidationQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SyndicateWires_GreenShoeWireValidationQuery,
    SyndicateWires_GreenShoeWireValidationQueryVariables
  >(SyndicateWires_GreenShoeWireValidationDocument, options);
}
export function useSyndicateWires_GreenShoeWireValidationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SyndicateWires_GreenShoeWireValidationQuery,
    SyndicateWires_GreenShoeWireValidationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SyndicateWires_GreenShoeWireValidationQuery,
    SyndicateWires_GreenShoeWireValidationQueryVariables
  >(SyndicateWires_GreenShoeWireValidationDocument, options);
}
export function useSyndicateWires_GreenShoeWireValidationSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        SyndicateWires_GreenShoeWireValidationQuery,
        SyndicateWires_GreenShoeWireValidationQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SyndicateWires_GreenShoeWireValidationQuery,
    SyndicateWires_GreenShoeWireValidationQueryVariables
  >(SyndicateWires_GreenShoeWireValidationDocument, options);
}
export type SyndicateWires_GreenShoeWireValidationQueryHookResult = ReturnType<
  typeof useSyndicateWires_GreenShoeWireValidationQuery
>;
export type SyndicateWires_GreenShoeWireValidationLazyQueryHookResult = ReturnType<
  typeof useSyndicateWires_GreenShoeWireValidationLazyQuery
>;
export type SyndicateWires_GreenShoeWireValidationSuspenseQueryHookResult = ReturnType<
  typeof useSyndicateWires_GreenShoeWireValidationSuspenseQuery
>;
export type SyndicateWires_GreenShoeWireValidationQueryResult = Apollo.QueryResult<
  SyndicateWires_GreenShoeWireValidationQuery,
  SyndicateWires_GreenShoeWireValidationQueryVariables
>;
export const SyndicateWires_GreenShoeWiresDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SyndicateWires_GreenShoeWires' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'greenShoeWireList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_GreenShoeWireParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_GreenShoeRecipientDataParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GreenShoeRecipientData' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'recipient' } },
          { kind: 'Field', name: { kind: 'Name', value: 'disclaimer' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_GreenShoeWireParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GreenShoeWire' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recipientsUserData' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_GreenShoeRecipientDataParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wireMessages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_WireMessageParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_WireMessageParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WireMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddresses' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'firmSnapshot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_FirmSnapshotParts' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'respondedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'responseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wireMessageAndResponseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sentAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'htmlBody' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_FirmSnapshotParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FirmSnapshot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringManagerRole' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useSyndicateWires_GreenShoeWiresQuery__
 *
 * To run a query within a React component, call `useSyndicateWires_GreenShoeWiresQuery` and pass it any options that fit your needs.
 * When your component renders, `useSyndicateWires_GreenShoeWiresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSyndicateWires_GreenShoeWiresQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useSyndicateWires_GreenShoeWiresQuery(
  baseOptions: Apollo.QueryHookOptions<
    SyndicateWires_GreenShoeWiresQuery,
    SyndicateWires_GreenShoeWiresQueryVariables
  > &
    ({ variables: SyndicateWires_GreenShoeWiresQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SyndicateWires_GreenShoeWiresQuery,
    SyndicateWires_GreenShoeWiresQueryVariables
  >(SyndicateWires_GreenShoeWiresDocument, options);
}
export function useSyndicateWires_GreenShoeWiresLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SyndicateWires_GreenShoeWiresQuery,
    SyndicateWires_GreenShoeWiresQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SyndicateWires_GreenShoeWiresQuery,
    SyndicateWires_GreenShoeWiresQueryVariables
  >(SyndicateWires_GreenShoeWiresDocument, options);
}
export function useSyndicateWires_GreenShoeWiresSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        SyndicateWires_GreenShoeWiresQuery,
        SyndicateWires_GreenShoeWiresQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SyndicateWires_GreenShoeWiresQuery,
    SyndicateWires_GreenShoeWiresQueryVariables
  >(SyndicateWires_GreenShoeWiresDocument, options);
}
export type SyndicateWires_GreenShoeWiresQueryHookResult = ReturnType<
  typeof useSyndicateWires_GreenShoeWiresQuery
>;
export type SyndicateWires_GreenShoeWiresLazyQueryHookResult = ReturnType<
  typeof useSyndicateWires_GreenShoeWiresLazyQuery
>;
export type SyndicateWires_GreenShoeWiresSuspenseQueryHookResult = ReturnType<
  typeof useSyndicateWires_GreenShoeWiresSuspenseQuery
>;
export type SyndicateWires_GreenShoeWiresQueryResult = Apollo.QueryResult<
  SyndicateWires_GreenShoeWiresQuery,
  SyndicateWires_GreenShoeWiresQueryVariables
>;
export const SyndicateWires_SendGreenShoeWireDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SyndicateWires_SendGreenShoeWire' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'syndicateWireId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'managerIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sendDateTimeZone' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'shouldAddSenderToCc' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendGreenShoeWire' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'syndicateWireId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'syndicateWireId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'payload' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'underwriterList' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'managerIds' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'sendDateTimeZone' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'sendDateTimeZone' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'shouldAddSenderToCc' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'shouldAddSenderToCc' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_GreenShoeWireParts' },
                },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ServiceErrorParts' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_GreenShoeRecipientDataParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GreenShoeRecipientData' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'recipient' } },
          { kind: 'Field', name: { kind: 'Name', value: 'disclaimer' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_GreenShoeWireParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GreenShoeWire' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recipientsUserData' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_GreenShoeRecipientDataParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wireMessages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_WireMessageParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_WireMessageParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WireMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddresses' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'firmSnapshot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_FirmSnapshotParts' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'respondedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'responseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wireMessageAndResponseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sentAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'htmlBody' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_FirmSnapshotParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FirmSnapshot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringManagerRole' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ServiceErrorParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ServiceError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'target' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'target' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type SyndicateWires_SendGreenShoeWireMutationFn = Apollo.MutationFunction<
  SyndicateWires_SendGreenShoeWireMutation,
  SyndicateWires_SendGreenShoeWireMutationVariables
>;

/**
 * __useSyndicateWires_SendGreenShoeWireMutation__
 *
 * To run a mutation, you first call `useSyndicateWires_SendGreenShoeWireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyndicateWires_SendGreenShoeWireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syndicateWiresSendGreenShoeWireMutation, { data, loading, error }] = useSyndicateWires_SendGreenShoeWireMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      syndicateWireId: // value for 'syndicateWireId'
 *      managerIds: // value for 'managerIds'
 *      sendDateTimeZone: // value for 'sendDateTimeZone'
 *      shouldAddSenderToCc: // value for 'shouldAddSenderToCc'
 *   },
 * });
 */
export function useSyndicateWires_SendGreenShoeWireMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SyndicateWires_SendGreenShoeWireMutation,
    SyndicateWires_SendGreenShoeWireMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SyndicateWires_SendGreenShoeWireMutation,
    SyndicateWires_SendGreenShoeWireMutationVariables
  >(SyndicateWires_SendGreenShoeWireDocument, options);
}
export type SyndicateWires_SendGreenShoeWireMutationHookResult = ReturnType<
  typeof useSyndicateWires_SendGreenShoeWireMutation
>;
export type SyndicateWires_SendGreenShoeWireMutationResult =
  Apollo.MutationResult<SyndicateWires_SendGreenShoeWireMutation>;
export type SyndicateWires_SendGreenShoeWireMutationOptions = Apollo.BaseMutationOptions<
  SyndicateWires_SendGreenShoeWireMutation,
  SyndicateWires_SendGreenShoeWireMutationVariables
>;
export const SyndicateWires_UpdateGreenShoeWireDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SyndicateWires_UpdateGreenShoeWire' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'syndicateWireId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'payload' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'GreenShoeWireInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateGreenShoeWire' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'syndicateWireId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'syndicateWireId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'payload' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'payload' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_GreenShoeWireParts' },
                },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ServiceErrorParts' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_GreenShoeRecipientDataParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GreenShoeRecipientData' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'recipient' } },
          { kind: 'Field', name: { kind: 'Name', value: 'disclaimer' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_GreenShoeWireParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GreenShoeWire' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recipientsUserData' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_GreenShoeRecipientDataParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wireMessages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_WireMessageParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_WireMessageParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WireMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddresses' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'firmSnapshot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_FirmSnapshotParts' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'respondedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'responseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wireMessageAndResponseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sentAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'htmlBody' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_FirmSnapshotParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FirmSnapshot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringManagerRole' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ServiceErrorParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ServiceError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'target' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'target' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type SyndicateWires_UpdateGreenShoeWireMutationFn = Apollo.MutationFunction<
  SyndicateWires_UpdateGreenShoeWireMutation,
  SyndicateWires_UpdateGreenShoeWireMutationVariables
>;

/**
 * __useSyndicateWires_UpdateGreenShoeWireMutation__
 *
 * To run a mutation, you first call `useSyndicateWires_UpdateGreenShoeWireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyndicateWires_UpdateGreenShoeWireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syndicateWiresUpdateGreenShoeWireMutation, { data, loading, error }] = useSyndicateWires_UpdateGreenShoeWireMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      syndicateWireId: // value for 'syndicateWireId'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useSyndicateWires_UpdateGreenShoeWireMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SyndicateWires_UpdateGreenShoeWireMutation,
    SyndicateWires_UpdateGreenShoeWireMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SyndicateWires_UpdateGreenShoeWireMutation,
    SyndicateWires_UpdateGreenShoeWireMutationVariables
  >(SyndicateWires_UpdateGreenShoeWireDocument, options);
}
export type SyndicateWires_UpdateGreenShoeWireMutationHookResult = ReturnType<
  typeof useSyndicateWires_UpdateGreenShoeWireMutation
>;
export type SyndicateWires_UpdateGreenShoeWireMutationResult =
  Apollo.MutationResult<SyndicateWires_UpdateGreenShoeWireMutation>;
export type SyndicateWires_UpdateGreenShoeWireMutationOptions = Apollo.BaseMutationOptions<
  SyndicateWires_UpdateGreenShoeWireMutation,
  SyndicateWires_UpdateGreenShoeWireMutationVariables
>;

import { makeMockResponse } from '../../../../../../../graphql/mock-response';

export const makeSyndicateWires_GreenShoeWireDetailsMockResponse = makeMockResponse<
  SyndicateWires_GreenShoeWireDetailsQueryVariables,
  SyndicateWires_GreenShoeWireDetailsQuery
>(SyndicateWires_GreenShoeWireDetailsDocument);

export const makeSyndicateWires_GreenShoeWirePreviewMockResponse = makeMockResponse<
  SyndicateWires_GreenShoeWirePreviewQueryVariables,
  SyndicateWires_GreenShoeWirePreviewQuery
>(SyndicateWires_GreenShoeWirePreviewDocument);

export const makeSyndicateWires_GreenShoeWireValidationMockResponse = makeMockResponse<
  SyndicateWires_GreenShoeWireValidationQueryVariables,
  SyndicateWires_GreenShoeWireValidationQuery
>(SyndicateWires_GreenShoeWireValidationDocument);

export const makeSyndicateWires_GreenShoeWiresMockResponse = makeMockResponse<
  SyndicateWires_GreenShoeWiresQueryVariables,
  SyndicateWires_GreenShoeWiresQuery
>(SyndicateWires_GreenShoeWiresDocument);
