/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OrderBook_GetAllocationSetShareInformationQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type OrderBook_GetAllocationSetShareInformationQuery = {
  readonly __typename?: 'Query';
  readonly publishedOffering: {
    readonly __typename?: 'OfferingProfile';
    readonly id: string;
    readonly syndicateEntitlements: {
      readonly __typename?: 'Entitlements';
      readonly syndicateEntitlements: ReadonlyArray<{
        readonly __typename?: 'SyndicateEntitlements';
        readonly cmgEntityKey: string;
        readonly entitlements: ReadonlyArray<string>;
      }>;
    };
    readonly syndicate: {
      readonly __typename?: 'SyndicateProfile';
      readonly managers: ReadonlyArray<{
        readonly __typename?: 'ManagerProfile';
        readonly cmgEntityKey: string;
        readonly firmName: string;
      }>;
    };
  };
};

export const OrderBook_GetAllocationSetShareInformationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'OrderBook_GetAllocationSetShareInformation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'publishedOffering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'syndicateEntitlements' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'syndicateEntitlements' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'entitlements' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'syndicate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'managers' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useOrderBook_GetAllocationSetShareInformationQuery__
 *
 * To run a query within a React component, call `useOrderBook_GetAllocationSetShareInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderBook_GetAllocationSetShareInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderBook_GetAllocationSetShareInformationQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useOrderBook_GetAllocationSetShareInformationQuery(
  baseOptions: Apollo.QueryHookOptions<
    OrderBook_GetAllocationSetShareInformationQuery,
    OrderBook_GetAllocationSetShareInformationQueryVariables
  > &
    (
      | { variables: OrderBook_GetAllocationSetShareInformationQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OrderBook_GetAllocationSetShareInformationQuery,
    OrderBook_GetAllocationSetShareInformationQueryVariables
  >(OrderBook_GetAllocationSetShareInformationDocument, options);
}
export function useOrderBook_GetAllocationSetShareInformationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrderBook_GetAllocationSetShareInformationQuery,
    OrderBook_GetAllocationSetShareInformationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OrderBook_GetAllocationSetShareInformationQuery,
    OrderBook_GetAllocationSetShareInformationQueryVariables
  >(OrderBook_GetAllocationSetShareInformationDocument, options);
}
export function useOrderBook_GetAllocationSetShareInformationSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        OrderBook_GetAllocationSetShareInformationQuery,
        OrderBook_GetAllocationSetShareInformationQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    OrderBook_GetAllocationSetShareInformationQuery,
    OrderBook_GetAllocationSetShareInformationQueryVariables
  >(OrderBook_GetAllocationSetShareInformationDocument, options);
}
export type OrderBook_GetAllocationSetShareInformationQueryHookResult = ReturnType<
  typeof useOrderBook_GetAllocationSetShareInformationQuery
>;
export type OrderBook_GetAllocationSetShareInformationLazyQueryHookResult = ReturnType<
  typeof useOrderBook_GetAllocationSetShareInformationLazyQuery
>;
export type OrderBook_GetAllocationSetShareInformationSuspenseQueryHookResult = ReturnType<
  typeof useOrderBook_GetAllocationSetShareInformationSuspenseQuery
>;
export type OrderBook_GetAllocationSetShareInformationQueryResult = Apollo.QueryResult<
  OrderBook_GetAllocationSetShareInformationQuery,
  OrderBook_GetAllocationSetShareInformationQueryVariables
>;

import { makeMockResponse } from '../../../../../../../../../../../graphql/mock-response';

export const makeOrderBook_GetAllocationSetShareInformationMockResponse = makeMockResponse<
  OrderBook_GetAllocationSetShareInformationQueryVariables,
  OrderBook_GetAllocationSetShareInformationQuery
>(OrderBook_GetAllocationSetShareInformationDocument);
