import { ActionPanelSection, Button, LoadingButton, Stack } from '@cmg/design-system';
import { Form, FormikProvider, useFormik } from 'formik';
import React from 'react';

import { InstitutionalIndicationOrderType } from '../../../../../../../../graphql';
import { useIndicationActivityBuySideContext } from '../../../../context/IndicationActivityBuySideContext';
import IndicationSectionForm from '../../common/indication-section-form/IndicationSectionForm';
import IndicationStatus from '../../common/indication-status/IndicationStatus';
import {
  IndicationFormValues,
  validationSchema,
} from '../../common/utils/IndicationSectionValidation';
import PassOfferingConfirmDialog from '../common/pass-offering-confirm-dialog/PassOfferingConfirmDialog';
import { createInitialValues as createInitialValuesPass } from '../indication-section-create-view/IndicationSectionCreateView.model';
import { useUpdateMyIndicationMutation } from './hooks/useUpdateMyIndicationMutation';
import { createInitialValues } from './IndicationSectionEditView.model';

export type Props = Readonly<{
  onFinishEditing: () => void;
  hasAllocationRanking: boolean;
}>;

const IndicationSectionEditView: React.FC<Props> = ({ hasAllocationRanking, onFinishEditing }) => {
  const { basicPublishedOffering, offeringId } = useIndicationActivityBuySideContext();
  const offering = basicPublishedOffering!;
  const indication = offering.myInstitutionalIndication!;

  const [updateMyIndication] = useUpdateMyIndicationMutation(offeringId, onFinishEditing);

  const [isPassDialogOpen, setIsPassDialogOpen] = React.useState(false);

  const formik = useFormik<IndicationFormValues>({
    initialValues:
      indication.type === InstitutionalIndicationOrderType.Pass
        ? createInitialValuesPass(offering)
        : createInitialValues(indication),
    validationSchema,
    onSubmit: updateMyIndication,
  });
  const { isSubmitting, handleSubmit } = formik;

  const isPassButtonVisible = indication.type !== InstitutionalIndicationOrderType.Pass;

  return (
    <ActionPanelSection
      data-testid="edit-indication-view"
      title="Indication"
      titleItems={<IndicationStatus status={indication.status} />}
      actions={[
        ...(isPassButtonVisible
          ? [
              <Button
                key="pass"
                disabled={hasAllocationRanking || isSubmitting}
                onClick={() => setIsPassDialogOpen(true)}
                variant="outlined"
              >
                Pass
              </Button>,
            ]
          : []),
        <Button key="discard" disabled={isSubmitting} onClick={onFinishEditing} variant="outlined">
          Discard Changes
        </Button>,
        <LoadingButton
          key="save"
          loading={isSubmitting}
          onClick={() => handleSubmit()}
          variant="contained"
        >
          Save
        </LoadingButton>,
      ]}
    >
      <Stack gap={1}>
        <FormikProvider value={formik}>
          <Form>
            <IndicationSectionForm
              tranches={offering.orderEntryProfile.tranches}
              instruments={offering.orderEntryProfile.instruments}
              offeringType={offering.type}
              canEnterTranche={indication.type === InstitutionalIndicationOrderType.Pass}
              pricingCurrencyCode={offering.pricingCurrencyCode}
              securityTypeDisplayName={offering.securityTypeDisplayName!}
            />
          </Form>
        </FormikProvider>

        <PassOfferingConfirmDialog
          isOpen={isPassDialogOpen}
          onClose={() => setIsPassDialogOpen(false)}
          onFinishEditing={onFinishEditing}
          offeringId={offeringId}
          indicationStatus={indication.status}
        />
      </Stack>
    </ActionPanelSection>
  );
};

export default IndicationSectionEditView;
