import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@cmg/design-system';
import { FormikTextField } from '@cmg/design-system-formik';
import { Form, FormikProvider, useFormik } from 'formik';
import React from 'react';
import { v4 as uuidV4 } from 'uuid';
import * as yup from 'yup';

import { useTemplatesState } from '../demand-grid/hooks/useTemplatesState';
import { CMG_TEMPLATES } from '../demand-grid/hooks/useTemplatesState.model';

export type Props = Readonly<{
  offeringId: string;
  isOpen: boolean;
  onClose: () => void;
  onSave: ({ id, name }: { id: string; name: string }) => void;
  templateId?: string;
  templateName?: string;
}>;

type SaveTemplateValues = {
  name: string;
};

export const getValidationSchema = (allTemplates: string[]) =>
  yup.object().shape({
    name: yup
      .string()
      .required('Template Name is required')
      .notOneOf(allTemplates, 'Template Name already exists'),
  });

const SaveTemplateDialog: React.FC<Props> = ({
  offeringId,
  isOpen,
  onClose,
  templateId,
  templateName,
  onSave,
}) => {
  const cmgTemplates = Object.keys(CMG_TEMPLATES);
  const { userTemplates } = useTemplatesState(offeringId);
  const allTemplates = React.useMemo(
    () => [...cmgTemplates, ...userTemplates.map(template => template.name)],
    [cmgTemplates, userTemplates]
  );

  const formik = useFormik<SaveTemplateValues>({
    validationSchema: getValidationSchema(allTemplates),
    enableReinitialize: true,
    initialValues: {
      name: templateName ?? '',
    },
    onSubmit: (values, { resetForm }) => {
      onSave({
        id: templateId ?? uuidV4(),
        name: values.name,
      });
      resetForm();
      onClose();
    },
  });

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Save Template</DialogTitle>
      <DialogContent dividers>
        <FormikProvider value={formik}>
          <Form>
            <Box py={2}>
              <FormikTextField autoFocus label="Template Name" name="name" required />
            </Box>
          </Form>
        </FormikProvider>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={() => formik.handleSubmit()}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SaveTemplateDialog;
