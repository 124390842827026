import { urlUtil, UUID } from '@cmg/common';
import {
  ActionPanelSection,
  Alert,
  Box,
  Button,
  Grid,
  LoadingButton,
  SnackbarManager,
  Stack,
} from '@cmg/design-system';
import { FormikSelectField } from '@cmg/design-system-formik';
import { Form, FormikProvider, useFormik } from 'formik';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { InvalidFormFieldsAlert } from '../../../../common/components/alerts/invalid-form-fields-alert/InvalidFormFieldsAlert';
import { IndicationNoteType } from '../../../../graphql';
import { refetchDemandGridReactiveVar } from '../../../order-book/institutional-demand-v2/hooks/useRefetchDemandGridReactiveVar';
import { useNotes_IndicationNoteQuery } from './graphql';
import { useAssignNoteMutation } from './hooks/useAssignNoteMutation';
import { useNoteSearchParams } from './hooks/useNoteSearchParams';
import Note from './Note';
import NoteForm, { FormType, SubmitNotesSchema } from './NoteForm';
import { filterOptions, NotesFilters } from './NotesModel.util';

export type Props = {
  offeringId: UUID;
  indicationId: UUID;
};

const Notes: React.FC<Props> = ({ offeringId, indicationId }) => {
  const { noteType, ...searchParams } = useNoteSearchParams({ noteType: NotesFilters.ALL });
  const formik = useFormik({
    initialValues: { notesType: noteType },
    enableReinitialize: true,
    onSubmit: values => {
      handleNoteTypeChange(values.notesType);
    },
  });

  const history = useHistory();
  const [editMode, setEditMode] = React.useState<boolean>(false);

  const { data: notesData, loading: notesLoading } = useNotes_IndicationNoteQuery({
    variables: { offeringId, indicationId },
    fetchPolicy: 'no-cache',
  });

  const [assignNote, { loading: assignNoteLoading }] = useAssignNoteMutation({});

  const notes = notesData?.getInstitutionalIndicationNotes ?? [];
  const syndicateNote = notes.find(n => n.type === IndicationNoteType.SyndicateNote);
  const salesAndTradingNote = notes.find(n => n.type === IndicationNoteType.SalesAndTradingNote);

  const noteFormik = useFormik<FormType>({
    enableReinitialize: true,
    initialValues: {
      text:
        noteType === NotesFilters.SYNDICATE
          ? syndicateNote?.text ?? ''
          : salesAndTradingNote?.text ?? '',
    },
    validationSchema: SubmitNotesSchema,
    onSubmit: async values => {
      await onSubmit(values);
    },
    validateOnChange: true,
  });

  const handleNoteTypeChange = (nextNoteType: NotesFilters) => {
    history.push({
      pathname: history.location.pathname,
      search: urlUtil.queryStringify({ ...searchParams, noteType: nextNoteType }),
    });
  };

  const handleNoteChange = (filterOption: NotesFilters) => {
    setEditMode(true);
    handleNoteTypeChange(filterOption);
  };

  const handleCancelEditMode = () => {
    setEditMode(false);
    handleNoteTypeChange(NotesFilters.ALL);
    noteFormik.resetForm();
  };

  const onSubmit = async (values: FormType) => {
    if (!values?.text) {
      return;
    }
    try {
      await assignNote({
        variables: {
          offeringId,
          indicationId,
          input: {
            text: values.text,
            type:
              noteType === NotesFilters.SYNDICATE
                ? IndicationNoteType.SyndicateNote
                : IndicationNoteType.SalesAndTradingNote,
          },
        },
      });
      setEditMode(false);
      handleNoteTypeChange(NotesFilters.ALL);
      refetchDemandGridReactiveVar(true);
      SnackbarManager.success('Note Saved');
    } catch (error) {
      SnackbarManager.error('An error has occurred at saving the note');
    }
  };

  return (
    <Stack data-testid="notes-section" pr={2}>
      <Alert severity="info">The following information is visible to your firm only</Alert>

      <ActionPanelSection
        data-testid="discounts-and-fees-action-panel-section"
        title={editMode ? 'Create New Note' : 'Notes'}
        pt={1}
        actions={[
          ...(editMode
            ? [
                <Button key="cancel" variant="outlined" onClick={handleCancelEditMode}>
                  Cancel
                </Button>,
                <LoadingButton
                  form="note-form"
                  type="submit"
                  variant="contained"
                  loading={notesLoading || assignNoteLoading}
                  key="save"
                >
                  Save
                </LoadingButton>,
              ]
            : []),
        ]}
      />
      <Box pb={2}>
        <InvalidFormFieldsAlert
          errors={noteFormik.errors}
          isSubmitting={noteFormik.isSubmitting}
          touched={noteFormik.touched}
          validationSchema={SubmitNotesSchema}
        />
      </Box>
      <Grid container spacing={2} pl={1}>
        <FormikProvider value={formik}>
          <Grid item xs={4}>
            <FormikSelectField
              aria-label="Select note type"
              name="notesType"
              label="Type"
              disabled={editMode}
              options={filterOptions}
              showHelperTextInTooltip
              fullWidth
              onChange={selected => {
                handleNoteTypeChange(selected!);
              }}
            />
          </Grid>
        </FormikProvider>
      </Grid>

      {!editMode && (
        <Stack data-testid="notes-list">
          {(noteType === NotesFilters.ALL || noteType === NotesFilters.SYNDICATE) && (
            <Note
              title="Syndicate"
              text={syndicateNote?.text ?? 'No Syndicate notes have been entered yet.'}
              filterType={NotesFilters.SYNDICATE}
              onChange={handleNoteChange}
              testId="syndicate-notes-list"
            />
          )}
          {(noteType === NotesFilters.ALL || noteType === NotesFilters.SALES_TRADER) && (
            <Note
              title="Sales & Trading"
              text={salesAndTradingNote?.text ?? 'No Sales & Trading notes have been entered yet.'}
              filterType={NotesFilters.SALES_TRADER}
              onChange={handleNoteChange}
              testId="sales-and-trading-notes-list"
            />
          )}
        </Stack>
      )}
      {editMode && (
        <FormikProvider value={noteFormik}>
          <Form id="note-form" noValidate data-testid="note-form">
            <NoteForm />
          </Form>
        </FormikProvider>
      )}
    </Stack>
  );
};

export default Notes;
