import { EditOutlinedIcon, IconButton, ListItem, ListItemText } from '@cmg/design-system';
import React from 'react';

import { useSupersededOfferingContext } from '../../contexts/SupersededOfferingContext';
import { NotesFilters } from './NotesModel.util';

export type Props = {
  text: string;
  title: string;
  filterType: NotesFilters;
  onChange: (filterType: NotesFilters) => void;
  testId?: string;
};

const Note: React.FC<Props> = ({ text, title, filterType, onChange, testId }) => {
  const { isObsoleteOffering } = useSupersededOfferingContext();

  return (
    <ListItem
      divider
      data-testid={testId}
      secondaryAction={
        <IconButton
          aria-label="Edit note"
          edge="end"
          onClick={() => onChange(filterType)}
          disabled={isObsoleteOffering}
        >
          <EditOutlinedIcon fontSize="small" color="disabled" />
        </IconButton>
      }
    >
      <ListItemText primary={title} secondary={text} />
    </ListItem>
  );
};

export default Note;
