import { Stack } from '@cmg/design-system';
import { FormikTextField } from '@cmg/design-system-formik';
import { useFormikContext } from 'formik';
import React from 'react';
import * as yup from 'yup';

import { TextareaCharacterCount } from '../../../../common/components/form/textarea-character-count/TextareaCharacterCount';

export type FormType = {
  text: string;
};

export type Props = {
  disabled?: boolean;
};

const NoteForm: React.FC<Props> = ({ disabled }) => {
  const { values, errors, handleChange, setFieldTouched } = useFormikContext<FormType>();

  return (
    <Stack pt={2} pl={1}>
      <FormikTextField
        label="Note"
        aria-label="Note text"
        name="text"
        inputProps={{
          onFocus: () => setFieldTouched('text'),
        }}
        multiline
        required
        showHelperTextInTooltip
        rows={6}
        disabled={disabled}
        onChange={handleChange}
      />
      <TextareaCharacterCount count={values.text.length} max={2000} hasError={!!errors.text} />
    </Stack>
  );
};

export const SubmitNotesSchema = yup.object().shape({
  text: yup.string().label('Note').max(2000),
});

export default NoteForm;
