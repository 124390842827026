/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RegulatoryFilings_ResubmitTnFilingMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  filingId: Types.Scalars['UUID'];
}>;

export type RegulatoryFilings_ResubmitTnFilingMutation = {
  readonly __typename?: 'Mutation';
  readonly resubmitTNFiling: {
    readonly __typename?: 'TNFiling';
    readonly id: string;
    readonly status: Types.FilingStatus;
    readonly submittedDateTime: string;
    readonly firmName: string;
    readonly firmCrdNumber: string;
    readonly firmMpid: string;
    readonly contactName: string;
    readonly contactTitle: string;
    readonly telephoneNumber: string;
    readonly emailAddress: string;
    readonly symbolsOfCoveredSecurities: ReadonlyArray<string>;
    readonly isConcurrentConvertible: boolean;
    readonly transactionType: Types.TransactionType;
    readonly transactionTypeDescription?: string | null;
    readonly securityType: Types.TnSecurityType;
    readonly securityTypeDescription?: string | null;
    readonly pricingBasisType: Types.PricingBasisType;
    readonly pricingBasisDescription?: string | null;
    readonly restrictedPeriod: Types.TnRestrictedPeriodType;
    readonly basisCode?: Types.TnBasisCodeType | null;
    readonly uarDate?: string | null;
    readonly adtvNumber?: number | null;
    readonly adtvSource?: string | null;
    readonly publicFloatValue?: number | null;
    readonly publicFloatValueSource?: string | null;
    readonly otherBasisDescription?: string | null;
    readonly restrictedPeriodStartDateTime?: string | null;
    readonly restrictedPeriodStartTimezone?: string | null;
    readonly restrictedPeriodEndDateTime?: string | null;
    readonly restrictedPeriodEndTimezone?: string | null;
    readonly finraErrorMessages: ReadonlyArray<string>;
    readonly issuerName: string;
    readonly issuerSymbol: string;
    readonly firmDealId: string;
    readonly formUniqueId: string;
    readonly atmTrades: ReadonlyArray<{
      readonly __typename?: 'TNFilingAtmTrade';
      readonly tradeDate: string;
      readonly numberOfShares: number;
      readonly averagePrice: number;
      readonly currency: Types.TradeCurrencyType;
    }>;
    readonly nonAtmTradeInfo?: {
      readonly __typename?: 'TNFilingNonAtmTrade';
      readonly numberOfShares: number;
      readonly offeringPrice: number;
      readonly currency: Types.TradeCurrencyType;
      readonly pricingDate: string;
      readonly secEffectiveDateTime?: string | null;
      readonly secEffectiveTimezone?: string | null;
      readonly tradeDate: string;
      readonly lastSaleBeforeDistributionPrice?: number | null;
      readonly lastSaleBeforeDistributionCurrency?: Types.TradeCurrencyType | null;
      readonly lastSaleBeforeDistributionDate?: string | null;
    } | null;
    readonly finraMemberFirms: ReadonlyArray<{
      readonly __typename?: 'TNFilingMemberFirm';
      readonly firmName: string;
      readonly firmMpid?: string | null;
      readonly firmCrdNumber: string;
      readonly cmgEntityKey?: string | null;
      readonly affiliateMemberId?: string | null;
      readonly ownerCmgEntityKey?: string | null;
    }>;
    readonly nonMemberFirms: ReadonlyArray<{
      readonly __typename?: 'TNFilingNonMemberFirm';
      readonly firmName: string;
      readonly cmgEntityKey?: string | null;
      readonly ownerCmgEntityKey?: string | null;
    }>;
    readonly activities: ReadonlyArray<{
      readonly __typename?: 'TNFilingActivity';
      readonly activityType: Types.ActivityType;
      readonly date: string;
      readonly aggregateNumberOfShares: number;
    }>;
    readonly stabilizationActivities: ReadonlyArray<{
      readonly __typename?: 'StabilizationTNFilingActivity';
      readonly stabilizationActivityType: Types.StabilizationTnActivityType;
      readonly date: string;
      readonly shares: number;
    }>;
  };
};

export type RegulatoryFilings_SingleTnFilingQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  filingId: Types.Scalars['UUID'];
}>;

export type RegulatoryFilings_SingleTnFilingQuery = {
  readonly __typename?: 'Query';
  readonly offering: {
    readonly __typename?: 'Offering';
    readonly id: string;
    readonly regulatoryFilings: {
      readonly __typename?: 'RegulatoryFilings';
      readonly id: string;
      readonly TNFiling: {
        readonly __typename?: 'TNFiling';
        readonly id: string;
        readonly status: Types.FilingStatus;
        readonly submittedDateTime: string;
        readonly firmName: string;
        readonly firmCrdNumber: string;
        readonly firmMpid: string;
        readonly contactName: string;
        readonly contactTitle: string;
        readonly telephoneNumber: string;
        readonly emailAddress: string;
        readonly symbolsOfCoveredSecurities: ReadonlyArray<string>;
        readonly isConcurrentConvertible: boolean;
        readonly transactionType: Types.TransactionType;
        readonly transactionTypeDescription?: string | null;
        readonly securityType: Types.TnSecurityType;
        readonly securityTypeDescription?: string | null;
        readonly pricingBasisType: Types.PricingBasisType;
        readonly pricingBasisDescription?: string | null;
        readonly restrictedPeriod: Types.TnRestrictedPeriodType;
        readonly basisCode?: Types.TnBasisCodeType | null;
        readonly uarDate?: string | null;
        readonly adtvNumber?: number | null;
        readonly adtvSource?: string | null;
        readonly publicFloatValue?: number | null;
        readonly publicFloatValueSource?: string | null;
        readonly otherBasisDescription?: string | null;
        readonly restrictedPeriodStartDateTime?: string | null;
        readonly restrictedPeriodStartTimezone?: string | null;
        readonly restrictedPeriodEndDateTime?: string | null;
        readonly restrictedPeriodEndTimezone?: string | null;
        readonly finraErrorMessages: ReadonlyArray<string>;
        readonly issuerName: string;
        readonly issuerSymbol: string;
        readonly firmDealId: string;
        readonly formUniqueId: string;
        readonly atmTrades: ReadonlyArray<{
          readonly __typename?: 'TNFilingAtmTrade';
          readonly tradeDate: string;
          readonly numberOfShares: number;
          readonly averagePrice: number;
          readonly currency: Types.TradeCurrencyType;
        }>;
        readonly nonAtmTradeInfo?: {
          readonly __typename?: 'TNFilingNonAtmTrade';
          readonly numberOfShares: number;
          readonly offeringPrice: number;
          readonly currency: Types.TradeCurrencyType;
          readonly pricingDate: string;
          readonly secEffectiveDateTime?: string | null;
          readonly secEffectiveTimezone?: string | null;
          readonly tradeDate: string;
          readonly lastSaleBeforeDistributionPrice?: number | null;
          readonly lastSaleBeforeDistributionCurrency?: Types.TradeCurrencyType | null;
          readonly lastSaleBeforeDistributionDate?: string | null;
        } | null;
        readonly finraMemberFirms: ReadonlyArray<{
          readonly __typename?: 'TNFilingMemberFirm';
          readonly firmName: string;
          readonly firmMpid?: string | null;
          readonly firmCrdNumber: string;
          readonly cmgEntityKey?: string | null;
          readonly affiliateMemberId?: string | null;
          readonly ownerCmgEntityKey?: string | null;
        }>;
        readonly nonMemberFirms: ReadonlyArray<{
          readonly __typename?: 'TNFilingNonMemberFirm';
          readonly firmName: string;
          readonly cmgEntityKey?: string | null;
          readonly ownerCmgEntityKey?: string | null;
        }>;
        readonly activities: ReadonlyArray<{
          readonly __typename?: 'TNFilingActivity';
          readonly activityType: Types.ActivityType;
          readonly date: string;
          readonly aggregateNumberOfShares: number;
        }>;
        readonly stabilizationActivities: ReadonlyArray<{
          readonly __typename?: 'StabilizationTNFilingActivity';
          readonly stabilizationActivityType: Types.StabilizationTnActivityType;
          readonly date: string;
          readonly shares: number;
        }>;
      };
      readonly notifications: ReadonlyArray<{
        readonly __typename?: 'RegulatoryFilingNotificationMessage';
        readonly id: string;
        readonly status: Types.NotificationMessageStatus;
        readonly sentAt?: string | null;
        readonly distributionList: ReadonlyArray<string>;
        readonly bccEmailAddresses: ReadonlyArray<string>;
        readonly managerSnapshot: {
          readonly __typename?: 'FinraManagerSnapshot';
          readonly id: string;
          readonly cmgEntityKey: string;
          readonly role: Types.ManagerRole;
          readonly firmName: string;
        };
      }>;
    };
  };
};

export type RegulatoryFilings_StabilizationTnActivityPartsFragment = {
  readonly __typename?: 'StabilizationTNFilingActivity';
  readonly date: string;
  readonly shares: number;
  readonly stabilizationActivityType: Types.StabilizationTnActivityType;
};

export type RegulatoryFilings_StabilizationTnActivitiesQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type RegulatoryFilings_StabilizationTnActivitiesQuery = {
  readonly __typename?: 'Query';
  readonly offering: {
    readonly __typename?: 'Offering';
    readonly id: string;
    readonly regulatoryFilings: {
      readonly __typename?: 'RegulatoryFilings';
      readonly id: string;
      readonly stabilizationTNActivities:
        | {
            readonly __typename: 'ServiceError';
            readonly code: Types.ServiceErrorCode;
            readonly message: string;
            readonly target?: string | null;
            readonly details?: ReadonlyArray<{
              readonly __typename: 'ErrorDetail';
              readonly code?: string | null;
              readonly message?: string | null;
              readonly target?: string | null;
            }> | null;
          }
        | {
            readonly __typename?: 'StabilizationTNFilingActivityWrapper';
            readonly activities: ReadonlyArray<{
              readonly __typename?: 'StabilizationTNFilingActivity';
              readonly date: string;
              readonly shares: number;
              readonly stabilizationActivityType: Types.StabilizationTnActivityType;
            }>;
          };
    };
  };
};

export type RegulatoryFilings_SubmitTnFilingMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  input: Types.CreateTnFilingInput;
}>;

export type RegulatoryFilings_SubmitTnFilingMutation = {
  readonly __typename?: 'Mutation';
  readonly submitTNFiling: {
    readonly __typename?: 'TNFiling';
    readonly id: string;
    readonly status: Types.FilingStatus;
    readonly submittedDateTime: string;
    readonly firmName: string;
    readonly firmCrdNumber: string;
    readonly firmMpid: string;
    readonly contactName: string;
    readonly contactTitle: string;
    readonly telephoneNumber: string;
    readonly emailAddress: string;
    readonly symbolsOfCoveredSecurities: ReadonlyArray<string>;
    readonly isConcurrentConvertible: boolean;
    readonly transactionType: Types.TransactionType;
    readonly transactionTypeDescription?: string | null;
    readonly securityType: Types.TnSecurityType;
    readonly securityTypeDescription?: string | null;
    readonly pricingBasisType: Types.PricingBasisType;
    readonly pricingBasisDescription?: string | null;
    readonly restrictedPeriod: Types.TnRestrictedPeriodType;
    readonly basisCode?: Types.TnBasisCodeType | null;
    readonly uarDate?: string | null;
    readonly adtvNumber?: number | null;
    readonly adtvSource?: string | null;
    readonly publicFloatValue?: number | null;
    readonly publicFloatValueSource?: string | null;
    readonly otherBasisDescription?: string | null;
    readonly restrictedPeriodStartDateTime?: string | null;
    readonly restrictedPeriodStartTimezone?: string | null;
    readonly restrictedPeriodEndDateTime?: string | null;
    readonly restrictedPeriodEndTimezone?: string | null;
    readonly finraErrorMessages: ReadonlyArray<string>;
    readonly issuerName: string;
    readonly issuerSymbol: string;
    readonly firmDealId: string;
    readonly formUniqueId: string;
    readonly atmTrades: ReadonlyArray<{
      readonly __typename?: 'TNFilingAtmTrade';
      readonly tradeDate: string;
      readonly numberOfShares: number;
      readonly averagePrice: number;
      readonly currency: Types.TradeCurrencyType;
    }>;
    readonly nonAtmTradeInfo?: {
      readonly __typename?: 'TNFilingNonAtmTrade';
      readonly numberOfShares: number;
      readonly offeringPrice: number;
      readonly currency: Types.TradeCurrencyType;
      readonly pricingDate: string;
      readonly secEffectiveDateTime?: string | null;
      readonly secEffectiveTimezone?: string | null;
      readonly tradeDate: string;
      readonly lastSaleBeforeDistributionPrice?: number | null;
      readonly lastSaleBeforeDistributionCurrency?: Types.TradeCurrencyType | null;
      readonly lastSaleBeforeDistributionDate?: string | null;
    } | null;
    readonly finraMemberFirms: ReadonlyArray<{
      readonly __typename?: 'TNFilingMemberFirm';
      readonly firmName: string;
      readonly firmMpid?: string | null;
      readonly firmCrdNumber: string;
      readonly cmgEntityKey?: string | null;
      readonly affiliateMemberId?: string | null;
      readonly ownerCmgEntityKey?: string | null;
    }>;
    readonly nonMemberFirms: ReadonlyArray<{
      readonly __typename?: 'TNFilingNonMemberFirm';
      readonly firmName: string;
      readonly cmgEntityKey?: string | null;
      readonly ownerCmgEntityKey?: string | null;
    }>;
    readonly activities: ReadonlyArray<{
      readonly __typename?: 'TNFilingActivity';
      readonly activityType: Types.ActivityType;
      readonly date: string;
      readonly aggregateNumberOfShares: number;
    }>;
    readonly stabilizationActivities: ReadonlyArray<{
      readonly __typename?: 'StabilizationTNFilingActivity';
      readonly stabilizationActivityType: Types.StabilizationTnActivityType;
      readonly date: string;
      readonly shares: number;
    }>;
  };
};

export type RegulatoryFilings_TnFilingMemberFirmPartsFragment = {
  readonly __typename?: 'TNFilingMemberFirm';
  readonly firmName: string;
  readonly firmMpid?: string | null;
  readonly firmCrdNumber: string;
  readonly cmgEntityKey?: string | null;
  readonly affiliateMemberId?: string | null;
  readonly ownerCmgEntityKey?: string | null;
};

export type RegulatoryFilings_TnFilingActivityPartsFragment = {
  readonly __typename?: 'TNFilingActivity';
  readonly activityType: Types.ActivityType;
  readonly date: string;
  readonly aggregateNumberOfShares: number;
};

export type RegulatoryFilings_TnFilingStabilizationActivityPartsFragment = {
  readonly __typename?: 'StabilizationTNFilingActivity';
  readonly stabilizationActivityType: Types.StabilizationTnActivityType;
  readonly date: string;
  readonly shares: number;
};

export type RegulatoryFilings_TnFilingPartsFragment = {
  readonly __typename?: 'TNFiling';
  readonly id: string;
  readonly status: Types.FilingStatus;
  readonly submittedDateTime: string;
  readonly firmName: string;
  readonly firmCrdNumber: string;
  readonly firmMpid: string;
  readonly contactName: string;
  readonly contactTitle: string;
  readonly telephoneNumber: string;
  readonly emailAddress: string;
  readonly symbolsOfCoveredSecurities: ReadonlyArray<string>;
  readonly isConcurrentConvertible: boolean;
  readonly transactionType: Types.TransactionType;
  readonly transactionTypeDescription?: string | null;
  readonly securityType: Types.TnSecurityType;
  readonly securityTypeDescription?: string | null;
  readonly pricingBasisType: Types.PricingBasisType;
  readonly pricingBasisDescription?: string | null;
  readonly restrictedPeriod: Types.TnRestrictedPeriodType;
  readonly basisCode?: Types.TnBasisCodeType | null;
  readonly uarDate?: string | null;
  readonly adtvNumber?: number | null;
  readonly adtvSource?: string | null;
  readonly publicFloatValue?: number | null;
  readonly publicFloatValueSource?: string | null;
  readonly otherBasisDescription?: string | null;
  readonly restrictedPeriodStartDateTime?: string | null;
  readonly restrictedPeriodStartTimezone?: string | null;
  readonly restrictedPeriodEndDateTime?: string | null;
  readonly restrictedPeriodEndTimezone?: string | null;
  readonly finraErrorMessages: ReadonlyArray<string>;
  readonly issuerName: string;
  readonly issuerSymbol: string;
  readonly firmDealId: string;
  readonly formUniqueId: string;
  readonly atmTrades: ReadonlyArray<{
    readonly __typename?: 'TNFilingAtmTrade';
    readonly tradeDate: string;
    readonly numberOfShares: number;
    readonly averagePrice: number;
    readonly currency: Types.TradeCurrencyType;
  }>;
  readonly nonAtmTradeInfo?: {
    readonly __typename?: 'TNFilingNonAtmTrade';
    readonly numberOfShares: number;
    readonly offeringPrice: number;
    readonly currency: Types.TradeCurrencyType;
    readonly pricingDate: string;
    readonly secEffectiveDateTime?: string | null;
    readonly secEffectiveTimezone?: string | null;
    readonly tradeDate: string;
    readonly lastSaleBeforeDistributionPrice?: number | null;
    readonly lastSaleBeforeDistributionCurrency?: Types.TradeCurrencyType | null;
    readonly lastSaleBeforeDistributionDate?: string | null;
  } | null;
  readonly finraMemberFirms: ReadonlyArray<{
    readonly __typename?: 'TNFilingMemberFirm';
    readonly firmName: string;
    readonly firmMpid?: string | null;
    readonly firmCrdNumber: string;
    readonly cmgEntityKey?: string | null;
    readonly affiliateMemberId?: string | null;
    readonly ownerCmgEntityKey?: string | null;
  }>;
  readonly nonMemberFirms: ReadonlyArray<{
    readonly __typename?: 'TNFilingNonMemberFirm';
    readonly firmName: string;
    readonly cmgEntityKey?: string | null;
    readonly ownerCmgEntityKey?: string | null;
  }>;
  readonly activities: ReadonlyArray<{
    readonly __typename?: 'TNFilingActivity';
    readonly activityType: Types.ActivityType;
    readonly date: string;
    readonly aggregateNumberOfShares: number;
  }>;
  readonly stabilizationActivities: ReadonlyArray<{
    readonly __typename?: 'StabilizationTNFilingActivity';
    readonly stabilizationActivityType: Types.StabilizationTnActivityType;
    readonly date: string;
    readonly shares: number;
  }>;
};

export type RegulatoryFilings_TnFilingsQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type RegulatoryFilings_TnFilingsQuery = {
  readonly __typename?: 'Query';
  readonly offering: {
    readonly __typename?: 'Offering';
    readonly id: string;
    readonly regulatoryFilings: {
      readonly __typename?: 'RegulatoryFilings';
      readonly id: string;
      readonly TNFilings: ReadonlyArray<{
        readonly __typename?: 'BaseRegulatoryFiling';
        readonly id: string;
        readonly status: Types.FilingStatus;
        readonly submittedDateTime: string;
      }>;
    };
  };
};

export type RegulatoryFilings_TnRestrictedPeriodQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type RegulatoryFilings_TnRestrictedPeriodQuery = {
  readonly __typename?: 'Query';
  readonly lastTnRestrictedPeriod?: {
    readonly __typename?: 'LastTnRestrictedPeriod';
    readonly id: string;
    readonly restrictedPeriod: Types.TnRestrictedPeriodType;
  } | null;
};

export const RegulatoryFilings_StabilizationTnActivityPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RegulatoryFilings_StabilizationTnActivityParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StabilizationTNFilingActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stabilizationActivityType' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const RegulatoryFilings_TnFilingMemberFirmPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RegulatoryFilings_TnFilingMemberFirmParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TNFilingMemberFirm' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmMpid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmCrdNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'affiliateMemberId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ownerCmgEntityKey' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const RegulatoryFilings_TnFilingActivityPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RegulatoryFilings_TnFilingActivityParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TNFilingActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'activityType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'aggregateNumberOfShares' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const RegulatoryFilings_TnFilingStabilizationActivityPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RegulatoryFilings_TnFilingStabilizationActivityParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StabilizationTNFilingActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'stabilizationActivityType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const RegulatoryFilings_TnFilingPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RegulatoryFilings_TnFilingParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TNFiling' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'submittedDateTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmCrdNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmMpid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'telephoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'symbolsOfCoveredSecurities' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isConcurrentConvertible' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transactionType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'atmTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'tradeDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'numberOfShares' } },
                { kind: 'Field', name: { kind: 'Name', value: 'averagePrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nonAtmTradeInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'numberOfShares' } },
                { kind: 'Field', name: { kind: 'Name', value: 'offeringPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pricingDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'secEffectiveDateTime' } },
                { kind: 'Field', name: { kind: 'Name', value: 'secEffectiveTimezone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tradeDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastSaleBeforeDistributionPrice' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lastSaleBeforeDistributionCurrency' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'lastSaleBeforeDistributionDate' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'transactionTypeDescription' } },
          { kind: 'Field', name: { kind: 'Name', value: 'securityType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'securityTypeDescription' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingBasisType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingBasisDescription' } },
          { kind: 'Field', name: { kind: 'Name', value: 'restrictedPeriod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'basisCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uarDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'adtvNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'adtvSource' } },
          { kind: 'Field', name: { kind: 'Name', value: 'publicFloatValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'publicFloatValueSource' } },
          { kind: 'Field', name: { kind: 'Name', value: 'otherBasisDescription' } },
          { kind: 'Field', name: { kind: 'Name', value: 'restrictedPeriodStartDateTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'restrictedPeriodStartTimezone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'restrictedPeriodEndDateTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'restrictedPeriodEndTimezone' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'finraMemberFirms' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RegulatoryFilings_TnFilingMemberFirmParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nonMemberFirms' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ownerCmgEntityKey' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RegulatoryFilings_TnFilingActivityParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stabilizationActivities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'RegulatoryFilings_TnFilingStabilizationActivityParts',
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'finraErrorMessages' } },
          { kind: 'Field', name: { kind: 'Name', value: 'issuerName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'issuerSymbol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmDealId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formUniqueId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RegulatoryFilings_TnFilingMemberFirmParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TNFilingMemberFirm' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmMpid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmCrdNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'affiliateMemberId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ownerCmgEntityKey' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RegulatoryFilings_TnFilingActivityParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TNFilingActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'activityType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'aggregateNumberOfShares' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RegulatoryFilings_TnFilingStabilizationActivityParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StabilizationTNFilingActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'stabilizationActivityType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const RegulatoryFilings_ResubmitTnFilingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RegulatoryFilings_ResubmitTnFiling' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filingId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resubmitTNFiling' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filingId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filingId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RegulatoryFilings_TnFilingParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RegulatoryFilings_TnFilingMemberFirmParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TNFilingMemberFirm' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmMpid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmCrdNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'affiliateMemberId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ownerCmgEntityKey' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RegulatoryFilings_TnFilingActivityParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TNFilingActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'activityType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'aggregateNumberOfShares' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RegulatoryFilings_TnFilingStabilizationActivityParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StabilizationTNFilingActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'stabilizationActivityType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RegulatoryFilings_TnFilingParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TNFiling' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'submittedDateTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmCrdNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmMpid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'telephoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'symbolsOfCoveredSecurities' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isConcurrentConvertible' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transactionType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'atmTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'tradeDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'numberOfShares' } },
                { kind: 'Field', name: { kind: 'Name', value: 'averagePrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nonAtmTradeInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'numberOfShares' } },
                { kind: 'Field', name: { kind: 'Name', value: 'offeringPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pricingDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'secEffectiveDateTime' } },
                { kind: 'Field', name: { kind: 'Name', value: 'secEffectiveTimezone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tradeDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastSaleBeforeDistributionPrice' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lastSaleBeforeDistributionCurrency' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'lastSaleBeforeDistributionDate' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'transactionTypeDescription' } },
          { kind: 'Field', name: { kind: 'Name', value: 'securityType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'securityTypeDescription' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingBasisType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingBasisDescription' } },
          { kind: 'Field', name: { kind: 'Name', value: 'restrictedPeriod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'basisCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uarDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'adtvNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'adtvSource' } },
          { kind: 'Field', name: { kind: 'Name', value: 'publicFloatValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'publicFloatValueSource' } },
          { kind: 'Field', name: { kind: 'Name', value: 'otherBasisDescription' } },
          { kind: 'Field', name: { kind: 'Name', value: 'restrictedPeriodStartDateTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'restrictedPeriodStartTimezone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'restrictedPeriodEndDateTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'restrictedPeriodEndTimezone' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'finraMemberFirms' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RegulatoryFilings_TnFilingMemberFirmParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nonMemberFirms' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ownerCmgEntityKey' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RegulatoryFilings_TnFilingActivityParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stabilizationActivities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'RegulatoryFilings_TnFilingStabilizationActivityParts',
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'finraErrorMessages' } },
          { kind: 'Field', name: { kind: 'Name', value: 'issuerName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'issuerSymbol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmDealId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formUniqueId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type RegulatoryFilings_ResubmitTnFilingMutationFn = Apollo.MutationFunction<
  RegulatoryFilings_ResubmitTnFilingMutation,
  RegulatoryFilings_ResubmitTnFilingMutationVariables
>;

/**
 * __useRegulatoryFilings_ResubmitTnFilingMutation__
 *
 * To run a mutation, you first call `useRegulatoryFilings_ResubmitTnFilingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegulatoryFilings_ResubmitTnFilingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [regulatoryFilingsResubmitTnFilingMutation, { data, loading, error }] = useRegulatoryFilings_ResubmitTnFilingMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      filingId: // value for 'filingId'
 *   },
 * });
 */
export function useRegulatoryFilings_ResubmitTnFilingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegulatoryFilings_ResubmitTnFilingMutation,
    RegulatoryFilings_ResubmitTnFilingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RegulatoryFilings_ResubmitTnFilingMutation,
    RegulatoryFilings_ResubmitTnFilingMutationVariables
  >(RegulatoryFilings_ResubmitTnFilingDocument, options);
}
export type RegulatoryFilings_ResubmitTnFilingMutationHookResult = ReturnType<
  typeof useRegulatoryFilings_ResubmitTnFilingMutation
>;
export type RegulatoryFilings_ResubmitTnFilingMutationResult =
  Apollo.MutationResult<RegulatoryFilings_ResubmitTnFilingMutation>;
export type RegulatoryFilings_ResubmitTnFilingMutationOptions = Apollo.BaseMutationOptions<
  RegulatoryFilings_ResubmitTnFilingMutation,
  RegulatoryFilings_ResubmitTnFilingMutationVariables
>;
export const RegulatoryFilings_SingleTnFilingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RegulatoryFilings_SingleTnFiling' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filingId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'offering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'regulatoryFilings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'TNFiling' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'filingId' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'filingId' } },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'RegulatoryFilings_TnFilingParts' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'notifications' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'filingId' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'filingId' } },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'RegulatoryFilings_FilingNotificationMessageParts',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RegulatoryFilings_TnFilingMemberFirmParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TNFilingMemberFirm' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmMpid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmCrdNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'affiliateMemberId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ownerCmgEntityKey' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RegulatoryFilings_TnFilingActivityParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TNFilingActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'activityType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'aggregateNumberOfShares' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RegulatoryFilings_TnFilingStabilizationActivityParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StabilizationTNFilingActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'stabilizationActivityType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RegulatoryFilings_TnFilingParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TNFiling' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'submittedDateTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmCrdNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmMpid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'telephoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'symbolsOfCoveredSecurities' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isConcurrentConvertible' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transactionType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'atmTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'tradeDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'numberOfShares' } },
                { kind: 'Field', name: { kind: 'Name', value: 'averagePrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nonAtmTradeInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'numberOfShares' } },
                { kind: 'Field', name: { kind: 'Name', value: 'offeringPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pricingDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'secEffectiveDateTime' } },
                { kind: 'Field', name: { kind: 'Name', value: 'secEffectiveTimezone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tradeDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastSaleBeforeDistributionPrice' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lastSaleBeforeDistributionCurrency' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'lastSaleBeforeDistributionDate' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'transactionTypeDescription' } },
          { kind: 'Field', name: { kind: 'Name', value: 'securityType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'securityTypeDescription' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingBasisType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingBasisDescription' } },
          { kind: 'Field', name: { kind: 'Name', value: 'restrictedPeriod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'basisCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uarDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'adtvNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'adtvSource' } },
          { kind: 'Field', name: { kind: 'Name', value: 'publicFloatValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'publicFloatValueSource' } },
          { kind: 'Field', name: { kind: 'Name', value: 'otherBasisDescription' } },
          { kind: 'Field', name: { kind: 'Name', value: 'restrictedPeriodStartDateTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'restrictedPeriodStartTimezone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'restrictedPeriodEndDateTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'restrictedPeriodEndTimezone' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'finraMemberFirms' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RegulatoryFilings_TnFilingMemberFirmParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nonMemberFirms' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ownerCmgEntityKey' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RegulatoryFilings_TnFilingActivityParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stabilizationActivities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'RegulatoryFilings_TnFilingStabilizationActivityParts',
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'finraErrorMessages' } },
          { kind: 'Field', name: { kind: 'Name', value: 'issuerName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'issuerSymbol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmDealId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formUniqueId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RegulatoryFilings_FilingNotificationMessageParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RegulatoryFilingNotificationMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sentAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'distributionList' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bccEmailAddresses' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'managerSnapshot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RegulatoryFilings_FinraManagerSnapshotParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RegulatoryFilings_FinraManagerSnapshotParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FinraManagerSnapshot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useRegulatoryFilings_SingleTnFilingQuery__
 *
 * To run a query within a React component, call `useRegulatoryFilings_SingleTnFilingQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegulatoryFilings_SingleTnFilingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegulatoryFilings_SingleTnFilingQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      filingId: // value for 'filingId'
 *   },
 * });
 */
export function useRegulatoryFilings_SingleTnFilingQuery(
  baseOptions: Apollo.QueryHookOptions<
    RegulatoryFilings_SingleTnFilingQuery,
    RegulatoryFilings_SingleTnFilingQueryVariables
  > &
    (
      | { variables: RegulatoryFilings_SingleTnFilingQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RegulatoryFilings_SingleTnFilingQuery,
    RegulatoryFilings_SingleTnFilingQueryVariables
  >(RegulatoryFilings_SingleTnFilingDocument, options);
}
export function useRegulatoryFilings_SingleTnFilingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RegulatoryFilings_SingleTnFilingQuery,
    RegulatoryFilings_SingleTnFilingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RegulatoryFilings_SingleTnFilingQuery,
    RegulatoryFilings_SingleTnFilingQueryVariables
  >(RegulatoryFilings_SingleTnFilingDocument, options);
}
export function useRegulatoryFilings_SingleTnFilingSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        RegulatoryFilings_SingleTnFilingQuery,
        RegulatoryFilings_SingleTnFilingQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    RegulatoryFilings_SingleTnFilingQuery,
    RegulatoryFilings_SingleTnFilingQueryVariables
  >(RegulatoryFilings_SingleTnFilingDocument, options);
}
export type RegulatoryFilings_SingleTnFilingQueryHookResult = ReturnType<
  typeof useRegulatoryFilings_SingleTnFilingQuery
>;
export type RegulatoryFilings_SingleTnFilingLazyQueryHookResult = ReturnType<
  typeof useRegulatoryFilings_SingleTnFilingLazyQuery
>;
export type RegulatoryFilings_SingleTnFilingSuspenseQueryHookResult = ReturnType<
  typeof useRegulatoryFilings_SingleTnFilingSuspenseQuery
>;
export type RegulatoryFilings_SingleTnFilingQueryResult = Apollo.QueryResult<
  RegulatoryFilings_SingleTnFilingQuery,
  RegulatoryFilings_SingleTnFilingQueryVariables
>;
export const RegulatoryFilings_StabilizationTnActivitiesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RegulatoryFilings_StabilizationTnActivities' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'offering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'regulatoryFilings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'stabilizationTNActivities' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'ServiceErrorParts' },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: {
                                  kind: 'Name',
                                  value: 'StabilizationTNFilingActivityWrapper',
                                },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'activities' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: {
                                            kind: 'Name',
                                            value: 'RegulatoryFilings_StabilizationTnActivityParts',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RegulatoryFilings_StabilizationTnActivityParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StabilizationTNFilingActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stabilizationActivityType' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ServiceErrorParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ServiceError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'target' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'target' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useRegulatoryFilings_StabilizationTnActivitiesQuery__
 *
 * To run a query within a React component, call `useRegulatoryFilings_StabilizationTnActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegulatoryFilings_StabilizationTnActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegulatoryFilings_StabilizationTnActivitiesQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useRegulatoryFilings_StabilizationTnActivitiesQuery(
  baseOptions: Apollo.QueryHookOptions<
    RegulatoryFilings_StabilizationTnActivitiesQuery,
    RegulatoryFilings_StabilizationTnActivitiesQueryVariables
  > &
    (
      | { variables: RegulatoryFilings_StabilizationTnActivitiesQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RegulatoryFilings_StabilizationTnActivitiesQuery,
    RegulatoryFilings_StabilizationTnActivitiesQueryVariables
  >(RegulatoryFilings_StabilizationTnActivitiesDocument, options);
}
export function useRegulatoryFilings_StabilizationTnActivitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RegulatoryFilings_StabilizationTnActivitiesQuery,
    RegulatoryFilings_StabilizationTnActivitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RegulatoryFilings_StabilizationTnActivitiesQuery,
    RegulatoryFilings_StabilizationTnActivitiesQueryVariables
  >(RegulatoryFilings_StabilizationTnActivitiesDocument, options);
}
export function useRegulatoryFilings_StabilizationTnActivitiesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        RegulatoryFilings_StabilizationTnActivitiesQuery,
        RegulatoryFilings_StabilizationTnActivitiesQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    RegulatoryFilings_StabilizationTnActivitiesQuery,
    RegulatoryFilings_StabilizationTnActivitiesQueryVariables
  >(RegulatoryFilings_StabilizationTnActivitiesDocument, options);
}
export type RegulatoryFilings_StabilizationTnActivitiesQueryHookResult = ReturnType<
  typeof useRegulatoryFilings_StabilizationTnActivitiesQuery
>;
export type RegulatoryFilings_StabilizationTnActivitiesLazyQueryHookResult = ReturnType<
  typeof useRegulatoryFilings_StabilizationTnActivitiesLazyQuery
>;
export type RegulatoryFilings_StabilizationTnActivitiesSuspenseQueryHookResult = ReturnType<
  typeof useRegulatoryFilings_StabilizationTnActivitiesSuspenseQuery
>;
export type RegulatoryFilings_StabilizationTnActivitiesQueryResult = Apollo.QueryResult<
  RegulatoryFilings_StabilizationTnActivitiesQuery,
  RegulatoryFilings_StabilizationTnActivitiesQueryVariables
>;
export const RegulatoryFilings_SubmitTnFilingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RegulatoryFilings_SubmitTnFiling' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateTNFilingInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submitTNFiling' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RegulatoryFilings_TnFilingParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RegulatoryFilings_TnFilingMemberFirmParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TNFilingMemberFirm' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmMpid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmCrdNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'affiliateMemberId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ownerCmgEntityKey' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RegulatoryFilings_TnFilingActivityParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TNFilingActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'activityType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'aggregateNumberOfShares' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RegulatoryFilings_TnFilingStabilizationActivityParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StabilizationTNFilingActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'stabilizationActivityType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RegulatoryFilings_TnFilingParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TNFiling' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'submittedDateTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmCrdNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmMpid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'telephoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'symbolsOfCoveredSecurities' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isConcurrentConvertible' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transactionType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'atmTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'tradeDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'numberOfShares' } },
                { kind: 'Field', name: { kind: 'Name', value: 'averagePrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nonAtmTradeInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'numberOfShares' } },
                { kind: 'Field', name: { kind: 'Name', value: 'offeringPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pricingDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'secEffectiveDateTime' } },
                { kind: 'Field', name: { kind: 'Name', value: 'secEffectiveTimezone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tradeDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastSaleBeforeDistributionPrice' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lastSaleBeforeDistributionCurrency' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'lastSaleBeforeDistributionDate' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'transactionTypeDescription' } },
          { kind: 'Field', name: { kind: 'Name', value: 'securityType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'securityTypeDescription' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingBasisType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingBasisDescription' } },
          { kind: 'Field', name: { kind: 'Name', value: 'restrictedPeriod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'basisCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uarDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'adtvNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'adtvSource' } },
          { kind: 'Field', name: { kind: 'Name', value: 'publicFloatValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'publicFloatValueSource' } },
          { kind: 'Field', name: { kind: 'Name', value: 'otherBasisDescription' } },
          { kind: 'Field', name: { kind: 'Name', value: 'restrictedPeriodStartDateTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'restrictedPeriodStartTimezone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'restrictedPeriodEndDateTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'restrictedPeriodEndTimezone' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'finraMemberFirms' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RegulatoryFilings_TnFilingMemberFirmParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nonMemberFirms' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ownerCmgEntityKey' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RegulatoryFilings_TnFilingActivityParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stabilizationActivities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'RegulatoryFilings_TnFilingStabilizationActivityParts',
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'finraErrorMessages' } },
          { kind: 'Field', name: { kind: 'Name', value: 'issuerName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'issuerSymbol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmDealId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formUniqueId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type RegulatoryFilings_SubmitTnFilingMutationFn = Apollo.MutationFunction<
  RegulatoryFilings_SubmitTnFilingMutation,
  RegulatoryFilings_SubmitTnFilingMutationVariables
>;

/**
 * __useRegulatoryFilings_SubmitTnFilingMutation__
 *
 * To run a mutation, you first call `useRegulatoryFilings_SubmitTnFilingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegulatoryFilings_SubmitTnFilingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [regulatoryFilingsSubmitTnFilingMutation, { data, loading, error }] = useRegulatoryFilings_SubmitTnFilingMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegulatoryFilings_SubmitTnFilingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegulatoryFilings_SubmitTnFilingMutation,
    RegulatoryFilings_SubmitTnFilingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RegulatoryFilings_SubmitTnFilingMutation,
    RegulatoryFilings_SubmitTnFilingMutationVariables
  >(RegulatoryFilings_SubmitTnFilingDocument, options);
}
export type RegulatoryFilings_SubmitTnFilingMutationHookResult = ReturnType<
  typeof useRegulatoryFilings_SubmitTnFilingMutation
>;
export type RegulatoryFilings_SubmitTnFilingMutationResult =
  Apollo.MutationResult<RegulatoryFilings_SubmitTnFilingMutation>;
export type RegulatoryFilings_SubmitTnFilingMutationOptions = Apollo.BaseMutationOptions<
  RegulatoryFilings_SubmitTnFilingMutation,
  RegulatoryFilings_SubmitTnFilingMutationVariables
>;
export const RegulatoryFilings_TnFilingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RegulatoryFilings_TnFilings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'offering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'regulatoryFilings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'TNFilings' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'submittedDateTime' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useRegulatoryFilings_TnFilingsQuery__
 *
 * To run a query within a React component, call `useRegulatoryFilings_TnFilingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegulatoryFilings_TnFilingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegulatoryFilings_TnFilingsQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useRegulatoryFilings_TnFilingsQuery(
  baseOptions: Apollo.QueryHookOptions<
    RegulatoryFilings_TnFilingsQuery,
    RegulatoryFilings_TnFilingsQueryVariables
  > &
    ({ variables: RegulatoryFilings_TnFilingsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RegulatoryFilings_TnFilingsQuery,
    RegulatoryFilings_TnFilingsQueryVariables
  >(RegulatoryFilings_TnFilingsDocument, options);
}
export function useRegulatoryFilings_TnFilingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RegulatoryFilings_TnFilingsQuery,
    RegulatoryFilings_TnFilingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RegulatoryFilings_TnFilingsQuery,
    RegulatoryFilings_TnFilingsQueryVariables
  >(RegulatoryFilings_TnFilingsDocument, options);
}
export function useRegulatoryFilings_TnFilingsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        RegulatoryFilings_TnFilingsQuery,
        RegulatoryFilings_TnFilingsQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    RegulatoryFilings_TnFilingsQuery,
    RegulatoryFilings_TnFilingsQueryVariables
  >(RegulatoryFilings_TnFilingsDocument, options);
}
export type RegulatoryFilings_TnFilingsQueryHookResult = ReturnType<
  typeof useRegulatoryFilings_TnFilingsQuery
>;
export type RegulatoryFilings_TnFilingsLazyQueryHookResult = ReturnType<
  typeof useRegulatoryFilings_TnFilingsLazyQuery
>;
export type RegulatoryFilings_TnFilingsSuspenseQueryHookResult = ReturnType<
  typeof useRegulatoryFilings_TnFilingsSuspenseQuery
>;
export type RegulatoryFilings_TnFilingsQueryResult = Apollo.QueryResult<
  RegulatoryFilings_TnFilingsQuery,
  RegulatoryFilings_TnFilingsQueryVariables
>;
export const RegulatoryFilings_TnRestrictedPeriodDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RegulatoryFilings_TnRestrictedPeriod' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastTnRestrictedPeriod' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'restrictedPeriod' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useRegulatoryFilings_TnRestrictedPeriodQuery__
 *
 * To run a query within a React component, call `useRegulatoryFilings_TnRestrictedPeriodQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegulatoryFilings_TnRestrictedPeriodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegulatoryFilings_TnRestrictedPeriodQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useRegulatoryFilings_TnRestrictedPeriodQuery(
  baseOptions: Apollo.QueryHookOptions<
    RegulatoryFilings_TnRestrictedPeriodQuery,
    RegulatoryFilings_TnRestrictedPeriodQueryVariables
  > &
    (
      | { variables: RegulatoryFilings_TnRestrictedPeriodQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RegulatoryFilings_TnRestrictedPeriodQuery,
    RegulatoryFilings_TnRestrictedPeriodQueryVariables
  >(RegulatoryFilings_TnRestrictedPeriodDocument, options);
}
export function useRegulatoryFilings_TnRestrictedPeriodLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RegulatoryFilings_TnRestrictedPeriodQuery,
    RegulatoryFilings_TnRestrictedPeriodQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RegulatoryFilings_TnRestrictedPeriodQuery,
    RegulatoryFilings_TnRestrictedPeriodQueryVariables
  >(RegulatoryFilings_TnRestrictedPeriodDocument, options);
}
export function useRegulatoryFilings_TnRestrictedPeriodSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        RegulatoryFilings_TnRestrictedPeriodQuery,
        RegulatoryFilings_TnRestrictedPeriodQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    RegulatoryFilings_TnRestrictedPeriodQuery,
    RegulatoryFilings_TnRestrictedPeriodQueryVariables
  >(RegulatoryFilings_TnRestrictedPeriodDocument, options);
}
export type RegulatoryFilings_TnRestrictedPeriodQueryHookResult = ReturnType<
  typeof useRegulatoryFilings_TnRestrictedPeriodQuery
>;
export type RegulatoryFilings_TnRestrictedPeriodLazyQueryHookResult = ReturnType<
  typeof useRegulatoryFilings_TnRestrictedPeriodLazyQuery
>;
export type RegulatoryFilings_TnRestrictedPeriodSuspenseQueryHookResult = ReturnType<
  typeof useRegulatoryFilings_TnRestrictedPeriodSuspenseQuery
>;
export type RegulatoryFilings_TnRestrictedPeriodQueryResult = Apollo.QueryResult<
  RegulatoryFilings_TnRestrictedPeriodQuery,
  RegulatoryFilings_TnRestrictedPeriodQueryVariables
>;

import { makeMockResponse } from '../../../../../../graphql/mock-response';

export const makeRegulatoryFilings_SingleTnFilingMockResponse = makeMockResponse<
  RegulatoryFilings_SingleTnFilingQueryVariables,
  RegulatoryFilings_SingleTnFilingQuery
>(RegulatoryFilings_SingleTnFilingDocument);

export const makeRegulatoryFilings_StabilizationTnActivitiesMockResponse = makeMockResponse<
  RegulatoryFilings_StabilizationTnActivitiesQueryVariables,
  RegulatoryFilings_StabilizationTnActivitiesQuery
>(RegulatoryFilings_StabilizationTnActivitiesDocument);

export const makeRegulatoryFilings_TnFilingsMockResponse = makeMockResponse<
  RegulatoryFilings_TnFilingsQueryVariables,
  RegulatoryFilings_TnFilingsQuery
>(RegulatoryFilings_TnFilingsDocument);

export const makeRegulatoryFilings_TnRestrictedPeriodMockResponse = makeMockResponse<
  RegulatoryFilings_TnRestrictedPeriodQueryVariables,
  RegulatoryFilings_TnRestrictedPeriodQuery
>(RegulatoryFilings_TnRestrictedPeriodDocument);
