/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OfferingSetup_TimingPartsFragment = {
  readonly __typename?: 'Timing';
  readonly confidentialFilingDate?: string | null;
  readonly publicFilingDate?: string | null;
  readonly filingOccurred?: Types.MarketTiming | null;
  readonly launchOccurred?: Types.MarketTiming | null;
  readonly launchDate?: string | null;
  readonly booksCloseAt?: string | null;
  readonly timeZone?: string | null;
  readonly pricingDate?: string | null;
  readonly firstTradeDate?: string | null;
  readonly tradeDate?: string | null;
  readonly settlementDate?: string | null;
  readonly postponedDate?: string | null;
  readonly withdrawnDate?: string | null;
  readonly terminatedDate?: string | null;
};

export type OfferingSetup_TimingInfoQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type OfferingSetup_TimingInfoQuery = {
  readonly __typename?: 'Query';
  readonly offering: {
    readonly __typename?: 'Offering';
    readonly id: string;
    readonly timing: {
      readonly __typename?: 'Timing';
      readonly confidentialFilingDate?: string | null;
      readonly publicFilingDate?: string | null;
      readonly filingOccurred?: Types.MarketTiming | null;
      readonly launchOccurred?: Types.MarketTiming | null;
      readonly launchDate?: string | null;
      readonly booksCloseAt?: string | null;
      readonly timeZone?: string | null;
      readonly pricingDate?: string | null;
      readonly firstTradeDate?: string | null;
      readonly tradeDate?: string | null;
      readonly settlementDate?: string | null;
      readonly postponedDate?: string | null;
      readonly withdrawnDate?: string | null;
      readonly terminatedDate?: string | null;
    };
  };
};

export type OfferingSetup_UpdateTimingInfoMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  input: Types.UpdateTimingInfoInput;
}>;

export type OfferingSetup_UpdateTimingInfoMutation = {
  readonly __typename?: 'Mutation';
  readonly updateTimingInfo: {
    readonly __typename?: 'Timing';
    readonly confidentialFilingDate?: string | null;
    readonly publicFilingDate?: string | null;
    readonly filingOccurred?: Types.MarketTiming | null;
    readonly launchOccurred?: Types.MarketTiming | null;
    readonly launchDate?: string | null;
    readonly booksCloseAt?: string | null;
    readonly timeZone?: string | null;
    readonly pricingDate?: string | null;
    readonly firstTradeDate?: string | null;
    readonly tradeDate?: string | null;
    readonly settlementDate?: string | null;
    readonly postponedDate?: string | null;
    readonly withdrawnDate?: string | null;
    readonly terminatedDate?: string | null;
  };
};

export const OfferingSetup_TimingPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_TimingParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Timing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'confidentialFilingDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'publicFilingDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'filingOccurred' } },
          { kind: 'Field', name: { kind: 'Name', value: 'launchOccurred' } },
          { kind: 'Field', name: { kind: 'Name', value: 'launchDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'booksCloseAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timeZone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstTradeDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tradeDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'settlementDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postponedDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'withdrawnDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'terminatedDate' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const OfferingSetup_TimingInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'OfferingSetup_TimingInfo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'offering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'timing' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'OfferingSetup_TimingParts' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_TimingParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Timing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'confidentialFilingDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'publicFilingDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'filingOccurred' } },
          { kind: 'Field', name: { kind: 'Name', value: 'launchOccurred' } },
          { kind: 'Field', name: { kind: 'Name', value: 'launchDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'booksCloseAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timeZone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstTradeDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tradeDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'settlementDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postponedDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'withdrawnDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'terminatedDate' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useOfferingSetup_TimingInfoQuery__
 *
 * To run a query within a React component, call `useOfferingSetup_TimingInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfferingSetup_TimingInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferingSetup_TimingInfoQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useOfferingSetup_TimingInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    OfferingSetup_TimingInfoQuery,
    OfferingSetup_TimingInfoQueryVariables
  > &
    ({ variables: OfferingSetup_TimingInfoQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OfferingSetup_TimingInfoQuery, OfferingSetup_TimingInfoQueryVariables>(
    OfferingSetup_TimingInfoDocument,
    options
  );
}
export function useOfferingSetup_TimingInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OfferingSetup_TimingInfoQuery,
    OfferingSetup_TimingInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OfferingSetup_TimingInfoQuery, OfferingSetup_TimingInfoQueryVariables>(
    OfferingSetup_TimingInfoDocument,
    options
  );
}
export function useOfferingSetup_TimingInfoSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        OfferingSetup_TimingInfoQuery,
        OfferingSetup_TimingInfoQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    OfferingSetup_TimingInfoQuery,
    OfferingSetup_TimingInfoQueryVariables
  >(OfferingSetup_TimingInfoDocument, options);
}
export type OfferingSetup_TimingInfoQueryHookResult = ReturnType<
  typeof useOfferingSetup_TimingInfoQuery
>;
export type OfferingSetup_TimingInfoLazyQueryHookResult = ReturnType<
  typeof useOfferingSetup_TimingInfoLazyQuery
>;
export type OfferingSetup_TimingInfoSuspenseQueryHookResult = ReturnType<
  typeof useOfferingSetup_TimingInfoSuspenseQuery
>;
export type OfferingSetup_TimingInfoQueryResult = Apollo.QueryResult<
  OfferingSetup_TimingInfoQuery,
  OfferingSetup_TimingInfoQueryVariables
>;
export const OfferingSetup_UpdateTimingInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'OfferingSetup_UpdateTimingInfo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateTimingInfoInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateTimingInfo' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OfferingSetup_TimingParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_TimingParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Timing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'confidentialFilingDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'publicFilingDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'filingOccurred' } },
          { kind: 'Field', name: { kind: 'Name', value: 'launchOccurred' } },
          { kind: 'Field', name: { kind: 'Name', value: 'launchDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'booksCloseAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timeZone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstTradeDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tradeDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'settlementDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postponedDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'withdrawnDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'terminatedDate' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type OfferingSetup_UpdateTimingInfoMutationFn = Apollo.MutationFunction<
  OfferingSetup_UpdateTimingInfoMutation,
  OfferingSetup_UpdateTimingInfoMutationVariables
>;

/**
 * __useOfferingSetup_UpdateTimingInfoMutation__
 *
 * To run a mutation, you first call `useOfferingSetup_UpdateTimingInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOfferingSetup_UpdateTimingInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [offeringSetupUpdateTimingInfoMutation, { data, loading, error }] = useOfferingSetup_UpdateTimingInfoMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOfferingSetup_UpdateTimingInfoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OfferingSetup_UpdateTimingInfoMutation,
    OfferingSetup_UpdateTimingInfoMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OfferingSetup_UpdateTimingInfoMutation,
    OfferingSetup_UpdateTimingInfoMutationVariables
  >(OfferingSetup_UpdateTimingInfoDocument, options);
}
export type OfferingSetup_UpdateTimingInfoMutationHookResult = ReturnType<
  typeof useOfferingSetup_UpdateTimingInfoMutation
>;
export type OfferingSetup_UpdateTimingInfoMutationResult =
  Apollo.MutationResult<OfferingSetup_UpdateTimingInfoMutation>;
export type OfferingSetup_UpdateTimingInfoMutationOptions = Apollo.BaseMutationOptions<
  OfferingSetup_UpdateTimingInfoMutation,
  OfferingSetup_UpdateTimingInfoMutationVariables
>;

import { makeMockResponse } from '../../../../../graphql/mock-response';

export const makeOfferingSetup_TimingInfoMockResponse = makeMockResponse<
  OfferingSetup_TimingInfoQueryVariables,
  OfferingSetup_TimingInfoQuery
>(OfferingSetup_TimingInfoDocument);
