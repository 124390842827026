/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InvestorFirmSearch_Advanced_InvestorFirmsQueryVariables = Types.Exact<{
  searchText: Types.Scalars['String'];
  cik?: Types.InputMaybe<Types.Scalars['String']>;
  lei?: Types.InputMaybe<Types.Scalars['String']>;
  crd?: Types.InputMaybe<Types.Scalars['String']>;
  firmKey?: Types.InputMaybe<Types.Scalars['String']>;
}>;

export type InvestorFirmSearch_Advanced_InvestorFirmsQuery = {
  readonly __typename?: 'Query';
  readonly investorFirms: {
    readonly __typename?: 'InvestorFirmBasicPagedList';
    readonly data: ReadonlyArray<{
      readonly __typename?: 'InvestorFirmBasic';
      readonly id: string;
      readonly firmKey?: string | null;
      readonly cmgEntityKey?: string | null;
      readonly name: string;
      readonly firmType: Types.RolodexFirmCategoryType;
      readonly lei?: string | null;
      readonly crd?: string | null;
      readonly cik1?: string | null;
      readonly cik2?: string | null;
      readonly address1?: string | null;
      readonly address2?: string | null;
      readonly website1?: string | null;
      readonly website2?: string | null;
    }>;
  };
};

export const InvestorFirmSearch_Advanced_InvestorFirmsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'InvestorFirmSearch_Advanced_InvestorFirms' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'searchText' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cik' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'lei' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'crd' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'firmKey' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investorFirms' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'searchText' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'searchText' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cik' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'cik' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'lei' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'lei' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'crd' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'crd' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'firmKey' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'firmKey' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'InvestorFirmSearch_Advanced_InvestorBasicParts',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InvestorFirmSearch_Advanced_InvestorBasicParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'InvestorFirmBasic' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lei' } },
          { kind: 'Field', name: { kind: 'Name', value: 'crd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cik1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cik2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website2' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useInvestorFirmSearch_Advanced_InvestorFirmsQuery__
 *
 * To run a query within a React component, call `useInvestorFirmSearch_Advanced_InvestorFirmsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvestorFirmSearch_Advanced_InvestorFirmsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvestorFirmSearch_Advanced_InvestorFirmsQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *      cik: // value for 'cik'
 *      lei: // value for 'lei'
 *      crd: // value for 'crd'
 *      firmKey: // value for 'firmKey'
 *   },
 * });
 */
export function useInvestorFirmSearch_Advanced_InvestorFirmsQuery(
  baseOptions: Apollo.QueryHookOptions<
    InvestorFirmSearch_Advanced_InvestorFirmsQuery,
    InvestorFirmSearch_Advanced_InvestorFirmsQueryVariables
  > &
    (
      | { variables: InvestorFirmSearch_Advanced_InvestorFirmsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    InvestorFirmSearch_Advanced_InvestorFirmsQuery,
    InvestorFirmSearch_Advanced_InvestorFirmsQueryVariables
  >(InvestorFirmSearch_Advanced_InvestorFirmsDocument, options);
}
export function useInvestorFirmSearch_Advanced_InvestorFirmsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InvestorFirmSearch_Advanced_InvestorFirmsQuery,
    InvestorFirmSearch_Advanced_InvestorFirmsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    InvestorFirmSearch_Advanced_InvestorFirmsQuery,
    InvestorFirmSearch_Advanced_InvestorFirmsQueryVariables
  >(InvestorFirmSearch_Advanced_InvestorFirmsDocument, options);
}
export function useInvestorFirmSearch_Advanced_InvestorFirmsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        InvestorFirmSearch_Advanced_InvestorFirmsQuery,
        InvestorFirmSearch_Advanced_InvestorFirmsQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    InvestorFirmSearch_Advanced_InvestorFirmsQuery,
    InvestorFirmSearch_Advanced_InvestorFirmsQueryVariables
  >(InvestorFirmSearch_Advanced_InvestorFirmsDocument, options);
}
export type InvestorFirmSearch_Advanced_InvestorFirmsQueryHookResult = ReturnType<
  typeof useInvestorFirmSearch_Advanced_InvestorFirmsQuery
>;
export type InvestorFirmSearch_Advanced_InvestorFirmsLazyQueryHookResult = ReturnType<
  typeof useInvestorFirmSearch_Advanced_InvestorFirmsLazyQuery
>;
export type InvestorFirmSearch_Advanced_InvestorFirmsSuspenseQueryHookResult = ReturnType<
  typeof useInvestorFirmSearch_Advanced_InvestorFirmsSuspenseQuery
>;
export type InvestorFirmSearch_Advanced_InvestorFirmsQueryResult = Apollo.QueryResult<
  InvestorFirmSearch_Advanced_InvestorFirmsQuery,
  InvestorFirmSearch_Advanced_InvestorFirmsQueryVariables
>;

import { makeMockResponse } from '../../../../../../../../graphql/mock-response';

export const makeInvestorFirmSearch_Advanced_InvestorFirmsMockResponse = makeMockResponse<
  InvestorFirmSearch_Advanced_InvestorFirmsQueryVariables,
  InvestorFirmSearch_Advanced_InvestorFirmsQuery
>(InvestorFirmSearch_Advanced_InvestorFirmsDocument);
