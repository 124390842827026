import { ActionPanelSection, Button, LoadingButton, Stack } from '@cmg/design-system';
import React from 'react';

import {
  IndicationStatus as IndicationStatusType,
  InstitutionalIndicationOrderType,
  OrderBookState,
} from '../../../../../../../../graphql';
import { useSupersededOfferingContext } from '../../../../../../contexts/SupersededOfferingContext';
import { useIndicationActivityBuySideContext } from '../../../../context/IndicationActivityBuySideContext';
import IndicationSectionPassedReadContent from '../../common/indication-section-passed-read-content/IndicationSectionPassedReadContent';
import IndicationSectionReadContent from '../../common/indication-section-read-content/IndicationSectionReadContent';
import IndicationStatus from '../../common/indication-status/IndicationStatus';
import PassOfferingConfirmDialog from '../common/pass-offering-confirm-dialog/PassOfferingConfirmDialog';
import CancelIndicationConfirmDialog from './cancel-indication-confirm-dialog/CancelIndicationConfirmDialog';
import { useUpdateMyIndicationStatus } from './hooks/useUpdateMyIndicationStatus';

export type Props = Readonly<{
  onStartEditing: () => void;
  hasAllocationRanking: boolean;
}>;

const IndicationSectionReadView: React.FC<Props> = ({ onStartEditing, hasAllocationRanking }) => {
  const {
    basicPublishedOffering,
    offeringId,
    access: { canUpdateIndication },
  } = useIndicationActivityBuySideContext();
  const { isObsoleteOffering } = useSupersededOfferingContext();

  const offering = basicPublishedOffering!;
  const indication = offering.myInstitutionalIndication!;

  const [isPassDialogOpen, setIsPassDialogOpen] = React.useState(false);
  const [isCancelDialogOpen, setIsCancelDialogOpen] = React.useState(false);

  const [onUpdateStatus, isUpdatingStatus] = useUpdateMyIndicationStatus(offeringId);

  const baseButtonAccess =
    offering.publicOrderBook?.state !== OrderBookState.Closed && canUpdateIndication;

  const isPassButtonVisible = indication.type !== InstitutionalIndicationOrderType.Pass;
  const isReactivateButtonVisible = indication.status === IndicationStatusType.Cancelled;
  const isEditAndCancelButtonVisible = indication.status !== IndicationStatusType.Cancelled;

  return (
    <ActionPanelSection
      data-testid="read-indication-view"
      title="Indication"
      titleItems={<IndicationStatus status={indication.status} />}
      actions={[
        ...(baseButtonAccess && isPassButtonVisible
          ? [
              <Button
                key="pass"
                disabled={hasAllocationRanking || isUpdatingStatus}
                onClick={() => setIsPassDialogOpen(true)}
                variant="outlined"
              >
                Pass
              </Button>,
            ]
          : []),
        ...(baseButtonAccess && isReactivateButtonVisible
          ? [
              <LoadingButton
                key="reactivate"
                loading={isUpdatingStatus}
                onClick={() => onUpdateStatus(IndicationStatusType.Active)}
                variant="outlined"
              >
                Re-activate
              </LoadingButton>,
            ]
          : []),
        ...(baseButtonAccess && isEditAndCancelButtonVisible
          ? [
              <Button
                key="cancel"
                disabled={isUpdatingStatus}
                onClick={() => setIsCancelDialogOpen(true)}
                variant="outlined"
              >
                Cancel
              </Button>,
              <Button
                key="edit"
                disabled={isObsoleteOffering || isUpdatingStatus}
                onClick={onStartEditing}
                variant="contained"
              >
                Edit
              </Button>,
            ]
          : []),
      ]}
    >
      <Stack gap={1}>
        {indication.type === InstitutionalIndicationOrderType.Pass && (
          <IndicationSectionPassedReadContent submittedOn={indication.auditInfo.createdAt} />
        )}
        {indication.type !== InstitutionalIndicationOrderType.Pass && (
          <IndicationSectionReadContent
            submittedOn={indication.auditInfo.createdAt}
            indicationTrancheId={indication.trancheId}
            indicationInstrumentId={indication.instrumentId}
            demandCurrencyCode={indication.currencyCode}
            pricingCurrencyCode={offering.pricingCurrencyCode}
            acknowledgements={indication.acknowledgements}
            indicationVersion={indication.version}
            bndAgentCmgEntityKey={indication.billingAndDeliveryAgentCmgEntityKey}
            interestLevels={indication.interestLevels}
            tranches={offering.orderEntryProfile.tranches}
            demandInstruments={offering.orderEntryProfile.instruments}
            managers={offering.syndicate.managers}
            securityTypeDisplayName={offering.securityTypeDisplayName!}
          />
        )}

        <CancelIndicationConfirmDialog
          offeringId={offeringId}
          isOpen={isCancelDialogOpen}
          onClose={() => setIsCancelDialogOpen(false)}
        />
        <PassOfferingConfirmDialog
          isOpen={isPassDialogOpen}
          onClose={() => setIsPassDialogOpen(false)}
          offeringId={offeringId}
          indicationStatus={indication.status}
        />
      </Stack>
    </ActionPanelSection>
  );
};

export default IndicationSectionReadView;
