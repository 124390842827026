import { useAuth } from '@cmg/auth';
import { useBrowserStorage, UUID } from '@cmg/common';
import { GridState, useDataGridContext } from '@cmg/data-grid';
import React from 'react';

import { OfferingStatus, OfferingType } from '../../../../../graphql';
import type { DemandGridDataContext } from '../types';
import type { StoredGridState } from './useStoredGridState';
import { CMG_TEMPLATES, isDynamicField, isStaticField } from './useTemplatesState.model';

export type UserTemplate = {
  id: string;
  name: string;
  active: boolean;
  gridState: IndexedStoredGridState;
};

export type OrderbookTemplate = {
  id: string;
  gridState: IndexedStoredGridState;
};

type ColumnIndex = {
  colId: string;
  index: number;
};

type IndexedStoredGridState = {
  columnOrder?: {
    orderedIndexes: ColumnIndex[];
  };
  columnPinning?: {
    leftIndexes: ColumnIndex[];
    rightIndexes: ColumnIndex[];
  };
} & Pick<StoredGridState, 'columnVisibility' | 'columnSizing' | 'rowGroup'>;

export function getCmgTemplateKey(_: {
  offeringType: OfferingType;
  offeringStatus: OfferingStatus;
}): keyof typeof CMG_TEMPLATES {
  // TODO: cmg template will be selected based on offeringType and offeringStatus in the future
  return 'CMG Default';
}

export function getGridStateForUser(state: GridState | null): IndexedStoredGridState {
  if (!state) {
    return {};
  }
  return {
    columnOrder: state?.columnOrder
      ? {
          orderedIndexes: reduceToIndexes(state.columnOrder.orderedColIds, isStaticField),
        }
      : undefined,
    columnVisibility: state?.columnVisibility
      ? { hiddenColIds: state.columnVisibility.hiddenColIds.filter(isStaticField) }
      : undefined,
    columnPinning: state?.columnPinning
      ? {
          leftIndexes: reduceToIndexes(state.columnPinning.leftColIds, isStaticField),
          rightIndexes: reduceToIndexes(state.columnPinning.rightColIds, isStaticField),
        }
      : undefined,
    columnSizing: state?.columnSizing
      ? {
          columnSizingModel: state.columnSizing.columnSizingModel.filter(({ colId }) =>
            isStaticField(colId)
          ),
        }
      : undefined,
  };
}

export function reduceToIndexes(input: string[], predicate: (_: string) => boolean): ColumnIndex[] {
  return input.reduce<ColumnIndex[]>((prev, colId, index) => {
    if (predicate(colId)) {
      prev.push({ colId, index });
    }
    return prev;
  }, []);
}

export function getGridStateForOrderbook(state: GridState | null): IndexedStoredGridState {
  if (!state) {
    return {};
  }
  return {
    columnOrder: state?.columnOrder
      ? {
          orderedIndexes: reduceToIndexes(state.columnOrder.orderedColIds, isDynamicField),
        }
      : undefined,
    columnVisibility: state?.columnVisibility
      ? { hiddenColIds: state.columnVisibility.hiddenColIds.filter(isDynamicField) }
      : undefined,
    columnPinning: state?.columnPinning
      ? {
          leftIndexes: reduceToIndexes(state.columnPinning.leftColIds, isDynamicField),
          rightIndexes: reduceToIndexes(state.columnPinning.rightColIds, isDynamicField),
        }
      : undefined,
    columnSizing: state?.columnSizing
      ? {
          columnSizingModel: state.columnSizing.columnSizingModel.filter(({ colId }) =>
            isDynamicField(colId)
          ),
        }
      : undefined,
  };
}

export function mergeColumnOrder(userIndices: ColumnIndex[], orderbookIndices: ColumnIndex[]) {
  const result = new Array<string>(userIndices.length + orderbookIndices?.length);
  let user_i = 0;
  let orderbook_i = 0;
  while (user_i + orderbook_i < result.length) {
    const userIndex = userIndices[user_i];
    const orderbookIndex = orderbookIndices[orderbook_i];
    if (orderbookIndex === undefined) {
      result[user_i + orderbook_i] = userIndex!.colId;
      user_i++;
      continue;
    }
    if (userIndex === undefined) {
      result[user_i + orderbook_i] = orderbookIndex.colId;
      orderbook_i++;
      continue;
    }
    if (orderbookIndex.index <= userIndex.index) {
      result[user_i + orderbook_i] = orderbookIndex.colId;
      orderbook_i++;
    } else {
      result[user_i + orderbook_i] = userIndex.colId;
      user_i++;
    }
  }
  return result;
}

export function mergeGridStates({
  userTemplate,
  orderbookTemplate,
}: {
  userTemplate: IndexedStoredGridState;
  orderbookTemplate: IndexedStoredGridState;
}): StoredGridState {
  const userTemplateOrderedIndices = userTemplate.columnOrder?.orderedIndexes ?? [];
  const orderbookTemplateOrderedIndices = orderbookTemplate.columnOrder?.orderedIndexes ?? [];

  const userTemplateRightIndices = userTemplate.columnPinning?.rightIndexes ?? [];
  const orderbookTemplateRightIndices = orderbookTemplate.columnPinning?.rightIndexes ?? [];

  const userTemplateLeftIndices = userTemplate.columnPinning?.leftIndexes ?? [];
  const orderbookTemplateLeftIndices = orderbookTemplate.columnPinning?.leftIndexes ?? [];

  const userTemplateSizingModel = userTemplate.columnSizing?.columnSizingModel ?? [];
  const orderbookTemplateSizingModel = orderbookTemplate.columnSizing?.columnSizingModel ?? [];

  const userTemplateHiddenColIds = userTemplate.columnVisibility?.hiddenColIds ?? [];
  const orderbookTemplateHiddenColIds = orderbookTemplate.columnVisibility?.hiddenColIds ?? [];

  return {
    columnOrder: {
      orderedColIds: mergeColumnOrder(userTemplateOrderedIndices, orderbookTemplateOrderedIndices),
    },
    columnPinning: {
      leftColIds: mergeColumnOrder(userTemplateLeftIndices, orderbookTemplateLeftIndices),
      rightColIds: mergeColumnOrder(userTemplateRightIndices, orderbookTemplateRightIndices),
    },
    columnSizing: {
      columnSizingModel: [...userTemplateSizingModel, ...orderbookTemplateSizingModel],
    },
    columnVisibility: {
      hiddenColIds: [...userTemplateHiddenColIds, ...orderbookTemplateHiddenColIds],
    },
  };
}

export function useTemplatesState(offeringId: UUID) {
  const { applyGridState, getColDefs, getState, getDataContextObject } = useDataGridContext();
  const { oidcUserId } = useAuth();
  const [userTemplates, setUserTemplates] = useBrowserStorage<UserTemplate[]>({
    key: `userTemplates_${oidcUserId}`,
    initialValue: [],
    storageType: 'Local',
  });
  const [orderbookTemplates, setOrderbookTemplates] = useBrowserStorage<OrderbookTemplate[]>({
    key: `orderbookTemplates_${oidcUserId}_${offeringId}`,
    initialValue: [],
    storageType: 'Local',
  });

  const saveTemplate = React.useCallback(
    (id: string) => {
      setUserTemplates(
        userTemplates.map(template => {
          if (template.id === id) {
            return { ...template, gridState: getGridStateForUser(getState()) };
          }
          return template;
        })
      );
      if (orderbookTemplates.find(template => template.id === id)) {
        setOrderbookTemplates(
          orderbookTemplates.map(template => {
            if (template.id === id) {
              return { ...template, gridState: getGridStateForOrderbook(getState()) };
            }
            return template;
          })
        );
      } else {
        setOrderbookTemplates([
          ...orderbookTemplates,
          {
            id,
            gridState: getGridStateForOrderbook(getState()),
          },
        ]);
      }
    },
    [getState, orderbookTemplates, setOrderbookTemplates, setUserTemplates, userTemplates]
  );

  const addTemplate = React.useCallback(
    ({
      id,
      name,
      originalTemplateId,
    }: {
      id: string;
      name: string;
      originalTemplateId?: string;
    }) => {
      let gridStateUser: IndexedStoredGridState | undefined = undefined;
      let gridStateOrderbook: IndexedStoredGridState | undefined = undefined;

      if (originalTemplateId === undefined) {
        const state = getState();
        gridStateUser = getGridStateForUser(state);
        gridStateOrderbook = getGridStateForOrderbook(state);
      } else {
        const originalUserTemplate = userTemplates.find(
          template => template.id === originalTemplateId
        );
        const originalOrderbookTemplate = orderbookTemplates.find(
          template => template.id === originalTemplateId
        );
        if (originalUserTemplate) {
          gridStateUser = originalUserTemplate.gridState;
          gridStateOrderbook = originalOrderbookTemplate?.gridState;
        } else {
          const state = CMG_TEMPLATES[originalTemplateId]({ colDefs: getColDefs() });
          gridStateUser = getGridStateForUser(state);
          gridStateOrderbook = getGridStateForOrderbook(state);
        }
      }
      if (!gridStateUser) {
        return;
      }
      const newUserTemplate = {
        id,
        name,
        active: true,
        gridState: gridStateUser,
      };
      if (userTemplates.find(template => template.id === id)) {
        setUserTemplates(
          userTemplates.map(template => {
            if (template.id === id) {
              return { ...template, name };
            }
            return template;
          })
        );
      } else {
        setUserTemplates([
          ...userTemplates.map(template => ({ ...template, active: false })),
          newUserTemplate,
        ]);
      }
      setOrderbookTemplates([
        ...orderbookTemplates.filter(template => template.id !== id),
        ...(gridStateOrderbook
          ? [
              {
                id,
                gridState: gridStateOrderbook,
              },
            ]
          : []),
      ]);
      applyGridState(
        mergeGridStates({
          userTemplate: gridStateUser,
          orderbookTemplate: gridStateOrderbook ?? {},
        })
      );
    },
    [
      userTemplates,
      setOrderbookTemplates,
      orderbookTemplates,
      applyGridState,
      getState,
      getColDefs,
      setUserTemplates,
    ]
  );

  const getActiveUserTemplate = React.useCallback(() => {
    return userTemplates.find(template => template.active);
  }, [userTemplates]);

  const getActiveGridState = React.useCallback(
    (cmgTemplateKey: keyof typeof CMG_TEMPLATES) => {
      const activeUserTemplate = userTemplates.find(template => template.active);
      if (activeUserTemplate) {
        const activeOrderbookTemplate = orderbookTemplates.find(
          template => template.id === activeUserTemplate.id
        );
        return mergeGridStates({
          userTemplate: activeUserTemplate.gridState,
          orderbookTemplate: activeOrderbookTemplate?.gridState ?? {},
        });
      } else {
        return CMG_TEMPLATES[cmgTemplateKey]({
          colDefs: getColDefs(),
          demandGridContext: getDataContextObject<DemandGridDataContext>(),
        });
      }
    },
    [userTemplates, orderbookTemplates, getColDefs, getDataContextObject]
  );

  const removeTemplate = React.useCallback(
    ({ id, cmgTemplateKey }: { id: string; cmgTemplateKey: keyof typeof CMG_TEMPLATES }) => {
      const result = userTemplates.filter(template => template.id !== id);
      setUserTemplates(result);
      setOrderbookTemplates(orderbookTemplates.filter(template => template.id !== id));
      if (result.find(template => template.active) === undefined) {
        applyGridState(
          CMG_TEMPLATES[cmgTemplateKey]({
            colDefs: getColDefs(),
            demandGridContext: getDataContextObject<DemandGridDataContext>(),
          })
        );
      }
    },
    [
      userTemplates,
      setUserTemplates,
      setOrderbookTemplates,
      orderbookTemplates,
      applyGridState,
      getColDefs,
      getDataContextObject,
    ]
  );

  const setActiveUserTemplate = React.useCallback(
    (id: string) => {
      const result = userTemplates.map(template => {
        if (template.id === id) {
          const gridState = mergeGridStates({
            userTemplate: template.gridState,
            orderbookTemplate:
              orderbookTemplates.find(template => template.id === id)?.gridState ?? {},
          });
          applyGridState(gridState);
          return { ...template, active: true };
        }
        return { ...template, active: false };
      });
      setUserTemplates(result);
    },
    [applyGridState, orderbookTemplates, setUserTemplates, userTemplates]
  );

  const setActiveCmgTemplate = React.useCallback(
    (cmgTemplateKey: keyof typeof CMG_TEMPLATES) => {
      applyGridState(
        CMG_TEMPLATES[cmgTemplateKey]({
          colDefs: getColDefs(),
          demandGridContext: getDataContextObject<DemandGridDataContext>(),
        })
      );
      const result = userTemplates.map(template => {
        return { ...template, active: false };
      });
      setUserTemplates(result);
    },
    [applyGridState, getColDefs, getDataContextObject, setUserTemplates, userTemplates]
  );

  return {
    userTemplates,
    addTemplate,
    removeTemplate,
    saveTemplate,
    getActiveUserTemplate,
    getActiveGridState,
    setActiveUserTemplate,
    setActiveCmgTemplate,
  };
}
