import { ActionPanelSection, Button, LoadingButton } from '@cmg/design-system';
import { Form, FormikProvider, useFormik, yupToFormErrors } from 'formik';
import React from 'react';

import { useFirmKeyOrIdContext } from '../../../../../../contexts/FirmKeyOrIdContext';
import { useIndicationActivitySyndicateContext } from '../../../../context/IndicationActivitySyndicateContext';
import InvestorContactInformation from '../../../investor-contact-information/layout/InvestorContactInformation';
import IndicationSectionForm from '../../common/indication-section-form/IndicationSectionForm';
import IndicationStatus from '../../common/indication-status/IndicationStatus';
import {
  createIndicationValidationSchema,
  IndicationCreateFormValues,
} from '../../common/utils/IndicationSectionValidation';
import { createInitialValues } from '../../sales-and-trading/indication-section-create-view/IndicationSectionCreateView.model';
import PassInstitutionalIndicationDialog from '../common/pass-institutional-indication-dialog/PassInstitutionalIndicationDialog';
import { useCreateInstitutionalIndication } from './hooks/useCreateInstitutionalIndication';

export type Props = Readonly<{
  prospectusContactMandatory: boolean;
}>;

const IndicationSectionCreateView: React.FC<Props> = ({ prospectusContactMandatory }) => {
  const { offeringId, basicPublishedOffering, loading } = useIndicationActivitySyndicateContext();
  const offering = basicPublishedOffering!;
  const { firmKeyOrId } = useFirmKeyOrIdContext();

  const [isPassDialogOpen, setIsPassDialogOpen] = React.useState(false);

  const [createIndication] = useCreateInstitutionalIndication(offeringId);

  const formik = useFormik<IndicationCreateFormValues>({
    initialValues: createInitialValues(offering),
    validate: async values => {
      try {
        await createIndicationValidationSchema.validate(values, {
          abortEarly: false,
          context: { prospectusContactMandatory: false }, // contacts are never mandatory for syndicate
        });

        return {};
      } catch (e) {
        return yupToFormErrors(e);
      }
    },
    onSubmit: createIndication,
  });
  const { isSubmitting, handleSubmit, handleReset, values } = formik;
  const isLoading = loading || isSubmitting;

  return (
    <ActionPanelSection
      data-testid="create-indication-view"
      title="Indication"
      titleItems={<IndicationStatus />}
      actions={[
        <Button
          key="pass"
          variant="outlined"
          disabled={isLoading}
          onClick={() => setIsPassDialogOpen(true)}
        >
          Pass
        </Button>,
        <Button key="discard" variant="outlined" disabled={isLoading} onClick={handleReset}>
          Discard Changes
        </Button>,
        <LoadingButton
          key="submit"
          variant="contained"
          loading={isLoading}
          onClick={() => handleSubmit()}
        >
          Submit Indication
        </LoadingButton>,
      ]}
    >
      <FormikProvider value={formik}>
        <Form>
          <IndicationSectionForm
            tranches={offering.orderEntryProfile.tranches}
            instruments={offering.orderEntryProfile.instruments}
            offeringType={offering.type}
            canEnterTranche
            pricingCurrencyCode={offering.pricingCurrencyCode}
            securityTypeDisplayName={offering.securityTypeDisplayName!}
          />
        </Form>

        <InvestorContactInformation
          bankInvestorKey={firmKeyOrId}
          sellSideContacts={values.sellSideContacts}
          buySideContacts={[]}
          editing={true}
          isCoveredAccount={false}
          displayMissingContacts={false}
          prospectusContactMandatory={prospectusContactMandatory}
        />
      </FormikProvider>

      <PassInstitutionalIndicationDialog
        isOpen={isPassDialogOpen}
        onClose={() => setIsPassDialogOpen(false)}
        offeringId={offeringId}
        isPassingNewIndication
      />
    </ActionPanelSection>
  );
};

export default IndicationSectionCreateView;
