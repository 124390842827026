/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Notes_AssignIndicationNoteMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  indicationId: Types.Scalars['UUID'];
  input: Types.AssignNoteInput;
}>;

export type Notes_AssignIndicationNoteMutation = {
  readonly __typename?: 'Mutation';
  readonly assignIndicationNote: ReadonlyArray<{
    readonly __typename?: 'Note';
    readonly id: string;
    readonly text: string;
    readonly type: Types.IndicationNoteType;
  }>;
};

export type Notes_IndicationInvestorQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type Notes_IndicationInvestorQuery = {
  readonly __typename?: 'Query';
  readonly basicPublishedOffering: {
    readonly __typename?: 'BasicPublishedOfferingProfile';
    readonly orderBook: {
      readonly __typename?: 'OrderBook';
      readonly institutionalIndications: ReadonlyArray<{
        readonly __typename?: 'InstitutionalIndication';
        readonly id: string;
        readonly status: Types.IndicationStatus;
        readonly investorInformation: {
          readonly __typename?: 'InvestorInformation';
          readonly bankInvestorName?: string | null;
          readonly cmgEntityName: string;
          readonly cmgEntityKey: string;
        };
      }>;
    };
  };
};

export type Notes_IndicationNoteQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  indicationId: Types.Scalars['UUID'];
}>;

export type Notes_IndicationNoteQuery = {
  readonly __typename?: 'Query';
  readonly getInstitutionalIndicationNotes: ReadonlyArray<{
    readonly __typename?: 'Note';
    readonly id: string;
    readonly text: string;
    readonly type: Types.IndicationNoteType;
  }>;
};

export const Notes_AssignIndicationNoteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Notes_AssignIndicationNote' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'indicationId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'AssignNoteInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignIndicationNote' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'indicationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'indicationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type Notes_AssignIndicationNoteMutationFn = Apollo.MutationFunction<
  Notes_AssignIndicationNoteMutation,
  Notes_AssignIndicationNoteMutationVariables
>;

/**
 * __useNotes_AssignIndicationNoteMutation__
 *
 * To run a mutation, you first call `useNotes_AssignIndicationNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotes_AssignIndicationNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notesAssignIndicationNoteMutation, { data, loading, error }] = useNotes_AssignIndicationNoteMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      indicationId: // value for 'indicationId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNotes_AssignIndicationNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Notes_AssignIndicationNoteMutation,
    Notes_AssignIndicationNoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Notes_AssignIndicationNoteMutation,
    Notes_AssignIndicationNoteMutationVariables
  >(Notes_AssignIndicationNoteDocument, options);
}
export type Notes_AssignIndicationNoteMutationHookResult = ReturnType<
  typeof useNotes_AssignIndicationNoteMutation
>;
export type Notes_AssignIndicationNoteMutationResult =
  Apollo.MutationResult<Notes_AssignIndicationNoteMutation>;
export type Notes_AssignIndicationNoteMutationOptions = Apollo.BaseMutationOptions<
  Notes_AssignIndicationNoteMutation,
  Notes_AssignIndicationNoteMutationVariables
>;
export const Notes_IndicationInvestorDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Notes_IndicationInvestor' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'basicPublishedOffering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'orderBook' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'institutionalIndications' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'investorInformation' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'bankInvestorName' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useNotes_IndicationInvestorQuery__
 *
 * To run a query within a React component, call `useNotes_IndicationInvestorQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotes_IndicationInvestorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotes_IndicationInvestorQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useNotes_IndicationInvestorQuery(
  baseOptions: Apollo.QueryHookOptions<
    Notes_IndicationInvestorQuery,
    Notes_IndicationInvestorQueryVariables
  > &
    ({ variables: Notes_IndicationInvestorQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Notes_IndicationInvestorQuery, Notes_IndicationInvestorQueryVariables>(
    Notes_IndicationInvestorDocument,
    options
  );
}
export function useNotes_IndicationInvestorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Notes_IndicationInvestorQuery,
    Notes_IndicationInvestorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Notes_IndicationInvestorQuery, Notes_IndicationInvestorQueryVariables>(
    Notes_IndicationInvestorDocument,
    options
  );
}
export function useNotes_IndicationInvestorSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        Notes_IndicationInvestorQuery,
        Notes_IndicationInvestorQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Notes_IndicationInvestorQuery,
    Notes_IndicationInvestorQueryVariables
  >(Notes_IndicationInvestorDocument, options);
}
export type Notes_IndicationInvestorQueryHookResult = ReturnType<
  typeof useNotes_IndicationInvestorQuery
>;
export type Notes_IndicationInvestorLazyQueryHookResult = ReturnType<
  typeof useNotes_IndicationInvestorLazyQuery
>;
export type Notes_IndicationInvestorSuspenseQueryHookResult = ReturnType<
  typeof useNotes_IndicationInvestorSuspenseQuery
>;
export type Notes_IndicationInvestorQueryResult = Apollo.QueryResult<
  Notes_IndicationInvestorQuery,
  Notes_IndicationInvestorQueryVariables
>;
export const Notes_IndicationNoteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Notes_IndicationNote' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'indicationId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getInstitutionalIndicationNotes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'indicationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'indicationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useNotes_IndicationNoteQuery__
 *
 * To run a query within a React component, call `useNotes_IndicationNoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotes_IndicationNoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotes_IndicationNoteQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      indicationId: // value for 'indicationId'
 *   },
 * });
 */
export function useNotes_IndicationNoteQuery(
  baseOptions: Apollo.QueryHookOptions<
    Notes_IndicationNoteQuery,
    Notes_IndicationNoteQueryVariables
  > &
    ({ variables: Notes_IndicationNoteQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Notes_IndicationNoteQuery, Notes_IndicationNoteQueryVariables>(
    Notes_IndicationNoteDocument,
    options
  );
}
export function useNotes_IndicationNoteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Notes_IndicationNoteQuery,
    Notes_IndicationNoteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Notes_IndicationNoteQuery, Notes_IndicationNoteQueryVariables>(
    Notes_IndicationNoteDocument,
    options
  );
}
export function useNotes_IndicationNoteSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<Notes_IndicationNoteQuery, Notes_IndicationNoteQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<Notes_IndicationNoteQuery, Notes_IndicationNoteQueryVariables>(
    Notes_IndicationNoteDocument,
    options
  );
}
export type Notes_IndicationNoteQueryHookResult = ReturnType<typeof useNotes_IndicationNoteQuery>;
export type Notes_IndicationNoteLazyQueryHookResult = ReturnType<
  typeof useNotes_IndicationNoteLazyQuery
>;
export type Notes_IndicationNoteSuspenseQueryHookResult = ReturnType<
  typeof useNotes_IndicationNoteSuspenseQuery
>;
export type Notes_IndicationNoteQueryResult = Apollo.QueryResult<
  Notes_IndicationNoteQuery,
  Notes_IndicationNoteQueryVariables
>;

import { makeMockResponse } from '../../../../../../graphql/mock-response';

export const makeNotes_IndicationInvestorMockResponse = makeMockResponse<
  Notes_IndicationInvestorQueryVariables,
  Notes_IndicationInvestorQuery
>(Notes_IndicationInvestorDocument);

export const makeNotes_IndicationNoteMockResponse = makeMockResponse<
  Notes_IndicationNoteQueryVariables,
  Notes_IndicationNoteQuery
>(Notes_IndicationNoteDocument);
