/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TradeRelease_ReleaseTradesMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  indicationIds: ReadonlyArray<Types.Scalars['UUID']> | Types.Scalars['UUID'];
}>;

export type TradeRelease_ReleaseTradesMutation = {
  readonly __typename?: 'Mutation';
  readonly releaseTradesBatch: ReadonlyArray<{
    readonly __typename?: 'TradeGridItem';
    readonly id: string;
    readonly tradeId?: string | null;
    readonly indicationId: string;
    readonly investorBankKey?: string | null;
    readonly investorCmgEntityKey: string;
    readonly investorFirmName: string;
    readonly allocationShares?: number | null;
    readonly sharesReleased?: number | null;
    readonly investorReplyStatus?: Types.InvestorReplyStatus | null;
    readonly investorReplyName?: string | null;
    readonly investorReplyEmail?: string | null;
    readonly indicationSubmittedBy: string;
    readonly indicationInvestorContactName?: string | null;
    readonly indicationInvestorContactEmail?: string | null;
    readonly statusDetails?: string | null;
    readonly allowedStatusTransitions: ReadonlyArray<Types.TradeReleaseGridStatusTransition>;
    readonly warnings: ReadonlyArray<Types.TradeReleaseGridWarning>;
    readonly sellingConcession?: number | null;
    readonly cusip?: string | null;
    readonly exchange?: string | null;
    readonly indicationAcknowledgedByName?: string | null;
    readonly indicationAcknowledgedByEmail?: string | null;
    readonly indicationStatus: Types.IndicationStatus;
    readonly investorContactName?: string | null;
    readonly investorContactEmail?: string | null;
    readonly isin?: string | null;
    readonly offeringCurrency: string;
    readonly releasedByName?: string | null;
    readonly releasedByEmail?: string | null;
    readonly releasedAt?: string | null;
    readonly securityType: Types.OfferingSecurityType;
    readonly settlementCurrency: string;
    readonly settlementDate: string;
    readonly tradeDate?: string | null;
    readonly symbol?: string | null;
    readonly tradeStatus: Types.TradeReleaseStatus;
    readonly offerPrice: number;
  }>;
};

export type TradeRelease_TradeGridItemFragment = {
  readonly __typename?: 'TradeGridItem';
  readonly id: string;
  readonly tradeId?: string | null;
  readonly indicationId: string;
  readonly investorBankKey?: string | null;
  readonly investorCmgEntityKey: string;
  readonly investorFirmName: string;
  readonly allocationShares?: number | null;
  readonly sharesReleased?: number | null;
  readonly investorReplyStatus?: Types.InvestorReplyStatus | null;
  readonly investorReplyName?: string | null;
  readonly investorReplyEmail?: string | null;
  readonly indicationSubmittedBy: string;
  readonly indicationInvestorContactName?: string | null;
  readonly indicationInvestorContactEmail?: string | null;
  readonly statusDetails?: string | null;
  readonly allowedStatusTransitions: ReadonlyArray<Types.TradeReleaseGridStatusTransition>;
  readonly warnings: ReadonlyArray<Types.TradeReleaseGridWarning>;
  readonly sellingConcession?: number | null;
  readonly cusip?: string | null;
  readonly exchange?: string | null;
  readonly indicationAcknowledgedByName?: string | null;
  readonly indicationAcknowledgedByEmail?: string | null;
  readonly indicationStatus: Types.IndicationStatus;
  readonly investorContactName?: string | null;
  readonly investorContactEmail?: string | null;
  readonly isin?: string | null;
  readonly offeringCurrency: string;
  readonly releasedByName?: string | null;
  readonly releasedByEmail?: string | null;
  readonly releasedAt?: string | null;
  readonly securityType: Types.OfferingSecurityType;
  readonly settlementCurrency: string;
  readonly settlementDate: string;
  readonly tradeDate?: string | null;
  readonly symbol?: string | null;
  readonly tradeStatus: Types.TradeReleaseStatus;
  readonly offerPrice: number;
};

export type TradeRelease_TradeGridRowsQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type TradeRelease_TradeGridRowsQuery = {
  readonly __typename?: 'Query';
  readonly tradeGridRows: ReadonlyArray<{
    readonly __typename?: 'TradeGridItem';
    readonly id: string;
    readonly tradeId?: string | null;
    readonly indicationId: string;
    readonly investorBankKey?: string | null;
    readonly investorCmgEntityKey: string;
    readonly investorFirmName: string;
    readonly allocationShares?: number | null;
    readonly sharesReleased?: number | null;
    readonly investorReplyStatus?: Types.InvestorReplyStatus | null;
    readonly investorReplyName?: string | null;
    readonly investorReplyEmail?: string | null;
    readonly indicationSubmittedBy: string;
    readonly indicationInvestorContactName?: string | null;
    readonly indicationInvestorContactEmail?: string | null;
    readonly statusDetails?: string | null;
    readonly allowedStatusTransitions: ReadonlyArray<Types.TradeReleaseGridStatusTransition>;
    readonly warnings: ReadonlyArray<Types.TradeReleaseGridWarning>;
    readonly sellingConcession?: number | null;
    readonly cusip?: string | null;
    readonly exchange?: string | null;
    readonly indicationAcknowledgedByName?: string | null;
    readonly indicationAcknowledgedByEmail?: string | null;
    readonly indicationStatus: Types.IndicationStatus;
    readonly investorContactName?: string | null;
    readonly investorContactEmail?: string | null;
    readonly isin?: string | null;
    readonly offeringCurrency: string;
    readonly releasedByName?: string | null;
    readonly releasedByEmail?: string | null;
    readonly releasedAt?: string | null;
    readonly securityType: Types.OfferingSecurityType;
    readonly settlementCurrency: string;
    readonly settlementDate: string;
    readonly tradeDate?: string | null;
    readonly symbol?: string | null;
    readonly tradeStatus: Types.TradeReleaseStatus;
    readonly offerPrice: number;
  }>;
};

export type TradeRelease_UpdateTradesStatusMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  input: Types.UpdateTradesStatusBatchInput;
}>;

export type TradeRelease_UpdateTradesStatusMutation = {
  readonly __typename?: 'Mutation';
  readonly updateTradesStatusBatch: ReadonlyArray<{
    readonly __typename?: 'TradeGridItem';
    readonly id: string;
    readonly tradeId?: string | null;
    readonly indicationId: string;
    readonly investorBankKey?: string | null;
    readonly investorCmgEntityKey: string;
    readonly investorFirmName: string;
    readonly allocationShares?: number | null;
    readonly sharesReleased?: number | null;
    readonly investorReplyStatus?: Types.InvestorReplyStatus | null;
    readonly investorReplyName?: string | null;
    readonly investorReplyEmail?: string | null;
    readonly indicationSubmittedBy: string;
    readonly indicationInvestorContactName?: string | null;
    readonly indicationInvestorContactEmail?: string | null;
    readonly statusDetails?: string | null;
    readonly allowedStatusTransitions: ReadonlyArray<Types.TradeReleaseGridStatusTransition>;
    readonly warnings: ReadonlyArray<Types.TradeReleaseGridWarning>;
    readonly sellingConcession?: number | null;
    readonly cusip?: string | null;
    readonly exchange?: string | null;
    readonly indicationAcknowledgedByName?: string | null;
    readonly indicationAcknowledgedByEmail?: string | null;
    readonly indicationStatus: Types.IndicationStatus;
    readonly investorContactName?: string | null;
    readonly investorContactEmail?: string | null;
    readonly isin?: string | null;
    readonly offeringCurrency: string;
    readonly releasedByName?: string | null;
    readonly releasedByEmail?: string | null;
    readonly releasedAt?: string | null;
    readonly securityType: Types.OfferingSecurityType;
    readonly settlementCurrency: string;
    readonly settlementDate: string;
    readonly tradeDate?: string | null;
    readonly symbol?: string | null;
    readonly tradeStatus: Types.TradeReleaseStatus;
    readonly offerPrice: number;
  }>;
};

export const TradeRelease_TradeGridItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TradeRelease_TradeGridItem' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TradeGridItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tradeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'indicationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorBankKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorCmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorFirmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allocationShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharesReleased' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorReplyStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorReplyName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorReplyEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'indicationSubmittedBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'indicationInvestorContactName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'indicationInvestorContactEmail' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'tradeStatus' },
            name: { kind: 'Name', value: 'status' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'statusDetails' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowedStatusTransitions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'warnings' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sellingConcession' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cusip' } },
          { kind: 'Field', name: { kind: 'Name', value: 'exchange' } },
          { kind: 'Field', name: { kind: 'Name', value: 'indicationAcknowledgedByName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'indicationAcknowledgedByEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'indicationStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorContactName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorContactEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringCurrency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'releasedByName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'releasedByEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'releasedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'securityType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'settlementCurrency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'settlementDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tradeDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'symbol' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'offerPrice' },
            name: { kind: 'Name', value: 'tradePrice' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const TradeRelease_ReleaseTradesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'TradeRelease_ReleaseTrades' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'indicationIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'releaseTradesBatch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'indicationIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'indicationIds' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TradeRelease_TradeGridItem' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TradeRelease_TradeGridItem' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TradeGridItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tradeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'indicationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorBankKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorCmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorFirmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allocationShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharesReleased' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorReplyStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorReplyName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorReplyEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'indicationSubmittedBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'indicationInvestorContactName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'indicationInvestorContactEmail' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'tradeStatus' },
            name: { kind: 'Name', value: 'status' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'statusDetails' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowedStatusTransitions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'warnings' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sellingConcession' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cusip' } },
          { kind: 'Field', name: { kind: 'Name', value: 'exchange' } },
          { kind: 'Field', name: { kind: 'Name', value: 'indicationAcknowledgedByName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'indicationAcknowledgedByEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'indicationStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorContactName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorContactEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringCurrency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'releasedByName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'releasedByEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'releasedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'securityType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'settlementCurrency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'settlementDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tradeDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'symbol' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'offerPrice' },
            name: { kind: 'Name', value: 'tradePrice' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type TradeRelease_ReleaseTradesMutationFn = Apollo.MutationFunction<
  TradeRelease_ReleaseTradesMutation,
  TradeRelease_ReleaseTradesMutationVariables
>;

/**
 * __useTradeRelease_ReleaseTradesMutation__
 *
 * To run a mutation, you first call `useTradeRelease_ReleaseTradesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTradeRelease_ReleaseTradesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tradeReleaseReleaseTradesMutation, { data, loading, error }] = useTradeRelease_ReleaseTradesMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      indicationIds: // value for 'indicationIds'
 *   },
 * });
 */
export function useTradeRelease_ReleaseTradesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TradeRelease_ReleaseTradesMutation,
    TradeRelease_ReleaseTradesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TradeRelease_ReleaseTradesMutation,
    TradeRelease_ReleaseTradesMutationVariables
  >(TradeRelease_ReleaseTradesDocument, options);
}
export type TradeRelease_ReleaseTradesMutationHookResult = ReturnType<
  typeof useTradeRelease_ReleaseTradesMutation
>;
export type TradeRelease_ReleaseTradesMutationResult =
  Apollo.MutationResult<TradeRelease_ReleaseTradesMutation>;
export type TradeRelease_ReleaseTradesMutationOptions = Apollo.BaseMutationOptions<
  TradeRelease_ReleaseTradesMutation,
  TradeRelease_ReleaseTradesMutationVariables
>;
export const TradeRelease_TradeGridRowsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'TradeRelease_TradeGridRows' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tradeGridRows' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TradeRelease_TradeGridItem' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TradeRelease_TradeGridItem' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TradeGridItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tradeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'indicationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorBankKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorCmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorFirmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allocationShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharesReleased' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorReplyStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorReplyName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorReplyEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'indicationSubmittedBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'indicationInvestorContactName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'indicationInvestorContactEmail' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'tradeStatus' },
            name: { kind: 'Name', value: 'status' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'statusDetails' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowedStatusTransitions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'warnings' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sellingConcession' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cusip' } },
          { kind: 'Field', name: { kind: 'Name', value: 'exchange' } },
          { kind: 'Field', name: { kind: 'Name', value: 'indicationAcknowledgedByName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'indicationAcknowledgedByEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'indicationStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorContactName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorContactEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringCurrency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'releasedByName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'releasedByEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'releasedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'securityType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'settlementCurrency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'settlementDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tradeDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'symbol' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'offerPrice' },
            name: { kind: 'Name', value: 'tradePrice' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useTradeRelease_TradeGridRowsQuery__
 *
 * To run a query within a React component, call `useTradeRelease_TradeGridRowsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTradeRelease_TradeGridRowsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTradeRelease_TradeGridRowsQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useTradeRelease_TradeGridRowsQuery(
  baseOptions: Apollo.QueryHookOptions<
    TradeRelease_TradeGridRowsQuery,
    TradeRelease_TradeGridRowsQueryVariables
  > &
    ({ variables: TradeRelease_TradeGridRowsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TradeRelease_TradeGridRowsQuery, TradeRelease_TradeGridRowsQueryVariables>(
    TradeRelease_TradeGridRowsDocument,
    options
  );
}
export function useTradeRelease_TradeGridRowsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TradeRelease_TradeGridRowsQuery,
    TradeRelease_TradeGridRowsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TradeRelease_TradeGridRowsQuery,
    TradeRelease_TradeGridRowsQueryVariables
  >(TradeRelease_TradeGridRowsDocument, options);
}
export function useTradeRelease_TradeGridRowsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        TradeRelease_TradeGridRowsQuery,
        TradeRelease_TradeGridRowsQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    TradeRelease_TradeGridRowsQuery,
    TradeRelease_TradeGridRowsQueryVariables
  >(TradeRelease_TradeGridRowsDocument, options);
}
export type TradeRelease_TradeGridRowsQueryHookResult = ReturnType<
  typeof useTradeRelease_TradeGridRowsQuery
>;
export type TradeRelease_TradeGridRowsLazyQueryHookResult = ReturnType<
  typeof useTradeRelease_TradeGridRowsLazyQuery
>;
export type TradeRelease_TradeGridRowsSuspenseQueryHookResult = ReturnType<
  typeof useTradeRelease_TradeGridRowsSuspenseQuery
>;
export type TradeRelease_TradeGridRowsQueryResult = Apollo.QueryResult<
  TradeRelease_TradeGridRowsQuery,
  TradeRelease_TradeGridRowsQueryVariables
>;
export const TradeRelease_UpdateTradesStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'TradeRelease_UpdateTradesStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateTradesStatusBatchInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateTradesStatusBatch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TradeRelease_TradeGridItem' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TradeRelease_TradeGridItem' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TradeGridItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tradeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'indicationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorBankKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorCmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorFirmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allocationShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharesReleased' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorReplyStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorReplyName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorReplyEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'indicationSubmittedBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'indicationInvestorContactName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'indicationInvestorContactEmail' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'tradeStatus' },
            name: { kind: 'Name', value: 'status' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'statusDetails' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowedStatusTransitions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'warnings' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sellingConcession' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cusip' } },
          { kind: 'Field', name: { kind: 'Name', value: 'exchange' } },
          { kind: 'Field', name: { kind: 'Name', value: 'indicationAcknowledgedByName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'indicationAcknowledgedByEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'indicationStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorContactName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorContactEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringCurrency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'releasedByName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'releasedByEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'releasedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'securityType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'settlementCurrency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'settlementDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tradeDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'symbol' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'offerPrice' },
            name: { kind: 'Name', value: 'tradePrice' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type TradeRelease_UpdateTradesStatusMutationFn = Apollo.MutationFunction<
  TradeRelease_UpdateTradesStatusMutation,
  TradeRelease_UpdateTradesStatusMutationVariables
>;

/**
 * __useTradeRelease_UpdateTradesStatusMutation__
 *
 * To run a mutation, you first call `useTradeRelease_UpdateTradesStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTradeRelease_UpdateTradesStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tradeReleaseUpdateTradesStatusMutation, { data, loading, error }] = useTradeRelease_UpdateTradesStatusMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTradeRelease_UpdateTradesStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TradeRelease_UpdateTradesStatusMutation,
    TradeRelease_UpdateTradesStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TradeRelease_UpdateTradesStatusMutation,
    TradeRelease_UpdateTradesStatusMutationVariables
  >(TradeRelease_UpdateTradesStatusDocument, options);
}
export type TradeRelease_UpdateTradesStatusMutationHookResult = ReturnType<
  typeof useTradeRelease_UpdateTradesStatusMutation
>;
export type TradeRelease_UpdateTradesStatusMutationResult =
  Apollo.MutationResult<TradeRelease_UpdateTradesStatusMutation>;
export type TradeRelease_UpdateTradesStatusMutationOptions = Apollo.BaseMutationOptions<
  TradeRelease_UpdateTradesStatusMutation,
  TradeRelease_UpdateTradesStatusMutationVariables
>;

import { makeMockResponse } from '../../../../../graphql/mock-response';

export const makeTradeRelease_TradeGridRowsMockResponse = makeMockResponse<
  TradeRelease_TradeGridRowsQueryVariables,
  TradeRelease_TradeGridRowsQuery
>(TradeRelease_TradeGridRowsDocument);
