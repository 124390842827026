/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MyOfferings_SharedDraftsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type MyOfferings_SharedDraftsQuery = {
  readonly __typename?: 'Query';
  readonly allSharedDrafts: {
    readonly __typename?: 'SharedDraftOfferingList';
    readonly data: ReadonlyArray<{
      readonly __typename?: 'SharedDraftOfferingLimited';
      readonly id: string;
      readonly type: Types.OfferingType;
      readonly issuerName: string;
      readonly pricingDate?: string | null;
      readonly pricingInstrumentStockSymbol?: string | null;
      readonly issuerSector?: Types.Sector | null;
      readonly priceRangeLow?: number | null;
      readonly priceRangeHigh?: number | null;
      readonly sizeInCurrency?: number | null;
      readonly sizeInShares?: number | null;
      readonly leftLeadFirmName?: string | null;
      readonly stage: Types.OfferingStage;
      readonly isObsolete: boolean;
      readonly pricingCurrencyCode: string;
    }>;
    readonly offeringTypes: ReadonlyArray<{
      readonly __typename?: 'SharedDraftOfferingLimited';
      readonly id: string;
      readonly type: Types.OfferingType;
      readonly pricingInstrumentCountryCode?: string | null;
    }>;
    readonly offeringStatuses: ReadonlyArray<{
      readonly __typename?: 'SharedDraftOfferingLimited';
      readonly id: string;
      readonly status: Types.OfferingStatus;
    }>;
    readonly issuerSectors: ReadonlyArray<{
      readonly __typename?: 'SharedDraftOfferingLimited';
      readonly id: string;
      readonly issuerSector?: Types.Sector | null;
    }>;
  };
};

export type MyOfferings_SharedOfferingsQueryVariables = Types.Exact<{
  skip?: Types.InputMaybe<Types.Scalars['Int']>;
  take?: Types.InputMaybe<Types.Scalars['Int']>;
}>;

export type MyOfferings_SharedOfferingsQuery = {
  readonly __typename?: 'Query';
  readonly sharedOfferings?: {
    readonly __typename?: 'SharedOfferingsCollectionSegment';
    readonly pageInfo: {
      readonly __typename?: 'CollectionSegmentInfo';
      readonly hasNextPage: boolean;
    };
    readonly items?: ReadonlyArray<{
      readonly __typename?: 'OfferingFull';
      readonly id: string;
      readonly type: Types.OfferingType;
      readonly status: Types.OfferingStatus;
      readonly stage: Types.OfferingStage;
      readonly isObsolete: boolean;
      readonly pricingInstrumentId?: string | null;
      readonly priceRangeLow?: number | null;
      readonly priceRangeHigh?: number | null;
      readonly pricingInstrumentStockSymbol?: string | null;
      readonly isExecutingOnPlatform: boolean;
      readonly pricingCurrencyCode: string;
      readonly sizeInCurrency?: number | null;
      readonly sizeInShares?: number | null;
      readonly issuer: {
        readonly __typename?: 'IssuerFull';
        readonly name: string;
        readonly sector?: Types.Sector | null;
      };
      readonly timing: { readonly __typename?: 'TimingFull'; readonly pricingDate?: string | null };
      readonly instruments: ReadonlyArray<{
        readonly __typename?: 'InstrumentFull';
        readonly id: string;
        readonly countryCode?: string | null;
      }>;
      readonly syndicate: {
        readonly __typename?: 'SyndicateFull';
        readonly managerResponsibilities: {
          readonly __typename?: 'ManagerResponsibilitiesFull';
          readonly leftLead?: string | null;
        };
        readonly managers: ReadonlyArray<{
          readonly __typename?: 'ManagerFull';
          readonly cmgEntityKey: string;
          readonly firmName: string;
          readonly firmNameAbbreviation?: string | null;
          readonly isJuniorRole?: boolean | null;
          readonly isParticipating: boolean;
          readonly role: Types.ManagerRole;
        }>;
      };
    }> | null;
  } | null;
};

export const MyOfferings_SharedDraftsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MyOfferings_SharedDrafts' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allSharedDrafts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'issuerName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'pricingDate' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pricingInstrumentStockSymbol' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'issuerSector' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'priceRangeLow' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'priceRangeHigh' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sizeInCurrency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sizeInShares' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'leftLeadFirmName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isObsolete' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'pricingCurrencyCode' } },
                    ],
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MyOfferings_Filters_SharedDraftOfferingListParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MyOfferings_Filters_SharedDraftOfferingListParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SharedDraftOfferingList' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'offeringTypes' },
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pricingInstrumentCountryCode' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'offeringStatuses' },
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'issuerSectors' },
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'issuerSector' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useMyOfferings_SharedDraftsQuery__
 *
 * To run a query within a React component, call `useMyOfferings_SharedDraftsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyOfferings_SharedDraftsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyOfferings_SharedDraftsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyOfferings_SharedDraftsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MyOfferings_SharedDraftsQuery,
    MyOfferings_SharedDraftsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MyOfferings_SharedDraftsQuery, MyOfferings_SharedDraftsQueryVariables>(
    MyOfferings_SharedDraftsDocument,
    options
  );
}
export function useMyOfferings_SharedDraftsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MyOfferings_SharedDraftsQuery,
    MyOfferings_SharedDraftsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MyOfferings_SharedDraftsQuery, MyOfferings_SharedDraftsQueryVariables>(
    MyOfferings_SharedDraftsDocument,
    options
  );
}
export function useMyOfferings_SharedDraftsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        MyOfferings_SharedDraftsQuery,
        MyOfferings_SharedDraftsQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    MyOfferings_SharedDraftsQuery,
    MyOfferings_SharedDraftsQueryVariables
  >(MyOfferings_SharedDraftsDocument, options);
}
export type MyOfferings_SharedDraftsQueryHookResult = ReturnType<
  typeof useMyOfferings_SharedDraftsQuery
>;
export type MyOfferings_SharedDraftsLazyQueryHookResult = ReturnType<
  typeof useMyOfferings_SharedDraftsLazyQuery
>;
export type MyOfferings_SharedDraftsSuspenseQueryHookResult = ReturnType<
  typeof useMyOfferings_SharedDraftsSuspenseQuery
>;
export type MyOfferings_SharedDraftsQueryResult = Apollo.QueryResult<
  MyOfferings_SharedDraftsQuery,
  MyOfferings_SharedDraftsQueryVariables
>;
export const MyOfferings_SharedOfferingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MyOfferings_SharedOfferings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'take' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sharedOfferings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'take' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'take' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'issuer' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sector' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timing' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'pricingDate' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'instruments' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'syndicate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'managerResponsibilities' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'leftLead' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'managers' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'firmNameAbbreviation' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'isJuniorRole' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'isParticipating' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'isObsolete' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'pricingInstrumentId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'priceRangeLow' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'priceRangeHigh' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pricingInstrumentStockSymbol' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'isExecutingOnPlatform' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'pricingCurrencyCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sizeInCurrency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sizeInShares' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useMyOfferings_SharedOfferingsQuery__
 *
 * To run a query within a React component, call `useMyOfferings_SharedOfferingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyOfferings_SharedOfferingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyOfferings_SharedOfferingsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useMyOfferings_SharedOfferingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MyOfferings_SharedOfferingsQuery,
    MyOfferings_SharedOfferingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MyOfferings_SharedOfferingsQuery,
    MyOfferings_SharedOfferingsQueryVariables
  >(MyOfferings_SharedOfferingsDocument, options);
}
export function useMyOfferings_SharedOfferingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MyOfferings_SharedOfferingsQuery,
    MyOfferings_SharedOfferingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MyOfferings_SharedOfferingsQuery,
    MyOfferings_SharedOfferingsQueryVariables
  >(MyOfferings_SharedOfferingsDocument, options);
}
export function useMyOfferings_SharedOfferingsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        MyOfferings_SharedOfferingsQuery,
        MyOfferings_SharedOfferingsQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    MyOfferings_SharedOfferingsQuery,
    MyOfferings_SharedOfferingsQueryVariables
  >(MyOfferings_SharedOfferingsDocument, options);
}
export type MyOfferings_SharedOfferingsQueryHookResult = ReturnType<
  typeof useMyOfferings_SharedOfferingsQuery
>;
export type MyOfferings_SharedOfferingsLazyQueryHookResult = ReturnType<
  typeof useMyOfferings_SharedOfferingsLazyQuery
>;
export type MyOfferings_SharedOfferingsSuspenseQueryHookResult = ReturnType<
  typeof useMyOfferings_SharedOfferingsSuspenseQuery
>;
export type MyOfferings_SharedOfferingsQueryResult = Apollo.QueryResult<
  MyOfferings_SharedOfferingsQuery,
  MyOfferings_SharedOfferingsQueryVariables
>;

import { makeMockResponse } from '../../../../../graphql/mock-response';

export const makeMyOfferings_SharedDraftsMockResponse = makeMockResponse<
  MyOfferings_SharedDraftsQueryVariables,
  MyOfferings_SharedDraftsQuery
>(MyOfferings_SharedDraftsDocument);

export const makeMyOfferings_SharedOfferingsMockResponse = makeMockResponse<
  MyOfferings_SharedOfferingsQueryVariables,
  MyOfferings_SharedOfferingsQuery
>(MyOfferings_SharedOfferingsDocument);
