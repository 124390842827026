import { useAuth } from '@cmg/auth';
import { ToastManager, UUID } from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import { FormikProvider, useFormik } from 'formik';
import React, { useCallback } from 'react';
import { RouteComponentProps, useHistory } from 'react-router';

import EmptyDistributionListWarning from '../../../../common/components/alerts/empty-distribution-list-warning/EmptyDistributionListWarning';
import SelectRecipientsDialog from '../../../../common/components/dialogs/select-recipients-dialog/SelectRecipientsDialog';
import Loading from '../../../../common/components/indicators/loading/Loading';
import ServerErrorsBanner from '../../../../common/components/indicators/server-error/ServerErrorsBanner';
import routeFactory from '../../../../common/util/routeFactory';
import { managerRoleLabels } from '../../../../types/domain/manager/constants';
import { FinraDistributionParticipantNameAlert } from '../../common/content/alerts/FinraDistributionParticipantNameAlert';
import { validateFilingWithFinraMembersContext } from '../../common/content/filing-common-fields-form/FilingCommonFieldsForm.model';
import { RecipientsInfoMessage } from '../../common/content/recipients-info-message/RecipientsInfoMessage';
import StockSymbolWarningBanner from '../../common/content/stock-symbol-warning/StockSymbolWarningBanner';
import CancelModal from '../../common/dialogs/cancel-amend-dialog/CancelModal';
import { BCCSenderSection } from '../../common/dialogs/send-notification-dialog/BCCSenderSection';
import { FilingConfirmationModal } from '../../common/dialogs/submit-filing-confirmation-dialog/FilingConfirmationModal';
import { useSubmitFilingConfirmationModal } from '../../common/dialogs/submit-filing-confirmation-dialog/hooks/useSubmitFilingConfirmationModal';
import { filingNames, getFilingFormLabel } from '../../common/filing-name-utils';
import RegulatoryFilingsFormHeader from '../../common/header/RegulatoryFilingsFormHeader';
import { FilingLayout } from '../../common/layouts/FilingLayout';
import { getAffiliatesAndDistributionParticipants } from '../../common/offering-affiliates-utils';
import { getRecipientsWithDefaultStatus } from '../../common/recipient-utils';
import useContactSupportDialog from '../../hooks/useContactSupportDialog';
import { useFilingRecipientsDialog } from '../../hooks/useFilingRecipientsDialog';
import { getRecipientsFromRecipientFragments } from '../../hooks/useFilingRecipientsDialog.model';
import { useRolodexCommonFilingQuery } from '../../hooks/useRolodexCommonFilingQuery';
import { SFormContent } from '../../RegulatoryFilingsRoute.styles';
import { MissingStabilizationActivitiesAlert } from './form/activity/MissingStabilizationActivitiesAlert';
import TNFilingForm from './form/TNFilingForm';
import {
  createInitialFormValues,
  formSchema,
  mapFormValuesToFiling,
  TNFilingValues,
} from './form/TNFilingForm.model';
import { TNInvalidFormAlert } from './form/TNInvalidFormAlert';
import {
  useRegulatoryFilings_SingleTnFilingQuery,
  useRegulatoryFilings_StabilizationTnActivitiesQuery,
  useRegulatoryFilings_TnFilingsQuery,
  useRegulatoryFilings_TnRestrictedPeriodQuery,
} from './graphql';
import { useSubmitTNFilingFormMutation } from './hooks/useSubmitTNFilingFormMutation';
import { createGetTNFormModalContentMessage } from './TNFilingFormRoute.model';

export type Props = RouteComponentProps<{ offeringId: UUID; filingId?: UUID }>;

const TNFilingFormRoute: React.FC<Props> = ({ match }) => {
  const { offeringId, filingId } = match.params;

  const [isCancelModalOpen, setCancelModalOpen] = React.useState<boolean>(false);

  const { push } = useHistory();
  const { oidcUserCmgEntityKey } = useAuth();
  const [shouldAddSenderToBcc, setShouldAddSenderToBcc] = React.useState(false);

  // queries
  const {
    data: filingDetailData,
    error: filingDetailError,
    loading: isLoadingFilingDetail,
  } = useRegulatoryFilings_SingleTnFilingQuery({
    variables: { filingId: filingId!, offeringId },
    skip: !filingId,
    fetchPolicy: 'cache-and-network',
  });

  const {
    data: allFilings,
    error: allFilingsError,
    loading: allFilingsLoading,
  } = useRegulatoryFilings_TnFilingsQuery({
    variables: { offeringId },
  });

  const {
    data: stabilizationActivitiesData,
    error: stabilizationActivitiesError,
    loading: stabilizationActivitiesLoading,
  } = useRegulatoryFilings_StabilizationTnActivitiesQuery({
    variables: { offeringId },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  });

  const {
    data: tnRestrictedPeriodData,
    error: tnRestrictedPeriodError,
    loading: tnRestrictedPeriodLoading,
  } = useRegulatoryFilings_TnRestrictedPeriodQuery({
    variables: { offeringId },
    skip: !filingId,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  }); // No need to fetch if amending filing

  const {
    data: filingConfigData,
    error: filingConfigError,
    loading: isLoadingFilingConfig,
  } = useRolodexCommonFilingQuery({
    offeringId,
    cmgEntityKey: oidcUserCmgEntityKey!,
  });

  // mutations
  const [submitMutation, { loading: isSubmitting, error: submitError }] =
    useSubmitTNFilingFormMutation();

  const { recipients, notificationDialogRecipients } = React.useMemo(
    () => ({
      recipients: getRecipientsWithDefaultStatus(filingConfigData?.recipients),
      notificationDialogRecipients: getRecipientsFromRecipientFragments(
        filingConfigData?.recipients
      ),
    }),
    [filingConfigData?.recipients]
  );

  const {
    selectableRecipients,
    selectedEnabledRecipientKeys,
    handleRecipientSelectionChange,
    isOpen: isRecipientsDialogOpen,
    open: openRecipientsDialog,
    close: closeRecipientsDialog,
  } = useFilingRecipientsDialog({
    recipients: notificationDialogRecipients,
  });

  const { handleContactSupport } = useContactSupportDialog({
    recipients,
    onOpen: closeRecipientsDialog,
    onClose: useCallback(
      () => (isRecipientsDialogOpen ? openRecipientsDialog() : undefined),
      [isRecipientsDialogOpen, openRecipientsDialog]
    ),
  });

  const initalRestrictedPeriod = tnRestrictedPeriodData?.lastTnRestrictedPeriod?.restrictedPeriod;

  const affiliatesAndDistributionParticipants = React.useMemo(() => {
    return getAffiliatesAndDistributionParticipants(
      filingConfigData?.offering.syndicate.managers ?? []
    );
  }, [filingConfigData?.offering.syndicate.managers]);

  // form config
  const formik = useFormik<TNFilingValues>({
    initialValues: createInitialFormValues({
      offeringType: filingConfigData?.filingOfferingDetail.type,
      underwriter: filingConfigData?.underwriter,
      filing: filingDetailData?.offering.regulatoryFilings.TNFiling,
      lastCommonValues: filingConfigData?.filingLastCommonValues,
      offeringDetail: filingConfigData?.filingOfferingDetail,
      stabilizationActivities:
        stabilizationActivitiesData?.offering.regulatoryFilings.stabilizationTNActivities
          .__typename === 'StabilizationTNFilingActivityWrapper'
          ? stabilizationActivitiesData?.offering.regulatoryFilings.stabilizationTNActivities
              .activities
          : [],
      restrictedPeriod: initalRestrictedPeriod,
      affiliatesAndDistributionParticipants,
    }),
    enableReinitialize: true,
    validateOnMount: true,
    validateOnChange: true,
    validateOnBlur: false,
    validate: validateFilingWithFinraMembersContext(formSchema),
    onSubmit: async values => {
      closeRecipientsDialog();

      try {
        const { data: submitData } = await submitMutation({
          variables: {
            offeringId,
            input: mapFormValuesToFiling(
              values,
              selectedEnabledRecipientKeys,
              shouldAddSenderToBcc
            ),
          },
        });

        const filingId = submitData!.submitTNFiling.id;
        push(
          routeFactory.regulatoryFilingsTNFilingDetail.getUrlPath({
            offeringId,
            filingId,
          })
        );

        ToastManager.success('Successfully submitted TN Form');
      } catch {
        ToastManager.error('Failed to submit TN filing. Please try again');
      }
    },
  });

  const { submitForm, isValid } = formik;

  const openCancelModal = () => {
    setCancelModalOpen(true);
  };

  const closeCancelModal = () => {
    setCancelModalOpen(false);
  };

  const getModalContentMessage = React.useMemo(
    () => createGetTNFormModalContentMessage(!!stabilizationActivitiesError),
    [stabilizationActivitiesError]
  );

  const [confirmationModalProps, { openConfirmationModal }] = useSubmitFilingConfirmationModal({
    filingName: filingNames.TN.full,
    getContentMessage: getModalContentMessage,
    isAmending: !!filingId,
    isValid,
    onSubmit: async () => {
      if (notificationDialogRecipients.length > 0) {
        openRecipientsDialog();
      } else {
        await submitForm();
      }
    },
  });

  const handleAmendCancel = () => {
    push(
      routeFactory.regulatoryFilingsTNFilingDetail.getUrlPath({
        offeringId,
        filingId: filingId!,
      })
    );
    closeCancelModal();
  };

  if (
    isLoadingFilingConfig ||
    isSubmitting ||
    isLoadingFilingDetail ||
    allFilingsLoading ||
    tnRestrictedPeriodLoading
  ) {
    return <Loading />;
  }

  const error =
    filingConfigError || filingDetailError || allFilingsError || stabilizationActivitiesError;

  if (error) {
    return <ServerErrorsBanner error={error} />;
  }

  const hasNoStabilizationActivities =
    stabilizationActivitiesData?.offering.regulatoryFilings.stabilizationTNActivities.__typename ===
    'ServiceError';

  const isStockSymbolWarningVisible =
    !filingConfigData?.filingOfferingDetail.pricingInstrumentSymbol;
  const stockSymbols = filingConfigData?.filingOfferingDetail.stockSymbols ?? [];

  return (
    <FilingLayout
      testId={xcSelectors.tnFilingFormScreen.testId}
      recipients={recipients}
      offeringId={offeringId}
    >
      {tnRestrictedPeriodError && <ServerErrorsBanner error={tnRestrictedPeriodError} />}
      <FormikProvider value={formik}>
        <RegulatoryFilingsFormHeader
          offeringId={offeringId}
          title={getFilingFormLabel('TN')}
          isAmended={!!allFilings?.offering.regulatoryFilings.TNFilings.length}
          onSubmitFiling={openConfirmationModal}
          onCancel={openCancelModal}
        />
        <SFormContent>
          <FinraDistributionParticipantNameAlert
            distributionParticipants={
              affiliatesAndDistributionParticipants?.distributionParticipants
            }
          />
          <EmptyDistributionListWarning
            withMargin
            recipients={recipients}
            onContactSupport={handleContactSupport}
          />
          {hasNoStabilizationActivities && (
            <MissingStabilizationActivitiesAlert variant="in-main-form" />
          )}
          {isStockSymbolWarningVisible && <StockSymbolWarningBanner offeringId={offeringId} />}
          {submitError && <ServerErrorsBanner error={submitError} />}
          <TNInvalidFormAlert />
          <TNFilingForm
            stockSymbols={stockSymbols}
            offeringId={offeringId}
            hasNoStabilizationActivities={hasNoStabilizationActivities}
            stabilizationActivitiesLoading={stabilizationActivitiesLoading}
          />
        </SFormContent>
        <FilingConfirmationModal {...confirmationModalProps} />
        {isCancelModalOpen && (
          <CancelModal onCancel={closeCancelModal} onConfirm={handleAmendCancel} />
        )}
        <SelectRecipientsDialog
          open={isRecipientsDialogOpen}
          recipients={selectableRecipients}
          onSelectionChange={handleRecipientSelectionChange}
          onCancel={() => closeRecipientsDialog()}
          onSubmit={submitForm}
          submitButtonLabel="Confirm And Submit Filing"
          message={<RecipientsInfoMessage />}
          onContactSupport={handleContactSupport}
          getManagerRoleLabel={role => managerRoleLabels[role]}
          ccmyfirm={
            <BCCSenderSection
              offeringId={offeringId}
              canSendNotification
              onChange={() => setShouldAddSenderToBcc(!shouldAddSenderToBcc)}
              shouldAddSenderToBcc={shouldAddSenderToBcc}
              onContactSupport={handleContactSupport}
            />
          }
        />
      </FormikProvider>
    </FilingLayout>
  );
};

export default TNFilingFormRoute;
