import { useAuth } from '@cmg/auth';
import { UUID } from '@cmg/common';
import { useMemo } from 'react';
import { useRouteMatch } from 'react-router';

import { WithFirmOrOwnerCmgEntityKey } from '../common/content/filing-common-fields-form/FilingCommonFieldsForm.model';
import {
  RegulatoryFilings_RolodexSyndicateManagerPartsFragment,
  useRegulatoryFilings_RolodexCommonFilingQuery,
} from '../graphql/__generated__';

export const distributionParticipantsGroupName = 'Distribution Participants';
export const affiliatesGroupNamePrefix = 'Affiliated Purchasers - ';

export const getAffiliatesGroupName = (groupManagerName: string) =>
  `${affiliatesGroupNamePrefix}${groupManagerName}`;

export const getManagerName = ({
  firmName,
  firmCmgEntityKey,
  ownerCmgEntityKey,
  currentTenantCmgEntityKey,
  managerNameMap,
  managerNames,
}: {
  firmName: string;
  firmCmgEntityKey: string | null;
  ownerCmgEntityKey: string | null;
  currentTenantCmgEntityKey: string;
  managerNameMap: Map<string, string>;
  managerNames: string[];
}): string | undefined => {
  // member firm distribution participants will have cmgEntityKey
  if (firmCmgEntityKey) return distributionParticipantsGroupName;

  let groupManagerName: string | undefined;

  if (ownerCmgEntityKey) {
    groupManagerName = managerNameMap.get(ownerCmgEntityKey);
  }

  if (groupManagerName) return getAffiliatesGroupName(groupManagerName);

  // Note: This is necessary to distinguish between distribution participants
  // and non-member firms that belong to current tenant
  // since ownerCmgEntityKey is null in both cases
  if (managerNames.includes(firmName)) {
    return distributionParticipantsGroupName;
  }

  groupManagerName = managerNameMap.get(currentTenantCmgEntityKey);

  if (groupManagerName) return getAffiliatesGroupName(groupManagerName);

  return undefined;
};

type AffiliateCommonFields = WithFirmOrOwnerCmgEntityKey & {
  firmName: string;
};

type ManagerNameCommonOptions = {
  affiliateManagers:
    | ReadonlyArray<RegulatoryFilings_RolodexSyndicateManagerPartsFragment>
    | undefined;
  isMemberFirmGrid: boolean;
};

export const getManagerNameMap = ({
  affiliateManagers = [],
  isMemberFirmGrid,
}: ManagerNameCommonOptions): Map<string, string> => {
  const mappedManagers = new Map<string, string>();

  const resolveFinraName = (manager: (typeof affiliateManagers)[number]) => {
    if (manager.finraInfo?.name) return manager.finraInfo.name;
    if (manager.finraInfo?.crdNumber) return `CRD #${manager.finraInfo.crdNumber}`;

    // this will be the case for non-member firm managers
    // but they will not be displayed in the grid
    // because this is only for member firm grid
    return '-';
  };

  affiliateManagers.forEach(m => {
    const managerName = isMemberFirmGrid ? resolveFinraName(m) : m.firmName;
    mappedManagers.set(m.cmgEntityKey, managerName);
  });

  return mappedManagers;
};

export const useGroupAffiliates = <TData extends AffiliateCommonFields>(
  affiliates: ReadonlyArray<TData>,
  isMemberFirmGrid = false
): [string, TData[]][] | null => {
  const {
    params: { offeringId },
  } = useRouteMatch<{ offeringId: UUID }>();

  const { oidcUserCmgEntityKey } = useAuth();
  const { data } = useRegulatoryFilings_RolodexCommonFilingQuery({
    variables: { offeringId, cmgEntityKey: oidcUserCmgEntityKey! },
    fetchPolicy: 'cache-first',
  });

  const managerNameMap = useMemo(() => {
    return getManagerNameMap({
      affiliateManagers: data?.offering.syndicate.managers,
      isMemberFirmGrid,
    });
  }, [isMemberFirmGrid, data?.offering.syndicate.managers]);

  const managerNames = useMemo(() => {
    return Array.from(managerNameMap.values());
  }, [managerNameMap]);

  const groupedAffiliatesAsMap = useMemo(() => {
    const affiliatesByManagerMap = new Map<string, TData[]>();

    if (!affiliates?.length || !managerNameMap.size) return affiliatesByManagerMap;

    for (const affiliate of affiliates) {
      const groupName = getManagerName({
        managerNameMap,
        firmCmgEntityKey: affiliate.cmgEntityKey ?? null,
        firmName: affiliate.firmName,
        ownerCmgEntityKey: affiliate.ownerCmgEntityKey ?? null,
        currentTenantCmgEntityKey: oidcUserCmgEntityKey!,
        managerNames,
      });

      if (!groupName) continue;

      if (affiliatesByManagerMap.has(groupName)) {
        affiliatesByManagerMap.get(groupName)?.push(affiliate);
      } else {
        affiliatesByManagerMap.set(groupName, [affiliate]);
      }
    }

    return affiliatesByManagerMap;
  }, [affiliates, managerNameMap, oidcUserCmgEntityKey, managerNames]);

  return useMemo(() => {
    if (!groupedAffiliatesAsMap.size) return null;

    const formattedManagerNames = managerNames.map(m => getAffiliatesGroupName(m));
    const groupNames = [distributionParticipantsGroupName, ...formattedManagerNames];

    return Array.from(groupedAffiliatesAsMap.entries()).sort(
      (a, b) => groupNames.indexOf(a[0]) - groupNames.indexOf(b[0])
    );
  }, [groupedAffiliatesAsMap, managerNames]);
};
