/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FinalSettlement_StabilizationQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type FinalSettlement_StabilizationQuery = {
  readonly __typename?: 'Query';
  readonly stabilization: {
    readonly __typename?: 'Stabilization';
    readonly id: string;
    readonly notes: string;
    readonly trades: ReadonlyArray<{
      readonly __typename?: 'StabilizationTrade';
      readonly shares: number;
      readonly date: string;
      readonly averagePrice?: number | null;
      readonly activityType?: Types.TradeActivityType | null;
      readonly type: Types.TradeType;
    }>;
  };
};

export type FinalSettlement_StabilizationInformationQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type FinalSettlement_StabilizationInformationQuery = {
  readonly __typename?: 'Query';
  readonly stabilizationInformation: {
    readonly __typename?: 'StabilizationInformation';
    readonly id: string;
    readonly additionalShortPosition: number;
    readonly sellingConcession?: number | null;
    readonly overallotmentExcercised?: number | null;
    readonly offeringPrice: number;
    readonly finalAuthorizedOverallotment: number;
  };
};

export type FinalSettlement_StabilizationInformationPartsFragment = {
  readonly __typename?: 'StabilizationInformation';
  readonly id: string;
  readonly additionalShortPosition: number;
  readonly sellingConcession?: number | null;
  readonly overallotmentExcercised?: number | null;
  readonly offeringPrice: number;
  readonly finalAuthorizedOverallotment: number;
};

export type FinalSettlement_StabilizationPartsFragment = {
  readonly __typename?: 'Stabilization';
  readonly id: string;
  readonly notes: string;
  readonly trades: ReadonlyArray<{
    readonly __typename?: 'StabilizationTrade';
    readonly shares: number;
    readonly date: string;
    readonly averagePrice?: number | null;
    readonly activityType?: Types.TradeActivityType | null;
    readonly type: Types.TradeType;
  }>;
};

export type FinalSettlement_StabilizationTradePartsFragment = {
  readonly __typename?: 'StabilizationTrade';
  readonly shares: number;
  readonly date: string;
  readonly averagePrice?: number | null;
  readonly activityType?: Types.TradeActivityType | null;
  readonly type: Types.TradeType;
};

export const FinalSettlement_StabilizationInformationPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FinalSettlement_StabilizationInformationParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StabilizationInformation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'additionalShortPosition' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sellingConcession' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overallotmentExcercised' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'finalAuthorizedOverallotment' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const FinalSettlement_StabilizationTradePartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FinalSettlement_StabilizationTradeParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'StabilizationTrade' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'averagePrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activityType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const FinalSettlement_StabilizationPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FinalSettlement_StabilizationParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Stabilization' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'trades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FinalSettlement_StabilizationTradeParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FinalSettlement_StabilizationTradeParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'StabilizationTrade' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'averagePrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activityType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const FinalSettlement_StabilizationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FinalSettlement_Stabilization' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stabilization' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FinalSettlement_StabilizationParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FinalSettlement_StabilizationParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Stabilization' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'trades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FinalSettlement_StabilizationTradeParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FinalSettlement_StabilizationTradeParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'StabilizationTrade' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'averagePrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activityType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useFinalSettlement_StabilizationQuery__
 *
 * To run a query within a React component, call `useFinalSettlement_StabilizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useFinalSettlement_StabilizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFinalSettlement_StabilizationQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useFinalSettlement_StabilizationQuery(
  baseOptions: Apollo.QueryHookOptions<
    FinalSettlement_StabilizationQuery,
    FinalSettlement_StabilizationQueryVariables
  > &
    ({ variables: FinalSettlement_StabilizationQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FinalSettlement_StabilizationQuery,
    FinalSettlement_StabilizationQueryVariables
  >(FinalSettlement_StabilizationDocument, options);
}
export function useFinalSettlement_StabilizationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FinalSettlement_StabilizationQuery,
    FinalSettlement_StabilizationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FinalSettlement_StabilizationQuery,
    FinalSettlement_StabilizationQueryVariables
  >(FinalSettlement_StabilizationDocument, options);
}
export function useFinalSettlement_StabilizationSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FinalSettlement_StabilizationQuery,
        FinalSettlement_StabilizationQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FinalSettlement_StabilizationQuery,
    FinalSettlement_StabilizationQueryVariables
  >(FinalSettlement_StabilizationDocument, options);
}
export type FinalSettlement_StabilizationQueryHookResult = ReturnType<
  typeof useFinalSettlement_StabilizationQuery
>;
export type FinalSettlement_StabilizationLazyQueryHookResult = ReturnType<
  typeof useFinalSettlement_StabilizationLazyQuery
>;
export type FinalSettlement_StabilizationSuspenseQueryHookResult = ReturnType<
  typeof useFinalSettlement_StabilizationSuspenseQuery
>;
export type FinalSettlement_StabilizationQueryResult = Apollo.QueryResult<
  FinalSettlement_StabilizationQuery,
  FinalSettlement_StabilizationQueryVariables
>;
export const FinalSettlement_StabilizationInformationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FinalSettlement_StabilizationInformation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stabilizationInformation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FinalSettlement_StabilizationInformationParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FinalSettlement_StabilizationInformationParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StabilizationInformation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'additionalShortPosition' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sellingConcession' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overallotmentExcercised' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'finalAuthorizedOverallotment' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useFinalSettlement_StabilizationInformationQuery__
 *
 * To run a query within a React component, call `useFinalSettlement_StabilizationInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useFinalSettlement_StabilizationInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFinalSettlement_StabilizationInformationQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useFinalSettlement_StabilizationInformationQuery(
  baseOptions: Apollo.QueryHookOptions<
    FinalSettlement_StabilizationInformationQuery,
    FinalSettlement_StabilizationInformationQueryVariables
  > &
    (
      | { variables: FinalSettlement_StabilizationInformationQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FinalSettlement_StabilizationInformationQuery,
    FinalSettlement_StabilizationInformationQueryVariables
  >(FinalSettlement_StabilizationInformationDocument, options);
}
export function useFinalSettlement_StabilizationInformationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FinalSettlement_StabilizationInformationQuery,
    FinalSettlement_StabilizationInformationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FinalSettlement_StabilizationInformationQuery,
    FinalSettlement_StabilizationInformationQueryVariables
  >(FinalSettlement_StabilizationInformationDocument, options);
}
export function useFinalSettlement_StabilizationInformationSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FinalSettlement_StabilizationInformationQuery,
        FinalSettlement_StabilizationInformationQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FinalSettlement_StabilizationInformationQuery,
    FinalSettlement_StabilizationInformationQueryVariables
  >(FinalSettlement_StabilizationInformationDocument, options);
}
export type FinalSettlement_StabilizationInformationQueryHookResult = ReturnType<
  typeof useFinalSettlement_StabilizationInformationQuery
>;
export type FinalSettlement_StabilizationInformationLazyQueryHookResult = ReturnType<
  typeof useFinalSettlement_StabilizationInformationLazyQuery
>;
export type FinalSettlement_StabilizationInformationSuspenseQueryHookResult = ReturnType<
  typeof useFinalSettlement_StabilizationInformationSuspenseQuery
>;
export type FinalSettlement_StabilizationInformationQueryResult = Apollo.QueryResult<
  FinalSettlement_StabilizationInformationQuery,
  FinalSettlement_StabilizationInformationQueryVariables
>;

import { makeMockResponse } from '../../../../../graphql/mock-response';

export const makeFinalSettlement_StabilizationMockResponse = makeMockResponse<
  FinalSettlement_StabilizationQueryVariables,
  FinalSettlement_StabilizationQuery
>(FinalSettlement_StabilizationDocument);

export const makeFinalSettlement_StabilizationInformationMockResponse = makeMockResponse<
  FinalSettlement_StabilizationInformationQueryVariables,
  FinalSettlement_StabilizationInformationQuery
>(FinalSettlement_StabilizationInformationDocument);
