import { ActionPanelSection, Button, LoadingButton, Stack } from '@cmg/design-system';
import { Form, FormikProvider, useFormik } from 'formik';
import React from 'react';

import { InstitutionalIndicationOrderType } from '../../../../../../../../graphql';
import { useIndicationActivitySyndicateContext } from '../../../../context/IndicationActivitySyndicateContext';
import IndicationSectionForm from '../../common/indication-section-form/IndicationSectionForm';
import IndicationStatus from '../../common/indication-status/IndicationStatus';
import {
  IndicationFormValues,
  validationSchema,
} from '../../common/utils/IndicationSectionValidation';
import { createInitialValues as createInitialValuesPass } from '../../sales-and-trading/indication-section-create-view/IndicationSectionCreateView.model';
import PassInstitutionalIndicationDialog from '../common/pass-institutional-indication-dialog/PassInstitutionalIndicationDialog';
import { useUpdateInstitutionalIndication } from './hooks/useUpdateInstitutionalIndication';
import { createInitialValues } from './IndicationSectionEditView.model';

export type Props = Readonly<{
  onFinishEditing: () => void;
  hasAllocationRanking: boolean;
}>;

const IndicationSectionEditView: React.FC<Props> = ({ onFinishEditing, hasAllocationRanking }) => {
  const { offeringId, basicPublishedOffering } = useIndicationActivitySyndicateContext();
  const offering = basicPublishedOffering!;
  const indication = offering.orderBook.institutionalIndication!;

  const [isPassDialogOpen, setIsPassDialogOpen] = React.useState(false);

  const [updateIndication] = useUpdateInstitutionalIndication(
    offeringId,
    indication,
    onFinishEditing
  );

  const formik = useFormik<IndicationFormValues>({
    initialValues:
      indication.type === InstitutionalIndicationOrderType.Pass
        ? createInitialValuesPass(offering)
        : createInitialValues(indication),
    validationSchema,
    onSubmit: updateIndication,
  });
  const { isSubmitting, handleSubmit } = formik;

  const isPassButtonVisible = indication.type !== InstitutionalIndicationOrderType.Pass;

  return (
    <ActionPanelSection
      data-testid="edit-indication-view"
      title="Indication"
      titleItems={<IndicationStatus status={indication.status} />}
      actions={[
        ...(isPassButtonVisible
          ? [
              <Button
                key="pass"
                variant="outlined"
                disabled={hasAllocationRanking || isSubmitting}
                onClick={() => setIsPassDialogOpen(true)}
              >
                Pass
              </Button>,
            ]
          : []),
        <Button key="discard" variant="outlined" disabled={isSubmitting} onClick={onFinishEditing}>
          Discard Changes
        </Button>,
        <LoadingButton
          key="submit"
          variant="contained"
          loading={isSubmitting}
          onClick={() => handleSubmit()}
        >
          Save
        </LoadingButton>,
      ]}
    >
      <Stack gap={1}>
        <FormikProvider value={formik}>
          <Form>
            <IndicationSectionForm
              tranches={offering.orderEntryProfile.tranches}
              instruments={offering.orderEntryProfile.instruments}
              offeringType={offering.type}
              pricingCurrencyCode={offering.pricingCurrencyCode}
              canEnterTranche={indication.type === InstitutionalIndicationOrderType.Pass}
              securityTypeDisplayName={offering.securityTypeDisplayName!}
            />
          </Form>
        </FormikProvider>

        <PassInstitutionalIndicationDialog
          isOpen={isPassDialogOpen}
          onClose={() => setIsPassDialogOpen(false)}
          onFinishEditing={onFinishEditing}
          offeringId={offeringId}
          indicationId={indication.id}
          indicationStatus={indication.status}
        />
      </Stack>
    </ActionPanelSection>
  );
};

export default IndicationSectionEditView;
