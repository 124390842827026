/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SyndicateWires_CreateSellingGroupInvitationWireMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  payload: Types.CreateSellingGroupInvitationWireInput;
}>;

export type SyndicateWires_CreateSellingGroupInvitationWireMutation = {
  readonly __typename?: 'Mutation';
  readonly createSellingGroupInvitationWire:
    | {
        readonly __typename?: 'SellingGroupInvitationWire';
        readonly id: string;
        readonly disclaimer?: string | null;
        readonly masterSdaDate: string;
        readonly status: Types.WireStatus;
        readonly createdAt: string;
        readonly sender: string;
        readonly wireMessages: ReadonlyArray<{
          readonly __typename?: 'WireMessage';
          readonly id: string;
          readonly emailAddresses: ReadonlyArray<string>;
          readonly expiredAt?: string | null;
          readonly respondedAt?: string | null;
          readonly responseStatus?: Types.SentWireResponseStatus | null;
          readonly wireMessageAndResponseStatus: Types.WireMessageAndResponseStatus;
          readonly sentAt?: string | null;
          readonly htmlBody: string;
          readonly firmSnapshot?: {
            readonly __typename?: 'FirmSnapshot';
            readonly cmgEntityKey: string;
            readonly firmName: string;
            readonly offeringManagerRole?: Types.SyndicateWiresManagerRole | null;
          } | null;
        }>;
      }
    | {
        readonly __typename: 'ServiceError';
        readonly code: Types.ServiceErrorCode;
        readonly message: string;
        readonly target?: string | null;
        readonly details?: ReadonlyArray<{
          readonly __typename: 'ErrorDetail';
          readonly code?: string | null;
          readonly message?: string | null;
          readonly target?: string | null;
        }> | null;
      };
};

export type SyndicateWires_DeleteSellingGroupInvitationWireMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  syndicateWireId: Types.Scalars['UUID'];
}>;

export type SyndicateWires_DeleteSellingGroupInvitationWireMutation = {
  readonly __typename?: 'Mutation';
  readonly deleteSellingGroupInvitationWire:
    | { readonly __typename?: 'DeleteWire'; readonly id: string }
    | {
        readonly __typename: 'ServiceError';
        readonly code: Types.ServiceErrorCode;
        readonly message: string;
        readonly target?: string | null;
        readonly details?: ReadonlyArray<{
          readonly __typename: 'ErrorDetail';
          readonly code?: string | null;
          readonly message?: string | null;
          readonly target?: string | null;
        }> | null;
      };
};

export type SyndicateWires_MasterSdaDateQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type SyndicateWires_MasterSdaDateQuery = {
  readonly __typename?: 'Query';
  readonly syndicateWires: {
    readonly __typename?: 'SyndicateWires';
    readonly masterSdaDate?: string | null;
  };
};

export type SyndicateWires_SellingGroupInvitationWireDetailsQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  syndicateWireId: Types.Scalars['UUID'];
}>;

export type SyndicateWires_SellingGroupInvitationWireDetailsQuery = {
  readonly __typename?: 'Query';
  readonly offering: {
    readonly __typename?: 'Offering';
    readonly id: string;
    readonly stage: Types.OfferingStage;
  };
  readonly sellingGroupInvitationWireDetails: {
    readonly __typename?: 'SellingGroupInvitationWire';
    readonly id: string;
    readonly disclaimer?: string | null;
    readonly masterSdaDate: string;
    readonly status: Types.WireStatus;
    readonly createdAt: string;
    readonly sender: string;
    readonly wireMessages: ReadonlyArray<{
      readonly __typename?: 'WireMessage';
      readonly id: string;
      readonly emailAddresses: ReadonlyArray<string>;
      readonly expiredAt?: string | null;
      readonly respondedAt?: string | null;
      readonly responseStatus?: Types.SentWireResponseStatus | null;
      readonly wireMessageAndResponseStatus: Types.WireMessageAndResponseStatus;
      readonly sentAt?: string | null;
      readonly htmlBody: string;
      readonly firmSnapshot?: {
        readonly __typename?: 'FirmSnapshot';
        readonly cmgEntityKey: string;
        readonly firmName: string;
        readonly offeringManagerRole?: Types.SyndicateWiresManagerRole | null;
      } | null;
    }>;
  };
};

export type SyndicateWires_SellingGroupInvitationWirePartsFragment = {
  readonly __typename?: 'SellingGroupInvitationWire';
  readonly id: string;
  readonly disclaimer?: string | null;
  readonly masterSdaDate: string;
  readonly status: Types.WireStatus;
  readonly createdAt: string;
  readonly sender: string;
  readonly wireMessages: ReadonlyArray<{
    readonly __typename?: 'WireMessage';
    readonly id: string;
    readonly emailAddresses: ReadonlyArray<string>;
    readonly expiredAt?: string | null;
    readonly respondedAt?: string | null;
    readonly responseStatus?: Types.SentWireResponseStatus | null;
    readonly wireMessageAndResponseStatus: Types.WireMessageAndResponseStatus;
    readonly sentAt?: string | null;
    readonly htmlBody: string;
    readonly firmSnapshot?: {
      readonly __typename?: 'FirmSnapshot';
      readonly cmgEntityKey: string;
      readonly firmName: string;
      readonly offeringManagerRole?: Types.SyndicateWiresManagerRole | null;
    } | null;
  }>;
};

export type SyndicateWires_SellingGroupInvitationWirePreviewQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  syndicateWireId?: Types.InputMaybe<Types.Scalars['UUID']>;
  cmgEntityKey?: Types.InputMaybe<Types.Scalars['String']>;
}>;

export type SyndicateWires_SellingGroupInvitationWirePreviewQuery = {
  readonly __typename?: 'Query';
  readonly sellingGroupInvitationWirePreview: {
    readonly __typename?: 'WirePreview';
    readonly htmlContent: string;
  };
};

export type SyndicateWires_SellingGroupInvitationWireValidationPartsFragment = {
  readonly __typename?: 'SellingGroupInvitationWireValidation';
  readonly orderBookErrors: ReadonlyArray<Types.OrderBookErrorType>;
  readonly offeringErrors: ReadonlyArray<Types.SellingGroupInvitationWireValidationOfferingErrors>;
  readonly settlementErrors: ReadonlyArray<Types.SettlementErrorType>;
  readonly managers: ReadonlyArray<{
    readonly __typename?: 'ManagerValidation';
    readonly cmgEntityKey: string;
    readonly fields: ReadonlyArray<Types.ManagerErrorType>;
  }>;
  readonly sender: {
    readonly __typename?: 'ManagerValidation';
    readonly cmgEntityKey: string;
    readonly fields: ReadonlyArray<Types.ManagerErrorType>;
  };
};

export type SyndicateWires_SellingGroupInvitationWireValidationQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type SyndicateWires_SellingGroupInvitationWireValidationQuery = {
  readonly __typename?: 'Query';
  readonly sellingGroupInvitationWireValidation: {
    readonly __typename?: 'SellingGroupInvitationWireValidation';
    readonly orderBookErrors: ReadonlyArray<Types.OrderBookErrorType>;
    readonly offeringErrors: ReadonlyArray<Types.SellingGroupInvitationWireValidationOfferingErrors>;
    readonly settlementErrors: ReadonlyArray<Types.SettlementErrorType>;
    readonly managers: ReadonlyArray<{
      readonly __typename?: 'ManagerValidation';
      readonly cmgEntityKey: string;
      readonly fields: ReadonlyArray<Types.ManagerErrorType>;
    }>;
    readonly sender: {
      readonly __typename?: 'ManagerValidation';
      readonly cmgEntityKey: string;
      readonly fields: ReadonlyArray<Types.ManagerErrorType>;
    };
  };
};

export type SyndicateWires_SellingGroupInvitationWiresQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type SyndicateWires_SellingGroupInvitationWiresQuery = {
  readonly __typename?: 'Query';
  readonly sellingGroupInvitationWireList: ReadonlyArray<{
    readonly __typename?: 'SellingGroupInvitationWire';
    readonly id: string;
    readonly disclaimer?: string | null;
    readonly masterSdaDate: string;
    readonly status: Types.WireStatus;
    readonly createdAt: string;
    readonly sender: string;
    readonly wireMessages: ReadonlyArray<{
      readonly __typename?: 'WireMessage';
      readonly id: string;
      readonly emailAddresses: ReadonlyArray<string>;
      readonly expiredAt?: string | null;
      readonly respondedAt?: string | null;
      readonly responseStatus?: Types.SentWireResponseStatus | null;
      readonly wireMessageAndResponseStatus: Types.WireMessageAndResponseStatus;
      readonly sentAt?: string | null;
      readonly htmlBody: string;
      readonly firmSnapshot?: {
        readonly __typename?: 'FirmSnapshot';
        readonly cmgEntityKey: string;
        readonly firmName: string;
        readonly offeringManagerRole?: Types.SyndicateWiresManagerRole | null;
      } | null;
    }>;
  }>;
};

export type SyndicateWires_SendSellingGroupInvitationWireMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  syndicateWireId: Types.Scalars['UUID'];
  managerIds: ReadonlyArray<Types.Scalars['String']> | Types.Scalars['String'];
  sendDateTimeZone: Types.Scalars['String'];
  shouldAddSenderToCc: Types.Scalars['Boolean'];
}>;

export type SyndicateWires_SendSellingGroupInvitationWireMutation = {
  readonly __typename?: 'Mutation';
  readonly sendSellingGroupInvitationWire:
    | {
        readonly __typename?: 'SellingGroupInvitationWire';
        readonly id: string;
        readonly disclaimer?: string | null;
        readonly masterSdaDate: string;
        readonly status: Types.WireStatus;
        readonly createdAt: string;
        readonly sender: string;
        readonly wireMessages: ReadonlyArray<{
          readonly __typename?: 'WireMessage';
          readonly id: string;
          readonly emailAddresses: ReadonlyArray<string>;
          readonly expiredAt?: string | null;
          readonly respondedAt?: string | null;
          readonly responseStatus?: Types.SentWireResponseStatus | null;
          readonly wireMessageAndResponseStatus: Types.WireMessageAndResponseStatus;
          readonly sentAt?: string | null;
          readonly htmlBody: string;
          readonly firmSnapshot?: {
            readonly __typename?: 'FirmSnapshot';
            readonly cmgEntityKey: string;
            readonly firmName: string;
            readonly offeringManagerRole?: Types.SyndicateWiresManagerRole | null;
          } | null;
        }>;
      }
    | {
        readonly __typename: 'ServiceError';
        readonly code: Types.ServiceErrorCode;
        readonly message: string;
        readonly target?: string | null;
        readonly details?: ReadonlyArray<{
          readonly __typename: 'ErrorDetail';
          readonly code?: string | null;
          readonly message?: string | null;
          readonly target?: string | null;
        }> | null;
      };
};

export type SyndicateWires_UpdateSellingGroupInvitationWireMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  syndicateWireId: Types.Scalars['UUID'];
  payload: Types.UpdateSellingGroupInvitationWireInput;
}>;

export type SyndicateWires_UpdateSellingGroupInvitationWireMutation = {
  readonly __typename?: 'Mutation';
  readonly updateSellingGroupInvitationWire:
    | {
        readonly __typename?: 'SellingGroupInvitationWire';
        readonly id: string;
        readonly disclaimer?: string | null;
        readonly masterSdaDate: string;
        readonly status: Types.WireStatus;
        readonly createdAt: string;
        readonly sender: string;
        readonly wireMessages: ReadonlyArray<{
          readonly __typename?: 'WireMessage';
          readonly id: string;
          readonly emailAddresses: ReadonlyArray<string>;
          readonly expiredAt?: string | null;
          readonly respondedAt?: string | null;
          readonly responseStatus?: Types.SentWireResponseStatus | null;
          readonly wireMessageAndResponseStatus: Types.WireMessageAndResponseStatus;
          readonly sentAt?: string | null;
          readonly htmlBody: string;
          readonly firmSnapshot?: {
            readonly __typename?: 'FirmSnapshot';
            readonly cmgEntityKey: string;
            readonly firmName: string;
            readonly offeringManagerRole?: Types.SyndicateWiresManagerRole | null;
          } | null;
        }>;
      }
    | {
        readonly __typename: 'ServiceError';
        readonly code: Types.ServiceErrorCode;
        readonly message: string;
        readonly target?: string | null;
        readonly details?: ReadonlyArray<{
          readonly __typename: 'ErrorDetail';
          readonly code?: string | null;
          readonly message?: string | null;
          readonly target?: string | null;
        }> | null;
      };
};

export const SyndicateWires_SellingGroupInvitationWirePartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_SellingGroupInvitationWireParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SellingGroupInvitationWire' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'disclaimer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'masterSdaDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wireMessages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_WireMessageParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_WireMessageParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WireMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddresses' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'firmSnapshot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_FirmSnapshotParts' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'respondedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'responseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wireMessageAndResponseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sentAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'htmlBody' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_FirmSnapshotParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FirmSnapshot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringManagerRole' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const SyndicateWires_SellingGroupInvitationWireValidationPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_SellingGroupInvitationWireValidationParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SellingGroupInvitationWireValidation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'orderBookErrors' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringErrors' } },
          { kind: 'Field', name: { kind: 'Name', value: 'settlementErrors' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'managers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_ManagerValidationParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sender' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_ManagerValidationParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_ManagerValidationParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ManagerValidation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fields' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const SyndicateWires_CreateSellingGroupInvitationWireDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SyndicateWires_CreateSellingGroupInvitationWire' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'payload' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateSellingGroupInvitationWireInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createSellingGroupInvitationWire' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'payload' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'payload' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_SellingGroupInvitationWireParts' },
                },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ServiceErrorParts' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_SellingGroupInvitationWireParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SellingGroupInvitationWire' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'disclaimer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'masterSdaDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wireMessages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_WireMessageParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_WireMessageParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WireMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddresses' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'firmSnapshot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_FirmSnapshotParts' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'respondedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'responseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wireMessageAndResponseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sentAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'htmlBody' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_FirmSnapshotParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FirmSnapshot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringManagerRole' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ServiceErrorParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ServiceError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'target' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'target' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type SyndicateWires_CreateSellingGroupInvitationWireMutationFn = Apollo.MutationFunction<
  SyndicateWires_CreateSellingGroupInvitationWireMutation,
  SyndicateWires_CreateSellingGroupInvitationWireMutationVariables
>;

/**
 * __useSyndicateWires_CreateSellingGroupInvitationWireMutation__
 *
 * To run a mutation, you first call `useSyndicateWires_CreateSellingGroupInvitationWireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyndicateWires_CreateSellingGroupInvitationWireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syndicateWiresCreateSellingGroupInvitationWireMutation, { data, loading, error }] = useSyndicateWires_CreateSellingGroupInvitationWireMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useSyndicateWires_CreateSellingGroupInvitationWireMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SyndicateWires_CreateSellingGroupInvitationWireMutation,
    SyndicateWires_CreateSellingGroupInvitationWireMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SyndicateWires_CreateSellingGroupInvitationWireMutation,
    SyndicateWires_CreateSellingGroupInvitationWireMutationVariables
  >(SyndicateWires_CreateSellingGroupInvitationWireDocument, options);
}
export type SyndicateWires_CreateSellingGroupInvitationWireMutationHookResult = ReturnType<
  typeof useSyndicateWires_CreateSellingGroupInvitationWireMutation
>;
export type SyndicateWires_CreateSellingGroupInvitationWireMutationResult =
  Apollo.MutationResult<SyndicateWires_CreateSellingGroupInvitationWireMutation>;
export type SyndicateWires_CreateSellingGroupInvitationWireMutationOptions =
  Apollo.BaseMutationOptions<
    SyndicateWires_CreateSellingGroupInvitationWireMutation,
    SyndicateWires_CreateSellingGroupInvitationWireMutationVariables
  >;
export const SyndicateWires_DeleteSellingGroupInvitationWireDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SyndicateWires_DeleteSellingGroupInvitationWire' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'syndicateWireId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteSellingGroupInvitationWire' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'syndicateWireId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'syndicateWireId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_DeleteWireParts' },
                },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ServiceErrorParts' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ServiceErrorParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ServiceError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'target' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'target' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_DeleteWireParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DeleteWire' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
      },
    },
  ],
} as unknown as DocumentNode;
export type SyndicateWires_DeleteSellingGroupInvitationWireMutationFn = Apollo.MutationFunction<
  SyndicateWires_DeleteSellingGroupInvitationWireMutation,
  SyndicateWires_DeleteSellingGroupInvitationWireMutationVariables
>;

/**
 * __useSyndicateWires_DeleteSellingGroupInvitationWireMutation__
 *
 * To run a mutation, you first call `useSyndicateWires_DeleteSellingGroupInvitationWireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyndicateWires_DeleteSellingGroupInvitationWireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syndicateWiresDeleteSellingGroupInvitationWireMutation, { data, loading, error }] = useSyndicateWires_DeleteSellingGroupInvitationWireMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      syndicateWireId: // value for 'syndicateWireId'
 *   },
 * });
 */
export function useSyndicateWires_DeleteSellingGroupInvitationWireMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SyndicateWires_DeleteSellingGroupInvitationWireMutation,
    SyndicateWires_DeleteSellingGroupInvitationWireMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SyndicateWires_DeleteSellingGroupInvitationWireMutation,
    SyndicateWires_DeleteSellingGroupInvitationWireMutationVariables
  >(SyndicateWires_DeleteSellingGroupInvitationWireDocument, options);
}
export type SyndicateWires_DeleteSellingGroupInvitationWireMutationHookResult = ReturnType<
  typeof useSyndicateWires_DeleteSellingGroupInvitationWireMutation
>;
export type SyndicateWires_DeleteSellingGroupInvitationWireMutationResult =
  Apollo.MutationResult<SyndicateWires_DeleteSellingGroupInvitationWireMutation>;
export type SyndicateWires_DeleteSellingGroupInvitationWireMutationOptions =
  Apollo.BaseMutationOptions<
    SyndicateWires_DeleteSellingGroupInvitationWireMutation,
    SyndicateWires_DeleteSellingGroupInvitationWireMutationVariables
  >;
export const SyndicateWires_MasterSdaDateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SyndicateWires_MasterSdaDate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'syndicateWires' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'masterSdaDate' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useSyndicateWires_MasterSdaDateQuery__
 *
 * To run a query within a React component, call `useSyndicateWires_MasterSdaDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useSyndicateWires_MasterSdaDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSyndicateWires_MasterSdaDateQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useSyndicateWires_MasterSdaDateQuery(
  baseOptions: Apollo.QueryHookOptions<
    SyndicateWires_MasterSdaDateQuery,
    SyndicateWires_MasterSdaDateQueryVariables
  > &
    ({ variables: SyndicateWires_MasterSdaDateQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SyndicateWires_MasterSdaDateQuery,
    SyndicateWires_MasterSdaDateQueryVariables
  >(SyndicateWires_MasterSdaDateDocument, options);
}
export function useSyndicateWires_MasterSdaDateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SyndicateWires_MasterSdaDateQuery,
    SyndicateWires_MasterSdaDateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SyndicateWires_MasterSdaDateQuery,
    SyndicateWires_MasterSdaDateQueryVariables
  >(SyndicateWires_MasterSdaDateDocument, options);
}
export function useSyndicateWires_MasterSdaDateSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        SyndicateWires_MasterSdaDateQuery,
        SyndicateWires_MasterSdaDateQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SyndicateWires_MasterSdaDateQuery,
    SyndicateWires_MasterSdaDateQueryVariables
  >(SyndicateWires_MasterSdaDateDocument, options);
}
export type SyndicateWires_MasterSdaDateQueryHookResult = ReturnType<
  typeof useSyndicateWires_MasterSdaDateQuery
>;
export type SyndicateWires_MasterSdaDateLazyQueryHookResult = ReturnType<
  typeof useSyndicateWires_MasterSdaDateLazyQuery
>;
export type SyndicateWires_MasterSdaDateSuspenseQueryHookResult = ReturnType<
  typeof useSyndicateWires_MasterSdaDateSuspenseQuery
>;
export type SyndicateWires_MasterSdaDateQueryResult = Apollo.QueryResult<
  SyndicateWires_MasterSdaDateQuery,
  SyndicateWires_MasterSdaDateQueryVariables
>;
export const SyndicateWires_SellingGroupInvitationWireDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SyndicateWires_SellingGroupInvitationWireDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'syndicateWireId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'offering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sellingGroupInvitationWireDetails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'syndicateWireId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'syndicateWireId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_SellingGroupInvitationWireParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_SellingGroupInvitationWireParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SellingGroupInvitationWire' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'disclaimer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'masterSdaDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wireMessages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_WireMessageParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_WireMessageParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WireMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddresses' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'firmSnapshot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_FirmSnapshotParts' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'respondedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'responseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wireMessageAndResponseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sentAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'htmlBody' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_FirmSnapshotParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FirmSnapshot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringManagerRole' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useSyndicateWires_SellingGroupInvitationWireDetailsQuery__
 *
 * To run a query within a React component, call `useSyndicateWires_SellingGroupInvitationWireDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSyndicateWires_SellingGroupInvitationWireDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSyndicateWires_SellingGroupInvitationWireDetailsQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      syndicateWireId: // value for 'syndicateWireId'
 *   },
 * });
 */
export function useSyndicateWires_SellingGroupInvitationWireDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    SyndicateWires_SellingGroupInvitationWireDetailsQuery,
    SyndicateWires_SellingGroupInvitationWireDetailsQueryVariables
  > &
    (
      | {
          variables: SyndicateWires_SellingGroupInvitationWireDetailsQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SyndicateWires_SellingGroupInvitationWireDetailsQuery,
    SyndicateWires_SellingGroupInvitationWireDetailsQueryVariables
  >(SyndicateWires_SellingGroupInvitationWireDetailsDocument, options);
}
export function useSyndicateWires_SellingGroupInvitationWireDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SyndicateWires_SellingGroupInvitationWireDetailsQuery,
    SyndicateWires_SellingGroupInvitationWireDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SyndicateWires_SellingGroupInvitationWireDetailsQuery,
    SyndicateWires_SellingGroupInvitationWireDetailsQueryVariables
  >(SyndicateWires_SellingGroupInvitationWireDetailsDocument, options);
}
export function useSyndicateWires_SellingGroupInvitationWireDetailsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        SyndicateWires_SellingGroupInvitationWireDetailsQuery,
        SyndicateWires_SellingGroupInvitationWireDetailsQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SyndicateWires_SellingGroupInvitationWireDetailsQuery,
    SyndicateWires_SellingGroupInvitationWireDetailsQueryVariables
  >(SyndicateWires_SellingGroupInvitationWireDetailsDocument, options);
}
export type SyndicateWires_SellingGroupInvitationWireDetailsQueryHookResult = ReturnType<
  typeof useSyndicateWires_SellingGroupInvitationWireDetailsQuery
>;
export type SyndicateWires_SellingGroupInvitationWireDetailsLazyQueryHookResult = ReturnType<
  typeof useSyndicateWires_SellingGroupInvitationWireDetailsLazyQuery
>;
export type SyndicateWires_SellingGroupInvitationWireDetailsSuspenseQueryHookResult = ReturnType<
  typeof useSyndicateWires_SellingGroupInvitationWireDetailsSuspenseQuery
>;
export type SyndicateWires_SellingGroupInvitationWireDetailsQueryResult = Apollo.QueryResult<
  SyndicateWires_SellingGroupInvitationWireDetailsQuery,
  SyndicateWires_SellingGroupInvitationWireDetailsQueryVariables
>;
export const SyndicateWires_SellingGroupInvitationWirePreviewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SyndicateWires_SellingGroupInvitationWirePreview' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'syndicateWireId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKey' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sellingGroupInvitationWirePreview' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'syndicateWireId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'syndicateWireId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cmgEntityKey' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKey' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'htmlContent' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useSyndicateWires_SellingGroupInvitationWirePreviewQuery__
 *
 * To run a query within a React component, call `useSyndicateWires_SellingGroupInvitationWirePreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useSyndicateWires_SellingGroupInvitationWirePreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSyndicateWires_SellingGroupInvitationWirePreviewQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      syndicateWireId: // value for 'syndicateWireId'
 *      cmgEntityKey: // value for 'cmgEntityKey'
 *   },
 * });
 */
export function useSyndicateWires_SellingGroupInvitationWirePreviewQuery(
  baseOptions: Apollo.QueryHookOptions<
    SyndicateWires_SellingGroupInvitationWirePreviewQuery,
    SyndicateWires_SellingGroupInvitationWirePreviewQueryVariables
  > &
    (
      | {
          variables: SyndicateWires_SellingGroupInvitationWirePreviewQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SyndicateWires_SellingGroupInvitationWirePreviewQuery,
    SyndicateWires_SellingGroupInvitationWirePreviewQueryVariables
  >(SyndicateWires_SellingGroupInvitationWirePreviewDocument, options);
}
export function useSyndicateWires_SellingGroupInvitationWirePreviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SyndicateWires_SellingGroupInvitationWirePreviewQuery,
    SyndicateWires_SellingGroupInvitationWirePreviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SyndicateWires_SellingGroupInvitationWirePreviewQuery,
    SyndicateWires_SellingGroupInvitationWirePreviewQueryVariables
  >(SyndicateWires_SellingGroupInvitationWirePreviewDocument, options);
}
export function useSyndicateWires_SellingGroupInvitationWirePreviewSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        SyndicateWires_SellingGroupInvitationWirePreviewQuery,
        SyndicateWires_SellingGroupInvitationWirePreviewQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SyndicateWires_SellingGroupInvitationWirePreviewQuery,
    SyndicateWires_SellingGroupInvitationWirePreviewQueryVariables
  >(SyndicateWires_SellingGroupInvitationWirePreviewDocument, options);
}
export type SyndicateWires_SellingGroupInvitationWirePreviewQueryHookResult = ReturnType<
  typeof useSyndicateWires_SellingGroupInvitationWirePreviewQuery
>;
export type SyndicateWires_SellingGroupInvitationWirePreviewLazyQueryHookResult = ReturnType<
  typeof useSyndicateWires_SellingGroupInvitationWirePreviewLazyQuery
>;
export type SyndicateWires_SellingGroupInvitationWirePreviewSuspenseQueryHookResult = ReturnType<
  typeof useSyndicateWires_SellingGroupInvitationWirePreviewSuspenseQuery
>;
export type SyndicateWires_SellingGroupInvitationWirePreviewQueryResult = Apollo.QueryResult<
  SyndicateWires_SellingGroupInvitationWirePreviewQuery,
  SyndicateWires_SellingGroupInvitationWirePreviewQueryVariables
>;
export const SyndicateWires_SellingGroupInvitationWireValidationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SyndicateWires_SellingGroupInvitationWireValidation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sellingGroupInvitationWireValidation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'SyndicateWires_SellingGroupInvitationWireValidationParts',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_SellingGroupInvitationWireValidationParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SellingGroupInvitationWireValidation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'orderBookErrors' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringErrors' } },
          { kind: 'Field', name: { kind: 'Name', value: 'settlementErrors' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'managers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_ManagerValidationParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sender' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_ManagerValidationParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_ManagerValidationParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ManagerValidation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fields' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useSyndicateWires_SellingGroupInvitationWireValidationQuery__
 *
 * To run a query within a React component, call `useSyndicateWires_SellingGroupInvitationWireValidationQuery` and pass it any options that fit your needs.
 * When your component renders, `useSyndicateWires_SellingGroupInvitationWireValidationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSyndicateWires_SellingGroupInvitationWireValidationQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useSyndicateWires_SellingGroupInvitationWireValidationQuery(
  baseOptions: Apollo.QueryHookOptions<
    SyndicateWires_SellingGroupInvitationWireValidationQuery,
    SyndicateWires_SellingGroupInvitationWireValidationQueryVariables
  > &
    (
      | {
          variables: SyndicateWires_SellingGroupInvitationWireValidationQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SyndicateWires_SellingGroupInvitationWireValidationQuery,
    SyndicateWires_SellingGroupInvitationWireValidationQueryVariables
  >(SyndicateWires_SellingGroupInvitationWireValidationDocument, options);
}
export function useSyndicateWires_SellingGroupInvitationWireValidationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SyndicateWires_SellingGroupInvitationWireValidationQuery,
    SyndicateWires_SellingGroupInvitationWireValidationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SyndicateWires_SellingGroupInvitationWireValidationQuery,
    SyndicateWires_SellingGroupInvitationWireValidationQueryVariables
  >(SyndicateWires_SellingGroupInvitationWireValidationDocument, options);
}
export function useSyndicateWires_SellingGroupInvitationWireValidationSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        SyndicateWires_SellingGroupInvitationWireValidationQuery,
        SyndicateWires_SellingGroupInvitationWireValidationQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SyndicateWires_SellingGroupInvitationWireValidationQuery,
    SyndicateWires_SellingGroupInvitationWireValidationQueryVariables
  >(SyndicateWires_SellingGroupInvitationWireValidationDocument, options);
}
export type SyndicateWires_SellingGroupInvitationWireValidationQueryHookResult = ReturnType<
  typeof useSyndicateWires_SellingGroupInvitationWireValidationQuery
>;
export type SyndicateWires_SellingGroupInvitationWireValidationLazyQueryHookResult = ReturnType<
  typeof useSyndicateWires_SellingGroupInvitationWireValidationLazyQuery
>;
export type SyndicateWires_SellingGroupInvitationWireValidationSuspenseQueryHookResult = ReturnType<
  typeof useSyndicateWires_SellingGroupInvitationWireValidationSuspenseQuery
>;
export type SyndicateWires_SellingGroupInvitationWireValidationQueryResult = Apollo.QueryResult<
  SyndicateWires_SellingGroupInvitationWireValidationQuery,
  SyndicateWires_SellingGroupInvitationWireValidationQueryVariables
>;
export const SyndicateWires_SellingGroupInvitationWiresDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SyndicateWires_SellingGroupInvitationWires' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sellingGroupInvitationWireList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_SellingGroupInvitationWireParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_SellingGroupInvitationWireParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SellingGroupInvitationWire' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'disclaimer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'masterSdaDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wireMessages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_WireMessageParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_WireMessageParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WireMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddresses' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'firmSnapshot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_FirmSnapshotParts' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'respondedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'responseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wireMessageAndResponseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sentAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'htmlBody' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_FirmSnapshotParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FirmSnapshot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringManagerRole' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useSyndicateWires_SellingGroupInvitationWiresQuery__
 *
 * To run a query within a React component, call `useSyndicateWires_SellingGroupInvitationWiresQuery` and pass it any options that fit your needs.
 * When your component renders, `useSyndicateWires_SellingGroupInvitationWiresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSyndicateWires_SellingGroupInvitationWiresQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useSyndicateWires_SellingGroupInvitationWiresQuery(
  baseOptions: Apollo.QueryHookOptions<
    SyndicateWires_SellingGroupInvitationWiresQuery,
    SyndicateWires_SellingGroupInvitationWiresQueryVariables
  > &
    (
      | { variables: SyndicateWires_SellingGroupInvitationWiresQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SyndicateWires_SellingGroupInvitationWiresQuery,
    SyndicateWires_SellingGroupInvitationWiresQueryVariables
  >(SyndicateWires_SellingGroupInvitationWiresDocument, options);
}
export function useSyndicateWires_SellingGroupInvitationWiresLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SyndicateWires_SellingGroupInvitationWiresQuery,
    SyndicateWires_SellingGroupInvitationWiresQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SyndicateWires_SellingGroupInvitationWiresQuery,
    SyndicateWires_SellingGroupInvitationWiresQueryVariables
  >(SyndicateWires_SellingGroupInvitationWiresDocument, options);
}
export function useSyndicateWires_SellingGroupInvitationWiresSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        SyndicateWires_SellingGroupInvitationWiresQuery,
        SyndicateWires_SellingGroupInvitationWiresQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SyndicateWires_SellingGroupInvitationWiresQuery,
    SyndicateWires_SellingGroupInvitationWiresQueryVariables
  >(SyndicateWires_SellingGroupInvitationWiresDocument, options);
}
export type SyndicateWires_SellingGroupInvitationWiresQueryHookResult = ReturnType<
  typeof useSyndicateWires_SellingGroupInvitationWiresQuery
>;
export type SyndicateWires_SellingGroupInvitationWiresLazyQueryHookResult = ReturnType<
  typeof useSyndicateWires_SellingGroupInvitationWiresLazyQuery
>;
export type SyndicateWires_SellingGroupInvitationWiresSuspenseQueryHookResult = ReturnType<
  typeof useSyndicateWires_SellingGroupInvitationWiresSuspenseQuery
>;
export type SyndicateWires_SellingGroupInvitationWiresQueryResult = Apollo.QueryResult<
  SyndicateWires_SellingGroupInvitationWiresQuery,
  SyndicateWires_SellingGroupInvitationWiresQueryVariables
>;
export const SyndicateWires_SendSellingGroupInvitationWireDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SyndicateWires_SendSellingGroupInvitationWire' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'syndicateWireId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'managerIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sendDateTimeZone' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'shouldAddSenderToCc' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendSellingGroupInvitationWire' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'syndicateWireId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'syndicateWireId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'payload' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'underwriterList' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'managerIds' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'sendDateTimeZone' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'sendDateTimeZone' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'shouldAddSenderToCc' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'shouldAddSenderToCc' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_SellingGroupInvitationWireParts' },
                },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ServiceErrorParts' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_SellingGroupInvitationWireParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SellingGroupInvitationWire' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'disclaimer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'masterSdaDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wireMessages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_WireMessageParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_WireMessageParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WireMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddresses' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'firmSnapshot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_FirmSnapshotParts' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'respondedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'responseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wireMessageAndResponseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sentAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'htmlBody' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_FirmSnapshotParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FirmSnapshot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringManagerRole' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ServiceErrorParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ServiceError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'target' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'target' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type SyndicateWires_SendSellingGroupInvitationWireMutationFn = Apollo.MutationFunction<
  SyndicateWires_SendSellingGroupInvitationWireMutation,
  SyndicateWires_SendSellingGroupInvitationWireMutationVariables
>;

/**
 * __useSyndicateWires_SendSellingGroupInvitationWireMutation__
 *
 * To run a mutation, you first call `useSyndicateWires_SendSellingGroupInvitationWireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyndicateWires_SendSellingGroupInvitationWireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syndicateWiresSendSellingGroupInvitationWireMutation, { data, loading, error }] = useSyndicateWires_SendSellingGroupInvitationWireMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      syndicateWireId: // value for 'syndicateWireId'
 *      managerIds: // value for 'managerIds'
 *      sendDateTimeZone: // value for 'sendDateTimeZone'
 *      shouldAddSenderToCc: // value for 'shouldAddSenderToCc'
 *   },
 * });
 */
export function useSyndicateWires_SendSellingGroupInvitationWireMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SyndicateWires_SendSellingGroupInvitationWireMutation,
    SyndicateWires_SendSellingGroupInvitationWireMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SyndicateWires_SendSellingGroupInvitationWireMutation,
    SyndicateWires_SendSellingGroupInvitationWireMutationVariables
  >(SyndicateWires_SendSellingGroupInvitationWireDocument, options);
}
export type SyndicateWires_SendSellingGroupInvitationWireMutationHookResult = ReturnType<
  typeof useSyndicateWires_SendSellingGroupInvitationWireMutation
>;
export type SyndicateWires_SendSellingGroupInvitationWireMutationResult =
  Apollo.MutationResult<SyndicateWires_SendSellingGroupInvitationWireMutation>;
export type SyndicateWires_SendSellingGroupInvitationWireMutationOptions =
  Apollo.BaseMutationOptions<
    SyndicateWires_SendSellingGroupInvitationWireMutation,
    SyndicateWires_SendSellingGroupInvitationWireMutationVariables
  >;
export const SyndicateWires_UpdateSellingGroupInvitationWireDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SyndicateWires_UpdateSellingGroupInvitationWire' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'syndicateWireId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'payload' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateSellingGroupInvitationWireInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSellingGroupInvitationWire' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'syndicateWireId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'syndicateWireId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'payload' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'payload' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_SellingGroupInvitationWireParts' },
                },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ServiceErrorParts' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_SellingGroupInvitationWireParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SellingGroupInvitationWire' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'disclaimer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'masterSdaDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wireMessages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_WireMessageParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_WireMessageParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WireMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddresses' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'firmSnapshot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_FirmSnapshotParts' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'respondedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'responseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wireMessageAndResponseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sentAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'htmlBody' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_FirmSnapshotParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FirmSnapshot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringManagerRole' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ServiceErrorParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ServiceError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'target' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'target' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type SyndicateWires_UpdateSellingGroupInvitationWireMutationFn = Apollo.MutationFunction<
  SyndicateWires_UpdateSellingGroupInvitationWireMutation,
  SyndicateWires_UpdateSellingGroupInvitationWireMutationVariables
>;

/**
 * __useSyndicateWires_UpdateSellingGroupInvitationWireMutation__
 *
 * To run a mutation, you first call `useSyndicateWires_UpdateSellingGroupInvitationWireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyndicateWires_UpdateSellingGroupInvitationWireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syndicateWiresUpdateSellingGroupInvitationWireMutation, { data, loading, error }] = useSyndicateWires_UpdateSellingGroupInvitationWireMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      syndicateWireId: // value for 'syndicateWireId'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useSyndicateWires_UpdateSellingGroupInvitationWireMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SyndicateWires_UpdateSellingGroupInvitationWireMutation,
    SyndicateWires_UpdateSellingGroupInvitationWireMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SyndicateWires_UpdateSellingGroupInvitationWireMutation,
    SyndicateWires_UpdateSellingGroupInvitationWireMutationVariables
  >(SyndicateWires_UpdateSellingGroupInvitationWireDocument, options);
}
export type SyndicateWires_UpdateSellingGroupInvitationWireMutationHookResult = ReturnType<
  typeof useSyndicateWires_UpdateSellingGroupInvitationWireMutation
>;
export type SyndicateWires_UpdateSellingGroupInvitationWireMutationResult =
  Apollo.MutationResult<SyndicateWires_UpdateSellingGroupInvitationWireMutation>;
export type SyndicateWires_UpdateSellingGroupInvitationWireMutationOptions =
  Apollo.BaseMutationOptions<
    SyndicateWires_UpdateSellingGroupInvitationWireMutation,
    SyndicateWires_UpdateSellingGroupInvitationWireMutationVariables
  >;

import { makeMockResponse } from '../../../../../../../graphql/mock-response';

export const makeSyndicateWires_MasterSdaDateMockResponse = makeMockResponse<
  SyndicateWires_MasterSdaDateQueryVariables,
  SyndicateWires_MasterSdaDateQuery
>(SyndicateWires_MasterSdaDateDocument);

export const makeSyndicateWires_SellingGroupInvitationWireDetailsMockResponse = makeMockResponse<
  SyndicateWires_SellingGroupInvitationWireDetailsQueryVariables,
  SyndicateWires_SellingGroupInvitationWireDetailsQuery
>(SyndicateWires_SellingGroupInvitationWireDetailsDocument);

export const makeSyndicateWires_SellingGroupInvitationWirePreviewMockResponse = makeMockResponse<
  SyndicateWires_SellingGroupInvitationWirePreviewQueryVariables,
  SyndicateWires_SellingGroupInvitationWirePreviewQuery
>(SyndicateWires_SellingGroupInvitationWirePreviewDocument);

export const makeSyndicateWires_SellingGroupInvitationWireValidationMockResponse = makeMockResponse<
  SyndicateWires_SellingGroupInvitationWireValidationQueryVariables,
  SyndicateWires_SellingGroupInvitationWireValidationQuery
>(SyndicateWires_SellingGroupInvitationWireValidationDocument);

export const makeSyndicateWires_SellingGroupInvitationWiresMockResponse = makeMockResponse<
  SyndicateWires_SellingGroupInvitationWiresQueryVariables,
  SyndicateWires_SellingGroupInvitationWiresQuery
>(SyndicateWires_SellingGroupInvitationWiresDocument);
