/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Calendar_PricedCalendarOfferingsListQueryVariables = Types.Exact<{
  startPricingDate: Types.Scalars['Date'];
  endPricingDate: Types.Scalars['Date'];
  allowMyInstitutionalIndication: Types.Scalars['Boolean'];
  allowMDLIndications: Types.Scalars['Boolean'];
  allowMDLAllocation: Types.Scalars['Boolean'];
  allowFinalAllocation: Types.Scalars['Boolean'];
  includeTestOfferings: Types.Scalars['Boolean'];
}>;

export type Calendar_PricedCalendarOfferingsListQuery = {
  readonly __typename?: 'Query';
  readonly pricedPublishedOfferings: {
    readonly __typename?: 'OfferingList';
    readonly data: ReadonlyArray<{
      readonly __typename?: 'OfferingLimited';
      readonly id: string;
      readonly type: Types.OfferingType;
      readonly status: Types.OfferingStatus;
      readonly issuerName: string;
      readonly finalFilingOfferPrice?: number | null;
      readonly securityType: Types.SecurityType;
      readonly securityTypeDisplayName?: string | null;
      readonly launchDate?: string | null;
      readonly leftLead?: string | null;
      readonly sizeInCurrency?: number | null;
      readonly sizeInShares?: number | null;
      readonly pricingDate?: string | null;
      readonly issuerSector?: Types.Sector | null;
      readonly publicFilingDate?: string | null;
      readonly postponedDate?: string | null;
      readonly firstTradeDate?: string | null;
      readonly priceRangeLow?: number | null;
      readonly priceRangeHigh?: number | null;
      readonly isExecutingOnPlatform: boolean;
      readonly leftLeadFirmName?: string | null;
      readonly revisedFilingsCount?: number | null;
      readonly pricingInstrumentStockSymbol?: string | null;
      readonly pricingInstrumentCountryCode?: string | null;
      readonly pricingCurrencyCode: string;
      readonly pricingInstrumentRegion?: string | null;
      readonly pricingInstrumentCountryDisplayName?: string | null;
      readonly mdlIndications?: {
        readonly __typename?: 'MdlIndications';
        readonly indications: ReadonlyArray<{
          readonly __typename?: 'MdlIndication';
          readonly unitType: Types.MdlIndicationUnitType;
          readonly dollars?: number | null;
          readonly percentage?: number | null;
          readonly shares?: number | null;
          readonly pricingType: Types.MdlIndicationPricingType;
          readonly limitPrice?: number | null;
          readonly realDemandDollars?: number | null;
          readonly realDemandPercentage?: number | null;
          readonly realDemandShares?: number | null;
          readonly limitType: Types.MdlIndicationLimitType;
          readonly limitPercentage?: number | null;
        }>;
      } | null;
      readonly mdlAllocation?: {
        readonly __typename?: 'MdlAllocationResponse';
        readonly allocation?: {
          readonly __typename?: 'MdlAllocation';
          readonly id: string;
          readonly fillPercentage?: number | null;
          readonly investment?: number | null;
          readonly salesConcession?: number | null;
          readonly shares?: number | null;
          readonly ranking: Types.MdlAllocationRanking;
          readonly ioiType: Types.MdlAllocationIoiType;
          readonly lastModified: {
            readonly __typename?: 'MdlAllocationLastModified';
            readonly timestamp?: string | null;
            readonly name?: string | null;
            readonly email?: string | null;
            readonly id?: string | null;
          };
        } | null;
      } | null;
      readonly myInstitutionalIndication?: {
        readonly __typename?: 'MyInstitutionalIndication';
        readonly id: string;
        readonly type: Types.InstitutionalIndicationOrderType;
        readonly status: Types.IndicationStatus;
        readonly currencyCode?: string | null;
        readonly interestLevels: ReadonlyArray<{
          readonly __typename?: 'InterestLevel';
          readonly interestUnit: Types.InterestLevelInterestUnit;
          readonly interestQuantity: number;
          readonly limitPrice?: number | null;
          readonly limitType?: Types.InterestLevelLimitType | null;
        }>;
      } | null;
      readonly finalAllocation?: {
        readonly __typename?: 'FinalAllocation';
        readonly indicationId: string;
        readonly shareQuantity?: number | null;
        readonly version: string;
        readonly acknowledgementStatus: Types.AllocationAcknowledgement;
        readonly investorReply?: {
          readonly __typename?: 'InvestorReply';
          readonly status: Types.AllocationAcknowledgement;
        } | null;
      } | null;
      readonly managers: ReadonlyArray<{
        readonly __typename?: 'ManagerLimited';
        readonly cmgEntityKey: string;
        readonly firmName: string;
        readonly role: Types.ManagerRole;
        readonly firmNameAbbreviation?: string | null;
      }>;
    }>;
  };
};

export const Calendar_PricedCalendarOfferingsListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Calendar_PricedCalendarOfferingsList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'startPricingDate' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'endPricingDate' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'allowMyInstitutionalIndication' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'allowMDLIndications' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'allowMDLAllocation' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'allowFinalAllocation' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeTestOfferings' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pricedPublishedOfferings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'startPricingDate' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'startPricingDate' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'endPricingDate' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'endPricingDate' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'includeTestOfferings' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'includeTestOfferings' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mdlIndications' },
                        directives: [
                          {
                            kind: 'Directive',
                            name: { kind: 'Name', value: 'include' },
                            arguments: [
                              {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'if' },
                                value: {
                                  kind: 'Variable',
                                  name: { kind: 'Name', value: 'allowMDLIndications' },
                                },
                              },
                            ],
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'indications' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'Calendar_MdlIndicationParts' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mdlAllocation' },
                        directives: [
                          {
                            kind: 'Directive',
                            name: { kind: 'Name', value: 'include' },
                            arguments: [
                              {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'if' },
                                value: {
                                  kind: 'Variable',
                                  name: { kind: 'Name', value: 'allowMDLAllocation' },
                                },
                              },
                            ],
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'allocation' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'Calendar_MdlAllocationParts' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'myInstitutionalIndication' },
                        directives: [
                          {
                            kind: 'Directive',
                            name: { kind: 'Name', value: 'include' },
                            arguments: [
                              {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'if' },
                                value: {
                                  kind: 'Variable',
                                  name: { kind: 'Name', value: 'allowMyInstitutionalIndication' },
                                },
                              },
                            ],
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'Calendar_MyInstitutionalIndicationParts',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'finalAllocation' },
                        directives: [
                          {
                            kind: 'Directive',
                            name: { kind: 'Name', value: 'include' },
                            arguments: [
                              {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'if' },
                                value: {
                                  kind: 'Variable',
                                  name: { kind: 'Name', value: 'allowFinalAllocation' },
                                },
                              },
                            ],
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'Calendar_FinalAllocationParts' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Calendar_CalendarGrid_OfferingLimitedParts' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Calendar_MdlIndicationParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MdlIndication' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'unitType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dollars' } },
          { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'limitPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'realDemandDollars' } },
          { kind: 'Field', name: { kind: 'Name', value: 'realDemandPercentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'realDemandShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'limitType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'limitPercentage' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Calendar_MdlAllocationParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MdlAllocation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fillPercentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investment' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastModified' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Calendar_AllocationLastModifiedInfoParts' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'salesConcession' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ranking' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ioiType' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Calendar_AllocationLastModifiedInfoParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MdlAllocationLastModified' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Calendar_MyInstitutionalIndicationParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MyInstitutionalIndication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'interestLevels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'interestUnit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'interestQuantity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limitPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limitType' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Calendar_FinalAllocationParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FinalAllocation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'indicationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shareQuantity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          { kind: 'Field', name: { kind: 'Name', value: 'acknowledgementStatus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investorReply' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'status' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Calendar_CalendarGrid_OfferingLimitedParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'OfferingLimited' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'issuerName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'finalFilingOfferPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'securityType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'securityTypeDisplayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'launchDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'leftLead' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sizeInCurrency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sizeInShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'issuerSector' } },
          { kind: 'Field', name: { kind: 'Name', value: 'publicFilingDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postponedDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstTradeDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceRangeLow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceRangeHigh' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isExecutingOnPlatform' } },
          { kind: 'Field', name: { kind: 'Name', value: 'leftLeadFirmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'revisedFilingsCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'managers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firmNameAbbreviation' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingInstrumentStockSymbol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingInstrumentCountryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingCurrencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingInstrumentRegion' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingInstrumentCountryDisplayName' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useCalendar_PricedCalendarOfferingsListQuery__
 *
 * To run a query within a React component, call `useCalendar_PricedCalendarOfferingsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendar_PricedCalendarOfferingsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendar_PricedCalendarOfferingsListQuery({
 *   variables: {
 *      startPricingDate: // value for 'startPricingDate'
 *      endPricingDate: // value for 'endPricingDate'
 *      allowMyInstitutionalIndication: // value for 'allowMyInstitutionalIndication'
 *      allowMDLIndications: // value for 'allowMDLIndications'
 *      allowMDLAllocation: // value for 'allowMDLAllocation'
 *      allowFinalAllocation: // value for 'allowFinalAllocation'
 *      includeTestOfferings: // value for 'includeTestOfferings'
 *   },
 * });
 */
export function useCalendar_PricedCalendarOfferingsListQuery(
  baseOptions: Apollo.QueryHookOptions<
    Calendar_PricedCalendarOfferingsListQuery,
    Calendar_PricedCalendarOfferingsListQueryVariables
  > &
    (
      | { variables: Calendar_PricedCalendarOfferingsListQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Calendar_PricedCalendarOfferingsListQuery,
    Calendar_PricedCalendarOfferingsListQueryVariables
  >(Calendar_PricedCalendarOfferingsListDocument, options);
}
export function useCalendar_PricedCalendarOfferingsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Calendar_PricedCalendarOfferingsListQuery,
    Calendar_PricedCalendarOfferingsListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Calendar_PricedCalendarOfferingsListQuery,
    Calendar_PricedCalendarOfferingsListQueryVariables
  >(Calendar_PricedCalendarOfferingsListDocument, options);
}
export function useCalendar_PricedCalendarOfferingsListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        Calendar_PricedCalendarOfferingsListQuery,
        Calendar_PricedCalendarOfferingsListQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Calendar_PricedCalendarOfferingsListQuery,
    Calendar_PricedCalendarOfferingsListQueryVariables
  >(Calendar_PricedCalendarOfferingsListDocument, options);
}
export type Calendar_PricedCalendarOfferingsListQueryHookResult = ReturnType<
  typeof useCalendar_PricedCalendarOfferingsListQuery
>;
export type Calendar_PricedCalendarOfferingsListLazyQueryHookResult = ReturnType<
  typeof useCalendar_PricedCalendarOfferingsListLazyQuery
>;
export type Calendar_PricedCalendarOfferingsListSuspenseQueryHookResult = ReturnType<
  typeof useCalendar_PricedCalendarOfferingsListSuspenseQuery
>;
export type Calendar_PricedCalendarOfferingsListQueryResult = Apollo.QueryResult<
  Calendar_PricedCalendarOfferingsListQuery,
  Calendar_PricedCalendarOfferingsListQueryVariables
>;

import { makeMockResponse } from '../../../../../graphql/mock-response';

export const makeCalendar_PricedCalendarOfferingsListMockResponse = makeMockResponse<
  Calendar_PricedCalendarOfferingsListQueryVariables,
  Calendar_PricedCalendarOfferingsListQuery
>(Calendar_PricedCalendarOfferingsListDocument);
