import { getOperationName } from '@apollo/client/utilities';
import { SnackbarManager } from '@cmg/design-system';
import React from 'react';

import { OrderBook_OrderBookRouteDocument } from '../../../../../../../../graphql';
import { OrderBook_InstitutionalDemand_AllocationSetPartsFragment } from '../../../../../../../../institutional-demand-v2/graphql/__generated__';
import { InstitutionalDemandGrid_SummaryDocument } from '../../../../../graphql';
import { InstitutionalFinalSet } from '../../../../../types';
import SaveAllocationSetDialog, {
  Props as AllocationSetDialogProps,
} from '../SaveAllocationSetDialog';
import { getDealLayoutInput } from '../SaveAllocationSetDialog.model';
import { useOrderBook_UpdateFinalAllocationSetDealLayoutMutation } from './graphql';

export type Props = Pick<AllocationSetDialogProps, 'isOpen' | 'onClose' | 'offeringId'> &
  Readonly<{
    finalAllocationSet:
      | InstitutionalFinalSet
      | OrderBook_InstitutionalDemand_AllocationSetPartsFragment
      | null;
  }>;

const EditFinalAllocationSetDialog: React.FC<Props> = ({
  isOpen,
  onClose,
  finalAllocationSet,
  offeringId,
}) => {
  const [editDealLayout] = useOrderBook_UpdateFinalAllocationSetDealLayoutMutation({
    refetchQueries: [
      getOperationName(OrderBook_OrderBookRouteDocument)!,
      getOperationName(InstitutionalDemandGrid_SummaryDocument)!,
    ],
  });

  const handleSubmit = React.useCallback<AllocationSetDialogProps['onSubmit']>(
    async values => {
      try {
        await editDealLayout({ variables: { offeringId, payload: getDealLayoutInput(values) } });

        onClose();
        SnackbarManager.success('Successfully edited Final Allocation set Deal Layout');
      } catch {
        SnackbarManager.error('Failed to edit Final Allocation set Deal Layout');
      }
    },
    [editDealLayout, offeringId, onClose]
  );

  if (!finalAllocationSet) {
    return null;
  }

  return (
    <SaveAllocationSetDialog
      offeringId={offeringId}
      title="Edit Final Allocation Set dialog"
      isEdit
      canSetName={false}
      isOpen={isOpen}
      onClose={onClose}
      name="Final"
      dealLayout={finalAllocationSet.dealLayout}
      onSubmit={handleSubmit}
    />
  );
};

export default EditFinalAllocationSetDialog;
