/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateHistory_CoveredIndicationVersionPartsFragment = {
  readonly __typename?: 'CoveredInstitutionalIndicationVersion';
  readonly id: string;
  readonly indicationId: string;
  readonly trancheId?: string | null;
  readonly instrumentId?: string | null;
  readonly currencyCode?: string | null;
  readonly type: Types.InstitutionalIndicationOrderType;
  readonly status: Types.IndicationStatus;
  readonly version: string;
  readonly isBuySideRevision: boolean;
  readonly investorInformation: {
    readonly __typename?: 'InvestorInformation';
    readonly contactName?: string | null;
    readonly cmgEntityName: string;
    readonly bankInvestorName?: string | null;
  };
  readonly tranche?: {
    readonly __typename?: 'TranchePrivileged';
    readonly id: string;
    readonly name: string;
    readonly demandCurrencies: ReadonlyArray<string>;
    readonly deliveryInstruments: ReadonlyArray<{
      readonly __typename?: 'DeliveryInstrumentPrivileged';
      readonly id: string;
      readonly isDepositaryReceipt: boolean;
      readonly stockSymbol?: string | null;
      readonly currencyCode: string;
      readonly countryCode: string;
    }>;
  } | null;
  readonly interestLevels: ReadonlyArray<{
    readonly __typename?: 'InterestLevel';
    readonly interestUnit: Types.InterestLevelInterestUnit;
    readonly interestQuantity: number;
    readonly limitPrice?: number | null;
    readonly limitType?: Types.InterestLevelLimitType | null;
  }>;
  readonly auditInfo: {
    readonly __typename?: 'AuditInfo';
    readonly modifiedByFirmKey?: string | null;
    readonly modifiedByUserEmail?: string | null;
    readonly modifiedAt: string;
    readonly modifiedByFirmName?: string | null;
  };
};

export type UpdateHistory_CoveredIndicationVersionsQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  indicationId: Types.Scalars['UUID'];
}>;

export type UpdateHistory_CoveredIndicationVersionsQuery = {
  readonly __typename?: 'Query';
  readonly coveredIndicationVersions: ReadonlyArray<{
    readonly __typename?: 'CoveredInstitutionalIndicationVersion';
    readonly id: string;
    readonly indicationId: string;
    readonly trancheId?: string | null;
    readonly instrumentId?: string | null;
    readonly currencyCode?: string | null;
    readonly type: Types.InstitutionalIndicationOrderType;
    readonly status: Types.IndicationStatus;
    readonly version: string;
    readonly isBuySideRevision: boolean;
    readonly investorInformation: {
      readonly __typename?: 'InvestorInformation';
      readonly contactName?: string | null;
      readonly cmgEntityName: string;
      readonly bankInvestorName?: string | null;
    };
    readonly tranche?: {
      readonly __typename?: 'TranchePrivileged';
      readonly id: string;
      readonly name: string;
      readonly demandCurrencies: ReadonlyArray<string>;
      readonly deliveryInstruments: ReadonlyArray<{
        readonly __typename?: 'DeliveryInstrumentPrivileged';
        readonly id: string;
        readonly isDepositaryReceipt: boolean;
        readonly stockSymbol?: string | null;
        readonly currencyCode: string;
        readonly countryCode: string;
      }>;
    } | null;
    readonly interestLevels: ReadonlyArray<{
      readonly __typename?: 'InterestLevel';
      readonly interestUnit: Types.InterestLevelInterestUnit;
      readonly interestQuantity: number;
      readonly limitPrice?: number | null;
      readonly limitType?: Types.InterestLevelLimitType | null;
    }>;
    readonly auditInfo: {
      readonly __typename?: 'AuditInfo';
      readonly modifiedByFirmKey?: string | null;
      readonly modifiedByUserEmail?: string | null;
      readonly modifiedAt: string;
      readonly modifiedByFirmName?: string | null;
    };
  }>;
};

export type UpdateHistory_IndicationVersionPartsFragment = {
  readonly __typename?: 'InstitutionalIndicationVersion';
  readonly id: string;
  readonly indicationId: string;
  readonly trancheId?: string | null;
  readonly instrumentId?: string | null;
  readonly currencyCode?: string | null;
  readonly type: Types.InstitutionalIndicationOrderType;
  readonly status: Types.IndicationStatus;
  readonly version: string;
  readonly isBuySideRevision: boolean;
  readonly investorInformation: {
    readonly __typename?: 'InvestorInformation';
    readonly contactName?: string | null;
    readonly cmgEntityName: string;
    readonly bankInvestorName?: string | null;
  };
  readonly tranche?: {
    readonly __typename?: 'TranchePrivileged';
    readonly id: string;
    readonly name: string;
    readonly demandCurrencies: ReadonlyArray<string>;
    readonly deliveryInstruments: ReadonlyArray<{
      readonly __typename?: 'DeliveryInstrumentPrivileged';
      readonly id: string;
      readonly isDepositaryReceipt: boolean;
      readonly stockSymbol?: string | null;
      readonly currencyCode: string;
      readonly countryCode: string;
    }>;
  } | null;
  readonly interestLevels: ReadonlyArray<{
    readonly __typename?: 'InterestLevel';
    readonly interestUnit: Types.InterestLevelInterestUnit;
    readonly interestQuantity: number;
    readonly limitPrice?: number | null;
    readonly limitType?: Types.InterestLevelLimitType | null;
  }>;
  readonly auditInfo: {
    readonly __typename?: 'AuditInfo';
    readonly modifiedByFirmKey?: string | null;
    readonly modifiedByUserEmail?: string | null;
    readonly modifiedAt: string;
    readonly modifiedByFirmName?: string | null;
  };
};

export type UpdateHistory_IndicationVersionsQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  indicationId: Types.Scalars['UUID'];
}>;

export type UpdateHistory_IndicationVersionsQuery = {
  readonly __typename?: 'Query';
  readonly indicationVersions: ReadonlyArray<{
    readonly __typename?: 'InstitutionalIndicationVersion';
    readonly id: string;
    readonly indicationId: string;
    readonly trancheId?: string | null;
    readonly instrumentId?: string | null;
    readonly currencyCode?: string | null;
    readonly type: Types.InstitutionalIndicationOrderType;
    readonly status: Types.IndicationStatus;
    readonly version: string;
    readonly isBuySideRevision: boolean;
    readonly investorInformation: {
      readonly __typename?: 'InvestorInformation';
      readonly contactName?: string | null;
      readonly cmgEntityName: string;
      readonly bankInvestorName?: string | null;
    };
    readonly tranche?: {
      readonly __typename?: 'TranchePrivileged';
      readonly id: string;
      readonly name: string;
      readonly demandCurrencies: ReadonlyArray<string>;
      readonly deliveryInstruments: ReadonlyArray<{
        readonly __typename?: 'DeliveryInstrumentPrivileged';
        readonly id: string;
        readonly isDepositaryReceipt: boolean;
        readonly stockSymbol?: string | null;
        readonly currencyCode: string;
        readonly countryCode: string;
      }>;
    } | null;
    readonly interestLevels: ReadonlyArray<{
      readonly __typename?: 'InterestLevel';
      readonly interestUnit: Types.InterestLevelInterestUnit;
      readonly interestQuantity: number;
      readonly limitPrice?: number | null;
      readonly limitType?: Types.InterestLevelLimitType | null;
    }>;
    readonly auditInfo: {
      readonly __typename?: 'AuditInfo';
      readonly modifiedByFirmKey?: string | null;
      readonly modifiedByUserEmail?: string | null;
      readonly modifiedAt: string;
      readonly modifiedByFirmName?: string | null;
    };
  }>;
};

export type UpdateHistory_MyIndicationVersionPartsFragment = {
  readonly __typename?: 'MyInstitutionalIndicationVersion';
  readonly id: string;
  readonly indicationId: string;
  readonly trancheId?: string | null;
  readonly instrumentId?: string | null;
  readonly currencyCode?: string | null;
  readonly type: Types.InstitutionalIndicationOrderType;
  readonly status: Types.IndicationStatus;
  readonly version: string;
  readonly isBuySideRevision: boolean;
  readonly tranche?: {
    readonly __typename?: 'TranchePrivileged';
    readonly id: string;
    readonly name: string;
    readonly demandCurrencies: ReadonlyArray<string>;
    readonly deliveryInstruments: ReadonlyArray<{
      readonly __typename?: 'DeliveryInstrumentPrivileged';
      readonly id: string;
      readonly isDepositaryReceipt: boolean;
      readonly stockSymbol?: string | null;
      readonly currencyCode: string;
      readonly countryCode: string;
    }>;
  } | null;
  readonly interestLevels: ReadonlyArray<{
    readonly __typename?: 'InterestLevel';
    readonly interestUnit: Types.InterestLevelInterestUnit;
    readonly interestQuantity: number;
    readonly limitPrice?: number | null;
    readonly limitType?: Types.InterestLevelLimitType | null;
  }>;
  readonly auditInfo: {
    readonly __typename?: 'AuditInfo';
    readonly modifiedByFirmKey?: string | null;
    readonly modifiedByUserEmail?: string | null;
    readonly modifiedAt: string;
    readonly modifiedByFirmName?: string | null;
  };
};

export type UpdateHistory_MyIndicationVersionsQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  myCmgEntityKey: Types.Scalars['String'];
}>;

export type UpdateHistory_MyIndicationVersionsQuery = {
  readonly __typename?: 'Query';
  readonly myIndicationVersions: ReadonlyArray<{
    readonly __typename?: 'MyInstitutionalIndicationVersion';
    readonly id: string;
    readonly indicationId: string;
    readonly trancheId?: string | null;
    readonly instrumentId?: string | null;
    readonly currencyCode?: string | null;
    readonly type: Types.InstitutionalIndicationOrderType;
    readonly status: Types.IndicationStatus;
    readonly version: string;
    readonly isBuySideRevision: boolean;
    readonly tranche?: {
      readonly __typename?: 'TranchePrivileged';
      readonly id: string;
      readonly name: string;
      readonly demandCurrencies: ReadonlyArray<string>;
      readonly deliveryInstruments: ReadonlyArray<{
        readonly __typename?: 'DeliveryInstrumentPrivileged';
        readonly id: string;
        readonly isDepositaryReceipt: boolean;
        readonly stockSymbol?: string | null;
        readonly currencyCode: string;
        readonly countryCode: string;
      }>;
    } | null;
    readonly interestLevels: ReadonlyArray<{
      readonly __typename?: 'InterestLevel';
      readonly interestUnit: Types.InterestLevelInterestUnit;
      readonly interestQuantity: number;
      readonly limitPrice?: number | null;
      readonly limitType?: Types.InterestLevelLimitType | null;
    }>;
    readonly auditInfo: {
      readonly __typename?: 'AuditInfo';
      readonly modifiedByFirmKey?: string | null;
      readonly modifiedByUserEmail?: string | null;
      readonly modifiedAt: string;
      readonly modifiedByFirmName?: string | null;
    };
  }>;
};

export const UpdateHistory_CoveredIndicationVersionPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UpdateHistory_CoveredIndicationVersionParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CoveredInstitutionalIndicationVersion' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'indicationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trancheId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'instrumentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investorInformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'contactName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bankInvestorName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tranche' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'demandCurrencies' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deliveryInstruments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isDepositaryReceipt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'stockSymbol' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'interestLevels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'interestUnit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'interestQuantity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limitPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limitType' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'auditInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'modifiedByFirmKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modifiedByUserEmail' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modifiedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modifiedByFirmName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isBuySideRevision' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const UpdateHistory_IndicationVersionPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UpdateHistory_IndicationVersionParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstitutionalIndicationVersion' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'indicationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trancheId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'instrumentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investorInformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'contactName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bankInvestorName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tranche' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'demandCurrencies' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deliveryInstruments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isDepositaryReceipt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'stockSymbol' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'interestLevels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'interestUnit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'interestQuantity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limitPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limitType' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'auditInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'modifiedByFirmKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modifiedByUserEmail' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modifiedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modifiedByFirmName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isBuySideRevision' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const UpdateHistory_MyIndicationVersionPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UpdateHistory_MyIndicationVersionParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MyInstitutionalIndicationVersion' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'indicationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trancheId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'instrumentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tranche' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'demandCurrencies' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deliveryInstruments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isDepositaryReceipt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'stockSymbol' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'interestLevels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'interestUnit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'interestQuantity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limitPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limitType' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'auditInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'modifiedByFirmKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modifiedByUserEmail' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modifiedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modifiedByFirmName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isBuySideRevision' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const UpdateHistory_CoveredIndicationVersionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UpdateHistory_CoveredIndicationVersions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'indicationId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'coveredIndicationVersions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'indicationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'indicationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UpdateHistory_CoveredIndicationVersionParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UpdateHistory_CoveredIndicationVersionParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CoveredInstitutionalIndicationVersion' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'indicationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trancheId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'instrumentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investorInformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'contactName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bankInvestorName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tranche' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'demandCurrencies' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deliveryInstruments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isDepositaryReceipt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'stockSymbol' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'interestLevels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'interestUnit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'interestQuantity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limitPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limitType' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'auditInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'modifiedByFirmKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modifiedByUserEmail' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modifiedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modifiedByFirmName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isBuySideRevision' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useUpdateHistory_CoveredIndicationVersionsQuery__
 *
 * To run a query within a React component, call `useUpdateHistory_CoveredIndicationVersionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpdateHistory_CoveredIndicationVersionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdateHistory_CoveredIndicationVersionsQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      indicationId: // value for 'indicationId'
 *   },
 * });
 */
export function useUpdateHistory_CoveredIndicationVersionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    UpdateHistory_CoveredIndicationVersionsQuery,
    UpdateHistory_CoveredIndicationVersionsQueryVariables
  > &
    (
      | { variables: UpdateHistory_CoveredIndicationVersionsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    UpdateHistory_CoveredIndicationVersionsQuery,
    UpdateHistory_CoveredIndicationVersionsQueryVariables
  >(UpdateHistory_CoveredIndicationVersionsDocument, options);
}
export function useUpdateHistory_CoveredIndicationVersionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UpdateHistory_CoveredIndicationVersionsQuery,
    UpdateHistory_CoveredIndicationVersionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    UpdateHistory_CoveredIndicationVersionsQuery,
    UpdateHistory_CoveredIndicationVersionsQueryVariables
  >(UpdateHistory_CoveredIndicationVersionsDocument, options);
}
export function useUpdateHistory_CoveredIndicationVersionsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        UpdateHistory_CoveredIndicationVersionsQuery,
        UpdateHistory_CoveredIndicationVersionsQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    UpdateHistory_CoveredIndicationVersionsQuery,
    UpdateHistory_CoveredIndicationVersionsQueryVariables
  >(UpdateHistory_CoveredIndicationVersionsDocument, options);
}
export type UpdateHistory_CoveredIndicationVersionsQueryHookResult = ReturnType<
  typeof useUpdateHistory_CoveredIndicationVersionsQuery
>;
export type UpdateHistory_CoveredIndicationVersionsLazyQueryHookResult = ReturnType<
  typeof useUpdateHistory_CoveredIndicationVersionsLazyQuery
>;
export type UpdateHistory_CoveredIndicationVersionsSuspenseQueryHookResult = ReturnType<
  typeof useUpdateHistory_CoveredIndicationVersionsSuspenseQuery
>;
export type UpdateHistory_CoveredIndicationVersionsQueryResult = Apollo.QueryResult<
  UpdateHistory_CoveredIndicationVersionsQuery,
  UpdateHistory_CoveredIndicationVersionsQueryVariables
>;
export const UpdateHistory_IndicationVersionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UpdateHistory_IndicationVersions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'indicationId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'indicationVersions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'indicationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'indicationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UpdateHistory_IndicationVersionParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UpdateHistory_IndicationVersionParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstitutionalIndicationVersion' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'indicationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trancheId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'instrumentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investorInformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'contactName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bankInvestorName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tranche' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'demandCurrencies' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deliveryInstruments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isDepositaryReceipt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'stockSymbol' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'interestLevels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'interestUnit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'interestQuantity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limitPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limitType' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'auditInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'modifiedByFirmKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modifiedByUserEmail' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modifiedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modifiedByFirmName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isBuySideRevision' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useUpdateHistory_IndicationVersionsQuery__
 *
 * To run a query within a React component, call `useUpdateHistory_IndicationVersionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpdateHistory_IndicationVersionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdateHistory_IndicationVersionsQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      indicationId: // value for 'indicationId'
 *   },
 * });
 */
export function useUpdateHistory_IndicationVersionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    UpdateHistory_IndicationVersionsQuery,
    UpdateHistory_IndicationVersionsQueryVariables
  > &
    (
      | { variables: UpdateHistory_IndicationVersionsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    UpdateHistory_IndicationVersionsQuery,
    UpdateHistory_IndicationVersionsQueryVariables
  >(UpdateHistory_IndicationVersionsDocument, options);
}
export function useUpdateHistory_IndicationVersionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UpdateHistory_IndicationVersionsQuery,
    UpdateHistory_IndicationVersionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    UpdateHistory_IndicationVersionsQuery,
    UpdateHistory_IndicationVersionsQueryVariables
  >(UpdateHistory_IndicationVersionsDocument, options);
}
export function useUpdateHistory_IndicationVersionsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        UpdateHistory_IndicationVersionsQuery,
        UpdateHistory_IndicationVersionsQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    UpdateHistory_IndicationVersionsQuery,
    UpdateHistory_IndicationVersionsQueryVariables
  >(UpdateHistory_IndicationVersionsDocument, options);
}
export type UpdateHistory_IndicationVersionsQueryHookResult = ReturnType<
  typeof useUpdateHistory_IndicationVersionsQuery
>;
export type UpdateHistory_IndicationVersionsLazyQueryHookResult = ReturnType<
  typeof useUpdateHistory_IndicationVersionsLazyQuery
>;
export type UpdateHistory_IndicationVersionsSuspenseQueryHookResult = ReturnType<
  typeof useUpdateHistory_IndicationVersionsSuspenseQuery
>;
export type UpdateHistory_IndicationVersionsQueryResult = Apollo.QueryResult<
  UpdateHistory_IndicationVersionsQuery,
  UpdateHistory_IndicationVersionsQueryVariables
>;
export const UpdateHistory_MyIndicationVersionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UpdateHistory_MyIndicationVersions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'myCmgEntityKey' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myIndicationVersions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'myCmgEntityKey' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'myCmgEntityKey' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UpdateHistory_MyIndicationVersionParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UpdateHistory_MyIndicationVersionParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MyInstitutionalIndicationVersion' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'indicationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trancheId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'instrumentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tranche' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'demandCurrencies' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deliveryInstruments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isDepositaryReceipt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'stockSymbol' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'interestLevels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'interestUnit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'interestQuantity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limitPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limitType' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'auditInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'modifiedByFirmKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modifiedByUserEmail' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modifiedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modifiedByFirmName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isBuySideRevision' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useUpdateHistory_MyIndicationVersionsQuery__
 *
 * To run a query within a React component, call `useUpdateHistory_MyIndicationVersionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpdateHistory_MyIndicationVersionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdateHistory_MyIndicationVersionsQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      myCmgEntityKey: // value for 'myCmgEntityKey'
 *   },
 * });
 */
export function useUpdateHistory_MyIndicationVersionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    UpdateHistory_MyIndicationVersionsQuery,
    UpdateHistory_MyIndicationVersionsQueryVariables
  > &
    (
      | { variables: UpdateHistory_MyIndicationVersionsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    UpdateHistory_MyIndicationVersionsQuery,
    UpdateHistory_MyIndicationVersionsQueryVariables
  >(UpdateHistory_MyIndicationVersionsDocument, options);
}
export function useUpdateHistory_MyIndicationVersionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UpdateHistory_MyIndicationVersionsQuery,
    UpdateHistory_MyIndicationVersionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    UpdateHistory_MyIndicationVersionsQuery,
    UpdateHistory_MyIndicationVersionsQueryVariables
  >(UpdateHistory_MyIndicationVersionsDocument, options);
}
export function useUpdateHistory_MyIndicationVersionsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        UpdateHistory_MyIndicationVersionsQuery,
        UpdateHistory_MyIndicationVersionsQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    UpdateHistory_MyIndicationVersionsQuery,
    UpdateHistory_MyIndicationVersionsQueryVariables
  >(UpdateHistory_MyIndicationVersionsDocument, options);
}
export type UpdateHistory_MyIndicationVersionsQueryHookResult = ReturnType<
  typeof useUpdateHistory_MyIndicationVersionsQuery
>;
export type UpdateHistory_MyIndicationVersionsLazyQueryHookResult = ReturnType<
  typeof useUpdateHistory_MyIndicationVersionsLazyQuery
>;
export type UpdateHistory_MyIndicationVersionsSuspenseQueryHookResult = ReturnType<
  typeof useUpdateHistory_MyIndicationVersionsSuspenseQuery
>;
export type UpdateHistory_MyIndicationVersionsQueryResult = Apollo.QueryResult<
  UpdateHistory_MyIndicationVersionsQuery,
  UpdateHistory_MyIndicationVersionsQueryVariables
>;

import { makeMockResponse } from '../../../../../../../../graphql/mock-response';

export const makeUpdateHistory_CoveredIndicationVersionsMockResponse = makeMockResponse<
  UpdateHistory_CoveredIndicationVersionsQueryVariables,
  UpdateHistory_CoveredIndicationVersionsQuery
>(UpdateHistory_CoveredIndicationVersionsDocument);

export const makeUpdateHistory_IndicationVersionsMockResponse = makeMockResponse<
  UpdateHistory_IndicationVersionsQueryVariables,
  UpdateHistory_IndicationVersionsQuery
>(UpdateHistory_IndicationVersionsDocument);

export const makeUpdateHistory_MyIndicationVersionsMockResponse = makeMockResponse<
  UpdateHistory_MyIndicationVersionsQueryVariables,
  UpdateHistory_MyIndicationVersionsQuery
>(UpdateHistory_MyIndicationVersionsDocument);
