/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IndicationActivity_FinalAllocationPartsFragment = {
  readonly __typename?: 'FinalAllocation';
  readonly id: string;
  readonly indicationId: string;
  readonly shareQuantity?: number | null;
  readonly version: string;
  readonly investorReply?: {
    readonly __typename?: 'InvestorReply';
    readonly status: Types.AllocationAcknowledgement;
  } | null;
};

export type IndicationActivity_CoveredAllocationFragment = {
  readonly __typename?: 'CoveredInstitutionalIndication';
  readonly id: string;
  readonly investorReplyStatus?: Types.AllocationAcknowledgement | null;
  readonly allocationShares?: number | null;
};

export type IndicationActivity_AllocationOfferingPartsFragment = {
  readonly __typename?: 'BasicPublishedOfferingProfile';
  readonly id: string;
  readonly offerPrice?: number | null;
  readonly pricingCurrencyCode: string;
  readonly status: Types.OfferingStatus;
};

export type IndicationActivity_FinalAllocationSetPartsFragment = {
  readonly __typename?: 'FinalInstitutionalAllocationSet';
  readonly releaseTimestamp?: string | null;
  readonly isReleased: boolean;
  readonly isVisibleToNonSyndicate: boolean;
};

export type IndicationActivity_UpdateFinalAllocationAcknowledgementStatusMutationVariables =
  Types.Exact<{
    offeringId: Types.Scalars['UUID'];
    indicationId: Types.Scalars['UUID'];
    investorReplyStatus: Types.AllocationAcknowledgementNewStatus;
    versionId: Types.Scalars['String'];
  }>;

export type IndicationActivity_UpdateFinalAllocationAcknowledgementStatusMutation = {
  readonly __typename?: 'Mutation';
  readonly updateFinalInstitutionalAllocationAcknowledgementStatus: {
    readonly __typename?: 'FinalAllocation';
    readonly id: string;
    readonly indicationId: string;
    readonly shareQuantity?: number | null;
    readonly version: string;
    readonly investorReply?: {
      readonly __typename?: 'InvestorReply';
      readonly status: Types.AllocationAcknowledgement;
    } | null;
  };
};

export type IndicationActivity_AcknowledgeMyFinalAllocationMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  versionId: Types.Scalars['String'];
}>;

export type IndicationActivity_AcknowledgeMyFinalAllocationMutation = {
  readonly __typename?: 'Mutation';
  readonly acknowledgeMyFinalInstitutionalAllocation: {
    readonly __typename?: 'FinalAllocation';
    readonly id: string;
    readonly indicationId: string;
    readonly shareQuantity?: number | null;
    readonly version: string;
    readonly investorReply?: {
      readonly __typename?: 'InvestorReply';
      readonly status: Types.AllocationAcknowledgement;
    } | null;
  };
};

export type IndicationActivity_AcknowledgeCoveredIndicationMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  indicationId: Types.Scalars['UUID'];
  versionId: Types.Scalars['String'];
}>;

export type IndicationActivity_AcknowledgeCoveredIndicationMutation = {
  readonly __typename?: 'Mutation';
  readonly acknowledgeCoveredInstitutionalIndication: {
    readonly __typename?: 'CoveredInstitutionalIndication';
    readonly id: string;
    readonly investorReplyStatus?: Types.AllocationAcknowledgement | null;
    readonly allocationShares?: number | null;
  };
};

export type IndicationActivity_OfferingAllocationQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  indicationId: Types.Scalars['UUID'];
  isSellSide: Types.Scalars['Boolean'];
}>;

export type IndicationActivity_OfferingAllocationQuery = {
  readonly __typename?: 'Query';
  readonly basicPublishedOffering: {
    readonly __typename?: 'BasicPublishedOfferingProfile';
    readonly id: string;
    readonly orderBook?: {
      readonly __typename?: 'OrderBook';
      readonly id: string;
      readonly institutionalIndication?: {
        readonly __typename?: 'InstitutionalIndication';
        readonly id: string;
        readonly finalAllocation?: {
          readonly __typename?: 'FinalAllocation';
          readonly id: string;
          readonly indicationId: string;
          readonly shareQuantity?: number | null;
          readonly version: string;
          readonly investorReply?: {
            readonly __typename?: 'InvestorReply';
            readonly status: Types.AllocationAcknowledgement;
          } | null;
        } | null;
      } | null;
    };
    readonly myInstitutionalIndication?: {
      readonly __typename?: 'MyInstitutionalIndication';
      readonly id: string;
      readonly finalAllocation?: {
        readonly __typename?: 'FinalAllocation';
        readonly id: string;
        readonly indicationId: string;
        readonly shareQuantity?: number | null;
        readonly version: string;
        readonly investorReply?: {
          readonly __typename?: 'InvestorReply';
          readonly status: Types.AllocationAcknowledgement;
        } | null;
      } | null;
    } | null;
  };
};

export const IndicationActivity_FinalAllocationPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'IndicationActivity_FinalAllocationParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FinalAllocation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'indicationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shareQuantity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investorReply' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'status' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const IndicationActivity_CoveredAllocationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'IndicationActivity_CoveredAllocation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CoveredInstitutionalIndication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorReplyStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allocationShares' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const IndicationActivity_AllocationOfferingPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'IndicationActivity_AllocationOfferingParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BasicPublishedOfferingProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offerPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingCurrencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const IndicationActivity_FinalAllocationSetPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'IndicationActivity_FinalAllocationSetParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FinalInstitutionalAllocationSet' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'releaseTimestamp' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isReleased' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isVisibleToNonSyndicate' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const IndicationActivity_UpdateFinalAllocationAcknowledgementStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {
        kind: 'Name',
        value: 'IndicationActivity_UpdateFinalAllocationAcknowledgementStatus',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'indicationId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'investorReplyStatus' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AllocationAcknowledgementNewStatus' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'versionId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'updateFinalInstitutionalAllocationAcknowledgementStatus',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'indicationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'indicationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'investorReplyStatus' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'investorReplyStatus' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'versionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'versionId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'IndicationActivity_FinalAllocationParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'IndicationActivity_FinalAllocationParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FinalAllocation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'indicationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shareQuantity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investorReply' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'status' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type IndicationActivity_UpdateFinalAllocationAcknowledgementStatusMutationFn =
  Apollo.MutationFunction<
    IndicationActivity_UpdateFinalAllocationAcknowledgementStatusMutation,
    IndicationActivity_UpdateFinalAllocationAcknowledgementStatusMutationVariables
  >;

/**
 * __useIndicationActivity_UpdateFinalAllocationAcknowledgementStatusMutation__
 *
 * To run a mutation, you first call `useIndicationActivity_UpdateFinalAllocationAcknowledgementStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIndicationActivity_UpdateFinalAllocationAcknowledgementStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [indicationActivityUpdateFinalAllocationAcknowledgementStatusMutation, { data, loading, error }] = useIndicationActivity_UpdateFinalAllocationAcknowledgementStatusMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      indicationId: // value for 'indicationId'
 *      investorReplyStatus: // value for 'investorReplyStatus'
 *      versionId: // value for 'versionId'
 *   },
 * });
 */
export function useIndicationActivity_UpdateFinalAllocationAcknowledgementStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IndicationActivity_UpdateFinalAllocationAcknowledgementStatusMutation,
    IndicationActivity_UpdateFinalAllocationAcknowledgementStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    IndicationActivity_UpdateFinalAllocationAcknowledgementStatusMutation,
    IndicationActivity_UpdateFinalAllocationAcknowledgementStatusMutationVariables
  >(IndicationActivity_UpdateFinalAllocationAcknowledgementStatusDocument, options);
}
export type IndicationActivity_UpdateFinalAllocationAcknowledgementStatusMutationHookResult =
  ReturnType<typeof useIndicationActivity_UpdateFinalAllocationAcknowledgementStatusMutation>;
export type IndicationActivity_UpdateFinalAllocationAcknowledgementStatusMutationResult =
  Apollo.MutationResult<IndicationActivity_UpdateFinalAllocationAcknowledgementStatusMutation>;
export type IndicationActivity_UpdateFinalAllocationAcknowledgementStatusMutationOptions =
  Apollo.BaseMutationOptions<
    IndicationActivity_UpdateFinalAllocationAcknowledgementStatusMutation,
    IndicationActivity_UpdateFinalAllocationAcknowledgementStatusMutationVariables
  >;
export const IndicationActivity_AcknowledgeMyFinalAllocationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'IndicationActivity_AcknowledgeMyFinalAllocation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'versionId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'acknowledgeMyFinalInstitutionalAllocation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'versionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'versionId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'IndicationActivity_FinalAllocationParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'IndicationActivity_FinalAllocationParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FinalAllocation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'indicationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shareQuantity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investorReply' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'status' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type IndicationActivity_AcknowledgeMyFinalAllocationMutationFn = Apollo.MutationFunction<
  IndicationActivity_AcknowledgeMyFinalAllocationMutation,
  IndicationActivity_AcknowledgeMyFinalAllocationMutationVariables
>;

/**
 * __useIndicationActivity_AcknowledgeMyFinalAllocationMutation__
 *
 * To run a mutation, you first call `useIndicationActivity_AcknowledgeMyFinalAllocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIndicationActivity_AcknowledgeMyFinalAllocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [indicationActivityAcknowledgeMyFinalAllocationMutation, { data, loading, error }] = useIndicationActivity_AcknowledgeMyFinalAllocationMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      versionId: // value for 'versionId'
 *   },
 * });
 */
export function useIndicationActivity_AcknowledgeMyFinalAllocationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IndicationActivity_AcknowledgeMyFinalAllocationMutation,
    IndicationActivity_AcknowledgeMyFinalAllocationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    IndicationActivity_AcknowledgeMyFinalAllocationMutation,
    IndicationActivity_AcknowledgeMyFinalAllocationMutationVariables
  >(IndicationActivity_AcknowledgeMyFinalAllocationDocument, options);
}
export type IndicationActivity_AcknowledgeMyFinalAllocationMutationHookResult = ReturnType<
  typeof useIndicationActivity_AcknowledgeMyFinalAllocationMutation
>;
export type IndicationActivity_AcknowledgeMyFinalAllocationMutationResult =
  Apollo.MutationResult<IndicationActivity_AcknowledgeMyFinalAllocationMutation>;
export type IndicationActivity_AcknowledgeMyFinalAllocationMutationOptions =
  Apollo.BaseMutationOptions<
    IndicationActivity_AcknowledgeMyFinalAllocationMutation,
    IndicationActivity_AcknowledgeMyFinalAllocationMutationVariables
  >;
export const IndicationActivity_AcknowledgeCoveredIndicationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'IndicationActivity_AcknowledgeCoveredIndication' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'indicationId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'versionId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'acknowledgeCoveredInstitutionalIndication' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'indicationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'indicationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'versionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'versionId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'IndicationActivity_CoveredAllocation' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'IndicationActivity_CoveredAllocation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CoveredInstitutionalIndication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorReplyStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allocationShares' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type IndicationActivity_AcknowledgeCoveredIndicationMutationFn = Apollo.MutationFunction<
  IndicationActivity_AcknowledgeCoveredIndicationMutation,
  IndicationActivity_AcknowledgeCoveredIndicationMutationVariables
>;

/**
 * __useIndicationActivity_AcknowledgeCoveredIndicationMutation__
 *
 * To run a mutation, you first call `useIndicationActivity_AcknowledgeCoveredIndicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIndicationActivity_AcknowledgeCoveredIndicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [indicationActivityAcknowledgeCoveredIndicationMutation, { data, loading, error }] = useIndicationActivity_AcknowledgeCoveredIndicationMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      indicationId: // value for 'indicationId'
 *      versionId: // value for 'versionId'
 *   },
 * });
 */
export function useIndicationActivity_AcknowledgeCoveredIndicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IndicationActivity_AcknowledgeCoveredIndicationMutation,
    IndicationActivity_AcknowledgeCoveredIndicationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    IndicationActivity_AcknowledgeCoveredIndicationMutation,
    IndicationActivity_AcknowledgeCoveredIndicationMutationVariables
  >(IndicationActivity_AcknowledgeCoveredIndicationDocument, options);
}
export type IndicationActivity_AcknowledgeCoveredIndicationMutationHookResult = ReturnType<
  typeof useIndicationActivity_AcknowledgeCoveredIndicationMutation
>;
export type IndicationActivity_AcknowledgeCoveredIndicationMutationResult =
  Apollo.MutationResult<IndicationActivity_AcknowledgeCoveredIndicationMutation>;
export type IndicationActivity_AcknowledgeCoveredIndicationMutationOptions =
  Apollo.BaseMutationOptions<
    IndicationActivity_AcknowledgeCoveredIndicationMutation,
    IndicationActivity_AcknowledgeCoveredIndicationMutationVariables
  >;
export const IndicationActivity_OfferingAllocationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'IndicationActivity_OfferingAllocation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'indicationId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'isSellSide' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'basicPublishedOffering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'orderBook' },
                  directives: [
                    {
                      kind: 'Directive',
                      name: { kind: 'Name', value: 'include' },
                      arguments: [
                        {
                          kind: 'Argument',
                          name: { kind: 'Name', value: 'if' },
                          value: { kind: 'Variable', name: { kind: 'Name', value: 'isSellSide' } },
                        },
                      ],
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'institutionalIndication' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'id' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'indicationId' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'finalAllocation' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'IndicationActivity_FinalAllocationParts',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'myInstitutionalIndication' },
                  directives: [
                    {
                      kind: 'Directive',
                      name: { kind: 'Name', value: 'skip' },
                      arguments: [
                        {
                          kind: 'Argument',
                          name: { kind: 'Name', value: 'if' },
                          value: { kind: 'Variable', name: { kind: 'Name', value: 'isSellSide' } },
                        },
                      ],
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'finalAllocation' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'IndicationActivity_FinalAllocationParts',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'IndicationActivity_FinalAllocationParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FinalAllocation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'indicationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shareQuantity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investorReply' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'status' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useIndicationActivity_OfferingAllocationQuery__
 *
 * To run a query within a React component, call `useIndicationActivity_OfferingAllocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useIndicationActivity_OfferingAllocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIndicationActivity_OfferingAllocationQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      indicationId: // value for 'indicationId'
 *      isSellSide: // value for 'isSellSide'
 *   },
 * });
 */
export function useIndicationActivity_OfferingAllocationQuery(
  baseOptions: Apollo.QueryHookOptions<
    IndicationActivity_OfferingAllocationQuery,
    IndicationActivity_OfferingAllocationQueryVariables
  > &
    (
      | { variables: IndicationActivity_OfferingAllocationQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    IndicationActivity_OfferingAllocationQuery,
    IndicationActivity_OfferingAllocationQueryVariables
  >(IndicationActivity_OfferingAllocationDocument, options);
}
export function useIndicationActivity_OfferingAllocationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IndicationActivity_OfferingAllocationQuery,
    IndicationActivity_OfferingAllocationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    IndicationActivity_OfferingAllocationQuery,
    IndicationActivity_OfferingAllocationQueryVariables
  >(IndicationActivity_OfferingAllocationDocument, options);
}
export function useIndicationActivity_OfferingAllocationSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        IndicationActivity_OfferingAllocationQuery,
        IndicationActivity_OfferingAllocationQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    IndicationActivity_OfferingAllocationQuery,
    IndicationActivity_OfferingAllocationQueryVariables
  >(IndicationActivity_OfferingAllocationDocument, options);
}
export type IndicationActivity_OfferingAllocationQueryHookResult = ReturnType<
  typeof useIndicationActivity_OfferingAllocationQuery
>;
export type IndicationActivity_OfferingAllocationLazyQueryHookResult = ReturnType<
  typeof useIndicationActivity_OfferingAllocationLazyQuery
>;
export type IndicationActivity_OfferingAllocationSuspenseQueryHookResult = ReturnType<
  typeof useIndicationActivity_OfferingAllocationSuspenseQuery
>;
export type IndicationActivity_OfferingAllocationQueryResult = Apollo.QueryResult<
  IndicationActivity_OfferingAllocationQuery,
  IndicationActivity_OfferingAllocationQueryVariables
>;

import { makeMockResponse } from '../../../../../../../../graphql/mock-response';

export const makeIndicationActivity_OfferingAllocationMockResponse = makeMockResponse<
  IndicationActivity_OfferingAllocationQueryVariables,
  IndicationActivity_OfferingAllocationQuery
>(IndicationActivity_OfferingAllocationDocument);
