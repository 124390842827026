/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OfferingSetup_CreateOrUpdateDemandCurrenciesMutationVariables = Types.Exact<{
  input: Types.CreateOrUpdateDemandCurrenciesInput;
}>;

export type OfferingSetup_CreateOrUpdateDemandCurrenciesMutation = {
  readonly __typename?: 'Mutation';
  readonly createOrUpdateDemandCurrencies?: {
    readonly __typename?: 'CreateOrUpdateDemandCurrenciesPayload';
    readonly demandCurrency?: ReadonlyArray<{
      readonly __typename?: 'DemandCurrency';
      readonly id: string;
      readonly currencyCode: string;
      readonly exchangeRate: number;
      readonly recordStatus: Types.RecordStatus;
    }> | null;
  } | null;
};

export type OfferingSetup_DemandCurrencyPartsFragment = {
  readonly __typename?: 'DemandCurrency';
  readonly id: string;
  readonly currencyCode: string;
  readonly exchangeRate: number;
  readonly recordStatus: Types.RecordStatus;
};

export type OfferingSetup_DemandCurrenciesQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type OfferingSetup_DemandCurrenciesQuery = {
  readonly __typename?: 'Query';
  readonly offering: {
    readonly __typename?: 'Offering';
    readonly id: string;
    readonly pricingCurrencyCode: string;
    readonly currencies: ReadonlyArray<{
      readonly __typename?: 'DemandCurrency';
      readonly id: string;
      readonly currencyCode: string;
      readonly exchangeRate: number;
      readonly recordStatus: Types.RecordStatus;
    }>;
  };
};

export type OfferingSetup_CreateDemandCurrencyMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  input: Types.CreateDemandCurrencyInput;
}>;

export type OfferingSetup_CreateDemandCurrencyMutation = {
  readonly __typename?: 'Mutation';
  readonly createDemandCurrency: {
    readonly __typename?: 'DemandCurrency';
    readonly id: string;
    readonly currencyCode: string;
    readonly exchangeRate: number;
    readonly recordStatus: Types.RecordStatus;
  };
};

export type OfferingSetup_DeleteDemandCurrencyMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  currencyCode: Types.Scalars['String'];
}>;

export type OfferingSetup_DeleteDemandCurrencyMutation = {
  readonly __typename?: 'Mutation';
  readonly deleteDemandCurrency: string;
};

export type OfferingSetup_SetPricingCurrencyMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  currencyCode: Types.Scalars['String'];
}>;

export type OfferingSetup_SetPricingCurrencyMutation = {
  readonly __typename?: 'Mutation';
  readonly setPricingCurrency: {
    readonly __typename?: 'Offering';
    readonly id: string;
    readonly pricingCurrencyCode: string;
  };
};

export type OfferingSetup_UpdateDemandCurrencyMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  currencyCode: Types.Scalars['String'];
  input: Types.UpdateDemandCurrencyInput;
}>;

export type OfferingSetup_UpdateDemandCurrencyMutation = {
  readonly __typename?: 'Mutation';
  readonly updateDemandCurrency: {
    readonly __typename?: 'DemandCurrency';
    readonly id: string;
    readonly currencyCode: string;
    readonly exchangeRate: number;
    readonly recordStatus: Types.RecordStatus;
  };
};

export const OfferingSetup_DemandCurrencyPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_DemandCurrencyParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DemandCurrency' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'exchangeRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recordStatus' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const OfferingSetup_CreateOrUpdateDemandCurrenciesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'OfferingSetup_CreateOrUpdateDemandCurrencies' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateOrUpdateDemandCurrenciesInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createOrUpdateDemandCurrencies' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'demandCurrency' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'exchangeRate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'recordStatus' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type OfferingSetup_CreateOrUpdateDemandCurrenciesMutationFn = Apollo.MutationFunction<
  OfferingSetup_CreateOrUpdateDemandCurrenciesMutation,
  OfferingSetup_CreateOrUpdateDemandCurrenciesMutationVariables
>;

/**
 * __useOfferingSetup_CreateOrUpdateDemandCurrenciesMutation__
 *
 * To run a mutation, you first call `useOfferingSetup_CreateOrUpdateDemandCurrenciesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOfferingSetup_CreateOrUpdateDemandCurrenciesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [offeringSetupCreateOrUpdateDemandCurrenciesMutation, { data, loading, error }] = useOfferingSetup_CreateOrUpdateDemandCurrenciesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOfferingSetup_CreateOrUpdateDemandCurrenciesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OfferingSetup_CreateOrUpdateDemandCurrenciesMutation,
    OfferingSetup_CreateOrUpdateDemandCurrenciesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OfferingSetup_CreateOrUpdateDemandCurrenciesMutation,
    OfferingSetup_CreateOrUpdateDemandCurrenciesMutationVariables
  >(OfferingSetup_CreateOrUpdateDemandCurrenciesDocument, options);
}
export type OfferingSetup_CreateOrUpdateDemandCurrenciesMutationHookResult = ReturnType<
  typeof useOfferingSetup_CreateOrUpdateDemandCurrenciesMutation
>;
export type OfferingSetup_CreateOrUpdateDemandCurrenciesMutationResult =
  Apollo.MutationResult<OfferingSetup_CreateOrUpdateDemandCurrenciesMutation>;
export type OfferingSetup_CreateOrUpdateDemandCurrenciesMutationOptions =
  Apollo.BaseMutationOptions<
    OfferingSetup_CreateOrUpdateDemandCurrenciesMutation,
    OfferingSetup_CreateOrUpdateDemandCurrenciesMutationVariables
  >;
export const OfferingSetup_DemandCurrenciesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'OfferingSetup_DemandCurrencies' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'offering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pricingCurrencyCode' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currencies' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filters' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'recordStatus' },
                            value: { kind: 'EnumValue', value: 'EFFECTIVE' },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'OfferingSetup_DemandCurrencyParts' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_DemandCurrencyParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DemandCurrency' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'exchangeRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recordStatus' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useOfferingSetup_DemandCurrenciesQuery__
 *
 * To run a query within a React component, call `useOfferingSetup_DemandCurrenciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfferingSetup_DemandCurrenciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferingSetup_DemandCurrenciesQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useOfferingSetup_DemandCurrenciesQuery(
  baseOptions: Apollo.QueryHookOptions<
    OfferingSetup_DemandCurrenciesQuery,
    OfferingSetup_DemandCurrenciesQueryVariables
  > &
    (
      | { variables: OfferingSetup_DemandCurrenciesQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OfferingSetup_DemandCurrenciesQuery,
    OfferingSetup_DemandCurrenciesQueryVariables
  >(OfferingSetup_DemandCurrenciesDocument, options);
}
export function useOfferingSetup_DemandCurrenciesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OfferingSetup_DemandCurrenciesQuery,
    OfferingSetup_DemandCurrenciesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OfferingSetup_DemandCurrenciesQuery,
    OfferingSetup_DemandCurrenciesQueryVariables
  >(OfferingSetup_DemandCurrenciesDocument, options);
}
export function useOfferingSetup_DemandCurrenciesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        OfferingSetup_DemandCurrenciesQuery,
        OfferingSetup_DemandCurrenciesQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    OfferingSetup_DemandCurrenciesQuery,
    OfferingSetup_DemandCurrenciesQueryVariables
  >(OfferingSetup_DemandCurrenciesDocument, options);
}
export type OfferingSetup_DemandCurrenciesQueryHookResult = ReturnType<
  typeof useOfferingSetup_DemandCurrenciesQuery
>;
export type OfferingSetup_DemandCurrenciesLazyQueryHookResult = ReturnType<
  typeof useOfferingSetup_DemandCurrenciesLazyQuery
>;
export type OfferingSetup_DemandCurrenciesSuspenseQueryHookResult = ReturnType<
  typeof useOfferingSetup_DemandCurrenciesSuspenseQuery
>;
export type OfferingSetup_DemandCurrenciesQueryResult = Apollo.QueryResult<
  OfferingSetup_DemandCurrenciesQuery,
  OfferingSetup_DemandCurrenciesQueryVariables
>;
export const OfferingSetup_CreateDemandCurrencyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'OfferingSetup_CreateDemandCurrency' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateDemandCurrencyInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createDemandCurrency' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OfferingSetup_DemandCurrencyParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_DemandCurrencyParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DemandCurrency' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'exchangeRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recordStatus' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type OfferingSetup_CreateDemandCurrencyMutationFn = Apollo.MutationFunction<
  OfferingSetup_CreateDemandCurrencyMutation,
  OfferingSetup_CreateDemandCurrencyMutationVariables
>;

/**
 * __useOfferingSetup_CreateDemandCurrencyMutation__
 *
 * To run a mutation, you first call `useOfferingSetup_CreateDemandCurrencyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOfferingSetup_CreateDemandCurrencyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [offeringSetupCreateDemandCurrencyMutation, { data, loading, error }] = useOfferingSetup_CreateDemandCurrencyMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOfferingSetup_CreateDemandCurrencyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OfferingSetup_CreateDemandCurrencyMutation,
    OfferingSetup_CreateDemandCurrencyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OfferingSetup_CreateDemandCurrencyMutation,
    OfferingSetup_CreateDemandCurrencyMutationVariables
  >(OfferingSetup_CreateDemandCurrencyDocument, options);
}
export type OfferingSetup_CreateDemandCurrencyMutationHookResult = ReturnType<
  typeof useOfferingSetup_CreateDemandCurrencyMutation
>;
export type OfferingSetup_CreateDemandCurrencyMutationResult =
  Apollo.MutationResult<OfferingSetup_CreateDemandCurrencyMutation>;
export type OfferingSetup_CreateDemandCurrencyMutationOptions = Apollo.BaseMutationOptions<
  OfferingSetup_CreateDemandCurrencyMutation,
  OfferingSetup_CreateDemandCurrencyMutationVariables
>;
export const OfferingSetup_DeleteDemandCurrencyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'OfferingSetup_DeleteDemandCurrency' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'currencyCode' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteDemandCurrency' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'currencyCode' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'currencyCode' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type OfferingSetup_DeleteDemandCurrencyMutationFn = Apollo.MutationFunction<
  OfferingSetup_DeleteDemandCurrencyMutation,
  OfferingSetup_DeleteDemandCurrencyMutationVariables
>;

/**
 * __useOfferingSetup_DeleteDemandCurrencyMutation__
 *
 * To run a mutation, you first call `useOfferingSetup_DeleteDemandCurrencyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOfferingSetup_DeleteDemandCurrencyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [offeringSetupDeleteDemandCurrencyMutation, { data, loading, error }] = useOfferingSetup_DeleteDemandCurrencyMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      currencyCode: // value for 'currencyCode'
 *   },
 * });
 */
export function useOfferingSetup_DeleteDemandCurrencyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OfferingSetup_DeleteDemandCurrencyMutation,
    OfferingSetup_DeleteDemandCurrencyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OfferingSetup_DeleteDemandCurrencyMutation,
    OfferingSetup_DeleteDemandCurrencyMutationVariables
  >(OfferingSetup_DeleteDemandCurrencyDocument, options);
}
export type OfferingSetup_DeleteDemandCurrencyMutationHookResult = ReturnType<
  typeof useOfferingSetup_DeleteDemandCurrencyMutation
>;
export type OfferingSetup_DeleteDemandCurrencyMutationResult =
  Apollo.MutationResult<OfferingSetup_DeleteDemandCurrencyMutation>;
export type OfferingSetup_DeleteDemandCurrencyMutationOptions = Apollo.BaseMutationOptions<
  OfferingSetup_DeleteDemandCurrencyMutation,
  OfferingSetup_DeleteDemandCurrencyMutationVariables
>;
export const OfferingSetup_SetPricingCurrencyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'OfferingSetup_SetPricingCurrency' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'currencyCode' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setPricingCurrency' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'currencyCode' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'currencyCode' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pricingCurrencyCode' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type OfferingSetup_SetPricingCurrencyMutationFn = Apollo.MutationFunction<
  OfferingSetup_SetPricingCurrencyMutation,
  OfferingSetup_SetPricingCurrencyMutationVariables
>;

/**
 * __useOfferingSetup_SetPricingCurrencyMutation__
 *
 * To run a mutation, you first call `useOfferingSetup_SetPricingCurrencyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOfferingSetup_SetPricingCurrencyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [offeringSetupSetPricingCurrencyMutation, { data, loading, error }] = useOfferingSetup_SetPricingCurrencyMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      currencyCode: // value for 'currencyCode'
 *   },
 * });
 */
export function useOfferingSetup_SetPricingCurrencyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OfferingSetup_SetPricingCurrencyMutation,
    OfferingSetup_SetPricingCurrencyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OfferingSetup_SetPricingCurrencyMutation,
    OfferingSetup_SetPricingCurrencyMutationVariables
  >(OfferingSetup_SetPricingCurrencyDocument, options);
}
export type OfferingSetup_SetPricingCurrencyMutationHookResult = ReturnType<
  typeof useOfferingSetup_SetPricingCurrencyMutation
>;
export type OfferingSetup_SetPricingCurrencyMutationResult =
  Apollo.MutationResult<OfferingSetup_SetPricingCurrencyMutation>;
export type OfferingSetup_SetPricingCurrencyMutationOptions = Apollo.BaseMutationOptions<
  OfferingSetup_SetPricingCurrencyMutation,
  OfferingSetup_SetPricingCurrencyMutationVariables
>;
export const OfferingSetup_UpdateDemandCurrencyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'OfferingSetup_UpdateDemandCurrency' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'currencyCode' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateDemandCurrencyInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateDemandCurrency' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'currencyCode' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'currencyCode' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OfferingSetup_DemandCurrencyParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_DemandCurrencyParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DemandCurrency' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'exchangeRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recordStatus' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type OfferingSetup_UpdateDemandCurrencyMutationFn = Apollo.MutationFunction<
  OfferingSetup_UpdateDemandCurrencyMutation,
  OfferingSetup_UpdateDemandCurrencyMutationVariables
>;

/**
 * __useOfferingSetup_UpdateDemandCurrencyMutation__
 *
 * To run a mutation, you first call `useOfferingSetup_UpdateDemandCurrencyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOfferingSetup_UpdateDemandCurrencyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [offeringSetupUpdateDemandCurrencyMutation, { data, loading, error }] = useOfferingSetup_UpdateDemandCurrencyMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      currencyCode: // value for 'currencyCode'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOfferingSetup_UpdateDemandCurrencyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OfferingSetup_UpdateDemandCurrencyMutation,
    OfferingSetup_UpdateDemandCurrencyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OfferingSetup_UpdateDemandCurrencyMutation,
    OfferingSetup_UpdateDemandCurrencyMutationVariables
  >(OfferingSetup_UpdateDemandCurrencyDocument, options);
}
export type OfferingSetup_UpdateDemandCurrencyMutationHookResult = ReturnType<
  typeof useOfferingSetup_UpdateDemandCurrencyMutation
>;
export type OfferingSetup_UpdateDemandCurrencyMutationResult =
  Apollo.MutationResult<OfferingSetup_UpdateDemandCurrencyMutation>;
export type OfferingSetup_UpdateDemandCurrencyMutationOptions = Apollo.BaseMutationOptions<
  OfferingSetup_UpdateDemandCurrencyMutation,
  OfferingSetup_UpdateDemandCurrencyMutationVariables
>;

import { makeMockResponse } from '../../../../../graphql/mock-response';

export const makeOfferingSetup_DemandCurrenciesMockResponse = makeMockResponse<
  OfferingSetup_DemandCurrenciesQueryVariables,
  OfferingSetup_DemandCurrenciesQuery
>(OfferingSetup_DemandCurrenciesDocument);
