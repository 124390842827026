/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OrderBook_TotalRetailSummaryItemRetentionPartsFragment = {
  readonly __typename?: 'TotalRetailSummaryItemRetention';
  readonly id: string;
  readonly shareQuantity?: number | null;
  readonly isReleased: boolean;
  readonly modifiedByFirmKey?: string | null;
  readonly modifiedAt?: string | null;
};

export type OrderBook_TotalRetailSummaryItemDemandPartsFragment = {
  readonly __typename?: 'TotalRetailSummaryItemDemand';
  readonly id: string;
  readonly shareQuantity?: number | null;
  readonly roundLots?: number | null;
  readonly modifiedByFirmKey?: string | null;
  readonly modifiedAt?: string | null;
  readonly isSubmitted: boolean;
};

export type OrderBook_TotalRetailSummaryItemManagerPartsFragment = {
  readonly __typename?: 'TotalRetailSummaryItemManager';
  readonly id: string;
  readonly cmgEntityKey: string;
  readonly name: string;
  readonly role: Types.OrderBookManagerRole;
  readonly isOnPlatform: boolean;
};

export type OrderBook_TotalRetailSummaryItemPartsFragment = {
  readonly __typename?: 'TotalRetailSummaryItem';
  readonly id: string;
  readonly retention: {
    readonly __typename?: 'TotalRetailSummaryItemRetention';
    readonly id: string;
    readonly shareQuantity?: number | null;
    readonly isReleased: boolean;
    readonly modifiedByFirmKey?: string | null;
    readonly modifiedAt?: string | null;
  };
  readonly demand: {
    readonly __typename?: 'TotalRetailSummaryItemDemand';
    readonly id: string;
    readonly shareQuantity?: number | null;
    readonly roundLots?: number | null;
    readonly modifiedByFirmKey?: string | null;
    readonly modifiedAt?: string | null;
    readonly isSubmitted: boolean;
  };
  readonly manager: {
    readonly __typename?: 'TotalRetailSummaryItemManager';
    readonly id: string;
    readonly cmgEntityKey: string;
    readonly name: string;
    readonly role: Types.OrderBookManagerRole;
    readonly isOnPlatform: boolean;
  };
};

export type OrderBook_TotalRetailSummaryGridPartsFragment = {
  readonly __typename?: 'TotalRetailSummaryGrid';
  readonly id: string;
  readonly retailTarget?: number | null;
  readonly items: ReadonlyArray<{
    readonly __typename?: 'TotalRetailSummaryItem';
    readonly id: string;
    readonly retention: {
      readonly __typename?: 'TotalRetailSummaryItemRetention';
      readonly id: string;
      readonly shareQuantity?: number | null;
      readonly isReleased: boolean;
      readonly modifiedByFirmKey?: string | null;
      readonly modifiedAt?: string | null;
    };
    readonly demand: {
      readonly __typename?: 'TotalRetailSummaryItemDemand';
      readonly id: string;
      readonly shareQuantity?: number | null;
      readonly roundLots?: number | null;
      readonly modifiedByFirmKey?: string | null;
      readonly modifiedAt?: string | null;
      readonly isSubmitted: boolean;
    };
    readonly manager: {
      readonly __typename?: 'TotalRetailSummaryItemManager';
      readonly id: string;
      readonly cmgEntityKey: string;
      readonly name: string;
      readonly role: Types.OrderBookManagerRole;
      readonly isOnPlatform: boolean;
    };
  }>;
};

export type OrderBook_TotalRetailDemandQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type OrderBook_TotalRetailDemandQuery = {
  readonly __typename?: 'Query';
  readonly totalRetailSummary: {
    readonly __typename?: 'TotalRetailSummaryGrid';
    readonly id: string;
    readonly retailTarget?: number | null;
    readonly items: ReadonlyArray<{
      readonly __typename?: 'TotalRetailSummaryItem';
      readonly id: string;
      readonly retention: {
        readonly __typename?: 'TotalRetailSummaryItemRetention';
        readonly id: string;
        readonly shareQuantity?: number | null;
        readonly isReleased: boolean;
        readonly modifiedByFirmKey?: string | null;
        readonly modifiedAt?: string | null;
      };
      readonly demand: {
        readonly __typename?: 'TotalRetailSummaryItemDemand';
        readonly id: string;
        readonly shareQuantity?: number | null;
        readonly roundLots?: number | null;
        readonly modifiedByFirmKey?: string | null;
        readonly modifiedAt?: string | null;
        readonly isSubmitted: boolean;
      };
      readonly manager: {
        readonly __typename?: 'TotalRetailSummaryItemManager';
        readonly id: string;
        readonly cmgEntityKey: string;
        readonly name: string;
        readonly role: Types.OrderBookManagerRole;
        readonly isOnPlatform: boolean;
      };
    }>;
  };
  readonly publishedOffering: {
    readonly __typename?: 'OfferingProfile';
    readonly instruments: ReadonlyArray<{
      readonly __typename?: 'DeliveryInstrumentPrivileged';
      readonly id: string;
    }>;
    readonly tranches?: ReadonlyArray<{
      readonly __typename?: 'TranchePrivileged';
      readonly id: string;
    }> | null;
  };
};

export const OrderBook_TotalRetailSummaryItemRetentionPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_TotalRetailSummaryItemRetentionParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TotalRetailSummaryItemRetention' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shareQuantity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isReleased' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modifiedByFirmKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modifiedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const OrderBook_TotalRetailSummaryItemDemandPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_TotalRetailSummaryItemDemandParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TotalRetailSummaryItemDemand' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shareQuantity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roundLots' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modifiedByFirmKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modifiedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSubmitted' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const OrderBook_TotalRetailSummaryItemManagerPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_TotalRetailSummaryItemManagerParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TotalRetailSummaryItemManager' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isOnPlatform' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const OrderBook_TotalRetailSummaryItemPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_TotalRetailSummaryItemParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TotalRetailSummaryItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'retention' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OrderBook_TotalRetailSummaryItemRetentionParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'demand' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OrderBook_TotalRetailSummaryItemDemandParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'manager' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OrderBook_TotalRetailSummaryItemManagerParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_TotalRetailSummaryItemRetentionParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TotalRetailSummaryItemRetention' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shareQuantity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isReleased' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modifiedByFirmKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modifiedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_TotalRetailSummaryItemDemandParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TotalRetailSummaryItemDemand' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shareQuantity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roundLots' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modifiedByFirmKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modifiedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSubmitted' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_TotalRetailSummaryItemManagerParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TotalRetailSummaryItemManager' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isOnPlatform' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const OrderBook_TotalRetailSummaryGridPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_TotalRetailSummaryGridParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TotalRetailSummaryGrid' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'retailTarget' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'items' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OrderBook_TotalRetailSummaryItemParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_TotalRetailSummaryItemRetentionParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TotalRetailSummaryItemRetention' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shareQuantity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isReleased' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modifiedByFirmKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modifiedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_TotalRetailSummaryItemDemandParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TotalRetailSummaryItemDemand' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shareQuantity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roundLots' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modifiedByFirmKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modifiedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSubmitted' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_TotalRetailSummaryItemManagerParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TotalRetailSummaryItemManager' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isOnPlatform' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_TotalRetailSummaryItemParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TotalRetailSummaryItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'retention' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OrderBook_TotalRetailSummaryItemRetentionParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'demand' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OrderBook_TotalRetailSummaryItemDemandParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'manager' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OrderBook_TotalRetailSummaryItemManagerParts' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const OrderBook_TotalRetailDemandDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'OrderBook_TotalRetailDemand' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalRetailSummary' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OrderBook_TotalRetailSummaryGridParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'publishedOffering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'instruments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tranches' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_TotalRetailSummaryItemRetentionParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TotalRetailSummaryItemRetention' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shareQuantity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isReleased' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modifiedByFirmKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modifiedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_TotalRetailSummaryItemDemandParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TotalRetailSummaryItemDemand' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shareQuantity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roundLots' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modifiedByFirmKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modifiedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSubmitted' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_TotalRetailSummaryItemManagerParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TotalRetailSummaryItemManager' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isOnPlatform' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_TotalRetailSummaryItemParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TotalRetailSummaryItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'retention' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OrderBook_TotalRetailSummaryItemRetentionParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'demand' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OrderBook_TotalRetailSummaryItemDemandParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'manager' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OrderBook_TotalRetailSummaryItemManagerParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_TotalRetailSummaryGridParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TotalRetailSummaryGrid' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'retailTarget' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'items' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OrderBook_TotalRetailSummaryItemParts' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useOrderBook_TotalRetailDemandQuery__
 *
 * To run a query within a React component, call `useOrderBook_TotalRetailDemandQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderBook_TotalRetailDemandQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderBook_TotalRetailDemandQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useOrderBook_TotalRetailDemandQuery(
  baseOptions: Apollo.QueryHookOptions<
    OrderBook_TotalRetailDemandQuery,
    OrderBook_TotalRetailDemandQueryVariables
  > &
    ({ variables: OrderBook_TotalRetailDemandQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OrderBook_TotalRetailDemandQuery,
    OrderBook_TotalRetailDemandQueryVariables
  >(OrderBook_TotalRetailDemandDocument, options);
}
export function useOrderBook_TotalRetailDemandLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrderBook_TotalRetailDemandQuery,
    OrderBook_TotalRetailDemandQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OrderBook_TotalRetailDemandQuery,
    OrderBook_TotalRetailDemandQueryVariables
  >(OrderBook_TotalRetailDemandDocument, options);
}
export function useOrderBook_TotalRetailDemandSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        OrderBook_TotalRetailDemandQuery,
        OrderBook_TotalRetailDemandQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    OrderBook_TotalRetailDemandQuery,
    OrderBook_TotalRetailDemandQueryVariables
  >(OrderBook_TotalRetailDemandDocument, options);
}
export type OrderBook_TotalRetailDemandQueryHookResult = ReturnType<
  typeof useOrderBook_TotalRetailDemandQuery
>;
export type OrderBook_TotalRetailDemandLazyQueryHookResult = ReturnType<
  typeof useOrderBook_TotalRetailDemandLazyQuery
>;
export type OrderBook_TotalRetailDemandSuspenseQueryHookResult = ReturnType<
  typeof useOrderBook_TotalRetailDemandSuspenseQuery
>;
export type OrderBook_TotalRetailDemandQueryResult = Apollo.QueryResult<
  OrderBook_TotalRetailDemandQuery,
  OrderBook_TotalRetailDemandQueryVariables
>;

import { makeMockResponse } from '../../../../../graphql/mock-response';

export const makeOrderBook_TotalRetailDemandMockResponse = makeMockResponse<
  OrderBook_TotalRetailDemandQueryVariables,
  OrderBook_TotalRetailDemandQuery
>(OrderBook_TotalRetailDemandDocument);
