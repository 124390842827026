/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FundLevelDemand_FundIoisPartsFragment = {
  readonly __typename?: 'FundIois';
  readonly allocations: ReadonlyArray<{
    readonly __typename?: 'FundAllocation';
    readonly id: string;
    readonly fundId: string;
    readonly offeringId: string;
    readonly shares?: number | null;
    readonly fillPercentage?: number | null;
    readonly pctOfFirmAllocation?: number | null;
  }>;
  readonly iois: ReadonlyArray<{
    readonly __typename?: 'FundIoi';
    readonly id: string;
    readonly fundId: string;
    readonly offeringId: string;
    readonly pricingType: Types.IoiPricingType;
    readonly unitType: Types.IoiUnitType;
    readonly limitPrice?: number | null;
    readonly shares?: number | null;
    readonly dollars?: number | null;
    readonly percentage?: number | null;
    readonly calculateShares?: number | null;
    readonly realDemandDollars?: number | null;
    readonly realDemandPercentage?: number | null;
    readonly realDemandShares?: number | null;
  }>;
};

export type FundLevelDemand_PublishedOfferingQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type FundLevelDemand_PublishedOfferingQuery = {
  readonly __typename?: 'Query';
  readonly limitedPublishedOffering: {
    readonly __typename?: 'OfferingLimited';
    readonly id: string;
    readonly funds: ReadonlyArray<{
      readonly __typename?: 'Fund';
      readonly id: string;
      readonly name: string;
    }>;
    readonly fundIois: {
      readonly __typename?: 'FundIois';
      readonly allocations: ReadonlyArray<{
        readonly __typename?: 'FundAllocation';
        readonly id: string;
        readonly fundId: string;
        readonly offeringId: string;
        readonly shares?: number | null;
        readonly fillPercentage?: number | null;
        readonly pctOfFirmAllocation?: number | null;
      }>;
      readonly iois: ReadonlyArray<{
        readonly __typename?: 'FundIoi';
        readonly id: string;
        readonly fundId: string;
        readonly offeringId: string;
        readonly pricingType: Types.IoiPricingType;
        readonly unitType: Types.IoiUnitType;
        readonly limitPrice?: number | null;
        readonly shares?: number | null;
        readonly dollars?: number | null;
        readonly percentage?: number | null;
        readonly calculateShares?: number | null;
        readonly realDemandDollars?: number | null;
        readonly realDemandPercentage?: number | null;
        readonly realDemandShares?: number | null;
      }>;
    };
  };
};

export type FundLevelDemand_UpdateFundIoisMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['ID'];
  input: Types.UpdateFundIoisInput;
}>;

export type FundLevelDemand_UpdateFundIoisMutation = {
  readonly __typename?: 'Mutation';
  readonly updateFundIois: {
    readonly __typename?: 'FundIois';
    readonly allocations: ReadonlyArray<{
      readonly __typename?: 'FundAllocation';
      readonly id: string;
      readonly fundId: string;
      readonly offeringId: string;
      readonly shares?: number | null;
      readonly fillPercentage?: number | null;
      readonly pctOfFirmAllocation?: number | null;
    }>;
    readonly iois: ReadonlyArray<{
      readonly __typename?: 'FundIoi';
      readonly id: string;
      readonly fundId: string;
      readonly offeringId: string;
      readonly pricingType: Types.IoiPricingType;
      readonly unitType: Types.IoiUnitType;
      readonly limitPrice?: number | null;
      readonly shares?: number | null;
      readonly dollars?: number | null;
      readonly percentage?: number | null;
      readonly calculateShares?: number | null;
      readonly realDemandDollars?: number | null;
      readonly realDemandPercentage?: number | null;
      readonly realDemandShares?: number | null;
    }>;
  };
};

export const FundLevelDemand_FundIoisPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundLevelDemand_FundIoisParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FundIois' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fundId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'offeringId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fillPercentage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pctOfFirmAllocation' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'iois' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fundId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'offeringId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pricingType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'unitType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limitPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dollars' } },
                { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'calculateShares' } },
                { kind: 'Field', name: { kind: 'Name', value: 'realDemandDollars' } },
                { kind: 'Field', name: { kind: 'Name', value: 'realDemandPercentage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'realDemandShares' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const FundLevelDemand_PublishedOfferingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FundLevelDemand_PublishedOffering' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'limitedPublishedOffering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'offeringId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'funds' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fundIois' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'FundLevelDemand_FundIoisParts' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundLevelDemand_FundIoisParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FundIois' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fundId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'offeringId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fillPercentage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pctOfFirmAllocation' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'iois' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fundId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'offeringId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pricingType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'unitType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limitPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dollars' } },
                { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'calculateShares' } },
                { kind: 'Field', name: { kind: 'Name', value: 'realDemandDollars' } },
                { kind: 'Field', name: { kind: 'Name', value: 'realDemandPercentage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'realDemandShares' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useFundLevelDemand_PublishedOfferingQuery__
 *
 * To run a query within a React component, call `useFundLevelDemand_PublishedOfferingQuery` and pass it any options that fit your needs.
 * When your component renders, `useFundLevelDemand_PublishedOfferingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFundLevelDemand_PublishedOfferingQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useFundLevelDemand_PublishedOfferingQuery(
  baseOptions: Apollo.QueryHookOptions<
    FundLevelDemand_PublishedOfferingQuery,
    FundLevelDemand_PublishedOfferingQueryVariables
  > &
    (
      | { variables: FundLevelDemand_PublishedOfferingQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FundLevelDemand_PublishedOfferingQuery,
    FundLevelDemand_PublishedOfferingQueryVariables
  >(FundLevelDemand_PublishedOfferingDocument, options);
}
export function useFundLevelDemand_PublishedOfferingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FundLevelDemand_PublishedOfferingQuery,
    FundLevelDemand_PublishedOfferingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FundLevelDemand_PublishedOfferingQuery,
    FundLevelDemand_PublishedOfferingQueryVariables
  >(FundLevelDemand_PublishedOfferingDocument, options);
}
export function useFundLevelDemand_PublishedOfferingSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FundLevelDemand_PublishedOfferingQuery,
        FundLevelDemand_PublishedOfferingQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FundLevelDemand_PublishedOfferingQuery,
    FundLevelDemand_PublishedOfferingQueryVariables
  >(FundLevelDemand_PublishedOfferingDocument, options);
}
export type FundLevelDemand_PublishedOfferingQueryHookResult = ReturnType<
  typeof useFundLevelDemand_PublishedOfferingQuery
>;
export type FundLevelDemand_PublishedOfferingLazyQueryHookResult = ReturnType<
  typeof useFundLevelDemand_PublishedOfferingLazyQuery
>;
export type FundLevelDemand_PublishedOfferingSuspenseQueryHookResult = ReturnType<
  typeof useFundLevelDemand_PublishedOfferingSuspenseQuery
>;
export type FundLevelDemand_PublishedOfferingQueryResult = Apollo.QueryResult<
  FundLevelDemand_PublishedOfferingQuery,
  FundLevelDemand_PublishedOfferingQueryVariables
>;
export const FundLevelDemand_UpdateFundIoisDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'FundLevelDemand_UpdateFundIois' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateFundIoisInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateFundIois' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundLevelDemand_FundIoisParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundLevelDemand_FundIoisParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FundIois' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fundId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'offeringId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fillPercentage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pctOfFirmAllocation' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'iois' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fundId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'offeringId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pricingType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'unitType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limitPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dollars' } },
                { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'calculateShares' } },
                { kind: 'Field', name: { kind: 'Name', value: 'realDemandDollars' } },
                { kind: 'Field', name: { kind: 'Name', value: 'realDemandPercentage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'realDemandShares' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type FundLevelDemand_UpdateFundIoisMutationFn = Apollo.MutationFunction<
  FundLevelDemand_UpdateFundIoisMutation,
  FundLevelDemand_UpdateFundIoisMutationVariables
>;

/**
 * __useFundLevelDemand_UpdateFundIoisMutation__
 *
 * To run a mutation, you first call `useFundLevelDemand_UpdateFundIoisMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFundLevelDemand_UpdateFundIoisMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fundLevelDemandUpdateFundIoisMutation, { data, loading, error }] = useFundLevelDemand_UpdateFundIoisMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFundLevelDemand_UpdateFundIoisMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FundLevelDemand_UpdateFundIoisMutation,
    FundLevelDemand_UpdateFundIoisMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    FundLevelDemand_UpdateFundIoisMutation,
    FundLevelDemand_UpdateFundIoisMutationVariables
  >(FundLevelDemand_UpdateFundIoisDocument, options);
}
export type FundLevelDemand_UpdateFundIoisMutationHookResult = ReturnType<
  typeof useFundLevelDemand_UpdateFundIoisMutation
>;
export type FundLevelDemand_UpdateFundIoisMutationResult =
  Apollo.MutationResult<FundLevelDemand_UpdateFundIoisMutation>;
export type FundLevelDemand_UpdateFundIoisMutationOptions = Apollo.BaseMutationOptions<
  FundLevelDemand_UpdateFundIoisMutation,
  FundLevelDemand_UpdateFundIoisMutationVariables
>;

import { makeMockResponse } from '../../../../../../../../graphql/mock-response';

export const makeFundLevelDemand_PublishedOfferingMockResponse = makeMockResponse<
  FundLevelDemand_PublishedOfferingQueryVariables,
  FundLevelDemand_PublishedOfferingQuery
>(FundLevelDemand_PublishedOfferingDocument);
