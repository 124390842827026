import { useAuth, useStoredPreference } from '@cmg/auth';
import type { UUID } from '@cmg/common';
import type { GridState } from '@cmg/data-grid';
import { useDataGridContext } from '@cmg/data-grid';
import pick from 'lodash/pick';
import { useCallback, useEffect, useState } from 'react';

import { OfferingStatus, OfferingType } from '../../../../../graphql';
import { getCmgTemplateKey, useTemplatesState } from './useTemplatesState';

export type Props = Readonly<{
  offeringId: UUID;
  /**
   * Total number of static columns in the grid.
   */
  staticColCount: number;
  offeringType: OfferingType;
  offeringStatus: OfferingStatus;
}>;

export type StoredGridState = Pick<
  GridState,
  | 'filter'
  | 'sort'
  | 'columnOrder'
  | 'columnVisibility'
  | 'columnPinning'
  | 'columnSizing'
  | 'rowGroup'
>;

const STORED_STATE_PROPS = [
  'filter',
  'sort',
  'columnOrder',
  'columnVisibility',
  'columnPinning',
  'columnSizing',
  'rowGroup',
];

/**
 * This hook persists and retrieves the grid state from the session storage.
 * The initial grid state is applied automatically when the dynamic columns are loaded.
 */
export const useStoredGridState = ({
  offeringId,
  staticColCount,
  offeringType,
  offeringStatus,
}: Props) => {
  const { addEventListener, removeEventListener, getColDefs, getState, applyGridState } =
    useDataGridContext();
  const { oidcUserId } = useAuth();
  const [initialStateApplied, setInitialStateApplied] = useState(false);

  const getCurrentGridState = useCallback(() => {
    return pick(getState() ?? {}, STORED_STATE_PROPS);
  }, [getState]);

  const [preference, setPreference] = useStoredPreference<StoredGridState>({
    key: `demand_grid_state_${offeringId}_${oidcUserId}`,
    initialValue: getCurrentGridState(),
    storageType: 'Session',
  });

  const cmgTemplateKey = getCmgTemplateKey({ offeringType, offeringStatus });
  const { getActiveGridState } = useTemplatesState(offeringId);
  const activeTemplateState = getActiveGridState(cmgTemplateKey);

  const storeGridState = useCallback(() => {
    setPreference(getCurrentGridState());
  }, [getCurrentGridState, setPreference]);

  const handleApplyInitialGridState = useCallback(() => {
    /**
     * We need to wait for the dynamic columns to get loaded before applying the stored grid state.
     * If the current column count is smaller/equal to the static column count, then it means the dynamic columns are not loaded yet.
     */
    const isDynamicColsLoaded = (getColDefs() ?? []).length > staticColCount;

    if (!isDynamicColsLoaded) {
      return;
    }

    if (!initialStateApplied) {
      applyGridState(Object.keys(preference).length === 0 ? activeTemplateState : preference);
      setInitialStateApplied(true);
    }

    removeEventListener('newColumnsLoaded', handleApplyInitialGridState);
  }, [
    getColDefs,
    staticColCount,
    initialStateApplied,
    removeEventListener,
    applyGridState,
    preference,
    activeTemplateState,
  ]);

  useEffect(() => {
    addEventListener('newColumnsLoaded', handleApplyInitialGridState);

    return () => {
      removeEventListener('newColumnsLoaded', handleApplyInitialGridState);
    };
  }, [addEventListener, handleApplyInitialGridState, removeEventListener]);

  return [preference, storeGridState] as const;
};
