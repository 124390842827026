/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OrderBook_TotalRetailInfoPartsFragment = {
  readonly __typename?: 'TotalRetailInfo';
  readonly id: string;
  readonly submittedRetailDemand?: {
    readonly __typename?: 'TotalSubmittedRetailDemandInfo';
    readonly id: string;
    readonly shareQuantity: number;
    readonly submittedDateTime: string;
  } | null;
  readonly releasedRetailRetention?: {
    readonly __typename?: 'TotalRetentionInfo';
    readonly id: string;
    readonly shareQuantity: number;
    readonly releasedDateTime: string;
  } | null;
};

export type OrderBook_RetailBrokerDealerPartsFragment = {
  readonly __typename?: 'RetailBrokerDealer';
  readonly id: string;
  readonly sellingConcessionPercentage?: number | null;
  readonly firmCmgEntityKey?: string | null;
  readonly firmName: string;
  readonly isOnPlatform: boolean;
  readonly retention: {
    readonly __typename?: 'RetailBrokerDealerRetention';
    readonly id: string;
    readonly shareQuantity?: number | null;
    readonly roundLots?: number | null;
  };
  readonly demand: {
    readonly __typename?: 'RetailBrokerDealerDemand';
    readonly id: string;
    readonly shareQuantity: number;
    readonly roundLots?: number | null;
  };
};

export type OrderBook_MyRetailSummaryPartsFragment = {
  readonly __typename?: 'MyRetailSummary';
  readonly id: string;
  readonly sellingConcession?: number | null;
  readonly totalRetailInfo: {
    readonly __typename?: 'TotalRetailInfo';
    readonly id: string;
    readonly submittedRetailDemand?: {
      readonly __typename?: 'TotalSubmittedRetailDemandInfo';
      readonly id: string;
      readonly shareQuantity: number;
      readonly submittedDateTime: string;
    } | null;
    readonly releasedRetailRetention?: {
      readonly __typename?: 'TotalRetentionInfo';
      readonly id: string;
      readonly shareQuantity: number;
      readonly releasedDateTime: string;
    } | null;
  };
  readonly brokerDealers: ReadonlyArray<{
    readonly __typename?: 'RetailBrokerDealer';
    readonly id: string;
    readonly sellingConcessionPercentage?: number | null;
    readonly firmCmgEntityKey?: string | null;
    readonly firmName: string;
    readonly isOnPlatform: boolean;
    readonly retention: {
      readonly __typename?: 'RetailBrokerDealerRetention';
      readonly id: string;
      readonly shareQuantity?: number | null;
      readonly roundLots?: number | null;
    };
    readonly demand: {
      readonly __typename?: 'RetailBrokerDealerDemand';
      readonly id: string;
      readonly shareQuantity: number;
      readonly roundLots?: number | null;
    };
  }>;
};

export type OrderBook_MyRetailDemandQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type OrderBook_MyRetailDemandQuery = {
  readonly __typename?: 'Query';
  readonly myRetailSummary: {
    readonly __typename?: 'MyRetailSummary';
    readonly id: string;
    readonly sellingConcession?: number | null;
    readonly totalRetailInfo: {
      readonly __typename?: 'TotalRetailInfo';
      readonly id: string;
      readonly submittedRetailDemand?: {
        readonly __typename?: 'TotalSubmittedRetailDemandInfo';
        readonly id: string;
        readonly shareQuantity: number;
        readonly submittedDateTime: string;
      } | null;
      readonly releasedRetailRetention?: {
        readonly __typename?: 'TotalRetentionInfo';
        readonly id: string;
        readonly shareQuantity: number;
        readonly releasedDateTime: string;
      } | null;
    };
    readonly brokerDealers: ReadonlyArray<{
      readonly __typename?: 'RetailBrokerDealer';
      readonly id: string;
      readonly sellingConcessionPercentage?: number | null;
      readonly firmCmgEntityKey?: string | null;
      readonly firmName: string;
      readonly isOnPlatform: boolean;
      readonly retention: {
        readonly __typename?: 'RetailBrokerDealerRetention';
        readonly id: string;
        readonly shareQuantity?: number | null;
        readonly roundLots?: number | null;
      };
      readonly demand: {
        readonly __typename?: 'RetailBrokerDealerDemand';
        readonly id: string;
        readonly shareQuantity: number;
        readonly roundLots?: number | null;
      };
    }>;
  };
  readonly publishedOffering: {
    readonly __typename?: 'OfferingProfile';
    readonly id: string;
    readonly instruments: ReadonlyArray<{
      readonly __typename?: 'DeliveryInstrumentPrivileged';
      readonly id: string;
    }>;
    readonly tranches?: ReadonlyArray<{
      readonly __typename?: 'TranchePrivileged';
      readonly id: string;
    }> | null;
  };
};

export const OrderBook_TotalRetailInfoPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_TotalRetailInfoParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TotalRetailInfo' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submittedRetailDemand' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shareQuantity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'submittedDateTime' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'releasedRetailRetention' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shareQuantity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'releasedDateTime' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const OrderBook_RetailBrokerDealerPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_RetailBrokerDealerParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RetailBrokerDealer' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sellingConcessionPercentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmCmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isOnPlatform' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'retention' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shareQuantity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'roundLots' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'demand' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shareQuantity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'roundLots' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const OrderBook_MyRetailSummaryPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_MyRetailSummaryParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MyRetailSummary' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sellingConcession' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalRetailInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OrderBook_TotalRetailInfoParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'brokerDealers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OrderBook_RetailBrokerDealerParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_TotalRetailInfoParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TotalRetailInfo' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submittedRetailDemand' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shareQuantity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'submittedDateTime' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'releasedRetailRetention' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shareQuantity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'releasedDateTime' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_RetailBrokerDealerParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RetailBrokerDealer' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sellingConcessionPercentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmCmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isOnPlatform' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'retention' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shareQuantity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'roundLots' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'demand' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shareQuantity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'roundLots' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const OrderBook_MyRetailDemandDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'OrderBook_MyRetailDemand' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myRetailSummary' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OrderBook_MyRetailSummaryParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'publishedOffering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'instruments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tranches' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_TotalRetailInfoParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TotalRetailInfo' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submittedRetailDemand' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shareQuantity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'submittedDateTime' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'releasedRetailRetention' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shareQuantity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'releasedDateTime' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_RetailBrokerDealerParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RetailBrokerDealer' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sellingConcessionPercentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmCmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isOnPlatform' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'retention' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shareQuantity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'roundLots' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'demand' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shareQuantity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'roundLots' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderBook_MyRetailSummaryParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MyRetailSummary' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sellingConcession' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalRetailInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OrderBook_TotalRetailInfoParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'brokerDealers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OrderBook_RetailBrokerDealerParts' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useOrderBook_MyRetailDemandQuery__
 *
 * To run a query within a React component, call `useOrderBook_MyRetailDemandQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderBook_MyRetailDemandQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderBook_MyRetailDemandQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useOrderBook_MyRetailDemandQuery(
  baseOptions: Apollo.QueryHookOptions<
    OrderBook_MyRetailDemandQuery,
    OrderBook_MyRetailDemandQueryVariables
  > &
    ({ variables: OrderBook_MyRetailDemandQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrderBook_MyRetailDemandQuery, OrderBook_MyRetailDemandQueryVariables>(
    OrderBook_MyRetailDemandDocument,
    options
  );
}
export function useOrderBook_MyRetailDemandLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrderBook_MyRetailDemandQuery,
    OrderBook_MyRetailDemandQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrderBook_MyRetailDemandQuery, OrderBook_MyRetailDemandQueryVariables>(
    OrderBook_MyRetailDemandDocument,
    options
  );
}
export function useOrderBook_MyRetailDemandSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        OrderBook_MyRetailDemandQuery,
        OrderBook_MyRetailDemandQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    OrderBook_MyRetailDemandQuery,
    OrderBook_MyRetailDemandQueryVariables
  >(OrderBook_MyRetailDemandDocument, options);
}
export type OrderBook_MyRetailDemandQueryHookResult = ReturnType<
  typeof useOrderBook_MyRetailDemandQuery
>;
export type OrderBook_MyRetailDemandLazyQueryHookResult = ReturnType<
  typeof useOrderBook_MyRetailDemandLazyQuery
>;
export type OrderBook_MyRetailDemandSuspenseQueryHookResult = ReturnType<
  typeof useOrderBook_MyRetailDemandSuspenseQuery
>;
export type OrderBook_MyRetailDemandQueryResult = Apollo.QueryResult<
  OrderBook_MyRetailDemandQuery,
  OrderBook_MyRetailDemandQueryVariables
>;

import { makeMockResponse } from '../../../../../graphql/mock-response';

export const makeOrderBook_MyRetailDemandMockResponse = makeMockResponse<
  OrderBook_MyRetailDemandQueryVariables,
  OrderBook_MyRetailDemandQuery
>(OrderBook_MyRetailDemandDocument);
