/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IndicationActivity_OrderBookSalesStateChangedForSalesTraderSubscriptionVariables =
  Types.Exact<{
    offeringId: Types.Scalars['UUID'];
  }>;

export type IndicationActivity_OrderBookSalesStateChangedForSalesTraderSubscription = {
  readonly __typename?: 'Subscription';
  readonly orderBookSalesStateChangedForSalesTrader: {
    readonly __typename?: 'OrderBookSalesState';
    readonly offeringId: string;
    readonly salesState: Types.OrderBookState;
  };
};

export type IndicationActivity_OrderBookSalesStateForSalesTraderQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type IndicationActivity_OrderBookSalesStateForSalesTraderQuery = {
  readonly __typename?: 'Query';
  readonly salesAndTradingOrderBook?: {
    readonly __typename?: 'OrderBookGraph';
    readonly offeringId: string;
    readonly salesState: Types.OrderBookState;
  } | null;
};

export type SalesAndTradingIndicationQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  cmgEntityKey: Types.Scalars['String'];
  indicationId?: Types.InputMaybe<Types.Scalars['UUID']>;
  skipIndicationPart: Types.Scalars['Boolean'];
}>;

export type SalesAndTradingIndicationQuery = {
  readonly __typename?: 'Query';
  readonly basicPublishedOffering: {
    readonly __typename?: 'BasicPublishedOfferingProfile';
    readonly id: string;
    readonly type: Types.OfferingType;
    readonly offerPrice?: number | null;
    readonly pricingCurrencyCode: string;
    readonly securityType: Types.SecurityType;
    readonly securityTypeDisplayName?: string | null;
    readonly status: Types.OfferingStatus;
    readonly publicOrderBook: {
      readonly __typename?: 'PublicOrderBook';
      readonly state: Types.OrderBookState;
      readonly id: string;
    };
    readonly syndicateEntitlements: {
      readonly __typename?: 'Entitlements';
      readonly syndicateEntitlements: ReadonlyArray<{
        readonly __typename?: 'SyndicateEntitlements';
        readonly cmgEntityKey: string;
        readonly entitlements: ReadonlyArray<string>;
      }>;
    };
    readonly prospectusDocuments: ReadonlyArray<{
      readonly __typename?: 'ProspectusDocumentProfile';
      readonly id: string;
    }>;
    readonly disclaimers: {
      readonly __typename?: 'DisclaimersProfile';
      readonly restrictions?: string | null;
      readonly footnotes?: string | null;
      readonly registrationLinks: ReadonlyArray<string>;
      readonly rule134Legend?: string | null;
    };
    readonly orderEntryProfile: {
      readonly __typename?: 'OfferingOrderEntryProfile';
      readonly id: string;
      readonly isExecutingOnPlatform: boolean;
      readonly tranches: ReadonlyArray<{
        readonly __typename?: 'TranchePrivileged';
        readonly id: string;
        readonly defaultInstrumentId?: string | null;
        readonly name: string;
        readonly demandCurrencies: ReadonlyArray<string>;
        readonly deliveryInstrumentIds: ReadonlyArray<string>;
      }>;
      readonly instruments: ReadonlyArray<{
        readonly __typename?: 'InstrumentOrderEntryProfile';
        readonly id: string;
        readonly currencyCode?: string | null;
        readonly countryCode?: string | null;
        readonly stockSymbol?: string | null;
        readonly isDepositaryReceipt: boolean;
      }>;
    };
    readonly syndicate: {
      readonly __typename?: 'SyndicateProfile';
      readonly managers: ReadonlyArray<{
        readonly __typename?: 'ManagerProfile';
        readonly cmgEntityKey: string;
        readonly firmName: string;
      }>;
    };
  };
  readonly coveredIndication?: {
    readonly __typename?: 'CoveredInstitutionalIndication';
    readonly id: string;
    readonly offeringId: string;
    readonly status: Types.IndicationStatus;
    readonly type: Types.InstitutionalIndicationOrderType;
    readonly trancheId?: string | null;
    readonly currencyCode?: string | null;
    readonly instrumentId?: string | null;
    readonly version: string;
    readonly allocationShares?: number | null;
    readonly allocationVersion?: string | null;
    readonly allocationCurrency?: number | null;
    readonly allocationPercentOfOffering?: number | null;
    readonly indicationPercentFill?: number | null;
    readonly investorReplyStatus?: Types.AllocationAcknowledgement | null;
    readonly duplicateOfIndicationId?: string | null;
    readonly duplicateIndicationIds: ReadonlyArray<string>;
    readonly billingAndDeliveryAgentCmgEntityKey?: string | null;
    readonly investorInformation: {
      readonly __typename?: 'InvestorInformation';
      readonly bankInvestorName?: string | null;
      readonly bankInvestorKey?: string | null;
      readonly cmgEntityKey: string;
      readonly cmgEntityName: string;
      readonly contactName?: string | null;
      readonly contactEmail?: string | null;
      readonly isOnPlatform: boolean;
    };
    readonly interestLevels: ReadonlyArray<{
      readonly __typename?: 'InterestLevel';
      readonly interestUnit: Types.InterestLevelInterestUnit;
      readonly interestQuantity: number;
      readonly limitPrice?: number | null;
      readonly limitType?: Types.InterestLevelLimitType | null;
    }>;
    readonly auditInfo: {
      readonly __typename?: 'AuditInfo';
      readonly createdAt: string;
      readonly modifiedByFirmKey?: string | null;
    };
    readonly institutionalIndicationAcknowledgements: ReadonlyArray<{
      readonly __typename?: 'InstitutionalIndicationAcknowledgement';
      readonly offeringId: string;
      readonly indicationId: string;
      readonly indicationVersion: string;
      readonly acknowledgedIndicationVersion: string;
      readonly managerCmgEntityKey: string;
      readonly version: string;
    }>;
    readonly prospectusStatus?: {
      readonly __typename?: 'InvestorDeliveryStatus';
      readonly overallDeliveryStatus: Types.ProspectusDeliveryStatus;
    } | null;
  } | null;
};

export const IndicationActivity_OrderBookSalesStateChangedForSalesTraderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'IndicationActivity_OrderBookSalesStateChangedForSalesTrader' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orderBookSalesStateChangedForSalesTrader' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'offeringId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'salesState' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useIndicationActivity_OrderBookSalesStateChangedForSalesTraderSubscription__
 *
 * To run a query within a React component, call `useIndicationActivity_OrderBookSalesStateChangedForSalesTraderSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicationActivity_OrderBookSalesStateChangedForSalesTraderSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIndicationActivity_OrderBookSalesStateChangedForSalesTraderSubscription({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useIndicationActivity_OrderBookSalesStateChangedForSalesTraderSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    IndicationActivity_OrderBookSalesStateChangedForSalesTraderSubscription,
    IndicationActivity_OrderBookSalesStateChangedForSalesTraderSubscriptionVariables
  > &
    (
      | {
          variables: IndicationActivity_OrderBookSalesStateChangedForSalesTraderSubscriptionVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    IndicationActivity_OrderBookSalesStateChangedForSalesTraderSubscription,
    IndicationActivity_OrderBookSalesStateChangedForSalesTraderSubscriptionVariables
  >(IndicationActivity_OrderBookSalesStateChangedForSalesTraderDocument, options);
}
export type IndicationActivity_OrderBookSalesStateChangedForSalesTraderSubscriptionHookResult =
  ReturnType<typeof useIndicationActivity_OrderBookSalesStateChangedForSalesTraderSubscription>;
export type IndicationActivity_OrderBookSalesStateChangedForSalesTraderSubscriptionResult =
  Apollo.SubscriptionResult<IndicationActivity_OrderBookSalesStateChangedForSalesTraderSubscription>;
export const IndicationActivity_OrderBookSalesStateForSalesTraderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'IndicationActivity_OrderBookSalesStateForSalesTrader' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'salesAndTradingOrderBook' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'offeringId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'salesState' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useIndicationActivity_OrderBookSalesStateForSalesTraderQuery__
 *
 * To run a query within a React component, call `useIndicationActivity_OrderBookSalesStateForSalesTraderQuery` and pass it any options that fit your needs.
 * When your component renders, `useIndicationActivity_OrderBookSalesStateForSalesTraderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIndicationActivity_OrderBookSalesStateForSalesTraderQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useIndicationActivity_OrderBookSalesStateForSalesTraderQuery(
  baseOptions: Apollo.QueryHookOptions<
    IndicationActivity_OrderBookSalesStateForSalesTraderQuery,
    IndicationActivity_OrderBookSalesStateForSalesTraderQueryVariables
  > &
    (
      | {
          variables: IndicationActivity_OrderBookSalesStateForSalesTraderQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    IndicationActivity_OrderBookSalesStateForSalesTraderQuery,
    IndicationActivity_OrderBookSalesStateForSalesTraderQueryVariables
  >(IndicationActivity_OrderBookSalesStateForSalesTraderDocument, options);
}
export function useIndicationActivity_OrderBookSalesStateForSalesTraderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IndicationActivity_OrderBookSalesStateForSalesTraderQuery,
    IndicationActivity_OrderBookSalesStateForSalesTraderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    IndicationActivity_OrderBookSalesStateForSalesTraderQuery,
    IndicationActivity_OrderBookSalesStateForSalesTraderQueryVariables
  >(IndicationActivity_OrderBookSalesStateForSalesTraderDocument, options);
}
export function useIndicationActivity_OrderBookSalesStateForSalesTraderSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        IndicationActivity_OrderBookSalesStateForSalesTraderQuery,
        IndicationActivity_OrderBookSalesStateForSalesTraderQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    IndicationActivity_OrderBookSalesStateForSalesTraderQuery,
    IndicationActivity_OrderBookSalesStateForSalesTraderQueryVariables
  >(IndicationActivity_OrderBookSalesStateForSalesTraderDocument, options);
}
export type IndicationActivity_OrderBookSalesStateForSalesTraderQueryHookResult = ReturnType<
  typeof useIndicationActivity_OrderBookSalesStateForSalesTraderQuery
>;
export type IndicationActivity_OrderBookSalesStateForSalesTraderLazyQueryHookResult = ReturnType<
  typeof useIndicationActivity_OrderBookSalesStateForSalesTraderLazyQuery
>;
export type IndicationActivity_OrderBookSalesStateForSalesTraderSuspenseQueryHookResult =
  ReturnType<typeof useIndicationActivity_OrderBookSalesStateForSalesTraderSuspenseQuery>;
export type IndicationActivity_OrderBookSalesStateForSalesTraderQueryResult = Apollo.QueryResult<
  IndicationActivity_OrderBookSalesStateForSalesTraderQuery,
  IndicationActivity_OrderBookSalesStateForSalesTraderQueryVariables
>;
export const SalesAndTradingIndicationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SalesAndTradingIndication' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKey' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'indicationId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'skipIndicationPart' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'basicPublishedOffering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'publicOrderBook' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'id' },
                        name: { kind: 'Name', value: 'offeringId' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'syndicateEntitlements' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'syndicateEntitlements' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'entitlements' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'prospectusDocuments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ManageIndication_OfferingParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'coveredIndication' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cmgEntityKey' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKey' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'indicationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'indicationId' } },
              },
            ],
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'skip' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'skipIndicationPart' },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'offeringId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'trancheId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'instrumentId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'investorInformation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'bankInvestorName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bankInvestorKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'contactName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'contactEmail' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isOnPlatform' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'interestLevels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'interestUnit' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'interestQuantity' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'limitPrice' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'limitType' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'allocationShares' } },
                { kind: 'Field', name: { kind: 'Name', value: 'allocationVersion' } },
                { kind: 'Field', name: { kind: 'Name', value: 'allocationCurrency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'allocationPercentOfOffering' } },
                { kind: 'Field', name: { kind: 'Name', value: 'indicationPercentFill' } },
                { kind: 'Field', name: { kind: 'Name', value: 'investorReplyStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'duplicateOfIndicationId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'duplicateIndicationIds' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'auditInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'modifiedByFirmKey' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'billingAndDeliveryAgentCmgEntityKey' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'institutionalIndicationAcknowledgements' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'offeringId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'indicationId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'indicationVersion' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'acknowledgedIndicationVersion' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'managerCmgEntityKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'prospectusStatus' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'overallDeliveryStatus' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ManageIndication_OfferingParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BasicPublishedOfferingProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'IndicationForm_OfferingParts' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'IndicationForm_OfferingParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BasicPublishedOfferingProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offerPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingCurrencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'securityType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'securityTypeDisplayName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'disclaimers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'restrictions' } },
                { kind: 'Field', name: { kind: 'Name', value: 'footnotes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'registrationLinks' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rule134Legend' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orderEntryProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tranches' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'defaultInstrumentId' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isExecutingOnPlatform' } },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'IndicationForm_IndicationDetails_OfferingParts' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'IndicationActivity_AllocationOfferingParts' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'IndicationForm_IndicationDetails_OfferingParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BasicPublishedOfferingProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orderEntryProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tranches' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'defaultInstrumentId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'demandCurrencies' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryInstrumentIds' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'instruments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'stockSymbol' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isDepositaryReceipt' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'syndicate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'managers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'IndicationActivity_AllocationOfferingParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BasicPublishedOfferingProfile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offerPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingCurrencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useSalesAndTradingIndicationQuery__
 *
 * To run a query within a React component, call `useSalesAndTradingIndicationQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesAndTradingIndicationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesAndTradingIndicationQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      cmgEntityKey: // value for 'cmgEntityKey'
 *      indicationId: // value for 'indicationId'
 *      skipIndicationPart: // value for 'skipIndicationPart'
 *   },
 * });
 */
export function useSalesAndTradingIndicationQuery(
  baseOptions: Apollo.QueryHookOptions<
    SalesAndTradingIndicationQuery,
    SalesAndTradingIndicationQueryVariables
  > &
    ({ variables: SalesAndTradingIndicationQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SalesAndTradingIndicationQuery, SalesAndTradingIndicationQueryVariables>(
    SalesAndTradingIndicationDocument,
    options
  );
}
export function useSalesAndTradingIndicationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SalesAndTradingIndicationQuery,
    SalesAndTradingIndicationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SalesAndTradingIndicationQuery,
    SalesAndTradingIndicationQueryVariables
  >(SalesAndTradingIndicationDocument, options);
}
export function useSalesAndTradingIndicationSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        SalesAndTradingIndicationQuery,
        SalesAndTradingIndicationQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SalesAndTradingIndicationQuery,
    SalesAndTradingIndicationQueryVariables
  >(SalesAndTradingIndicationDocument, options);
}
export type SalesAndTradingIndicationQueryHookResult = ReturnType<
  typeof useSalesAndTradingIndicationQuery
>;
export type SalesAndTradingIndicationLazyQueryHookResult = ReturnType<
  typeof useSalesAndTradingIndicationLazyQuery
>;
export type SalesAndTradingIndicationSuspenseQueryHookResult = ReturnType<
  typeof useSalesAndTradingIndicationSuspenseQuery
>;
export type SalesAndTradingIndicationQueryResult = Apollo.QueryResult<
  SalesAndTradingIndicationQuery,
  SalesAndTradingIndicationQueryVariables
>;

import {
  UseWithSubscriptionOptions,
  useWithLazySubscription,
  useWithSubscription,
} from '../../../../../../../../graphql/with-subscription';

type UseWithIndicationActivity_OrderBookSalesStateChangedForSalesTraderSubscriptionOptions<
  TQueryData extends {},
  TQueryVariables extends Apollo.OperationVariables
> = Omit<
  UseWithSubscriptionOptions<
    IndicationActivity_OrderBookSalesStateChangedForSalesTraderSubscription,
    IndicationActivity_OrderBookSalesStateChangedForSalesTraderSubscriptionVariables,
    TQueryData,
    TQueryVariables
  >,
  'document' | 'clientName'
>;

export const useWithIndicationActivity_OrderBookSalesStateChangedForSalesTraderSubscription = <
  TQueryData extends {},
  TQueryVariables extends Apollo.OperationVariables
>(
  queryResults: Apollo.QueryResult<TQueryData, TQueryVariables>,
  options: UseWithIndicationActivity_OrderBookSalesStateChangedForSalesTraderSubscriptionOptions<
    TQueryData,
    TQueryVariables
  >
) => {
  return useWithSubscription(queryResults, {
    ...options,
    document: IndicationActivity_OrderBookSalesStateChangedForSalesTraderDocument,
  });
};

export const useWithIndicationActivity_OrderBookSalesStateChangedForSalesTraderLazySubscription = <
  TQueryData extends {},
  TQueryVariables extends Apollo.OperationVariables
>(
  queryResults: Apollo.LazyQueryResultTuple<TQueryData, TQueryVariables>,
  options: UseWithIndicationActivity_OrderBookSalesStateChangedForSalesTraderSubscriptionOptions<
    TQueryData,
    TQueryVariables
  >
) => {
  return useWithLazySubscription(queryResults, {
    ...options,
    document: IndicationActivity_OrderBookSalesStateChangedForSalesTraderDocument,
  });
};

import { makeMockResponse } from '../../../../../../../../graphql/mock-response';

export const makeIndicationActivity_OrderBookSalesStateForSalesTraderMockResponse =
  makeMockResponse<
    IndicationActivity_OrderBookSalesStateForSalesTraderQueryVariables,
    IndicationActivity_OrderBookSalesStateForSalesTraderQuery
  >(IndicationActivity_OrderBookSalesStateForSalesTraderDocument);

export const makeSalesAndTradingIndicationMockResponse = makeMockResponse<
  SalesAndTradingIndicationQueryVariables,
  SalesAndTradingIndicationQuery
>(SalesAndTradingIndicationDocument);
