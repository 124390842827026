/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Compliance_Syndicate_AttestationQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  cmgEntityKey: Types.Scalars['String'];
}>;

export type Compliance_Syndicate_AttestationQuery = {
  readonly __typename?: 'Query';
  readonly basicPublishedOffering: {
    readonly __typename?: 'BasicPublishedOfferingProfile';
    readonly id: string;
    readonly type: Types.OfferingType;
  };
  readonly attestation: {
    readonly __typename?: 'Attestation';
    readonly cmgEntityKey: string;
    readonly formStatus: Types.AttestationFormStatus;
    readonly attestationForm?: {
      readonly __typename?: 'AttestationForm';
      readonly firmName: string;
      readonly expirationDate?: string | null;
      readonly signatoryDate: string;
      readonly effectiveDate: string;
      readonly cityStateZip: string;
      readonly addressLine1: string;
      readonly isBlanketForm: boolean;
      readonly firmIdentifier?: string | null;
      readonly bankRequesterCmgEntityKey?: string | null;
      readonly envelopeId?: string | null;
      readonly primaryFirmCmgEntityKey?: string | null;
      readonly id: string;
      readonly signers: ReadonlyArray<{
        readonly __typename?: 'Signer';
        readonly title: string;
        readonly name: string;
        readonly email: string;
        readonly telephone: string;
      }>;
    } | null;
  };
};

export const Compliance_Syndicate_AttestationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Compliance_Syndicate_Attestation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKey' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'basicPublishedOffering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attestation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cmgEntityKey' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKey' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'formStatus' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attestationForm' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'expirationDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'signatoryDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'effectiveDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cityStateZip' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'addressLine1' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isBlanketForm' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firmIdentifier' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'signers' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'telephone' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'bankRequesterCmgEntityKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'envelopeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'primaryFirmCmgEntityKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useCompliance_Syndicate_AttestationQuery__
 *
 * To run a query within a React component, call `useCompliance_Syndicate_AttestationQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompliance_Syndicate_AttestationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompliance_Syndicate_AttestationQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      cmgEntityKey: // value for 'cmgEntityKey'
 *   },
 * });
 */
export function useCompliance_Syndicate_AttestationQuery(
  baseOptions: Apollo.QueryHookOptions<
    Compliance_Syndicate_AttestationQuery,
    Compliance_Syndicate_AttestationQueryVariables
  > &
    (
      | { variables: Compliance_Syndicate_AttestationQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Compliance_Syndicate_AttestationQuery,
    Compliance_Syndicate_AttestationQueryVariables
  >(Compliance_Syndicate_AttestationDocument, options);
}
export function useCompliance_Syndicate_AttestationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Compliance_Syndicate_AttestationQuery,
    Compliance_Syndicate_AttestationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Compliance_Syndicate_AttestationQuery,
    Compliance_Syndicate_AttestationQueryVariables
  >(Compliance_Syndicate_AttestationDocument, options);
}
export function useCompliance_Syndicate_AttestationSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        Compliance_Syndicate_AttestationQuery,
        Compliance_Syndicate_AttestationQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    Compliance_Syndicate_AttestationQuery,
    Compliance_Syndicate_AttestationQueryVariables
  >(Compliance_Syndicate_AttestationDocument, options);
}
export type Compliance_Syndicate_AttestationQueryHookResult = ReturnType<
  typeof useCompliance_Syndicate_AttestationQuery
>;
export type Compliance_Syndicate_AttestationLazyQueryHookResult = ReturnType<
  typeof useCompliance_Syndicate_AttestationLazyQuery
>;
export type Compliance_Syndicate_AttestationSuspenseQueryHookResult = ReturnType<
  typeof useCompliance_Syndicate_AttestationSuspenseQuery
>;
export type Compliance_Syndicate_AttestationQueryResult = Apollo.QueryResult<
  Compliance_Syndicate_AttestationQuery,
  Compliance_Syndicate_AttestationQueryVariables
>;

import { makeMockResponse } from '../../../../../../../../graphql/mock-response';

export const makeCompliance_Syndicate_AttestationMockResponse = makeMockResponse<
  Compliance_Syndicate_AttestationQueryVariables,
  Compliance_Syndicate_AttestationQuery
>(Compliance_Syndicate_AttestationDocument);
