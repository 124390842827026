/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FinalSettlement_EligibleManagerPartsFragment = {
  readonly __typename?: 'ExpensesManager';
  readonly id: string;
  readonly cmgEntityKey: string;
  readonly role: Types.ExpensesManagerRole;
  readonly name: string;
};

export type FinalSettlement_EligibleManagersQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type FinalSettlement_EligibleManagersQuery = {
  readonly __typename?: 'Query';
  readonly letterEligibleManagers: ReadonlyArray<{
    readonly __typename?: 'ExpensesManager';
    readonly id: string;
    readonly cmgEntityKey: string;
    readonly role: Types.ExpensesManagerRole;
    readonly name: string;
  }>;
};

export type FinalSettlement_SettlementLetterQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  managerId: Types.Scalars['String'];
}>;

export type FinalSettlement_SettlementLetterQuery = {
  readonly __typename?: 'Query';
  readonly settlementLetter: {
    readonly __typename?: 'SettlementLetter';
    readonly issuerName: string;
    readonly trancheName?: string | null;
    readonly offeringType: Types.OfferingType;
    readonly tradeDate?: string | null;
    readonly finalSettlementDate?: string | null;
    readonly managerName: string;
    readonly managerRole: Types.ExpensesManagerRole;
    readonly totalManagerExpensesReimbursements?: number | null;
    readonly totalLossOnOversale?: number | null;
    readonly totalUnderwritingGroupExpense?: number | null;
    readonly managementPercentage?: number | null;
    readonly managementFee?: number | null;
    readonly underwritingPercentage?: number | null;
    readonly underwritingSpread?: number | null;
    readonly underwritingShares?: number | null;
    readonly underwritingFee?: number | null;
    readonly sellingConcessionSpread?: number | null;
    readonly designatedShares?: number | null;
    readonly sellingConcession?: number | null;
    readonly managerExpensesReimbursements?: number | null;
    readonly managerDealRelatedExpenses?: number | null;
    readonly managerGainLossOnOversale?: number | null;
    readonly adjustmentDescription?: string | null;
    readonly adjustmentValue?: number | null;
    readonly finalSettlement?: number | null;
    readonly netDealFee?: number | null;
    readonly userFirmName: string;
    readonly dealRelatedExpenses: ReadonlyArray<{
      readonly __typename?: 'LetterDealRelatedExpenses';
      readonly category: Types.ExpenseCategory;
      readonly value: number;
    }>;
  };
};

export type FinalSettlement_SettlementLetterPartsFragment = {
  readonly __typename?: 'SettlementLetter';
  readonly issuerName: string;
  readonly trancheName?: string | null;
  readonly offeringType: Types.OfferingType;
  readonly tradeDate?: string | null;
  readonly finalSettlementDate?: string | null;
  readonly managerName: string;
  readonly managerRole: Types.ExpensesManagerRole;
  readonly totalManagerExpensesReimbursements?: number | null;
  readonly totalLossOnOversale?: number | null;
  readonly totalUnderwritingGroupExpense?: number | null;
  readonly managementPercentage?: number | null;
  readonly managementFee?: number | null;
  readonly underwritingPercentage?: number | null;
  readonly underwritingSpread?: number | null;
  readonly underwritingShares?: number | null;
  readonly underwritingFee?: number | null;
  readonly sellingConcessionSpread?: number | null;
  readonly designatedShares?: number | null;
  readonly sellingConcession?: number | null;
  readonly managerExpensesReimbursements?: number | null;
  readonly managerDealRelatedExpenses?: number | null;
  readonly managerGainLossOnOversale?: number | null;
  readonly adjustmentDescription?: string | null;
  readonly adjustmentValue?: number | null;
  readonly finalSettlement?: number | null;
  readonly netDealFee?: number | null;
  readonly userFirmName: string;
  readonly dealRelatedExpenses: ReadonlyArray<{
    readonly __typename?: 'LetterDealRelatedExpenses';
    readonly category: Types.ExpenseCategory;
    readonly value: number;
  }>;
};

export const FinalSettlement_EligibleManagerPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FinalSettlement_EligibleManagerParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ExpensesManager' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const FinalSettlement_SettlementLetterPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FinalSettlement_SettlementLetterParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SettlementLetter' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'issuerName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trancheName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tradeDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'finalSettlementDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'managerName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'managerRole' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dealRelatedExpenses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'totalManagerExpensesReimbursements' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalLossOnOversale' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalUnderwritingGroupExpense' } },
          { kind: 'Field', name: { kind: 'Name', value: 'managementPercentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'managementFee' } },
          { kind: 'Field', name: { kind: 'Name', value: 'underwritingPercentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'underwritingSpread' } },
          { kind: 'Field', name: { kind: 'Name', value: 'underwritingShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'underwritingFee' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sellingConcessionSpread' } },
          { kind: 'Field', name: { kind: 'Name', value: 'designatedShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sellingConcession' } },
          { kind: 'Field', name: { kind: 'Name', value: 'managerExpensesReimbursements' } },
          { kind: 'Field', name: { kind: 'Name', value: 'managerDealRelatedExpenses' } },
          { kind: 'Field', name: { kind: 'Name', value: 'managerGainLossOnOversale' } },
          { kind: 'Field', name: { kind: 'Name', value: 'adjustmentDescription' } },
          { kind: 'Field', name: { kind: 'Name', value: 'adjustmentValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'finalSettlement' } },
          { kind: 'Field', name: { kind: 'Name', value: 'netDealFee' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userFirmName' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const FinalSettlement_EligibleManagersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FinalSettlement_EligibleManagers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'letterEligibleManagers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FinalSettlement_EligibleManagerParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FinalSettlement_EligibleManagerParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ExpensesManager' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useFinalSettlement_EligibleManagersQuery__
 *
 * To run a query within a React component, call `useFinalSettlement_EligibleManagersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFinalSettlement_EligibleManagersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFinalSettlement_EligibleManagersQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useFinalSettlement_EligibleManagersQuery(
  baseOptions: Apollo.QueryHookOptions<
    FinalSettlement_EligibleManagersQuery,
    FinalSettlement_EligibleManagersQueryVariables
  > &
    (
      | { variables: FinalSettlement_EligibleManagersQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FinalSettlement_EligibleManagersQuery,
    FinalSettlement_EligibleManagersQueryVariables
  >(FinalSettlement_EligibleManagersDocument, options);
}
export function useFinalSettlement_EligibleManagersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FinalSettlement_EligibleManagersQuery,
    FinalSettlement_EligibleManagersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FinalSettlement_EligibleManagersQuery,
    FinalSettlement_EligibleManagersQueryVariables
  >(FinalSettlement_EligibleManagersDocument, options);
}
export function useFinalSettlement_EligibleManagersSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FinalSettlement_EligibleManagersQuery,
        FinalSettlement_EligibleManagersQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FinalSettlement_EligibleManagersQuery,
    FinalSettlement_EligibleManagersQueryVariables
  >(FinalSettlement_EligibleManagersDocument, options);
}
export type FinalSettlement_EligibleManagersQueryHookResult = ReturnType<
  typeof useFinalSettlement_EligibleManagersQuery
>;
export type FinalSettlement_EligibleManagersLazyQueryHookResult = ReturnType<
  typeof useFinalSettlement_EligibleManagersLazyQuery
>;
export type FinalSettlement_EligibleManagersSuspenseQueryHookResult = ReturnType<
  typeof useFinalSettlement_EligibleManagersSuspenseQuery
>;
export type FinalSettlement_EligibleManagersQueryResult = Apollo.QueryResult<
  FinalSettlement_EligibleManagersQuery,
  FinalSettlement_EligibleManagersQueryVariables
>;
export const FinalSettlement_SettlementLetterDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FinalSettlement_SettlementLetter' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'managerId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'settlementLetter' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'managerId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'managerId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FinalSettlement_SettlementLetterParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FinalSettlement_SettlementLetterParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SettlementLetter' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'issuerName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trancheName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tradeDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'finalSettlementDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'managerName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'managerRole' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dealRelatedExpenses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'totalManagerExpensesReimbursements' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalLossOnOversale' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalUnderwritingGroupExpense' } },
          { kind: 'Field', name: { kind: 'Name', value: 'managementPercentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'managementFee' } },
          { kind: 'Field', name: { kind: 'Name', value: 'underwritingPercentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'underwritingSpread' } },
          { kind: 'Field', name: { kind: 'Name', value: 'underwritingShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'underwritingFee' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sellingConcessionSpread' } },
          { kind: 'Field', name: { kind: 'Name', value: 'designatedShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sellingConcession' } },
          { kind: 'Field', name: { kind: 'Name', value: 'managerExpensesReimbursements' } },
          { kind: 'Field', name: { kind: 'Name', value: 'managerDealRelatedExpenses' } },
          { kind: 'Field', name: { kind: 'Name', value: 'managerGainLossOnOversale' } },
          { kind: 'Field', name: { kind: 'Name', value: 'adjustmentDescription' } },
          { kind: 'Field', name: { kind: 'Name', value: 'adjustmentValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'finalSettlement' } },
          { kind: 'Field', name: { kind: 'Name', value: 'netDealFee' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userFirmName' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useFinalSettlement_SettlementLetterQuery__
 *
 * To run a query within a React component, call `useFinalSettlement_SettlementLetterQuery` and pass it any options that fit your needs.
 * When your component renders, `useFinalSettlement_SettlementLetterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFinalSettlement_SettlementLetterQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      managerId: // value for 'managerId'
 *   },
 * });
 */
export function useFinalSettlement_SettlementLetterQuery(
  baseOptions: Apollo.QueryHookOptions<
    FinalSettlement_SettlementLetterQuery,
    FinalSettlement_SettlementLetterQueryVariables
  > &
    (
      | { variables: FinalSettlement_SettlementLetterQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FinalSettlement_SettlementLetterQuery,
    FinalSettlement_SettlementLetterQueryVariables
  >(FinalSettlement_SettlementLetterDocument, options);
}
export function useFinalSettlement_SettlementLetterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FinalSettlement_SettlementLetterQuery,
    FinalSettlement_SettlementLetterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FinalSettlement_SettlementLetterQuery,
    FinalSettlement_SettlementLetterQueryVariables
  >(FinalSettlement_SettlementLetterDocument, options);
}
export function useFinalSettlement_SettlementLetterSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FinalSettlement_SettlementLetterQuery,
        FinalSettlement_SettlementLetterQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FinalSettlement_SettlementLetterQuery,
    FinalSettlement_SettlementLetterQueryVariables
  >(FinalSettlement_SettlementLetterDocument, options);
}
export type FinalSettlement_SettlementLetterQueryHookResult = ReturnType<
  typeof useFinalSettlement_SettlementLetterQuery
>;
export type FinalSettlement_SettlementLetterLazyQueryHookResult = ReturnType<
  typeof useFinalSettlement_SettlementLetterLazyQuery
>;
export type FinalSettlement_SettlementLetterSuspenseQueryHookResult = ReturnType<
  typeof useFinalSettlement_SettlementLetterSuspenseQuery
>;
export type FinalSettlement_SettlementLetterQueryResult = Apollo.QueryResult<
  FinalSettlement_SettlementLetterQuery,
  FinalSettlement_SettlementLetterQueryVariables
>;

import { makeMockResponse } from '../../../../../graphql/mock-response';

export const makeFinalSettlement_EligibleManagersMockResponse = makeMockResponse<
  FinalSettlement_EligibleManagersQueryVariables,
  FinalSettlement_EligibleManagersQuery
>(FinalSettlement_EligibleManagersDocument);

export const makeFinalSettlement_SettlementLetterMockResponse = makeMockResponse<
  FinalSettlement_SettlementLetterQueryVariables,
  FinalSettlement_SettlementLetterQuery
>(FinalSettlement_SettlementLetterDocument);
