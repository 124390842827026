import { Alert, AlertTitle, Typography } from '@cmg/design-system';

export type InvalidFormAlertProps = {
  showInvalidFields: boolean;
  invalidFormFieldLabels: string[];
};

export const InvalidFormAlert = ({
  showInvalidFields,
  invalidFormFieldLabels,
}: InvalidFormAlertProps) => {
  if (!showInvalidFields) {
    return null;
  }

  return (
    <Alert
      severity="error"
      aria-label="An entry is required or has invalid value for the following fields:"
    >
      <AlertTitle>An entry is required or has invalid value for the following fields:</AlertTitle>
      <Typography variant="body2">{invalidFormFieldLabels.join(', ')}</Typography>
    </Alert>
  );
};
