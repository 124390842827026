/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OfferingNavbar_OrderBookSubNavItem_OrderBookQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
}>;

export type OfferingNavbar_OrderBookSubNavItem_OrderBookQuery = {
  readonly __typename?: 'Query';
  readonly orderBook: {
    readonly __typename?: 'OrderBook';
    readonly offeringId: string;
    readonly state: Types.OrderBookState;
  };
};

export type OfferingNavbar_OrderBookSubNavItem_OrderBookStatusChangedSubscriptionVariables =
  Types.Exact<{
    offeringId: Types.Scalars['UUID'];
  }>;

export type OfferingNavbar_OrderBookSubNavItem_OrderBookStatusChangedSubscription = {
  readonly __typename?: 'Subscription';
  readonly orderBookStatusChanged: {
    readonly __typename?: 'OrderBookStatus';
    readonly state: Types.OrderBookState;
  };
};

export const OfferingNavbar_OrderBookSubNavItem_OrderBookDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'OfferingNavbar_OrderBookSubNavItem_OrderBook' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orderBook' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'offeringId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useOfferingNavbar_OrderBookSubNavItem_OrderBookQuery__
 *
 * To run a query within a React component, call `useOfferingNavbar_OrderBookSubNavItem_OrderBookQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfferingNavbar_OrderBookSubNavItem_OrderBookQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferingNavbar_OrderBookSubNavItem_OrderBookQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOfferingNavbar_OrderBookSubNavItem_OrderBookQuery(
  baseOptions: Apollo.QueryHookOptions<
    OfferingNavbar_OrderBookSubNavItem_OrderBookQuery,
    OfferingNavbar_OrderBookSubNavItem_OrderBookQueryVariables
  > &
    (
      | { variables: OfferingNavbar_OrderBookSubNavItem_OrderBookQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OfferingNavbar_OrderBookSubNavItem_OrderBookQuery,
    OfferingNavbar_OrderBookSubNavItem_OrderBookQueryVariables
  >(OfferingNavbar_OrderBookSubNavItem_OrderBookDocument, options);
}
export function useOfferingNavbar_OrderBookSubNavItem_OrderBookLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OfferingNavbar_OrderBookSubNavItem_OrderBookQuery,
    OfferingNavbar_OrderBookSubNavItem_OrderBookQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OfferingNavbar_OrderBookSubNavItem_OrderBookQuery,
    OfferingNavbar_OrderBookSubNavItem_OrderBookQueryVariables
  >(OfferingNavbar_OrderBookSubNavItem_OrderBookDocument, options);
}
export function useOfferingNavbar_OrderBookSubNavItem_OrderBookSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        OfferingNavbar_OrderBookSubNavItem_OrderBookQuery,
        OfferingNavbar_OrderBookSubNavItem_OrderBookQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    OfferingNavbar_OrderBookSubNavItem_OrderBookQuery,
    OfferingNavbar_OrderBookSubNavItem_OrderBookQueryVariables
  >(OfferingNavbar_OrderBookSubNavItem_OrderBookDocument, options);
}
export type OfferingNavbar_OrderBookSubNavItem_OrderBookQueryHookResult = ReturnType<
  typeof useOfferingNavbar_OrderBookSubNavItem_OrderBookQuery
>;
export type OfferingNavbar_OrderBookSubNavItem_OrderBookLazyQueryHookResult = ReturnType<
  typeof useOfferingNavbar_OrderBookSubNavItem_OrderBookLazyQuery
>;
export type OfferingNavbar_OrderBookSubNavItem_OrderBookSuspenseQueryHookResult = ReturnType<
  typeof useOfferingNavbar_OrderBookSubNavItem_OrderBookSuspenseQuery
>;
export type OfferingNavbar_OrderBookSubNavItem_OrderBookQueryResult = Apollo.QueryResult<
  OfferingNavbar_OrderBookSubNavItem_OrderBookQuery,
  OfferingNavbar_OrderBookSubNavItem_OrderBookQueryVariables
>;
export const OfferingNavbar_OrderBookSubNavItem_OrderBookStatusChangedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'OfferingNavbar_OrderBookSubNavItem_OrderBookStatusChanged' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orderBookStatusChanged' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'state' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useOfferingNavbar_OrderBookSubNavItem_OrderBookStatusChangedSubscription__
 *
 * To run a query within a React component, call `useOfferingNavbar_OrderBookSubNavItem_OrderBookStatusChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOfferingNavbar_OrderBookSubNavItem_OrderBookStatusChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferingNavbar_OrderBookSubNavItem_OrderBookStatusChangedSubscription({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useOfferingNavbar_OrderBookSubNavItem_OrderBookStatusChangedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    OfferingNavbar_OrderBookSubNavItem_OrderBookStatusChangedSubscription,
    OfferingNavbar_OrderBookSubNavItem_OrderBookStatusChangedSubscriptionVariables
  > &
    (
      | {
          variables: OfferingNavbar_OrderBookSubNavItem_OrderBookStatusChangedSubscriptionVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    OfferingNavbar_OrderBookSubNavItem_OrderBookStatusChangedSubscription,
    OfferingNavbar_OrderBookSubNavItem_OrderBookStatusChangedSubscriptionVariables
  >(OfferingNavbar_OrderBookSubNavItem_OrderBookStatusChangedDocument, options);
}
export type OfferingNavbar_OrderBookSubNavItem_OrderBookStatusChangedSubscriptionHookResult =
  ReturnType<typeof useOfferingNavbar_OrderBookSubNavItem_OrderBookStatusChangedSubscription>;
export type OfferingNavbar_OrderBookSubNavItem_OrderBookStatusChangedSubscriptionResult =
  Apollo.SubscriptionResult<OfferingNavbar_OrderBookSubNavItem_OrderBookStatusChangedSubscription>;

import {
  UseWithSubscriptionOptions,
  useWithLazySubscription,
  useWithSubscription,
} from '../../../../../../graphql/with-subscription';

type UseWithOfferingNavbar_OrderBookSubNavItem_OrderBookStatusChangedSubscriptionOptions<
  TQueryData extends {},
  TQueryVariables extends Apollo.OperationVariables
> = Omit<
  UseWithSubscriptionOptions<
    OfferingNavbar_OrderBookSubNavItem_OrderBookStatusChangedSubscription,
    OfferingNavbar_OrderBookSubNavItem_OrderBookStatusChangedSubscriptionVariables,
    TQueryData,
    TQueryVariables
  >,
  'document' | 'clientName'
>;

export const useWithOfferingNavbar_OrderBookSubNavItem_OrderBookStatusChangedSubscription = <
  TQueryData extends {},
  TQueryVariables extends Apollo.OperationVariables
>(
  queryResults: Apollo.QueryResult<TQueryData, TQueryVariables>,
  options: UseWithOfferingNavbar_OrderBookSubNavItem_OrderBookStatusChangedSubscriptionOptions<
    TQueryData,
    TQueryVariables
  >
) => {
  return useWithSubscription(queryResults, {
    ...options,
    document: OfferingNavbar_OrderBookSubNavItem_OrderBookStatusChangedDocument,
  });
};

export const useWithOfferingNavbar_OrderBookSubNavItem_OrderBookStatusChangedLazySubscription = <
  TQueryData extends {},
  TQueryVariables extends Apollo.OperationVariables
>(
  queryResults: Apollo.LazyQueryResultTuple<TQueryData, TQueryVariables>,
  options: UseWithOfferingNavbar_OrderBookSubNavItem_OrderBookStatusChangedSubscriptionOptions<
    TQueryData,
    TQueryVariables
  >
) => {
  return useWithLazySubscription(queryResults, {
    ...options,
    document: OfferingNavbar_OrderBookSubNavItem_OrderBookStatusChangedDocument,
  });
};

import { makeMockResponse } from '../../../../../../graphql/mock-response';

export const makeOfferingNavbar_OrderBookSubNavItem_OrderBookMockResponse = makeMockResponse<
  OfferingNavbar_OrderBookSubNavItem_OrderBookQueryVariables,
  OfferingNavbar_OrderBookSubNavItem_OrderBookQuery
>(OfferingNavbar_OrderBookSubNavItem_OrderBookDocument);
