/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SyndicateWires_CreateNonSyndicateSellingGroupInvitationWireMutationVariables =
  Types.Exact<{
    offeringId: Types.Scalars['UUID'];
    payload: Types.CreateNonSyndicateSellingGroupInvitationWireInput;
  }>;

export type SyndicateWires_CreateNonSyndicateSellingGroupInvitationWireMutation = {
  readonly __typename?: 'Mutation';
  readonly createNonSyndicateSellingGroupInvitationWire:
    | {
        readonly __typename?: 'NonSyndicateSellingGroupInvitationWire';
        readonly id: string;
        readonly status: Types.WireStatus;
        readonly createdAt: string;
        readonly sender: string;
        readonly recipientsUserData: ReadonlyArray<{
          readonly __typename?: 'NonSyndicateSellingGroupInvitationRecipientData';
          readonly recipient: string;
          readonly disclaimer?: string | null;
          readonly masterSdaDate: string;
          readonly retention: number;
        }>;
        readonly wireMessages: ReadonlyArray<{
          readonly __typename?: 'WireMessage';
          readonly id: string;
          readonly emailAddresses: ReadonlyArray<string>;
          readonly expiredAt?: string | null;
          readonly respondedAt?: string | null;
          readonly responseStatus?: Types.SentWireResponseStatus | null;
          readonly wireMessageAndResponseStatus: Types.WireMessageAndResponseStatus;
          readonly sentAt?: string | null;
          readonly htmlBody: string;
          readonly firmSnapshot?: {
            readonly __typename?: 'FirmSnapshot';
            readonly cmgEntityKey: string;
            readonly firmName: string;
            readonly offeringManagerRole?: Types.SyndicateWiresManagerRole | null;
          } | null;
        }>;
      }
    | {
        readonly __typename: 'ServiceError';
        readonly code: Types.ServiceErrorCode;
        readonly message: string;
        readonly target?: string | null;
        readonly details?: ReadonlyArray<{
          readonly __typename: 'ErrorDetail';
          readonly code?: string | null;
          readonly message?: string | null;
          readonly target?: string | null;
        }> | null;
      };
};

export type SyndicateWires_DeleteNonSyndicateSellingGroupInvitationWireMutationVariables =
  Types.Exact<{
    offeringId: Types.Scalars['UUID'];
    syndicateWireId: Types.Scalars['UUID'];
  }>;

export type SyndicateWires_DeleteNonSyndicateSellingGroupInvitationWireMutation = {
  readonly __typename?: 'Mutation';
  readonly deleteNonSyndicateSellingGroupInvitationWire:
    | { readonly __typename?: 'DeleteWire'; readonly id: string }
    | {
        readonly __typename: 'ServiceError';
        readonly code: Types.ServiceErrorCode;
        readonly message: string;
        readonly target?: string | null;
        readonly details?: ReadonlyArray<{
          readonly __typename: 'ErrorDetail';
          readonly code?: string | null;
          readonly message?: string | null;
          readonly target?: string | null;
        }> | null;
      };
};

export type SyndicateWires_NonSyndicateSellingGroupInvitationWireDetailsQueryVariables =
  Types.Exact<{
    offeringId: Types.Scalars['UUID'];
    syndicateWireId: Types.Scalars['UUID'];
  }>;

export type SyndicateWires_NonSyndicateSellingGroupInvitationWireDetailsQuery = {
  readonly __typename?: 'Query';
  readonly syndicateWires: {
    readonly __typename?: 'SyndicateWires';
    readonly id: string;
    readonly publishedOrPrivateOffering: {
      readonly __typename?: 'SyndicateWiresOffering';
      readonly id: string;
      readonly stage: Types.OfferingStage;
    };
  };
  readonly nonSyndicateSellingGroupInvitationWireDetails: {
    readonly __typename?: 'NonSyndicateSellingGroupInvitationWire';
    readonly id: string;
    readonly status: Types.WireStatus;
    readonly createdAt: string;
    readonly sender: string;
    readonly recipientsUserData: ReadonlyArray<{
      readonly __typename?: 'NonSyndicateSellingGroupInvitationRecipientData';
      readonly recipient: string;
      readonly disclaimer?: string | null;
      readonly masterSdaDate: string;
      readonly retention: number;
    }>;
    readonly wireMessages: ReadonlyArray<{
      readonly __typename?: 'WireMessage';
      readonly id: string;
      readonly emailAddresses: ReadonlyArray<string>;
      readonly expiredAt?: string | null;
      readonly respondedAt?: string | null;
      readonly responseStatus?: Types.SentWireResponseStatus | null;
      readonly wireMessageAndResponseStatus: Types.WireMessageAndResponseStatus;
      readonly sentAt?: string | null;
      readonly htmlBody: string;
      readonly firmSnapshot?: {
        readonly __typename?: 'FirmSnapshot';
        readonly cmgEntityKey: string;
        readonly firmName: string;
        readonly offeringManagerRole?: Types.SyndicateWiresManagerRole | null;
      } | null;
    }>;
  };
};

export type SyndicateWires_NonSyndicateSellingGroupInvitationRecipientDataPartsFragment = {
  readonly __typename?: 'NonSyndicateSellingGroupInvitationRecipientData';
  readonly recipient: string;
  readonly disclaimer?: string | null;
  readonly masterSdaDate: string;
  readonly retention: number;
};

export type SyndicateWires_NonSyndicateSellingGroupInvitationWirePartsFragment = {
  readonly __typename?: 'NonSyndicateSellingGroupInvitationWire';
  readonly id: string;
  readonly status: Types.WireStatus;
  readonly createdAt: string;
  readonly sender: string;
  readonly recipientsUserData: ReadonlyArray<{
    readonly __typename?: 'NonSyndicateSellingGroupInvitationRecipientData';
    readonly recipient: string;
    readonly disclaimer?: string | null;
    readonly masterSdaDate: string;
    readonly retention: number;
  }>;
  readonly wireMessages: ReadonlyArray<{
    readonly __typename?: 'WireMessage';
    readonly id: string;
    readonly emailAddresses: ReadonlyArray<string>;
    readonly expiredAt?: string | null;
    readonly respondedAt?: string | null;
    readonly responseStatus?: Types.SentWireResponseStatus | null;
    readonly wireMessageAndResponseStatus: Types.WireMessageAndResponseStatus;
    readonly sentAt?: string | null;
    readonly htmlBody: string;
    readonly firmSnapshot?: {
      readonly __typename?: 'FirmSnapshot';
      readonly cmgEntityKey: string;
      readonly firmName: string;
      readonly offeringManagerRole?: Types.SyndicateWiresManagerRole | null;
    } | null;
  }>;
};

export type SyndicateWires_NonSyndicateSellingGroupInvitationWirePreviewQueryVariables =
  Types.Exact<{
    offeringId: Types.Scalars['UUID'];
    syndicateWireId?: Types.InputMaybe<Types.Scalars['UUID']>;
    cmgEntityKey?: Types.InputMaybe<Types.Scalars['String']>;
  }>;

export type SyndicateWires_NonSyndicateSellingGroupInvitationWirePreviewQuery = {
  readonly __typename?: 'Query';
  readonly nonSyndicateSellingGroupInvitationWirePreview: {
    readonly __typename?: 'WirePreview';
    readonly htmlContent: string;
  };
};

export type SyndicateWires_NonSyndicateSellingGroupInvitationWireValidationPartsFragment = {
  readonly __typename?: 'NonSyndicateSellingGroupInvitationWireValidation';
  readonly orderBookErrors: ReadonlyArray<Types.OrderBookErrorType>;
  readonly offeringErrors: ReadonlyArray<Types.NonSyndicateSellingGroupInvitationWireValidationOfferingErrors>;
  readonly settlementErrors: ReadonlyArray<Types.SettlementErrorType>;
  readonly managers: ReadonlyArray<{
    readonly __typename?: 'ManagerValidation';
    readonly cmgEntityKey: string;
    readonly fields: ReadonlyArray<Types.ManagerErrorType>;
  }>;
  readonly sender: {
    readonly __typename?: 'ManagerValidation';
    readonly cmgEntityKey: string;
    readonly fields: ReadonlyArray<Types.ManagerErrorType>;
  };
};

export type SyndicateWires_NonSyndicateSellingGroupInvitationWireValidationQueryVariables =
  Types.Exact<{
    offeringId: Types.Scalars['UUID'];
  }>;

export type SyndicateWires_NonSyndicateSellingGroupInvitationWireValidationQuery = {
  readonly __typename?: 'Query';
  readonly nonSyndicateSellingGroupInvitationWireValidation: {
    readonly __typename?: 'NonSyndicateSellingGroupInvitationWireValidation';
    readonly orderBookErrors: ReadonlyArray<Types.OrderBookErrorType>;
    readonly offeringErrors: ReadonlyArray<Types.NonSyndicateSellingGroupInvitationWireValidationOfferingErrors>;
    readonly settlementErrors: ReadonlyArray<Types.SettlementErrorType>;
    readonly managers: ReadonlyArray<{
      readonly __typename?: 'ManagerValidation';
      readonly cmgEntityKey: string;
      readonly fields: ReadonlyArray<Types.ManagerErrorType>;
    }>;
    readonly sender: {
      readonly __typename?: 'ManagerValidation';
      readonly cmgEntityKey: string;
      readonly fields: ReadonlyArray<Types.ManagerErrorType>;
    };
  };
};

export type SyndicateWires_NonSyndicateSellingGroupInvitationWiresQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type SyndicateWires_NonSyndicateSellingGroupInvitationWiresQuery = {
  readonly __typename?: 'Query';
  readonly nonSyndicateSellingGroupInvitationWireList: ReadonlyArray<{
    readonly __typename?: 'NonSyndicateSellingGroupInvitationWire';
    readonly id: string;
    readonly status: Types.WireStatus;
    readonly createdAt: string;
    readonly sender: string;
    readonly recipientsUserData: ReadonlyArray<{
      readonly __typename?: 'NonSyndicateSellingGroupInvitationRecipientData';
      readonly recipient: string;
      readonly disclaimer?: string | null;
      readonly masterSdaDate: string;
      readonly retention: number;
    }>;
    readonly wireMessages: ReadonlyArray<{
      readonly __typename?: 'WireMessage';
      readonly id: string;
      readonly emailAddresses: ReadonlyArray<string>;
      readonly expiredAt?: string | null;
      readonly respondedAt?: string | null;
      readonly responseStatus?: Types.SentWireResponseStatus | null;
      readonly wireMessageAndResponseStatus: Types.WireMessageAndResponseStatus;
      readonly sentAt?: string | null;
      readonly htmlBody: string;
      readonly firmSnapshot?: {
        readonly __typename?: 'FirmSnapshot';
        readonly cmgEntityKey: string;
        readonly firmName: string;
        readonly offeringManagerRole?: Types.SyndicateWiresManagerRole | null;
      } | null;
    }>;
  }>;
};

export type SyndicateWires_SendNonSyndicateSellingGroupInvitationWireMutationVariables =
  Types.Exact<{
    offeringId: Types.Scalars['UUID'];
    syndicateWireId: Types.Scalars['UUID'];
    managerIds: ReadonlyArray<Types.Scalars['String']> | Types.Scalars['String'];
    sendDateTimeZone: Types.Scalars['String'];
    shouldAddSenderToCc: Types.Scalars['Boolean'];
  }>;

export type SyndicateWires_SendNonSyndicateSellingGroupInvitationWireMutation = {
  readonly __typename?: 'Mutation';
  readonly sendNonSyndicateSellingGroupInvitationWire:
    | {
        readonly __typename?: 'NonSyndicateSellingGroupInvitationWire';
        readonly id: string;
        readonly status: Types.WireStatus;
        readonly createdAt: string;
        readonly sender: string;
        readonly recipientsUserData: ReadonlyArray<{
          readonly __typename?: 'NonSyndicateSellingGroupInvitationRecipientData';
          readonly recipient: string;
          readonly disclaimer?: string | null;
          readonly masterSdaDate: string;
          readonly retention: number;
        }>;
        readonly wireMessages: ReadonlyArray<{
          readonly __typename?: 'WireMessage';
          readonly id: string;
          readonly emailAddresses: ReadonlyArray<string>;
          readonly expiredAt?: string | null;
          readonly respondedAt?: string | null;
          readonly responseStatus?: Types.SentWireResponseStatus | null;
          readonly wireMessageAndResponseStatus: Types.WireMessageAndResponseStatus;
          readonly sentAt?: string | null;
          readonly htmlBody: string;
          readonly firmSnapshot?: {
            readonly __typename?: 'FirmSnapshot';
            readonly cmgEntityKey: string;
            readonly firmName: string;
            readonly offeringManagerRole?: Types.SyndicateWiresManagerRole | null;
          } | null;
        }>;
      }
    | {
        readonly __typename: 'ServiceError';
        readonly code: Types.ServiceErrorCode;
        readonly message: string;
        readonly target?: string | null;
        readonly details?: ReadonlyArray<{
          readonly __typename: 'ErrorDetail';
          readonly code?: string | null;
          readonly message?: string | null;
          readonly target?: string | null;
        }> | null;
      };
};

export type SyndicateWires_UpdateNonSyndicateSellingGroupInvitationWireMutationVariables =
  Types.Exact<{
    offeringId: Types.Scalars['UUID'];
    syndicateWireId: Types.Scalars['UUID'];
    payload: Types.UpdateNonSyndicateSellingGroupInvitationWireInput;
  }>;

export type SyndicateWires_UpdateNonSyndicateSellingGroupInvitationWireMutation = {
  readonly __typename?: 'Mutation';
  readonly updateNonSyndicateSellingGroupInvitationWire:
    | {
        readonly __typename?: 'NonSyndicateSellingGroupInvitationWire';
        readonly id: string;
        readonly status: Types.WireStatus;
        readonly createdAt: string;
        readonly sender: string;
        readonly recipientsUserData: ReadonlyArray<{
          readonly __typename?: 'NonSyndicateSellingGroupInvitationRecipientData';
          readonly recipient: string;
          readonly disclaimer?: string | null;
          readonly masterSdaDate: string;
          readonly retention: number;
        }>;
        readonly wireMessages: ReadonlyArray<{
          readonly __typename?: 'WireMessage';
          readonly id: string;
          readonly emailAddresses: ReadonlyArray<string>;
          readonly expiredAt?: string | null;
          readonly respondedAt?: string | null;
          readonly responseStatus?: Types.SentWireResponseStatus | null;
          readonly wireMessageAndResponseStatus: Types.WireMessageAndResponseStatus;
          readonly sentAt?: string | null;
          readonly htmlBody: string;
          readonly firmSnapshot?: {
            readonly __typename?: 'FirmSnapshot';
            readonly cmgEntityKey: string;
            readonly firmName: string;
            readonly offeringManagerRole?: Types.SyndicateWiresManagerRole | null;
          } | null;
        }>;
      }
    | {
        readonly __typename: 'ServiceError';
        readonly code: Types.ServiceErrorCode;
        readonly message: string;
        readonly target?: string | null;
        readonly details?: ReadonlyArray<{
          readonly __typename: 'ErrorDetail';
          readonly code?: string | null;
          readonly message?: string | null;
          readonly target?: string | null;
        }> | null;
      };
};

export const SyndicateWires_NonSyndicateSellingGroupInvitationRecipientDataPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'SyndicateWires_NonSyndicateSellingGroupInvitationRecipientDataParts',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'NonSyndicateSellingGroupInvitationRecipientData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'recipient' } },
          { kind: 'Field', name: { kind: 'Name', value: 'disclaimer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'masterSdaDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'retention' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const SyndicateWires_NonSyndicateSellingGroupInvitationWirePartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_NonSyndicateSellingGroupInvitationWireParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'NonSyndicateSellingGroupInvitationWire' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recipientsUserData' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'SyndicateWires_NonSyndicateSellingGroupInvitationRecipientDataParts',
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wireMessages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_WireMessageParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'SyndicateWires_NonSyndicateSellingGroupInvitationRecipientDataParts',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'NonSyndicateSellingGroupInvitationRecipientData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'recipient' } },
          { kind: 'Field', name: { kind: 'Name', value: 'disclaimer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'masterSdaDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'retention' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_WireMessageParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WireMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddresses' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'firmSnapshot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_FirmSnapshotParts' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'respondedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'responseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wireMessageAndResponseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sentAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'htmlBody' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_FirmSnapshotParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FirmSnapshot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringManagerRole' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const SyndicateWires_NonSyndicateSellingGroupInvitationWireValidationPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'SyndicateWires_NonSyndicateSellingGroupInvitationWireValidationParts',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'NonSyndicateSellingGroupInvitationWireValidation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'orderBookErrors' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringErrors' } },
          { kind: 'Field', name: { kind: 'Name', value: 'settlementErrors' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'managers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_ManagerValidationParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sender' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_ManagerValidationParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_ManagerValidationParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ManagerValidation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fields' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const SyndicateWires_CreateNonSyndicateSellingGroupInvitationWireDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SyndicateWires_CreateNonSyndicateSellingGroupInvitationWire' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'payload' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateNonSyndicateSellingGroupInvitationWireInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createNonSyndicateSellingGroupInvitationWire' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'payload' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'payload' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'SyndicateWires_NonSyndicateSellingGroupInvitationWireParts',
                  },
                },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ServiceErrorParts' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'SyndicateWires_NonSyndicateSellingGroupInvitationRecipientDataParts',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'NonSyndicateSellingGroupInvitationRecipientData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'recipient' } },
          { kind: 'Field', name: { kind: 'Name', value: 'disclaimer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'masterSdaDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'retention' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_NonSyndicateSellingGroupInvitationWireParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'NonSyndicateSellingGroupInvitationWire' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recipientsUserData' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'SyndicateWires_NonSyndicateSellingGroupInvitationRecipientDataParts',
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wireMessages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_WireMessageParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_WireMessageParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WireMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddresses' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'firmSnapshot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_FirmSnapshotParts' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'respondedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'responseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wireMessageAndResponseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sentAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'htmlBody' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_FirmSnapshotParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FirmSnapshot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringManagerRole' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ServiceErrorParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ServiceError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'target' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'target' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type SyndicateWires_CreateNonSyndicateSellingGroupInvitationWireMutationFn =
  Apollo.MutationFunction<
    SyndicateWires_CreateNonSyndicateSellingGroupInvitationWireMutation,
    SyndicateWires_CreateNonSyndicateSellingGroupInvitationWireMutationVariables
  >;

/**
 * __useSyndicateWires_CreateNonSyndicateSellingGroupInvitationWireMutation__
 *
 * To run a mutation, you first call `useSyndicateWires_CreateNonSyndicateSellingGroupInvitationWireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyndicateWires_CreateNonSyndicateSellingGroupInvitationWireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syndicateWiresCreateNonSyndicateSellingGroupInvitationWireMutation, { data, loading, error }] = useSyndicateWires_CreateNonSyndicateSellingGroupInvitationWireMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useSyndicateWires_CreateNonSyndicateSellingGroupInvitationWireMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SyndicateWires_CreateNonSyndicateSellingGroupInvitationWireMutation,
    SyndicateWires_CreateNonSyndicateSellingGroupInvitationWireMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SyndicateWires_CreateNonSyndicateSellingGroupInvitationWireMutation,
    SyndicateWires_CreateNonSyndicateSellingGroupInvitationWireMutationVariables
  >(SyndicateWires_CreateNonSyndicateSellingGroupInvitationWireDocument, options);
}
export type SyndicateWires_CreateNonSyndicateSellingGroupInvitationWireMutationHookResult =
  ReturnType<typeof useSyndicateWires_CreateNonSyndicateSellingGroupInvitationWireMutation>;
export type SyndicateWires_CreateNonSyndicateSellingGroupInvitationWireMutationResult =
  Apollo.MutationResult<SyndicateWires_CreateNonSyndicateSellingGroupInvitationWireMutation>;
export type SyndicateWires_CreateNonSyndicateSellingGroupInvitationWireMutationOptions =
  Apollo.BaseMutationOptions<
    SyndicateWires_CreateNonSyndicateSellingGroupInvitationWireMutation,
    SyndicateWires_CreateNonSyndicateSellingGroupInvitationWireMutationVariables
  >;
export const SyndicateWires_DeleteNonSyndicateSellingGroupInvitationWireDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SyndicateWires_DeleteNonSyndicateSellingGroupInvitationWire' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'syndicateWireId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteNonSyndicateSellingGroupInvitationWire' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'syndicateWireId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'syndicateWireId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_DeleteWireParts' },
                },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ServiceErrorParts' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ServiceErrorParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ServiceError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'target' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'target' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_DeleteWireParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DeleteWire' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
      },
    },
  ],
} as unknown as DocumentNode;
export type SyndicateWires_DeleteNonSyndicateSellingGroupInvitationWireMutationFn =
  Apollo.MutationFunction<
    SyndicateWires_DeleteNonSyndicateSellingGroupInvitationWireMutation,
    SyndicateWires_DeleteNonSyndicateSellingGroupInvitationWireMutationVariables
  >;

/**
 * __useSyndicateWires_DeleteNonSyndicateSellingGroupInvitationWireMutation__
 *
 * To run a mutation, you first call `useSyndicateWires_DeleteNonSyndicateSellingGroupInvitationWireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyndicateWires_DeleteNonSyndicateSellingGroupInvitationWireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syndicateWiresDeleteNonSyndicateSellingGroupInvitationWireMutation, { data, loading, error }] = useSyndicateWires_DeleteNonSyndicateSellingGroupInvitationWireMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      syndicateWireId: // value for 'syndicateWireId'
 *   },
 * });
 */
export function useSyndicateWires_DeleteNonSyndicateSellingGroupInvitationWireMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SyndicateWires_DeleteNonSyndicateSellingGroupInvitationWireMutation,
    SyndicateWires_DeleteNonSyndicateSellingGroupInvitationWireMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SyndicateWires_DeleteNonSyndicateSellingGroupInvitationWireMutation,
    SyndicateWires_DeleteNonSyndicateSellingGroupInvitationWireMutationVariables
  >(SyndicateWires_DeleteNonSyndicateSellingGroupInvitationWireDocument, options);
}
export type SyndicateWires_DeleteNonSyndicateSellingGroupInvitationWireMutationHookResult =
  ReturnType<typeof useSyndicateWires_DeleteNonSyndicateSellingGroupInvitationWireMutation>;
export type SyndicateWires_DeleteNonSyndicateSellingGroupInvitationWireMutationResult =
  Apollo.MutationResult<SyndicateWires_DeleteNonSyndicateSellingGroupInvitationWireMutation>;
export type SyndicateWires_DeleteNonSyndicateSellingGroupInvitationWireMutationOptions =
  Apollo.BaseMutationOptions<
    SyndicateWires_DeleteNonSyndicateSellingGroupInvitationWireMutation,
    SyndicateWires_DeleteNonSyndicateSellingGroupInvitationWireMutationVariables
  >;
export const SyndicateWires_NonSyndicateSellingGroupInvitationWireDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SyndicateWires_NonSyndicateSellingGroupInvitationWireDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'syndicateWireId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'syndicateWires' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'publishedOrPrivateOffering' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nonSyndicateSellingGroupInvitationWireDetails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'syndicateWireId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'syndicateWireId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'SyndicateWires_NonSyndicateSellingGroupInvitationWireParts',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'SyndicateWires_NonSyndicateSellingGroupInvitationRecipientDataParts',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'NonSyndicateSellingGroupInvitationRecipientData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'recipient' } },
          { kind: 'Field', name: { kind: 'Name', value: 'disclaimer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'masterSdaDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'retention' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_NonSyndicateSellingGroupInvitationWireParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'NonSyndicateSellingGroupInvitationWire' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recipientsUserData' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'SyndicateWires_NonSyndicateSellingGroupInvitationRecipientDataParts',
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wireMessages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_WireMessageParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_WireMessageParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WireMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddresses' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'firmSnapshot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_FirmSnapshotParts' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'respondedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'responseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wireMessageAndResponseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sentAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'htmlBody' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_FirmSnapshotParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FirmSnapshot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringManagerRole' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useSyndicateWires_NonSyndicateSellingGroupInvitationWireDetailsQuery__
 *
 * To run a query within a React component, call `useSyndicateWires_NonSyndicateSellingGroupInvitationWireDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSyndicateWires_NonSyndicateSellingGroupInvitationWireDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSyndicateWires_NonSyndicateSellingGroupInvitationWireDetailsQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      syndicateWireId: // value for 'syndicateWireId'
 *   },
 * });
 */
export function useSyndicateWires_NonSyndicateSellingGroupInvitationWireDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    SyndicateWires_NonSyndicateSellingGroupInvitationWireDetailsQuery,
    SyndicateWires_NonSyndicateSellingGroupInvitationWireDetailsQueryVariables
  > &
    (
      | {
          variables: SyndicateWires_NonSyndicateSellingGroupInvitationWireDetailsQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SyndicateWires_NonSyndicateSellingGroupInvitationWireDetailsQuery,
    SyndicateWires_NonSyndicateSellingGroupInvitationWireDetailsQueryVariables
  >(SyndicateWires_NonSyndicateSellingGroupInvitationWireDetailsDocument, options);
}
export function useSyndicateWires_NonSyndicateSellingGroupInvitationWireDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SyndicateWires_NonSyndicateSellingGroupInvitationWireDetailsQuery,
    SyndicateWires_NonSyndicateSellingGroupInvitationWireDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SyndicateWires_NonSyndicateSellingGroupInvitationWireDetailsQuery,
    SyndicateWires_NonSyndicateSellingGroupInvitationWireDetailsQueryVariables
  >(SyndicateWires_NonSyndicateSellingGroupInvitationWireDetailsDocument, options);
}
export function useSyndicateWires_NonSyndicateSellingGroupInvitationWireDetailsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        SyndicateWires_NonSyndicateSellingGroupInvitationWireDetailsQuery,
        SyndicateWires_NonSyndicateSellingGroupInvitationWireDetailsQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SyndicateWires_NonSyndicateSellingGroupInvitationWireDetailsQuery,
    SyndicateWires_NonSyndicateSellingGroupInvitationWireDetailsQueryVariables
  >(SyndicateWires_NonSyndicateSellingGroupInvitationWireDetailsDocument, options);
}
export type SyndicateWires_NonSyndicateSellingGroupInvitationWireDetailsQueryHookResult =
  ReturnType<typeof useSyndicateWires_NonSyndicateSellingGroupInvitationWireDetailsQuery>;
export type SyndicateWires_NonSyndicateSellingGroupInvitationWireDetailsLazyQueryHookResult =
  ReturnType<typeof useSyndicateWires_NonSyndicateSellingGroupInvitationWireDetailsLazyQuery>;
export type SyndicateWires_NonSyndicateSellingGroupInvitationWireDetailsSuspenseQueryHookResult =
  ReturnType<typeof useSyndicateWires_NonSyndicateSellingGroupInvitationWireDetailsSuspenseQuery>;
export type SyndicateWires_NonSyndicateSellingGroupInvitationWireDetailsQueryResult =
  Apollo.QueryResult<
    SyndicateWires_NonSyndicateSellingGroupInvitationWireDetailsQuery,
    SyndicateWires_NonSyndicateSellingGroupInvitationWireDetailsQueryVariables
  >;
export const SyndicateWires_NonSyndicateSellingGroupInvitationWirePreviewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SyndicateWires_NonSyndicateSellingGroupInvitationWirePreview' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'syndicateWireId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKey' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nonSyndicateSellingGroupInvitationWirePreview' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'syndicateWireId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'syndicateWireId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cmgEntityKey' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKey' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'htmlContent' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useSyndicateWires_NonSyndicateSellingGroupInvitationWirePreviewQuery__
 *
 * To run a query within a React component, call `useSyndicateWires_NonSyndicateSellingGroupInvitationWirePreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useSyndicateWires_NonSyndicateSellingGroupInvitationWirePreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSyndicateWires_NonSyndicateSellingGroupInvitationWirePreviewQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      syndicateWireId: // value for 'syndicateWireId'
 *      cmgEntityKey: // value for 'cmgEntityKey'
 *   },
 * });
 */
export function useSyndicateWires_NonSyndicateSellingGroupInvitationWirePreviewQuery(
  baseOptions: Apollo.QueryHookOptions<
    SyndicateWires_NonSyndicateSellingGroupInvitationWirePreviewQuery,
    SyndicateWires_NonSyndicateSellingGroupInvitationWirePreviewQueryVariables
  > &
    (
      | {
          variables: SyndicateWires_NonSyndicateSellingGroupInvitationWirePreviewQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SyndicateWires_NonSyndicateSellingGroupInvitationWirePreviewQuery,
    SyndicateWires_NonSyndicateSellingGroupInvitationWirePreviewQueryVariables
  >(SyndicateWires_NonSyndicateSellingGroupInvitationWirePreviewDocument, options);
}
export function useSyndicateWires_NonSyndicateSellingGroupInvitationWirePreviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SyndicateWires_NonSyndicateSellingGroupInvitationWirePreviewQuery,
    SyndicateWires_NonSyndicateSellingGroupInvitationWirePreviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SyndicateWires_NonSyndicateSellingGroupInvitationWirePreviewQuery,
    SyndicateWires_NonSyndicateSellingGroupInvitationWirePreviewQueryVariables
  >(SyndicateWires_NonSyndicateSellingGroupInvitationWirePreviewDocument, options);
}
export function useSyndicateWires_NonSyndicateSellingGroupInvitationWirePreviewSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        SyndicateWires_NonSyndicateSellingGroupInvitationWirePreviewQuery,
        SyndicateWires_NonSyndicateSellingGroupInvitationWirePreviewQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SyndicateWires_NonSyndicateSellingGroupInvitationWirePreviewQuery,
    SyndicateWires_NonSyndicateSellingGroupInvitationWirePreviewQueryVariables
  >(SyndicateWires_NonSyndicateSellingGroupInvitationWirePreviewDocument, options);
}
export type SyndicateWires_NonSyndicateSellingGroupInvitationWirePreviewQueryHookResult =
  ReturnType<typeof useSyndicateWires_NonSyndicateSellingGroupInvitationWirePreviewQuery>;
export type SyndicateWires_NonSyndicateSellingGroupInvitationWirePreviewLazyQueryHookResult =
  ReturnType<typeof useSyndicateWires_NonSyndicateSellingGroupInvitationWirePreviewLazyQuery>;
export type SyndicateWires_NonSyndicateSellingGroupInvitationWirePreviewSuspenseQueryHookResult =
  ReturnType<typeof useSyndicateWires_NonSyndicateSellingGroupInvitationWirePreviewSuspenseQuery>;
export type SyndicateWires_NonSyndicateSellingGroupInvitationWirePreviewQueryResult =
  Apollo.QueryResult<
    SyndicateWires_NonSyndicateSellingGroupInvitationWirePreviewQuery,
    SyndicateWires_NonSyndicateSellingGroupInvitationWirePreviewQueryVariables
  >;
export const SyndicateWires_NonSyndicateSellingGroupInvitationWireValidationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {
        kind: 'Name',
        value: 'SyndicateWires_NonSyndicateSellingGroupInvitationWireValidation',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nonSyndicateSellingGroupInvitationWireValidation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'SyndicateWires_NonSyndicateSellingGroupInvitationWireValidationParts',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'SyndicateWires_NonSyndicateSellingGroupInvitationWireValidationParts',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'NonSyndicateSellingGroupInvitationWireValidation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'orderBookErrors' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringErrors' } },
          { kind: 'Field', name: { kind: 'Name', value: 'settlementErrors' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'managers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_ManagerValidationParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sender' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_ManagerValidationParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_ManagerValidationParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ManagerValidation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fields' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useSyndicateWires_NonSyndicateSellingGroupInvitationWireValidationQuery__
 *
 * To run a query within a React component, call `useSyndicateWires_NonSyndicateSellingGroupInvitationWireValidationQuery` and pass it any options that fit your needs.
 * When your component renders, `useSyndicateWires_NonSyndicateSellingGroupInvitationWireValidationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSyndicateWires_NonSyndicateSellingGroupInvitationWireValidationQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useSyndicateWires_NonSyndicateSellingGroupInvitationWireValidationQuery(
  baseOptions: Apollo.QueryHookOptions<
    SyndicateWires_NonSyndicateSellingGroupInvitationWireValidationQuery,
    SyndicateWires_NonSyndicateSellingGroupInvitationWireValidationQueryVariables
  > &
    (
      | {
          variables: SyndicateWires_NonSyndicateSellingGroupInvitationWireValidationQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SyndicateWires_NonSyndicateSellingGroupInvitationWireValidationQuery,
    SyndicateWires_NonSyndicateSellingGroupInvitationWireValidationQueryVariables
  >(SyndicateWires_NonSyndicateSellingGroupInvitationWireValidationDocument, options);
}
export function useSyndicateWires_NonSyndicateSellingGroupInvitationWireValidationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SyndicateWires_NonSyndicateSellingGroupInvitationWireValidationQuery,
    SyndicateWires_NonSyndicateSellingGroupInvitationWireValidationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SyndicateWires_NonSyndicateSellingGroupInvitationWireValidationQuery,
    SyndicateWires_NonSyndicateSellingGroupInvitationWireValidationQueryVariables
  >(SyndicateWires_NonSyndicateSellingGroupInvitationWireValidationDocument, options);
}
export function useSyndicateWires_NonSyndicateSellingGroupInvitationWireValidationSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        SyndicateWires_NonSyndicateSellingGroupInvitationWireValidationQuery,
        SyndicateWires_NonSyndicateSellingGroupInvitationWireValidationQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SyndicateWires_NonSyndicateSellingGroupInvitationWireValidationQuery,
    SyndicateWires_NonSyndicateSellingGroupInvitationWireValidationQueryVariables
  >(SyndicateWires_NonSyndicateSellingGroupInvitationWireValidationDocument, options);
}
export type SyndicateWires_NonSyndicateSellingGroupInvitationWireValidationQueryHookResult =
  ReturnType<typeof useSyndicateWires_NonSyndicateSellingGroupInvitationWireValidationQuery>;
export type SyndicateWires_NonSyndicateSellingGroupInvitationWireValidationLazyQueryHookResult =
  ReturnType<typeof useSyndicateWires_NonSyndicateSellingGroupInvitationWireValidationLazyQuery>;
export type SyndicateWires_NonSyndicateSellingGroupInvitationWireValidationSuspenseQueryHookResult =
  ReturnType<
    typeof useSyndicateWires_NonSyndicateSellingGroupInvitationWireValidationSuspenseQuery
  >;
export type SyndicateWires_NonSyndicateSellingGroupInvitationWireValidationQueryResult =
  Apollo.QueryResult<
    SyndicateWires_NonSyndicateSellingGroupInvitationWireValidationQuery,
    SyndicateWires_NonSyndicateSellingGroupInvitationWireValidationQueryVariables
  >;
export const SyndicateWires_NonSyndicateSellingGroupInvitationWiresDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SyndicateWires_NonSyndicateSellingGroupInvitationWires' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nonSyndicateSellingGroupInvitationWireList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'SyndicateWires_NonSyndicateSellingGroupInvitationWireParts',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'SyndicateWires_NonSyndicateSellingGroupInvitationRecipientDataParts',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'NonSyndicateSellingGroupInvitationRecipientData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'recipient' } },
          { kind: 'Field', name: { kind: 'Name', value: 'disclaimer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'masterSdaDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'retention' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_NonSyndicateSellingGroupInvitationWireParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'NonSyndicateSellingGroupInvitationWire' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recipientsUserData' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'SyndicateWires_NonSyndicateSellingGroupInvitationRecipientDataParts',
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wireMessages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_WireMessageParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_WireMessageParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WireMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddresses' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'firmSnapshot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_FirmSnapshotParts' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'respondedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'responseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wireMessageAndResponseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sentAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'htmlBody' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_FirmSnapshotParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FirmSnapshot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringManagerRole' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useSyndicateWires_NonSyndicateSellingGroupInvitationWiresQuery__
 *
 * To run a query within a React component, call `useSyndicateWires_NonSyndicateSellingGroupInvitationWiresQuery` and pass it any options that fit your needs.
 * When your component renders, `useSyndicateWires_NonSyndicateSellingGroupInvitationWiresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSyndicateWires_NonSyndicateSellingGroupInvitationWiresQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useSyndicateWires_NonSyndicateSellingGroupInvitationWiresQuery(
  baseOptions: Apollo.QueryHookOptions<
    SyndicateWires_NonSyndicateSellingGroupInvitationWiresQuery,
    SyndicateWires_NonSyndicateSellingGroupInvitationWiresQueryVariables
  > &
    (
      | {
          variables: SyndicateWires_NonSyndicateSellingGroupInvitationWiresQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SyndicateWires_NonSyndicateSellingGroupInvitationWiresQuery,
    SyndicateWires_NonSyndicateSellingGroupInvitationWiresQueryVariables
  >(SyndicateWires_NonSyndicateSellingGroupInvitationWiresDocument, options);
}
export function useSyndicateWires_NonSyndicateSellingGroupInvitationWiresLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SyndicateWires_NonSyndicateSellingGroupInvitationWiresQuery,
    SyndicateWires_NonSyndicateSellingGroupInvitationWiresQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SyndicateWires_NonSyndicateSellingGroupInvitationWiresQuery,
    SyndicateWires_NonSyndicateSellingGroupInvitationWiresQueryVariables
  >(SyndicateWires_NonSyndicateSellingGroupInvitationWiresDocument, options);
}
export function useSyndicateWires_NonSyndicateSellingGroupInvitationWiresSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        SyndicateWires_NonSyndicateSellingGroupInvitationWiresQuery,
        SyndicateWires_NonSyndicateSellingGroupInvitationWiresQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SyndicateWires_NonSyndicateSellingGroupInvitationWiresQuery,
    SyndicateWires_NonSyndicateSellingGroupInvitationWiresQueryVariables
  >(SyndicateWires_NonSyndicateSellingGroupInvitationWiresDocument, options);
}
export type SyndicateWires_NonSyndicateSellingGroupInvitationWiresQueryHookResult = ReturnType<
  typeof useSyndicateWires_NonSyndicateSellingGroupInvitationWiresQuery
>;
export type SyndicateWires_NonSyndicateSellingGroupInvitationWiresLazyQueryHookResult = ReturnType<
  typeof useSyndicateWires_NonSyndicateSellingGroupInvitationWiresLazyQuery
>;
export type SyndicateWires_NonSyndicateSellingGroupInvitationWiresSuspenseQueryHookResult =
  ReturnType<typeof useSyndicateWires_NonSyndicateSellingGroupInvitationWiresSuspenseQuery>;
export type SyndicateWires_NonSyndicateSellingGroupInvitationWiresQueryResult = Apollo.QueryResult<
  SyndicateWires_NonSyndicateSellingGroupInvitationWiresQuery,
  SyndicateWires_NonSyndicateSellingGroupInvitationWiresQueryVariables
>;
export const SyndicateWires_SendNonSyndicateSellingGroupInvitationWireDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SyndicateWires_SendNonSyndicateSellingGroupInvitationWire' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'syndicateWireId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'managerIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sendDateTimeZone' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'shouldAddSenderToCc' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendNonSyndicateSellingGroupInvitationWire' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'syndicateWireId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'syndicateWireId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'payload' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'underwriterList' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'managerIds' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'sendDateTimeZone' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'sendDateTimeZone' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'shouldAddSenderToCc' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'shouldAddSenderToCc' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'SyndicateWires_NonSyndicateSellingGroupInvitationWireParts',
                  },
                },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ServiceErrorParts' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'SyndicateWires_NonSyndicateSellingGroupInvitationRecipientDataParts',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'NonSyndicateSellingGroupInvitationRecipientData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'recipient' } },
          { kind: 'Field', name: { kind: 'Name', value: 'disclaimer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'masterSdaDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'retention' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_NonSyndicateSellingGroupInvitationWireParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'NonSyndicateSellingGroupInvitationWire' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recipientsUserData' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'SyndicateWires_NonSyndicateSellingGroupInvitationRecipientDataParts',
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wireMessages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_WireMessageParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_WireMessageParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WireMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddresses' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'firmSnapshot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_FirmSnapshotParts' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'respondedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'responseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wireMessageAndResponseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sentAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'htmlBody' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_FirmSnapshotParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FirmSnapshot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringManagerRole' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ServiceErrorParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ServiceError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'target' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'target' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type SyndicateWires_SendNonSyndicateSellingGroupInvitationWireMutationFn =
  Apollo.MutationFunction<
    SyndicateWires_SendNonSyndicateSellingGroupInvitationWireMutation,
    SyndicateWires_SendNonSyndicateSellingGroupInvitationWireMutationVariables
  >;

/**
 * __useSyndicateWires_SendNonSyndicateSellingGroupInvitationWireMutation__
 *
 * To run a mutation, you first call `useSyndicateWires_SendNonSyndicateSellingGroupInvitationWireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyndicateWires_SendNonSyndicateSellingGroupInvitationWireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syndicateWiresSendNonSyndicateSellingGroupInvitationWireMutation, { data, loading, error }] = useSyndicateWires_SendNonSyndicateSellingGroupInvitationWireMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      syndicateWireId: // value for 'syndicateWireId'
 *      managerIds: // value for 'managerIds'
 *      sendDateTimeZone: // value for 'sendDateTimeZone'
 *      shouldAddSenderToCc: // value for 'shouldAddSenderToCc'
 *   },
 * });
 */
export function useSyndicateWires_SendNonSyndicateSellingGroupInvitationWireMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SyndicateWires_SendNonSyndicateSellingGroupInvitationWireMutation,
    SyndicateWires_SendNonSyndicateSellingGroupInvitationWireMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SyndicateWires_SendNonSyndicateSellingGroupInvitationWireMutation,
    SyndicateWires_SendNonSyndicateSellingGroupInvitationWireMutationVariables
  >(SyndicateWires_SendNonSyndicateSellingGroupInvitationWireDocument, options);
}
export type SyndicateWires_SendNonSyndicateSellingGroupInvitationWireMutationHookResult =
  ReturnType<typeof useSyndicateWires_SendNonSyndicateSellingGroupInvitationWireMutation>;
export type SyndicateWires_SendNonSyndicateSellingGroupInvitationWireMutationResult =
  Apollo.MutationResult<SyndicateWires_SendNonSyndicateSellingGroupInvitationWireMutation>;
export type SyndicateWires_SendNonSyndicateSellingGroupInvitationWireMutationOptions =
  Apollo.BaseMutationOptions<
    SyndicateWires_SendNonSyndicateSellingGroupInvitationWireMutation,
    SyndicateWires_SendNonSyndicateSellingGroupInvitationWireMutationVariables
  >;
export const SyndicateWires_UpdateNonSyndicateSellingGroupInvitationWireDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SyndicateWires_UpdateNonSyndicateSellingGroupInvitationWire' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'syndicateWireId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'payload' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateNonSyndicateSellingGroupInvitationWireInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateNonSyndicateSellingGroupInvitationWire' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'syndicateWireId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'syndicateWireId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'payload' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'payload' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'SyndicateWires_NonSyndicateSellingGroupInvitationWireParts',
                  },
                },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ServiceErrorParts' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'SyndicateWires_NonSyndicateSellingGroupInvitationRecipientDataParts',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'NonSyndicateSellingGroupInvitationRecipientData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'recipient' } },
          { kind: 'Field', name: { kind: 'Name', value: 'disclaimer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'masterSdaDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'retention' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_NonSyndicateSellingGroupInvitationWireParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'NonSyndicateSellingGroupInvitationWire' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recipientsUserData' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'SyndicateWires_NonSyndicateSellingGroupInvitationRecipientDataParts',
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wireMessages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_WireMessageParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_WireMessageParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WireMessage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddresses' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'firmSnapshot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SyndicateWires_FirmSnapshotParts' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'respondedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'responseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wireMessageAndResponseStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sentAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'htmlBody' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_FirmSnapshotParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FirmSnapshot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringManagerRole' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ServiceErrorParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ServiceError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'target' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'target' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type SyndicateWires_UpdateNonSyndicateSellingGroupInvitationWireMutationFn =
  Apollo.MutationFunction<
    SyndicateWires_UpdateNonSyndicateSellingGroupInvitationWireMutation,
    SyndicateWires_UpdateNonSyndicateSellingGroupInvitationWireMutationVariables
  >;

/**
 * __useSyndicateWires_UpdateNonSyndicateSellingGroupInvitationWireMutation__
 *
 * To run a mutation, you first call `useSyndicateWires_UpdateNonSyndicateSellingGroupInvitationWireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyndicateWires_UpdateNonSyndicateSellingGroupInvitationWireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syndicateWiresUpdateNonSyndicateSellingGroupInvitationWireMutation, { data, loading, error }] = useSyndicateWires_UpdateNonSyndicateSellingGroupInvitationWireMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      syndicateWireId: // value for 'syndicateWireId'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useSyndicateWires_UpdateNonSyndicateSellingGroupInvitationWireMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SyndicateWires_UpdateNonSyndicateSellingGroupInvitationWireMutation,
    SyndicateWires_UpdateNonSyndicateSellingGroupInvitationWireMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SyndicateWires_UpdateNonSyndicateSellingGroupInvitationWireMutation,
    SyndicateWires_UpdateNonSyndicateSellingGroupInvitationWireMutationVariables
  >(SyndicateWires_UpdateNonSyndicateSellingGroupInvitationWireDocument, options);
}
export type SyndicateWires_UpdateNonSyndicateSellingGroupInvitationWireMutationHookResult =
  ReturnType<typeof useSyndicateWires_UpdateNonSyndicateSellingGroupInvitationWireMutation>;
export type SyndicateWires_UpdateNonSyndicateSellingGroupInvitationWireMutationResult =
  Apollo.MutationResult<SyndicateWires_UpdateNonSyndicateSellingGroupInvitationWireMutation>;
export type SyndicateWires_UpdateNonSyndicateSellingGroupInvitationWireMutationOptions =
  Apollo.BaseMutationOptions<
    SyndicateWires_UpdateNonSyndicateSellingGroupInvitationWireMutation,
    SyndicateWires_UpdateNonSyndicateSellingGroupInvitationWireMutationVariables
  >;

import { makeMockResponse } from '../../../../../../../graphql/mock-response';

export const makeSyndicateWires_NonSyndicateSellingGroupInvitationWireDetailsMockResponse =
  makeMockResponse<
    SyndicateWires_NonSyndicateSellingGroupInvitationWireDetailsQueryVariables,
    SyndicateWires_NonSyndicateSellingGroupInvitationWireDetailsQuery
  >(SyndicateWires_NonSyndicateSellingGroupInvitationWireDetailsDocument);

export const makeSyndicateWires_NonSyndicateSellingGroupInvitationWirePreviewMockResponse =
  makeMockResponse<
    SyndicateWires_NonSyndicateSellingGroupInvitationWirePreviewQueryVariables,
    SyndicateWires_NonSyndicateSellingGroupInvitationWirePreviewQuery
  >(SyndicateWires_NonSyndicateSellingGroupInvitationWirePreviewDocument);

export const makeSyndicateWires_NonSyndicateSellingGroupInvitationWireValidationMockResponse =
  makeMockResponse<
    SyndicateWires_NonSyndicateSellingGroupInvitationWireValidationQueryVariables,
    SyndicateWires_NonSyndicateSellingGroupInvitationWireValidationQuery
  >(SyndicateWires_NonSyndicateSellingGroupInvitationWireValidationDocument);

export const makeSyndicateWires_NonSyndicateSellingGroupInvitationWiresMockResponse =
  makeMockResponse<
    SyndicateWires_NonSyndicateSellingGroupInvitationWiresQueryVariables,
    SyndicateWires_NonSyndicateSellingGroupInvitationWiresQuery
  >(SyndicateWires_NonSyndicateSellingGroupInvitationWiresDocument);
