import { QueryLazyOptions } from '@apollo/client';
import { permissionsByEntity, useAuth, useCheckPermissions } from '@cmg/auth';
import {
  ActionPanelSection,
  Alert,
  Button,
  DotStatus,
  SnackbarManager,
  Stack,
  Typography,
} from '@cmg/design-system';
import { Form, FormikProvider, useFormik } from 'formik';
import React, { ReactElement } from 'react';

import { useFeatureToggles } from '../../../../../../common/config';
import { useCheckFinalInstitutionalAllocationAccess } from '../../../../../../common/util/check-access/useCheckFinalInstitutionalAllocationAccess';
import { useGetPublishedOfferingEntitlementsAndCheckInstitutionalIndicationAccess } from '../../../../../../common/util/check-access/useGetPublishedOfferingEntitlementsAndCheckInstitutionalIndicationAccess';
import {
  AllocationAcknowledgement,
  AllocationAcknowledgementNewStatus,
  MdlAllocationRanking,
  OfferingStatus,
} from '../../../../../../graphql';
import { refetchDemandGridReactiveVar } from '../../../../../order-book/institutional-demand-v2/hooks/useRefetchDemandGridReactiveVar';
import AllocationRanking from '../../../../components/allocation-ranking/AllocationRanking';
import {
  useOfferingSidePanel_UnackknowledgeCoveredInstutionalFinalAllocationMutation,
  useOfferingSidePanel_UnackknowledgeInstutionalFinalAllocationMutation,
} from '../../../../graphql';
import { ManageIndication_MdlAllocationRankingQueryVariables } from '../manage-indication/graphql/__generated__';
import {
  canAcknowledgeAllocation,
  getIsAllocationReleased,
  IndicationType,
} from './Allocation.model';
import { AllocationAcknowledgementStatusLabel } from './components/allocation-acknowledgement-status/AllocationAcknowledgementStatus.model';
import AllocationDetails from './components/allocation-details/AllocationDetails';
import {
  IndicationActivity_AllocationOfferingPartsFragment,
  IndicationActivity_FinalAllocationPartsFragment,
  IndicationActivity_FinalAllocationSetPartsFragment,
  useIndicationActivity_AcknowledgeCoveredIndicationMutation,
  useIndicationActivity_AcknowledgeMyFinalAllocationMutation,
  useIndicationActivity_UpdateFinalAllocationAcknowledgementStatusMutation,
} from './graphql/__generated__';
import { useCanDisplayAllocationActions } from './hooks/useCanDisplayAllocationActions';

export type Props = Readonly<{
  allocation?: IndicationActivity_FinalAllocationPartsFragment | null;
  allocationSet?: IndicationActivity_FinalAllocationSetPartsFragment | null;
  offering: IndicationActivity_AllocationOfferingPartsFragment;
  onChangeAllocationRanking: (ranking: MdlAllocationRanking) => void;
  allocationRanking?: MdlAllocationRanking;
  indication?: IndicationType;
  getAllocationRanking?: (
    options: QueryLazyOptions<ManageIndication_MdlAllocationRankingQueryVariables>
  ) => void;
  billingAndDeliveryAgentCmgEntityKey?: string | null;
  isCoveredAccount: boolean;
  isOrderBookOpen: boolean;
  isSellSideAccount: boolean;
  canBillingAndDeliveryAgentUpdate: boolean;
  canUpdateAllocation: boolean;
}>;

type AllocationFormValues = {
  allocationRanking: MdlAllocationRanking;
};

const Allocation: React.FC<Props> = ({
  allocation,
  allocationSet,
  offering,
  onChangeAllocationRanking,
  allocationRanking,
  indication,
  getAllocationRanking,
  billingAndDeliveryAgentCmgEntityKey,
  isCoveredAccount,
  isOrderBookOpen,
  isSellSideAccount,
  canUpdateAllocation,
  canBillingAndDeliveryAgentUpdate,
}) => {
  const { isOrderBookSidePanelInstitutionalFinalAllocationUnacknowledgeOn } = useFeatureToggles();
  const { canRead } = useCheckFinalInstitutionalAllocationAccess({
    offeringId: offering.id,
  });

  const { oidcUserCmgEntityKey } = useAuth();

  const { canUpdate: canUpdateInstitutionalIndication } =
    useGetPublishedOfferingEntitlementsAndCheckInstitutionalIndicationAccess({
      offeringId: offering.id,
    });

  const canAcknowledge = canAcknowledgeAllocation(
    canBillingAndDeliveryAgentUpdate,
    canUpdateInstitutionalIndication,
    oidcUserCmgEntityKey,
    billingAndDeliveryAgentCmgEntityKey
  );

  const hasIoiRead = useCheckPermissions([permissionsByEntity.Ioi.READ]);

  const [updateAcknowledgementStatus, { loading: updateStatusLoading, error: updateStatusError }] =
    useIndicationActivity_UpdateFinalAllocationAcknowledgementStatusMutation({
      onCompleted: () => {
        SnackbarManager.success('Successfully updated allocation acknowledgement status');
      },
    });

  const [acknowledgeMyAllocation, { loading: updateMyStatusLoading, error: updateMyStatusError }] =
    useIndicationActivity_AcknowledgeMyFinalAllocationMutation({
      onCompleted: () => {
        SnackbarManager.success('Successfully acknowledged allocation');
        if (hasIoiRead) {
          getAllocationRanking?.({ variables: { offeringId: offering.id } });
        }
      },
    });

  const [
    acknowledgeCoveredAllocation,
    { loading: acknowledgeCoveredAllocationLoading, error: acknowledgeCoveredAllocationError },
  ] = useIndicationActivity_AcknowledgeCoveredIndicationMutation({
    onCompleted: () => {
      SnackbarManager.success('Successfully acknowledged allocation');
    },
  });

  const [
    unacknowledgeAllocation,
    { loading: unacknowledgeAllocationLoading, error: unacknowledgeAllocationError },
  ] = useOfferingSidePanel_UnackknowledgeInstutionalFinalAllocationMutation({
    onCompleted: () => {
      SnackbarManager.success('Successfully unacknowledged allocation');
    },
  });

  const [
    unacknowledgeCoveredAllocation,
    { loading: unacknowledgeCoveredAllocationLoading, error: unacknowledgeCoveredAllocationError },
  ] = useOfferingSidePanel_UnackknowledgeCoveredInstutionalFinalAllocationMutation({
    onCompleted: () => {
      SnackbarManager.success('Successfully unacknowledged allocation');
    },
  });

  const isReleased = getIsAllocationReleased({
    allocationShares: indication?.allocationShares,
    isSellSideAccount,
    allocation,
    isVisibleToNonSyndicate: allocationSet?.isVisibleToNonSyndicate ?? false,
    isReleased: allocationSet?.isReleased ?? false,
  });

  const displayAllocationActions = useCanDisplayAllocationActions({
    isReleased,
    allocation,
    indication,
  });

  const handleAcknowledgement = async (status: AllocationAcknowledgementNewStatus) => {
    if (!allocation && !indication) {
      return;
    }

    if (isSellSideAccount) {
      if (isCoveredAccount && canUpdateAllocation && indication) {
        await acknowledgeCoveredAllocation({
          variables: {
            offeringId: indication.offeringId,
            indicationId: indication.id,
            versionId: indication!.allocationVersion!,
          },
        });
      } else {
        await updateAcknowledgementStatus({
          variables: {
            offeringId: offering.id,
            indicationId: allocation!.indicationId,
            versionId: allocation!.version,
            investorReplyStatus: status,
          },
        });
      }
    } else if (status === AllocationAcknowledgementNewStatus.Acknowledged) {
      await acknowledgeMyAllocation({
        variables: {
          offeringId: offering.id,
          versionId: allocation!.version,
        },
      });
    }

    refetchDemandGridReactiveVar(true);
  };

  const handleUnacknowledgement = async () => {
    if (isCoveredAccount) {
      await unacknowledgeCoveredAllocation({
        variables: {
          offeringId: indication!.offeringId,
          indicationId: indication!.id,
          versionId: indication!.allocationVersion!,
        },
      });
    } else {
      await unacknowledgeAllocation({
        variables: {
          offeringId: offering.id,
          indicationId: allocation!.indicationId,
          versionId: allocation!.version,
        },
      });
    }

    refetchDemandGridReactiveVar(true);
  };

  const canRenderAllocationRanking =
    !isSellSideAccount && allocationRanking && offering.status === OfferingStatus.Priced;

  const formik = useFormik<AllocationFormValues>({
    initialValues: {
      allocationRanking: allocationRanking || MdlAllocationRanking.NotIndicated,
    },
    onSubmit: values => {
      onChangeAllocationRanking(values.allocationRanking);
    },
  });

  if (!canRead) {
    return null;
  }

  const investorReplyStatus =
    (allocation?.investorReply?.status || indication?.investorReplyStatus) ??
    AllocationAcknowledgement.Unacknowledged;

  const updateAllocationLoading =
    updateStatusLoading ||
    updateMyStatusLoading ||
    acknowledgeCoveredAllocationLoading ||
    unacknowledgeAllocationLoading ||
    unacknowledgeCoveredAllocationLoading;

  const allocationConfirmation =
    isReleased && investorReplyStatus
      ? AllocationAcknowledgementStatusLabel[investorReplyStatus]
      : '-';

  const getDeclineButton = (): ReactElement => (
    <Button
      key="decline"
      variant="outlined"
      onClick={() => handleAcknowledgement(AllocationAcknowledgementNewStatus.Rejected)}
      disabled={updateAllocationLoading}
    >
      Decline
    </Button>
  );

  const getAcknowledgeButton = (): ReactElement => (
    <Button
      key="acknowledge"
      variant="contained"
      onClick={() => handleAcknowledgement(AllocationAcknowledgementNewStatus.Acknowledged)}
      disabled={updateAllocationLoading}
    >
      Acknowledge
    </Button>
  );

  const getUndoButton = (): ReactElement => (
    <Button
      key="undo"
      variant="outlined"
      onClick={handleUnacknowledgement}
      disabled={updateAllocationLoading}
    >
      Undo
    </Button>
  );

  const getUnacknowledgedButtons = (): ReactElement[] => {
    const buttons: ReactElement[] = [];
    if (canAcknowledge && !isCoveredAccount) {
      buttons.push(getDeclineButton());
    }
    if (!isSellSideAccount || canAcknowledge) {
      buttons.push(getAcknowledgeButton());
    }
    return buttons;
  };

  const getRejectedButtons = () => {
    if (isOrderBookSidePanelInstitutionalFinalAllocationUnacknowledgeOn && canAcknowledge) {
      return [getUndoButton()];
    }
    if (canAcknowledge) {
      return [getAcknowledgeButton()];
    }
    return [];
  };

  const getAcknowledgedButtons = () => {
    if (isOrderBookSidePanelInstitutionalFinalAllocationUnacknowledgeOn && canAcknowledge) {
      return [getUndoButton()];
    }
    if (!isCoveredAccount && !isOrderBookSidePanelInstitutionalFinalAllocationUnacknowledgeOn) {
      return [getDeclineButton()];
    }
    return [];
  };

  const actionButtons = () => {
    if (!displayAllocationActions || !canUpdateAllocation) return [];

    switch (investorReplyStatus) {
      case AllocationAcknowledgement.Unacknowledged:
        return getUnacknowledgedButtons();
      case AllocationAcknowledgement.Rejected:
        return getRejectedButtons();
      case AllocationAcknowledgement.Acknowledged:
        return getAcknowledgedButtons();
      default:
        return [];
    }
  };

  const totalErrors = [
    updateStatusError?.message,
    updateMyStatusError?.message,
    acknowledgeCoveredAllocationError?.message,
    unacknowledgeAllocationError?.message,
    unacknowledgeCoveredAllocationError?.message,
  ].filter(Boolean);

  return (
    <React.Fragment>
      <ActionPanelSection
        data-testid="read-allocation-view"
        title={isSellSideAccount ? 'Final Allocation' : 'Expected Allocation'}
        titleItems={
          isReleased ? (
            <div data-testid="allocation-released">
              <DotStatus color="success" label="Released" />
            </div>
          ) : (
            <div data-testid="allocation-unreleased">
              <DotStatus color="disabled" label="Unreleased" />
            </div>
          )
        }
        actions={actionButtons()}
      >
        <Stack gap={1}>
          {totalErrors.length > 0 && (
            <Alert severity="error">
              <Stack gap={1}>
                {totalErrors.map((error, index) => (
                  <Typography key={index} variant="body2">
                    {error}
                  </Typography>
                ))}
              </Stack>
            </Alert>
          )}
          <AllocationDetails
            shareQuantity={allocation?.shareQuantity ?? indication?.allocationShares ?? null}
            offerPrice={offering.offerPrice!}
            offeringId={offering.id}
            pricingCurrencyCode={offering.pricingCurrencyCode}
            orderType={indication?.type ?? null}
            indicationStatus={indication?.status ?? null}
            isOrderBookOpen={isOrderBookOpen}
            allocationConfirmation={allocationConfirmation}
            isReleased={isReleased}
          />
        </Stack>
      </ActionPanelSection>
      {canRenderAllocationRanking && allocationRanking && (
        <FormikProvider value={formik}>
          <Form>
            <AllocationRanking onChangeAllocationRanking={onChangeAllocationRanking} />
          </Form>
        </FormikProvider>
      )}
    </React.Fragment>
  );
};

export default Allocation;
