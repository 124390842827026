import { SelectField, TextInputField } from '@cmg/common';
import { xcSelectors } from '@cmg/e2e-selectors';
import { useFormikContext } from 'formik';
import React, { useCallback } from 'react';

import FlexLayout from '../../../../../common/components/layout/flex-layout/FlexLayout';
import Column from '../../../../../common/components/layout/grid-layout/Column';
import GridLayout from '../../../../../common/components/layout/grid-layout/GridLayout';
import Panel from '../../../../../common/components/layout/panel/Panel';
import { CommonFields } from '../filing-common-fields-form/FilingCommonFieldsForm.model';
import IssuerSymbolInfo from '../issuer-symbol-info/IssuerSymbolInfo';
import { getAddNewSymbolLabel, getIssuerSymbolOptions } from './FilingDetailsForm.model';
import {
  SAddCustomSymbolLabel,
  SCustomLabel,
  SOptionLabel,
  SOptionsWrapper,
  StyledAddNewButton,
} from './FilingDetailsForm.styles';

export type Props = {
  stockSymbols: readonly string[];
};

const FilingDetailsForm: React.FC<Props> = ({ stockSymbols, children }) => {
  const formik = useFormikContext<CommonFields>();

  const [symbolSearchInput, setSymbolSearchInput] = React.useState<string | null>(null);
  const [hasMatchingOptions, setHasMatchingOptions] = React.useState(
    !!stockSymbols.length && !!symbolSearchInput
  );
  const [hasExactMatch, setHasExactMatch] = React.useState(false);
  const [customSymbol, setCustomSymbol] = React.useState<string | null>(formik.values.issuerSymbol);

  const issuerSymbolOptions = React.useMemo(
    () => getIssuerSymbolOptions(stockSymbols, customSymbol),
    [customSymbol, stockSymbols]
  );

  const handleAddNewIssuerSymbol = useCallback(() => {
    if (symbolSearchInput) {
      setCustomSymbol(symbolSearchInput);
      setSymbolSearchInput(null);
      setHasMatchingOptions(true);
      setHasExactMatch(false);
      formik.setFieldValue('issuerSymbol', symbolSearchInput);
    }
  }, [formik, symbolSearchInput]);

  const handleIssuerSymbolSearchInputChange = React.useCallback(
    (searchInput: string) => {
      const lowerCaseSearchInput = searchInput.toLowerCase();
      const hasInputMatchingOptions = issuerSymbolOptions.some(option => {
        return option.label.toLowerCase().includes(lowerCaseSearchInput);
      });
      const hasInputExactMatch = issuerSymbolOptions.some(option => {
        return option.label.toLowerCase() === lowerCaseSearchInput;
      });

      setSymbolSearchInput(searchInput);
      setHasMatchingOptions(hasInputMatchingOptions);
      setHasExactMatch(hasInputExactMatch);
    },
    [issuerSymbolOptions]
  );

  const isAddSymbolVisible =
    (!hasExactMatch && symbolSearchInput) ||
    (!issuerSymbolOptions.length && !formik.values.issuerSymbol);

  return (
    <Panel testId={xcSelectors.regulatoryFilingsFilingDetailsForm.testId}>
      <Panel.Header title="Filing Details" />
      <Panel.Content>
        <GridLayout>
          <Column span={3}>
            <TextInputField
              label="Issuer Name"
              name="issuerName"
              aria-label="Issuer Name"
              disabled
              withMargin
            />
            <SelectField
              onInputChange={handleIssuerSymbolSearchInputChange}
              label="Issuer Symbol"
              aria-label="Issuer Symbol"
              name="issuerSymbol"
              placeholder="Select or add symbol"
              options={issuerSymbolOptions}
              noOptionsMessage={() => ''}
              renderSingleSelectedOption={option => option.label}
              renderOption={option => {
                const { label, value } = option;
                const isCustom = !stockSymbols.includes(label);
                const isSelected = value === formik.values.issuerSymbol;

                return (
                  <FlexLayout direction="row" aria-label={`Option - ${label}`}>
                    <SOptionLabel>{label}</SOptionLabel>
                    {isCustom && <SCustomLabel isSelected={isSelected}>Custom</SCustomLabel>}
                  </FlexLayout>
                );
              }}
              renderMenuList={children => (
                <React.Fragment>
                  <SOptionsWrapper withBorderRadius={hasMatchingOptions}>
                    {children}
                  </SOptionsWrapper>
                  {isAddSymbolVisible && (
                    <React.Fragment>
                      <SAddCustomSymbolLabel withTopPadding={hasMatchingOptions}>
                        {getAddNewSymbolLabel(stockSymbols, symbolSearchInput)}
                      </SAddCustomSymbolLabel>
                      <StyledAddNewButton
                        disabled={!symbolSearchInput}
                        onClick={handleAddNewIssuerSymbol}
                        fullWidth
                      >
                        Add New
                      </StyledAddNewButton>
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
              renderInfo={() => <IssuerSymbolInfo />}
              withMargin
              required
            />
          </Column>
        </GridLayout>
        {children}
      </Panel.Content>
    </Panel>
  );
};

export default FilingDetailsForm;
