import { UUID } from '@cmg/common';
import { SelectOption } from '@cmg/design-system-formik';

import {
  InstitutionalIndicationOrderType,
  InterestLevelLimitType,
} from '../../../../../../../../graphql';
import { IndicationForm_IndicationDetails_OfferingPartsFragment } from '../../../indication-form/components/indication-details/graphql/__generated__';
import { emptyInterestLevelValue, InterestLevelValue } from '../utils/IndicationSectionValidation';

type OrderEntryProfile =
  IndicationForm_IndicationDetails_OfferingPartsFragment['orderEntryProfile'];

export const getTrancheOptions = (
  tranches: OrderEntryProfile['tranches']
): SelectOption<UUID>[] => {
  return tranches.map(({ name, id }) => ({ label: name, value: id }));
};

export const getDeliveryInstrumentLabel = (
  selectedInstrument: OrderEntryProfile['instruments'][number],
  securityTypeDisplayName: string
) => {
  const deliveryInstrumentType = [
    selectedInstrument?.isDepositaryReceipt && 'Depositary Receipt',
    securityTypeDisplayName,
  ]
    .filter(Boolean)
    .join(' ');
  const deliveryInstrumentCountry = `Country - ${selectedInstrument.countryCode}`;
  const deliveryInstrumentCurrency = `Currency - ${selectedInstrument.currencyCode}`;
  const deliveryInstrumentSymbol = `Symbol - ${selectedInstrument.stockSymbol}`;

  return `${deliveryInstrumentType}: ${deliveryInstrumentCountry}; ${deliveryInstrumentCurrency}${
    selectedInstrument.stockSymbol ? `; ${deliveryInstrumentSymbol}` : ''
  }`;
};

export const getInstrumentOptions = (
  instruments: OrderEntryProfile['instruments'],
  selectedTranche: OrderEntryProfile['tranches'][number] | undefined,
  securityTypeDisplayName: string
): SelectOption<UUID>[] => {
  if (!selectedTranche) {
    return [];
  }

  const selectedInstruments = instruments.filter(({ id }) =>
    selectedTranche.deliveryInstrumentIds?.includes(id)
  )!;

  return selectedInstruments.map(instrument => ({
    label: getDeliveryInstrumentLabel(instrument, securityTypeDisplayName),
    value: instrument.id,
  }));
};

export const getCurrencyOptions = (
  selectedTranche: OrderEntryProfile['tranches'][number] | undefined
): SelectOption<string>[] => {
  if (!selectedTranche) {
    return [];
  }

  return selectedTranche.demandCurrencies.map(currency => ({ label: currency, value: currency }));
};

export const createInterestLevelNewValue = (
  interestLevels: InterestLevelValue[]
): InterestLevelValue => {
  const selectedInterestUnit = interestLevels[0]?.interestUnit ?? null;
  const interestLevelsContainMarketOrderType = interestLevels.some(
    ({ orderType }) => orderType === InstitutionalIndicationOrderType.Market
  );
  const selectedLimitType =
    interestLevels.find(({ limitType }) => !!limitType)?.limitType ?? InterestLevelLimitType.Price;

  return {
    ...emptyInterestLevelValue,
    interestUnit: selectedInterestUnit,
    // default to Limit order type when there is market
    orderType: interestLevelsContainMarketOrderType ? InstitutionalIndicationOrderType.Limit : null,
    // default to selected limit type when we default to Limit order type
    limitType: interestLevelsContainMarketOrderType ? selectedLimitType : null,
  };
};
