/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IsUserSettlementAgent_OfferingProfilePartsFragment = {
  readonly __typename?: 'OfferingProfile';
  readonly id: string;
  readonly syndicate: {
    readonly __typename?: 'SyndicateProfile';
    readonly managerResponsibilities: {
      readonly __typename?: 'ManagerResponsibilitiesProfile';
      readonly settlementAgent?: string | null;
    };
  };
};

export type IsUserSettlementAgent_OfferingSettlementAgentQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type IsUserSettlementAgent_OfferingSettlementAgentQuery = {
  readonly __typename?: 'Query';
  readonly publishedOffering: {
    readonly __typename?: 'OfferingProfile';
    readonly id: string;
    readonly syndicate: {
      readonly __typename?: 'SyndicateProfile';
      readonly managerResponsibilities: {
        readonly __typename?: 'ManagerResponsibilitiesProfile';
        readonly settlementAgent?: string | null;
      };
    };
  };
};

export const IsUserSettlementAgent_OfferingProfilePartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'IsUserSettlementAgent_OfferingProfileParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'OfferingProfile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'syndicate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'managerResponsibilities' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'settlementAgent' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const IsUserSettlementAgent_OfferingSettlementAgentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'IsUserSettlementAgent_OfferingSettlementAgent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'publishedOffering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'IsUserSettlementAgent_OfferingProfileParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'IsUserSettlementAgent_OfferingProfileParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'OfferingProfile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'syndicate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'managerResponsibilities' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'settlementAgent' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useIsUserSettlementAgent_OfferingSettlementAgentQuery__
 *
 * To run a query within a React component, call `useIsUserSettlementAgent_OfferingSettlementAgentQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsUserSettlementAgent_OfferingSettlementAgentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsUserSettlementAgent_OfferingSettlementAgentQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useIsUserSettlementAgent_OfferingSettlementAgentQuery(
  baseOptions: Apollo.QueryHookOptions<
    IsUserSettlementAgent_OfferingSettlementAgentQuery,
    IsUserSettlementAgent_OfferingSettlementAgentQueryVariables
  > &
    (
      | { variables: IsUserSettlementAgent_OfferingSettlementAgentQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    IsUserSettlementAgent_OfferingSettlementAgentQuery,
    IsUserSettlementAgent_OfferingSettlementAgentQueryVariables
  >(IsUserSettlementAgent_OfferingSettlementAgentDocument, options);
}
export function useIsUserSettlementAgent_OfferingSettlementAgentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IsUserSettlementAgent_OfferingSettlementAgentQuery,
    IsUserSettlementAgent_OfferingSettlementAgentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    IsUserSettlementAgent_OfferingSettlementAgentQuery,
    IsUserSettlementAgent_OfferingSettlementAgentQueryVariables
  >(IsUserSettlementAgent_OfferingSettlementAgentDocument, options);
}
export function useIsUserSettlementAgent_OfferingSettlementAgentSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        IsUserSettlementAgent_OfferingSettlementAgentQuery,
        IsUserSettlementAgent_OfferingSettlementAgentQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    IsUserSettlementAgent_OfferingSettlementAgentQuery,
    IsUserSettlementAgent_OfferingSettlementAgentQueryVariables
  >(IsUserSettlementAgent_OfferingSettlementAgentDocument, options);
}
export type IsUserSettlementAgent_OfferingSettlementAgentQueryHookResult = ReturnType<
  typeof useIsUserSettlementAgent_OfferingSettlementAgentQuery
>;
export type IsUserSettlementAgent_OfferingSettlementAgentLazyQueryHookResult = ReturnType<
  typeof useIsUserSettlementAgent_OfferingSettlementAgentLazyQuery
>;
export type IsUserSettlementAgent_OfferingSettlementAgentSuspenseQueryHookResult = ReturnType<
  typeof useIsUserSettlementAgent_OfferingSettlementAgentSuspenseQuery
>;
export type IsUserSettlementAgent_OfferingSettlementAgentQueryResult = Apollo.QueryResult<
  IsUserSettlementAgent_OfferingSettlementAgentQuery,
  IsUserSettlementAgent_OfferingSettlementAgentQueryVariables
>;

import { makeMockResponse } from '../../../../../graphql/mock-response';

export const makeIsUserSettlementAgent_OfferingSettlementAgentMockResponse = makeMockResponse<
  IsUserSettlementAgent_OfferingSettlementAgentQueryVariables,
  IsUserSettlementAgent_OfferingSettlementAgentQuery
>(IsUserSettlementAgent_OfferingSettlementAgentDocument);
