import { numericUtil } from '@cmg/common';
import { Stack, Typography } from '@cmg/design-system';
import { FormikCurrencyField, FormikNumberField } from '@cmg/design-system-formik';

import { UnderwritingTermsFeesAndDiscounts } from '../../../../graphql';
import { useFormIsEditing } from '../hooks/useFormIsEditing';
import { RestInformationBlockGridArea } from './ResponsiveContentLayout';

type RestInformationBlockProps = Pick<
  UnderwritingTermsFeesAndDiscounts,
  'reallowance' | 'sharesExcludedFromGrossSpread'
> & { currencyCode: string };

export function RestInformationBlock({
  reallowance,
  sharesExcludedFromGrossSpread,
  currencyCode,
}: RestInformationBlockProps) {
  const { isEditing } = useFormIsEditing();
  if (isEditing) {
    return (
      <RestInformationBlockGridArea>
        <Stack gap={4} direction={{ xl: 'column', xs: 'row' }}>
          <FormikCurrencyField
            style={{ width: 264 }}
            name="reallowance"
            label="Reallowance"
            currencyCode={currencyCode}
          />
          <FormikNumberField
            style={{ width: 264 }}
            name="sharesExcludedFromGrossSpread"
            label="Shares excl. from Gross Spread"
          />
        </Stack>
      </RestInformationBlockGridArea>
    );
  }

  return (
    <RestInformationBlockGridArea>
      <Stack gap={2} direction={{ xl: 'column', xs: 'row' }}>
        <Stack
          flexDirection="row"
          data-testid="rest-information-block-reallowance"
          justifyContent={{ xs: 'start', sm: 'space-between' }}
          gap={{ xs: 1 }}
        >
          <Typography variant="body1" color="text.secondary">
            Reallowance
          </Typography>
          <Typography variant="body1" color="text.primary">
            {numericUtil.getDisplayValueForCurrency(reallowance, 0, currencyCode)}
          </Typography>
        </Stack>
        <Stack
          flexDirection="row"
          data-testid="rest-information-block-shares-excluded-from-gross-spread"
          justifyContent={{ xs: 'start', xl: 'space-between' }}
          gap={{ xs: 1 }}
        >
          <Typography variant="body1" color="text.secondary">
            Shares excl from Gross Spread
          </Typography>
          <Typography variant="body1" color="text.primary">
            {numericUtil.getDisplayValueForNumber(sharesExcludedFromGrossSpread, 0)}
          </Typography>
        </Stack>
      </Stack>
    </RestInformationBlockGridArea>
  );
}
