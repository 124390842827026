/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IndicationForm_IndicationBillingAndDeliveryAgentQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  indicationId: Types.Scalars['UUID'];
}>;

export type IndicationForm_IndicationBillingAndDeliveryAgentQuery = {
  readonly __typename?: 'Query';
  readonly institutionalIndicationBillingAndDeliveryAgent: {
    readonly __typename?: 'BillingAndDeliveryAgent';
    readonly managerCmgEntityKey: string;
  };
};

export const IndicationForm_IndicationBillingAndDeliveryAgentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'IndicationForm_IndicationBillingAndDeliveryAgent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'indicationId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institutionalIndicationBillingAndDeliveryAgent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'indicationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'indicationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'managerCmgEntityKey' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useIndicationForm_IndicationBillingAndDeliveryAgentQuery__
 *
 * To run a query within a React component, call `useIndicationForm_IndicationBillingAndDeliveryAgentQuery` and pass it any options that fit your needs.
 * When your component renders, `useIndicationForm_IndicationBillingAndDeliveryAgentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIndicationForm_IndicationBillingAndDeliveryAgentQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      indicationId: // value for 'indicationId'
 *   },
 * });
 */
export function useIndicationForm_IndicationBillingAndDeliveryAgentQuery(
  baseOptions: Apollo.QueryHookOptions<
    IndicationForm_IndicationBillingAndDeliveryAgentQuery,
    IndicationForm_IndicationBillingAndDeliveryAgentQueryVariables
  > &
    (
      | {
          variables: IndicationForm_IndicationBillingAndDeliveryAgentQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    IndicationForm_IndicationBillingAndDeliveryAgentQuery,
    IndicationForm_IndicationBillingAndDeliveryAgentQueryVariables
  >(IndicationForm_IndicationBillingAndDeliveryAgentDocument, options);
}
export function useIndicationForm_IndicationBillingAndDeliveryAgentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IndicationForm_IndicationBillingAndDeliveryAgentQuery,
    IndicationForm_IndicationBillingAndDeliveryAgentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    IndicationForm_IndicationBillingAndDeliveryAgentQuery,
    IndicationForm_IndicationBillingAndDeliveryAgentQueryVariables
  >(IndicationForm_IndicationBillingAndDeliveryAgentDocument, options);
}
export function useIndicationForm_IndicationBillingAndDeliveryAgentSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        IndicationForm_IndicationBillingAndDeliveryAgentQuery,
        IndicationForm_IndicationBillingAndDeliveryAgentQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    IndicationForm_IndicationBillingAndDeliveryAgentQuery,
    IndicationForm_IndicationBillingAndDeliveryAgentQueryVariables
  >(IndicationForm_IndicationBillingAndDeliveryAgentDocument, options);
}
export type IndicationForm_IndicationBillingAndDeliveryAgentQueryHookResult = ReturnType<
  typeof useIndicationForm_IndicationBillingAndDeliveryAgentQuery
>;
export type IndicationForm_IndicationBillingAndDeliveryAgentLazyQueryHookResult = ReturnType<
  typeof useIndicationForm_IndicationBillingAndDeliveryAgentLazyQuery
>;
export type IndicationForm_IndicationBillingAndDeliveryAgentSuspenseQueryHookResult = ReturnType<
  typeof useIndicationForm_IndicationBillingAndDeliveryAgentSuspenseQuery
>;
export type IndicationForm_IndicationBillingAndDeliveryAgentQueryResult = Apollo.QueryResult<
  IndicationForm_IndicationBillingAndDeliveryAgentQuery,
  IndicationForm_IndicationBillingAndDeliveryAgentQueryVariables
>;

import { makeMockResponse } from '../../../../../../../../../../../../graphql/mock-response';

export const makeIndicationForm_IndicationBillingAndDeliveryAgentMockResponse = makeMockResponse<
  IndicationForm_IndicationBillingAndDeliveryAgentQueryVariables,
  IndicationForm_IndicationBillingAndDeliveryAgentQuery
>(IndicationForm_IndicationBillingAndDeliveryAgentDocument);
