/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ComplianceProspectus_LatestEmailStatusPartsFragment = {
  readonly __typename?: 'LatestEmailStatus';
  readonly sendRequestedAt: string;
  readonly status: Types.EmailDeliveryStatus;
  readonly statusInfo?: string | null;
  readonly recipientName?: string | null;
  readonly recipientEmail?: string | null;
  readonly isOwnedByCurrentTenant: boolean;
};

export type ComplianceProspectus_ProspectusDocumentMetadataPartsFragment = {
  readonly __typename?: 'ProspectusDocumentMetadata';
  readonly publishedAt: string;
  readonly fileName: string;
  readonly url: string;
};

export type ComplianceProspectus_ProspectusDeliveryPartsFragment = {
  readonly __typename?: 'ProspectusDelivery';
  readonly id: string;
  readonly version: number;
  readonly revisions: ReadonlyArray<{
    readonly __typename?: 'LatestEmailStatus';
    readonly sendRequestedAt: string;
    readonly status: Types.EmailDeliveryStatus;
    readonly statusInfo?: string | null;
    readonly recipientName?: string | null;
    readonly recipientEmail?: string | null;
    readonly isOwnedByCurrentTenant: boolean;
  }>;
  readonly documents: ReadonlyArray<{
    readonly __typename?: 'ProspectusDocumentMetadata';
    readonly publishedAt: string;
    readonly fileName: string;
    readonly url: string;
  }>;
};

export type ComplianceProspectus_ProspectusDeliveryQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  cmgEntityKey: Types.Scalars['String'];
  isSyndicatePersona: Types.Scalars['Boolean'];
}>;

export type ComplianceProspectus_ProspectusDeliveryQuery = {
  readonly __typename?: 'Query';
  readonly prospectusDelivery: {
    readonly __typename?: 'ProspectusDeliveryRoot';
    readonly id: string;
    readonly syndicateHistory?: ReadonlyArray<{
      readonly __typename?: 'ProspectusDelivery';
      readonly id: string;
      readonly version: number;
      readonly revisions: ReadonlyArray<{
        readonly __typename?: 'LatestEmailStatus';
        readonly sendRequestedAt: string;
        readonly status: Types.EmailDeliveryStatus;
        readonly statusInfo?: string | null;
        readonly recipientName?: string | null;
        readonly recipientEmail?: string | null;
        readonly isOwnedByCurrentTenant: boolean;
      }>;
      readonly documents: ReadonlyArray<{
        readonly __typename?: 'ProspectusDocumentMetadata';
        readonly publishedAt: string;
        readonly fileName: string;
        readonly url: string;
      }>;
    }>;
    readonly salesAndTradingHistory?: ReadonlyArray<{
      readonly __typename?: 'ProspectusDelivery';
      readonly id: string;
      readonly version: number;
      readonly revisions: ReadonlyArray<{
        readonly __typename?: 'LatestEmailStatus';
        readonly sendRequestedAt: string;
        readonly status: Types.EmailDeliveryStatus;
        readonly statusInfo?: string | null;
        readonly recipientName?: string | null;
        readonly recipientEmail?: string | null;
        readonly isOwnedByCurrentTenant: boolean;
      }>;
      readonly documents: ReadonlyArray<{
        readonly __typename?: 'ProspectusDocumentMetadata';
        readonly publishedAt: string;
        readonly fileName: string;
        readonly url: string;
      }>;
    }>;
  };
};

export type ComplianceProspectus_ProspectusDeliveryOnSendValidationQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  investorKeys: ReadonlyArray<Types.Scalars['String']> | Types.Scalars['String'];
  currentTenantOnly: Types.Scalars['Boolean'];
  isSyndicatePersona: Types.Scalars['Boolean'];
}>;

export type ComplianceProspectus_ProspectusDeliveryOnSendValidationQuery = {
  readonly __typename?: 'Query';
  readonly prospectusDelivery: {
    readonly __typename?: 'ProspectusDeliveryRoot';
    readonly id: string;
    readonly onSendValidationAsSyndicate?: {
      readonly __typename?: 'ProspectusOnSendValidationResult';
      readonly id: string;
      readonly prospectusErrors: ReadonlyArray<{
        readonly __typename?: 'ProspectusValidationError';
        readonly id: string;
        readonly errorType: Types.ProspectusErrorType;
      }>;
      readonly investorErrors: ReadonlyArray<{
        readonly __typename?: 'ProspectusInvestorValidationError';
        readonly id: string;
        readonly errorType: Types.ProspectusInvestorErrorType;
        readonly investorKey: string;
      }>;
    };
    readonly onSendValidationAsSalesAndTrading?: {
      readonly __typename?: 'ProspectusOnSendValidationResult';
      readonly id: string;
      readonly prospectusErrors: ReadonlyArray<{
        readonly __typename?: 'ProspectusValidationError';
        readonly id: string;
        readonly errorType: Types.ProspectusErrorType;
      }>;
      readonly investorErrors: ReadonlyArray<{
        readonly __typename?: 'ProspectusInvestorValidationError';
        readonly id: string;
        readonly errorType: Types.ProspectusInvestorErrorType;
        readonly investorKey: string;
      }>;
    };
  };
};

export type ComplianceProspectus_ProspectusDeliverySingleOnSendValidationQueryVariables =
  Types.Exact<{
    offeringId: Types.Scalars['UUID'];
    investorKey: Types.Scalars['String'];
    currentTenantOnly: Types.Scalars['Boolean'];
    isSyndicatePersona: Types.Scalars['Boolean'];
  }>;

export type ComplianceProspectus_ProspectusDeliverySingleOnSendValidationQuery = {
  readonly __typename?: 'Query';
  readonly prospectusDelivery: {
    readonly __typename?: 'ProspectusDeliveryRoot';
    readonly id: string;
    readonly singleOnSendValidationAsSyndicate?: {
      readonly __typename?: 'ProspectusOnSendValidationResult';
      readonly id: string;
      readonly prospectusErrors: ReadonlyArray<{
        readonly __typename?: 'ProspectusValidationError';
        readonly id: string;
        readonly errorType: Types.ProspectusErrorType;
      }>;
      readonly investorErrors: ReadonlyArray<{
        readonly __typename?: 'ProspectusInvestorValidationError';
        readonly id: string;
        readonly errorType: Types.ProspectusInvestorErrorType;
        readonly investorKey: string;
      }>;
    };
    readonly singleOnSendValidationAsSalesAndTrading?: {
      readonly __typename?: 'ProspectusOnSendValidationResult';
      readonly id: string;
      readonly prospectusErrors: ReadonlyArray<{
        readonly __typename?: 'ProspectusValidationError';
        readonly id: string;
        readonly errorType: Types.ProspectusErrorType;
      }>;
      readonly investorErrors: ReadonlyArray<{
        readonly __typename?: 'ProspectusInvestorValidationError';
        readonly id: string;
        readonly errorType: Types.ProspectusInvestorErrorType;
        readonly investorKey: string;
      }>;
    };
  };
};

export type ComplianceProspectus_ProspectusOnSendValidationResultPartsFragment = {
  readonly __typename?: 'ProspectusOnSendValidationResult';
  readonly id: string;
  readonly prospectusErrors: ReadonlyArray<{
    readonly __typename?: 'ProspectusValidationError';
    readonly id: string;
    readonly errorType: Types.ProspectusErrorType;
  }>;
  readonly investorErrors: ReadonlyArray<{
    readonly __typename?: 'ProspectusInvestorValidationError';
    readonly id: string;
    readonly errorType: Types.ProspectusInvestorErrorType;
    readonly investorKey: string;
  }>;
};

export type ComplianceProspectus_ServiceErrorPartsFragment = {
  readonly __typename: 'ServiceError';
  readonly code: Types.ServiceErrorCode;
  readonly message: string;
};

export type ComplianceProspectus_ResendProspectusMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  investorKeys: ReadonlyArray<Types.Scalars['String']> | Types.Scalars['String'];
  isSyndicatePersona: Types.Scalars['Boolean'];
}>;

export type ComplianceProspectus_ResendProspectusMutation = {
  readonly __typename?: 'Mutation';
  readonly resendProspectusAsSyndicate?:
    | { readonly __typename: 'EmptyMutationResponse' }
    | {
        readonly __typename: 'ServiceError';
        readonly code: Types.ServiceErrorCode;
        readonly message: string;
      };
  readonly resendProspectusAsSalesAndTrading?:
    | { readonly __typename: 'EmptyMutationResponse' }
    | {
        readonly __typename: 'ServiceError';
        readonly code: Types.ServiceErrorCode;
        readonly message: string;
      };
};

export const ComplianceProspectus_LatestEmailStatusPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ComplianceProspectus_LatestEmailStatusParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'LatestEmailStatus' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'sendRequestedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'statusInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recipientName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recipientEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isOwnedByCurrentTenant' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const ComplianceProspectus_ProspectusDocumentMetadataPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ComplianceProspectus_ProspectusDocumentMetadataParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProspectusDocumentMetadata' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const ComplianceProspectus_ProspectusDeliveryPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ComplianceProspectus_ProspectusDeliveryParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProspectusDelivery' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'revisions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ComplianceProspectus_LatestEmailStatusParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'documents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ComplianceProspectus_ProspectusDocumentMetadataParts',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ComplianceProspectus_LatestEmailStatusParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'LatestEmailStatus' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'sendRequestedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'statusInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recipientName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recipientEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isOwnedByCurrentTenant' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ComplianceProspectus_ProspectusDocumentMetadataParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProspectusDocumentMetadata' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const ComplianceProspectus_ProspectusOnSendValidationResultPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ComplianceProspectus_ProspectusOnSendValidationResultParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProspectusOnSendValidationResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'prospectusErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'errorType' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investorErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'errorType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'investorKey' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const ComplianceProspectus_ServiceErrorPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ComplianceProspectus_ServiceErrorParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ServiceError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const ComplianceProspectus_ProspectusDeliveryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ComplianceProspectus_ProspectusDelivery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKey' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'isSyndicatePersona' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'prospectusDelivery' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'syndicateHistory' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'cmgEntityKey' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKey' } },
                    },
                  ],
                  directives: [
                    {
                      kind: 'Directive',
                      name: { kind: 'Name', value: 'include' },
                      arguments: [
                        {
                          kind: 'Argument',
                          name: { kind: 'Name', value: 'if' },
                          value: {
                            kind: 'Variable',
                            name: { kind: 'Name', value: 'isSyndicatePersona' },
                          },
                        },
                      ],
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'ComplianceProspectus_ProspectusDeliveryParts',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'salesAndTradingHistory' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'cmgEntityKey' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKey' } },
                    },
                  ],
                  directives: [
                    {
                      kind: 'Directive',
                      name: { kind: 'Name', value: 'skip' },
                      arguments: [
                        {
                          kind: 'Argument',
                          name: { kind: 'Name', value: 'if' },
                          value: {
                            kind: 'Variable',
                            name: { kind: 'Name', value: 'isSyndicatePersona' },
                          },
                        },
                      ],
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'ComplianceProspectus_ProspectusDeliveryParts',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ComplianceProspectus_LatestEmailStatusParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'LatestEmailStatus' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'sendRequestedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'statusInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recipientName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recipientEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isOwnedByCurrentTenant' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ComplianceProspectus_ProspectusDocumentMetadataParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProspectusDocumentMetadata' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ComplianceProspectus_ProspectusDeliveryParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProspectusDelivery' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'revisions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ComplianceProspectus_LatestEmailStatusParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'documents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ComplianceProspectus_ProspectusDocumentMetadataParts',
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useComplianceProspectus_ProspectusDeliveryQuery__
 *
 * To run a query within a React component, call `useComplianceProspectus_ProspectusDeliveryQuery` and pass it any options that fit your needs.
 * When your component renders, `useComplianceProspectus_ProspectusDeliveryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useComplianceProspectus_ProspectusDeliveryQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      cmgEntityKey: // value for 'cmgEntityKey'
 *      isSyndicatePersona: // value for 'isSyndicatePersona'
 *   },
 * });
 */
export function useComplianceProspectus_ProspectusDeliveryQuery(
  baseOptions: Apollo.QueryHookOptions<
    ComplianceProspectus_ProspectusDeliveryQuery,
    ComplianceProspectus_ProspectusDeliveryQueryVariables
  > &
    (
      | { variables: ComplianceProspectus_ProspectusDeliveryQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ComplianceProspectus_ProspectusDeliveryQuery,
    ComplianceProspectus_ProspectusDeliveryQueryVariables
  >(ComplianceProspectus_ProspectusDeliveryDocument, options);
}
export function useComplianceProspectus_ProspectusDeliveryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ComplianceProspectus_ProspectusDeliveryQuery,
    ComplianceProspectus_ProspectusDeliveryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ComplianceProspectus_ProspectusDeliveryQuery,
    ComplianceProspectus_ProspectusDeliveryQueryVariables
  >(ComplianceProspectus_ProspectusDeliveryDocument, options);
}
export function useComplianceProspectus_ProspectusDeliverySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ComplianceProspectus_ProspectusDeliveryQuery,
        ComplianceProspectus_ProspectusDeliveryQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ComplianceProspectus_ProspectusDeliveryQuery,
    ComplianceProspectus_ProspectusDeliveryQueryVariables
  >(ComplianceProspectus_ProspectusDeliveryDocument, options);
}
export type ComplianceProspectus_ProspectusDeliveryQueryHookResult = ReturnType<
  typeof useComplianceProspectus_ProspectusDeliveryQuery
>;
export type ComplianceProspectus_ProspectusDeliveryLazyQueryHookResult = ReturnType<
  typeof useComplianceProspectus_ProspectusDeliveryLazyQuery
>;
export type ComplianceProspectus_ProspectusDeliverySuspenseQueryHookResult = ReturnType<
  typeof useComplianceProspectus_ProspectusDeliverySuspenseQuery
>;
export type ComplianceProspectus_ProspectusDeliveryQueryResult = Apollo.QueryResult<
  ComplianceProspectus_ProspectusDeliveryQuery,
  ComplianceProspectus_ProspectusDeliveryQueryVariables
>;
export const ComplianceProspectus_ProspectusDeliveryOnSendValidationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ComplianceProspectus_ProspectusDeliveryOnSendValidation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'investorKeys' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'currentTenantOnly' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'isSyndicatePersona' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'prospectusDelivery' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'onSendValidationAsSyndicate' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'investorKeys' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'investorKeys' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'currentTenantOnly' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'currentTenantOnly' },
                      },
                    },
                  ],
                  directives: [
                    {
                      kind: 'Directive',
                      name: { kind: 'Name', value: 'include' },
                      arguments: [
                        {
                          kind: 'Argument',
                          name: { kind: 'Name', value: 'if' },
                          value: {
                            kind: 'Variable',
                            name: { kind: 'Name', value: 'isSyndicatePersona' },
                          },
                        },
                      ],
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'ComplianceProspectus_ProspectusOnSendValidationResultParts',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'onSendValidationAsSalesAndTrading' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'investorKeys' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'investorKeys' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'currentTenantOnly' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'currentTenantOnly' },
                      },
                    },
                  ],
                  directives: [
                    {
                      kind: 'Directive',
                      name: { kind: 'Name', value: 'skip' },
                      arguments: [
                        {
                          kind: 'Argument',
                          name: { kind: 'Name', value: 'if' },
                          value: {
                            kind: 'Variable',
                            name: { kind: 'Name', value: 'isSyndicatePersona' },
                          },
                        },
                      ],
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'ComplianceProspectus_ProspectusOnSendValidationResultParts',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ComplianceProspectus_ProspectusOnSendValidationResultParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProspectusOnSendValidationResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'prospectusErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'errorType' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investorErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'errorType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'investorKey' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useComplianceProspectus_ProspectusDeliveryOnSendValidationQuery__
 *
 * To run a query within a React component, call `useComplianceProspectus_ProspectusDeliveryOnSendValidationQuery` and pass it any options that fit your needs.
 * When your component renders, `useComplianceProspectus_ProspectusDeliveryOnSendValidationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useComplianceProspectus_ProspectusDeliveryOnSendValidationQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      investorKeys: // value for 'investorKeys'
 *      currentTenantOnly: // value for 'currentTenantOnly'
 *      isSyndicatePersona: // value for 'isSyndicatePersona'
 *   },
 * });
 */
export function useComplianceProspectus_ProspectusDeliveryOnSendValidationQuery(
  baseOptions: Apollo.QueryHookOptions<
    ComplianceProspectus_ProspectusDeliveryOnSendValidationQuery,
    ComplianceProspectus_ProspectusDeliveryOnSendValidationQueryVariables
  > &
    (
      | {
          variables: ComplianceProspectus_ProspectusDeliveryOnSendValidationQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ComplianceProspectus_ProspectusDeliveryOnSendValidationQuery,
    ComplianceProspectus_ProspectusDeliveryOnSendValidationQueryVariables
  >(ComplianceProspectus_ProspectusDeliveryOnSendValidationDocument, options);
}
export function useComplianceProspectus_ProspectusDeliveryOnSendValidationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ComplianceProspectus_ProspectusDeliveryOnSendValidationQuery,
    ComplianceProspectus_ProspectusDeliveryOnSendValidationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ComplianceProspectus_ProspectusDeliveryOnSendValidationQuery,
    ComplianceProspectus_ProspectusDeliveryOnSendValidationQueryVariables
  >(ComplianceProspectus_ProspectusDeliveryOnSendValidationDocument, options);
}
export function useComplianceProspectus_ProspectusDeliveryOnSendValidationSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ComplianceProspectus_ProspectusDeliveryOnSendValidationQuery,
        ComplianceProspectus_ProspectusDeliveryOnSendValidationQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ComplianceProspectus_ProspectusDeliveryOnSendValidationQuery,
    ComplianceProspectus_ProspectusDeliveryOnSendValidationQueryVariables
  >(ComplianceProspectus_ProspectusDeliveryOnSendValidationDocument, options);
}
export type ComplianceProspectus_ProspectusDeliveryOnSendValidationQueryHookResult = ReturnType<
  typeof useComplianceProspectus_ProspectusDeliveryOnSendValidationQuery
>;
export type ComplianceProspectus_ProspectusDeliveryOnSendValidationLazyQueryHookResult = ReturnType<
  typeof useComplianceProspectus_ProspectusDeliveryOnSendValidationLazyQuery
>;
export type ComplianceProspectus_ProspectusDeliveryOnSendValidationSuspenseQueryHookResult =
  ReturnType<typeof useComplianceProspectus_ProspectusDeliveryOnSendValidationSuspenseQuery>;
export type ComplianceProspectus_ProspectusDeliveryOnSendValidationQueryResult = Apollo.QueryResult<
  ComplianceProspectus_ProspectusDeliveryOnSendValidationQuery,
  ComplianceProspectus_ProspectusDeliveryOnSendValidationQueryVariables
>;
export const ComplianceProspectus_ProspectusDeliverySingleOnSendValidationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {
        kind: 'Name',
        value: 'ComplianceProspectus_ProspectusDeliverySingleOnSendValidation',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'investorKey' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'currentTenantOnly' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'isSyndicatePersona' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'prospectusDelivery' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'singleOnSendValidationAsSyndicate' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'investorKey' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'investorKey' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'currentTenantOnly' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'currentTenantOnly' },
                      },
                    },
                  ],
                  directives: [
                    {
                      kind: 'Directive',
                      name: { kind: 'Name', value: 'include' },
                      arguments: [
                        {
                          kind: 'Argument',
                          name: { kind: 'Name', value: 'if' },
                          value: {
                            kind: 'Variable',
                            name: { kind: 'Name', value: 'isSyndicatePersona' },
                          },
                        },
                      ],
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'ComplianceProspectus_ProspectusOnSendValidationResultParts',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'singleOnSendValidationAsSalesAndTrading' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'investorKey' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'investorKey' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'currentTenantOnly' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'currentTenantOnly' },
                      },
                    },
                  ],
                  directives: [
                    {
                      kind: 'Directive',
                      name: { kind: 'Name', value: 'skip' },
                      arguments: [
                        {
                          kind: 'Argument',
                          name: { kind: 'Name', value: 'if' },
                          value: {
                            kind: 'Variable',
                            name: { kind: 'Name', value: 'isSyndicatePersona' },
                          },
                        },
                      ],
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'ComplianceProspectus_ProspectusOnSendValidationResultParts',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ComplianceProspectus_ProspectusOnSendValidationResultParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProspectusOnSendValidationResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'prospectusErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'errorType' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investorErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'errorType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'investorKey' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useComplianceProspectus_ProspectusDeliverySingleOnSendValidationQuery__
 *
 * To run a query within a React component, call `useComplianceProspectus_ProspectusDeliverySingleOnSendValidationQuery` and pass it any options that fit your needs.
 * When your component renders, `useComplianceProspectus_ProspectusDeliverySingleOnSendValidationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useComplianceProspectus_ProspectusDeliverySingleOnSendValidationQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      investorKey: // value for 'investorKey'
 *      currentTenantOnly: // value for 'currentTenantOnly'
 *      isSyndicatePersona: // value for 'isSyndicatePersona'
 *   },
 * });
 */
export function useComplianceProspectus_ProspectusDeliverySingleOnSendValidationQuery(
  baseOptions: Apollo.QueryHookOptions<
    ComplianceProspectus_ProspectusDeliverySingleOnSendValidationQuery,
    ComplianceProspectus_ProspectusDeliverySingleOnSendValidationQueryVariables
  > &
    (
      | {
          variables: ComplianceProspectus_ProspectusDeliverySingleOnSendValidationQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ComplianceProspectus_ProspectusDeliverySingleOnSendValidationQuery,
    ComplianceProspectus_ProspectusDeliverySingleOnSendValidationQueryVariables
  >(ComplianceProspectus_ProspectusDeliverySingleOnSendValidationDocument, options);
}
export function useComplianceProspectus_ProspectusDeliverySingleOnSendValidationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ComplianceProspectus_ProspectusDeliverySingleOnSendValidationQuery,
    ComplianceProspectus_ProspectusDeliverySingleOnSendValidationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ComplianceProspectus_ProspectusDeliverySingleOnSendValidationQuery,
    ComplianceProspectus_ProspectusDeliverySingleOnSendValidationQueryVariables
  >(ComplianceProspectus_ProspectusDeliverySingleOnSendValidationDocument, options);
}
export function useComplianceProspectus_ProspectusDeliverySingleOnSendValidationSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ComplianceProspectus_ProspectusDeliverySingleOnSendValidationQuery,
        ComplianceProspectus_ProspectusDeliverySingleOnSendValidationQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ComplianceProspectus_ProspectusDeliverySingleOnSendValidationQuery,
    ComplianceProspectus_ProspectusDeliverySingleOnSendValidationQueryVariables
  >(ComplianceProspectus_ProspectusDeliverySingleOnSendValidationDocument, options);
}
export type ComplianceProspectus_ProspectusDeliverySingleOnSendValidationQueryHookResult =
  ReturnType<typeof useComplianceProspectus_ProspectusDeliverySingleOnSendValidationQuery>;
export type ComplianceProspectus_ProspectusDeliverySingleOnSendValidationLazyQueryHookResult =
  ReturnType<typeof useComplianceProspectus_ProspectusDeliverySingleOnSendValidationLazyQuery>;
export type ComplianceProspectus_ProspectusDeliverySingleOnSendValidationSuspenseQueryHookResult =
  ReturnType<typeof useComplianceProspectus_ProspectusDeliverySingleOnSendValidationSuspenseQuery>;
export type ComplianceProspectus_ProspectusDeliverySingleOnSendValidationQueryResult =
  Apollo.QueryResult<
    ComplianceProspectus_ProspectusDeliverySingleOnSendValidationQuery,
    ComplianceProspectus_ProspectusDeliverySingleOnSendValidationQueryVariables
  >;
export const ComplianceProspectus_ResendProspectusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ComplianceProspectus_ResendProspectus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'investorKeys' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'isSyndicatePersona' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resendProspectusAsSyndicate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'investorKeys' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'investorKeys' } },
              },
            ],
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'isSyndicatePersona' },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'EmptyMutationResponse' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: '__typename' } }],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ServiceError' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ComplianceProspectus_ServiceErrorParts' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resendProspectusAsSalesAndTrading' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'investorKeys' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'investorKeys' } },
              },
            ],
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'skip' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'isSyndicatePersona' },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'EmptyMutationResponse' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: '__typename' } }],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ServiceError' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ComplianceProspectus_ServiceErrorParts' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ComplianceProspectus_ServiceErrorParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ServiceError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type ComplianceProspectus_ResendProspectusMutationFn = Apollo.MutationFunction<
  ComplianceProspectus_ResendProspectusMutation,
  ComplianceProspectus_ResendProspectusMutationVariables
>;

/**
 * __useComplianceProspectus_ResendProspectusMutation__
 *
 * To run a mutation, you first call `useComplianceProspectus_ResendProspectusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useComplianceProspectus_ResendProspectusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [complianceProspectusResendProspectusMutation, { data, loading, error }] = useComplianceProspectus_ResendProspectusMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      investorKeys: // value for 'investorKeys'
 *      isSyndicatePersona: // value for 'isSyndicatePersona'
 *   },
 * });
 */
export function useComplianceProspectus_ResendProspectusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ComplianceProspectus_ResendProspectusMutation,
    ComplianceProspectus_ResendProspectusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ComplianceProspectus_ResendProspectusMutation,
    ComplianceProspectus_ResendProspectusMutationVariables
  >(ComplianceProspectus_ResendProspectusDocument, options);
}
export type ComplianceProspectus_ResendProspectusMutationHookResult = ReturnType<
  typeof useComplianceProspectus_ResendProspectusMutation
>;
export type ComplianceProspectus_ResendProspectusMutationResult =
  Apollo.MutationResult<ComplianceProspectus_ResendProspectusMutation>;
export type ComplianceProspectus_ResendProspectusMutationOptions = Apollo.BaseMutationOptions<
  ComplianceProspectus_ResendProspectusMutation,
  ComplianceProspectus_ResendProspectusMutationVariables
>;

import { makeMockResponse } from '../../../../../../../graphql/mock-response';

export const makeComplianceProspectus_ProspectusDeliveryMockResponse = makeMockResponse<
  ComplianceProspectus_ProspectusDeliveryQueryVariables,
  ComplianceProspectus_ProspectusDeliveryQuery
>(ComplianceProspectus_ProspectusDeliveryDocument);

export const makeComplianceProspectus_ProspectusDeliveryOnSendValidationMockResponse =
  makeMockResponse<
    ComplianceProspectus_ProspectusDeliveryOnSendValidationQueryVariables,
    ComplianceProspectus_ProspectusDeliveryOnSendValidationQuery
  >(ComplianceProspectus_ProspectusDeliveryOnSendValidationDocument);

export const makeComplianceProspectus_ProspectusDeliverySingleOnSendValidationMockResponse =
  makeMockResponse<
    ComplianceProspectus_ProspectusDeliverySingleOnSendValidationQueryVariables,
    ComplianceProspectus_ProspectusDeliverySingleOnSendValidationQuery
  >(ComplianceProspectus_ProspectusDeliverySingleOnSendValidationDocument);
