/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ManageIndication_DuplicateIndicationsQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type ManageIndication_DuplicateIndicationsQuery = {
  readonly __typename?: 'Query';
  readonly basicPublishedOffering: {
    readonly __typename?: 'BasicPublishedOfferingProfile';
    readonly orderBook: {
      readonly __typename?: 'OrderBook';
      readonly id: string;
      readonly institutionalIndications: ReadonlyArray<{
        readonly __typename?: 'InstitutionalIndication';
        readonly id: string;
        readonly investorInformation: {
          readonly __typename?: 'InvestorInformation';
          readonly cmgEntityKey: string;
          readonly cmgEntityName: string;
          readonly bankInvestorKey?: string | null;
          readonly bankInvestorName?: string | null;
        };
        readonly auditInfo: {
          readonly __typename?: 'AuditInfo';
          readonly createdByFirmName?: string | null;
        };
      }>;
    };
  };
};

export const ManageIndication_DuplicateIndicationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ManageIndication_DuplicateIndications' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'basicPublishedOffering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'orderBook' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'institutionalIndications' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'investorInformation' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityName' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'bankInvestorKey' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'bankInvestorName' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'auditInfo' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'createdByFirmName' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useManageIndication_DuplicateIndicationsQuery__
 *
 * To run a query within a React component, call `useManageIndication_DuplicateIndicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useManageIndication_DuplicateIndicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManageIndication_DuplicateIndicationsQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useManageIndication_DuplicateIndicationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ManageIndication_DuplicateIndicationsQuery,
    ManageIndication_DuplicateIndicationsQueryVariables
  > &
    (
      | { variables: ManageIndication_DuplicateIndicationsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ManageIndication_DuplicateIndicationsQuery,
    ManageIndication_DuplicateIndicationsQueryVariables
  >(ManageIndication_DuplicateIndicationsDocument, options);
}
export function useManageIndication_DuplicateIndicationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ManageIndication_DuplicateIndicationsQuery,
    ManageIndication_DuplicateIndicationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ManageIndication_DuplicateIndicationsQuery,
    ManageIndication_DuplicateIndicationsQueryVariables
  >(ManageIndication_DuplicateIndicationsDocument, options);
}
export function useManageIndication_DuplicateIndicationsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ManageIndication_DuplicateIndicationsQuery,
        ManageIndication_DuplicateIndicationsQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ManageIndication_DuplicateIndicationsQuery,
    ManageIndication_DuplicateIndicationsQueryVariables
  >(ManageIndication_DuplicateIndicationsDocument, options);
}
export type ManageIndication_DuplicateIndicationsQueryHookResult = ReturnType<
  typeof useManageIndication_DuplicateIndicationsQuery
>;
export type ManageIndication_DuplicateIndicationsLazyQueryHookResult = ReturnType<
  typeof useManageIndication_DuplicateIndicationsLazyQuery
>;
export type ManageIndication_DuplicateIndicationsSuspenseQueryHookResult = ReturnType<
  typeof useManageIndication_DuplicateIndicationsSuspenseQuery
>;
export type ManageIndication_DuplicateIndicationsQueryResult = Apollo.QueryResult<
  ManageIndication_DuplicateIndicationsQuery,
  ManageIndication_DuplicateIndicationsQueryVariables
>;

import { makeMockResponse } from '../../../../../../../../../../graphql/mock-response';

export const makeManageIndication_DuplicateIndicationsMockResponse = makeMockResponse<
  ManageIndication_DuplicateIndicationsQueryVariables,
  ManageIndication_DuplicateIndicationsQuery
>(ManageIndication_DuplicateIndicationsDocument);
