import type { UUID } from '@cmg/common';
import { SnackbarManager } from '@cmg/design-system';
import { useCallback } from 'react';

import {
  useOrderBook_InstitutionalDemand_UpdateDraftAllocationMutation,
  useOrderBook_InstitutionalDemand_UpdateFinalAllocationMutation,
} from '../graphql/__generated__';
import { refetchDemandGridReactiveVar } from './useRefetchDemandGridReactiveVar';

export type Props = Readonly<{ offeringId: UUID; finalAllocationSetId: UUID | undefined }>;

export const useEnterAllocation = ({ offeringId, finalAllocationSetId }: Props) => {
  const [updateDraftAllocation] = useOrderBook_InstitutionalDemand_UpdateDraftAllocationMutation();
  const [updateFinalAllocation] = useOrderBook_InstitutionalDemand_UpdateFinalAllocationMutation();

  return useCallback(
    async (indicationId: UUID, allocationSetId: UUID, shareQuantity: number | null) => {
      const isFinal = allocationSetId === finalAllocationSetId;
      const variables = { offeringId, indicationId, shareQuantity };

      try {
        if (isFinal) {
          await updateFinalAllocation({ variables });
        } else {
          await updateDraftAllocation({ variables: { ...variables, allocationSetId } });
        }

        refetchDemandGridReactiveVar(true);
      } catch {
        SnackbarManager.error('Failed to update allocation.');
      }
    },
    [finalAllocationSetId, offeringId, updateDraftAllocation, updateFinalAllocation]
  );
};
