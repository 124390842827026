/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TradeRelease_TradeReleaseSettingsQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type TradeRelease_TradeReleaseSettingsQuery = {
  readonly __typename?: 'Query';
  readonly tradeReleaseSettings: {
    readonly __typename?: 'TradeReleaseSettings';
    readonly id: string;
    readonly isAutoreleaseEnabled: boolean;
  };
};

export type TradeRelease_UpdateTradeReleaseSettingsMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  isAutoreleaseEnabled: Types.Scalars['Boolean'];
}>;

export type TradeRelease_UpdateTradeReleaseSettingsMutation = {
  readonly __typename?: 'Mutation';
  readonly updateTradeReleaseSettings: {
    readonly __typename?: 'TradeReleaseSettings';
    readonly id: string;
    readonly isAutoreleaseEnabled: boolean;
  };
};

export const TradeRelease_TradeReleaseSettingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'TradeRelease_TradeReleaseSettings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tradeReleaseSettings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isAutoreleaseEnabled' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useTradeRelease_TradeReleaseSettingsQuery__
 *
 * To run a query within a React component, call `useTradeRelease_TradeReleaseSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTradeRelease_TradeReleaseSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTradeRelease_TradeReleaseSettingsQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useTradeRelease_TradeReleaseSettingsQuery(
  baseOptions: Apollo.QueryHookOptions<
    TradeRelease_TradeReleaseSettingsQuery,
    TradeRelease_TradeReleaseSettingsQueryVariables
  > &
    (
      | { variables: TradeRelease_TradeReleaseSettingsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    TradeRelease_TradeReleaseSettingsQuery,
    TradeRelease_TradeReleaseSettingsQueryVariables
  >(TradeRelease_TradeReleaseSettingsDocument, options);
}
export function useTradeRelease_TradeReleaseSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TradeRelease_TradeReleaseSettingsQuery,
    TradeRelease_TradeReleaseSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TradeRelease_TradeReleaseSettingsQuery,
    TradeRelease_TradeReleaseSettingsQueryVariables
  >(TradeRelease_TradeReleaseSettingsDocument, options);
}
export function useTradeRelease_TradeReleaseSettingsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        TradeRelease_TradeReleaseSettingsQuery,
        TradeRelease_TradeReleaseSettingsQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    TradeRelease_TradeReleaseSettingsQuery,
    TradeRelease_TradeReleaseSettingsQueryVariables
  >(TradeRelease_TradeReleaseSettingsDocument, options);
}
export type TradeRelease_TradeReleaseSettingsQueryHookResult = ReturnType<
  typeof useTradeRelease_TradeReleaseSettingsQuery
>;
export type TradeRelease_TradeReleaseSettingsLazyQueryHookResult = ReturnType<
  typeof useTradeRelease_TradeReleaseSettingsLazyQuery
>;
export type TradeRelease_TradeReleaseSettingsSuspenseQueryHookResult = ReturnType<
  typeof useTradeRelease_TradeReleaseSettingsSuspenseQuery
>;
export type TradeRelease_TradeReleaseSettingsQueryResult = Apollo.QueryResult<
  TradeRelease_TradeReleaseSettingsQuery,
  TradeRelease_TradeReleaseSettingsQueryVariables
>;
export const TradeRelease_UpdateTradeReleaseSettingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'TradeRelease_UpdateTradeReleaseSettings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'isAutoreleaseEnabled' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateTradeReleaseSettings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'isAutoreleaseEnabled' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'isAutoreleaseEnabled' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isAutoreleaseEnabled' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type TradeRelease_UpdateTradeReleaseSettingsMutationFn = Apollo.MutationFunction<
  TradeRelease_UpdateTradeReleaseSettingsMutation,
  TradeRelease_UpdateTradeReleaseSettingsMutationVariables
>;

/**
 * __useTradeRelease_UpdateTradeReleaseSettingsMutation__
 *
 * To run a mutation, you first call `useTradeRelease_UpdateTradeReleaseSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTradeRelease_UpdateTradeReleaseSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tradeReleaseUpdateTradeReleaseSettingsMutation, { data, loading, error }] = useTradeRelease_UpdateTradeReleaseSettingsMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      isAutoreleaseEnabled: // value for 'isAutoreleaseEnabled'
 *   },
 * });
 */
export function useTradeRelease_UpdateTradeReleaseSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TradeRelease_UpdateTradeReleaseSettingsMutation,
    TradeRelease_UpdateTradeReleaseSettingsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TradeRelease_UpdateTradeReleaseSettingsMutation,
    TradeRelease_UpdateTradeReleaseSettingsMutationVariables
  >(TradeRelease_UpdateTradeReleaseSettingsDocument, options);
}
export type TradeRelease_UpdateTradeReleaseSettingsMutationHookResult = ReturnType<
  typeof useTradeRelease_UpdateTradeReleaseSettingsMutation
>;
export type TradeRelease_UpdateTradeReleaseSettingsMutationResult =
  Apollo.MutationResult<TradeRelease_UpdateTradeReleaseSettingsMutation>;
export type TradeRelease_UpdateTradeReleaseSettingsMutationOptions = Apollo.BaseMutationOptions<
  TradeRelease_UpdateTradeReleaseSettingsMutation,
  TradeRelease_UpdateTradeReleaseSettingsMutationVariables
>;

import { makeMockResponse } from '../../../../../../graphql/mock-response';

export const makeTradeRelease_TradeReleaseSettingsMockResponse = makeMockResponse<
  TradeRelease_TradeReleaseSettingsQueryVariables,
  TradeRelease_TradeReleaseSettingsQuery
>(TradeRelease_TradeReleaseSettingsDocument);
