/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MyOrders_CoveredIndicationChangedSubscriptionVariables = Types.Exact<{
  [key: string]: never;
}>;

export type MyOrders_CoveredIndicationChangedSubscription = {
  readonly __typename?: 'Subscription';
  readonly coveredIndicationChanged: {
    readonly __typename?: 'CoveredIndicationChanged';
    readonly offeringId?: string | null;
    readonly indicationId?: string | null;
    readonly investorKey?: string | null;
  };
};

export type MyOrders_CoveredIndications_InvestorInformationPartsFragment = {
  readonly __typename?: 'InvestorInformation';
  readonly bankInvestorName?: string | null;
  readonly bankInvestorKey?: string | null;
  readonly cmgEntityKey: string;
  readonly cmgEntityName: string;
  readonly contactName?: string | null;
  readonly contactEmail?: string | null;
  readonly isOnPlatform: boolean;
};

export type MyOrders_CoveredIndications_InterestLevelPartsFragment = {
  readonly __typename?: 'InterestLevel';
  readonly interestUnit: Types.InterestLevelInterestUnit;
  readonly interestQuantity: number;
  readonly limitPrice?: number | null;
  readonly limitType?: Types.InterestLevelLimitType | null;
};

export type MyOrders_CoveredIndications_AuditInfoPartsFragment = {
  readonly __typename?: 'AuditInfo';
  readonly modifiedByFirmKey?: string | null;
  readonly modifiedAt: string;
  readonly createdAt: string;
  readonly modifiedByUserEmail?: string | null;
  readonly createdByUserEmail?: string | null;
};

export type MyOrders_CoveredIndications_LatestEmailStatusPartsFragment = {
  readonly __typename?: 'LatestEmailStatus';
  readonly status: Types.EmailDeliveryStatus;
};

export type MyOrders_CoveredIndications_InvestorDeliveryStatusPartsFragment = {
  readonly __typename?: 'InvestorDeliveryStatus';
  readonly id: string;
  readonly offeringId: string;
  readonly cmgEntityKey: string;
  readonly overallDeliveryStatus: Types.ProspectusDeliveryStatus;
  readonly sentEmailCount: number;
  readonly totalEmailCount: number;
  readonly failedEmailCount: number;
  readonly inProgressEmailCount: number;
};

export type MyOrders_CoveredIndications_InstitutionalIndicationAcknowledgementPartsFragment = {
  readonly __typename?: 'InstitutionalIndicationAcknowledgement';
  readonly id: string;
  readonly offeringId: string;
  readonly indicationId: string;
  readonly indicationVersion: string;
  readonly acknowledgedIndicationVersion: string;
  readonly managerCmgEntityKey: string;
  readonly version: string;
  readonly auditInfo: {
    readonly __typename?: 'AuditInfo';
    readonly modifiedByFirmKey?: string | null;
    readonly modifiedAt: string;
    readonly createdAt: string;
    readonly modifiedByUserEmail?: string | null;
    readonly createdByUserEmail?: string | null;
  };
};

export type MyOrders_CoveredIndications_CoveredInstitutionalIndicationPartsFragment = {
  readonly __typename?: 'CoveredInstitutionalIndicationWithAttestation';
  readonly id: string;
  readonly offeringId: string;
  readonly status: Types.IndicationStatus;
  readonly type: Types.InstitutionalIndicationOrderType;
  readonly trancheId?: string | null;
  readonly currencyCode?: string | null;
  readonly instrumentId?: string | null;
  readonly version: string;
  readonly allocationShares?: number | null;
  readonly allocationVersion?: string | null;
  readonly allocationCurrency?: number | null;
  readonly allocationPercentOfOffering?: number | null;
  readonly indicationPercentFill?: number | null;
  readonly investorReplyStatus?: Types.AllocationAcknowledgement | null;
  readonly duplicateOfIndicationId?: string | null;
  readonly duplicateIndicationIds: ReadonlyArray<string>;
  readonly billingAndDeliveryAgentCmgEntityKey?: string | null;
  readonly coveredIndicationAttestationStatus: Types.AttestationFormStatus;
  readonly investorInformation: {
    readonly __typename?: 'InvestorInformation';
    readonly bankInvestorName?: string | null;
    readonly bankInvestorKey?: string | null;
    readonly cmgEntityKey: string;
    readonly cmgEntityName: string;
    readonly contactName?: string | null;
    readonly contactEmail?: string | null;
    readonly isOnPlatform: boolean;
  };
  readonly interestLevels: ReadonlyArray<{
    readonly __typename?: 'InterestLevel';
    readonly interestUnit: Types.InterestLevelInterestUnit;
    readonly interestQuantity: number;
    readonly limitPrice?: number | null;
    readonly limitType?: Types.InterestLevelLimitType | null;
  }>;
  readonly auditInfo: {
    readonly __typename?: 'AuditInfo';
    readonly modifiedByFirmKey?: string | null;
    readonly modifiedAt: string;
    readonly createdAt: string;
    readonly modifiedByUserEmail?: string | null;
    readonly createdByUserEmail?: string | null;
  };
  readonly institutionalIndicationAcknowledgements: ReadonlyArray<{
    readonly __typename?: 'InstitutionalIndicationAcknowledgement';
    readonly id: string;
    readonly offeringId: string;
    readonly indicationId: string;
    readonly indicationVersion: string;
    readonly acknowledgedIndicationVersion: string;
    readonly managerCmgEntityKey: string;
    readonly version: string;
    readonly auditInfo: {
      readonly __typename?: 'AuditInfo';
      readonly modifiedByFirmKey?: string | null;
      readonly modifiedAt: string;
      readonly createdAt: string;
      readonly modifiedByUserEmail?: string | null;
      readonly createdByUserEmail?: string | null;
    };
  }>;
};

export type MyOrders_CoveredIndicationsQueryVariables = Types.Exact<{
  pricingDateFrom: Types.Scalars['Date'];
  pricingDateTo: Types.Scalars['Date'];
  useNewStrategy?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;

export type MyOrders_CoveredIndicationsQuery = {
  readonly __typename?: 'Query';
  readonly coveredIndications: ReadonlyArray<{
    readonly __typename?: 'CoveredInstitutionalIndicationWithAttestation';
    readonly id: string;
    readonly offeringId: string;
    readonly status: Types.IndicationStatus;
    readonly type: Types.InstitutionalIndicationOrderType;
    readonly trancheId?: string | null;
    readonly currencyCode?: string | null;
    readonly instrumentId?: string | null;
    readonly version: string;
    readonly allocationShares?: number | null;
    readonly allocationVersion?: string | null;
    readonly allocationCurrency?: number | null;
    readonly allocationPercentOfOffering?: number | null;
    readonly indicationPercentFill?: number | null;
    readonly investorReplyStatus?: Types.AllocationAcknowledgement | null;
    readonly duplicateOfIndicationId?: string | null;
    readonly duplicateIndicationIds: ReadonlyArray<string>;
    readonly billingAndDeliveryAgentCmgEntityKey?: string | null;
    readonly coveredIndicationAttestationStatus: Types.AttestationFormStatus;
    readonly investorInformation: {
      readonly __typename?: 'InvestorInformation';
      readonly bankInvestorName?: string | null;
      readonly bankInvestorKey?: string | null;
      readonly cmgEntityKey: string;
      readonly cmgEntityName: string;
      readonly contactName?: string | null;
      readonly contactEmail?: string | null;
      readonly isOnPlatform: boolean;
    };
    readonly interestLevels: ReadonlyArray<{
      readonly __typename?: 'InterestLevel';
      readonly interestUnit: Types.InterestLevelInterestUnit;
      readonly interestQuantity: number;
      readonly limitPrice?: number | null;
      readonly limitType?: Types.InterestLevelLimitType | null;
    }>;
    readonly auditInfo: {
      readonly __typename?: 'AuditInfo';
      readonly modifiedByFirmKey?: string | null;
      readonly modifiedAt: string;
      readonly createdAt: string;
      readonly modifiedByUserEmail?: string | null;
      readonly createdByUserEmail?: string | null;
    };
    readonly institutionalIndicationAcknowledgements: ReadonlyArray<{
      readonly __typename?: 'InstitutionalIndicationAcknowledgement';
      readonly id: string;
      readonly offeringId: string;
      readonly indicationId: string;
      readonly indicationVersion: string;
      readonly acknowledgedIndicationVersion: string;
      readonly managerCmgEntityKey: string;
      readonly version: string;
      readonly auditInfo: {
        readonly __typename?: 'AuditInfo';
        readonly modifiedByFirmKey?: string | null;
        readonly modifiedAt: string;
        readonly createdAt: string;
        readonly modifiedByUserEmail?: string | null;
        readonly createdByUserEmail?: string | null;
      };
    }>;
  }>;
};

export type MyOrders_GridCoveredAttestationStatuses_AttestationStatusPartsFragment = {
  readonly __typename?: 'AttestationStatus';
  readonly cmgEntityKey: string;
  readonly status: Types.AttestationFormStatus;
  readonly firmId: string;
};

export type MyOrders_GridCoveredAttestationStatusesQueryVariables = Types.Exact<{
  cmgEntityKeys: ReadonlyArray<Types.Scalars['String']> | Types.Scalars['String'];
}>;

export type MyOrders_GridCoveredAttestationStatusesQuery = {
  readonly __typename?: 'Query';
  readonly coveredAttestationStatuses: ReadonlyArray<{
    readonly __typename?: 'AttestationStatus';
    readonly cmgEntityKey: string;
    readonly status: Types.AttestationFormStatus;
    readonly firmId: string;
  }>;
};

export type MyOrders_LiveOfferingsList_OfferingLimitedPartsFragment = {
  readonly __typename?: 'OfferingLimited';
  readonly id: string;
  readonly isExecutingOnPlatform: boolean;
  readonly issuerName: string;
  readonly type: Types.OfferingType;
  readonly status: Types.OfferingStatus;
  readonly pricingDate?: string | null;
  readonly finalFilingOfferPrice?: number | null;
  readonly pricingCurrencyCode: string;
  readonly pricingInstrumentCountryCode?: string | null;
  readonly managers: ReadonlyArray<{
    readonly __typename?: 'ManagerLimited';
    readonly cmgEntityKey: string;
    readonly firmName: string;
    readonly firmNameAbbreviation?: string | null;
  }>;
  readonly syndicateEntitlements: ReadonlyArray<{
    readonly __typename?: 'SyndicateEntitlements';
    readonly cmgEntityKey: string;
    readonly entitlements: ReadonlyArray<string>;
  }>;
};

export type MyOrders_LiveOfferingsListQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.LivePublishedOfferingListFiltersInput>;
}>;

export type MyOrders_LiveOfferingsListQuery = {
  readonly __typename?: 'Query';
  readonly livePublishedOfferings: {
    readonly __typename?: 'OfferingList';
    readonly data: ReadonlyArray<{
      readonly __typename?: 'OfferingLimited';
      readonly id: string;
      readonly isExecutingOnPlatform: boolean;
      readonly issuerName: string;
      readonly type: Types.OfferingType;
      readonly status: Types.OfferingStatus;
      readonly pricingDate?: string | null;
      readonly finalFilingOfferPrice?: number | null;
      readonly pricingCurrencyCode: string;
      readonly pricingInstrumentCountryCode?: string | null;
      readonly managers: ReadonlyArray<{
        readonly __typename?: 'ManagerLimited';
        readonly cmgEntityKey: string;
        readonly firmName: string;
        readonly firmNameAbbreviation?: string | null;
      }>;
      readonly syndicateEntitlements: ReadonlyArray<{
        readonly __typename?: 'SyndicateEntitlements';
        readonly cmgEntityKey: string;
        readonly entitlements: ReadonlyArray<string>;
      }>;
    }>;
  };
};

export type MyOrders_PricedOfferingsList_OfferingLimitedPartsFragment = {
  readonly __typename?: 'OfferingLimited';
  readonly id: string;
  readonly isExecutingOnPlatform: boolean;
  readonly issuerName: string;
  readonly type: Types.OfferingType;
  readonly status: Types.OfferingStatus;
  readonly pricingDate?: string | null;
  readonly finalFilingOfferPrice?: number | null;
  readonly pricingCurrencyCode: string;
  readonly pricingInstrumentCountryCode?: string | null;
  readonly managers: ReadonlyArray<{
    readonly __typename?: 'ManagerLimited';
    readonly cmgEntityKey: string;
    readonly firmName: string;
    readonly firmNameAbbreviation?: string | null;
  }>;
  readonly syndicateEntitlements: ReadonlyArray<{
    readonly __typename?: 'SyndicateEntitlements';
    readonly cmgEntityKey: string;
    readonly entitlements: ReadonlyArray<string>;
  }>;
};

export type MyOrders_PricedOfferingsListQueryVariables = Types.Exact<{
  startPricingDate: Types.Scalars['Date'];
  endPricingDate: Types.Scalars['Date'];
  cmgEntityKey?: Types.InputMaybe<Types.Scalars['String']>;
  includeTestOfferings?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;

export type MyOrders_PricedOfferingsListQuery = {
  readonly __typename?: 'Query';
  readonly pricedPublishedOfferings: {
    readonly __typename?: 'OfferingList';
    readonly data: ReadonlyArray<{
      readonly __typename?: 'OfferingLimited';
      readonly id: string;
      readonly isExecutingOnPlatform: boolean;
      readonly issuerName: string;
      readonly type: Types.OfferingType;
      readonly status: Types.OfferingStatus;
      readonly pricingDate?: string | null;
      readonly finalFilingOfferPrice?: number | null;
      readonly pricingCurrencyCode: string;
      readonly pricingInstrumentCountryCode?: string | null;
      readonly managers: ReadonlyArray<{
        readonly __typename?: 'ManagerLimited';
        readonly cmgEntityKey: string;
        readonly firmName: string;
        readonly firmNameAbbreviation?: string | null;
      }>;
      readonly syndicateEntitlements: ReadonlyArray<{
        readonly __typename?: 'SyndicateEntitlements';
        readonly cmgEntityKey: string;
        readonly entitlements: ReadonlyArray<string>;
      }>;
    }>;
  };
};

export type MyOrders_InvestorDeliveryStatusPartsFragment = {
  readonly __typename?: 'InvestorDeliveryStatus';
  readonly id: string;
  readonly offeringId: string;
  readonly cmgEntityKey: string;
  readonly overallDeliveryStatus: Types.ProspectusDeliveryStatus;
  readonly sentEmailCount: number;
  readonly totalEmailCount: number;
  readonly failedEmailCount: number;
  readonly inProgressEmailCount: number;
};

export type MyOrders_ProspectusDeliverySummariesQueryVariables = Types.Exact<{
  offeringIds: ReadonlyArray<Types.Scalars['UUID']> | Types.Scalars['UUID'];
  investorKeys: ReadonlyArray<Types.Scalars['String']> | Types.Scalars['String'];
}>;

export type MyOrders_ProspectusDeliverySummariesQuery = {
  readonly __typename?: 'Query';
  readonly prospectusDeliverySummaries: ReadonlyArray<{
    readonly __typename?: 'InvestorDeliveryStatus';
    readonly id: string;
    readonly offeringId: string;
    readonly cmgEntityKey: string;
    readonly overallDeliveryStatus: Types.ProspectusDeliveryStatus;
    readonly sentEmailCount: number;
    readonly totalEmailCount: number;
    readonly failedEmailCount: number;
    readonly inProgressEmailCount: number;
  }>;
};

export type MyOrders_PublishedOfferingsList_OfferingFullPartsFragment = {
  readonly __typename?: 'OfferingFull';
  readonly id: string;
  readonly type: Types.OfferingType;
  readonly status: Types.OfferingStatus;
  readonly pricingInstrumentStockSymbol?: string | null;
  readonly isExecutingOnPlatform: boolean;
  readonly pricingCurrencyCode: string;
  readonly pricingInstrumentId?: string | null;
  readonly issuer: { readonly __typename?: 'IssuerFull'; readonly name: string };
  readonly timing: { readonly __typename?: 'TimingFull'; readonly pricingDate?: string | null };
  readonly syndicate: {
    readonly __typename?: 'SyndicateFull';
    readonly managers: ReadonlyArray<{
      readonly __typename?: 'ManagerFull';
      readonly cmgEntityKey: string;
      readonly firmName: string;
      readonly firmNameAbbreviation?: string | null;
      readonly entitlements?: ReadonlyArray<string> | null;
    }>;
  };
  readonly instruments: ReadonlyArray<{
    readonly __typename?: 'InstrumentFull';
    readonly id: string;
    readonly countryCode?: string | null;
  }>;
  readonly terms: {
    readonly __typename?: 'TermsFull';
    readonly filings: ReadonlyArray<{
      readonly __typename?: 'FilingFull';
      readonly filingType: Types.FilingType;
      readonly id: string;
      readonly offerPrice?: number | null;
    }>;
  };
};

export type MyOrders_PublishedOfferingsListQueryVariables = Types.Exact<{
  skip?: Types.InputMaybe<Types.Scalars['Int']>;
  take?: Types.InputMaybe<Types.Scalars['Int']>;
  includeTestOfferings: Types.Scalars['Boolean'];
  where: Types.OfferingFullFilterInput;
}>;

export type MyOrders_PublishedOfferingsListQuery = {
  readonly __typename?: 'Query';
  readonly publishedOfferings?: {
    readonly __typename?: 'PublishedOfferingsCollectionSegment';
    readonly items?: ReadonlyArray<{
      readonly __typename?: 'OfferingFull';
      readonly id: string;
      readonly type: Types.OfferingType;
      readonly status: Types.OfferingStatus;
      readonly pricingInstrumentStockSymbol?: string | null;
      readonly isExecutingOnPlatform: boolean;
      readonly pricingCurrencyCode: string;
      readonly pricingInstrumentId?: string | null;
      readonly issuer: { readonly __typename?: 'IssuerFull'; readonly name: string };
      readonly timing: { readonly __typename?: 'TimingFull'; readonly pricingDate?: string | null };
      readonly syndicate: {
        readonly __typename?: 'SyndicateFull';
        readonly managers: ReadonlyArray<{
          readonly __typename?: 'ManagerFull';
          readonly cmgEntityKey: string;
          readonly firmName: string;
          readonly firmNameAbbreviation?: string | null;
          readonly entitlements?: ReadonlyArray<string> | null;
        }>;
      };
      readonly instruments: ReadonlyArray<{
        readonly __typename?: 'InstrumentFull';
        readonly id: string;
        readonly countryCode?: string | null;
      }>;
      readonly terms: {
        readonly __typename?: 'TermsFull';
        readonly filings: ReadonlyArray<{
          readonly __typename?: 'FilingFull';
          readonly filingType: Types.FilingType;
          readonly id: string;
          readonly offerPrice?: number | null;
        }>;
      };
    }> | null;
    readonly pageInfo: {
      readonly __typename?: 'CollectionSegmentInfo';
      readonly hasNextPage: boolean;
    };
  } | null;
};

export const MyOrders_CoveredIndications_LatestEmailStatusPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MyOrders_CoveredIndications_LatestEmailStatusParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'LatestEmailStatus' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'status' } }],
      },
    },
  ],
} as unknown as DocumentNode;
export const MyOrders_CoveredIndications_InvestorDeliveryStatusPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MyOrders_CoveredIndications_InvestorDeliveryStatusParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'InvestorDeliveryStatus' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overallDeliveryStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sentEmailCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalEmailCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'failedEmailCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inProgressEmailCount' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const MyOrders_CoveredIndications_InvestorInformationPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MyOrders_CoveredIndications_InvestorInformationParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'InvestorInformation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'bankInvestorName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bankInvestorKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isOnPlatform' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const MyOrders_CoveredIndications_InterestLevelPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MyOrders_CoveredIndications_InterestLevelParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'InterestLevel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'interestUnit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'interestQuantity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'limitPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'limitType' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const MyOrders_CoveredIndications_AuditInfoPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MyOrders_CoveredIndications_AuditInfoParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AuditInfo' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'modifiedByFirmKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modifiedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modifiedByUserEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdByUserEmail' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const MyOrders_CoveredIndications_InstitutionalIndicationAcknowledgementPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'MyOrders_CoveredIndications_InstitutionalIndicationAcknowledgementParts',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstitutionalIndicationAcknowledgement' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'indicationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'indicationVersion' } },
          { kind: 'Field', name: { kind: 'Name', value: 'acknowledgedIndicationVersion' } },
          { kind: 'Field', name: { kind: 'Name', value: 'managerCmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'auditInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MyOrders_CoveredIndications_AuditInfoParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MyOrders_CoveredIndications_AuditInfoParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AuditInfo' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'modifiedByFirmKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modifiedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modifiedByUserEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdByUserEmail' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const MyOrders_CoveredIndications_CoveredInstitutionalIndicationPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'MyOrders_CoveredIndications_CoveredInstitutionalIndicationParts',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CoveredInstitutionalIndicationWithAttestation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trancheId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'instrumentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investorInformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'MyOrders_CoveredIndications_InvestorInformationParts',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'interestLevels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MyOrders_CoveredIndications_InterestLevelParts' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'allocationShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allocationVersion' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allocationCurrency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allocationPercentOfOffering' } },
          { kind: 'Field', name: { kind: 'Name', value: 'indicationPercentFill' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorReplyStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'duplicateOfIndicationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'duplicateIndicationIds' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'auditInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MyOrders_CoveredIndications_AuditInfoParts' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'billingAndDeliveryAgentCmgEntityKey' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institutionalIndicationAcknowledgements' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'MyOrders_CoveredIndications_InstitutionalIndicationAcknowledgementParts',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'coveredIndicationAttestationStatus' },
            name: { kind: 'Name', value: 'attestationStatus' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MyOrders_CoveredIndications_InvestorInformationParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'InvestorInformation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'bankInvestorName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bankInvestorKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isOnPlatform' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MyOrders_CoveredIndications_InterestLevelParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'InterestLevel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'interestUnit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'interestQuantity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'limitPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'limitType' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MyOrders_CoveredIndications_AuditInfoParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AuditInfo' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'modifiedByFirmKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modifiedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modifiedByUserEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdByUserEmail' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'MyOrders_CoveredIndications_InstitutionalIndicationAcknowledgementParts',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstitutionalIndicationAcknowledgement' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'indicationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'indicationVersion' } },
          { kind: 'Field', name: { kind: 'Name', value: 'acknowledgedIndicationVersion' } },
          { kind: 'Field', name: { kind: 'Name', value: 'managerCmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'auditInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MyOrders_CoveredIndications_AuditInfoParts' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const MyOrders_GridCoveredAttestationStatuses_AttestationStatusPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'MyOrders_GridCoveredAttestationStatuses_AttestationStatusParts',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AttestationStatus' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const MyOrders_LiveOfferingsList_OfferingLimitedPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MyOrders_LiveOfferingsList_OfferingLimitedParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'OfferingLimited' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isExecutingOnPlatform' } },
          { kind: 'Field', name: { kind: 'Name', value: 'issuerName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'finalFilingOfferPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingCurrencyCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'managers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firmNameAbbreviation' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'syndicateEntitlements' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'entitlements' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingInstrumentCountryCode' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const MyOrders_PricedOfferingsList_OfferingLimitedPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MyOrders_PricedOfferingsList_OfferingLimitedParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'OfferingLimited' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isExecutingOnPlatform' } },
          { kind: 'Field', name: { kind: 'Name', value: 'issuerName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'finalFilingOfferPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingCurrencyCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'managers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firmNameAbbreviation' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'syndicateEntitlements' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'entitlements' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingInstrumentCountryCode' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const MyOrders_InvestorDeliveryStatusPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MyOrders_InvestorDeliveryStatusParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'InvestorDeliveryStatus' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overallDeliveryStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sentEmailCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalEmailCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'failedEmailCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inProgressEmailCount' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const MyOrders_PublishedOfferingsList_OfferingFullPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MyOrders_PublishedOfferingsList_OfferingFullParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'OfferingFull' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'issuer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'timing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'pricingDate' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingInstrumentStockSymbol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isExecutingOnPlatform' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingCurrencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingInstrumentId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'syndicate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'managers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firmNameAbbreviation' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'entitlements' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'instruments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'terms' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'filings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'filingType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'offerPrice' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const MyOrders_CoveredIndicationChangedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'MyOrders_CoveredIndicationChanged' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'coveredIndicationChanged' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'offeringId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'indicationId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'investorKey' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useMyOrders_CoveredIndicationChangedSubscription__
 *
 * To run a query within a React component, call `useMyOrders_CoveredIndicationChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useMyOrders_CoveredIndicationChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyOrders_CoveredIndicationChangedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useMyOrders_CoveredIndicationChangedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    MyOrders_CoveredIndicationChangedSubscription,
    MyOrders_CoveredIndicationChangedSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    MyOrders_CoveredIndicationChangedSubscription,
    MyOrders_CoveredIndicationChangedSubscriptionVariables
  >(MyOrders_CoveredIndicationChangedDocument, options);
}
export type MyOrders_CoveredIndicationChangedSubscriptionHookResult = ReturnType<
  typeof useMyOrders_CoveredIndicationChangedSubscription
>;
export type MyOrders_CoveredIndicationChangedSubscriptionResult =
  Apollo.SubscriptionResult<MyOrders_CoveredIndicationChangedSubscription>;
export const MyOrders_CoveredIndicationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MyOrders_CoveredIndications' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pricingDateFrom' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pricingDateTo' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'useNewStrategy' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'coveredIndications' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pricingDateFrom' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pricingDateFrom' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pricingDateTo' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pricingDateTo' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'useNewStrategy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'useNewStrategy' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'MyOrders_CoveredIndications_CoveredInstitutionalIndicationParts',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MyOrders_CoveredIndications_InvestorInformationParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'InvestorInformation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'bankInvestorName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bankInvestorKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isOnPlatform' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MyOrders_CoveredIndications_InterestLevelParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'InterestLevel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'interestUnit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'interestQuantity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'limitPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'limitType' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MyOrders_CoveredIndications_AuditInfoParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AuditInfo' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'modifiedByFirmKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modifiedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modifiedByUserEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdByUserEmail' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'MyOrders_CoveredIndications_InstitutionalIndicationAcknowledgementParts',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InstitutionalIndicationAcknowledgement' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'indicationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'indicationVersion' } },
          { kind: 'Field', name: { kind: 'Name', value: 'acknowledgedIndicationVersion' } },
          { kind: 'Field', name: { kind: 'Name', value: 'managerCmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'auditInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MyOrders_CoveredIndications_AuditInfoParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'MyOrders_CoveredIndications_CoveredInstitutionalIndicationParts',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CoveredInstitutionalIndicationWithAttestation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trancheId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'instrumentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investorInformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'MyOrders_CoveredIndications_InvestorInformationParts',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'interestLevels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MyOrders_CoveredIndications_InterestLevelParts' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'allocationShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allocationVersion' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allocationCurrency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allocationPercentOfOffering' } },
          { kind: 'Field', name: { kind: 'Name', value: 'indicationPercentFill' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investorReplyStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'duplicateOfIndicationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'duplicateIndicationIds' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'auditInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MyOrders_CoveredIndications_AuditInfoParts' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'billingAndDeliveryAgentCmgEntityKey' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institutionalIndicationAcknowledgements' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'MyOrders_CoveredIndications_InstitutionalIndicationAcknowledgementParts',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'coveredIndicationAttestationStatus' },
            name: { kind: 'Name', value: 'attestationStatus' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useMyOrders_CoveredIndicationsQuery__
 *
 * To run a query within a React component, call `useMyOrders_CoveredIndicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyOrders_CoveredIndicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyOrders_CoveredIndicationsQuery({
 *   variables: {
 *      pricingDateFrom: // value for 'pricingDateFrom'
 *      pricingDateTo: // value for 'pricingDateTo'
 *      useNewStrategy: // value for 'useNewStrategy'
 *   },
 * });
 */
export function useMyOrders_CoveredIndicationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    MyOrders_CoveredIndicationsQuery,
    MyOrders_CoveredIndicationsQueryVariables
  > &
    ({ variables: MyOrders_CoveredIndicationsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MyOrders_CoveredIndicationsQuery,
    MyOrders_CoveredIndicationsQueryVariables
  >(MyOrders_CoveredIndicationsDocument, options);
}
export function useMyOrders_CoveredIndicationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MyOrders_CoveredIndicationsQuery,
    MyOrders_CoveredIndicationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MyOrders_CoveredIndicationsQuery,
    MyOrders_CoveredIndicationsQueryVariables
  >(MyOrders_CoveredIndicationsDocument, options);
}
export function useMyOrders_CoveredIndicationsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        MyOrders_CoveredIndicationsQuery,
        MyOrders_CoveredIndicationsQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    MyOrders_CoveredIndicationsQuery,
    MyOrders_CoveredIndicationsQueryVariables
  >(MyOrders_CoveredIndicationsDocument, options);
}
export type MyOrders_CoveredIndicationsQueryHookResult = ReturnType<
  typeof useMyOrders_CoveredIndicationsQuery
>;
export type MyOrders_CoveredIndicationsLazyQueryHookResult = ReturnType<
  typeof useMyOrders_CoveredIndicationsLazyQuery
>;
export type MyOrders_CoveredIndicationsSuspenseQueryHookResult = ReturnType<
  typeof useMyOrders_CoveredIndicationsSuspenseQuery
>;
export type MyOrders_CoveredIndicationsQueryResult = Apollo.QueryResult<
  MyOrders_CoveredIndicationsQuery,
  MyOrders_CoveredIndicationsQueryVariables
>;
export const MyOrders_GridCoveredAttestationStatusesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MyOrders_GridCoveredAttestationStatuses' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKeys' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'coveredAttestationStatuses' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cmgEntityKeys' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKeys' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'MyOrders_GridCoveredAttestationStatuses_AttestationStatusParts',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'MyOrders_GridCoveredAttestationStatuses_AttestationStatusParts',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AttestationStatus' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useMyOrders_GridCoveredAttestationStatusesQuery__
 *
 * To run a query within a React component, call `useMyOrders_GridCoveredAttestationStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyOrders_GridCoveredAttestationStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyOrders_GridCoveredAttestationStatusesQuery({
 *   variables: {
 *      cmgEntityKeys: // value for 'cmgEntityKeys'
 *   },
 * });
 */
export function useMyOrders_GridCoveredAttestationStatusesQuery(
  baseOptions: Apollo.QueryHookOptions<
    MyOrders_GridCoveredAttestationStatusesQuery,
    MyOrders_GridCoveredAttestationStatusesQueryVariables
  > &
    (
      | { variables: MyOrders_GridCoveredAttestationStatusesQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MyOrders_GridCoveredAttestationStatusesQuery,
    MyOrders_GridCoveredAttestationStatusesQueryVariables
  >(MyOrders_GridCoveredAttestationStatusesDocument, options);
}
export function useMyOrders_GridCoveredAttestationStatusesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MyOrders_GridCoveredAttestationStatusesQuery,
    MyOrders_GridCoveredAttestationStatusesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MyOrders_GridCoveredAttestationStatusesQuery,
    MyOrders_GridCoveredAttestationStatusesQueryVariables
  >(MyOrders_GridCoveredAttestationStatusesDocument, options);
}
export function useMyOrders_GridCoveredAttestationStatusesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        MyOrders_GridCoveredAttestationStatusesQuery,
        MyOrders_GridCoveredAttestationStatusesQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    MyOrders_GridCoveredAttestationStatusesQuery,
    MyOrders_GridCoveredAttestationStatusesQueryVariables
  >(MyOrders_GridCoveredAttestationStatusesDocument, options);
}
export type MyOrders_GridCoveredAttestationStatusesQueryHookResult = ReturnType<
  typeof useMyOrders_GridCoveredAttestationStatusesQuery
>;
export type MyOrders_GridCoveredAttestationStatusesLazyQueryHookResult = ReturnType<
  typeof useMyOrders_GridCoveredAttestationStatusesLazyQuery
>;
export type MyOrders_GridCoveredAttestationStatusesSuspenseQueryHookResult = ReturnType<
  typeof useMyOrders_GridCoveredAttestationStatusesSuspenseQuery
>;
export type MyOrders_GridCoveredAttestationStatusesQueryResult = Apollo.QueryResult<
  MyOrders_GridCoveredAttestationStatusesQuery,
  MyOrders_GridCoveredAttestationStatusesQueryVariables
>;
export const MyOrders_LiveOfferingsListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MyOrders_LiveOfferingsList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'LivePublishedOfferingListFiltersInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'livePublishedOfferings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'MyOrders_LiveOfferingsList_OfferingLimitedParts',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MyOrders_LiveOfferingsList_OfferingLimitedParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'OfferingLimited' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isExecutingOnPlatform' } },
          { kind: 'Field', name: { kind: 'Name', value: 'issuerName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'finalFilingOfferPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingCurrencyCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'managers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firmNameAbbreviation' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'syndicateEntitlements' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'entitlements' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingInstrumentCountryCode' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useMyOrders_LiveOfferingsListQuery__
 *
 * To run a query within a React component, call `useMyOrders_LiveOfferingsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyOrders_LiveOfferingsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyOrders_LiveOfferingsListQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useMyOrders_LiveOfferingsListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MyOrders_LiveOfferingsListQuery,
    MyOrders_LiveOfferingsListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MyOrders_LiveOfferingsListQuery, MyOrders_LiveOfferingsListQueryVariables>(
    MyOrders_LiveOfferingsListDocument,
    options
  );
}
export function useMyOrders_LiveOfferingsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MyOrders_LiveOfferingsListQuery,
    MyOrders_LiveOfferingsListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MyOrders_LiveOfferingsListQuery,
    MyOrders_LiveOfferingsListQueryVariables
  >(MyOrders_LiveOfferingsListDocument, options);
}
export function useMyOrders_LiveOfferingsListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        MyOrders_LiveOfferingsListQuery,
        MyOrders_LiveOfferingsListQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    MyOrders_LiveOfferingsListQuery,
    MyOrders_LiveOfferingsListQueryVariables
  >(MyOrders_LiveOfferingsListDocument, options);
}
export type MyOrders_LiveOfferingsListQueryHookResult = ReturnType<
  typeof useMyOrders_LiveOfferingsListQuery
>;
export type MyOrders_LiveOfferingsListLazyQueryHookResult = ReturnType<
  typeof useMyOrders_LiveOfferingsListLazyQuery
>;
export type MyOrders_LiveOfferingsListSuspenseQueryHookResult = ReturnType<
  typeof useMyOrders_LiveOfferingsListSuspenseQuery
>;
export type MyOrders_LiveOfferingsListQueryResult = Apollo.QueryResult<
  MyOrders_LiveOfferingsListQuery,
  MyOrders_LiveOfferingsListQueryVariables
>;
export const MyOrders_PricedOfferingsListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MyOrders_PricedOfferingsList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'startPricingDate' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'endPricingDate' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKey' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeTestOfferings' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pricedPublishedOfferings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'startPricingDate' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'startPricingDate' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'endPricingDate' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'endPricingDate' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'cmgEntityKey' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKey' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'includeTestOfferings' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'includeTestOfferings' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'MyOrders_PricedOfferingsList_OfferingLimitedParts',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MyOrders_PricedOfferingsList_OfferingLimitedParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'OfferingLimited' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isExecutingOnPlatform' } },
          { kind: 'Field', name: { kind: 'Name', value: 'issuerName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'finalFilingOfferPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingCurrencyCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'managers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firmNameAbbreviation' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'syndicateEntitlements' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'entitlements' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingInstrumentCountryCode' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useMyOrders_PricedOfferingsListQuery__
 *
 * To run a query within a React component, call `useMyOrders_PricedOfferingsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyOrders_PricedOfferingsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyOrders_PricedOfferingsListQuery({
 *   variables: {
 *      startPricingDate: // value for 'startPricingDate'
 *      endPricingDate: // value for 'endPricingDate'
 *      cmgEntityKey: // value for 'cmgEntityKey'
 *      includeTestOfferings: // value for 'includeTestOfferings'
 *   },
 * });
 */
export function useMyOrders_PricedOfferingsListQuery(
  baseOptions: Apollo.QueryHookOptions<
    MyOrders_PricedOfferingsListQuery,
    MyOrders_PricedOfferingsListQueryVariables
  > &
    ({ variables: MyOrders_PricedOfferingsListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MyOrders_PricedOfferingsListQuery,
    MyOrders_PricedOfferingsListQueryVariables
  >(MyOrders_PricedOfferingsListDocument, options);
}
export function useMyOrders_PricedOfferingsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MyOrders_PricedOfferingsListQuery,
    MyOrders_PricedOfferingsListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MyOrders_PricedOfferingsListQuery,
    MyOrders_PricedOfferingsListQueryVariables
  >(MyOrders_PricedOfferingsListDocument, options);
}
export function useMyOrders_PricedOfferingsListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        MyOrders_PricedOfferingsListQuery,
        MyOrders_PricedOfferingsListQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    MyOrders_PricedOfferingsListQuery,
    MyOrders_PricedOfferingsListQueryVariables
  >(MyOrders_PricedOfferingsListDocument, options);
}
export type MyOrders_PricedOfferingsListQueryHookResult = ReturnType<
  typeof useMyOrders_PricedOfferingsListQuery
>;
export type MyOrders_PricedOfferingsListLazyQueryHookResult = ReturnType<
  typeof useMyOrders_PricedOfferingsListLazyQuery
>;
export type MyOrders_PricedOfferingsListSuspenseQueryHookResult = ReturnType<
  typeof useMyOrders_PricedOfferingsListSuspenseQuery
>;
export type MyOrders_PricedOfferingsListQueryResult = Apollo.QueryResult<
  MyOrders_PricedOfferingsListQuery,
  MyOrders_PricedOfferingsListQueryVariables
>;
export const MyOrders_ProspectusDeliverySummariesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MyOrders_ProspectusDeliverySummaries' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'investorKeys' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'prospectusDeliverySummaries' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringIds' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'investorKeys' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'investorKeys' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MyOrders_InvestorDeliveryStatusParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MyOrders_InvestorDeliveryStatusParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'InvestorDeliveryStatus' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offeringId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overallDeliveryStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sentEmailCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalEmailCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'failedEmailCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inProgressEmailCount' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useMyOrders_ProspectusDeliverySummariesQuery__
 *
 * To run a query within a React component, call `useMyOrders_ProspectusDeliverySummariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyOrders_ProspectusDeliverySummariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyOrders_ProspectusDeliverySummariesQuery({
 *   variables: {
 *      offeringIds: // value for 'offeringIds'
 *      investorKeys: // value for 'investorKeys'
 *   },
 * });
 */
export function useMyOrders_ProspectusDeliverySummariesQuery(
  baseOptions: Apollo.QueryHookOptions<
    MyOrders_ProspectusDeliverySummariesQuery,
    MyOrders_ProspectusDeliverySummariesQueryVariables
  > &
    (
      | { variables: MyOrders_ProspectusDeliverySummariesQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MyOrders_ProspectusDeliverySummariesQuery,
    MyOrders_ProspectusDeliverySummariesQueryVariables
  >(MyOrders_ProspectusDeliverySummariesDocument, options);
}
export function useMyOrders_ProspectusDeliverySummariesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MyOrders_ProspectusDeliverySummariesQuery,
    MyOrders_ProspectusDeliverySummariesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MyOrders_ProspectusDeliverySummariesQuery,
    MyOrders_ProspectusDeliverySummariesQueryVariables
  >(MyOrders_ProspectusDeliverySummariesDocument, options);
}
export function useMyOrders_ProspectusDeliverySummariesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        MyOrders_ProspectusDeliverySummariesQuery,
        MyOrders_ProspectusDeliverySummariesQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    MyOrders_ProspectusDeliverySummariesQuery,
    MyOrders_ProspectusDeliverySummariesQueryVariables
  >(MyOrders_ProspectusDeliverySummariesDocument, options);
}
export type MyOrders_ProspectusDeliverySummariesQueryHookResult = ReturnType<
  typeof useMyOrders_ProspectusDeliverySummariesQuery
>;
export type MyOrders_ProspectusDeliverySummariesLazyQueryHookResult = ReturnType<
  typeof useMyOrders_ProspectusDeliverySummariesLazyQuery
>;
export type MyOrders_ProspectusDeliverySummariesSuspenseQueryHookResult = ReturnType<
  typeof useMyOrders_ProspectusDeliverySummariesSuspenseQuery
>;
export type MyOrders_ProspectusDeliverySummariesQueryResult = Apollo.QueryResult<
  MyOrders_ProspectusDeliverySummariesQuery,
  MyOrders_ProspectusDeliverySummariesQueryVariables
>;
export const MyOrders_PublishedOfferingsListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MyOrders_PublishedOfferingsList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'take' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeTestOfferings' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'OfferingFullFilterInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'publishedOfferings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'take' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'take' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'includeTestOfferings' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'includeTestOfferings' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'MyOrders_PublishedOfferingsList_OfferingFullParts',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MyOrders_PublishedOfferingsList_OfferingFullParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'OfferingFull' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'issuer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'timing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'pricingDate' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingInstrumentStockSymbol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isExecutingOnPlatform' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingCurrencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingInstrumentId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'syndicate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'managers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firmNameAbbreviation' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'entitlements' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'instruments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'terms' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'filings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'filingType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'offerPrice' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useMyOrders_PublishedOfferingsListQuery__
 *
 * To run a query within a React component, call `useMyOrders_PublishedOfferingsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyOrders_PublishedOfferingsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyOrders_PublishedOfferingsListQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      includeTestOfferings: // value for 'includeTestOfferings'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useMyOrders_PublishedOfferingsListQuery(
  baseOptions: Apollo.QueryHookOptions<
    MyOrders_PublishedOfferingsListQuery,
    MyOrders_PublishedOfferingsListQueryVariables
  > &
    (
      | { variables: MyOrders_PublishedOfferingsListQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MyOrders_PublishedOfferingsListQuery,
    MyOrders_PublishedOfferingsListQueryVariables
  >(MyOrders_PublishedOfferingsListDocument, options);
}
export function useMyOrders_PublishedOfferingsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MyOrders_PublishedOfferingsListQuery,
    MyOrders_PublishedOfferingsListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MyOrders_PublishedOfferingsListQuery,
    MyOrders_PublishedOfferingsListQueryVariables
  >(MyOrders_PublishedOfferingsListDocument, options);
}
export function useMyOrders_PublishedOfferingsListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        MyOrders_PublishedOfferingsListQuery,
        MyOrders_PublishedOfferingsListQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    MyOrders_PublishedOfferingsListQuery,
    MyOrders_PublishedOfferingsListQueryVariables
  >(MyOrders_PublishedOfferingsListDocument, options);
}
export type MyOrders_PublishedOfferingsListQueryHookResult = ReturnType<
  typeof useMyOrders_PublishedOfferingsListQuery
>;
export type MyOrders_PublishedOfferingsListLazyQueryHookResult = ReturnType<
  typeof useMyOrders_PublishedOfferingsListLazyQuery
>;
export type MyOrders_PublishedOfferingsListSuspenseQueryHookResult = ReturnType<
  typeof useMyOrders_PublishedOfferingsListSuspenseQuery
>;
export type MyOrders_PublishedOfferingsListQueryResult = Apollo.QueryResult<
  MyOrders_PublishedOfferingsListQuery,
  MyOrders_PublishedOfferingsListQueryVariables
>;

import {
  UseWithSubscriptionOptions,
  useWithLazySubscription,
  useWithSubscription,
} from '../../../../graphql/with-subscription';

type UseWithMyOrders_CoveredIndicationChangedSubscriptionOptions<
  TQueryData extends {},
  TQueryVariables extends Apollo.OperationVariables
> = Omit<
  UseWithSubscriptionOptions<
    MyOrders_CoveredIndicationChangedSubscription,
    MyOrders_CoveredIndicationChangedSubscriptionVariables,
    TQueryData,
    TQueryVariables
  >,
  'document' | 'clientName'
>;

export const useWithMyOrders_CoveredIndicationChangedSubscription = <
  TQueryData extends {},
  TQueryVariables extends Apollo.OperationVariables
>(
  queryResults: Apollo.QueryResult<TQueryData, TQueryVariables>,
  options: UseWithMyOrders_CoveredIndicationChangedSubscriptionOptions<TQueryData, TQueryVariables>
) => {
  return useWithSubscription(queryResults, {
    ...options,
    document: MyOrders_CoveredIndicationChangedDocument,
  });
};

export const useWithMyOrders_CoveredIndicationChangedLazySubscription = <
  TQueryData extends {},
  TQueryVariables extends Apollo.OperationVariables
>(
  queryResults: Apollo.LazyQueryResultTuple<TQueryData, TQueryVariables>,
  options: UseWithMyOrders_CoveredIndicationChangedSubscriptionOptions<TQueryData, TQueryVariables>
) => {
  return useWithLazySubscription(queryResults, {
    ...options,
    document: MyOrders_CoveredIndicationChangedDocument,
  });
};

import { makeMockResponse } from '../../../../graphql/mock-response';

export const makeMyOrders_CoveredIndicationsMockResponse = makeMockResponse<
  MyOrders_CoveredIndicationsQueryVariables,
  MyOrders_CoveredIndicationsQuery
>(MyOrders_CoveredIndicationsDocument);

export const makeMyOrders_GridCoveredAttestationStatusesMockResponse = makeMockResponse<
  MyOrders_GridCoveredAttestationStatusesQueryVariables,
  MyOrders_GridCoveredAttestationStatusesQuery
>(MyOrders_GridCoveredAttestationStatusesDocument);

export const makeMyOrders_LiveOfferingsListMockResponse = makeMockResponse<
  MyOrders_LiveOfferingsListQueryVariables,
  MyOrders_LiveOfferingsListQuery
>(MyOrders_LiveOfferingsListDocument);

export const makeMyOrders_PricedOfferingsListMockResponse = makeMockResponse<
  MyOrders_PricedOfferingsListQueryVariables,
  MyOrders_PricedOfferingsListQuery
>(MyOrders_PricedOfferingsListDocument);

export const makeMyOrders_ProspectusDeliverySummariesMockResponse = makeMockResponse<
  MyOrders_ProspectusDeliverySummariesQueryVariables,
  MyOrders_ProspectusDeliverySummariesQuery
>(MyOrders_ProspectusDeliverySummariesDocument);

export const makeMyOrders_PublishedOfferingsListMockResponse = makeMockResponse<
  MyOrders_PublishedOfferingsListQueryVariables,
  MyOrders_PublishedOfferingsListQuery
>(MyOrders_PublishedOfferingsListDocument);
