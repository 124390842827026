/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SalesCredits_InvestorHistoryItemPartsFragment = {
  readonly __typename?: 'SalesCreditInvestorHistoryItem';
  readonly id: string;
  readonly salesCreditId: string;
  readonly salesCreditVersion: string;
  readonly salesCreditStatus: Types.SalesCreditReleaseStatus;
  readonly statusDetails?: string | null;
  readonly createdByUserName: string;
  readonly createdAt: string;
};

export type SalesCredits_InvestorHistoryQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  investorCmgEntityKey: Types.Scalars['String'];
}>;

export type SalesCredits_InvestorHistoryQuery = {
  readonly __typename?: 'Query';
  readonly salesCreditsInvestorHistory: ReadonlyArray<{
    readonly __typename?: 'SalesCreditInvestorHistoryItem';
    readonly id: string;
    readonly salesCreditId: string;
    readonly salesCreditVersion: string;
    readonly salesCreditStatus: Types.SalesCreditReleaseStatus;
    readonly statusDetails?: string | null;
    readonly createdByUserName: string;
    readonly createdAt: string;
  }>;
};

export const SalesCredits_InvestorHistoryItemPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SalesCredits_InvestorHistoryItemParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SalesCreditInvestorHistoryItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salesCreditId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salesCreditVersion' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salesCreditStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'statusDetails' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdByUserName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const SalesCredits_InvestorHistoryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SalesCredits_InvestorHistory' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'investorCmgEntityKey' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'salesCreditsInvestorHistory' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'investorCmgEntityKey' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'investorCmgEntityKey' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SalesCredits_InvestorHistoryItemParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SalesCredits_InvestorHistoryItemParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SalesCreditInvestorHistoryItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salesCreditId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salesCreditVersion' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salesCreditStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'statusDetails' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdByUserName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useSalesCredits_InvestorHistoryQuery__
 *
 * To run a query within a React component, call `useSalesCredits_InvestorHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesCredits_InvestorHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesCredits_InvestorHistoryQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      investorCmgEntityKey: // value for 'investorCmgEntityKey'
 *   },
 * });
 */
export function useSalesCredits_InvestorHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    SalesCredits_InvestorHistoryQuery,
    SalesCredits_InvestorHistoryQueryVariables
  > &
    ({ variables: SalesCredits_InvestorHistoryQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SalesCredits_InvestorHistoryQuery,
    SalesCredits_InvestorHistoryQueryVariables
  >(SalesCredits_InvestorHistoryDocument, options);
}
export function useSalesCredits_InvestorHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SalesCredits_InvestorHistoryQuery,
    SalesCredits_InvestorHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SalesCredits_InvestorHistoryQuery,
    SalesCredits_InvestorHistoryQueryVariables
  >(SalesCredits_InvestorHistoryDocument, options);
}
export function useSalesCredits_InvestorHistorySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        SalesCredits_InvestorHistoryQuery,
        SalesCredits_InvestorHistoryQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SalesCredits_InvestorHistoryQuery,
    SalesCredits_InvestorHistoryQueryVariables
  >(SalesCredits_InvestorHistoryDocument, options);
}
export type SalesCredits_InvestorHistoryQueryHookResult = ReturnType<
  typeof useSalesCredits_InvestorHistoryQuery
>;
export type SalesCredits_InvestorHistoryLazyQueryHookResult = ReturnType<
  typeof useSalesCredits_InvestorHistoryLazyQuery
>;
export type SalesCredits_InvestorHistorySuspenseQueryHookResult = ReturnType<
  typeof useSalesCredits_InvestorHistorySuspenseQuery
>;
export type SalesCredits_InvestorHistoryQueryResult = Apollo.QueryResult<
  SalesCredits_InvestorHistoryQuery,
  SalesCredits_InvestorHistoryQueryVariables
>;

import { makeMockResponse } from '../../../../../../../graphql/mock-response';

export const makeSalesCredits_InvestorHistoryMockResponse = makeMockResponse<
  SalesCredits_InvestorHistoryQueryVariables,
  SalesCredits_InvestorHistoryQuery
>(SalesCredits_InvestorHistoryDocument);
