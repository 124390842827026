import type { QueryResult } from '@apollo/client';
import type { UUID } from '@cmg/common';
import { useDataGridContext } from '@cmg/data-grid';
import { useCallback, useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { Exact, Scalars } from '../../../../../graphql';
import type { OrderBook_InstitutionalDemandRouteQuery } from '../../graphql/__generated__';
import { useOrderBook_InstitutionalDemand_GridChangedSubscription } from '../../graphql/__generated__';
import { useRefetchDemandGridReactiveVar } from '../../hooks/useRefetchDemandGridReactiveVar';
import { getServerSideGroupKey, isServerSideGroup } from '../DemandGrid.model';
import type { DemandGridRowData } from '../types';

type RefetchRouteQueryType = QueryResult<
  OrderBook_InstitutionalDemandRouteQuery,
  Exact<{
    offeringId: Scalars['UUID'];
  }>
>['refetch'];

export type Props = Readonly<{
  offeringId: UUID;
  refetchRouteQuery: RefetchRouteQueryType;
}>;

export const useHandleInstitutionalGridChanged = ({ offeringId, refetchRouteQuery }: Props) => {
  const {
    refreshServerSide,
    isGridReady,
    isEditModeActive,
    getRowData,
    addEventListener,
    removeEventListener,
  } = useDataGridContext();

  const [hasPendingChanges, setHasPendingChanges] = useState(false);

  const handleGridChanged = useCallback(() => {
    /**
     * Do not refresh if user is editing a cell
     */
    if (isEditModeActive()) {
      !hasPendingChanges && setHasPendingChanges(true);
      return;
    }

    void refetchRouteQuery();

    /**
     * Refresh top level rows
     */
    refreshServerSide({ purge: false });

    /**
     * Refresh individual groups
     */
    const serverSideGroupKeys = getRowData<DemandGridRowData>().reduce<string[]>((acc, data) => {
      const groupKey = getServerSideGroupKey(data);

      if (isServerSideGroup(data) && groupKey) {
        acc.push(groupKey);
      }

      return acc;
    }, []);

    serverSideGroupKeys.forEach(rowId => {
      refreshServerSide({ purge: false, route: [rowId] });
    });
  }, [getRowData, hasPendingChanges, isEditModeActive, refetchRouteQuery, refreshServerSide]);

  const handleGridChangedDebounced = useDebouncedCallback(
    handleGridChanged,
    1_000 + Math.floor(Math.random() * 1_000),
    { maxWait: 4_000 + Math.floor(Math.random() * 1_000) }
  );

  const handleCellEditingStopped = useCallback(() => {
    if (hasPendingChanges) {
      handleGridChanged();
      setHasPendingChanges(false);
    }
  }, [handleGridChanged, hasPendingChanges]);

  useEffect(() => {
    if (!isGridReady) {
      return;
    }

    addEventListener('cellEditingStopped', handleCellEditingStopped);

    return () => {
      removeEventListener('cellEditingStopped', handleCellEditingStopped);
    };
  }, [addEventListener, handleCellEditingStopped, removeEventListener, isGridReady]);

  /**
   * Refetch data immediately when user performs an action that affects demand grid change.
   */
  useRefetchDemandGridReactiveVar({ onRefetch: handleGridChanged });

  /**
   * Refetch data when demand grid change done by another users is detected.
   */
  return useOrderBook_InstitutionalDemand_GridChangedSubscription({
    variables: { offeringId },
    skip: !isGridReady,
    onData: handleGridChangedDebounced,
    // https://www.apollographql.com/docs/react/data/subscriptions/#subscriptionhookoptions-interface-ignoreresults
    ignoreResults: true,
  });
};
