import { CellRenderers, formatters } from '@cmg/data-grid';

import { ProspectusDeliveryStatus } from '../../../../../../graphql';
import { ProspectusDeliveryStatusLabel } from '../../../../../offering-side-panel/order-book/compliance/context/hooks/prospectus-delivery/ProspectusDelivery.model';
import type { OrderBook_InstitutionalDemand_GridRowPartsFragment } from '../../../graphql/__generated__';
import type { DemandGridServerColDef } from '../../types';
import { getCellRendererSelector, getValueFormatter } from '../columns.model';

/**
 * Exported for testing purposes.
 */
export const getStatusColor = (
  _value: string,
  data: OrderBook_InstitutionalDemand_GridRowPartsFragment | undefined
) => {
  const { overallStatus = ProspectusDeliveryStatus.NotSent, sentEmailCount = 0 } =
    data?.prospectusDeliverySummary ?? {};

  switch (overallStatus) {
    case ProspectusDeliveryStatus.Sent:
      return 'success';
    case ProspectusDeliveryStatus.Failed:
      return 'error';
    case ProspectusDeliveryStatus.InProgress:
      return sentEmailCount > 0 ? 'success' : 'disabled';
    default:
      return 'disabled';
  }
};

/**
 * Exported for testing purposes.
 */
export const cellRendererSelector = getCellRendererSelector<string>({
  gridRow: {
    component: CellRenderers.DotStatusCellRenderer,
    params: {
      getStatusColor,
    },
  },
});

export const valueFormatter = getValueFormatter<string>({
  gridRow: ({ data }) => {
    const {
      overallStatusDisplayValue = ProspectusDeliveryStatusLabel[ProspectusDeliveryStatus.NotSent],
    } = data?.prospectusDeliverySummary ?? {};

    return overallStatusDisplayValue;
  },
});

const prospectusColDef: DemandGridServerColDef<string> = {
  field: 'prospectusDeliverySummary.overallStatusDisplayValue',
  headerName: 'Prospectus Contacts ⓘ',
  headerTooltip: 'Investor contacts that have been sent prospectus documents.',
  enableRowGroup: true,
  cellRendererSelector,
  filter: 'agSetColumnFilter',
  server: {
    filterFieldPath: 'prospectusDeliverySummary.overallStatus',
  },
  filterParams: {
    values: Object.values(ProspectusDeliveryStatus),
    valueFormatter: formatters.enumValueFormatter,
  },
};

export default prospectusColDef;
