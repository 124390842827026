/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RegulatoryFilings_DsFilingDetailQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  filingId: Types.Scalars['UUID'];
}>;

export type RegulatoryFilings_DsFilingDetailQuery = {
  readonly __typename?: 'Query';
  readonly offering: {
    readonly __typename?: 'Offering';
    readonly id: string;
    readonly regulatoryFilings: {
      readonly __typename?: 'RegulatoryFilings';
      readonly id: string;
      readonly DSFiling: {
        readonly __typename?: 'DSFiling';
        readonly id: string;
        readonly status: Types.FilingStatus;
        readonly submittedDateTime: string;
        readonly firmName: string;
        readonly firmCrdNumber: string;
        readonly firmMpid: string;
        readonly contactName: string;
        readonly contactTitle: string;
        readonly telephoneNumber: string;
        readonly emailAddress: string;
        readonly dealStatus: Types.DealStatus;
        readonly finraErrorMessages: ReadonlyArray<string>;
        readonly issuerName: string;
        readonly issuerSymbol: string;
        readonly firmDealId: string;
        readonly formUniqueId: string;
      };
      readonly notifications: ReadonlyArray<{
        readonly __typename?: 'RegulatoryFilingNotificationMessage';
        readonly id: string;
        readonly status: Types.NotificationMessageStatus;
        readonly sentAt?: string | null;
        readonly distributionList: ReadonlyArray<string>;
        readonly bccEmailAddresses: ReadonlyArray<string>;
        readonly managerSnapshot: {
          readonly __typename?: 'FinraManagerSnapshot';
          readonly id: string;
          readonly cmgEntityKey: string;
          readonly role: Types.ManagerRole;
          readonly firmName: string;
        };
      }>;
    };
  };
};

export type RegulatoryFilings_DsFilingsQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type RegulatoryFilings_DsFilingsQuery = {
  readonly __typename?: 'Query';
  readonly offering: {
    readonly __typename?: 'Offering';
    readonly id: string;
    readonly regulatoryFilings: {
      readonly __typename?: 'RegulatoryFilings';
      readonly id: string;
      readonly DSFilings: ReadonlyArray<{
        readonly __typename?: 'BaseRegulatoryFiling';
        readonly id: string;
        readonly status: Types.FilingStatus;
        readonly submittedDateTime: string;
      }>;
    };
  };
};

export type RegulatoryFilings_DsFilingPartsFragment = {
  readonly __typename?: 'DSFiling';
  readonly id: string;
  readonly status: Types.FilingStatus;
  readonly submittedDateTime: string;
  readonly firmName: string;
  readonly firmCrdNumber: string;
  readonly firmMpid: string;
  readonly contactName: string;
  readonly contactTitle: string;
  readonly telephoneNumber: string;
  readonly emailAddress: string;
  readonly dealStatus: Types.DealStatus;
  readonly finraErrorMessages: ReadonlyArray<string>;
  readonly issuerName: string;
  readonly issuerSymbol: string;
  readonly firmDealId: string;
  readonly formUniqueId: string;
};

export type RegulatoryFilings_ResubmitDsFilingMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  filingId: Types.Scalars['UUID'];
}>;

export type RegulatoryFilings_ResubmitDsFilingMutation = {
  readonly __typename?: 'Mutation';
  readonly resubmitDSFiling: {
    readonly __typename?: 'DSFiling';
    readonly id: string;
    readonly status: Types.FilingStatus;
    readonly submittedDateTime: string;
    readonly firmName: string;
    readonly firmCrdNumber: string;
    readonly firmMpid: string;
    readonly contactName: string;
    readonly contactTitle: string;
    readonly telephoneNumber: string;
    readonly emailAddress: string;
    readonly dealStatus: Types.DealStatus;
    readonly finraErrorMessages: ReadonlyArray<string>;
    readonly issuerName: string;
    readonly issuerSymbol: string;
    readonly firmDealId: string;
    readonly formUniqueId: string;
  };
};

export type RegulatoryFilings_SubmitDsFilingMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  input: Types.CreateDsFilingInput;
}>;

export type RegulatoryFilings_SubmitDsFilingMutation = {
  readonly __typename?: 'Mutation';
  readonly submitDSFiling: {
    readonly __typename?: 'DSFiling';
    readonly id: string;
    readonly status: Types.FilingStatus;
    readonly submittedDateTime: string;
    readonly firmName: string;
    readonly firmCrdNumber: string;
    readonly firmMpid: string;
    readonly contactName: string;
    readonly contactTitle: string;
    readonly telephoneNumber: string;
    readonly emailAddress: string;
    readonly dealStatus: Types.DealStatus;
    readonly finraErrorMessages: ReadonlyArray<string>;
    readonly issuerName: string;
    readonly issuerSymbol: string;
    readonly firmDealId: string;
    readonly formUniqueId: string;
  };
};

export const RegulatoryFilings_DsFilingPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RegulatoryFilings_DsFilingParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DSFiling' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'submittedDateTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmCrdNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmMpid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'telephoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dealStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'finraErrorMessages' } },
          { kind: 'Field', name: { kind: 'Name', value: 'issuerName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'issuerSymbol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmDealId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formUniqueId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const RegulatoryFilings_DsFilingDetailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RegulatoryFilings_DsFilingDetail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filingId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'offering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'regulatoryFilings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'DSFiling' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'filingId' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'filingId' } },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'RegulatoryFilings_DsFilingParts' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'notifications' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'filingId' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'filingId' } },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'RegulatoryFilings_FilingNotificationMessageParts',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RegulatoryFilings_DsFilingParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DSFiling' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'submittedDateTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmCrdNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmMpid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'telephoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dealStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'finraErrorMessages' } },
          { kind: 'Field', name: { kind: 'Name', value: 'issuerName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'issuerSymbol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmDealId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formUniqueId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RegulatoryFilings_FilingNotificationMessageParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RegulatoryFilingNotificationMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sentAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'distributionList' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bccEmailAddresses' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'managerSnapshot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RegulatoryFilings_FinraManagerSnapshotParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RegulatoryFilings_FinraManagerSnapshotParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FinraManagerSnapshot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useRegulatoryFilings_DsFilingDetailQuery__
 *
 * To run a query within a React component, call `useRegulatoryFilings_DsFilingDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegulatoryFilings_DsFilingDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegulatoryFilings_DsFilingDetailQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      filingId: // value for 'filingId'
 *   },
 * });
 */
export function useRegulatoryFilings_DsFilingDetailQuery(
  baseOptions: Apollo.QueryHookOptions<
    RegulatoryFilings_DsFilingDetailQuery,
    RegulatoryFilings_DsFilingDetailQueryVariables
  > &
    (
      | { variables: RegulatoryFilings_DsFilingDetailQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RegulatoryFilings_DsFilingDetailQuery,
    RegulatoryFilings_DsFilingDetailQueryVariables
  >(RegulatoryFilings_DsFilingDetailDocument, options);
}
export function useRegulatoryFilings_DsFilingDetailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RegulatoryFilings_DsFilingDetailQuery,
    RegulatoryFilings_DsFilingDetailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RegulatoryFilings_DsFilingDetailQuery,
    RegulatoryFilings_DsFilingDetailQueryVariables
  >(RegulatoryFilings_DsFilingDetailDocument, options);
}
export function useRegulatoryFilings_DsFilingDetailSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        RegulatoryFilings_DsFilingDetailQuery,
        RegulatoryFilings_DsFilingDetailQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    RegulatoryFilings_DsFilingDetailQuery,
    RegulatoryFilings_DsFilingDetailQueryVariables
  >(RegulatoryFilings_DsFilingDetailDocument, options);
}
export type RegulatoryFilings_DsFilingDetailQueryHookResult = ReturnType<
  typeof useRegulatoryFilings_DsFilingDetailQuery
>;
export type RegulatoryFilings_DsFilingDetailLazyQueryHookResult = ReturnType<
  typeof useRegulatoryFilings_DsFilingDetailLazyQuery
>;
export type RegulatoryFilings_DsFilingDetailSuspenseQueryHookResult = ReturnType<
  typeof useRegulatoryFilings_DsFilingDetailSuspenseQuery
>;
export type RegulatoryFilings_DsFilingDetailQueryResult = Apollo.QueryResult<
  RegulatoryFilings_DsFilingDetailQuery,
  RegulatoryFilings_DsFilingDetailQueryVariables
>;
export const RegulatoryFilings_DsFilingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RegulatoryFilings_DsFilings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'offering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'regulatoryFilings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'DSFilings' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'submittedDateTime' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useRegulatoryFilings_DsFilingsQuery__
 *
 * To run a query within a React component, call `useRegulatoryFilings_DsFilingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegulatoryFilings_DsFilingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegulatoryFilings_DsFilingsQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useRegulatoryFilings_DsFilingsQuery(
  baseOptions: Apollo.QueryHookOptions<
    RegulatoryFilings_DsFilingsQuery,
    RegulatoryFilings_DsFilingsQueryVariables
  > &
    ({ variables: RegulatoryFilings_DsFilingsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RegulatoryFilings_DsFilingsQuery,
    RegulatoryFilings_DsFilingsQueryVariables
  >(RegulatoryFilings_DsFilingsDocument, options);
}
export function useRegulatoryFilings_DsFilingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RegulatoryFilings_DsFilingsQuery,
    RegulatoryFilings_DsFilingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RegulatoryFilings_DsFilingsQuery,
    RegulatoryFilings_DsFilingsQueryVariables
  >(RegulatoryFilings_DsFilingsDocument, options);
}
export function useRegulatoryFilings_DsFilingsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        RegulatoryFilings_DsFilingsQuery,
        RegulatoryFilings_DsFilingsQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    RegulatoryFilings_DsFilingsQuery,
    RegulatoryFilings_DsFilingsQueryVariables
  >(RegulatoryFilings_DsFilingsDocument, options);
}
export type RegulatoryFilings_DsFilingsQueryHookResult = ReturnType<
  typeof useRegulatoryFilings_DsFilingsQuery
>;
export type RegulatoryFilings_DsFilingsLazyQueryHookResult = ReturnType<
  typeof useRegulatoryFilings_DsFilingsLazyQuery
>;
export type RegulatoryFilings_DsFilingsSuspenseQueryHookResult = ReturnType<
  typeof useRegulatoryFilings_DsFilingsSuspenseQuery
>;
export type RegulatoryFilings_DsFilingsQueryResult = Apollo.QueryResult<
  RegulatoryFilings_DsFilingsQuery,
  RegulatoryFilings_DsFilingsQueryVariables
>;
export const RegulatoryFilings_ResubmitDsFilingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RegulatoryFilings_ResubmitDsFiling' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filingId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resubmitDSFiling' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filingId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filingId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RegulatoryFilings_DsFilingParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RegulatoryFilings_DsFilingParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DSFiling' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'submittedDateTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmCrdNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmMpid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'telephoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dealStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'finraErrorMessages' } },
          { kind: 'Field', name: { kind: 'Name', value: 'issuerName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'issuerSymbol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmDealId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formUniqueId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type RegulatoryFilings_ResubmitDsFilingMutationFn = Apollo.MutationFunction<
  RegulatoryFilings_ResubmitDsFilingMutation,
  RegulatoryFilings_ResubmitDsFilingMutationVariables
>;

/**
 * __useRegulatoryFilings_ResubmitDsFilingMutation__
 *
 * To run a mutation, you first call `useRegulatoryFilings_ResubmitDsFilingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegulatoryFilings_ResubmitDsFilingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [regulatoryFilingsResubmitDsFilingMutation, { data, loading, error }] = useRegulatoryFilings_ResubmitDsFilingMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      filingId: // value for 'filingId'
 *   },
 * });
 */
export function useRegulatoryFilings_ResubmitDsFilingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegulatoryFilings_ResubmitDsFilingMutation,
    RegulatoryFilings_ResubmitDsFilingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RegulatoryFilings_ResubmitDsFilingMutation,
    RegulatoryFilings_ResubmitDsFilingMutationVariables
  >(RegulatoryFilings_ResubmitDsFilingDocument, options);
}
export type RegulatoryFilings_ResubmitDsFilingMutationHookResult = ReturnType<
  typeof useRegulatoryFilings_ResubmitDsFilingMutation
>;
export type RegulatoryFilings_ResubmitDsFilingMutationResult =
  Apollo.MutationResult<RegulatoryFilings_ResubmitDsFilingMutation>;
export type RegulatoryFilings_ResubmitDsFilingMutationOptions = Apollo.BaseMutationOptions<
  RegulatoryFilings_ResubmitDsFilingMutation,
  RegulatoryFilings_ResubmitDsFilingMutationVariables
>;
export const RegulatoryFilings_SubmitDsFilingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RegulatoryFilings_SubmitDsFiling' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateDSFilingInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submitDSFiling' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RegulatoryFilings_DsFilingParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RegulatoryFilings_DsFilingParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DSFiling' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'submittedDateTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmCrdNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmMpid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'telephoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dealStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'finraErrorMessages' } },
          { kind: 'Field', name: { kind: 'Name', value: 'issuerName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'issuerSymbol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firmDealId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formUniqueId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type RegulatoryFilings_SubmitDsFilingMutationFn = Apollo.MutationFunction<
  RegulatoryFilings_SubmitDsFilingMutation,
  RegulatoryFilings_SubmitDsFilingMutationVariables
>;

/**
 * __useRegulatoryFilings_SubmitDsFilingMutation__
 *
 * To run a mutation, you first call `useRegulatoryFilings_SubmitDsFilingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegulatoryFilings_SubmitDsFilingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [regulatoryFilingsSubmitDsFilingMutation, { data, loading, error }] = useRegulatoryFilings_SubmitDsFilingMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegulatoryFilings_SubmitDsFilingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegulatoryFilings_SubmitDsFilingMutation,
    RegulatoryFilings_SubmitDsFilingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RegulatoryFilings_SubmitDsFilingMutation,
    RegulatoryFilings_SubmitDsFilingMutationVariables
  >(RegulatoryFilings_SubmitDsFilingDocument, options);
}
export type RegulatoryFilings_SubmitDsFilingMutationHookResult = ReturnType<
  typeof useRegulatoryFilings_SubmitDsFilingMutation
>;
export type RegulatoryFilings_SubmitDsFilingMutationResult =
  Apollo.MutationResult<RegulatoryFilings_SubmitDsFilingMutation>;
export type RegulatoryFilings_SubmitDsFilingMutationOptions = Apollo.BaseMutationOptions<
  RegulatoryFilings_SubmitDsFilingMutation,
  RegulatoryFilings_SubmitDsFilingMutationVariables
>;

import { makeMockResponse } from '../../../../../../graphql/mock-response';

export const makeRegulatoryFilings_DsFilingDetailMockResponse = makeMockResponse<
  RegulatoryFilings_DsFilingDetailQueryVariables,
  RegulatoryFilings_DsFilingDetailQuery
>(RegulatoryFilings_DsFilingDetailDocument);

export const makeRegulatoryFilings_DsFilingsMockResponse = makeMockResponse<
  RegulatoryFilings_DsFilingsQueryVariables,
  RegulatoryFilings_DsFilingsQuery
>(RegulatoryFilings_DsFilingsDocument);
