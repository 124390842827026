import { getOperationName } from '@apollo/client/utilities';
import { UUID } from '@cmg/common';
import { SnackbarManager } from '@cmg/design-system';
import React from 'react';

import { InterestLevelInput } from '../../../../../../../../../graphql';
import { pendingOrderBookGridForcedRefreshVar } from '../../../../../../../../order-book';
import { InstitutionalDemandGrid_SummaryDocument } from '../../../../../../../../order-book/institutional-demand/components/institutional-demand-grid/graphql';
import { refetchDemandGridReactiveVar } from '../../../../../../../../order-book/institutional-demand-v2/hooks/useRefetchDemandGridReactiveVar';
import { SyndicateIndicationQuery } from '../../../../../context/hooks/syndicate/__generated__';
import { IndicationForm_IndicationAcknowledgementsDocument } from '../../../../indication-form/components/indication-details/components/acknowledgement/graphql/__generated__';
import { IndicationFormValues } from '../../../common/utils/IndicationSectionValidation';
import { useManageIndication_UpdateIndicationMutation } from '../../../graphql/__generated__';

export const refetchQueries = [
  getOperationName(InstitutionalDemandGrid_SummaryDocument)!,
  getOperationName(IndicationForm_IndicationAcknowledgementsDocument)!,
];

export const useUpdateInstitutionalIndication = (
  offeringId: UUID,
  indication: SyndicateIndicationQuery['basicPublishedOffering']['orderBook']['institutionalIndication'],
  onFinishEditing: () => void
) => {
  const [updateInstitutionalIndication] = useManageIndication_UpdateIndicationMutation();

  const handleUpdateInstitutionalIndication = React.useCallback(
    async (values: IndicationFormValues) => {
      try {
        await updateInstitutionalIndication({
          variables: {
            offeringId,
            indicationId: indication!.id,
            input: {
              instrumentId: values.instrumentId,
              currencyCode: values.currencyCode,
              // leftover from legacy code
              investorInformation: {
                contactName: indication?.investorInformation?.contactName ?? '',
                contactEmail: indication?.investorInformation?.contactEmail ?? '',
              },
              interestLevels: values.interestLevels.map<InterestLevelInput>(
                ({ interestQuantity, interestUnit, limitPrice, limitType }) => ({
                  interestUnit: interestUnit!,
                  interestQuantity: interestQuantity!,
                  limitType,
                  limitPrice,
                })
              ),
            },
          },
          awaitRefetchQueries: true,
          refetchQueries,
        });
        SnackbarManager.success('Successfully updated Indication');
        pendingOrderBookGridForcedRefreshVar(true);
        refetchDemandGridReactiveVar(true);
        onFinishEditing();
      } catch {
        SnackbarManager.error('Failed to update Indication');
      }
    },
    [indication, offeringId, onFinishEditing, updateInstitutionalIndication]
  );

  return [handleUpdateInstitutionalIndication];
};
