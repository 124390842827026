import { ApolloError } from '@apollo/client';
import { numericUtil } from '@cmg/common';
import { Alert, AlertTitle, Stack, Typography } from '@cmg/design-system';
import { FormikProvider, useFormik, validateYupSchema, yupToFormErrors } from 'formik';
import React from 'react';

import ServerErrorsBanner from '../../../common/components/indicators/server-error/ServerErrorsBanner';
import FormikUnsavedChangesGuard from '../../../common/components/overlays/formik-unsaved-changes-guard/FormikUnsavedChangesGuard';
import { UnderwritingTermsFeesAndDiscounts } from '../../../graphql';
import { DiscountsAndFeesActionPanelSection } from './components/DiscountsAndFeesActionPanelSection';
import { InformationBlock } from './components/InformationBlock';
import {
  GridLayoutContainer,
  MainFormGridArea,
  OverallotmentDetailGridArea,
  UnderwritingDiscountGridArea,
} from './components/ResponsiveContentLayout';
import { RestInformationBlock } from './components/RestInformationBlock';
import { UnderwritingTermsDiscountsAndFeesForm } from './components/UnderwritingTermsDiscountsAndFeesForm';
import { FinalFilingTerms } from './hooks/useOfferingData';
import { useUpdateUnderwritingTermsDiscountsFees } from './hooks/useUpdateUnderwritingTermsDiscountsFees';
import {
  getFormInitialValues,
  TotalCalculations,
  UnderwritingDiscountsAndFeesSchema,
} from './UnderwritingTermsDiscountsAndFeesSection.model';

type FinalFilingTermsFormState = {
  offerPrice: number | null | undefined;
};

export type FormFields = FinalFilingTermsFormState & UnderwritingTermsFeesAndDiscounts;

export type UnderwritingTermsDiscountsAndFeesFormValues = FormFields & TotalCalculations;

type UnderwritingTermsDiscountsAndFeesSectionProps = {
  offeringId: string;
  serverErrors: (ApolloError | undefined)[];
  finalTermsData?: FinalFilingTerms;
  underwritingDiscountsFeesData?: UnderwritingTermsFeesAndDiscounts;
  currencyCode: string;
};

export enum FormStatus {
  ReadOnly = 'read-only',
  EditingPercentage = 'editing-percentage',
  EditingCurrency = 'editing-currency',
}

export const UnderwritingTermsDiscountsAndFeesSection: React.FC<
  UnderwritingTermsDiscountsAndFeesSectionProps
> = ({ offeringId, serverErrors, finalTermsData, underwritingDiscountsFeesData, currencyCode }) => {
  const submitClicks = React.useRef(0);
  const { updateUnderwritingTermsDiscountsFees, mutationError } =
    useUpdateUnderwritingTermsDiscountsFees();

  const formik = useFormik<UnderwritingTermsDiscountsAndFeesFormValues>({
    validate: async values => {
      try {
        await validateYupSchema(values, UnderwritingDiscountsAndFeesSchema, false, {
          values,
          submitClicks,
        });

        return {};
      } catch (e) {
        return yupToFormErrors(e);
      }
    },
    initialValues: getFormInitialValues({
      offerPrice: finalTermsData?.offerPrice || null,
      underwritingDiscountsFeesData,
    }),
    onSubmit: async (values, actions) => {
      await updateUnderwritingTermsDiscountsFees({
        values,
        offeringId,
        editingStatus: formik.status,
      });
      submitClicks.current = 0;
      actions.resetForm({
        values,
      });
    },
    initialStatus: FormStatus.ReadOnly,
  });

  const saveButtonClick = React.useCallback(() => {
    submitClicks.current += 1;
    formik.handleSubmit();
  }, [submitClicks, formik]);

  const editButtonClick = React.useCallback(() => {
    formik.setStatus(FormStatus.EditingPercentage);
  }, [formik]);

  const totalErrors = [
    formik.errors.totalBaseGrossSpreadAllocationPercentageDiff ||
      formik.errors.totalBaseGrossSpreadAllocationDiff,
    formik.errors.totalIncentiveGrossSpreadAllocationPercentageDiff ||
      formik.errors.totalIncentiveGrossSpreadAllocationDiff,
  ].filter(Boolean);

  const showBannerErrors = totalErrors.length > 0 && formik.submitCount > 0;

  const allServerErrors = [...serverErrors, mutationError];

  return (
    <FormikProvider value={formik}>
      <FormikUnsavedChangesGuard>
        <DiscountsAndFeesActionPanelSection
          currencyCode={currencyCode}
          submitClicksRef={submitClicks}
          handleSubmit={saveButtonClick}
          handleEdit={editButtonClick}
        >
          <Stack gap={3}>
            {showBannerErrors && (
              <Alert severity="error">
                <AlertTitle>An entry has invalid value for the following fields:</AlertTitle>
                <Stack gap={1}>
                  {totalErrors.map((error, index) => (
                    <Typography key={index} variant="body2">
                      {error}
                    </Typography>
                  ))}
                </Stack>
              </Alert>
            )}
            {allServerErrors &&
              allServerErrors.map(
                error => error && <ServerErrorsBanner error={error} key={error.name} />
              )}
            <GridLayoutContainer gap={2}>
              <MainFormGridArea>
                <UnderwritingTermsDiscountsAndFeesForm currencyCode={currencyCode} />
              </MainFormGridArea>
              <UnderwritingDiscountGridArea>
                <InformationBlock
                  title="Underwriting Discount"
                  body={[
                    {
                      rowLabel: 'Offer Price',
                      rowValue: numericUtil.getDisplayValueForCurrency(
                        formik.values.offerPrice,
                        6,
                        currencyCode
                      ),
                    },
                    {
                      rowLabel: 'Total Gross Spread',
                      rowValue: numericUtil.getDisplayValueForCurrency(
                        formik.values.grossSpreadTotal,
                        6,
                        currencyCode
                      ),
                    },
                  ]}
                  footer={{
                    rowLabel: 'Net Price',
                    rowValue: numericUtil.getDisplayValueForCurrency(
                      formik.values.netPrice,
                      6,
                      currencyCode
                    ),
                  }}
                />
              </UnderwritingDiscountGridArea>
              <OverallotmentDetailGridArea>
                <InformationBlock
                  title="Overallotment Details"
                  body={[
                    {
                      rowLabel: 'Ovlt. Authorized',
                      rowValue: numericUtil.getDisplayValueForInteger(
                        finalTermsData?.totalSharesOverAllotmentAuthorized
                      ),
                    },
                    {
                      rowLabel: 'Ovlt. Exercised',
                      rowValue: numericUtil.getDisplayValueForInteger(
                        finalTermsData?.totalSharesOverAllotmentExercised
                      ),
                    },
                  ]}
                  footer={{
                    rowLabel: '% Exercised',
                    rowValue: numericUtil.getDisplayValueForPercents(
                      finalTermsData?.totalSharesOverAllotmentAuthorized &&
                        finalTermsData?.totalSharesOverAllotmentExercised
                        ? finalTermsData.totalSharesOverAllotmentExercised /
                            finalTermsData.totalSharesOverAllotmentAuthorized
                        : null
                    ),
                  }}
                />
              </OverallotmentDetailGridArea>
              <RestInformationBlock
                reallowance={formik.values.reallowance}
                sharesExcludedFromGrossSpread={formik.values.sharesExcludedFromGrossSpread}
                currencyCode={currencyCode}
              />
            </GridLayoutContainer>
          </Stack>
        </DiscountsAndFeesActionPanelSection>
      </FormikUnsavedChangesGuard>
    </FormikProvider>
  );
};
