/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OfferingSetup_EntitlementsConfigurationQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type OfferingSetup_EntitlementsConfigurationQuery = {
  readonly __typename?: 'Query';
  readonly entitlementsConfiguration: ReadonlyArray<{
    readonly __typename?: 'EntitlementsConfiguration';
    readonly resource: Types.Resource;
    readonly boundedContext: Types.BoundedContext;
    readonly displayName: string;
    readonly description: string;
    readonly actionConfigurations: ReadonlyArray<{
      readonly __typename?: 'ActionConfiguration';
      readonly action: Types.Action;
      readonly description: string;
      readonly managerResponsibilityRestriction?: Types.ManagerResponsibility | null;
      readonly managerRoleRestriction?: Types.ManagerRole | null;
    }>;
  }>;
};

export type OfferingSetup_SyndicateEntitlementsPartsFragment = {
  readonly __typename?: 'SyndicateEntitlements';
  readonly cmgEntityKey: string;
  readonly entitlements: ReadonlyArray<string>;
  readonly recordStatus: Types.RecordStatus;
};

export type OfferingSetup_OfferingEntitlementsQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type OfferingSetup_OfferingEntitlementsQuery = {
  readonly __typename?: 'Query';
  readonly offering: {
    readonly __typename?: 'Offering';
    readonly id: string;
    readonly syndicate: {
      readonly __typename?: 'Syndicate';
      readonly id: string;
      readonly managers: ReadonlyArray<{
        readonly __typename?: 'Manager';
        readonly id: string;
        readonly cmgEntityKey: string;
        readonly firmName: string;
        readonly role: Types.ManagerRole;
      }>;
    };
    readonly entitlements: {
      readonly __typename?: 'Entitlements';
      readonly id: string;
      readonly version: string;
      readonly syndicateEntitlements: ReadonlyArray<{
        readonly __typename?: 'SyndicateEntitlements';
        readonly cmgEntityKey: string;
        readonly entitlements: ReadonlyArray<string>;
        readonly recordStatus: Types.RecordStatus;
      }>;
    };
  };
};

export const OfferingSetup_SyndicateEntitlementsPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_SyndicateEntitlementsParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SyndicateEntitlements' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'entitlements' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recordStatus' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const OfferingSetup_EntitlementsConfigurationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'OfferingSetup_EntitlementsConfiguration' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entitlementsConfiguration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'resource' } },
                { kind: 'Field', name: { kind: 'Name', value: 'boundedContext' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'actionConfigurations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'managerResponsibilityRestriction' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'managerRoleRestriction' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useOfferingSetup_EntitlementsConfigurationQuery__
 *
 * To run a query within a React component, call `useOfferingSetup_EntitlementsConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfferingSetup_EntitlementsConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferingSetup_EntitlementsConfigurationQuery({
 *   variables: {
 *   },
 * });
 */
export function useOfferingSetup_EntitlementsConfigurationQuery(
  baseOptions?: Apollo.QueryHookOptions<
    OfferingSetup_EntitlementsConfigurationQuery,
    OfferingSetup_EntitlementsConfigurationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OfferingSetup_EntitlementsConfigurationQuery,
    OfferingSetup_EntitlementsConfigurationQueryVariables
  >(OfferingSetup_EntitlementsConfigurationDocument, options);
}
export function useOfferingSetup_EntitlementsConfigurationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OfferingSetup_EntitlementsConfigurationQuery,
    OfferingSetup_EntitlementsConfigurationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OfferingSetup_EntitlementsConfigurationQuery,
    OfferingSetup_EntitlementsConfigurationQueryVariables
  >(OfferingSetup_EntitlementsConfigurationDocument, options);
}
export function useOfferingSetup_EntitlementsConfigurationSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        OfferingSetup_EntitlementsConfigurationQuery,
        OfferingSetup_EntitlementsConfigurationQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    OfferingSetup_EntitlementsConfigurationQuery,
    OfferingSetup_EntitlementsConfigurationQueryVariables
  >(OfferingSetup_EntitlementsConfigurationDocument, options);
}
export type OfferingSetup_EntitlementsConfigurationQueryHookResult = ReturnType<
  typeof useOfferingSetup_EntitlementsConfigurationQuery
>;
export type OfferingSetup_EntitlementsConfigurationLazyQueryHookResult = ReturnType<
  typeof useOfferingSetup_EntitlementsConfigurationLazyQuery
>;
export type OfferingSetup_EntitlementsConfigurationSuspenseQueryHookResult = ReturnType<
  typeof useOfferingSetup_EntitlementsConfigurationSuspenseQuery
>;
export type OfferingSetup_EntitlementsConfigurationQueryResult = Apollo.QueryResult<
  OfferingSetup_EntitlementsConfigurationQuery,
  OfferingSetup_EntitlementsConfigurationQueryVariables
>;
export const OfferingSetup_OfferingEntitlementsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'OfferingSetup_OfferingEntitlements' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'offering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'syndicate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'managers' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'firmName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'entitlements' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filters' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'recordStatus' },
                            value: { kind: 'EnumValue', value: 'EFFECTIVE' },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'syndicateEntitlements' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'OfferingSetup_SyndicateEntitlementsParts',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferingSetup_SyndicateEntitlementsParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SyndicateEntitlements' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'entitlements' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recordStatus' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useOfferingSetup_OfferingEntitlementsQuery__
 *
 * To run a query within a React component, call `useOfferingSetup_OfferingEntitlementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfferingSetup_OfferingEntitlementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferingSetup_OfferingEntitlementsQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useOfferingSetup_OfferingEntitlementsQuery(
  baseOptions: Apollo.QueryHookOptions<
    OfferingSetup_OfferingEntitlementsQuery,
    OfferingSetup_OfferingEntitlementsQueryVariables
  > &
    (
      | { variables: OfferingSetup_OfferingEntitlementsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OfferingSetup_OfferingEntitlementsQuery,
    OfferingSetup_OfferingEntitlementsQueryVariables
  >(OfferingSetup_OfferingEntitlementsDocument, options);
}
export function useOfferingSetup_OfferingEntitlementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OfferingSetup_OfferingEntitlementsQuery,
    OfferingSetup_OfferingEntitlementsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OfferingSetup_OfferingEntitlementsQuery,
    OfferingSetup_OfferingEntitlementsQueryVariables
  >(OfferingSetup_OfferingEntitlementsDocument, options);
}
export function useOfferingSetup_OfferingEntitlementsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        OfferingSetup_OfferingEntitlementsQuery,
        OfferingSetup_OfferingEntitlementsQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    OfferingSetup_OfferingEntitlementsQuery,
    OfferingSetup_OfferingEntitlementsQueryVariables
  >(OfferingSetup_OfferingEntitlementsDocument, options);
}
export type OfferingSetup_OfferingEntitlementsQueryHookResult = ReturnType<
  typeof useOfferingSetup_OfferingEntitlementsQuery
>;
export type OfferingSetup_OfferingEntitlementsLazyQueryHookResult = ReturnType<
  typeof useOfferingSetup_OfferingEntitlementsLazyQuery
>;
export type OfferingSetup_OfferingEntitlementsSuspenseQueryHookResult = ReturnType<
  typeof useOfferingSetup_OfferingEntitlementsSuspenseQuery
>;
export type OfferingSetup_OfferingEntitlementsQueryResult = Apollo.QueryResult<
  OfferingSetup_OfferingEntitlementsQuery,
  OfferingSetup_OfferingEntitlementsQueryVariables
>;

import { makeMockResponse } from '../../../../../../../graphql/mock-response';

export const makeOfferingSetup_EntitlementsConfigurationMockResponse = makeMockResponse<
  OfferingSetup_EntitlementsConfigurationQueryVariables,
  OfferingSetup_EntitlementsConfigurationQuery
>(OfferingSetup_EntitlementsConfigurationDocument);

export const makeOfferingSetup_OfferingEntitlementsMockResponse = makeMockResponse<
  OfferingSetup_OfferingEntitlementsQueryVariables,
  OfferingSetup_OfferingEntitlementsQuery
>(OfferingSetup_OfferingEntitlementsDocument);
