/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SyndicateWires_SendWireDialog_FirmDistributionListPartsFragment = {
  readonly __typename?: 'FirmDistributionList';
  readonly id: string;
  readonly cmgEntityKey: string;
  readonly distributionEmails: ReadonlyArray<string>;
};

export type SyndicateWires_SendWireDialog_FirmDistributionListsQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
  cmgEntityKeys: ReadonlyArray<Types.Scalars['String']> | Types.Scalars['String'];
}>;

export type SyndicateWires_SendWireDialog_FirmDistributionListsQuery = {
  readonly __typename?: 'Query';
  readonly syndicateWires: {
    readonly __typename?: 'SyndicateWires';
    readonly id: string;
    readonly firmDistributionLists: ReadonlyArray<{
      readonly __typename?: 'FirmDistributionList';
      readonly id: string;
      readonly cmgEntityKey: string;
      readonly distributionEmails: ReadonlyArray<string>;
    }>;
  };
};

export const SyndicateWires_SendWireDialog_FirmDistributionListPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_SendWireDialog_FirmDistributionListParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FirmDistributionList' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'distributionEmails' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const SyndicateWires_SendWireDialog_FirmDistributionListsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SyndicateWires_SendWireDialog_FirmDistributionLists' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKeys' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'syndicateWires' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'firmDistributionLists' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'cmgEntityKeys' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'cmgEntityKeys' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'SyndicateWires_SendWireDialog_FirmDistributionListParts',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SyndicateWires_SendWireDialog_FirmDistributionListParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FirmDistributionList' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cmgEntityKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'distributionEmails' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useSyndicateWires_SendWireDialog_FirmDistributionListsQuery__
 *
 * To run a query within a React component, call `useSyndicateWires_SendWireDialog_FirmDistributionListsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSyndicateWires_SendWireDialog_FirmDistributionListsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSyndicateWires_SendWireDialog_FirmDistributionListsQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      cmgEntityKeys: // value for 'cmgEntityKeys'
 *   },
 * });
 */
export function useSyndicateWires_SendWireDialog_FirmDistributionListsQuery(
  baseOptions: Apollo.QueryHookOptions<
    SyndicateWires_SendWireDialog_FirmDistributionListsQuery,
    SyndicateWires_SendWireDialog_FirmDistributionListsQueryVariables
  > &
    (
      | {
          variables: SyndicateWires_SendWireDialog_FirmDistributionListsQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SyndicateWires_SendWireDialog_FirmDistributionListsQuery,
    SyndicateWires_SendWireDialog_FirmDistributionListsQueryVariables
  >(SyndicateWires_SendWireDialog_FirmDistributionListsDocument, options);
}
export function useSyndicateWires_SendWireDialog_FirmDistributionListsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SyndicateWires_SendWireDialog_FirmDistributionListsQuery,
    SyndicateWires_SendWireDialog_FirmDistributionListsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SyndicateWires_SendWireDialog_FirmDistributionListsQuery,
    SyndicateWires_SendWireDialog_FirmDistributionListsQueryVariables
  >(SyndicateWires_SendWireDialog_FirmDistributionListsDocument, options);
}
export function useSyndicateWires_SendWireDialog_FirmDistributionListsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        SyndicateWires_SendWireDialog_FirmDistributionListsQuery,
        SyndicateWires_SendWireDialog_FirmDistributionListsQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SyndicateWires_SendWireDialog_FirmDistributionListsQuery,
    SyndicateWires_SendWireDialog_FirmDistributionListsQueryVariables
  >(SyndicateWires_SendWireDialog_FirmDistributionListsDocument, options);
}
export type SyndicateWires_SendWireDialog_FirmDistributionListsQueryHookResult = ReturnType<
  typeof useSyndicateWires_SendWireDialog_FirmDistributionListsQuery
>;
export type SyndicateWires_SendWireDialog_FirmDistributionListsLazyQueryHookResult = ReturnType<
  typeof useSyndicateWires_SendWireDialog_FirmDistributionListsLazyQuery
>;
export type SyndicateWires_SendWireDialog_FirmDistributionListsSuspenseQueryHookResult = ReturnType<
  typeof useSyndicateWires_SendWireDialog_FirmDistributionListsSuspenseQuery
>;
export type SyndicateWires_SendWireDialog_FirmDistributionListsQueryResult = Apollo.QueryResult<
  SyndicateWires_SendWireDialog_FirmDistributionListsQuery,
  SyndicateWires_SendWireDialog_FirmDistributionListsQueryVariables
>;

import { makeMockResponse } from '../../../../../../../../../graphql/mock-response';

export const makeSyndicateWires_SendWireDialog_FirmDistributionListsMockResponse = makeMockResponse<
  SyndicateWires_SendWireDialog_FirmDistributionListsQueryVariables,
  SyndicateWires_SendWireDialog_FirmDistributionListsQuery
>(SyndicateWires_SendWireDialog_FirmDistributionListsDocument);
