/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../graphql/__generated__';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CommunicatedDemandForm_MdlCommunicatedDemandIndicationsPartsFragment = {
  readonly __typename?: 'MdlCommunicatedDemandIndications';
  readonly id: string;
  readonly indications: ReadonlyArray<{
    readonly __typename?: 'MdlIndication';
    readonly unitType: Types.MdlIndicationUnitType;
    readonly dollars?: number | null;
    readonly percentage?: number | null;
    readonly shares?: number | null;
    readonly pricingType: Types.MdlIndicationPricingType;
    readonly limitPrice?: number | null;
    readonly realDemandDollars?: number | null;
    readonly realDemandPercentage?: number | null;
    readonly realDemandShares?: number | null;
    readonly limitType: Types.MdlIndicationLimitType;
    readonly limitPercentage?: number | null;
  }>;
};

export type OrderAllocation_CommunicatedIndicationsDemandQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type OrderAllocation_CommunicatedIndicationsDemandQuery = {
  readonly __typename?: 'Query';
  readonly communicatedIndicationsDemand: {
    readonly __typename?: 'MdlCommunicatedDemandIndications';
    readonly id: string;
    readonly indications: ReadonlyArray<{
      readonly __typename?: 'MdlIndication';
      readonly unitType: Types.MdlIndicationUnitType;
      readonly dollars?: number | null;
      readonly percentage?: number | null;
      readonly shares?: number | null;
      readonly pricingType: Types.MdlIndicationPricingType;
      readonly limitPrice?: number | null;
      readonly realDemandDollars?: number | null;
      readonly realDemandPercentage?: number | null;
      readonly realDemandShares?: number | null;
      readonly limitType: Types.MdlIndicationLimitType;
      readonly limitPercentage?: number | null;
    }>;
  };
};

export type OrderAllocation_CommunicatedAllocationDemandQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type OrderAllocation_CommunicatedAllocationDemandQuery = {
  readonly __typename?: 'Query';
  readonly communicatedAllocationDemand: {
    readonly __typename?: 'MdlAllocationResponse';
    readonly id: string;
    readonly allocation?: {
      readonly __typename?: 'MdlAllocation';
      readonly id: string;
      readonly fillPercentage?: number | null;
      readonly investment?: number | null;
      readonly salesConcession?: number | null;
      readonly shares?: number | null;
      readonly ranking: Types.MdlAllocationRanking;
      readonly ioiType: Types.MdlAllocationIoiType;
      readonly lastModified: {
        readonly __typename?: 'MdlAllocationLastModified';
        readonly id?: string | null;
        readonly name?: string | null;
        readonly email?: string | null;
        readonly timestamp?: string | null;
      };
    } | null;
  };
};

export type OrderAllocation_SetAllocationRankingMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['ID'];
  allocationId: Types.Scalars['ID'];
  ranking: Types.MdlAllocationRanking;
}>;

export type OrderAllocation_SetAllocationRankingMutation = {
  readonly __typename?: 'Mutation';
  readonly setAllocationRanking: {
    readonly __typename?: 'MdlAllocationResponse';
    readonly id: string;
    readonly allocation?: {
      readonly __typename?: 'MdlAllocation';
      readonly id: string;
      readonly fillPercentage?: number | null;
      readonly investment?: number | null;
      readonly salesConcession?: number | null;
      readonly shares?: number | null;
      readonly ranking: Types.MdlAllocationRanking;
      readonly ioiType: Types.MdlAllocationIoiType;
      readonly lastModified: {
        readonly __typename?: 'MdlAllocationLastModified';
        readonly id?: string | null;
        readonly name?: string | null;
        readonly email?: string | null;
        readonly timestamp?: string | null;
      };
    } | null;
  };
};

export type OrderAllocation_CommunicatedDemandOfferingTypeQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['UUID'];
}>;

export type OrderAllocation_CommunicatedDemandOfferingTypeQuery = {
  readonly __typename?: 'Query';
  readonly basicPublishedOffering: {
    readonly __typename?: 'BasicPublishedOfferingProfile';
    readonly id: string;
    readonly type: Types.OfferingType;
  };
};

export type OrderAllocation_UpsertCommunicatedDemandIndicationsMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['ID'];
  input?: Types.InputMaybe<ReadonlyArray<Types.MdlIndicationInput> | Types.MdlIndicationInput>;
}>;

export type OrderAllocation_UpsertCommunicatedDemandIndicationsMutation = {
  readonly __typename?: 'Mutation';
  readonly upsertCommunicatedDemandIndications: {
    readonly __typename?: 'MdlCommunicatedDemandIndications';
    readonly id: string;
    readonly indications: ReadonlyArray<{
      readonly __typename?: 'MdlIndication';
      readonly unitType: Types.MdlIndicationUnitType;
      readonly dollars?: number | null;
      readonly percentage?: number | null;
      readonly shares?: number | null;
      readonly pricingType: Types.MdlIndicationPricingType;
      readonly limitPrice?: number | null;
      readonly realDemandDollars?: number | null;
      readonly realDemandPercentage?: number | null;
      readonly realDemandShares?: number | null;
      readonly limitType: Types.MdlIndicationLimitType;
      readonly limitPercentage?: number | null;
    }>;
  };
};

export type OrderAllocation_UpsertCommunicatedDemandAllocationMutationVariables = Types.Exact<{
  offeringId: Types.Scalars['ID'];
  input?: Types.InputMaybe<Types.MdlAllocationInput>;
}>;

export type OrderAllocation_UpsertCommunicatedDemandAllocationMutation = {
  readonly __typename?: 'Mutation';
  readonly upsertCommunicatedDemandAllocation: {
    readonly __typename?: 'MdlAllocationResponse';
    readonly id: string;
    readonly allocation?: {
      readonly __typename?: 'MdlAllocation';
      readonly id: string;
      readonly fillPercentage?: number | null;
      readonly investment?: number | null;
      readonly salesConcession?: number | null;
      readonly shares?: number | null;
      readonly ranking: Types.MdlAllocationRanking;
      readonly ioiType: Types.MdlAllocationIoiType;
      readonly lastModified: {
        readonly __typename?: 'MdlAllocationLastModified';
        readonly id?: string | null;
        readonly name?: string | null;
        readonly email?: string | null;
        readonly timestamp?: string | null;
      };
    } | null;
  };
};

export const CommunicatedDemandForm_MdlCommunicatedDemandIndicationsPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CommunicatedDemandForm_MdlCommunicatedDemandIndicationsParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MdlCommunicatedDemandIndications' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'indications' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CommunicatedDemandForm_MdlIndicationParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CommunicatedDemandForm_MdlIndicationParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MdlIndication' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'unitType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dollars' } },
          { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'limitPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'realDemandDollars' } },
          { kind: 'Field', name: { kind: 'Name', value: 'realDemandPercentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'realDemandShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'limitType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'limitPercentage' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const OrderAllocation_CommunicatedIndicationsDemandDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'OrderAllocation_CommunicatedIndicationsDemand' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'communicatedIndicationsDemand' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'xcOfferingId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'CommunicatedDemandForm_MdlCommunicatedDemandIndicationsParts',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CommunicatedDemandForm_MdlCommunicatedDemandIndicationsParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MdlCommunicatedDemandIndications' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'indications' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CommunicatedDemandForm_MdlIndicationParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CommunicatedDemandForm_MdlIndicationParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MdlIndication' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'unitType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dollars' } },
          { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'limitPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'realDemandDollars' } },
          { kind: 'Field', name: { kind: 'Name', value: 'realDemandPercentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'realDemandShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'limitType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'limitPercentage' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useOrderAllocation_CommunicatedIndicationsDemandQuery__
 *
 * To run a query within a React component, call `useOrderAllocation_CommunicatedIndicationsDemandQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderAllocation_CommunicatedIndicationsDemandQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderAllocation_CommunicatedIndicationsDemandQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useOrderAllocation_CommunicatedIndicationsDemandQuery(
  baseOptions: Apollo.QueryHookOptions<
    OrderAllocation_CommunicatedIndicationsDemandQuery,
    OrderAllocation_CommunicatedIndicationsDemandQueryVariables
  > &
    (
      | { variables: OrderAllocation_CommunicatedIndicationsDemandQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OrderAllocation_CommunicatedIndicationsDemandQuery,
    OrderAllocation_CommunicatedIndicationsDemandQueryVariables
  >(OrderAllocation_CommunicatedIndicationsDemandDocument, options);
}
export function useOrderAllocation_CommunicatedIndicationsDemandLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrderAllocation_CommunicatedIndicationsDemandQuery,
    OrderAllocation_CommunicatedIndicationsDemandQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OrderAllocation_CommunicatedIndicationsDemandQuery,
    OrderAllocation_CommunicatedIndicationsDemandQueryVariables
  >(OrderAllocation_CommunicatedIndicationsDemandDocument, options);
}
export function useOrderAllocation_CommunicatedIndicationsDemandSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        OrderAllocation_CommunicatedIndicationsDemandQuery,
        OrderAllocation_CommunicatedIndicationsDemandQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    OrderAllocation_CommunicatedIndicationsDemandQuery,
    OrderAllocation_CommunicatedIndicationsDemandQueryVariables
  >(OrderAllocation_CommunicatedIndicationsDemandDocument, options);
}
export type OrderAllocation_CommunicatedIndicationsDemandQueryHookResult = ReturnType<
  typeof useOrderAllocation_CommunicatedIndicationsDemandQuery
>;
export type OrderAllocation_CommunicatedIndicationsDemandLazyQueryHookResult = ReturnType<
  typeof useOrderAllocation_CommunicatedIndicationsDemandLazyQuery
>;
export type OrderAllocation_CommunicatedIndicationsDemandSuspenseQueryHookResult = ReturnType<
  typeof useOrderAllocation_CommunicatedIndicationsDemandSuspenseQuery
>;
export type OrderAllocation_CommunicatedIndicationsDemandQueryResult = Apollo.QueryResult<
  OrderAllocation_CommunicatedIndicationsDemandQuery,
  OrderAllocation_CommunicatedIndicationsDemandQueryVariables
>;
export const OrderAllocation_CommunicatedAllocationDemandDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'OrderAllocation_CommunicatedAllocationDemand' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'communicatedAllocationDemand' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'xcOfferingId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'CommunicatedDemandForm_MdlAllocationResponseParts',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CommunicatedDemandForm_MdlAllocationResponseParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MdlAllocationResponse' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CommunicatedDemandForm_MdlAllocationParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CommunicatedDemandForm_MdlAllocationParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MdlAllocation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fillPercentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investment' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastModified' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'IndicationSubmittedInfo_AllocationLastModifiedInfoParts',
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'salesConcession' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ranking' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ioiType' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'IndicationSubmittedInfo_AllocationLastModifiedInfoParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MdlAllocationLastModified' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useOrderAllocation_CommunicatedAllocationDemandQuery__
 *
 * To run a query within a React component, call `useOrderAllocation_CommunicatedAllocationDemandQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderAllocation_CommunicatedAllocationDemandQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderAllocation_CommunicatedAllocationDemandQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useOrderAllocation_CommunicatedAllocationDemandQuery(
  baseOptions: Apollo.QueryHookOptions<
    OrderAllocation_CommunicatedAllocationDemandQuery,
    OrderAllocation_CommunicatedAllocationDemandQueryVariables
  > &
    (
      | { variables: OrderAllocation_CommunicatedAllocationDemandQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OrderAllocation_CommunicatedAllocationDemandQuery,
    OrderAllocation_CommunicatedAllocationDemandQueryVariables
  >(OrderAllocation_CommunicatedAllocationDemandDocument, options);
}
export function useOrderAllocation_CommunicatedAllocationDemandLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrderAllocation_CommunicatedAllocationDemandQuery,
    OrderAllocation_CommunicatedAllocationDemandQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OrderAllocation_CommunicatedAllocationDemandQuery,
    OrderAllocation_CommunicatedAllocationDemandQueryVariables
  >(OrderAllocation_CommunicatedAllocationDemandDocument, options);
}
export function useOrderAllocation_CommunicatedAllocationDemandSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        OrderAllocation_CommunicatedAllocationDemandQuery,
        OrderAllocation_CommunicatedAllocationDemandQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    OrderAllocation_CommunicatedAllocationDemandQuery,
    OrderAllocation_CommunicatedAllocationDemandQueryVariables
  >(OrderAllocation_CommunicatedAllocationDemandDocument, options);
}
export type OrderAllocation_CommunicatedAllocationDemandQueryHookResult = ReturnType<
  typeof useOrderAllocation_CommunicatedAllocationDemandQuery
>;
export type OrderAllocation_CommunicatedAllocationDemandLazyQueryHookResult = ReturnType<
  typeof useOrderAllocation_CommunicatedAllocationDemandLazyQuery
>;
export type OrderAllocation_CommunicatedAllocationDemandSuspenseQueryHookResult = ReturnType<
  typeof useOrderAllocation_CommunicatedAllocationDemandSuspenseQuery
>;
export type OrderAllocation_CommunicatedAllocationDemandQueryResult = Apollo.QueryResult<
  OrderAllocation_CommunicatedAllocationDemandQuery,
  OrderAllocation_CommunicatedAllocationDemandQueryVariables
>;
export const OrderAllocation_SetAllocationRankingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'OrderAllocation_SetAllocationRanking' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'allocationId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ranking' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'MdlAllocationRanking' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setAllocationRanking' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'xcOfferingId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'allocationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'allocationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ranking' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'ranking' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'CommunicatedDemandForm_MdlAllocationResponseParts',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CommunicatedDemandForm_MdlAllocationResponseParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MdlAllocationResponse' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CommunicatedDemandForm_MdlAllocationParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CommunicatedDemandForm_MdlAllocationParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MdlAllocation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fillPercentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investment' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastModified' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'IndicationSubmittedInfo_AllocationLastModifiedInfoParts',
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'salesConcession' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ranking' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ioiType' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'IndicationSubmittedInfo_AllocationLastModifiedInfoParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MdlAllocationLastModified' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type OrderAllocation_SetAllocationRankingMutationFn = Apollo.MutationFunction<
  OrderAllocation_SetAllocationRankingMutation,
  OrderAllocation_SetAllocationRankingMutationVariables
>;

/**
 * __useOrderAllocation_SetAllocationRankingMutation__
 *
 * To run a mutation, you first call `useOrderAllocation_SetAllocationRankingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderAllocation_SetAllocationRankingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderAllocationSetAllocationRankingMutation, { data, loading, error }] = useOrderAllocation_SetAllocationRankingMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      allocationId: // value for 'allocationId'
 *      ranking: // value for 'ranking'
 *   },
 * });
 */
export function useOrderAllocation_SetAllocationRankingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OrderAllocation_SetAllocationRankingMutation,
    OrderAllocation_SetAllocationRankingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OrderAllocation_SetAllocationRankingMutation,
    OrderAllocation_SetAllocationRankingMutationVariables
  >(OrderAllocation_SetAllocationRankingDocument, options);
}
export type OrderAllocation_SetAllocationRankingMutationHookResult = ReturnType<
  typeof useOrderAllocation_SetAllocationRankingMutation
>;
export type OrderAllocation_SetAllocationRankingMutationResult =
  Apollo.MutationResult<OrderAllocation_SetAllocationRankingMutation>;
export type OrderAllocation_SetAllocationRankingMutationOptions = Apollo.BaseMutationOptions<
  OrderAllocation_SetAllocationRankingMutation,
  OrderAllocation_SetAllocationRankingMutationVariables
>;
export const OrderAllocation_CommunicatedDemandOfferingTypeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'OrderAllocation_CommunicatedDemandOfferingType' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'basicPublishedOffering' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useOrderAllocation_CommunicatedDemandOfferingTypeQuery__
 *
 * To run a query within a React component, call `useOrderAllocation_CommunicatedDemandOfferingTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderAllocation_CommunicatedDemandOfferingTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderAllocation_CommunicatedDemandOfferingTypeQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useOrderAllocation_CommunicatedDemandOfferingTypeQuery(
  baseOptions: Apollo.QueryHookOptions<
    OrderAllocation_CommunicatedDemandOfferingTypeQuery,
    OrderAllocation_CommunicatedDemandOfferingTypeQueryVariables
  > &
    (
      | { variables: OrderAllocation_CommunicatedDemandOfferingTypeQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OrderAllocation_CommunicatedDemandOfferingTypeQuery,
    OrderAllocation_CommunicatedDemandOfferingTypeQueryVariables
  >(OrderAllocation_CommunicatedDemandOfferingTypeDocument, options);
}
export function useOrderAllocation_CommunicatedDemandOfferingTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrderAllocation_CommunicatedDemandOfferingTypeQuery,
    OrderAllocation_CommunicatedDemandOfferingTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OrderAllocation_CommunicatedDemandOfferingTypeQuery,
    OrderAllocation_CommunicatedDemandOfferingTypeQueryVariables
  >(OrderAllocation_CommunicatedDemandOfferingTypeDocument, options);
}
export function useOrderAllocation_CommunicatedDemandOfferingTypeSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        OrderAllocation_CommunicatedDemandOfferingTypeQuery,
        OrderAllocation_CommunicatedDemandOfferingTypeQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    OrderAllocation_CommunicatedDemandOfferingTypeQuery,
    OrderAllocation_CommunicatedDemandOfferingTypeQueryVariables
  >(OrderAllocation_CommunicatedDemandOfferingTypeDocument, options);
}
export type OrderAllocation_CommunicatedDemandOfferingTypeQueryHookResult = ReturnType<
  typeof useOrderAllocation_CommunicatedDemandOfferingTypeQuery
>;
export type OrderAllocation_CommunicatedDemandOfferingTypeLazyQueryHookResult = ReturnType<
  typeof useOrderAllocation_CommunicatedDemandOfferingTypeLazyQuery
>;
export type OrderAllocation_CommunicatedDemandOfferingTypeSuspenseQueryHookResult = ReturnType<
  typeof useOrderAllocation_CommunicatedDemandOfferingTypeSuspenseQuery
>;
export type OrderAllocation_CommunicatedDemandOfferingTypeQueryResult = Apollo.QueryResult<
  OrderAllocation_CommunicatedDemandOfferingTypeQuery,
  OrderAllocation_CommunicatedDemandOfferingTypeQueryVariables
>;
export const OrderAllocation_UpsertCommunicatedDemandIndicationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'OrderAllocation_UpsertCommunicatedDemandIndications' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'MdlIndicationInput' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertCommunicatedDemandIndications' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'CommunicatedDemandForm_MdlCommunicatedDemandIndicationsParts',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CommunicatedDemandForm_MdlCommunicatedDemandIndicationsParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MdlCommunicatedDemandIndications' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'indications' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CommunicatedDemandForm_MdlIndicationParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CommunicatedDemandForm_MdlIndicationParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MdlIndication' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'unitType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dollars' } },
          { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricingType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'limitPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'realDemandDollars' } },
          { kind: 'Field', name: { kind: 'Name', value: 'realDemandPercentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'realDemandShares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'limitType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'limitPercentage' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type OrderAllocation_UpsertCommunicatedDemandIndicationsMutationFn = Apollo.MutationFunction<
  OrderAllocation_UpsertCommunicatedDemandIndicationsMutation,
  OrderAllocation_UpsertCommunicatedDemandIndicationsMutationVariables
>;

/**
 * __useOrderAllocation_UpsertCommunicatedDemandIndicationsMutation__
 *
 * To run a mutation, you first call `useOrderAllocation_UpsertCommunicatedDemandIndicationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderAllocation_UpsertCommunicatedDemandIndicationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderAllocationUpsertCommunicatedDemandIndicationsMutation, { data, loading, error }] = useOrderAllocation_UpsertCommunicatedDemandIndicationsMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrderAllocation_UpsertCommunicatedDemandIndicationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OrderAllocation_UpsertCommunicatedDemandIndicationsMutation,
    OrderAllocation_UpsertCommunicatedDemandIndicationsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OrderAllocation_UpsertCommunicatedDemandIndicationsMutation,
    OrderAllocation_UpsertCommunicatedDemandIndicationsMutationVariables
  >(OrderAllocation_UpsertCommunicatedDemandIndicationsDocument, options);
}
export type OrderAllocation_UpsertCommunicatedDemandIndicationsMutationHookResult = ReturnType<
  typeof useOrderAllocation_UpsertCommunicatedDemandIndicationsMutation
>;
export type OrderAllocation_UpsertCommunicatedDemandIndicationsMutationResult =
  Apollo.MutationResult<OrderAllocation_UpsertCommunicatedDemandIndicationsMutation>;
export type OrderAllocation_UpsertCommunicatedDemandIndicationsMutationOptions =
  Apollo.BaseMutationOptions<
    OrderAllocation_UpsertCommunicatedDemandIndicationsMutation,
    OrderAllocation_UpsertCommunicatedDemandIndicationsMutationVariables
  >;
export const OrderAllocation_UpsertCommunicatedDemandAllocationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'OrderAllocation_UpsertCommunicatedDemandAllocation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'MdlAllocationInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertCommunicatedDemandAllocation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offeringId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offeringId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'CommunicatedDemandForm_MdlAllocationResponseParts',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CommunicatedDemandForm_MdlAllocationResponseParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MdlAllocationResponse' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CommunicatedDemandForm_MdlAllocationParts' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CommunicatedDemandForm_MdlAllocationParts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MdlAllocation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fillPercentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'investment' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastModified' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'IndicationSubmittedInfo_AllocationLastModifiedInfoParts',
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'salesConcession' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ranking' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ioiType' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'IndicationSubmittedInfo_AllocationLastModifiedInfoParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MdlAllocationLastModified' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type OrderAllocation_UpsertCommunicatedDemandAllocationMutationFn = Apollo.MutationFunction<
  OrderAllocation_UpsertCommunicatedDemandAllocationMutation,
  OrderAllocation_UpsertCommunicatedDemandAllocationMutationVariables
>;

/**
 * __useOrderAllocation_UpsertCommunicatedDemandAllocationMutation__
 *
 * To run a mutation, you first call `useOrderAllocation_UpsertCommunicatedDemandAllocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderAllocation_UpsertCommunicatedDemandAllocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderAllocationUpsertCommunicatedDemandAllocationMutation, { data, loading, error }] = useOrderAllocation_UpsertCommunicatedDemandAllocationMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrderAllocation_UpsertCommunicatedDemandAllocationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OrderAllocation_UpsertCommunicatedDemandAllocationMutation,
    OrderAllocation_UpsertCommunicatedDemandAllocationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OrderAllocation_UpsertCommunicatedDemandAllocationMutation,
    OrderAllocation_UpsertCommunicatedDemandAllocationMutationVariables
  >(OrderAllocation_UpsertCommunicatedDemandAllocationDocument, options);
}
export type OrderAllocation_UpsertCommunicatedDemandAllocationMutationHookResult = ReturnType<
  typeof useOrderAllocation_UpsertCommunicatedDemandAllocationMutation
>;
export type OrderAllocation_UpsertCommunicatedDemandAllocationMutationResult =
  Apollo.MutationResult<OrderAllocation_UpsertCommunicatedDemandAllocationMutation>;
export type OrderAllocation_UpsertCommunicatedDemandAllocationMutationOptions =
  Apollo.BaseMutationOptions<
    OrderAllocation_UpsertCommunicatedDemandAllocationMutation,
    OrderAllocation_UpsertCommunicatedDemandAllocationMutationVariables
  >;

import { makeMockResponse } from '../../../../../../graphql/mock-response';

export const makeOrderAllocation_CommunicatedIndicationsDemandMockResponse = makeMockResponse<
  OrderAllocation_CommunicatedIndicationsDemandQueryVariables,
  OrderAllocation_CommunicatedIndicationsDemandQuery
>(OrderAllocation_CommunicatedIndicationsDemandDocument);

export const makeOrderAllocation_CommunicatedAllocationDemandMockResponse = makeMockResponse<
  OrderAllocation_CommunicatedAllocationDemandQueryVariables,
  OrderAllocation_CommunicatedAllocationDemandQuery
>(OrderAllocation_CommunicatedAllocationDemandDocument);

export const makeOrderAllocation_CommunicatedDemandOfferingTypeMockResponse = makeMockResponse<
  OrderAllocation_CommunicatedDemandOfferingTypeQueryVariables,
  OrderAllocation_CommunicatedDemandOfferingTypeQuery
>(OrderAllocation_CommunicatedDemandOfferingTypeDocument);
