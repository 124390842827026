import {
  DeliveryInstrumentPrivileged,
  DemandCurrencyProfile,
  DisclaimersProfile,
  FilingProfile,
  ProspectusDocumentProfile,
  RoadshowScheduleProfile,
  SharesPerCmgEntity,
  SyndicateProfile,
  TermsProfile,
  TimingProfile,
  TranchePrivileged,
} from '../../../../graphql';
import {
  Offering_OfferingDetails_OfferingPartsFragment,
  Offering_OfferingDetails_PublishedOfferingPartsFragment,
  Offering_OfferingDetails_PublishedOfferingQuery,
} from '../../../offering/offering-details/graphql';
import { Props as OfferingSummaryViewProps } from './OfferingSummaryView';

export function mapOffering(
  source: Offering_OfferingDetails_OfferingPartsFragment
): OfferingSummaryViewProps['offering'] {
  return {
    id: source.id,
    version: source.version,
    type: source.type,
    status: source.status,
    stage: source.stage,
    pricingCurrencyCode: source.pricingCurrencyCode,
    pricingInstrumentId: source.pricingInstrumentId,
    pricingInstrumentStockSymbol: source.pricingInstrumentStockSymbol,
    sizeInCurrency: source.sizeInCurrency,
    roadshowSchedules: source.roadshowSchedules,
    issuer: source.issuer,
    security: source.security,
    timing: source.timing,
    terms: source.terms,
    filings: source.terms.filings,
    disclaimers: source.disclaimers,
    currencies: source.currencies,
    deliveryInstruments: source.deliveryInstruments,
    tranches: source.tranches,
    syndicate: source.syndicate,
    prospectusDocuments: source.prospectusDocuments,
    instruments: source.instruments,
  };
}

export function mapOfferingProfile(
  source: Offering_OfferingDetails_PublishedOfferingPartsFragment
): OfferingSummaryViewProps['offering'] {
  return {
    id: source.id,
    version: source.version,
    type: source.type,
    status: source.status,
    stage: source.stage,
    pricingCurrencyCode: source.pricingCurrencyCode,
    pricingInstrumentId: source.pricingInstrumentId,
    pricingInstrumentStockSymbol: source.pricingInstrumentStockSymbol,
    sizeInCurrency: source.sizeInCurrency,
    roadshowSchedules: mapRoadshowSchedules(source.roadshowSchedules),
    issuer: source.issuer,
    security: mapSecurity(source.security),
    timing: mapTiming(source.timing),
    terms: mapTerms(source.terms),
    filings: mapFilings(source.terms.filings),
    disclaimers: mapDisclaimers(source.disclaimers, source.id),
    currencies: mapCurrencies(source.demandCurrenciesWithExchangeRates),
    deliveryInstruments: mapInstruments(source.instruments),
    tranches: mapTranches(source.tranches),
    syndicate: mapSyndicate(
      source.syndicate,
      source.managerEconomics?.underwritingBaseSharesDistribution ?? []
    ),
    prospectusDocuments: mapProspectusDocuments(source.prospectusDocuments),
    instruments: [],
  };
}

export function mapInstruments(
  source: readonly DeliveryInstrumentPrivileged[]
): OfferingSummaryViewProps['offering']['deliveryInstruments'] {
  return source.map(item => ({
    id: item.id,
    currencyCode: item.currencyCode ?? '',
    countryCode: item.countryCode ?? '',
    isDepositaryReceipt: item.isDepositaryReceipt ?? false,
    stockSymbol: item.stockSymbol,
    isin: item.isin,
    cusip: item.cusip,
    figi: item.figi,
    exchangeMic: item.exchangeMic,
    depositaryReceiptConversionRatio: item.depositaryReceiptConversionRatio,
  }));
}

export function mapTranches(
  source: readonly Omit<TranchePrivileged, 'deliveryInstruments'>[] | undefined | null
): OfferingSummaryViewProps['offering']['tranches'] {
  return (
    source?.map(item => ({
      id: item.id,
      name: item.name,
      region: item.region,
      defaultInstrumentId: item.defaultInstrumentId,
      currencyIds: item.demandCurrencies,
    })) ?? []
  );
}

export function mapRoadshowSchedules(
  source: readonly RoadshowScheduleProfile[] | undefined
): OfferingSummaryViewProps['offering']['roadshowSchedules'] {
  return (
    source?.map(item => ({
      date: item.date,
      details: item.details,
      id: item.id,
    })) ?? []
  );
}

export function mapCurrencies(
  source: readonly DemandCurrencyProfile[] | undefined
): OfferingSummaryViewProps['offering']['currencies'] {
  return (
    source?.map(item => ({
      currencyCode: item.currencyCode,
      exchangeRate: item.exchangeRate ?? 0,
    })) ?? []
  );
}

export function mapProspectusDocuments(
  source: readonly ProspectusDocumentProfile[]
): OfferingSummaryViewProps['offering']['prospectusDocuments'] {
  return source.map(item => ({
    id: item.id,
    documentName: item.documentName,
    includeOnPublish: true,
    publicUrl: item.publicUrl,
    uploadedAt: '',
    firstPublishedAt: item.firstPublishedAt,
  }));
}

export function mapSecurity(
  source: Offering_OfferingDetails_PublishedOfferingQuery['publishedOffering']['security']
): OfferingSummaryViewProps['offering']['security'] {
  return {
    type: source.type,
    typeDisplayName: source.typeDisplayName,
    isDepositaryReceipt: false,
    shareClass: source.shareClass,
  };
}

export function mapSyndicate(
  source: SyndicateProfile,
  economics: readonly SharesPerCmgEntity[]
): OfferingSummaryViewProps['offering']['syndicate'] {
  return {
    grossSpread: null,
    managers: source.managers.map(item => ({
      id: item.id,
      cmgEntityKey: item.cmgEntityKey,
      role: item.role,
      isJuniorRole: false,
      isParticipating: true,
      economics: economics.find(manager => manager.cmgEntityKey === item.cmgEntityKey)?.sharesValue,
      firmName: item.firmName,
    })),
    managerResponsibilities: {
      stabilizationAgent: source.managerResponsibilities.stabilizationAgent,
      settlementAgent: source.managerResponsibilities.settlementAgent,
      logisticsAgent: source.managerResponsibilities.logisticsAgent,
      leftLead: source.managerResponsibilities.leftLead,
    },
  };
}

export function mapDisclaimers(
  source: DisclaimersProfile,
  offeringId: string
): OfferingSummaryViewProps['offering']['disclaimers'] {
  return {
    id: offeringId,
    restrictions: source.restrictions,
    footnotes: source.footnotes,
    registrationLinks: source.registrationLinks,
    rule134Legend: source.rule134Legend,
  };
}

export function mapFilings(
  source: readonly FilingProfile[]
): OfferingSummaryViewProps['offering']['filings'] {
  return source.map(item => ({
    id: item.id,
    filingType: item.filingType,
    initialRegistrationValue: item.initialRegistrationValue,
    primarySharesBaseOffering: item.primarySharesBaseOffering,
    secondarySharesBaseOffering: item.secondarySharesBaseOffering,
    notionalOverAllotmentAuthorized: item.notionalOverAllotmentAuthorized,
    primarySharesOverAllotmentAuthorized: item.primarySharesOverAllotmentAuthorized,
    secondarySharesOverAllotmentAuthorized: item.secondarySharesOverAllotmentAuthorized,
    notionalOverAllotmentExercised: item.notionalOverAllotmentExercised,
    primarySharesOverAllotmentExercised: item.primarySharesOverAllotmentExercised,
    secondarySharesOverAllotmentExercised: item.secondarySharesOverAllotmentExercised,
    ipoRangeLow: item.ipoRangeLow,
    ipoRangeHigh: item.ipoRangeHigh,
    offerPrice: item.offerPrice,
    postOfferingShares: item.postOfferingShares,
    useOfProceeds: item.useOfProceeds,
    lockUpPeriod: item.lockUpPeriod,
  }));
}

export function mapTerms(source: TermsProfile): OfferingSummaryViewProps['offering']['terms'] {
  return {
    followOnPricing: {
      lastTradeBeforeFiling: source.followOnPricing?.lastTradeBeforeFiling,
      lastTradeBeforeLaunch: source.followOnPricing?.lastTradeBeforeLaunch,
      lastTradeBeforeOffer: source.followOnPricing?.lastTradeBeforeOffer,
      reOfferLow: source.followOnPricing?.reOfferLow,
      reOfferHigh: source.followOnPricing?.reOfferHigh,
    },
  };
}

export function mapTiming(source: TimingProfile): OfferingSummaryViewProps['offering']['timing'] {
  return {
    confidentialFilingDate: source.confidentialFilingDate,
    publicFilingDate: source.publicFilingDate,
    filingOccurred: source.filingOccurred,
    launchOccurred: source.launchOccurred,
    launchDate: source.launchDate,
    booksCloseAt: source.booksCloseAt,
    pricingDate: source.pricingDate,
    firstTradeDate: source.firstTradeDate,
    tradeDate: source.tradeDate,
    settlementDate: source.settlementDate,
    postponedDate: null, // this field is not used
    withdrawnDate: source.withdrawnDate,
    terminatedDate: source.terminatedDate,
    timeZone: source.timeZone,
  };
}
