import type { CustomToolPanelProps } from '@cmg/data-grid';
import { AddIcon, Button, List, Stack, Typography } from '@cmg/design-system';
import React from 'react';

import { ReactComponent as CmgLogo } from '../../../../../common/assets/identity-icon.svg';
import { OfferingStatus, OfferingType } from '../../../../../graphql';
import SaveTemplateDialog from '../../dialogs/SaveTemplateDialog';
import type { OrderBook_InstitutionalDemand_GridRowPartsFragment } from '../../graphql/__generated__';
import { useTemplateDialogState } from '../hooks/useTemplateDialogState';
import { getCmgTemplateKey, useTemplatesState } from '../hooks/useTemplatesState';
import type { DemandGridDataContext } from '../types';
import CmgTemplateListItem from './components/templates/CmgTemplateListItem';
import UserTemplateListItem from './components/templates/UserTemplateListItem';
import { ToolPanel } from './ToolPanel';

export type TemplatesToolPanelProps = Readonly<{
  offeringId: string;
  offeringType: OfferingType;
  offeringStatus: OfferingStatus;
}>;

type Props = CustomToolPanelProps<
  OrderBook_InstitutionalDemand_GridRowPartsFragment,
  DemandGridDataContext
> &
  TemplatesToolPanelProps;

export const TemplatesToolPanel: React.FC<Props> = ({
  offeringId,
  offeringStatus,
  offeringType,
}) => {
  const { isDialogOpen, templateId, templateName, originalTemplateId, openDialog, closeDialog } =
    useTemplateDialogState();
  const {
    userTemplates,
    addTemplate,
    removeTemplate,
    saveTemplate,
    setActiveUserTemplate,
    setActiveCmgTemplate,
    getActiveUserTemplate,
  } = useTemplatesState(offeringId);

  const defaultTemplateName = getCmgTemplateKey({ offeringType, offeringStatus });

  return (
    <ToolPanel title="Grid Templates">
      <List
        subheader={
          <Stack direction="row" alignItems="center" gap={1}>
            <CmgLogo width={20} />
            <Typography variant="subtitle1">CMG Templates</Typography>
          </Stack>
        }
      >
        <CmgTemplateListItem
          isActive={getActiveUserTemplate() === undefined}
          templateName={defaultTemplateName}
          onReset={() => setActiveCmgTemplate(defaultTemplateName)}
          onSaveAsNew={openDialog}
        />
      </List>
      <List
        subheader={
          <Stack direction="row" alignItems="center" gap={1}>
            <Typography variant="subtitle1">Saved Templates</Typography>
            <Button onClick={() => openDialog()} startIcon={<AddIcon />}>
              Add New
            </Button>
          </Stack>
        }
      >
        {userTemplates.length === 0 && (
          <Typography color="textSecondary">
            To customize your experience of the Institutional Demand Grid, set your preferred
            columns, width, and order, and save them as a template.
          </Typography>
        )}
        {userTemplates.map(template => (
          <UserTemplateListItem
            key={template.id}
            template={template}
            onDelete={() =>
              removeTemplate({ id: template.id, cmgTemplateKey: defaultTemplateName })
            }
            onSave={() => saveTemplate(template.id)}
            onRename={() => openDialog(template)}
            onSaveAsNew={openDialog}
            onReset={() => setActiveUserTemplate(template.id)}
          />
        ))}
      </List>
      <SaveTemplateDialog
        offeringId={offeringId}
        isOpen={isDialogOpen}
        templateId={templateId}
        templateName={templateName}
        onClose={closeDialog}
        onSave={({ id, name }) => addTemplate({ id, name, originalTemplateId })}
      />
    </ToolPanel>
  );
};
